<template>
  <div :class="customClass ? customClass : 'loader'" v-if="loading">
    <div v-if="!small" class="loader-spinner">Loading
      <span class="ring-loader"></span>
    </div>
    <div v-if="small" class="loader-spinner-sm">
      <span class="ring-loader-sm"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Loader',
  props: ['loading', 'small', 'customClass'],
  data () {
    return {
    }
  }
}
</script>

<style>
.loader {
  position: absolute;
  z-index: 10;
  top: 35vh;
  width: 100%;
}

/* Start Normal Size */
.loader-spinner
{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 150px;
  height: 150px;
  background: transparent;
  border: 2px solid #3c3c3c;
  border-radius: 50%;
  text-align: center;
  line-height: 150px;
  font-family: sans-serif;
  font-size: 20px;
  color: #19b5fe;
  letter-spacing: 4px;
  text-transform: uppercase;
  text-shadow: 0 0 10px #19b5fe;
  box-shadow: 0 0 20px rgba(0,0,0,.5);
}
.loader-spinner:before
{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  border-top: 3px solid #19b5fe;
  border-right: 3px solid #19b5fe;
  border-radius: 50%;
  animation: spinner 2s linear infinite;
}
span.ring-loader
{
  display: block;
  position: absolute;
  top: calc(50% - 2px);
  left: 50%;
  width: 50%;
  height: 4px;
  background: transparent;
  transform-origin: left;
  animation: ring 2s linear infinite;
}
span.ring-loader:before
{
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #19b5fe;
  top: -6px;
  right: -8px;
  box-shadow: 0 0 20px #19b5fe;
}
/* End Normal Size */

/* Start Small Size */
.loader-spinner-sm
{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 25px;
  height: 25px;
  background: transparent;
  border: 2px solid #3c3c3c;
  border-radius: 50%;
  box-shadow: 0 0 20px rgba(0,0,0,.5);
}
.loader-spinner-sm:before
{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  border-top: 3px solid #19b5fe;
  border-right: 3px solid #19b5fe;
  border-radius: 50%;
  animation: spinner 2s linear infinite;
}
span.ring-loader-sm
{
  display: block;
  position: absolute;
  top: calc(50% - 2px);
  left: 50%;
  width: 50%;
  height: 4px;
  background: transparent;
  transform-origin: left;
  animation: ring 2s linear infinite;
}
span.ring-loader-sm:before
{
  content: '';
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #19b5fe;
  top: 0;
  right: -2px;
  box-shadow: 0 0 20px #19b5fe;
}
/* End Small Size */
@keyframes spinner
{
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
@keyframes ring
{
  0% { transform:rotate(45deg); }
  100% { transform:rotate(405deg); }
}
</style>