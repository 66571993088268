<template>
  <div>
    <div class="bookmarks fixed-bottom clearfix text-start">
      <div class="bookmarks-container">
        <div v-if="favourites && favourites.length > 0" class="bookmarks-wrapper scroll-width-thin">
          <div class="bookmarks-list">

            <div v-for="(fav, index) in favourites" :key="index"
                 class="bookmark"
                 @mouseover="toggleBookmarkPopup(fav, 'mouseover')"
                 @mouseleave="toggleBookmarkPopup(fav, 'mouseleave')"
                 @click="openAdDetails(fav.property.id)"
            >

              <div v-if="fav.property" class=""><!-- position-relative -->
                <button class="remove-bookmark-btn"><font-awesome-icon icon="times-circle" :id="'bookmark-remove-btn-' + fav.id" :class="'bookmark-' + fav.id + ' remove-bookmark-icon'" @click.stop="removeBookmark(fav.id)"/></button>
                <div v-if="fav.property.documents_property_images && fav.property.documents_property_images.length === 0">
                  <img :src="require(`@/assets/no-image-found.png`)"
                       class="d-block w-100 img-fluid img-bookmark"
                       alt="..."
                  >
                </div>
                <img v-else :src="`${$s3bucket}${fav.property.documents_property_images[0].file_path}`" @error="setDefaultImg"
                     class="d-block w-100 img-fluid img-bookmark"
                     alt="..."
                >
<!--                <img v-if="getImage(fav.property.documents_property_images[0].file_name)" :src="require(`@/assets/${fav.property.documents_property_images[0].file_name}`)"-->
<!--                     class="d-block w-100 img-fluid img-bookmark"-->
<!--                     alt="..."-->
<!--                >-->
<!--                <img v-else :src="require(`@/assets/no-image-found.png`)" class="d-block w-100 img-fluid thumbnails-img" alt="...">-->
                <div id="bookmarkPopup" class="bookmark-popup">
                  <div class="pop">
                    <div v-if="fav" class="small p-2">
                      <p class="p-0 m-0">
                      <span v-if="showPriceRange(fav.property)" class="price-container">
                        <font-awesome-icon icon="pound-sign" class="icon-fa" />
                        <span v-if="showHourPriceRange(fav.property)" class="price-color"> {{ calculateHourPriceRange(fav.property) }} </span>
                        <span v-if="showHourPriceRange(fav.property)" class="fw-bold"> per hour </span> <br v-if="showHourPriceRange(fav.property)"/>
                        <span v-if="showNightPriceRange(fav.property)" class="price-color"> {{ calculateNightPriceRange(fav.property) }} </span>
                        <span v-if="showNightPriceRange(fav.property)" class="fw-bold"> per night </span>
                        <!-- This only for Let Long-Term Rooms -->
                        <span v-if="showWeekPriceRange(fav.property)" class="price-color"> {{ calculateWeekPriceRange(fav.property) }} </span>
                        <span v-if="showWeekPriceRange(fav.property)" class="fw-bold"> per week </span>
                        <span v-if="showMonthPriceRange(fav.property)" class="price-color"> {{ calculateMonthPriceRange(fav.property) }} </span>
                        <span v-if="showMonthPriceRange(fav.property)" class="fw-bold"> per month </span>
                      </span>
                      <span v-else class="price-container">
                        <font-awesome-icon icon="pound-sign" class="icon-fa" />
                        <span class="price-color"> {{ convertNumberToCommas(fav.property.price) }}</span>
                        <span v-if="fav.property.is_rent || fav.property.is_let" class="fw-bold"> per {{ fav.property.property_rent_type.type }} </span>
                      </span>
                      </p>
                      <p class="p-0 m-0">
                      <span class="fw-bold prop-type-container">
                        {{ fav.property.property_category && !fav.property.property_type_details ? fav.property.property_category.type : '' }}
                        {{ fav.property.property_type ? fav.property.property_type.type : '' }}
                        {{ fav.property.property_type_details ? fav.property.property_type_details.type_detail : '' }}
                      </span>
                      </p>
                      <p class="p-0 m-0">
                      <span class="address-container">
                        {{ fav.property.address && fav.property.address.full_address ? fav.property.address.full_address : '' }}
                      </span>
                      </p>
  <!--                    {{ fav.property.is_sale }}-->
  <!--                    {{ fav.property.is_let }}-->
  <!--                    {{ fav.property.is_rent }}-->
  <!--                    {{ fav.property.is_buy }}-->
  <!--                            {{ fav.property }}-->
                    </div>
                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import axios from "axios";

export default {
  name: 'Bookmarks',
  data () {
    return {
      favourites: null,
      loading: true,
      fav: null,
      processing: false
    }
  },
  mounted() {
    this.loading = true;

    if (this.$store.state.auth.authenticated === true && !this.$store.state.auth.user.favourite_properties) {
      this.getFavourites('Property');
    } else {
      this.favourites = this.$store.state.auth.user.favourite_properties;
    }

    this.loading = false;
  },
  methods: {
    // Helpers for LET ads to reflect short-term and long-term lets data
    checkAdIsLetShortTermOrRoom(prop) {
      // property_type_details_id === 8 - studio flat as we do not get property_details.studio_room on favourites
      if (prop && prop.is_let && ((prop.is_short_term && prop.property_type_details_id !== 8) || prop.is_room)) {
        return true;
      } else {
        return false;
      }
    },
    checkAdIsLetLongTermOrStudio(prop) {
      // property_type_details_id === 8 - studio flat as we do not get property_details.studio_room on favourites
      if (prop && prop.is_let && ((!prop.is_short_term && !prop.is_room) || prop.property_type_details_id === 8)) {
        return true;
      } else {
        return false;
      }
    },
    showPriceRange(prop) {
      if (this.checkAdIsLetShortTermOrRoom(prop)) {
        return true;
      } else {
        return false;
      }
    },
    showHourPriceRange(prop) {
      if (prop.property_rooms && prop.property_rooms.length > 0) {
        // get hourly prices
        let hourlyPrices = prop.property_rooms.filter(obj => {
          if (obj.property_rent_type_id !== 2) {
            return false;
          } else {
            return true;
          }
        }).map(object => {
          return object.price;
        });

        if (hourlyPrices.length > 0) {
          return true;
        } else {
          return false;
        }
      }
    },
    calculateHourPriceRange(prop) {
      if (prop.property_rooms && prop.property_rooms.length > 0) {
        let maxHourlyPrice = null;
        let minHourlyPrice = null;

        // get hourly prices
        let hourlyPrices = prop.property_rooms.filter(obj => {
          if (obj.property_rent_type_id !== 2) {
            return false;
          } else {
            return true;
          }
        }).map(object => {
          return object.price;
        });

        if (hourlyPrices.length > 0) {
          maxHourlyPrice = Math.max.apply(null, hourlyPrices);
          minHourlyPrice = Math.min.apply(null, hourlyPrices);
        }

        if (minHourlyPrice && maxHourlyPrice) {
          if (minHourlyPrice == maxHourlyPrice) {
            return this.convertNumberToCommas(minHourlyPrice);
          } else {
            return this.convertNumberToCommas(minHourlyPrice) + ' - ' + this.convertNumberToCommas(maxHourlyPrice);
          }
        }
      }
    },
    showNightPriceRange(prop) {
      if (prop.property_rooms && prop.property_rooms.length > 0) {
        // get nightly prices
        let nightlyPrices = prop.property_rooms.filter(obj => {
          if (obj.property_rent_type_id !== 3) {
            return false;
          } else {
            return true;
          }
        }).map(object => {
          return object.price;
        });

        if (nightlyPrices.length > 0) {
          return true;
        } else {
          return false;
        }
      }
    },
    calculateNightPriceRange(prop) {
      if (prop.property_rooms && prop.property_rooms.length > 0) {
        let maxNightlyPrice = null;
        let minNightlyPrice = null;

        // get nightly prices
        let nightlyPrices = prop.property_rooms.filter(obj => {
          if (obj.property_rent_type_id !== 3) {
            return false;
          } else {
            return true;
          }
        }).map(object => {
          return object.price;
        });

        if (nightlyPrices.length > 0) {
          maxNightlyPrice = Math.max.apply(null, nightlyPrices);
          minNightlyPrice = Math.min.apply(null, nightlyPrices);
        }

        if (minNightlyPrice && maxNightlyPrice) {
          if (minNightlyPrice == maxNightlyPrice) {
            return this.convertNumberToCommas(minNightlyPrice);
          } else {
            return this.convertNumberToCommas(minNightlyPrice) + ' - ' + this.convertNumberToCommas(maxNightlyPrice);
          }
        }
      }
    },
    showWeekPriceRange(prop) {
      if (prop.property_rooms && prop.property_rooms.length > 0) {
        // get weekly prices
        let weeklyPrices = prop.property_rooms.filter(obj => {
          if (obj.property_rent_type_id !== 4) {
            return false;
          } else {
            return true;
          }
        }).map(object => {
          return object.price;
        });

        if (weeklyPrices.length > 0) {
          return true;
        } else {
          return false;
        }
      }
    },
    calculateWeekPriceRange(prop) {
      if (prop.property_rooms && prop.property_rooms.length > 0) {
        let maxWeeklyPrice = null;
        let minWeeklyPrice = null;

        // get weekly prices
        let weeklyPrices = prop.property_rooms.filter(obj => {
          if (obj.property_rent_type_id !== 4) {
            return false;
          } else {
            return true;
          }
        }).map(object => {
          return object.price;
        });

        if (weeklyPrices.length > 0) {
          maxWeeklyPrice = Math.max.apply(null, weeklyPrices);
          minWeeklyPrice = Math.min.apply(null, weeklyPrices);
        }

        if (minWeeklyPrice && maxWeeklyPrice) {
          if (minWeeklyPrice == maxWeeklyPrice) {
            return this.convertNumberToCommas(minWeeklyPrice);
          } else {
            return this.convertNumberToCommas(minWeeklyPrice) + ' - ' + this.convertNumberToCommas(maxWeeklyPrice);
          }
        }
      }
    },
    showMonthPriceRange(prop) {
      if (prop.property_rooms && prop.property_rooms.length > 0) {
        // get monthly prices
        let monthlyPrices = prop.property_rooms.filter(obj => {
          if (obj.property_rent_type_id !== 5) {
            return false;
          } else {
            return true;
          }
        }).map(object => {
          return object.price;
        });

        if (monthlyPrices.length > 0) {
          return true;
        } else {
          return false;
        }
      }
    },
    calculateMonthPriceRange(prop) {
      if (prop.property_rooms && prop.property_rooms.length > 0) {
        let maxMonthlyPrice = null;
        let minMonthlyPrice = null;

        // get monthly prices
        let monthlyPrices = prop.property_rooms.filter(obj => {
          if (obj.property_rent_type_id !== 5) {
            return false;
          } else {
            return true;
          }
        }).map(object => {
          return object.price;
        });

        if (monthlyPrices.length > 0) {
          maxMonthlyPrice = Math.max.apply(null, monthlyPrices);
          minMonthlyPrice = Math.min.apply(null, monthlyPrices);
        }

        if (minMonthlyPrice && maxMonthlyPrice) {
          if (minMonthlyPrice == maxMonthlyPrice) {
            return this.convertNumberToCommas(minMonthlyPrice);
          } else {
            return this.convertNumberToCommas(minMonthlyPrice) + ' - ' + this.convertNumberToCommas(maxMonthlyPrice);
          }
        }
      }
    },
    // End Helpers for LET ads to reflect short-term and long-term lets data
    async getFavourites(type = null) {
      return axios.post('api/favourites', {type: type }).then(({data})=> {
        this.favourites = data.data;
      })
    },
    getImage(file_name) {
      try {
        return require(`@/assets/${file_name}`);
      } catch (e) {
        return false
      }
    },
    setDefaultImg(e) {
      e.target.src = require(`@/assets/no-image-found.png`);
    },
    toggleBookmarkPopup(fav, eventType) {
      this.fav = fav;
      let popup = document.getElementById("bookmarkPopup");
      let bookmarkWrapper = document.getElementsByClassName("bookmarks-wrapper");
      let bookmarkRemoveBtn = document.getElementById("bookmark-remove-btn-" + fav.id);

      if (eventType === 'mouseover') {
        if (!popup.classList.contains("show")) {
          popup.classList.toggle("show");
        }
        if (!bookmarkRemoveBtn.classList.contains("show-remove-bookmark-btn")) {
          bookmarkRemoveBtn.classList.toggle("show-remove-bookmark-btn")
        }
        bookmarkWrapper[0].style.height = "250px";
      }
      if (eventType === 'mouseleave') {
        if (popup.classList.contains("show")) {
          popup.classList.toggle("show");
          this.fav = null;
        }
        if (bookmarkRemoveBtn.classList.contains("show-remove-bookmark-btn")) {
          bookmarkRemoveBtn.classList.toggle("show-remove-bookmark-btn")
        }
        bookmarkWrapper[0].style.height = "50px";
      }
    },
    openAdDetails(id) {
      // Open propDetails (AdDetails.vue) to show specific favourite bookmark
      this.$root.$emit('openPropDetailsGlobal', id);
    },
    convertNumberToCommas(num) {
      if (num && num !== '' && num !== null) {
        return num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return 0;
      }
    },
    async removeBookmark(id) {
      if (this.processing === true) {
        return;
      }
      this.processing = true;
      await axios.post('api/favourites/' + id + '/delete').then(response => {
        this.validationErrors = null;
      }).catch(({response}) => { // {response: {data}}
        if (response && response.data && response.data.errors) {
          this.validationErrors = response.data.errors;
          // this.validationMessage = data.message;
        }
      }).finally(() => {
        // Refresh user data so we get new favourites in the listing
        if (this.validationErrors === null) {
          // Refresh User Data
          this.$root.$emit('globalRefreshUser');
        }
        this.processing = false
      })
    }
  },
  watch: {
    '$store.state.auth': {
      handler: function (val) {
        this.loading = true;

        if (this.$store.state.auth.authenticated === true) {
          this.getFavourites('Property');
        } else {
          this.favourites = null;
        }

        this.loading = false;
      }, deep: true, immediate: true
    },
  }
}
</script>

<style scoped lang="scss">

.bookmarks-container {
  height: 35px;
  position: relative;
  .bookmarks-wrapper {
    pointer-events: none;
    width: 100%;
    height: 250px;
    position: absolute;
    bottom: 0;
    overflow-y: hidden;
    overflow-x: hidden;
    .bookmarks-list {
      padding-left: 5%;
      padding-right: 5%;
      pointer-events: auto;
      height: 30px;
      position: absolute;
      bottom: 0;
      white-space: nowrap;
      .remove-bookmark-btn {
        z-index: 10;
        position: absolute;
        display: block;
        left: -25%;
        top: -10%;
        width: auto;
        height: auto;
        border-radius: 50%;
        margin: 0;
        padding: 0;
        border: none;
        .remove-bookmark-icon {
          transition: 0.5s;
          -moz-transition: 0.5s;
          border-radius: 50%;
          color: red;
          background-color: white;
          width: 15px;
          height: 15px;
          display: none;
        }
        .remove-bookmark-icon:hover {
          transition: 0.5s;
          -moz-transition: 0.5s;
          border-radius: 50%;
          color: white;
          background-color: red;
        }
        .show-remove-bookmark-btn {
          display: block;
        }
      }
    }
  }
  .bookmarks-wrapper:hover {
    pointer-events: auto;
    position: absolute;
    overflow-y: hidden;
    overflow-x: scroll;
  }
}

#bookmarkPopup {
  min-width: 200px;
  max-width: 200px;
  max-height: 200px;
  position: absolute;
  bottom: 35px;
  left: -40px;
  //left: 50%;
  z-index: 99999;
  border-radius: 10px;
  display: inline-block;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#bookmarkPopup .pop {
  display: inline-block;
  max-width: 200px;
  cursor: pointer;
  background-color: white;
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
  padding: 5px;
  border-radius: 10px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: normal;
}
/* Toggle this class - hide and show the popup */
#bookmarkPopup .pop .show {
  z-index: 99999;
  visibility: visible;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 2s;
}

/* Add animation (fade in the popup) */
@-webkit-keyframes fadeIn {
  from {opacity: 0;}
  to {opacity: 1;}
}

@keyframes fadeIn {
  from {opacity: 0;}
  to {opacity:1 ;}
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scroll {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.bookmarks {
  width: 100%;
  background-color: #2c3e50;
  color: #3d3d3d;
  padding-left: 3%;
  padding-right: 3%;
  padding-bottom: 2px;
  overflow-x: visible;
  z-index: 999999;
}

.bookmarks .bookmark {
  //position: absolute;
  overflow-x: hidden;
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-left: 10px;
  margin-right: 10px;
  transform: scale(1);
  transition: 0.5s;
  -moz-transition: 0.5s;
}

.bookmarks .bookmark:hover {
  cursor: pointer;
  overflow-x: visible;
  transform: scale(1.2);
  transition: 0.5s;
  -moz-transition: 0.5s;
}

.bookmarks .bookmark .img-bookmark {
  border-radius: 50%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
}

.price-container {
  font-size: small;
}

.prop-type-container {
  font-size: smaller;
}

.address-container {
  font-size: smaller;
}

.sticky-bottom {
  position: sticky;
  position: -webkit-sticky;
  bottom: 0;
  z-index: 99998;
}

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {

}
@media screen and (max-width: 500px) {
  #bookmarkPopup {
    display: none;
    left: 0;
  }
}
@media screen and (min-width: 501px) {
  #bookmarkPopup {
    display: none;
    left: 0;
  }
}
@media screen and (min-width: 769px) {
  #bookmarkPopup {
    display: inline-block;
    left: -40px;
  }
}
@media screen and (min-width: 992px) {

}
@media screen and (min-width: 1200px) {

}
@media screen and (min-width: 1600px) {

}
@media screen and (min-width: 1920px) {

}

</style>


<!--// html = html + "<div class=\"col-12 text-start\">";-->
<!--// html = html + "<div class=\"row m-0 p-0 ps-2 pe-2\">";-->
<!--// html = html + "<div class=\"col-12 text-center m-0 p-0\">";-->
<!--// html = html + "<p class=\"m-1 p-0\">";-->
<!--// console.log(jsonMarkersArr[i].properties[markersKeys[g]]);-->
<!--// console.log(jsonMarkersArr[i].properties[markersKeys[g]].type);-->
<!--// if (jsonMarkersArr[i].properties[markersKeys[g]].type === 'Sale') {-->
<!--//   html = html + "<span class=\"p-1 category-label\"\n" +-->
<!--//       " style=\"color: green; background-color: lightgreen;\">FOR SALE</span>"-->
<!--// }-->
<!--// if (jsonMarkersArr[i].properties[markersKeys[g]].type === 'Let') {-->
<!--//   html = html + "<span class=\"p-1 category-label\"\n" +-->
<!--//       " style=\"color: white; background-color: deepskyblue;\">TO LET</span>"-->
<!--// }-->
<!--// if (jsonMarkersArr[i].properties[markersKeys[g]].type === 'Buy') {-->
<!--//   html = html + "<span class=\"p-1 category-label\"\n" +-->
<!--//       " style=\"color: green; background-color: lightgreen;\">TO BUY</span>"-->
<!--// }-->
<!--// if (jsonMarkersArr[i].properties[markersKeys[g]].type === 'Rent') {-->
<!--//   html = html + "<span class=\"p-1 category-label\"\n" +-->
<!--//       " style=\"color: white; background-color: deepskyblue;\">TO RENT</span>"-->
<!--// }-->
<!--// html = html + "</p>";-->
<!--// html = html + "</div>";-->
<!--// html = html + "</div>";-->