import axios from 'axios'

export default {
    namespaced: true,
    state:{
        loading:false,
        services: {},
        errors: {},
        services_map: {},
        errors_map: {}
    },
    getters:{
        loading(state){
            return state.loading
        },
        services(state){
            return state.services
        },
        errors(state){
            return state.errors
        },
        services_map(state){
            return state.services_map
        },
        errors_map(state){
            return state.errors_map
        }
    },
    mutations:{
        SET_LOADING (state, value) {
            state.loading = value
        },
        SET_SERVICES (state, value) {
            state.services = value
        },
        SET_ERRORS (state, value) {
            state.errors = value
        },
        SET_SERVICES_MAP (state, value) {
            state.services_map = value
        },
        SET_ERRORS_MAP (state, value) {
            state.errors_map = value
        }
    },
    actions:{
        async getServices({commit}, payload){
            commit('SET_LOADING',true)
            // console.log(payload);
            return axios.post('api/services-list', payload).then(({data})=>{
                commit('SET_SERVICES',data)
                commit('SET_LOADING',false)
            }).catch(({response:{data}})=>{
                commit('SET_SERVICES',{})
                commit('SET_ERRORS', data.errors)
                commit('SET_LOADING',false)
            })
        },
        async getServicesMap({commit}, payload){
            commit('SET_LOADING',true)
            return axios.post('api/services-map', payload).then(({data})=>{
                commit('SET_SERVICES_MAP',data)
                commit('SET_LOADING',false)
            }).catch(({response:{data}})=>{
                commit('SET_SERVICES_MAP',{})
                commit('SET_ERRORS_MAP', data.errors)
                commit('SET_LOADING',false)
            })
        },
        resetErrors({commit}) {
            commit('SET_ERRORS', {})
            commit('SET_ERRORS_MAP', {})
        }
    }
}