<template>
  <div class="modal fade" id="addRoomModal" data-bs-backdrop="true" tabindex="-1" aria-labelledby="addRoomModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-border">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="addRoomModalLabel">Add Room</h5>
            <button type="button" class="btn btn-outline-light close close-btn" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body scroll-width-thin">
            <!-- Loading -->
            <Loader class="mt-2" :loading="loading" :small="false"></Loader>

            <ValidationObserver ref="addRoomFormObserver" v-slot="{ handleSubmit }">
            <form v-if="!loading" action="javascript:void(0)" class="row text-start" enctype="multipart/form-data" method="post">
              <div class="row m-0 p-0">
                <div class="form-group col-12 pb-2 ps-5 pe-5">
                  <label class="pb-2">Please choose space type<span class="required"> *</span></label>
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <select
                        v-model="form.room_type_id"
                        class="form-select select-placeholder cursor-pointer"
                        @focus="handleFocus($event)"
                        @focusout="handleFocusOut(form.room_type_id, $event)"
                        tabindex="-1"
                        aria-label="room_type_id"
                        name="room_type_id"
                        id="roomType"
                    >
                      <option value="" disabled>Select space type</option>
                      <option :value="1">Single Room</option>
                      <option :value="2">Double Room</option>
                      <option :value="3">Twin Room</option>
                      <option :value="4">Twin Double Room</option>
                      <option :value="5">Triple Room</option>
                      <option :value="6">Quadruple Room</option>
                      <option :value="7">Family Room</option>
                      <option v-if="!formData.is_room" :disabled="checkSuiteExists(formData)" :value="8">Whole Place (Suite/Apartment)</option>
                    </select>
                    <span class="red-text"><small>{{ errors[0] }}</small></span>
                  </ValidationProvider>
                </div>
              </div>

              <div v-if="form.room_type_id && form.room_type_id === 8" class="row m-0 p-0">
                <div class="form-group col-12 text-center">
                  <!-- Start Horizontal Line with Icon -->
                  <div class="horizontal-separator-box">
                    <span class="hr-line-short"></span>
                    <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                    <span class="hr-line-short"></span>
                  </div>
                  <!-- End Horizontal Line with Icon -->

                  <!-- Information -->
                  <small class="p-3 fw-bold">Rooms</small>

                  <!-- Start Horizontal Line with Icon -->
                  <div class="horizontal-separator-box">
                    <span class="hr-line-short"></span>
                    <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                    <span class="hr-line-short"></span>
                  </div>
                  <!-- End Horizontal Line with Icon -->

                  <!-- Information -->
                  <small class="p-3">Please assign at least one room in the premises</small>

                  <!-- Start Horizontal Line with Icon -->
                  <div class="horizontal-separator-box">
                    <span class="hr-line-short"></span>
                    <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                    <span class="hr-line-short"></span>
                  </div>
                  <!-- End Horizontal Line with Icon -->
                </div>
              </div>

              <!-- Single and Double Rooms for Whole Place Suite/Apartment option -->
              <div v-if="form.room_type_id && form.room_type_id === 8" class="row m-0 p-0 ps-5 pe-5">
                <!-- Single Rooms -->
                <div class="col-7 col-xs-7 col-sm-7 col-md-4 col-lg-4 pt-2 mb-1">
                  <div class="list-relative">
                    <span data-tooltip="tooltip">
                      <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                        <p class="options-paragraph m-0 p-0 me-2 d-inline">Single Rooms</p>
                        <font-awesome-icon icon="dice-one" class="icon-fa" />
                        <font-awesome-icon icon="bed" class="icon-fa" />
                        <span class="tooltiptext">How many single rooms are in this premises?</span>
                      </div>
                    </span>
                  </div>
                </div>
                <div class="col-5 col-xs-5 col-sm-5 col-md-2 col-lg-2 mb-1">
                  <ValidationProvider :rules="{required: atLeastOneRequired, min_value: 1}" v-slot="{ errors }">
                    <input
                        type="number"
                        placeholder="..."
                        min="0"
                        max="9999"
                        v-model="form.single_beds"
                        class="options-input options-price-input select-placeholder"
                        @focus="handleFocus($event)"
                        @focusout="handleFocusOut(form.single_beds, $event)"
                        data-vv-as="single_beds_rq"
                        name="single_beds_rq"
                    >
                    <span class="red-text"><small>{{ errors[0] }}</small></span>
                  </ValidationProvider>
                </div>
                <!-- Double Rooms -->
                <div class="col-7 col-xs-7 col-sm-7 col-md-4 col-lg-4 pt-2 mb-1">
                  <div class="list-relative">
                    <span data-tooltip="tooltip">
                      <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                        <p class="options-paragraph m-0 p-0 me-2 d-inline">Double Rooms</p>
                        <font-awesome-icon icon="dice-two" class="icon-fa" />
                        <font-awesome-icon icon="bed" class="icon-fa" />
                        <span class="tooltiptext">How many double rooms are in this premises?</span>
                      </div>
                    </span>
                  </div>
                </div>
                <div class="col-5 col-xs-5 col-sm-5 col-md-2 mb-1">
                  <ValidationProvider :rules="{required: atLeastOneRequired, min_value: 1}" v-slot="{ errors }">
                    <input
                        type="number"
                        placeholder="..."
                        min="0"
                        max="9999"
                        v-model="form.double_beds"
                        class="options-input options-price-input select-placeholder"
                        @focus="handleFocus($event)"
                        @focusout="handleFocusOut(form.double_beds, $event)"
                        data-vv-as="double_beds_rq"
                        name="double_beds_rq"
                    >
                    <span class="red-text"><small>{{ errors[0] }}</small></span>
                  </ValidationProvider>
                </div>
              </div>

              <div v-if="form.room_type_id && form.room_type_id !== 8" class="row m-0 p-0">
                <div class="form-group col-12 text-center">
                  <!-- Start Horizontal Line with Icon -->
                  <div class="horizontal-separator-box">
                    <span class="hr-line-short"></span>
                    <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                    <span class="hr-line-short"></span>
                  </div>
                  <!-- End Horizontal Line with Icon -->

                  <!-- Information -->
                  <small class="p-3 fw-bold">Beds</small>

                  <!-- Start Horizontal Line with Icon -->
                  <div class="horizontal-separator-box">
                    <span class="hr-line-short"></span>
                    <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                    <span class="hr-line-short"></span>
                  </div>
                  <!-- End Horizontal Line with Icon -->

                  <!-- Information -->
                  <small class="p-3">Please assign at least one bed in the room</small>

                  <!-- Start Horizontal Line with Icon -->
                  <div class="horizontal-separator-box">
                    <span class="hr-line-short"></span>
                    <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                    <span class="hr-line-short"></span>
                  </div>
                  <!-- End Horizontal Line with Icon -->
                </div>
              </div>

              <!-- Bed options for other than 8 - Whole Place -->
              <div v-if="form.room_type_id && form.room_type_id !== 8" class="row m-0 p-0 ps-5 pe-5">
                <!-- Single Beds -->
                <div class="col-7 col-xs-7 col-sm-7 col-md-4 col-lg-4 pt-2 mb-1">
                  <div class="list-relative">
                    <span data-tooltip="tooltip">
                      <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                        <p class="options-paragraph m-0 p-0 me-2 d-inline">Single Beds</p>
                        <font-awesome-icon icon="dice-one" class="icon-fa" />
                        <font-awesome-icon icon="bed" class="icon-fa" />
                        <span class="tooltiptext">How many single beds are in this room?</span>
                      </div>
                    </span>
                  </div>
                </div>
                <div class="col-5 col-xs-5 col-sm-5 col-md-2 mb-1">
                  <ValidationProvider :rules="{required: atLeastOneRequired, min_value: 1}" v-slot="{ errors }">
                  <input
                      type="number"
                      placeholder="..."
                      min="0"
                      max="9999"
                      v-model="form.single_beds"
                      class="options-input options-price-input select-placeholder"
                      @focus="handleFocus($event)"
                      @focusout="handleFocusOut(form.single_beds, $event)"
                      data-vv-as="single_beds_rq"
                      name="single_beds_rq"
                  >
                  <span class="red-text"><small>{{ errors[0] }}</small></span>
                  </ValidationProvider>
                </div>
                <!-- Double Beds -->
                <div class="col-7 col-xs-7 col-sm-7 col-md-4 col-lg-4 pt-2 mb-1">
                  <div class="list-relative">
                    <span data-tooltip="tooltip">
                      <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                        <p class="options-paragraph m-0 p-0 me-2 d-inline">Double Beds</p>
                        <font-awesome-icon icon="dice-two" class="icon-fa" />
                        <font-awesome-icon icon="bed" class="icon-fa" />
                        <span class="tooltiptext">How many double beds are in this room?</span>
                      </div>
                    </span>
                  </div>
                </div>
                <div class="col-5 col-xs-5 col-sm-5 col-md-2 mb-1">
                  <ValidationProvider :rules="{required: atLeastOneRequired, min_value: 1}" v-slot="{ errors }">
                  <input
                      type="number"
                      placeholder="..."
                      min="0"
                      max="9999"
                      v-model="form.double_beds"
                      class="options-input options-price-input select-placeholder"
                      @focus="handleFocus($event)"
                      @focusout="handleFocusOut(form.double_beds, $event)"
                      data-vv-as="double_beds_rq"
                      name="double_beds_rq"
                  >
                  <span class="red-text"><small>{{ errors[0] }}</small></span>
                  </ValidationProvider>
                </div>
                <!-- Queen Beds -->
                <div class="col-7 col-xs-7 col-sm-7 col-md-4 col-lg-4 pt-2 mb-1">
                  <div class="list-relative">
                    <span data-tooltip="tooltip">
                      <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                        <p class="options-paragraph m-0 p-0 me-2 d-inline">Queen Beds</p>
                        <font-awesome-icon icon="chess-queen" class="icon-fa" />
                        <font-awesome-icon icon="bed" class="icon-fa" />
                        <span class="tooltiptext">How many queen beds are in this room?</span>
                      </div>
                    </span>
                  </div>
                </div>
                <div class="col-5 col-xs-5 col-sm-5 col-md-2 mb-1">
                  <ValidationProvider :rules="{required: atLeastOneRequired, min_value: 1}" v-slot="{ errors }">
                  <input
                      type="number"
                      placeholder="..."
                      min="0"
                      max="9999"
                      v-model="form.queen_beds"
                      class="options-input options-price-input select-placeholder"
                      @focus="handleFocus($event)"
                      @focusout="handleFocusOut(form.queen_beds, $event)"
                  >
                  <span class="red-text"><small>{{ errors[0] }}</small></span>
                  </ValidationProvider>
                </div>
                <!-- King Beds -->
                <div class="col-7 col-xs-7 col-sm-7 col-md-4 col-lg-4 pt-2 mb-1">
                  <div class="list-relative">
                    <span data-tooltip="tooltip">
                      <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                        <p class="options-paragraph m-0 p-0 me-2 d-inline">King Beds</p>
                        <font-awesome-icon icon="chess-king" class="icon-fa" />
                        <font-awesome-icon icon="bed" class="icon-fa" />
                        <span class="tooltiptext">How many king beds are in this room?</span>
                      </div>
                    </span>
                  </div>
                </div>
                <div class="col-5 col-xs-5 col-sm-5 col-md-2 mb-1">
                  <ValidationProvider :rules="{required: atLeastOneRequired, min_value: 1}" v-slot="{ errors }">
                  <input
                      type="number"
                      placeholder="..."
                      min="0"
                      max="9999"
                      v-model="form.king_beds"
                      class="options-input options-price-input select-placeholder"
                      @focus="handleFocus($event)"
                      @focusout="handleFocusOut(form.king_beds, $event)"
                  >
                  <span class="red-text"><small>{{ errors[0] }}</small></span>
                  </ValidationProvider>
                </div>
                <!-- Zip and Link Beds -->
                <div class="col-7 col-xs-7 col-sm-7 col-md-4 col-lg-4 pt-2 mb-1">
                  <div class="list-relative">
                    <span data-tooltip="tooltip">
                      <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                        <p class="options-paragraph m-0 p-0 me-2 d-inline">Zink and Link Beds</p>
                        <font-awesome-icon icon="link" class="icon-fa" />
                        <font-awesome-icon icon="bed" class="icon-fa" />
                        <span class="tooltiptext">How many zip and link beds are in this room?</span>
                      </div>
                    </span>
                  </div>
                </div>
                <div class="col-5 col-xs-5 col-sm-5 col-md-2 mb-1">
                  <ValidationProvider :rules="{required: atLeastOneRequired, min_value: 1}" v-slot="{ errors }">
                  <input
                      type="number"
                      placeholder="..."
                      min="0"
                      max="9999"
                      v-model="form.zip_and_link_beds"
                      class="options-input options-price-input select-placeholder"
                      @focus="handleFocus($event)"
                      @focusout="handleFocusOut(form.zip_and_link_beds, $event)"
                  >
                  <span class="red-text"><small>{{ errors[0] }}</small></span>
                  </ValidationProvider>
                </div>
                <!-- Water Beds -->
                <div class="col-7 col-xs-7 col-sm-7 col-md-4 col-lg-4 pt-2 mb-1">
                  <div class="list-relative">
                    <span data-tooltip="tooltip">
                      <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                        <p class="options-paragraph m-0 p-0 me-2 d-inline">Water Beds</p>
                        <font-awesome-icon icon="water" class="icon-fa" />
                        <font-awesome-icon icon="bed" class="icon-fa" />
                        <span class="tooltiptext">How many water beds are in this room?</span>
                      </div>
                    </span>
                  </div>
                </div>
                <div class="col-5 col-xs-5 col-sm-5 col-md-2 mb-1">
                  <ValidationProvider :rules="{required: atLeastOneRequired, min_value: 1}" v-slot="{ errors }">
                  <input
                      type="number"
                      placeholder="..."
                      min="0"
                      max="9999"
                      v-model="form.water_beds"
                      class="options-input options-price-input select-placeholder"
                      @focus="handleFocus($event)"
                      @focusout="handleFocusOut(form.water_beds, $event)"
                  >
                  <span class="red-text"><small>{{ errors[0] }}</small></span>
                  </ValidationProvider>
                </div>
                <!-- Sofa Beds -->
                <div class="col-7 col-xs-7 col-sm-7 col-md-4 col-lg-4 pt-2 mb-1">
                  <div class="list-relative">
                    <span data-tooltip="tooltip">
                      <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                        <p class="options-paragraph m-0 p-0 me-2 d-inline">Sofa Beds</p>
                        <font-awesome-icon icon="couch" class="icon-fa" />
                        <span class="tooltiptext">How many sofa beds are in this room?</span>
                      </div>
                    </span>
                  </div>
                </div>
                <div class="col-5 col-xs-5 col-sm-5 col-md-2 mb-1">
                  <ValidationProvider :rules="{required: atLeastOneRequired, min_value: 1}" v-slot="{ errors }">
                  <input
                      type="number"
                      placeholder="..."
                      min="0"
                      max="9999"
                      v-model="form.sofa_beds"
                      class="options-input options-price-input select-placeholder"
                      @focus="handleFocus($event)"
                      @focusout="handleFocusOut(form.sofa_beds, $event)"
                  >
                  <span class="red-text"><small>{{ errors[0] }}</small></span>
                  </ValidationProvider>
                </div>
                <!-- Bunk Beds -->
                <div class="col-7 col-xs-7 col-sm-7 col-md-4 col-lg-4 pt-2 mb-1">
                  <div class="list-relative">
                    <span data-tooltip="tooltip">
                      <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                        <p class="options-paragraph m-0 p-0 me-2 d-inline">Bunk Beds</p>
                        <font-awesome-icon icon="layer-group" class="icon-fa" />
                        <font-awesome-icon icon="bed" class="icon-fa" />
                        <span class="tooltiptext">How many bunk beds are in this room?</span>
                      </div>
                    </span>
                  </div>
                </div>
                <div class="col-5 col-xs-5 col-sm-5 col-md-2 mb-1">
                  <ValidationProvider :rules="{required: atLeastOneRequired, min_value: 1}" v-slot="{ errors }">
                  <input
                      type="number"
                      placeholder="..."
                      min="0"
                      max="9999"
                      v-model="form.bunk_beds"
                      class="options-input options-price-input select-placeholder"
                      @focus="handleFocus($event)"
                      @focusout="handleFocusOut(form.bunk_beds, $event)"
                  >
                  <span class="red-text"><small>{{ errors[0] }}</small></span>
                  </ValidationProvider>
                </div>
                <!-- Toddler Beds -->
                <div class="col-7 col-xs-7 col-sm-7 col-md-4 col-lg-4 pt-2 mb-1">
                  <div class="list-relative">
                    <span data-tooltip="tooltip">
                      <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                        <p class="options-paragraph m-0 p-0 me-2 d-inline">Toddler Beds</p>
                        <font-awesome-icon icon="baby" class="icon-fa" />
                        <span class="tooltiptext">How many toddler beds are in this room?</span>
                      </div>
                    </span>
                  </div>
                </div>
                <div class="col-5 col-xs-5 col-sm-5 col-md-2 mb-1">
                  <ValidationProvider :rules="{required: atLeastOneRequired, min_value: 1}" v-slot="{ errors }">
                  <input
                      type="number"
                      placeholder="..."
                      min="0"
                      max="9999"
                      v-model="form.toddler_beds"
                      class="options-input options-price-input select-placeholder"
                      @focus="handleFocus($event)"
                      @focusout="handleFocusOut(form.toddler_beds, $event)"
                  >
                  <span class="red-text"><small>{{ errors[0] }}</small></span>
                  </ValidationProvider>
                </div>
                <!-- Cribs -->
                <div class="col-7 col-xs-7 col-sm-7 col-md-4 col-lg-4 pt-2 mb-1">
                  <div class="list-relative">
                    <span data-tooltip="tooltip">
                      <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                        <p class="options-paragraph m-0 p-0 me-2 d-inline">Cribs</p>
                        <font-awesome-icon icon="baby-carriage" class="icon-fa" />
                        <span class="tooltiptext">How many cribs are in this room?</span>
                      </div>
                    </span>
                  </div>
                </div>
                <div class="col-5 col-xs-5 col-sm-5 col-md-2 mb-1">
                  <ValidationProvider :rules="{required: atLeastOneRequired, min_value: 1}" v-slot="{ errors }">
                  <input
                      type="number"
                      placeholder="..."
                      min="0"
                      max="9999"
                      v-model="form.cribs"
                      class="options-input options-price-input select-placeholder"
                      @focus="handleFocus($event)"
                      @focusout="handleFocusOut(form.cribs, $event)"
                  >
                  <span class="red-text"><small>{{ errors[0] }}</small></span>
                  </ValidationProvider>
                </div>
                <!-- Hammock Beds -->
                <div class="col-7 col-xs-7 col-sm-7 col-md-4 col-lg-4 pt-2 mb-1">
                  <div class="list-relative">
                    <span data-tooltip="tooltip">
                      <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                        <p class="options-paragraph m-0 p-0 me-2 d-inline">Hammocks</p>
                        <font-awesome-icon icon="feather" class="icon-fa" />
                        <span class="tooltiptext">How many hammocks are in this room?</span>
                      </div>
                    </span>
                  </div>
                </div>
                <div class="col-5 col-xs-5 col-sm-5 col-md-2 mb-1">
                  <ValidationProvider :rules="{required: atLeastOneRequired, min_value: 1}" v-slot="{ errors }">
                  <input
                      type="number"
                      placeholder="..."
                      min="0"
                      max="9999"
                      v-model="form.hammocks"
                      class="options-input options-price-input select-placeholder"
                      @focus="handleFocus($event)"
                      @focusout="handleFocusOut(form.hammocks, $event)"
                  >
                  <span class="red-text"><small>{{ errors[0] }}</small></span>
                  </ValidationProvider>
                </div>
              </div>

              <div v-if="form.room_type_id" class="row m-0 p-0">
                <div class="form-group col-12 text-center">
                  <!-- Start Horizontal Line with Icon -->
                  <div class="horizontal-separator-box">
                    <span class="hr-line-short"></span>
                    <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                    <span class="hr-line-short"></span>
                  </div>
                  <!-- End Horizontal Line with Icon -->

                  <!-- Information -->
                  <small class="p-3 fw-bold">Basic Details</small>

                  <!-- Start Horizontal Line with Icon -->
                  <div class="horizontal-separator-box">
                    <span class="hr-line-short"></span>
                    <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                    <span class="hr-line-short"></span>
                  </div>
                  <!-- End Horizontal Line with Icon -->
                </div>
              </div>

              <div v-if="form.room_type_id" class="row m-0 p-0 ps-5 pe-5">
                <!-- Price -->
                <div class="col-12 p-0 mt-1 mb-1">
                  <div class="row m-0 p-0">
                    <div class="col-6 col-xs-6 col-sm-6 col-md-3 col-lg-3 pt-2">
                      <div class="list-relative">
                        <span data-tooltip="tooltip">
                          <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                            <p class="options-paragraph m-0 p-0 me-2 d-inline">Price</p>
                            <font-awesome-icon icon="pound-sign" class="icon-fa" />
                            <span class="required"> *</span>
                            <span class="tooltiptext">What is the price for this room/space?</span>
                          </div>
                        </span>
                      </div>
                    </div>
                    <div class="col-6 col-xs-6 col-sm-6 col-md-3 col-lg-3">
                      <ValidationProvider rules="required|min_value:1" v-slot="{ errors }">
                      <input
                          type="number"
                          placeholder="£"
                          min="0"
                          max="999999999999"
                          v-model="form.price"
                          class="options-input options-price-input select-placeholder"
                          @focus="handleFocus($event)"
                          @focusout="handleFocusOut(form.price, $event)"
                      >
                      <span class="red-text"><small>{{ errors[0] }}</small></span>
                      </ValidationProvider>
                    </div>
                    <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                      <select
                          v-model="form.property_rent_type_id"
                          class="options-select form-select select-placeholder cursor-pointer"
                          @focus="handleFocus($event)"
                          @focusout="handleFocusOut(form.property_rent_type_id, $event)"
                          tabindex="-1"
                          aria-label="propertyRentType"
                          name="property-rent-type"
                          id="propertyRentType"
                      >
                        <option value="" disabled>Select payment frequency</option>
                        <option v-if="form.room_type_id && formData.is_short_term" :value="2">Per Hour</option>
                        <option v-if="form.room_type_id && formData.is_short_term" :value="3">Per Night</option>
                        <option v-if="form.room_type_id && !formData.is_short_term" :value="4">Per Week</option>
                        <option v-if="form.room_type_id && !formData.is_short_term" :value="5">Per Month</option>
                      </select>
                      <span class="red-text"><small>{{ errors[0] }}</small></span>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>
                <!-- Max Guests/Tenants -->
                <div class="col-12 p-0 mt-1 mb-1">
                  <div class="row m-0 p-0">
                    <div class="col-6 pt-2">
                      <div class="list-relative">
                        <span data-tooltip="tooltip">
                          <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                            <p class="options-paragraph m-0 p-0 me-2 d-inline">{{formData.is_short_term ? 'Max Guests' : 'Max Tenants'}}</p>
                            <font-awesome-icon icon="user-friends" class="icon-fa" />
                            <span class="required"> *</span>
                            <span v-if="formData.is_short_term" class="tooltiptext">What is the maximum guests allowed in that room/space?</span>
                            <span v-else class="tooltiptext">What is the maximum tenants allowed in that room/space?</span>
                          </div>
                        </span>
                      </div>
                    </div>
                    <div class="col-6">
                      <ValidationProvider rules="required|min_value:1" v-slot="{ errors }">
                      <input
                          type="number"
                          placeholder="..."
                          min="0"
                          max="999"
                          v-model="form.guests"
                          class="options-input options-price-input select-placeholder"
                          @focus="handleFocus($event)"
                          @focusout="handleFocusOut(form.guests, $event)"
                      >
                      <span class="red-text"><small>{{ errors[0] }}</small></span>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>
                <!-- Quantity (of Rooms) -->
                <div class="col-12 p-0 mt-1 mb-1">
                  <div class="row m-0 p-0">
                    <div class="col-6 pt-2">
                      <div class="list-relative">
                        <span data-tooltip="tooltip">
                          <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                            <p class="options-paragraph m-0 p-0 me-2 d-inline">Quantity</p>
                            <font-awesome-icon icon="calculator" class="icon-fa" />
                            <span class="required"> *</span>
                            <span class="tooltiptext">How many of similar rooms do you want to advertise?</span>
                          </div>
                        </span>
                      </div>
                    </div>
                    <div class="col-6">
                      <ValidationProvider rules="required|min_value:1" v-slot="{ errors }">
                        <input
                            type="number"
                            placeholder="..."
                            min="0"
                            max="999"
                            v-model="form.quantity"
                            class="options-input options-price-input select-placeholder"
                            @focus="handleFocus($event)"
                            @focusout="handleFocusOut(form.quantity, $event)"
                        >
                        <span class="red-text"><small>{{ errors[0] }}</small></span>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="form.room_type_id && form.room_type_id === 8" class="row m-0 p-0">
                <div class="form-group col-12 text-center">
                  <!-- Start Horizontal Line with Icon -->
                  <div class="horizontal-separator-box">
                    <span class="hr-line-short"></span>
                    <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                    <span class="hr-line-short"></span>
                  </div>
                  <!-- End Horizontal Line with Icon -->

                  <!-- Information -->
                  <small class="p-3 fw-bold">Please click 'Add' button and specify rest of premises details with a normal flow of the form</small>

                  <!-- Start Horizontal Line with Icon -->
                  <div class="horizontal-separator-box">
                    <span class="hr-line-short"></span>
                    <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                    <span class="hr-line-short"></span>
                  </div>
                  <!-- End Horizontal Line with Icon -->
                </div>
              </div>

              <div v-if="form.room_type_id && form.room_type_id !== 8" class="row m-0 p-0">
                <div class="form-group col-12 text-center">
                  <!-- Start Horizontal Line with Icon -->
                  <div class="horizontal-separator-box">
                    <span class="hr-line-short"></span>
                    <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                    <span class="hr-line-short"></span>
                  </div>
                  <!-- End Horizontal Line with Icon -->

                  <!-- Information -->
                  <small class="p-3 fw-bold">Kitchen Like Extras</small>

                  <!-- Start Horizontal Line with Icon -->
                  <div class="horizontal-separator-box">
                    <span class="hr-line-short"></span>
                    <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                    <span class="hr-line-short"></span>
                  </div>
                  <!-- End Horizontal Line with Icon -->
                </div>
              </div>

              <!-- Start Kitchen Like & Extra Items Switches -->
              <div v-if="form.room_type_id && form.room_type_id !== 8" class="row m-0 p-0 ps-5 pe-5">
                <div class="col-12 p-0 mt-1 mb-1">
                  <div class="row m-0 p-0">
                    <!-- Fridge -->
                    <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 p-0 mt-1 mb-1">
                      <div class="row m-0 p-0">
                        <div class="col-8">
                          <div class="list-relative">
                            <span data-tooltip="tooltip">
                              <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                <p class="options-paragraph m-0 p-0 me-2 d-inline">Fridge</p>
                                <font-awesome-icon icon="hamburger" class="icon-fa" />
                                <span class="tooltiptext">Is there fridge in the room?</span>
                              </div>
                            </span>
                          </div>
                        </div>
                        <div class="col-4">
                          <label class="switch">
                            <input type="checkbox" v-model="form.fridge">
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <!-- Freezer -->
                    <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 p-0 mt-1 mb-1">
                      <div class="row m-0 p-0">
                        <div class="col-8">
                          <div class="list-relative">
                            <span data-tooltip="tooltip">
                              <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                <p class="options-paragraph m-0 p-0 me-2 d-inline">Freezer</p>
                                <font-awesome-icon icon="snowflake" class="icon-fa" />
                                <span class="tooltiptext">Is there freezer in the room?</span>
                              </div>
                            </span>
                          </div>
                        </div>
                        <div class="col-4">
                          <label class="switch">
                            <input type="checkbox" v-model="form.freezer">
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <!-- Microwave -->
                    <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 p-0 mt-1 mb-1">
                      <div class="row m-0 p-0">
                        <div class="col-8">
                          <div class="list-relative">
                            <span data-tooltip="tooltip">
                              <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                <p class="options-paragraph m-0 p-0 me-2 d-inline">Microwave</p>
                                <font-awesome-icon icon="pager" class="icon-fa" />
                                <span class="tooltiptext">Is there microwave in the room?</span>
                              </div>
                            </span>
                          </div>
                        </div>
                        <div class="col-4">
                          <label class="switch">
                            <input type="checkbox" v-model="form.microwave">
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <!-- Kettle -->
                    <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 p-0 mt-1 mb-1">
                      <div class="row m-0 p-0">
                        <div class="col-8">
                          <div class="list-relative">
                            <span data-tooltip="tooltip">
                              <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                <p class="options-paragraph m-0 p-0 me-2 d-inline">Kettle</p>
                                <font-awesome-icon icon="mug-hot" class="icon-fa" />
                                <span class="tooltiptext">Is there kettle in the room?</span>
                              </div>
                            </span>
                          </div>
                        </div>
                        <div class="col-4">
                          <label class="switch">
                            <input type="checkbox" v-model="form.kettle">
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <!-- Coffee -->
                    <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 p-0 mt-1 mb-1">
                      <div class="row m-0 p-0">
                        <div class="col-8">
                          <div class="list-relative">
                            <span data-tooltip="tooltip">
                              <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                <p class="options-paragraph m-0 p-0 me-2 d-inline">Coffee</p>
                                <font-awesome-icon icon="coffee" class="icon-fa" />
                                <span class="tooltiptext">Is there coffee in the room?</span>
                              </div>
                            </span>
                          </div>
                        </div>
                        <div class="col-4">
                          <label class="switch">
                            <input type="checkbox" v-model="form.coffee">
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <!-- Toaster -->
                    <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 p-0 mt-1 mb-1">
                      <div class="row m-0 p-0">
                        <div class="col-8">
                          <div class="list-relative">
                            <span data-tooltip="tooltip">
                              <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                <p class="options-paragraph m-0 p-0 me-2 d-inline">Toaster</p>
                                <font-awesome-icon icon="bread-slice" class="icon-fa" />
                                <span class="tooltiptext">Is there toaster in the room?</span>
                              </div>
                            </span>
                          </div>
                        </div>
                        <div class="col-4">
                          <label class="switch">
                            <input type="checkbox" v-model="form.toaster">
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- End Kitchen Like & Extra Items Switches -->

              <div v-if="form.room_type_id && form.room_type_id !== 8" class="row m-0 p-0">
                <div class="form-group col-12 text-center">
                  <!-- Start Horizontal Line with Icon -->
                  <div class="horizontal-separator-box">
                    <span class="hr-line-short"></span>
                    <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                    <span class="hr-line-short"></span>
                  </div>
                  <!-- End Horizontal Line with Icon -->

                  <!-- Information -->
                  <small class="p-3 fw-bold">En-suite & Extras</small>

                  <!-- Start Horizontal Line with Icon -->
                  <div class="horizontal-separator-box">
                    <span class="hr-line-short"></span>
                    <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                    <span class="hr-line-short"></span>
                  </div>
                  <!-- End Horizontal Line with Icon -->
                </div>
              </div>

              <!-- Start Toilet and Extras Switches -->
              <div v-if="form.room_type_id && form.room_type_id !== 8" class="row m-0 p-0 ps-5 pe-5">
                <div class="col-12 p-0 mt-1 mb-1">
                  <div class="row m-0 p-0">
                    <!-- WC -->
                    <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 p-0 mt-1 mb-1">
                      <div class="row m-0 p-0">
                        <div class="col-8">
                          <div class="list-relative">
                            <span data-tooltip="tooltip">
                              <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                <p class="options-paragraph m-0 p-0 me-2 d-inline">WC</p>
                                <font-awesome-icon icon="toilet" class="icon-fa" />
                                <span class="tooltiptext">Is there wc in the room?</span>
                              </div>
                            </span>
                          </div>
                        </div>
                        <div class="col-4">
                          <label class="switch">
                            <input type="checkbox" v-model="form.wc">
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <!-- Sink -->
                    <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 p-0 mt-1 mb-1">
                      <div class="row m-0 p-0">
                        <div class="col-8">
                          <div class="list-relative">
                            <span data-tooltip="tooltip">
                              <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                <p class="options-paragraph m-0 p-0 me-2 d-inline">Sink</p>
                                <font-awesome-icon icon="sink" />
                                <span class="tooltiptext">Is there sink in the room?</span>
                              </div>
                            </span>
                          </div>
                        </div>
                        <div class="col-4">
                          <label class="switch">
                            <input type="checkbox" v-model="form.sink">
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <!-- Bath -->
                    <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 p-0 mt-1 mb-1">
                      <div class="row m-0 p-0">
                        <div class="col-8">
                          <div class="list-relative">
                            <span data-tooltip="tooltip">
                              <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                <p class="options-paragraph m-0 p-0 me-2 d-inline">Bath</p>
                                <font-awesome-icon icon="bath" />
                                <span class="tooltiptext">Is there bath in the room?</span>
                              </div>
                            </span>
                          </div>
                        </div>
                        <div class="col-4">
                          <label class="switch">
                            <input type="checkbox" v-model="form.bath">
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <!-- Shower -->
                    <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 p-0 mt-1 mb-1">
                      <div class="row m-0 p-0">
                        <div class="col-8">
                          <div class="list-relative">
                            <span data-tooltip="tooltip">
                              <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                <p class="options-paragraph m-0 p-0 me-2 d-inline">Shower</p>
                                <font-awesome-icon icon="shower" />
                                <span class="tooltiptext">Is there shower in the room?</span>
                              </div>
                            </span>
                          </div>
                        </div>
                        <div class="col-4">
                          <label class="switch">
                            <input type="checkbox" v-model="form.shower">
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <!-- Free Toiletries -->
                    <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 p-0 mt-1 mb-1">
                      <div class="row m-0 p-0">
                        <div class="col-8">
                          <div class="list-relative">
                            <span data-tooltip="tooltip">
                              <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                <p class="options-paragraph m-0 p-0 me-2 d-inline">Free Toiletries</p>
                                <font-awesome-icon icon="pump-soap" class="icon-fa" />
                                <span class="tooltiptext">Are there free toiletries in the room?</span>
                              </div>
                            </span>
                          </div>
                        </div>
                        <div class="col-4">
                          <label class="switch">
                            <input type="checkbox" v-model="form.free_toiletries">
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <!-- Towels -->
                    <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 p-0 mt-1 mb-1">
                      <div class="row m-0 p-0">
                        <div class="col-8">
                          <div class="list-relative">
                            <span data-tooltip="tooltip">
                              <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                <p class="options-paragraph m-0 p-0 me-2 d-inline">Towels</p>
                                <font-awesome-icon icon="layer-group" class="icon-fa" />
                                <span class="tooltiptext">Do you provide towels?</span>
                              </div>
                            </span>
                          </div>
                        </div>
                        <div class="col-4">
                          <label class="switch">
                            <input type="checkbox" v-model="form.towels">
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- End Toilet and Extras Switches -->

              <div v-if="form.room_type_id && form.room_type_id !== 8" class="row m-0 p-0">
                <div class="form-group col-12 text-center">
                  <!-- Start Horizontal Line with Icon -->
                  <div class="horizontal-separator-box">
                    <span class="hr-line-short"></span>
                    <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                    <span class="hr-line-short"></span>
                  </div>
                  <!-- End Horizontal Line with Icon -->

                  <!-- Information -->
                  <small class="p-3 fw-bold">Practical</small>

                  <!-- Start Horizontal Line with Icon -->
                  <div class="horizontal-separator-box">
                    <span class="hr-line-short"></span>
                    <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                    <span class="hr-line-short"></span>
                  </div>
                  <!-- End Horizontal Line with Icon -->
                </div>
              </div>

              <!-- Start Practical Switches -->
              <div v-if="form.room_type_id && form.room_type_id !== 8" class="row m-0 p-0 ps-5 pe-5">
                <div class="col-12 p-0 mt-1 mb-1">
                  <div class="row m-0 p-0">
                    <!-- Wi-Fi -->
                    <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 p-0 mt-1 mb-1">
                      <div class="row m-0 p-0">
                        <div class="col-8">
                          <div class="list-relative">
                            <span data-tooltip="tooltip">
                              <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                <p class="options-paragraph m-0 p-0 me-2 d-inline">Wi-Fi</p>
                                <font-awesome-icon icon="wifi" class="icon-fa" />
                                <span class="tooltiptext">Is there Wi-Fi in the room?</span>
                              </div>
                            </span>
                          </div>
                        </div>
                        <div class="col-4">
                          <label class="switch">
                            <input type="checkbox" v-model="form.wifi">
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <!-- TV -->
                    <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 p-0 mt-1 mb-1">
                      <div class="row m-0 p-0">
                        <div class="col-8">
                          <div class="list-relative">
                            <span data-tooltip="tooltip">
                              <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                <p class="options-paragraph m-0 p-0 me-2 d-inline">TV</p>
                                <font-awesome-icon icon="tv" class="icon-fa" />
                                <span class="tooltiptext">Is there TV in the room?</span>
                              </div>
                            </span>
                          </div>
                        </div>
                        <div class="col-4">
                          <label class="switch">
                            <input type="checkbox" v-model="form.tv">
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <!-- Work Desk -->
                    <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 p-0 mt-1 mb-1">
                      <div class="row m-0 p-0">
                        <div class="col-8">
                          <div class="list-relative">
                            <span data-tooltip="tooltip">
                              <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                <p class="options-paragraph m-0 p-0 me-2 d-inline">Work Desk</p>
                                <font-awesome-icon icon="laptop-house" class="icon-fa" />
                                <span class="tooltiptext">Is there work desk/space in the room?</span>
                              </div>
                            </span>
                          </div>
                        </div>
                        <div class="col-4">
                          <label class="switch">
                            <input type="checkbox" v-model="form.work_desk">
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <!-- Air Conditioning -->
                    <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 p-0 mt-1 mb-1">
                      <div class="row m-0 p-0">
                        <div class="col-8">
                          <div class="list-relative">
                            <span data-tooltip="tooltip">
                              <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                <p class="options-paragraph m-0 p-0 me-2 d-inline">Air Conditioning</p>
                                <font-awesome-icon icon="temperature-low" class="icon-fa" />
                                <span class="tooltiptext">Is there air conditioning in the room?</span>
                              </div>
                            </span>
                          </div>
                        </div>
                        <div class="col-4">
                          <label class="switch">
                            <input type="checkbox" v-model="form.air_conditioning">
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <!-- Fan -->
                    <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 p-0 mt-1 mb-1">
                      <div class="row m-0 p-0">
                        <div class="col-8">
                          <div class="list-relative">
                            <span data-tooltip="tooltip">
                              <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                <p class="options-paragraph m-0 p-0 me-2 d-inline">Fan</p>
                                <font-awesome-icon icon="fan" class="icon-fa" />
                                <span class="tooltiptext">Is there fan in the room?</span>
                              </div>
                            </span>
                          </div>
                        </div>
                        <div class="col-4">
                          <label class="switch">
                            <input type="checkbox" v-model="form.fan">
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <!-- Heating -->
                    <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 p-0 mt-1 mb-1">
                      <div class="row m-0 p-0">
                        <div class="col-8">
                          <div class="list-relative">
                            <span data-tooltip="tooltip">
                              <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                <p class="options-paragraph m-0 p-0 me-2 d-inline">Heating</p>
                                <font-awesome-icon icon="temperature-high" class="icon-fa" />
                                <span class="tooltiptext">Is there heating in the room?</span>
                              </div>
                            </span>
                          </div>
                        </div>
                        <div class="col-4">
                          <label class="switch">
                            <input type="checkbox" v-model="form.heating">
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <!-- Mosquito Net -->
                    <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 p-0 mt-1 mb-1">
                      <div class="row m-0 p-0">
                        <div class="col-8">
                          <div class="list-relative">
                            <span data-tooltip="tooltip">
                              <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                <p class="options-paragraph m-0 p-0 me-2 d-inline">Mosquito Net</p>
                                <font-awesome-icon icon="border-none" class="icon-fa" />
                                <span class="tooltiptext">Is there mosquito net in the room?</span>
                              </div>
                            </span>
                          </div>
                        </div>
                        <div class="col-4">
                          <label class="switch">
                            <input type="checkbox" v-model="form.mosquito_net">
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <!-- Board Games -->
                    <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 p-0 mt-1 mb-1">
                      <div class="row m-0 p-0">
                        <div class="col-8">
                          <div class="list-relative">
                            <span data-tooltip="tooltip">
                              <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                <p class="options-paragraph m-0 p-0 me-2 d-inline">Board Games</p>
                                <font-awesome-icon icon="dice" class="icon-fa" />
                                <span class="tooltiptext">Are there board games in the room?</span>
                              </div>
                            </span>
                          </div>
                        </div>
                        <div class="col-4">
                          <label class="switch">
                            <input type="checkbox" v-model="form.board_games">
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <!-- Digital Games -->
                    <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 p-0 mt-1 mb-1">
                      <div class="row m-0 p-0">
                        <div class="col-8">
                          <div class="list-relative">
                            <span data-tooltip="tooltip">
                              <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                <p class="options-paragraph m-0 p-0 me-2 d-inline">Digital Games</p>
                                <font-awesome-icon icon="gamepad" class="icon-fa" />
                                <span class="tooltiptext">Are there digital games in the room (Play Station, Xbox, Nintendo, other)?</span>
                              </div>
                            </span>
                          </div>
                        </div>
                        <div class="col-4">
                          <label class="switch">
                            <input type="checkbox" v-model="form.digital_games">
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <!-- Radio -->
                    <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 p-0 mt-1 mb-1">
                      <div class="row m-0 p-0">
                        <div class="col-8">
                          <div class="list-relative">
                            <span data-tooltip="tooltip">
                              <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                <p class="options-paragraph m-0 p-0 me-2 d-inline">Radio</p>
                                <font-awesome-icon icon="rss" class="icon-fa" />
                                <span class="tooltiptext">Is there radio in the room?</span>
                              </div>
                            </span>
                          </div>
                        </div>
                        <div class="col-4">
                          <label class="switch">
                            <input type="checkbox" v-model="form.radio">
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <!-- Iron -->
                    <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 p-0 mt-1 mb-1">
                      <div class="row m-0 p-0">
                        <div class="col-8">
                          <div class="list-relative">
                            <span data-tooltip="tooltip">
                              <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                <p class="options-paragraph m-0 p-0 me-2 d-inline">Iron</p>
                                <font-awesome-icon icon="shield-alt" class="icon-fa" />
                                <span class="tooltiptext">Is there iron in the room?</span>
                              </div>
                            </span>
                          </div>
                        </div>
                        <div class="col-4">
                          <label class="switch">
                            <input type="checkbox" v-model="form.iron">
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <!-- Hairdryer -->
                    <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 p-0 mt-1 mb-1">
                      <div class="row m-0 p-0">
                        <div class="col-8">
                          <div class="list-relative">
                            <span data-tooltip="tooltip">
                              <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                <p class="options-paragraph m-0 p-0 me-2 d-inline">Hairdryer</p>
                                <font-awesome-icon icon="wind" class="icon-fa" />
                                <span class="tooltiptext">Is there hairdryer in the room?</span>
                              </div>
                            </span>
                          </div>
                        </div>
                        <div class="col-4">
                          <label class="switch">
                            <input type="checkbox" v-model="form.hairdryer">
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <!-- Slippers -->
                    <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 p-0 mt-1 mb-1">
                      <div class="row m-0 p-0">
                        <div class="col-8">
                          <div class="list-relative">
                            <span data-tooltip="tooltip">
                              <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                <p class="options-paragraph m-0 p-0 me-2 d-inline">Slippers</p>
                                <font-awesome-icon icon="shoe-prints" class="icon-fa" />
                                <span class="tooltiptext">Are there slippers in the room?</span>
                              </div>
                            </span>
                          </div>
                        </div>
                        <div class="col-4">
                          <label class="switch">
                            <input type="checkbox" v-model="form.slippers">
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <!-- Hangers -->
                    <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 p-0 mt-1 mb-1">
                      <div class="row m-0 p-0">
                        <div class="col-8">
                          <div class="list-relative">
                            <span data-tooltip="tooltip">
                              <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                <p class="options-paragraph m-0 p-0 me-2 d-inline">Hangers</p>
                                <font-awesome-icon icon="angle-up" class="icon-fa" />
                                <span class="tooltiptext">Are there hangers in the room?</span>
                              </div>
                            </span>
                          </div>
                        </div>
                        <div class="col-4">
                          <label class="switch">
                            <input type="checkbox" v-model="form.hangers">
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- End Practical Switches -->

              <div v-if="form.room_type_id && form.room_type_id !== 8" class="row m-0 p-0">
                <div class="form-group col-12 text-center">
                  <!-- Start Horizontal Line with Icon -->
                  <div class="horizontal-separator-box">
                    <span class="hr-line-short"></span>
                    <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                    <span class="hr-line-short"></span>
                  </div>
                  <!-- End Horizontal Line with Icon -->

                  <!-- Information -->
                  <small class="p-3 fw-bold">Security</small>

                  <!-- Start Horizontal Line with Icon -->
                  <div class="horizontal-separator-box">
                    <span class="hr-line-short"></span>
                    <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                    <span class="hr-line-short"></span>
                  </div>
                  <!-- End Horizontal Line with Icon -->
                </div>
              </div>

              <!-- Start Security Switches -->
              <div v-if="form.room_type_id && form.room_type_id !== 8" class="row m-0 p-0 ps-5 pe-5">
                <div class="col-12 p-0 mt-1 mb-1">
                  <div class="row m-0 p-0">
                    <!-- Private Locked Room -->
                    <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 p-0 mt-1 mb-1">
                      <div class="row m-0 p-0">
                        <div class="col-8">
                          <div class="list-relative">
                            <span data-tooltip="tooltip">
                              <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                <p class="options-paragraph m-0 p-0 me-2 d-inline">Private Locked Room</p>
                                <font-awesome-icon icon="key" class="icon-fa" />
                                <span class="tooltiptext">Do you have privacy and can you lock the room?</span>
                              </div>
                            </span>
                          </div>
                        </div>
                        <div class="col-4">
                          <label class="switch">
                            <input type="checkbox" v-model="form.private_locked_room">
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- End Security Switches -->

              <div v-if="form.room_type_id && form.room_type_id !== 8" class="row m-0 p-0">
                <div class="form-group col-12 text-center">
                  <!-- Start Horizontal Line with Icon -->
                  <div class="horizontal-separator-box">
                    <span class="hr-line-short"></span>
                    <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                    <span class="hr-line-short"></span>
                  </div>
                  <!-- End Horizontal Line with Icon -->

                  <!-- Information -->
                  <small class="p-3 fw-bold">Accessibility</small>

                  <!-- Start Horizontal Line with Icon -->
                  <div class="horizontal-separator-box">
                    <span class="hr-line-short"></span>
                    <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                    <span class="hr-line-short"></span>
                  </div>
                  <!-- End Horizontal Line with Icon -->
                </div>
              </div>

              <!-- Start Accessibility Switches -->
              <div v-if="form.room_type_id && form.room_type_id !== 8" class="row m-0 p-0 ps-5 pe-5">
                <div class="col-12 p-0 mt-1 mb-1">
                  <div class="row m-0 p-0">
                    <!-- Wheelchair Accessible -->
                    <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 p-0 mt-1 mb-1">
                      <div class="row m-0 p-0">
                        <div class="col-8">
                          <div class="list-relative">
                            <span data-tooltip="tooltip">
                              <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                <p class="options-paragraph m-0 p-0 me-2 d-inline">Wheelchair Accessible</p>
                                <font-awesome-icon icon="wheelchair" class="icon-fa" />
                                <span class="tooltiptext">Can you access the room on a wheel chair?</span>
                              </div>
                            </span>
                          </div>
                        </div>
                        <div class="col-4">
                          <label class="switch">
                            <input type="checkbox" v-model="form.wheelchair_accessible">
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <!-- Ground Floor -->
                    <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 p-0 mt-1 mb-1">
                      <div class="row m-0 p-0">
                        <div class="col-8">
                          <div class="list-relative">
                            <span data-tooltip="tooltip">
                              <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                <p class="options-paragraph m-0 p-0 me-2 d-inline">Ground Floor</p>
                                <font-awesome-icon icon="window-minimize" class="icon-fa" />
                                <span class="tooltiptext">Is room located on the ground floor?</span>
                              </div>
                            </span>
                          </div>
                        </div>
                        <div class="col-4">
                          <label class="switch">
                            <input type="checkbox" v-model="form.ground_floor">
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <!-- Elevator -->
                    <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 p-0 mt-1 mb-1">
                      <div class="row m-0 p-0">
                        <div class="col-8">
                          <div class="list-relative">
                            <span data-tooltip="tooltip">
                              <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                <p class="options-paragraph m-0 p-0 me-2 d-inline">Elevator Accessible</p>
                                <font-awesome-icon icon="arrows-alt-v" class="icon-fa" />
                                <span class="tooltiptext">Is there an elevator that can be used to access the room?</span>
                              </div>
                            </span>
                          </div>
                        </div>
                        <div class="col-4">
                          <label class="switch">
                            <input type="checkbox" v-model="form.elevator">
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- End Accessibility Switches -->

              <div v-if="form.room_type_id && form.room_type_id !== 8" class="row m-0 p-0">
                <div class="form-group col-12 text-center">
                  <!-- Start Horizontal Line with Icon -->
                  <div class="horizontal-separator-box">
                    <span class="hr-line-short"></span>
                    <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                    <span class="hr-line-short"></span>
                  </div>
                  <!-- End Horizontal Line with Icon -->

                  <!-- Information -->
                  <small class="p-3 fw-bold">View</small>

                  <!-- Start Horizontal Line with Icon -->
                  <div class="horizontal-separator-box">
                    <span class="hr-line-short"></span>
                    <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                    <span class="hr-line-short"></span>
                  </div>
                  <!-- End Horizontal Line with Icon -->
                </div>
              </div>

              <!-- Start View Switches -->
              <div v-if="form.room_type_id && form.room_type_id !== 8" class="row m-0 p-0 ps-5 pe-5">
                <div class="col-12 p-0 mt-1 mb-1">
                  <div class="row m-0 p-0">
                    <!-- Balcony -->
                    <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 p-0 mt-1 mb-1">
                      <div class="row m-0 p-0">
                        <div class="col-8">
                          <div class="list-relative">
                            <span data-tooltip="tooltip">
                              <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                <p class="options-paragraph m-0 p-0 me-2 d-inline">Balcony</p>
                                <font-awesome-icon icon="chess-rook" class="icon-fa" />
                                <span class="tooltiptext">Is there balcony in the room?</span>
                              </div>
                            </span>
                          </div>
                        </div>
                        <div class="col-4">
                          <label class="switch">
                            <input type="checkbox" v-model="form.balcony">
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <!-- Bay View -->
                    <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 p-0 mt-1 mb-1">
                      <div class="row m-0 p-0">
                        <div class="col-8">
                          <div class="list-relative">
                            <span data-tooltip="tooltip">
                              <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                <p class="options-paragraph m-0 p-0 me-2 d-inline">Bay View</p>
                                <font-awesome-icon icon="eye" class="icon-fa" />
                                <span class="tooltiptext">Is there view on a bay?</span>
                              </div>
                            </span>
                          </div>
                        </div>
                        <div class="col-4">
                          <label class="switch">
                            <input type="checkbox" v-model="form.bay_view">
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <!-- Beach View -->
                    <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 p-0 mt-1 mb-1">
                      <div class="row m-0 p-0">
                        <div class="col-8">
                          <div class="list-relative">
                            <span data-tooltip="tooltip">
                              <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                <p class="options-paragraph m-0 p-0 me-2 d-inline">Beach View</p>
                                <font-awesome-icon icon="eye" class="icon-fa" />
                                <span class="tooltiptext">Is there view on a beach?</span>
                              </div>
                            </span>
                          </div>
                        </div>
                        <div class="col-4">
                          <label class="switch">
                            <input type="checkbox" v-model="form.beach_view">
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <!-- Canal View -->
                    <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 p-0 mt-1 mb-1">
                      <div class="row m-0 p-0">
                        <div class="col-8">
                          <div class="list-relative">
                            <span data-tooltip="tooltip">
                              <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                <p class="options-paragraph m-0 p-0 me-2 d-inline">Canal View</p>
                                <font-awesome-icon icon="eye" class="icon-fa" />
                                <span class="tooltiptext">Is there view on a canal?</span>
                              </div>
                            </span>
                          </div>
                        </div>
                        <div class="col-4">
                          <label class="switch">
                            <input type="checkbox" v-model="form.canal_view">
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <!-- City View -->
                    <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 p-0 mt-1 mb-1">
                      <div class="row m-0 p-0">
                        <div class="col-8">
                          <div class="list-relative">
                            <span data-tooltip="tooltip">
                              <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                <p class="options-paragraph m-0 p-0 me-2 d-inline">City View</p>
                                <font-awesome-icon icon="eye" class="icon-fa" />
                                <span class="tooltiptext">Is there view on a city?</span>
                              </div>
                            </span>
                          </div>
                        </div>
                        <div class="col-4">
                          <label class="switch">
                            <input type="checkbox" v-model="form.city_view">
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <!-- Courtyard View -->
                    <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 p-0 mt-1 mb-1">
                      <div class="row m-0 p-0">
                        <div class="col-8">
                          <div class="list-relative">
                            <span data-tooltip="tooltip">
                              <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                <p class="options-paragraph m-0 p-0 me-2 d-inline">Courtyard View</p>
                                <font-awesome-icon icon="eye" class="icon-fa" />
                                <span class="tooltiptext">Is there view on a courtyard?</span>
                              </div>
                            </span>
                          </div>
                        </div>
                        <div class="col-4">
                          <label class="switch">
                            <input type="checkbox" v-model="form.courtyard_view">
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <!-- Desert View -->
                    <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 p-0 mt-1 mb-1">
                      <div class="row m-0 p-0">
                        <div class="col-8">
                          <div class="list-relative">
                            <span data-tooltip="tooltip">
                              <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                <p class="options-paragraph m-0 p-0 me-2 d-inline">Desert View</p>
                                <font-awesome-icon icon="eye" class="icon-fa" />
                                <span class="tooltiptext">Is there view on a desert?</span>
                              </div>
                            </span>
                          </div>
                        </div>
                        <div class="col-4">
                          <label class="switch">
                            <input type="checkbox" v-model="form.desert_view">
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <!-- Garden View -->
                    <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 p-0 mt-1 mb-1">
                      <div class="row m-0 p-0">
                        <div class="col-8">
                          <div class="list-relative">
                            <span data-tooltip="tooltip">
                              <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                <p class="options-paragraph m-0 p-0 me-2 d-inline">Garden View</p>
                                <font-awesome-icon icon="eye" class="icon-fa" />
                                <span class="tooltiptext">Is there view on a garden?</span>
                              </div>
                            </span>
                          </div>
                        </div>
                        <div class="col-4">
                          <label class="switch">
                            <input type="checkbox" v-model="form.garden_view">
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <!-- Sports View -->
                    <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 p-0 mt-1 mb-1">
                      <div class="row m-0 p-0">
                        <div class="col-8">
                          <div class="list-relative">
                            <span data-tooltip="tooltip">
                              <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                <p class="options-paragraph m-0 p-0 me-2 d-inline">Sports View</p>
                                <font-awesome-icon icon="eye" class="icon-fa" />
                                <span class="tooltiptext">Is there view on sports?</span>
                              </div>
                            </span>
                          </div>
                        </div>
                        <div class="col-4">
                          <label class="switch">
                            <input type="checkbox" v-model="form.sports_view">
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <!-- Harbour View -->
                    <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 p-0 mt-1 mb-1">
                      <div class="row m-0 p-0">
                        <div class="col-8">
                          <div class="list-relative">
                            <span data-tooltip="tooltip">
                              <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                <p class="options-paragraph m-0 p-0 me-2 d-inline">Harbour View</p>
                                <font-awesome-icon icon="eye" class="icon-fa" />
                                <span class="tooltiptext">Is there view on harbour?</span>
                              </div>
                            </span>
                          </div>
                        </div>
                        <div class="col-4">
                          <label class="switch">
                            <input type="checkbox" v-model="form.harbour_view">
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <!-- Marina View -->
                    <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 p-0 mt-1 mb-1">
                      <div class="row m-0 p-0">
                        <div class="col-8">
                          <div class="list-relative">
                            <span data-tooltip="tooltip">
                              <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                <p class="options-paragraph m-0 p-0 me-2 d-inline">Marina View</p>
                                <font-awesome-icon icon="eye" class="icon-fa" />
                                <span class="tooltiptext">Is there view on marina?</span>
                              </div>
                            </span>
                          </div>
                        </div>
                        <div class="col-4">
                          <label class="switch">
                            <input type="checkbox" v-model="form.marina_view">
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <!-- Landmark View -->
                    <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 p-0 mt-1 mb-1">
                      <div class="row m-0 p-0">
                        <div class="col-8">
                          <div class="list-relative">
                            <span data-tooltip="tooltip">
                              <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                <p class="options-paragraph m-0 p-0 me-2 d-inline">Landmark View</p>
                                <font-awesome-icon icon="eye" class="icon-fa" />
                                <span class="tooltiptext">Is there landmark view?</span>
                              </div>
                            </span>
                          </div>
                        </div>
                        <div class="col-4">
                          <label class="switch">
                            <input type="checkbox" v-model="form.landmark_view">
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <!-- Mountain View -->
                    <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 p-0 mt-1 mb-1">
                      <div class="row m-0 p-0">
                        <div class="col-8">
                          <div class="list-relative">
                            <span data-tooltip="tooltip">
                              <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                <p class="options-paragraph m-0 p-0 me-2 d-inline">Mountain View</p>
                                <font-awesome-icon icon="eye" class="icon-fa" />
                                <span class="tooltiptext">Is there view on a mountains?</span>
                              </div>
                            </span>
                          </div>
                        </div>
                        <div class="col-4">
                          <label class="switch">
                            <input type="checkbox" v-model="form.mountain_view">
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <!-- Sea View -->
                    <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 p-0 mt-1 mb-1">
                      <div class="row m-0 p-0">
                        <div class="col-8">
                          <div class="list-relative">
                            <span data-tooltip="tooltip">
                              <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                <p class="options-paragraph m-0 p-0 me-2 d-inline">Sea View</p>
                                <font-awesome-icon icon="eye" class="icon-fa" />
                                <span class="tooltiptext">Is there view on a sea?</span>
                              </div>
                            </span>
                          </div>
                        </div>
                        <div class="col-4">
                          <label class="switch">
                            <input type="checkbox" v-model="form.sea_view">
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <!-- Ocean View -->
                    <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 p-0 mt-1 mb-1">
                      <div class="row m-0 p-0">
                        <div class="col-8">
                          <div class="list-relative">
                            <span data-tooltip="tooltip">
                              <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                <p class="options-paragraph m-0 p-0 me-2 d-inline">Ocean View</p>
                                <font-awesome-icon icon="eye" class="icon-fa" />
                                <span class="tooltiptext">Is there view on ocean?</span>
                              </div>
                            </span>
                          </div>
                        </div>
                        <div class="col-4">
                          <label class="switch">
                            <input type="checkbox" v-model="form.ocean_view">
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <!-- Pool View -->
                    <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 p-0 mt-1 mb-1">
                      <div class="row m-0 p-0">
                        <div class="col-8">
                          <div class="list-relative">
                            <span data-tooltip="tooltip">
                              <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                <p class="options-paragraph m-0 p-0 me-2 d-inline">Pool View</p>
                                <font-awesome-icon icon="eye" class="icon-fa" />
                                <span class="tooltiptext">Is there view on a pool?</span>
                              </div>
                            </span>
                          </div>
                        </div>
                        <div class="col-4">
                          <label class="switch">
                            <input type="checkbox" v-model="form.pool_view">
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <!-- Resort View -->
                    <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 p-0 mt-1 mb-1">
                      <div class="row m-0 p-0">
                        <div class="col-8">
                          <div class="list-relative">
                            <span data-tooltip="tooltip">
                              <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                <p class="options-paragraph m-0 p-0 me-2 d-inline">Resort View</p>
                                <font-awesome-icon icon="eye" class="icon-fa" />
                                <span class="tooltiptext">Is there view on a resort?</span>
                              </div>
                            </span>
                          </div>
                        </div>
                        <div class="col-4">
                          <label class="switch">
                            <input type="checkbox" v-model="form.resort_view">
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <!-- Park View -->
                    <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 p-0 mt-1 mb-1">
                      <div class="row m-0 p-0">
                        <div class="col-8">
                          <div class="list-relative">
                            <span data-tooltip="tooltip">
                              <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                <p class="options-paragraph m-0 p-0 me-2 d-inline">Park View</p>
                                <font-awesome-icon icon="eye" class="icon-fa" />
                                <span class="tooltiptext">Is there view on a park?</span>
                              </div>
                            </span>
                          </div>
                        </div>
                        <div class="col-4">
                          <label class="switch">
                            <input type="checkbox" v-model="form.park_view">
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <!-- Lake View -->
                    <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 p-0 mt-1 mb-1">
                      <div class="row m-0 p-0">
                        <div class="col-8">
                          <div class="list-relative">
                            <span data-tooltip="tooltip">
                              <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                <p class="options-paragraph m-0 p-0 me-2 d-inline">Lake View</p>
                                <font-awesome-icon icon="eye" class="icon-fa" />
                                <span class="tooltiptext">Is there view on a lake?</span>
                              </div>
                            </span>
                          </div>
                        </div>
                        <div class="col-4">
                          <label class="switch">
                            <input type="checkbox" v-model="form.lake_view">
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <!-- River View -->
                    <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 p-0 mt-1 mb-1">
                      <div class="row m-0 p-0">
                        <div class="col-8">
                          <div class="list-relative">
                            <span data-tooltip="tooltip">
                              <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                <p class="options-paragraph m-0 p-0 me-2 d-inline">River View</p>
                                <font-awesome-icon icon="eye" class="icon-fa" />
                                <span class="tooltiptext">Is there view on a river?</span>
                              </div>
                            </span>
                          </div>
                        </div>
                        <div class="col-4">
                          <label class="switch">
                            <input type="checkbox" v-model="form.river_view">
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <!-- Valley View -->
                    <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 p-0 mt-1 mb-1">
                      <div class="row m-0 p-0">
                        <div class="col-8">
                          <div class="list-relative">
                            <span data-tooltip="tooltip">
                              <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                <p class="options-paragraph m-0 p-0 me-2 d-inline">Valley View</p>
                                <font-awesome-icon icon="eye" class="icon-fa" />
                                <span class="tooltiptext">Is there view on valley?</span>
                              </div>
                            </span>
                          </div>
                        </div>
                        <div class="col-4">
                          <label class="switch">
                            <input type="checkbox" v-model="form.valley_view">
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- End View Switches -->

            </form>
            </ValidationObserver>

          </div>
          <div class="modal-footer">
            <button type="button" id="closeAddRoomModal" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            <button type="button"
                    :disabled="processing"
                    class="btn btn-primary"
                    @click.prevent="addRoom"
            >
              {{ processing ? "Please wait" : "Add" }}
            </button>
            <div class="row m-0 p-0">
              <div class="col-12 mb-2 text-center">
                <p v-if="this.validationMessage" class="required-info text-center">
                  {{ this.validationMessage }} Please scroll up to see error messages.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ValidationErrors from "./ValidationErrors"
import Loader from "./Loader.vue"

export default {
  name: 'AddRoomModal',
  props: ['formData', 'refresh'],
  components: {
    'ValidationErrors': ValidationErrors,
    'Loader': Loader
  },
  data() {
    return {
      user: (this.$store.state.auth.authenticated === true && this.$store.state.auth.user) ? this.$store.state.auth.user : null,
      modalData: this.formData,
      roomId: this.formData.room ? this.formData.room.id : '',
      form: {
        id: '', // fake room id for purpose of listing and editing properly, this id can be also valid taken from db if user edits existing rooms
        is_existing_room: false,
        guests: '',
        price: '',
        property_rent_type_id: '',
        discount: '',
        discount_rate: '',
        smoking_room: false,
        pet_friendly_room: false,
        // Room type
        room_type_id: '',
        property_room_type: {
          type: ''
        },
        property_rent_type: {
          type: ''
        },
        quantity: 1,
        // Beds
        single_beds: '',
        double_beds: '',
        queen_beds: '',
        king_beds: '',
        zip_and_link_beds: '',
        water_beds: '',
        sofa_beds: '',
        bunk_beds: '',
        toddler_beds: '',
        cribs: '',
        hammocks: '',
        // Security
        private_locked_room: false,
        // Kitchen Like && Extra items
        fridge: false,
        freezer: false,
        microwave: false,
        kettle: false,
        coffee: false,
        toaster: false,
        iron: false,
        hairdryer: false,
        slippers: false,
        hangers: false,
        // Toilet and Extras
        sink: false,
        shower: false,
        bath: false,
        wc: false,
        free_toiletries: false,
        towels: false,
        // Electronic and useful
        wifi: false,
        tv: false,
        work_desk: false,
        air_conditioning: false,
        fan: false,
        heating: false,
        mosquito_net: false,
        board_games: false,
        digital_games: false,
        radio: false,
        // View
        balcony: false,
        bay_view: false,
        beach_view: false,
        canal_view: false,
        city_view: false,
        courtyard_view: false,
        desert_view: false,
        garden_view: false,
        sports_view: false,
        harbour_view: false,
        marina_view: false,
        landmark_view: false,
        mountain_view: false,
        sea_view: false,
        ocean_view: false,
        pool_view: false,
        resort_view: false,
        park_view: false,
        lake_view: false,
        river_view: false,
        valley_view: false,
        // Accessibility
        wheelchair_accessible: false,
        ground_floor: false,
        elevator: false
      },
      defaultForm: {
        id: '',
        is_existing_room: false,
        guests: '',
        price: '',
        property_rent_type_id: '',
        discount: '',
        discount_rate: '',
        smoking_room: false,
        pet_friendly_room: false,
        // Room type
        room_type_id: '',
        // property_room_type: '',
        property_room_type: {
          type: ''
        },
        property_rent_type: {
          type: ''
        },
        quantity: 1,
        // Beds
        single_beds: '',
        double_beds: '',
        queen_beds: '',
        king_beds: '',
        zip_and_link_beds: '',
        water_beds: '',
        sofa_beds: '',
        bunk_beds: '',
        toddler_beds: '',
        cribs: '',
        hammocks: '',
        // Security
        private_locked_room: false,
        // Kitchen Like Extras
        fridge: false,
        freezer: false,
        microwave: false,
        kettle: false,
        coffee: false,
        toaster: false,
        // Toilet and Extras
        sink: false,
        shower: false,
        bath: false,
        wc: false,
        free_toiletries: false,
        towels: false,
        // Practical
        wifi: false,
        tv: false,
        work_desk: false,
        air_conditioning: false,
        fan: false,
        heating: false,
        mosquito_net: false,
        board_games: false,
        digital_games: false,
        radio: false,
        iron: false,
        hairdryer: false,
        slippers: false,
        hangers: false,
        // View
        balcony: false,
        bay_view: false,
        beach_view: false,
        canal_view: false,
        city_view: false,
        courtyard_view: false,
        desert_view: false,
        garden_view: false,
        sports_view: false,
        harbour_view: false,
        marina_view: false,
        landmark_view: false,
        mountain_view: false,
        sea_view: false,
        ocean_view: false,
        pool_view: false,
        resort_view: false,
        park_view: false,
        lake_view: false,
        river_view: false,
        valley_view: false,
        // Accessibility
        wheelchair_accessible: false,
        ground_floor: false,
        elevator: false
      },
      processing: false,
      loading: false,
      editing: false,
      validationMessage: '',
      validationErrors: '',
      validationSuccess: null
    }
  },
  computed: {
    atLeastOneRequired() {
      if (
          (!this.form.single_beds || this.form.single_beds.length === 0 || this.form.single_beds == 0) &&
          (!this.form.double_beds || this.form.double_beds.length === 0 || this.form.double_beds == 0) &&
          (!this.form.queen_beds || this.form.queen_beds.length === 0 || this.form.queen_beds == 0) &&
          (!this.form.king_beds || this.form.king_beds.length === 0 || this.form.king_beds == 0) &&
          (!this.form.zip_and_link_beds || this.form.zip_and_link_beds.length === 0 || this.form.zip_and_link_beds == 0) &&
          (!this.form.water_beds || this.form.water_beds.length === 0 || this.form.water_beds == 0) &&
          (!this.form.sofa_beds || this.form.sofa_beds.length === 0 || this.form.sofa_beds == 0) &&
          (!this.form.bunk_beds || this.form.bunk_beds.length === 0 || this.form.bunk_beds == 0) &&
          (!this.form.toddler_beds || this.form.toddler_beds.length === 0 || this.form.toddler_beds == 0) &&
          (!this.form.cribs || this.form.cribs.length === 0 || this.form.cribs == 0) &&
          (!this.form.hammocks || this.form.hammocks.length === 0 || this.form.hammocks == 0)
      ) {
        return true;
      } else {
        return false;
      }
    }
  },
  mounted() {
    if (this.formData.room && this.formData.room.id !== '' && this.formData.room.id !== null && this.formData.room.id !== undefined) {
      this.loading = true;
      this.form = '';
      this.form = {...this.formData.room};
      this.loading = false;
    } else {
      this.form = '';
      this.form = {...this.defaultForm};
    }
  },
  methods:{
    async addRoom() {
      // Validate that data is correct and then
      const isValid = await this.$refs.addRoomFormObserver.validate();
      if (isValid) {
        // Pass room data back to component using global addRoom
        this.$root.$emit('addRoomGlobal', this.form);

        // Reset form values to default
        this.form = this.defaultForm;

        // Close AddRoomModal
        let closeAddRoomBtn = document.getElementById('closeAddRoomModal');
        if (closeAddRoomBtn) {
          closeAddRoomBtn.click();
        }
      }
    },
    checkSuiteExists(data) {
      if (data.rooms) {
        for (let i = 0; i < data.rooms.length; i++) {
          if (data.rooms[i].property_room_type.type === 'Suite/Apartment') {
            return true;
          }
        }
      }
    },
    handleFocus(event) {
      if(!event.target.classList.contains('select-clicked')) {
        event.target.classList.toggle('select-clicked');
      }
    },
    handleFocusOut(value, event) {
      if(value === null || value === '') {
        if(event.target.classList.contains('select-clicked')) {
          event.target.classList.toggle('select-clicked');
        }
      }
    },
  },
  watch: {
    refresh: {
      handler: async function (val) {
        this.loading = true;

        // Action if necessary

        this.loading = false;
      }, deep: true, immediate: true
    },
    "formData.room.id": {
      handler: function (id) {
        if (id !== '' && id !== null && id !== undefined) {
          this.loading = true;
          this.form = '';
          this.form = {...this.formData.room};
          this.loading = false;
        } else {
          this.form = '';
          this.form = {...this.defaultForm};
        }
      }, immediate: true, deep: true
    },
    "form.room_type_id": function(val) {

      switch(val) {
        case 1:
          this.form.property_room_type = {type: 'Single'};
          break;
        case 2:
          this.form.property_room_type = {type: 'Double'};
          break;
        case 3:
          this.form.property_room_type = {type: 'Twin'};
          break;
        case 4:
          this.form.property_room_type = {type: 'Twin/Double'};
          break;
        case 5:
          this.form.property_room_type = {type: 'Triple'};
          break;
        case 6:
          this.form.property_room_type = {type: 'Quadruple'};
          break;
        case 7:
          this.form.property_room_type = {type: 'Family'};
          break;
        case 8:
          this.form.property_room_type = {type: 'Suite/Apartment'};
          break;
        default:
          this.form.property_room_type = {type: ''};
          break;
      }

    },
    "form.property_rent_type_id": function(val) {

      switch(val) {
        case 1:
          this.form.property_rent_type = {type: 'hour'};
          break;
        case 2:
          this.form.property_rent_type = {type: 'night'};
          break;
        case 3:
          this.form.property_rent_type = {type: 'week'};
          break;
        case 4:
          this.form.property_rent_type = {type: 'month'};
          break;
        default:
          this.form.property_rent_type = {type: ''};
          break;
      }
    }
  }
}
</script>

<style lang="scss">

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scroll {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

#addRoomModal {
  height: 100%;
  z-index: 100000;
  .modal-dialog {
    max-width: 60vw; // For mobile should take about 100% width
    .modal-header {
      font-size: 13px;
      color: white;
      border-radius: 10px 10px 0 0;
      background: rgba(128, 0, 128, 0.7);
      padding: .5rem;
      text-transform: uppercase
    }

    .modal-content {
      width: 60vw; // For mobile should take about 100% width
      height: 80vh;
      border-radius: 10px;
      border: none;
      .modal-body {
        overflow-x: hidden;
        overflow-y: scroll;
        width: 60vw; // For mobile should take about 100% width
        height: 80vh;
      }
    }

    .modal-footer {
      justify-content: center;
    }

    .modal-border {
      border-radius: 10px;
      background: white;
      $border: 2px;
      position: relative;
      box-sizing: border-box;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        margin: -$border; /* !importanté */
        border-radius: inherit; /* !importanté */
        background: linear-gradient(to bottom, rgba(128, 0, 128, 0.7), rgba(25, 181, 254, 0.7));
      }
    }

    .close-btn {
      color: white;
      line-height: 30px;
      font-size: 26px;
      border-radius: 50%;
      padding: 1px 5px;
      border: 0;
    }

    .close-btn:hover {
      color: rgba(128, 0, 128, 0.7);
      transform: rotate(90deg);
      transition: 0.5s;
      -moz-transition: 0.5s;
    }
  }
}

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {

}
@media screen and (max-width: 767px) {
  #addRoomModal {
    .modal-dialog {
      max-width: 90vw;

      .modal-content {
        width: 90vw;

        .modal-body {
          width: 90vw;
        }
      }
    }
  }
}
@media screen and (min-width: 768px) {
  #addRoomModal {
    .modal-dialog {
      max-width: 60vw;

      .modal-content {
        width: 60vw;

        .modal-body {
          width: 60vw;
        }
      }
    }
  }
}
@media screen and (min-width: 992px) {

}
@media screen and (min-width: 1200px) {

}
@media (max-height: 800px) and (min-width: 1200px) {

}
@media screen and (min-width: 1680px) {

}
@media (min-height: 1200px) and (min-width: 1600px) {

}
@media screen and (min-width: 1920px) {

}

</style>