<template>
  <!-- Modal -->
  <div class="modal fade" id="formDraftModal" tabindex="-1" role="dialog" data-bs-backdrop="true" aria-labelledby="formDraftModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-border">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="formDraftModalLabel">Save Draft</h5>
            <button type="button" id="closeFormDraftModal" class="btn btn-outline-light close close-btn" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="col-12 zindex-10">
              <div class="">
<!--                {{ type }}-->
<!--                {{formData}}-->
                <form action="javascript:void(0)" method="post" class="label-top" enctype="multipart/form-data">

                  <!-- List for overwrites -->
                  <div class="form-group col-12 p-1">
                    <ul class="list-unstyled">
                      <li class="draft-list draft mt-1 cursor-pointer"
                          @click="toggleOverwrite(draft)"
                          v-for="draft in drafts"
                          v-bind:class="{ 'bg-selected-draft text-light': form.overwrite && form.overwrite_id === draft.id }"
                      >
                        {{ draft.title }}
                      </li>
                    </ul>
                  </div>

                  <div class="form-group form-floating-label col-12 p-1">
                    <input required type="text" name="draft_title" maxlength="100" v-model="form.title" id="draft_title" placeholder=" " class="form-control top">
                    <label for="draft_title" class="font-weight-bold top">Draft Title <span class="required"> *</span></label>
                  </div>

                  <div class="form-group col-12 p-1">
                    <button type="submit"
                            :disabled="processing || !form.overwrite"
                            @click.prevent="deleteDraft"
                            class="btn btn-outline-danger float-start"
                            id="deleteDraftBtn"
                    >Delete
                    </button>
                    <button type="submit"
                            :disabled="processing"
                            @click.prevent="saveDraft"
                            class="btn btn-outline-success float-end"
                            id="saveDraftBtn"
                    >{{ form.overwrite ? 'Overwrite' : 'Save'}}
                    </button>
                  </div>
                </form>
              </div>
            </div>

          </div>
          <div class="modal-footer">
            <div v-if="this.validationMessage" class="success-info text-center">
              {{ this.validationMessage }}
            </div>
            <div v-if="validationErrors" class="global-error mt-2">
              <ValidationErrors :errors="validationErrors"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ValidationErrors from "../ValidationErrors"

export default {
  name: 'FormDraftModal',
  props: ['type', 'formData', 'drafts', 'refresh'],
  components: {
    'ValidationErrors': ValidationErrors
  },
  data() {
    return {
      user: (this.$store.state.auth.authenticated === true && this.$store.state.auth.user) ? this.$store.state.auth.user : null,
      processing: false,
      validationMessage: '',
      validationErrors: '',
      validationSuccess: null,
      form: {
        user_id: this.$store.state.auth.user.id,
        type: this.type,
        title: '',
        data: this.formData,
        overwrite: false,
        overwrite_id: ''
      },
    }
  },
  methods:{
    async saveDraft() {
      this.processing = true;

      let formData = new FormData();
      formData.append('user_id', this.$store.state.auth.user.id);
      formData.append('type', this.type);
      formData.append('title', this.form.title);
      formData.append('data', JSON.stringify(this.formData));
      if (this.form.overwrite) {
        formData.append('overwrite', 1);
        formData.append('overwrite_id', this.form.overwrite_id);
      }

      await axios.post('api/draft/store', formData).then(({data}) => {
        // Clear form title
        this.form.title = '';
        this.validationMessage = "Your draft was saved successfully!";
        this.showError = true;
        this.$root.$emit('refreshDrafts');
        const timeout = setTimeout(() => {
          this.showError = false;
          this.validationSuccess = null;
          this.validationMessage = '';
          let closeChatBtn = document.getElementById('closeFormDraftModal');
          if (closeChatBtn) {
            closeChatBtn.click();
          }
          clearTimeout(timeout);
        }, 2000);
        this.validationErrors = null;
      }).catch(({response})=>{
        if (response && response.data && response.data.errors) {
          this.validationErrors = response.data.errors;
          const timeout = setTimeout(() => {
            this.validationErrors = '';
            clearTimeout(timeout);
          }, 3000)
          // this.validationMessage = response.data.message;
        }
        // alert(data.message)
      }).finally(() => {
        this.processing = false
      })
    },
    async deleteDraft() {
      if (!this.form.overwrite && !isNaN(this.form.overwrite_id)) {
        return;
      }

      this.processing = true;

      let formData = new FormData();
      formData.append('user_id', this.$store.state.auth.user.id);
      formData.append('type', this.type);
      formData.append('title', this.form.title);
      formData.append('data', JSON.stringify(this.formData));
      if (this.form.overwrite) {
        formData.append('overwrite', 1);
        formData.append('overwrite_id', this.form.overwrite_id);
      }

      await axios.post('api/draft/delete', formData).then(({data}) => {
        // Clear form title
        this.form.title = '';
        this.form.overwrite = false;
        this.form.overwrite_id = '';
        this.validationMessage = "Your draft was deleted successfully!";
        this.showError = true;
        this.$root.$emit('refreshDrafts');
        const timeout = setTimeout(() => {
          this.showError = false;
          this.validationSuccess = null;
          this.validationMessage = '';
          let closeChatBtn = document.getElementById('closeFormDraftModal');
          if (closeChatBtn) {
            closeChatBtn.click();
          }
          clearTimeout(timeout);
        }, 2000);
        this.validationErrors = null;
      }).catch(({response})=>{
        if (response && response.data && response.data.errors) {
          this.validationErrors = response.data.errors;
          const timeout = setTimeout(() => {
            this.validationErrors = '';
            clearTimeout(timeout);
          }, 3000)
          // this.validationMessage = response.data.message;
        }
        // alert(data.message)
      }).finally(() => {
        this.processing = false
      })
    },
    toggleOverwrite(draft) {
      if (draft.id == this.form.overwrite_id) {
        this.form.overwrite_id = '';
        this.form.overwrite = false;
        this.form.title = '';
      } else {
        this.form.overwrite_id = draft.id;
        this.form.overwrite = true;
        this.form.title = draft.title_original;
      }
    },
  },
  watch: {
    refresh: {
      handler: async function (val) {
        this.loading = true;

        // Action if necessary

        this.loading = false;
      }, deep: true, immediate: true
    },
  }
}
</script>

<style lang="scss">

#formDraftModal {
  height: 100%;
  z-index: 100000;
  .modal-header {
    font-size: 13px;
    color: white;
    border-radius: 10px 10px 0 0;
    background: rgba(128, 0, 128, 0.7);
    padding: .5rem;
    text-transform: uppercase
  }
  .modal-content {
    border-radius: 10px;
    border: none;
    height: fit-content;
  }
  .modal-footer {
    justify-content: center;
  }
  .modal-border {
    border-radius: 10px;
    background: white;
    $border: 2px;
    position: relative;
    box-sizing: border-box;
    &:before {
      content: '';
      position: absolute;
      top: 0; right: 0; bottom: 0; left: 0;
      z-index: -1;
      margin: -$border; /* !importanté */
      border-radius: inherit; /* !importanté */
      background: linear-gradient(to bottom, rgba(128, 0, 128, 0.7), rgba(25, 181, 254, 0.7));
    }
  }
  .close-btn {
    color: white;
    line-height: 30px;
    font-size: 26px;
    border-radius: 50%;
    padding: 1px 5px;
    border: 0;
  }
  .close-btn:hover {
    color: rgba(128, 0, 128, 0.7);
    transform: rotate(90deg);
    transition: 0.5s;
    -moz-transition: 0.5s;
  }
  .draft-list {
    border-radius: 4px;
    border: 1px solid #ced4da;
    display: inline-block;
    width: 100%;
    padding: .375rem .75rem;
    line-height: 1.5;
    color: #212529;
  }

  .draft {
    font-size: 13px;
    background-color: #f1f1f1;
    transition: 0.5s;
    -moz-transition: 0.5s;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    &:hover {
      background-color: white;
      color: black;
      transition: 0.5s;
      -moz-transition: 0.5s;
      -webkit-transition: 0.5s;
      -o-transition: 0.5s;
    }
  }

  .bg-selected-draft {
    background-color: #bdfdbd;
    color: black !important;
    transition: 0.5s;
    -moz-transition: 0.5s;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    &:hover {
      background-color: #bdfdbd !important;
      color: black !important;
      transition: 0.5s;
      -moz-transition: 0.5s;
      -webkit-transition: 0.5s;
      -o-transition: 0.5s;
    }
  }
}

.chat-input-position {
  position: relative;
  transform: perspective(1px) translateY(20%);
}

</style>