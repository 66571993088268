<template>
  <div>
    <transition name="fade">
      <div id="mySidebarRight" class="sidebarRight modal-tab zindex-max">
        <a href="javascript:void(0)" class="closebtn" v-on:click="closeRightNav">×</a>
        <a href="#">Chat</a>
        <form class="d-flex">
          <input class="form-control me-2" type="search" placeholder="Search area..." aria-label="Search">
          <button class="btn btn-outline-success" type="submit">Search</button>
        </form>
      </div>
    </transition>
    <!-- Chat Button - temporarily hide -->
<!--    <div id="mainRight">-->
<!--      <button id="sidebarRight" v-show="!activeRight" class="openbtnRight zindex-max" @mouseover="mouseOverRight" v-on:click="openRightNav"><font-awesome-icon icon="comments" /></button>-->
<!--    </div>-->
  </div>
</template>

<script>
export default {
  name:"Chat",
  data(){
    return {
      user:this.$store.state.auth.user,
      activeRight: false
    }
  },
  methods:{
    mouseOverRight: function(){
      this.activeRight = !this.activeRight;
    },
    openRightNav() {
      document.getElementById("mySidebarRight").style.width = "250px";
      document.getElementById("mainRight").style.marginLeft = "250px";
    },
    closeRightNav() {
      document.getElementById("mySidebarRight").style.width = "0";
      document.getElementById("mainRight").style.marginLeft= "0";
      this.activeRight = !this.activeRight;
    }
  },
  watch: {
    activeRight: function (val) {
      if(this.activeRight === true) {
        this.openRightNav();
      }
    },
  }
}
</script>

<style lang="scss">

/* Fade effect for transition elements */
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.zindex-max {
  z-index: 9999 !important;
}

#mainRight {
  position: absolute;
  transition: margin-left .5s;
  padding: 16px;
}

/* right side bar - chat */
#sidebarRight {
  position: fixed;
  left: 96vw;
}

.sidebarRight {
  height: 90vh;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  background-color: rgba(33, 150, 243, 50%);
  border-radius: 5% 0 0 5%;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}

.sidebarRight a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: black;
  display: block;
  transition: 0.3s;
}

.sidebarRight a:hover {
  color: #f1f1f1;
}

.sidebarRight .closebtn {
  position: absolute;
  top: -10px;
  left: -76px;
  font-size: 36px;
  margin-left: 50px;
}

.openbtnRight {
  position: fixed;
  top: 9.8vh;
  left: 0;
  font-size: 20px;
  cursor: pointer;
  background-color: rgba(33, 150, 243, 50%);
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 50% 0 0 50%;
}

.openbtnRight:hover {
  background-color: #444;
}

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
  .sidebarRight {padding-top: 15px;}
  .sidebarRight a {font-size: 18px;}
}

</style>
