import axios from 'axios'

export default {
    namespaced: true,
    state:{
        loading:false,
        location:false,
    },
    getters:{
        loading(state){
            return state.loading
        },
        location(state){
            return state.location
        }
    },
    mutations:{
        SET_LOADING (state, value) {
            state.loading = value
        },
        SET_LOCATION (state, value) {
            state.location = value
        }
    },
    actions:{
        setLocation({ commit }, payload) {
            commit('SET_LOADING', true)
            commit('SET_LOCATION', payload);
            commit('SET_LOADING', false)
        }
    }
}