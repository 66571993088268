<template>
  <div class="your-page">
    <div class="section">
      <div class="container">
        <ins class="adsbygoogle"
             style="display:block"
             data-ad-client="ca-pub-8649481054784994"
             data-ad-slot="4824315952"
             data-ad-format="auto"
             data-adtest="off"
             data-full-width-responsive="true">
        </ins>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AdSense',
  mounted () {
    this.adsenseAddLoad();
  },
  methods:{
    adsenseAddLoad(){
      let inlineScript   = document.createElement("script");
      inlineScript.type  = "text/javascript";
      inlineScript.text  = '(adsbygoogle = window.adsbygoogle || []).push({});'
      document.getElementsByTagName('body')[0].appendChild(inlineScript);
    }
  }
}
</script>