<template>
    <div class="scroll-width-thin">
      <!-- Loading -->
<!--      <Loader class="mt-2" :loading="loading"></Loader>-->

      <div v-if="formData" class="p-3">

        <div v-if="formData.room_type_id && formData.room_type_id === 8 && conditionsRoomSuite" class="row m-0 p-0">
          <div class="form-group col-12 text-center">
            <!-- Start Horizontal Line with Icon -->
            <div class="horizontal-separator-box">
              <span class="hr-line-short"></span>
              <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
              <span class="hr-line-short"></span>
            </div>
            <!-- End Horizontal Line with Icon -->

            <!-- Information -->
            <small class="p-3 fw-bold">Rooms</small>

            <!-- Start Horizontal Line with Icon -->
            <div class="horizontal-separator-box">
              <span class="hr-line-short"></span>
              <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
              <span class="hr-line-short"></span>
            </div>
            <!-- End Horizontal Line with Icon -->
          </div>
        </div>

        <!-- Single and Double Rooms for Whole Place Suite/Apartment option -->
        <div v-if="formData.room_type_id && formData.room_type_id === 8 && conditionsRoomSuite" class="row m-0 p-0">
          <!-- Single Rooms -->
          <div v-if="formData.property_room_detail && formData.property_room_detail.single_beds" class="col-8 pt-2 mb-1">
            <div class="list-relative">
              <span data-tooltip="tooltip">
                <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                  <p class="options-paragraph m-0 p-0 me-2 d-inline">Single Rooms</p>
                  <font-awesome-icon icon="dice-one" class="icon-fa" />
                  <font-awesome-icon icon="bed" class="icon-fa" />
                </div>
              </span>
            </div>
          </div>
          <div v-if="formData.property_room_detail && formData.property_room_detail.single_beds" class="col-4 pt-2 mb-1">
            {{ formData.property_room_detail.single_beds }}
          </div>
          <!-- Double Rooms -->
          <div v-if="formData.property_room_detail && formData.property_room_detail.double_beds" class="col-8 pt-2 mb-1">
            <div class="list-relative">
              <span data-tooltip="tooltip">
                <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                  <p class="options-paragraph m-0 p-0 me-2 d-inline">Double Rooms</p>
                  <font-awesome-icon icon="dice-two" class="icon-fa" />
                  <font-awesome-icon icon="bed" class="icon-fa" />
                </div>
              </span>
            </div>
          </div>
          <div v-if="formData.property_room_detail && formData.property_room_detail.double_beds" class="col-4 pt-2 mb-1">
            {{ formData.property_room_detail.double_beds }}
          </div>
        </div>

        <div v-if="formData.room_type_id && formData.room_type_id !== 8 && conditionsRoomBeds" class="row m-0 p-0">
          <div class="form-group col-12 text-center">
            <!-- Start Horizontal Line with Icon -->
            <div class="horizontal-separator-box">
              <span class="hr-line-short"></span>
              <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
              <span class="hr-line-short"></span>
            </div>
            <!-- End Horizontal Line with Icon -->

            <!-- Information -->
            <small class="p-3 fw-bold">Beds</small>

            <!-- Start Horizontal Line with Icon -->
            <div class="horizontal-separator-box">
              <span class="hr-line-short"></span>
              <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
              <span class="hr-line-short"></span>
            </div>
            <!-- End Horizontal Line with Icon -->
          </div>
        </div>

        <!-- Bed options for other than 8 - Whole Place -->
        <div v-if="formData.room_type_id && formData.room_type_id !== 8 && conditionsRoomBeds" class="row m-0 p-0">
          <!-- Single Beds -->
          <div v-if="formData.property_room_detail && formData.property_room_detail.single_beds" class="col-8 pt-2 mb-1">
            <div class="list-relative">
              <span data-tooltip="tooltip">
                <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                  <p class="options-paragraph m-0 p-0 me-2 d-inline">Single Beds</p>
                  <font-awesome-icon icon="dice-one" class="icon-fa" />
                  <font-awesome-icon icon="bed" class="icon-fa" />
                </div>
              </span>
            </div>
          </div>
          <div v-if="formData.property_room_detail && formData.property_room_detail.single_beds" class="col-4 pt-2 mb-1">
            {{ formData.property_room_detail.single_beds }}
          </div>
          <!-- Double Beds -->
          <div v-if="formData.property_room_detail && formData.property_room_detail.double_beds" class="col-8 pt-2 mb-1">
            <div class="list-relative">
              <span data-tooltip="tooltip">
                <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                  <p class="options-paragraph m-0 p-0 me-2 d-inline">Double Beds</p>
                  <font-awesome-icon icon="dice-two" class="icon-fa" />
                  <font-awesome-icon icon="bed" class="icon-fa" />
                </div>
              </span>
            </div>
          </div>
          <div v-if="formData.property_room_detail && formData.property_room_detail.double_beds" class="col-4 pt-2 mb-1">
            {{ formData.property_room_detail.double_beds }}
          </div>
          <!-- Queen Beds -->
          <div v-if="formData.property_room_detail && formData.property_room_detail.queen_beds" class="col-8 pt-2 mb-1">
            <div class="list-relative">
              <span data-tooltip="tooltip">
                <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                  <p class="options-paragraph m-0 p-0 me-2 d-inline">Queen Beds</p>
                  <font-awesome-icon icon="chess-queen" class="icon-fa" />
                  <font-awesome-icon icon="bed" class="icon-fa" />
                </div>
              </span>
            </div>
          </div>
          <div v-if="formData.property_room_detail && formData.property_room_detail.queen_beds" class="col-4 pt-2 mb-1">
            {{ formData.property_room_detail.queen_beds }}
          </div>
          <!-- King Beds -->
          <div v-if="formData.property_room_detail && formData.property_room_detail.king_beds" class="col-8 pt-2 mb-1">
            <div class="list-relative">
              <span data-tooltip="tooltip">
                <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                  <p class="options-paragraph m-0 p-0 me-2 d-inline">King Beds</p>
                  <font-awesome-icon icon="chess-king" class="icon-fa" />
                  <font-awesome-icon icon="bed" class="icon-fa" />
                </div>
              </span>
            </div>
          </div>
          <div v-if="formData.property_room_detail && formData.property_room_detail.king_beds" class="col-4 pt-2 mb-1">
            {{ formData.property_room_detail.king_beds }}
          </div>
          <!-- Zip and Link Beds -->
          <div v-if="formData.property_room_detail && formData.property_room_detail.zip_and_link_beds" class="col-8 pt-2 mb-1">
            <div class="list-relative">
              <span data-tooltip="tooltip">
                <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                  <p class="options-paragraph m-0 p-0 me-2 d-inline">Zink and Link Beds</p>
                  <font-awesome-icon icon="link" class="icon-fa" />
                  <font-awesome-icon icon="bed" class="icon-fa" />
                </div>
              </span>
            </div>
          </div>
          <div v-if="formData.property_room_detail && formData.property_room_detail.zip_and_link_beds" class="col-4 pt-2 mb-1">
            {{ formData.property_room_detail.zip_and_link_beds }}
          </div>
          <!-- Water Beds -->
          <div v-if="formData.property_room_detail && formData.property_room_detail.water_beds" class="col-8 pt-2 mb-1">
            <div class="list-relative">
              <span data-tooltip="tooltip">
                <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                  <p class="options-paragraph m-0 p-0 me-2 d-inline">Water Beds</p>
                  <font-awesome-icon icon="water" class="icon-fa" />
                  <font-awesome-icon icon="bed" class="icon-fa" />
                  <span class="tooltiptext">How many water beds are in this room?</span>
                </div>
              </span>
            </div>
          </div>
          <div v-if="formData.property_room_detail && formData.property_room_detail.water_beds" class="col-4 pt-2 mb-1">
            {{ formData.water_beds }}
          </div>
          <!-- Sofa Beds -->
          <div v-if="formData.property_room_detail && formData.property_room_detail.sofa_beds" class="col-8 pt-2 mb-1">
            <div class="list-relative">
              <span data-tooltip="tooltip">
                <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                  <p class="options-paragraph m-0 p-0 me-2 d-inline">Sofa Beds</p>
                  <font-awesome-icon icon="couch" class="icon-fa" />
                </div>
              </span>
            </div>
          </div>
          <div v-if="formData.property_room_detail && formData.property_room_detail.sofa_beds" class="col-4 pt-2 mb-1">
            {{ formData.sofa_beds }}
          </div>
          <!-- Bunk Beds -->
          <div v-if="formData.property_room_detail && formData.property_room_detail.bunk_beds" class="col-8 pt-2 mb-1">
            <div class="list-relative">
              <span data-tooltip="tooltip">
                <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                  <p class="options-paragraph m-0 p-0 me-2 d-inline">Bunk Beds</p>
                  <font-awesome-icon icon="layer-group" class="icon-fa" />
                  <font-awesome-icon icon="bed" class="icon-fa" />
                </div>
              </span>
            </div>
          </div>
          <div v-if="formData.property_room_detail && formData.property_room_detail.bunk_beds" class="col-4 pt-2 mb-1">
            {{ formData.bunk_beds }}
          </div>
          <!-- Toddler Beds -->
          <div v-if="formData.property_room_detail && formData.property_room_detail.toddler_beds" class="col-8 pt-2 mb-1">
            <div class="list-relative">
              <span data-tooltip="tooltip">
                <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                  <p class="options-paragraph m-0 p-0 me-2 d-inline">Toddler Beds</p>
                  <font-awesome-icon icon="baby" class="icon-fa" />
                </div>
              </span>
            </div>
          </div>
          <div v-if="formData.property_room_detail && formData.property_room_detail.toddler_beds" class="col-4 pt-2 mb-1">
            {{ formData.toddler_beds }}
          </div>
          <!-- Cribs -->
          <div v-if="formData.property_room_detail && formData.property_room_detail.cribs" class="col-8 pt-2 mb-1">
            <div class="list-relative">
              <span data-tooltip="tooltip">
                <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                  <p class="options-paragraph m-0 p-0 me-2 d-inline">Cribs</p>
                  <font-awesome-icon icon="baby-carriage" class="icon-fa" />
                </div>
              </span>
            </div>
          </div>
          <div v-if="formData.property_room_detail && formData.property_room_detail.cribs" class="col-4 pt-2 mb-1">
            {{ formData.cribs }}
          </div>
          <!-- Hammock Beds -->
          <div v-if="formData.property_room_detail && formData.property_room_detail.hammocks" class="col-8 pt-2 mb-1">
            <div class="list-relative">
              <span data-tooltip="tooltip">
                <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                  <p class="options-paragraph m-0 p-0 me-2 d-inline">Hammocks</p>
                  <font-awesome-icon icon="feather" class="icon-fa" />
                </div>
              </span>
            </div>
          </div>
          <div v-if="formData.property_room_detail && formData.property_room_detail.hammocks" class="col-4 pt-2 mb-1">
            {{ formData.hammocks }}
          </div>
        </div>

        <div v-if="formData.room_type_id && formData.room_type_id !== 8 && conditionsRoomKitchen" class="row m-0 p-0">
          <div class="form-group col-12 text-center">
            <!-- Start Horizontal Line with Icon -->
            <div class="horizontal-separator-box">
              <span class="hr-line-short"></span>
              <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
              <span class="hr-line-short"></span>
            </div>
            <!-- End Horizontal Line with Icon -->

            <!-- Information -->
            <small class="p-3 fw-bold">Kitchen Like Extras</small>

            <!-- Start Horizontal Line with Icon -->
            <div class="horizontal-separator-box">
              <span class="hr-line-short"></span>
              <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
              <span class="hr-line-short"></span>
            </div>
            <!-- End Horizontal Line with Icon -->
          </div>
        </div>

        <!-- Start Kitchen Like & Extra Items Switches -->
        <div v-if="formData.room_type_id && formData.room_type_id !== 8 && conditionsRoomKitchen" class="row m-0 p-0">
          <div class="col-12 p-0 mt-1 mb-1">
            <div class="row m-0 p-0">
              <!-- Test inline list -->
              <ul class="list-unstyled">
                <li v-if="formData.property_room_detail && formData.property_room_detail.fridge" class="d-inline me-1">
                  <p class="options-paragraph m-0 p-0 me-2 d-inline-block">
                    <font-awesome-icon icon="hamburger" class="icon-fa" /> Fridge <font-awesome-icon icon="check" class="icon-fa green-text" />
                  </p>
                </li>
                <li v-if="formData.property_room_detail && formData.property_room_detail.freezer" class="d-inline me-1">
                  <p class="options-paragraph m-0 p-0 me-2 d-inline-block">
                    <font-awesome-icon icon="snowflake" class="icon-fa" /> Freezer <font-awesome-icon icon="check" class="icon-fa green-text" />
                  </p>
                </li>
                <li v-if="formData.property_room_detail && formData.property_room_detail.microwave" class="d-inline me-1">
                  <p class="options-paragraph m-0 p-0 me-2 d-inline-block">
                    <font-awesome-icon icon="pager" class="icon-fa" /> Microwave <font-awesome-icon icon="check" class="icon-fa green-text" />
                  </p>
                </li>
                <li v-if="formData.property_room_detail && formData.property_room_detail.kettle" class="d-inline me-1">
                  <p class="options-paragraph m-0 p-0 me-2 d-inline-block">
                    <font-awesome-icon icon="mug-hot" class="icon-fa" /> Kettle <font-awesome-icon icon="check" class="icon-fa green-text" />
                  </p>
                </li>
                <li v-if="formData.property_room_detail && formData.property_room_detail.coffee" class="d-inline me-1">
                  <p class="options-paragraph m-0 p-0 me-2 d-inline-block">
                    <font-awesome-icon icon="coffee" class="icon-fa" /> Coffee <font-awesome-icon icon="check" class="icon-fa green-text" />
                  </p>
                </li>
                <li v-if="formData.property_room_detail && formData.property_room_detail.toaster" class="d-inline me-1">
                  <p class="options-paragraph m-0 p-0 me-2 d-inline-block">
                    <font-awesome-icon icon="bread-slice" class="icon-fa" /> Toaster <font-awesome-icon icon="check" class="icon-fa green-text" />
                  </p>
                </li>
              </ul>

<!--              &lt;!&ndash; Fridge &ndash;&gt;-->
<!--              <div v-if="formData.property_room_detail && formData.property_room_detail.fridge" class="col-12 p-0 mt-1 mb-1">-->
<!--                <div class="row m-0 p-0">-->
<!--                  <div class="col-8">-->
<!--                    <div class="list-relative">-->
<!--                      <span data-tooltip="tooltip">-->
<!--                        <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">-->
<!--                          <p class="options-paragraph m-0 p-0 me-2 d-inline">Fridge</p>-->
<!--                          <font-awesome-icon icon="hamburger" class="icon-fa" />-->
<!--                        </div>-->
<!--                      </span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="col-4">-->
<!--                    <font-awesome-icon v-if="formData.property_room_detail.fridge" icon="check" class="icon-fa green-text" />-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              &lt;!&ndash; Freezer &ndash;&gt;-->
<!--              <div v-if="formData.property_room_detail && formData.property_room_detail.freezer" class="col-12 p-0 mt-1 mb-1">-->
<!--                <div class="row m-0 p-0">-->
<!--                  <div class="col-8">-->
<!--                    <div class="list-relative">-->
<!--                      <span data-tooltip="tooltip">-->
<!--                        <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">-->
<!--                          <p class="options-paragraph m-0 p-0 me-2 d-inline">Freezer</p>-->
<!--                          <font-awesome-icon icon="snowflake" class="icon-fa" />-->
<!--                        </div>-->
<!--                      </span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="col-4">-->
<!--                    <font-awesome-icon v-if="formData.property_room_detail.freezer" icon="check" class="icon-fa green-text" />-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              &lt;!&ndash; Microwave &ndash;&gt;-->
<!--              <div v-if="formData.property_room_detail && formData.property_room_detail.microwave" class="col-12 p-0 mt-1 mb-1">-->
<!--                <div class="row m-0 p-0">-->
<!--                  <div class="col-8">-->
<!--                    <div class="list-relative">-->
<!--                      <span data-tooltip="tooltip">-->
<!--                        <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">-->
<!--                          <p class="options-paragraph m-0 p-0 me-2 d-inline">Microwave</p>-->
<!--                          <font-awesome-icon icon="pager" class="icon-fa" />-->
<!--                        </div>-->
<!--                      </span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="col-4">-->
<!--                    <font-awesome-icon v-if="formData.property_room_detail.microwave" icon="check" class="icon-fa green-text" />-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              &lt;!&ndash; Kettle &ndash;&gt;-->
<!--              <div v-if="formData.property_room_detail && formData.property_room_detail.kettle" class="col-12 p-0 mt-1 mb-1">-->
<!--                <div class="row m-0 p-0">-->
<!--                  <div class="col-8">-->
<!--                    <div class="list-relative">-->
<!--                      <span data-tooltip="tooltip">-->
<!--                        <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">-->
<!--                          <p class="options-paragraph m-0 p-0 me-2 d-inline">Kettle</p>-->
<!--                          <font-awesome-icon icon="mug-hot" class="icon-fa" />-->
<!--                        </div>-->
<!--                      </span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="col-4">-->
<!--                    <font-awesome-icon v-if="formData.property_room_detail.kettle" icon="check" class="icon-fa green-text" />-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              &lt;!&ndash; Coffee &ndash;&gt;-->
<!--              <div v-if="formData.property_room_detail && formData.property_room_detail.coffee" class="col-12 p-0 mt-1 mb-1">-->
<!--                <div class="row m-0 p-0">-->
<!--                  <div class="col-8">-->
<!--                    <div class="list-relative">-->
<!--                      <span data-tooltip="tooltip">-->
<!--                        <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">-->
<!--                          <p class="options-paragraph m-0 p-0 me-2 d-inline">Coffee</p>-->
<!--                          <font-awesome-icon icon="coffee" class="icon-fa" />-->
<!--                        </div>-->
<!--                      </span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="col-4">-->
<!--                    <font-awesome-icon v-if="formData.property_room_detail.coffee" icon="check" class="icon-fa green-text" />-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              &lt;!&ndash; Toaster &ndash;&gt;-->
<!--              <div v-if="formData.property_room_detail && formData.property_room_detail.toaster" class="col-12 p-0 mt-1 mb-1">-->
<!--                <div class="row m-0 p-0">-->
<!--                  <div class="col-8">-->
<!--                    <div class="list-relative">-->
<!--                      <span data-tooltip="tooltip">-->
<!--                        <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">-->
<!--                          <p class="options-paragraph m-0 p-0 me-2 d-inline">Toaster</p>-->
<!--                          <font-awesome-icon icon="bread-slice" class="icon-fa" />-->
<!--                        </div>-->
<!--                      </span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="col-4">-->
<!--                    <font-awesome-icon v-if="formData.property_room_detail.toaster" icon="check" class="icon-fa green-text" />-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
            </div>
          </div>
        </div>
        <!-- End Kitchen Like & Extra Items Switches -->

        <div v-if="formData.room_type_id && formData.room_type_id !== 8 && conditionsRoomBathroom" class="row m-0 p-0">
          <div class="form-group col-12 text-center">
            <!-- Start Horizontal Line with Icon -->
            <div class="horizontal-separator-box">
              <span class="hr-line-short"></span>
              <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
              <span class="hr-line-short"></span>
            </div>
            <!-- End Horizontal Line with Icon -->

            <!-- Information -->
            <small class="p-3 fw-bold">En-suite & Extras</small>

            <!-- Start Horizontal Line with Icon -->
            <div class="horizontal-separator-box">
              <span class="hr-line-short"></span>
              <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
              <span class="hr-line-short"></span>
            </div>
            <!-- End Horizontal Line with Icon -->
          </div>
        </div>

        <!-- Start Toilet and Extras Switches -->
        <div v-if="formData.room_type_id && formData.room_type_id !== 8 && conditionsRoomBathroom" class="row m-0 p-0">
          <div class="col-12 p-0 mt-1 mb-1">
            <div class="row m-0 p-0">

              <ul class="list-unstyled">
                <li v-if="formData.property_room_detail && formData.property_room_detail.wc" class="d-inline me-1">
                  <p class="options-paragraph m-0 p-0 me-2 d-inline-block">
                    <font-awesome-icon icon="toilet" class="icon-fa" /> WC <font-awesome-icon icon="check" class="icon-fa green-text" />
                  </p>
                </li>
                <li v-if="formData.property_room_detail && formData.property_room_detail.sink" class="d-inline me-1">
                  <p class="options-paragraph m-0 p-0 me-2 d-inline-block">
                    <font-awesome-icon icon="sink" /> Sink <font-awesome-icon icon="check" class="icon-fa green-text" />
                  </p>
                </li>
                <li v-if="formData.property_room_detail && formData.property_room_detail.bath" class="d-inline me-1">
                  <p class="options-paragraph m-0 p-0 me-2 d-inline-block">
                    <font-awesome-icon icon="bath" /> Bath <font-awesome-icon icon="check" class="icon-fa green-text" />
                  </p>
                </li>
                <li v-if="formData.property_room_detail && formData.property_room_detail.shower" class="d-inline me-1">
                  <p class="options-paragraph m-0 p-0 me-2 d-inline-block">
                    <font-awesome-icon icon="shower" /> Shower <font-awesome-icon icon="check" class="icon-fa green-text" />
                  </p>
                </li>
                <li v-if="formData.property_room_detail && formData.property_room_detail.free_toiletries" class="d-inline me-1">
                  <p class="options-paragraph m-0 p-0 me-2 d-inline-block">
                    <font-awesome-icon icon="pump-soap" class="icon-fa" /> Free Toiletries <font-awesome-icon icon="check" class="icon-fa green-text" />
                  </p>
                </li>
                <li v-if="formData.property_room_detail && formData.property_room_detail.towels" class="d-inline me-1">
                  <p class="options-paragraph m-0 p-0 me-2 d-inline-block">
                    <font-awesome-icon icon="layer-group" class="icon-fa" /> Towels <font-awesome-icon icon="check" class="icon-fa green-text" />
                  </p>
                </li>
              </ul>

<!--              &lt;!&ndash; WC &ndash;&gt;-->
<!--              <div v-if="formData.property_room_detail && formData.property_room_detail.wc" class="col-12 p-0 mt-1 mb-1">-->
<!--                <div class="row m-0 p-0">-->
<!--                  <div class="col-8">-->
<!--                    <div class="list-relative">-->
<!--                      <span data-tooltip="tooltip">-->
<!--                        <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">-->
<!--                          <p class="options-paragraph m-0 p-0 me-2 d-inline">WC</p>-->
<!--                          <font-awesome-icon icon="toilet" class="icon-fa" />-->
<!--                        </div>-->
<!--                      </span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="col-4">-->
<!--                    <font-awesome-icon v-if="formData.property_room_detail.wc" icon="check" class="icon-fa green-text" />-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              &lt;!&ndash; Sink &ndash;&gt;-->
<!--              <div v-if="formData.property_room_detail && formData.property_room_detail.sink" class="col-12 p-0 mt-1 mb-1">-->
<!--                <div class="row m-0 p-0">-->
<!--                  <div class="col-8">-->
<!--                    <div class="list-relative">-->
<!--                      <span data-tooltip="tooltip">-->
<!--                        <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">-->
<!--                          <p class="options-paragraph m-0 p-0 me-2 d-inline">Sink</p>-->
<!--                          <font-awesome-icon icon="sink" />-->
<!--                        </div>-->
<!--                      </span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="col-4">-->
<!--                    <font-awesome-icon v-if="formData.property_room_detail.sink" icon="check" class="icon-fa green-text" />-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              &lt;!&ndash; Bath &ndash;&gt;-->
<!--              <div v-if="formData.property_room_detail && formData.property_room_detail.bath" class="col-12 p-0 mt-1 mb-1">-->
<!--                <div class="row m-0 p-0">-->
<!--                  <div class="col-8">-->
<!--                    <div class="list-relative">-->
<!--                      <span data-tooltip="tooltip">-->
<!--                        <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">-->
<!--                          <p class="options-paragraph m-0 p-0 me-2 d-inline">Bath</p>-->
<!--                          <font-awesome-icon icon="bath" />-->
<!--                        </div>-->
<!--                      </span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="col-4">-->
<!--                    <font-awesome-icon v-if="formData.property_room_detail.bath" icon="check" class="icon-fa green-text" />-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              &lt;!&ndash; Shower &ndash;&gt;-->
<!--              <div v-if="formData.property_room_detail && formData.property_room_detail.shower" class="col-12 p-0 mt-1 mb-1">-->
<!--                <div class="row m-0 p-0">-->
<!--                  <div class="col-8">-->
<!--                    <div class="list-relative">-->
<!--                      <span data-tooltip="tooltip">-->
<!--                        <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">-->
<!--                          <p class="options-paragraph m-0 p-0 me-2 d-inline">Shower</p>-->
<!--                          <font-awesome-icon icon="shower" />-->
<!--                        </div>-->
<!--                      </span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="col-4">-->
<!--                    <font-awesome-icon v-if="formData.property_room_detail.shower" icon="check" class="icon-fa green-text" />-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              &lt;!&ndash; Free Toiletries &ndash;&gt;-->
<!--              <div v-if="formData.property_room_detail && formData.property_room_detail.free_toiletries" class="col-12 p-0 mt-1 mb-1">-->
<!--                <div class="row m-0 p-0">-->
<!--                  <div class="col-8">-->
<!--                    <div class="list-relative">-->
<!--                      <span data-tooltip="tooltip">-->
<!--                        <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">-->
<!--                          <p class="options-paragraph m-0 p-0 me-2 d-inline">Free Toiletries</p>-->
<!--                          <font-awesome-icon icon="pump-soap" class="icon-fa" />-->
<!--                        </div>-->
<!--                      </span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="col-4">-->
<!--                    <font-awesome-icon v-if="formData.property_room_detail.free_toiletries" icon="check" class="icon-fa green-text" />-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              &lt;!&ndash; Towels &ndash;&gt;-->
<!--              <div v-if="formData.property_room_detail && formData.property_room_detail.towels" class="col-12 p-0 mt-1 mb-1">-->
<!--                <div class="row m-0 p-0">-->
<!--                  <div class="col-8">-->
<!--                    <div class="list-relative">-->
<!--                      <span data-tooltip="tooltip">-->
<!--                        <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">-->
<!--                          <p class="options-paragraph m-0 p-0 me-2 d-inline">Towels</p>-->
<!--                          <font-awesome-icon icon="layer-group" class="icon-fa" />-->
<!--                        </div>-->
<!--                      </span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="col-4">-->
<!--                    <font-awesome-icon v-if="formData.property_room_detail.towels" icon="check" class="icon-fa green-text" />-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
            </div>
          </div>
        </div>
        <!-- End Toilet and Extras Switches -->

        <div v-if="formData.room_type_id && formData.room_type_id !== 8 && conditionsRoomPractical" class="row m-0 p-0">
          <div class="form-group col-12 text-center">
            <!-- Start Horizontal Line with Icon -->
            <div class="horizontal-separator-box">
              <span class="hr-line-short"></span>
              <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
              <span class="hr-line-short"></span>
            </div>
            <!-- End Horizontal Line with Icon -->

            <!-- Information -->
            <small class="p-3 fw-bold">Practical</small>

            <!-- Start Horizontal Line with Icon -->
            <div class="horizontal-separator-box">
              <span class="hr-line-short"></span>
              <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
              <span class="hr-line-short"></span>
            </div>
            <!-- End Horizontal Line with Icon -->
          </div>
        </div>

        <!-- Start Practical Switches -->
        <div v-if="formData.room_type_id && formData.room_type_id !== 8 && conditionsRoomPractical" class="row m-0 p-0">
          <div class="col-12 p-0 mt-1 mb-1">
            <div class="row m-0 p-0">

              <ul class="list-unstyled">
                <li v-if="formData.property_room_detail && formData.property_room_detail.wifi" class="d-inline me-1">
                  <p class="options-paragraph m-0 p-0 me-2 d-inline-block">
                    <font-awesome-icon icon="wifi" class="icon-fa" /> Wi-Fi <font-awesome-icon icon="check" class="icon-fa green-text" />
                  </p>
                </li>
                <li v-if="formData.property_room_detail && formData.property_room_detail.tv" class="d-inline me-1">
                  <p class="options-paragraph m-0 p-0 me-2 d-inline-block">
                    <font-awesome-icon icon="tv" class="icon-fa" /> TV <font-awesome-icon icon="check" class="icon-fa green-text" />
                  </p>
                </li>
                <li v-if="formData.property_room_detail && formData.property_room_detail.work_desk" class="d-inline me-1">
                  <p class="options-paragraph m-0 p-0 me-2 d-inline-block">
                    <font-awesome-icon icon="laptop-house" class="icon-fa" /> Work Desk <font-awesome-icon icon="check" class="icon-fa green-text" />
                  </p>
                </li>
                <li v-if="formData.property_room_detail && formData.property_room_detail.air_conditioning" class="d-inline me-1">
                  <p class="options-paragraph m-0 p-0 me-2 d-inline-block">
                    <font-awesome-icon icon="temperature-low" class="icon-fa" /> Air Conditioning <font-awesome-icon icon="check" class="icon-fa green-text" />
                  </p>
                </li>
                <li v-if="formData.property_room_detail && formData.property_room_detail.fan" class="d-inline me-1">
                  <p class="options-paragraph m-0 p-0 me-2 d-inline-block">
                    <font-awesome-icon icon="fan" class="icon-fa" /> Fan <font-awesome-icon icon="check" class="icon-fa green-text" />
                  </p>
                </li>
                <li v-if="formData.property_room_detail && formData.property_room_detail.heating" class="d-inline me-1">
                  <p class="options-paragraph m-0 p-0 me-2 d-inline-block">
                    <font-awesome-icon icon="temperature-high" class="icon-fa" /> Heating <font-awesome-icon icon="check" class="icon-fa green-text" />
                  </p>
                </li>
                <li v-if="formData.property_room_detail && formData.property_room_detail.mosquito_net" class="d-inline me-1">
                  <p class="options-paragraph m-0 p-0 me-2 d-inline-block">
                    <font-awesome-icon icon="border-none" class="icon-fa" /> Mosquito Net <font-awesome-icon icon="check" class="icon-fa green-text" />
                  </p>
                </li>
                <li v-if="formData.property_room_detail && formData.property_room_detail.board_games" class="d-inline me-1">
                  <p class="options-paragraph m-0 p-0 me-2 d-inline-block">
                    <font-awesome-icon icon="dice" class="icon-fa" /> Board Games <font-awesome-icon icon="check" class="icon-fa green-text" />
                  </p>
                </li>
                <li v-if="formData.property_room_detail && formData.property_room_detail.digital_games" class="d-inline me-1">
                  <p class="options-paragraph m-0 p-0 me-2 d-inline-block">
                    <font-awesome-icon icon="gamepad" class="icon-fa" /> Digital Games <font-awesome-icon icon="check" class="icon-fa green-text" />
                  </p>
                </li>
                <li v-if="formData.property_room_detail && formData.property_room_detail.radio" class="d-inline me-1">
                  <p class="options-paragraph m-0 p-0 me-2 d-inline-block">
                    <font-awesome-icon icon="rss" class="icon-fa" /> Radio <font-awesome-icon icon="check" class="icon-fa green-text" />
                  </p>
                </li>
                <li v-if="formData.property_room_detail && formData.property_room_detail.iron" class="d-inline me-1">
                  <p class="options-paragraph m-0 p-0 me-2 d-inline-block">
                    <font-awesome-icon icon="shield-alt" class="icon-fa" /> Iron <font-awesome-icon icon="check" class="icon-fa green-text" />
                  </p>
                </li>
                <li v-if="formData.property_room_detail && formData.property_room_detail.hairdryer" class="d-inline me-1">
                  <p class="options-paragraph m-0 p-0 me-2 d-inline-block">
                    <font-awesome-icon icon="wind" class="icon-fa" /> Hairdryer <font-awesome-icon icon="check" class="icon-fa green-text" />
                  </p>
                </li>
                <li v-if="formData.property_room_detail && formData.property_room_detail.slippers" class="d-inline me-1">
                  <p class="options-paragraph m-0 p-0 me-2 d-inline-block">
                    <font-awesome-icon icon="shoe-prints" class="icon-fa" /> Slippers <font-awesome-icon icon="check" class="icon-fa green-text" />
                  </p>
                </li>
                <li v-if="formData.property_room_detail && formData.property_room_detail.hangers" class="d-inline me-1">
                  <p class="options-paragraph m-0 p-0 me-2 d-inline-block">
                    <font-awesome-icon icon="angle-up" class="icon-fa" /> Hangers <font-awesome-icon icon="check" class="icon-fa green-text" />
                  </p>
                </li>
              </ul>

<!--              &lt;!&ndash; Wi-Fi &ndash;&gt;-->
<!--              <div v-if="formData.property_room_detail && formData.property_room_detail.wifi" class="col-12 p-0 mt-1 mb-1">-->
<!--                <div class="row m-0 p-0">-->
<!--                  <div class="col-8">-->
<!--                    <div class="list-relative">-->
<!--                      <span data-tooltip="tooltip">-->
<!--                        <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">-->
<!--                          <p class="options-paragraph m-0 p-0 me-2 d-inline">Wi-Fi</p>-->
<!--                          <font-awesome-icon icon="wifi" class="icon-fa" />-->
<!--                        </div>-->
<!--                      </span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="col-4">-->
<!--                    <font-awesome-icon v-if="formData.property_room_detail.wifi" icon="check" class="icon-fa green-text" />-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              &lt;!&ndash; TV &ndash;&gt;-->
<!--              <div v-if="formData.property_room_detail && formData.property_room_detail.tv" class="col-12 p-0 mt-1 mb-1">-->
<!--                <div class="row m-0 p-0">-->
<!--                  <div class="col-8">-->
<!--                    <div class="list-relative">-->
<!--                      <span data-tooltip="tooltip">-->
<!--                        <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">-->
<!--                          <p class="options-paragraph m-0 p-0 me-2 d-inline">TV</p>-->
<!--                          <font-awesome-icon icon="tv" class="icon-fa" />-->
<!--                        </div>-->
<!--                      </span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="col-4">-->
<!--                    <font-awesome-icon v-if="formData.property_room_detail.tv" icon="check" class="icon-fa green-text" />-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              &lt;!&ndash; Work Desk &ndash;&gt;-->
<!--              <div v-if="formData.property_room_detail && formData.property_room_detail.work_desk" class="col-12 p-0 mt-1 mb-1">-->
<!--                <div class="row m-0 p-0">-->
<!--                  <div class="col-8">-->
<!--                    <div class="list-relative">-->
<!--                      <span data-tooltip="tooltip">-->
<!--                        <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">-->
<!--                          <p class="options-paragraph m-0 p-0 me-2 d-inline">Work Desk</p>-->
<!--                          <font-awesome-icon icon="laptop-house" class="icon-fa" />-->
<!--                        </div>-->
<!--                      </span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="col-4">-->
<!--                    <font-awesome-icon v-if="formData.property_room_detail.work_desk" icon="check" class="icon-fa green-text" />-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              &lt;!&ndash; Air Conditioning &ndash;&gt;-->
<!--              <div v-if="formData.property_room_detail && formData.property_room_detail.air_conditioning" class="col-12 p-0 mt-1 mb-1">-->
<!--                <div class="row m-0 p-0">-->
<!--                  <div class="col-8">-->
<!--                    <div class="list-relative">-->
<!--                      <span data-tooltip="tooltip">-->
<!--                        <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">-->
<!--                          <p class="options-paragraph m-0 p-0 me-2 d-inline">Air Conditioning</p>-->
<!--                          <font-awesome-icon icon="temperature-low" class="icon-fa" />-->
<!--                        </div>-->
<!--                      </span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="col-4">-->
<!--                    <font-awesome-icon v-if="formData.property_room_detail.air_conditioning" icon="check" class="icon-fa green-text" />-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              &lt;!&ndash; Fan &ndash;&gt;-->
<!--              <div v-if="formData.property_room_detail && formData.property_room_detail.fan" class="col-12 p-0 mt-1 mb-1">-->
<!--                <div class="row m-0 p-0">-->
<!--                  <div class="col-8">-->
<!--                    <div class="list-relative">-->
<!--                      <span data-tooltip="tooltip">-->
<!--                        <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">-->
<!--                          <p class="options-paragraph m-0 p-0 me-2 d-inline">Fan</p>-->
<!--                          <font-awesome-icon icon="fan" class="icon-fa" />-->
<!--                        </div>-->
<!--                      </span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="col-4">-->
<!--                    <font-awesome-icon v-if="formData.property_room_detail.fan" icon="check" class="icon-fa green-text" />-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              &lt;!&ndash; Heating &ndash;&gt;-->
<!--              <div v-if="formData.property_room_detail && formData.property_room_detail.heating" class="col-12 p-0 mt-1 mb-1">-->
<!--                <div class="row m-0 p-0">-->
<!--                  <div class="col-8">-->
<!--                    <div class="list-relative">-->
<!--                      <span data-tooltip="tooltip">-->
<!--                        <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">-->
<!--                          <p class="options-paragraph m-0 p-0 me-2 d-inline">Heating</p>-->
<!--                          <font-awesome-icon icon="temperature-high" class="icon-fa" />-->
<!--                        </div>-->
<!--                      </span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="col-4">-->
<!--                    <font-awesome-icon v-if="formData.property_room_detail.heating" icon="check" class="icon-fa green-text" />-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              &lt;!&ndash; Mosquito Net &ndash;&gt;-->
<!--              <div v-if="formData.property_room_detail && formData.property_room_detail.mosquito_net" class="col-12 p-0 mt-1 mb-1">-->
<!--                <div class="row m-0 p-0">-->
<!--                  <div class="col-8">-->
<!--                    <div class="list-relative">-->
<!--                      <span data-tooltip="tooltip">-->
<!--                        <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">-->
<!--                          <p class="options-paragraph m-0 p-0 me-2 d-inline">Mosquito Net</p>-->
<!--                          <font-awesome-icon icon="border-none" class="icon-fa" />-->
<!--                        </div>-->
<!--                      </span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="col-4">-->
<!--                    <font-awesome-icon v-if="formData.property_room_detail.mosquito_net" icon="check" class="icon-fa green-text" />-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              &lt;!&ndash; Board Games &ndash;&gt;-->
<!--              <div v-if="formData.property_room_detail && formData.property_room_detail.board_games" class="col-12 p-0 mt-1 mb-1">-->
<!--                <div class="row m-0 p-0">-->
<!--                  <div class="col-8">-->
<!--                    <div class="list-relative">-->
<!--                      <span data-tooltip="tooltip">-->
<!--                        <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">-->
<!--                          <p class="options-paragraph m-0 p-0 me-2 d-inline">Board Games</p>-->
<!--                          <font-awesome-icon icon="dice" class="icon-fa" />-->
<!--                        </div>-->
<!--                      </span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="col-4">-->
<!--                    <font-awesome-icon v-if="formData.property_room_detail.board_games" icon="check" class="icon-fa green-text" />-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              &lt;!&ndash; Digital Games &ndash;&gt;-->
<!--              <div v-if="formData.property_room_detail && formData.property_room_detail.digital_games" class="col-12 p-0 mt-1 mb-1">-->
<!--                <div class="row m-0 p-0">-->
<!--                  <div class="col-8">-->
<!--                    <div class="list-relative">-->
<!--                      <span data-tooltip="tooltip">-->
<!--                        <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">-->
<!--                          <p class="options-paragraph m-0 p-0 me-2 d-inline">Digital Games</p>-->
<!--                          <font-awesome-icon icon="gamepad" class="icon-fa" />-->
<!--                        </div>-->
<!--                      </span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="col-4">-->
<!--                    <font-awesome-icon v-if="formData.property_room_detail.digital_games" icon="check" class="icon-fa green-text" />-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              &lt;!&ndash; Radio &ndash;&gt;-->
<!--              <div v-if="formData.property_room_detail && formData.property_room_detail.radio" class="col-12 p-0 mt-1 mb-1">-->
<!--                <div class="row m-0 p-0">-->
<!--                  <div class="col-8">-->
<!--                    <div class="list-relative">-->
<!--                      <span data-tooltip="tooltip">-->
<!--                        <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">-->
<!--                          <p class="options-paragraph m-0 p-0 me-2 d-inline">Radio</p>-->
<!--                          <font-awesome-icon icon="rss" class="icon-fa" />-->
<!--                        </div>-->
<!--                      </span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="col-4">-->
<!--                    <font-awesome-icon v-if="formData.property_room_detail.radio" icon="check" class="icon-fa green-text" />-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              &lt;!&ndash; Iron &ndash;&gt;-->
<!--              <div v-if="formData.property_room_detail && formData.property_room_detail.iron" class="col-12 p-0 mt-1 mb-1">-->
<!--                <div class="row m-0 p-0">-->
<!--                  <div class="col-8">-->
<!--                    <div class="list-relative">-->
<!--                      <span data-tooltip="tooltip">-->
<!--                        <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">-->
<!--                          <p class="options-paragraph m-0 p-0 me-2 d-inline">Iron</p>-->
<!--                          <font-awesome-icon icon="shield-alt" class="icon-fa" />-->
<!--                        </div>-->
<!--                      </span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="col-4">-->
<!--                    <font-awesome-icon v-if="formData.property_room_detail.iron" icon="check" class="icon-fa green-text" />-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              &lt;!&ndash; Hairdryer &ndash;&gt;-->
<!--              <div v-if="formData.property_room_detail && formData.property_room_detail.hairdryer" class="col-12 p-0 mt-1 mb-1">-->
<!--                <div class="row m-0 p-0">-->
<!--                  <div class="col-8">-->
<!--                    <div class="list-relative">-->
<!--                      <span data-tooltip="tooltip">-->
<!--                        <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">-->
<!--                          <p class="options-paragraph m-0 p-0 me-2 d-inline">Hairdryer</p>-->
<!--                          <font-awesome-icon icon="wind" class="icon-fa" />-->
<!--                        </div>-->
<!--                      </span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="col-4">-->
<!--                    <font-awesome-icon v-if="formData.property_room_detail.hairdryer" icon="check" class="icon-fa green-text" />-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              &lt;!&ndash; Slippers &ndash;&gt;-->
<!--              <div v-if="formData.property_room_detail && formData.property_room_detail.slippers" class="col-12 p-0 mt-1 mb-1">-->
<!--                <div class="row m-0 p-0">-->
<!--                  <div class="col-8">-->
<!--                    <div class="list-relative">-->
<!--                      <span data-tooltip="tooltip">-->
<!--                        <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">-->
<!--                          <p class="options-paragraph m-0 p-0 me-2 d-inline">Slippers</p>-->
<!--                          <font-awesome-icon icon="shoe-prints" class="icon-fa" />-->
<!--                        </div>-->
<!--                      </span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="col-4">-->
<!--                    <font-awesome-icon v-if="formData.property_room_detail.slippers" icon="check" class="icon-fa green-text" />-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              &lt;!&ndash; Hangers &ndash;&gt;-->
<!--              <div v-if="formData.property_room_detail && formData.property_room_detail.hangers" class="col-12 p-0 mt-1 mb-1">-->
<!--                <div class="row m-0 p-0">-->
<!--                  <div class="col-8">-->
<!--                    <div class="list-relative">-->
<!--                      <span data-tooltip="tooltip">-->
<!--                        <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">-->
<!--                          <p class="options-paragraph m-0 p-0 me-2 d-inline">Hangers</p>-->
<!--                          <font-awesome-icon icon="angle-up" class="icon-fa" />-->
<!--                        </div>-->
<!--                      </span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="col-4">-->
<!--                    <font-awesome-icon v-if="formData.property_room_detail.hangers" icon="check" class="icon-fa green-text" />-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
            </div>
          </div>
        </div>
        <!-- End Practical Switches -->

        <div v-if="formData.room_type_id && formData.room_type_id !== 8 && conditionsRoomSecurity" class="row m-0 p-0">
          <div class="form-group col-12 text-center">
            <!-- Start Horizontal Line with Icon -->
            <div class="horizontal-separator-box">
              <span class="hr-line-short"></span>
              <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
              <span class="hr-line-short"></span>
            </div>
            <!-- End Horizontal Line with Icon -->

            <!-- Information -->
            <small class="p-3 fw-bold">Security</small>

            <!-- Start Horizontal Line with Icon -->
            <div class="horizontal-separator-box">
              <span class="hr-line-short"></span>
              <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
              <span class="hr-line-short"></span>
            </div>
            <!-- End Horizontal Line with Icon -->
          </div>
        </div>

        <!-- Start Security Switches -->
        <div v-if="formData.room_type_id && formData.room_type_id !== 8 && conditionsRoomSecurity" class="row m-0 p-0">
          <div class="col-12 p-0 mt-1 mb-1">
            <div class="row m-0 p-0">

              <ul class="list-unstyled">
                <li v-if="formData.property_room_detail && formData.property_room_detail.private_locked_room" class="d-inline me-1">
                  <p class="options-paragraph m-0 p-0 me-2 d-inline-block">
                    <font-awesome-icon icon="key" class="icon-fa" /> Private Locked Room <font-awesome-icon icon="check" class="icon-fa green-text" />
                  </p>
                </li>
              </ul>

<!--              &lt;!&ndash; Private Locked Room &ndash;&gt;-->
<!--              <div v-if="formData.property_room_detail && formData.property_room_detail.private_locked_room" class="col-12 p-0 mt-1 mb-1">-->
<!--                <div class="row m-0 p-0">-->
<!--                  <div class="col-8">-->
<!--                    <div class="list-relative">-->
<!--                      <span data-tooltip="tooltip">-->
<!--                        <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">-->
<!--                          <p class="options-paragraph m-0 p-0 me-2 d-inline">Private Locked Room</p>-->
<!--                          <font-awesome-icon icon="key" class="icon-fa" />-->
<!--                        </div>-->
<!--                      </span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="col-4">-->
<!--                    <font-awesome-icon v-if="formData.property_room_detail.private_locked_room" icon="check" class="icon-fa green-text" />-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
            </div>
          </div>
        </div>
        <!-- End Security Switches -->

        <div v-if="formData.room_type_id && formData.room_type_id !== 8 && conditionsRoomAccessibility" class="row m-0 p-0">
          <div class="form-group col-12 text-center">
            <!-- Start Horizontal Line with Icon -->
            <div class="horizontal-separator-box">
              <span class="hr-line-short"></span>
              <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
              <span class="hr-line-short"></span>
            </div>
            <!-- End Horizontal Line with Icon -->

            <!-- Information -->
            <small class="p-3 fw-bold">Accessibility</small>

            <!-- Start Horizontal Line with Icon -->
            <div class="horizontal-separator-box">
              <span class="hr-line-short"></span>
              <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
              <span class="hr-line-short"></span>
            </div>
            <!-- End Horizontal Line with Icon -->
          </div>
        </div>

        <!-- Start Accessibility Switches -->
        <div v-if="formData.room_type_id && formData.room_type_id !== 8 && conditionsRoomAccessibility" class="row m-0 p-0">
          <div class="col-12 p-0 mt-1 mb-1">
            <div class="row m-0 p-0">

              <ul class="list-unstyled">
                <li v-if="formData.property_room_detail && formData.property_room_detail.wheelchair_accessible" class="d-inline me-1">
                  <p class="options-paragraph m-0 p-0 me-2 d-inline-block">
                    <font-awesome-icon icon="wheelchair" class="icon-fa" /> Wheelchair Accessible <font-awesome-icon icon="check" class="icon-fa green-text" />
                  </p>
                </li>
                <li v-if="formData.property_room_detail && formData.property_room_detail.ground_floor" class="d-inline me-1">
                  <p class="options-paragraph m-0 p-0 me-2 d-inline-block">
                    <font-awesome-icon icon="window-minimize" class="icon-fa" /> Ground Floor <font-awesome-icon icon="check" class="icon-fa green-text" />
                  </p>
                </li>
                <li v-if="formData.property_room_detail && formData.property_room_detail.elevator" class="d-inline me-1">
                  <p class="options-paragraph m-0 p-0 me-2 d-inline-block">
                    <font-awesome-icon icon="arrows-alt-v" class="icon-fa" /> Elevator Accessible <font-awesome-icon icon="check" class="icon-fa green-text" />
                  </p>
                </li>
              </ul>

<!--              &lt;!&ndash; Wheelchair Accessible &ndash;&gt;-->
<!--              <div v-if="formData.property_room_detail && formData.property_room_detail.wheelchair_accessible" class="col-12 p-0 mt-1 mb-1">-->
<!--                <div class="row m-0 p-0">-->
<!--                  <div class="col-8">-->
<!--                    <div class="list-relative">-->
<!--                      <span data-tooltip="tooltip">-->
<!--                        <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">-->
<!--                          <p class="options-paragraph m-0 p-0 me-2 d-inline">Wheelchair Accessible</p>-->
<!--                          <font-awesome-icon icon="wheelchair" class="icon-fa" />-->
<!--                        </div>-->
<!--                      </span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="col-4">-->
<!--                    <font-awesome-icon v-if="formData.property_room_detail.wheelchair_accessible" icon="check" class="icon-fa green-text" />-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              &lt;!&ndash; Ground Floor &ndash;&gt;-->
<!--              <div v-if="formData.property_room_detail && formData.property_room_detail.ground_floor" class="col-12 p-0 mt-1 mb-1">-->
<!--                <div class="row m-0 p-0">-->
<!--                  <div class="col-8">-->
<!--                    <div class="list-relative">-->
<!--                      <span data-tooltip="tooltip">-->
<!--                        <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">-->
<!--                          <p class="options-paragraph m-0 p-0 me-2 d-inline">Ground Floor</p>-->
<!--                          <font-awesome-icon icon="window-minimize" class="icon-fa" />-->
<!--                        </div>-->
<!--                      </span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="col-4">-->
<!--                    <font-awesome-icon v-if="formData.property_room_detail.ground_floor" icon="check" class="icon-fa green-text" />-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              &lt;!&ndash; Elevator &ndash;&gt;-->
<!--              <div v-if="formData.property_room_detail && formData.property_room_detail.elevator" class="col-12 p-0 mt-1 mb-1">-->
<!--                <div class="row m-0 p-0">-->
<!--                  <div class="col-8">-->
<!--                    <div class="list-relative">-->
<!--                      <span data-tooltip="tooltip">-->
<!--                        <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">-->
<!--                          <p class="options-paragraph m-0 p-0 me-2 d-inline">Elevator Accessible</p>-->
<!--                          <font-awesome-icon icon="arrows-alt-v" class="icon-fa" />-->
<!--                        </div>-->
<!--                      </span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="col-4">-->
<!--                    <font-awesome-icon v-if="formData.property_room_detail.elevator" icon="check" class="icon-fa green-text" />-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
            </div>
          </div>
        </div>
        <!-- End Accessibility Switches -->

        <div v-if="formData.room_type_id && formData.room_type_id !== 8 && conditionsRoomView" class="row m-0 p-0">
          <div class="form-group col-12 text-center">
            <!-- Start Horizontal Line with Icon -->
            <div class="horizontal-separator-box">
              <span class="hr-line-short"></span>
              <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
              <span class="hr-line-short"></span>
            </div>
            <!-- End Horizontal Line with Icon -->

            <!-- Information -->
            <small class="p-3 fw-bold">View</small>

            <!-- Start Horizontal Line with Icon -->
            <div class="horizontal-separator-box">
              <span class="hr-line-short"></span>
              <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
              <span class="hr-line-short"></span>
            </div>
            <!-- End Horizontal Line with Icon -->
          </div>
        </div>

        <!-- Start View Switches -->
        <div v-if="formData.room_type_id && formData.room_type_id !== 8 && conditionsRoomView" class="row m-0 p-0">
          <div class="col-12 p-0 mt-1 mb-1">
            <div class="row m-0 p-0">

              <ul class="list-unstyled">
                <li v-if="formData.property_room_detail && formData.property_room_detail.balcony" class="d-inline me-1">
                  <p class="options-paragraph m-0 p-0 me-2 d-inline-block">
                    <font-awesome-icon icon="chess-rook" class="icon-fa" /> Balcony <font-awesome-icon icon="check" class="icon-fa green-text" />
                  </p>
                </li>
                <li v-if="formData.property_room_detail && formData.property_room_detail.bay_view" class="d-inline me-1">
                  <p class="options-paragraph m-0 p-0 me-2 d-inline-block">
                    <font-awesome-icon icon="eye" class="icon-fa" /> Bay View <font-awesome-icon icon="check" class="icon-fa green-text" />
                  </p>
                </li>
                <li v-if="formData.property_room_detail && formData.property_room_detail.beach_view" class="d-inline me-1">
                  <p class="options-paragraph m-0 p-0 me-2 d-inline-block">
                    <font-awesome-icon icon="eye" class="icon-fa" /> Beach View <font-awesome-icon icon="check" class="icon-fa green-text" />
                  </p>
                </li>
                <li v-if="formData.property_room_detail && formData.property_room_detail.canal_view" class="d-inline me-1">
                  <p class="options-paragraph m-0 p-0 me-2 d-inline-block">
                    <font-awesome-icon icon="eye" class="icon-fa" /> Canal View <font-awesome-icon icon="check" class="icon-fa green-text" />
                  </p>
                </li>
                <li v-if="formData.property_room_detail && formData.property_room_detail.city_view" class="d-inline me-1">
                  <p class="options-paragraph m-0 p-0 me-2 d-inline-block">
                    <font-awesome-icon icon="eye" class="icon-fa" /> City View <font-awesome-icon icon="check" class="icon-fa green-text" />
                  </p>
                </li>
                <li v-if="formData.property_room_detail && formData.property_room_detail.courtyard_view" class="d-inline me-1">
                  <p class="options-paragraph m-0 p-0 me-2 d-inline-block">
                    <font-awesome-icon icon="eye" class="icon-fa" /> Courtyard View <font-awesome-icon icon="check" class="icon-fa green-text" />
                  </p>
                </li>
                <li v-if="formData.property_room_detail && formData.property_room_detail.desert_view" class="d-inline me-1">
                  <p class="options-paragraph m-0 p-0 me-2 d-inline-block">
                    <font-awesome-icon icon="eye" class="icon-fa" /> Desert View <font-awesome-icon icon="check" class="icon-fa green-text" />
                  </p>
                </li>
                <li v-if="formData.property_room_detail && formData.property_room_detail.garden_view" class="d-inline me-1">
                  <p class="options-paragraph m-0 p-0 me-2 d-inline-block">
                    <font-awesome-icon icon="eye" class="icon-fa" /> Garden View <font-awesome-icon icon="check" class="icon-fa green-text" />
                  </p>
                </li>
                <li v-if="formData.property_room_detail && formData.property_room_detail.sports_view" class="d-inline me-1">
                  <p class="options-paragraph m-0 p-0 me-2 d-inline-block">
                    <font-awesome-icon icon="eye" class="icon-fa" /> Sports View <font-awesome-icon icon="check" class="icon-fa green-text" />
                  </p>
                </li>
                <li v-if="formData.property_room_detail && formData.property_room_detail.harbour_view" class="d-inline me-1">
                  <p class="options-paragraph m-0 p-0 me-2 d-inline-block">
                    <font-awesome-icon icon="eye" class="icon-fa" /> Harbour View <font-awesome-icon icon="check" class="icon-fa green-text" />
                  </p>
                </li>
                <li v-if="formData.property_room_detail && formData.property_room_detail.marina_view" class="d-inline me-1">
                  <p class="options-paragraph m-0 p-0 me-2 d-inline-block">
                    <font-awesome-icon icon="eye" class="icon-fa" /> Marina View <font-awesome-icon icon="check" class="icon-fa green-text" />
                  </p>
                </li>
                <li v-if="formData.property_room_detail && formData.property_room_detail.landmark_view" class="d-inline me-1">
                  <p class="options-paragraph m-0 p-0 me-2 d-inline-block">
                    <font-awesome-icon icon="eye" class="icon-fa" /> Landmark View <font-awesome-icon icon="check" class="icon-fa green-text" />
                  </p>
                </li>
                <li v-if="formData.property_room_detail && formData.property_room_detail.mountain_view" class="d-inline me-1">
                  <p class="options-paragraph m-0 p-0 me-2 d-inline-block">
                    <font-awesome-icon icon="eye" class="icon-fa" /> Mountain View <font-awesome-icon icon="check" class="icon-fa green-text" />
                  </p>
                </li>
                <li v-if="formData.property_room_detail && formData.property_room_detail.sea_view" class="d-inline me-1">
                  <p class="options-paragraph m-0 p-0 me-2 d-inline-block">
                    <font-awesome-icon icon="eye" class="icon-fa" /> Sea View <font-awesome-icon icon="check" class="icon-fa green-text" />
                  </p>
                </li>
                <li v-if="formData.property_room_detail && formData.property_room_detail.ocean_view" class="d-inline me-1">
                  <p class="options-paragraph m-0 p-0 me-2 d-inline-block">
                    <font-awesome-icon icon="eye" class="icon-fa" /> Ocean View <font-awesome-icon icon="check" class="icon-fa green-text" />
                  </p>
                </li>
                <li v-if="formData.property_room_detail && formData.property_room_detail.pool_view" class="d-inline me-1">
                  <p class="options-paragraph m-0 p-0 me-2 d-inline-block">
                    <font-awesome-icon icon="eye" class="icon-fa" /> Pool View <font-awesome-icon icon="check" class="icon-fa green-text" />
                  </p>
                </li>
                <li v-if="formData.property_room_detail && formData.property_room_detail.resort_view" class="d-inline me-1">
                  <p class="options-paragraph m-0 p-0 me-2 d-inline-block">
                    <font-awesome-icon icon="eye" class="icon-fa" /> Resort View <font-awesome-icon icon="check" class="icon-fa green-text" />
                  </p>
                </li>
                <li v-if="formData.property_room_detail && formData.property_room_detail.park_view" class="d-inline me-1">
                  <p class="options-paragraph m-0 p-0 me-2 d-inline-block">
                    <font-awesome-icon icon="eye" class="icon-fa" /> Park View <font-awesome-icon icon="check" class="icon-fa green-text" />
                  </p>
                </li>
                <li v-if="formData.property_room_detail && formData.property_room_detail.lake_view" class="d-inline me-1">
                  <p class="options-paragraph m-0 p-0 me-2 d-inline-block">
                    <font-awesome-icon icon="eye" class="icon-fa" /> Lake View <font-awesome-icon icon="check" class="icon-fa green-text" />
                  </p>
                </li>
                <li v-if="formData.property_room_detail && formData.property_room_detail.river_view" class="d-inline me-1">
                  <p class="options-paragraph m-0 p-0 me-2 d-inline-block">
                    <font-awesome-icon icon="eye" class="icon-fa" /> River View <font-awesome-icon icon="check" class="icon-fa green-text" />
                  </p>
                </li>
                <li v-if="formData.property_room_detail && formData.property_room_detail.valley_view" class="d-inline me-1">
                  <p class="options-paragraph m-0 p-0 me-2 d-inline-block">
                    <font-awesome-icon icon="eye" class="icon-fa" /> Valley View <font-awesome-icon icon="check" class="icon-fa green-text" />
                  </p>
                </li>
              </ul>

<!--              &lt;!&ndash; Balcony &ndash;&gt;-->
<!--              <div v-if="formData.property_room_detail && formData.property_room_detail.balcony" class="col-12 p-0 mt-1 mb-1">-->
<!--                <div class="row m-0 p-0">-->
<!--                  <div class="col-8">-->
<!--                    <div class="list-relative">-->
<!--                      <span data-tooltip="tooltip">-->
<!--                        <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">-->
<!--                          <p class="options-paragraph m-0 p-0 me-2 d-inline">Balcony</p>-->
<!--                          <font-awesome-icon icon="chess-rook" class="icon-fa" />-->
<!--                        </div>-->
<!--                      </span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="col-4">-->
<!--                    <font-awesome-icon v-if="formData.property_room_detail.balcony" icon="check" class="icon-fa green-text" />-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              &lt;!&ndash; Bay View &ndash;&gt;-->
<!--              <div v-if="formData.property_room_detail && formData.property_room_detail.bay_view" class="col-12 p-0 mt-1 mb-1">-->
<!--                <div class="row m-0 p-0">-->
<!--                  <div class="col-8">-->
<!--                    <div class="list-relative">-->
<!--                      <span data-tooltip="tooltip">-->
<!--                        <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">-->
<!--                          <p class="options-paragraph m-0 p-0 me-2 d-inline">Bay View</p>-->
<!--                          <font-awesome-icon icon="eye" class="icon-fa" />-->
<!--                        </div>-->
<!--                      </span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="col-4">-->
<!--                    <font-awesome-icon v-if="formData.property_room_detail.bay_view" icon="check" class="icon-fa green-text" />-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              &lt;!&ndash; Beach View &ndash;&gt;-->
<!--              <div v-if="formData.property_room_detail && formData.property_room_detail.beach_view" class="col-12 p-0 mt-1 mb-1">-->
<!--                <div class="row m-0 p-0">-->
<!--                  <div class="col-8">-->
<!--                    <div class="list-relative">-->
<!--                      <span data-tooltip="tooltip">-->
<!--                        <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">-->
<!--                          <p class="options-paragraph m-0 p-0 me-2 d-inline">Beach View</p>-->
<!--                          <font-awesome-icon icon="eye" class="icon-fa" />-->
<!--                        </div>-->
<!--                      </span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="col-4">-->
<!--                    <font-awesome-icon v-if="formData.property_room_detail.beach_view" icon="check" class="icon-fa green-text" />-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              &lt;!&ndash; Canal View &ndash;&gt;-->
<!--              <div v-if="formData.property_room_detail && formData.property_room_detail.canal_view" class="col-12 p-0 mt-1 mb-1">-->
<!--                <div class="row m-0 p-0">-->
<!--                  <div class="col-8">-->
<!--                    <div class="list-relative">-->
<!--                      <span data-tooltip="tooltip">-->
<!--                        <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">-->
<!--                          <p class="options-paragraph m-0 p-0 me-2 d-inline">Canal View</p>-->
<!--                          <font-awesome-icon icon="eye" class="icon-fa" />-->
<!--                        </div>-->
<!--                      </span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="col-4">-->
<!--                    <font-awesome-icon v-if="formData.property_room_detail.canal_view" icon="check" class="icon-fa green-text" />-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              &lt;!&ndash; City View &ndash;&gt;-->
<!--              <div v-if="formData.property_room_detail && formData.property_room_detail.city_view" class="col-12 p-0 mt-1 mb-1">-->
<!--                <div class="row m-0 p-0">-->
<!--                  <div class="col-8">-->
<!--                    <div class="list-relative">-->
<!--                      <span data-tooltip="tooltip">-->
<!--                        <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">-->
<!--                          <p class="options-paragraph m-0 p-0 me-2 d-inline">City View</p>-->
<!--                          <font-awesome-icon icon="eye" class="icon-fa" />-->
<!--                        </div>-->
<!--                      </span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="col-4">-->
<!--                    <font-awesome-icon v-if="formData.property_room_detail.city_view" icon="check" class="icon-fa green-text" />-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              &lt;!&ndash; Courtyard View &ndash;&gt;-->
<!--              <div v-if="formData.property_room_detail && formData.property_room_detail.courtyard_view" class="col-12 p-0 mt-1 mb-1">-->
<!--                <div class="row m-0 p-0">-->
<!--                  <div class="col-8">-->
<!--                    <div class="list-relative">-->
<!--                      <span data-tooltip="tooltip">-->
<!--                        <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">-->
<!--                          <p class="options-paragraph m-0 p-0 me-2 d-inline">Courtyard View</p>-->
<!--                          <font-awesome-icon icon="eye" class="icon-fa" />-->
<!--                        </div>-->
<!--                      </span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="col-4">-->
<!--                    <font-awesome-icon v-if="formData.property_room_detail.courtyard_view" icon="check" class="icon-fa green-text" />-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              &lt;!&ndash; Desert View &ndash;&gt;-->
<!--              <div v-if="formData.property_room_detail && formData.property_room_detail.desert_view" class="col-12 p-0 mt-1 mb-1">-->
<!--                <div class="row m-0 p-0">-->
<!--                  <div class="col-8">-->
<!--                    <div class="list-relative">-->
<!--                      <span data-tooltip="tooltip">-->
<!--                        <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">-->
<!--                          <p class="options-paragraph m-0 p-0 me-2 d-inline">Desert View</p>-->
<!--                          <font-awesome-icon icon="eye" class="icon-fa" />-->
<!--                        </div>-->
<!--                      </span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="col-4">-->
<!--                    <font-awesome-icon v-if="formData.property_room_detail.desert_view" icon="check" class="icon-fa green-text" />-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              &lt;!&ndash; Garden View &ndash;&gt;-->
<!--              <div v-if="formData.property_room_detail && formData.property_room_detail.garden_view" class="col-12 p-0 mt-1 mb-1">-->
<!--                <div class="row m-0 p-0">-->
<!--                  <div class="col-8">-->
<!--                    <div class="list-relative">-->
<!--                      <span data-tooltip="tooltip">-->
<!--                        <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">-->
<!--                          <p class="options-paragraph m-0 p-0 me-2 d-inline">Garden View</p>-->
<!--                          <font-awesome-icon icon="eye" class="icon-fa" />-->
<!--                        </div>-->
<!--                      </span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="col-4">-->
<!--                    <font-awesome-icon v-if="formData.property_room_detail.garden_view" icon="check" class="icon-fa green-text" />-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              &lt;!&ndash; Sports View &ndash;&gt;-->
<!--              <div v-if="formData.property_room_detail && formData.property_room_detail.sports_view" class="col-12 p-0 mt-1 mb-1">-->
<!--                <div class="row m-0 p-0">-->
<!--                  <div class="col-8">-->
<!--                    <div class="list-relative">-->
<!--                      <span data-tooltip="tooltip">-->
<!--                        <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">-->
<!--                          <p class="options-paragraph m-0 p-0 me-2 d-inline">Sports View</p>-->
<!--                          <font-awesome-icon icon="eye" class="icon-fa" />-->
<!--                        </div>-->
<!--                      </span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="col-4">-->
<!--                    <font-awesome-icon v-if="formData.property_room_detail.sports_view" icon="check" class="icon-fa green-text" />-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              &lt;!&ndash; Harbour View &ndash;&gt;-->
<!--              <div v-if="formData.property_room_detail && formData.property_room_detail.harbour_view" class="col-12 p-0 mt-1 mb-1">-->
<!--                <div class="row m-0 p-0">-->
<!--                  <div class="col-8">-->
<!--                    <div class="list-relative">-->
<!--                      <span data-tooltip="tooltip">-->
<!--                        <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">-->
<!--                          <p class="options-paragraph m-0 p-0 me-2 d-inline">Harbour View</p>-->
<!--                          <font-awesome-icon icon="eye" class="icon-fa" />-->
<!--                        </div>-->
<!--                      </span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="col-4">-->
<!--                    <font-awesome-icon v-if="formData.property_room_detail.harbour_view" icon="check" class="icon-fa green-text" />-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              &lt;!&ndash; Marina View &ndash;&gt;-->
<!--              <div v-if="formData.property_room_detail && formData.property_room_detail.marina_view" class="col-12 p-0 mt-1 mb-1">-->
<!--                <div class="row m-0 p-0">-->
<!--                  <div class="col-8">-->
<!--                    <div class="list-relative">-->
<!--                      <span data-tooltip="tooltip">-->
<!--                        <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">-->
<!--                          <p class="options-paragraph m-0 p-0 me-2 d-inline">Marina View</p>-->
<!--                          <font-awesome-icon icon="eye" class="icon-fa" />-->
<!--                        </div>-->
<!--                      </span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="col-4">-->
<!--                    <font-awesome-icon v-if="formData.property_room_detail.marina_view" icon="check" class="icon-fa green-text" />-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              &lt;!&ndash; Landmark View &ndash;&gt;-->
<!--              <div v-if="formData.property_room_detail && formData.property_room_detail.landmark_view" class="col-12 p-0 mt-1 mb-1">-->
<!--                <div class="row m-0 p-0">-->
<!--                  <div class="col-8">-->
<!--                    <div class="list-relative">-->
<!--                      <span data-tooltip="tooltip">-->
<!--                        <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">-->
<!--                          <p class="options-paragraph m-0 p-0 me-2 d-inline">Landmark View</p>-->
<!--                          <font-awesome-icon icon="eye" class="icon-fa" />-->
<!--                        </div>-->
<!--                      </span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="col-4">-->
<!--                    <font-awesome-icon v-if="formData.property_room_detail.landmark_view" icon="check" class="icon-fa green-text" />-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              &lt;!&ndash; Mountain View &ndash;&gt;-->
<!--              <div v-if="formData.property_room_detail && formData.property_room_detail.mountain_view" class="col-12 p-0 mt-1 mb-1">-->
<!--                <div class="row m-0 p-0">-->
<!--                  <div class="col-8">-->
<!--                    <div class="list-relative">-->
<!--                      <span data-tooltip="tooltip">-->
<!--                        <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">-->
<!--                          <p class="options-paragraph m-0 p-0 me-2 d-inline">Mountain View</p>-->
<!--                          <font-awesome-icon icon="eye" class="icon-fa" />-->
<!--                        </div>-->
<!--                      </span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="col-4">-->
<!--                    <font-awesome-icon v-if="formData.property_room_detail.mountain_view" icon="check" class="icon-fa green-text" />-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              &lt;!&ndash; Sea View &ndash;&gt;-->
<!--              <div v-if="formData.property_room_detail && formData.property_room_detail.sea_view" class="col-12 p-0 mt-1 mb-1">-->
<!--                <div class="row m-0 p-0">-->
<!--                  <div class="col-8">-->
<!--                    <div class="list-relative">-->
<!--                      <span data-tooltip="tooltip">-->
<!--                        <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">-->
<!--                          <p class="options-paragraph m-0 p-0 me-2 d-inline">Sea View</p>-->
<!--                          <font-awesome-icon icon="eye" class="icon-fa" />-->
<!--                        </div>-->
<!--                      </span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="col-4">-->
<!--                    <font-awesome-icon v-if="formData.property_room_detail.sea_view" icon="check" class="icon-fa green-text" />-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              &lt;!&ndash; Ocean View &ndash;&gt;-->
<!--              <div v-if="formData.property_room_detail && formData.property_room_detail.ocean_view" class="col-12 p-0 mt-1 mb-1">-->
<!--                <div class="row m-0 p-0">-->
<!--                  <div class="col-8">-->
<!--                    <div class="list-relative">-->
<!--                      <span data-tooltip="tooltip">-->
<!--                        <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">-->
<!--                          <p class="options-paragraph m-0 p-0 me-2 d-inline">Ocean View</p>-->
<!--                          <font-awesome-icon icon="eye" class="icon-fa" />-->
<!--                        </div>-->
<!--                      </span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="col-4">-->
<!--                    <font-awesome-icon v-if="formData.property_room_detail.ocean_view" icon="check" class="icon-fa green-text" />-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              &lt;!&ndash; Pool View &ndash;&gt;-->
<!--              <div v-if="formData.property_room_detail && formData.property_room_detail.pool_view" class="col-12 p-0 mt-1 mb-1">-->
<!--                <div class="row m-0 p-0">-->
<!--                  <div class="col-8">-->
<!--                    <div class="list-relative">-->
<!--                      <span data-tooltip="tooltip">-->
<!--                        <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">-->
<!--                          <p class="options-paragraph m-0 p-0 me-2 d-inline">Pool View</p>-->
<!--                          <font-awesome-icon icon="eye" class="icon-fa" />-->
<!--                        </div>-->
<!--                      </span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="col-4">-->
<!--                    <font-awesome-icon v-if="formData.property_room_detail.pool_view" icon="check" class="icon-fa green-text" />-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              &lt;!&ndash; Resort View &ndash;&gt;-->
<!--              <div v-if="formData.property_room_detail && formData.property_room_detail.resort_view" class="col-12 p-0 mt-1 mb-1">-->
<!--                <div class="row m-0 p-0">-->
<!--                  <div class="col-8">-->
<!--                    <div class="list-relative">-->
<!--                      <span data-tooltip="tooltip">-->
<!--                        <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">-->
<!--                          <p class="options-paragraph m-0 p-0 me-2 d-inline">Resort View</p>-->
<!--                          <font-awesome-icon icon="eye" class="icon-fa" />-->
<!--                        </div>-->
<!--                      </span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="col-4">-->
<!--                    <font-awesome-icon v-if="formData.property_room_detail.resort_view" icon="check" class="icon-fa green-text" />-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              &lt;!&ndash; Park View &ndash;&gt;-->
<!--              <div v-if="formData.property_room_detail && formData.property_room_detail.park_view" class="col-12 p-0 mt-1 mb-1">-->
<!--                <div class="row m-0 p-0">-->
<!--                  <div class="col-8">-->
<!--                    <div class="list-relative">-->
<!--                      <span data-tooltip="tooltip">-->
<!--                        <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">-->
<!--                          <p class="options-paragraph m-0 p-0 me-2 d-inline">Park View</p>-->
<!--                          <font-awesome-icon icon="eye" class="icon-fa" />-->
<!--                        </div>-->
<!--                      </span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="col-4">-->
<!--                    <font-awesome-icon v-if="formData.property_room_detail.park_view" icon="check" class="icon-fa green-text" />-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              &lt;!&ndash; Lake View &ndash;&gt;-->
<!--              <div v-if="formData.property_room_detail && formData.property_room_detail.lake_view" class="col-12 p-0 mt-1 mb-1">-->
<!--                <div class="row m-0 p-0">-->
<!--                  <div class="col-8">-->
<!--                    <div class="list-relative">-->
<!--                      <span data-tooltip="tooltip">-->
<!--                        <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">-->
<!--                          <p class="options-paragraph m-0 p-0 me-2 d-inline">Lake View</p>-->
<!--                          <font-awesome-icon icon="eye" class="icon-fa" />-->
<!--                        </div>-->
<!--                      </span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="col-4">-->
<!--                    <font-awesome-icon v-if="formData.property_room_detail.lake_view" icon="check" class="icon-fa green-text" />-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              &lt;!&ndash; River View &ndash;&gt;-->
<!--              <div v-if="formData.property_room_detail && formData.property_room_detail.river_view" class="col-12 p-0 mt-1 mb-1">-->
<!--                <div class="row m-0 p-0">-->
<!--                  <div class="col-8">-->
<!--                    <div class="list-relative">-->
<!--                      <span data-tooltip="tooltip">-->
<!--                        <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">-->
<!--                          <p class="options-paragraph m-0 p-0 me-2 d-inline">River View</p>-->
<!--                          <font-awesome-icon icon="eye" class="icon-fa" />-->
<!--                        </div>-->
<!--                      </span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="col-4">-->
<!--                    <font-awesome-icon v-if="formData.property_room_detail.river_view" icon="check" class="icon-fa green-text" />-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              &lt;!&ndash; Valley View &ndash;&gt;-->
<!--              <div v-if="formData.property_room_detail && formData.property_room_detail.valley_view" class="col-12 p-0 mt-1 mb-1">-->
<!--                <div class="row m-0 p-0">-->
<!--                  <div class="col-8">-->
<!--                    <div class="list-relative">-->
<!--                      <span data-tooltip="tooltip">-->
<!--                        <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">-->
<!--                          <p class="options-paragraph m-0 p-0 me-2 d-inline">Valley View</p>-->
<!--                          <font-awesome-icon icon="eye" class="icon-fa" />-->
<!--                        </div>-->
<!--                      </span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="col-4">-->
<!--                    <font-awesome-icon v-if="formData.property_room_detail.valley_view" icon="check" class="icon-fa green-text" />-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
            </div>
          </div>
        </div>
        <!-- End View Switches -->

      </div>
      <div v-else class="p-3">
        Please click the room to see its details
      </div>

    </div>
</template>

<script>
import axios from "axios";
import ValidationErrors from "./ValidationErrors"
import Loader from "./Loader.vue"

export default {
  name: 'RoomDetails',
  props: ['formData'],
  components: {
    'ValidationErrors': ValidationErrors,
    'Loader': Loader
  },
  data() {
    return {
      user: (this.$store.state.auth.authenticated === true && this.$store.state.auth.user) ? this.$store.state.auth.user : null,
      modalData: this.formData,
      // roomId: this.formData.room ? this.formData.room.id : '',
      // form: {
      //   id: '', // fake room id for purpose of listing and editing properly, this id can be also valid taken from db if user edits existing rooms
      //   is_existing_room: false,
      //   guests: '',
      //   price: '',
      //   property_rent_type_id: '',
      //   discount: '',
      //   discount_rate: '',
      //   smoking_room: false,
      //   pet_friendly_room: false,
      //   // Room type
      //   room_type_id: '',
      //   property_room_type: {
      //     type: ''
      //   },
      //   property_rent_type: {
      //     type: ''
      //   },
      //   // Beds
      //   single_beds: '',
      //   double_beds: '',
      //   queen_beds: '',
      //   king_beds: '',
      //   zip_and_link_beds: '',
      //   water_beds: '',
      //   sofa_beds: '',
      //   bunk_beds: '',
      //   toddler_beds: '',
      //   cribs: '',
      //   hammocks: '',
      //   // Security
      //   private_locked_room: false,
      //   // Kitchen Like && Extra items
      //   fridge: false,
      //   freezer: false,
      //   microwave: false,
      //   kettle: false,
      //   coffee: false,
      //   toaster: false,
      //   iron: false,
      //   hairdryer: false,
      //   slippers: false,
      //   hangers: false,
      //   // Toilet and Extras
      //   sink: false,
      //   shower: false,
      //   bath: false,
      //   wc: false,
      //   free_toiletries: false,
      //   towels: false,
      //   // Electronic and useful
      //   wifi: false,
      //   tv: false,
      //   work_desk: false,
      //   air_conditioning: false,
      //   fan: false,
      //   heating: false,
      //   mosquito_net: false,
      //   board_games: false,
      //   digital_games: false,
      //   radio: false,
      //   // View
      //   balcony: false,
      //   bay_view: false,
      //   beach_view: false,
      //   canal_view: false,
      //   city_view: false,
      //   courtyard_view: false,
      //   desert_view: false,
      //   garden_view: false,
      //   sports_view: false,
      //   harbour_view: false,
      //   marina_view: false,
      //   landmark_view: false,
      //   mountain_view: false,
      //   sea_view: false,
      //   ocean_view: false,
      //   pool_view: false,
      //   resort_view: false,
      //   park_view: false,
      //   lake_view: false,
      //   river_view: false,
      //   valley_view: false,
      //   // Accessibility
      //   wheelchair_accessible: false,
      //   ground_floor: false,
      //   elevator: false
      // },
      processing: false,
      loading: false,
      editing: false,
      validationMessage: '',
      validationErrors: '',
      validationSuccess: null
    }
  },
  computed: {
    atLeastOneRequired() {
      if (
          (!this.formData.single_beds || this.formData.single_beds.length === 0 || this.formData.single_beds == 0) &&
          (!this.formData.double_beds || this.formData.double_beds.length === 0 || this.formData.double_beds == 0) &&
          (!this.formData.queen_beds || this.formData.queen_beds.length === 0 || this.formData.queen_beds == 0) &&
          (!this.formData.king_beds || this.formData.king_beds.length === 0 || this.formData.king_beds == 0) &&
          (!this.formData.zip_and_link_beds || this.formData.zip_and_link_beds.length === 0 || this.formData.zip_and_link_beds == 0) &&
          (!this.formData.water_beds || this.formData.water_beds.length === 0 || this.formData.water_beds == 0) &&
          (!this.formData.sofa_beds || this.formData.sofa_beds.length === 0 || this.formData.sofa_beds == 0) &&
          (!this.formData.bunk_beds || this.formData.bunk_beds.length === 0 || this.formData.bunk_beds == 0) &&
          (!this.formData.toddler_beds || this.formData.toddler_beds.length === 0 || this.formData.toddler_beds == 0) &&
          (!this.formData.cribs || this.formData.cribs.length === 0 || this.formData.cribs == 0) &&
          (!this.formData.hammocks || this.formData.hammocks.length === 0 || this.formData.hammocks == 0)
      ) {
        return true;
      } else {
        return false;
      }
    },
    conditionsRoomSuite() {
      return this.formData.property_room_detail.single_beds || this.formData.property_room_detail.double_beds;
    },
    conditionsRoomBeds() {
      return this.formData.property_room_detail.single_beds ||
          this.formData.property_room_detail.double_beds ||
          this.formData.property_room_detail.queen_beds ||
          this.formData.property_room_detail.king_beds ||
          this.formData.property_room_detail.zip_and_link_beds ||
          this.formData.property_room_detail.water_beds ||
          this.formData.property_room_detail.sofa_beds ||
          this.formData.property_room_detail.bunk_beds ||
          this.formData.property_room_detail.toddler_beds ||
          this.formData.property_room_detail.cribs ||
          this.formData.property_room_detail.hammocks;
    },
    conditionsRoomBathroom() {
      return this.formData.property_room_detail.sink ||
          this.formData.property_room_detail.shower ||
          this.formData.property_room_detail.bath ||
          this.formData.property_room_detail.wc ||
          this.formData.property_room_detail.free_toiletries ||
          this.formData.property_room_detail.towels;
    },
    conditionsRoomKitchen() {
      return this.formData.property_room_detail.fridge ||
          this.formData.property_room_detail.freezer ||
          this.formData.property_room_detail.kettle ||
          this.formData.property_room_detail.coffee ||
          this.formData.property_room_detail.toaster ||
          this.formData.property_room_detail.microwave;
    },
    conditionsRoomPractical() {
      return this.formData.property_room_detail.wifi ||
          this.formData.property_room_detail.tv ||
          this.formData.property_room_detail.work_desk ||
          this.formData.property_room_detail.air_conditioning ||
          this.formData.property_room_detail.fan ||
          this.formData.property_room_detail.heating ||
          this.formData.property_room_detail.mosquito_net ||
          this.formData.property_room_detail.board_games ||
          this.formData.property_room_detail.digital_games ||
          this.formData.property_room_detail.radio ||
          this.formData.property_room_detail.iron ||
          this.formData.property_room_detail.hairdryer ||
          this.formData.property_room_detail.slippers ||
          this.formData.property_room_detail.hangers ||
          this.formData.property_room_detail.iron ||
          this.formData.property_room_detail.hairdryer ||
          this.formData.property_room_detail.slippers ||
          this.formData.property_room_detail.hangers;
    },
    conditionsRoomSecurity() {
      return this.formData.property_room_detail.private_locked_room;
    },
    conditionsRoomAccessibility() {
      return this.formData.property_room_detail.wheelchair_accessible ||
          this.formData.property_room_detail.ground_floor ||
          this.formData.property_room_detail.elevator;
    },
    conditionsRoomView() {
      return this.formData.property_room_detail.balcony ||
          this.formData.property_room_detail.bay_view ||
          this.formData.property_room_detail.beach_view ||
          this.formData.property_room_detail.canal_view ||
          this.formData.property_room_detail.city_view ||
          this.formData.property_room_detail.courtyard_view ||
          this.formData.property_room_detail.desert_view ||
          this.formData.property_room_detail.garden_view ||
          this.formData.property_room_detail.sports_view ||
          this.formData.property_room_detail.harbour_view ||
          this.formData.property_room_detail.marina_view ||
          this.formData.property_room_detail.landmark_view ||
          this.formData.property_room_detail.mountain_view ||
          this.formData.property_room_detail.sea_view ||
          this.formData.property_room_detail.ocean_view ||
          this.formData.property_room_detail.pool_view ||
          this.formData.property_room_detail.resort_view ||
          this.formData.property_room_detail.park_view ||
          this.formData.property_room_detail.lake_view ||
          this.formData.property_room_detail.river_view ||
          this.formData.property_room_detail.valley_view;
    }
  },
  mounted() {
    // if (this.formData.room && this.formData.room.id !== '' && this.formData.room.id !== null && this.formData.room.id !== undefined) {
    //   this.loading = true;
    //   this.form = '';
    //   this.form = {...this.formData.room};
    //   this.loading = false;
    // } else {
    //   this.form = '';
    //   this.form = {...this.defaultForm};
    // }
  },
  methods:{
    // async addRoom() {
    //   // Validate that data is correct and then
    //   const isValid = await this.$refs.addRoomFormObserver.validate();
    //   if (isValid) {
    //     // Pass room data back to component using global addRoom
    //     this.$root.$emit('addRoomGlobal', this.form);
    //
    //     // Reset form values to default
    //     this.form = this.defaultForm;
    //
    //     // Close AddRoomModal
    //     let closeAddRoomBtn = document.getElementById('closeAddRoomModal');
    //     if (closeAddRoomBtn) {
    //       closeAddRoomBtn.click();
    //     }
    //   }
    // },
    checkSuiteExists(data) {
      if (data.rooms) {
        for (let i = 0; i < data.rooms.length; i++) {
          if (data.rooms[i].property_room_type.type === 'Suite/Apartment') {
            return true;
          }
        }
      }
    },
    handleFocus(event) {
      if(!event.target.classList.contains('select-clicked')) {
        event.target.classList.toggle('select-clicked');
      }
    },
    handleFocusOut(value, event) {
      if(value === null || value === '') {
        if(event.target.classList.contains('select-clicked')) {
          event.target.classList.toggle('select-clicked');
        }
      }
    },
  },
  watch: {
    // "formData.room.id": {
    //   handler: function (id) {
    //     if (id !== '' && id !== null && id !== undefined) {
    //       this.loading = true;
    //       this.form = '';
    //       this.form = {...this.formData.room};
    //       this.loading = false;
    //     } else {
    //       this.form = '';
    //       this.form = {...this.defaultForm};
    //     }
    //   }, immediate: true, deep: true
    // },
    // "formData.room_type_id": function(val) {
    //
    //   switch(val) {
    //     case 1:
    //       this.formData.property_room_type = {type: 'Single'};
    //       break;
    //     case 2:
    //       this.formData.property_room_type = {type: 'Double'};
    //       break;
    //     case 3:
    //       this.formData.property_room_type = {type: 'Twin'};
    //       break;
    //     case 4:
    //       this.formData.property_room_type = {type: 'Twin/Double'};
    //       break;
    //     case 5:
    //       this.formData.property_room_type = {type: 'Triple'};
    //       break;
    //     case 6:
    //       this.formData.property_room_type = {type: 'Quadruple'};
    //       break;
    //     case 7:
    //       this.formData.property_room_type = {type: 'Family'};
    //       break;
    //     case 8:
    //       this.formData.property_room_type = {type: 'Suite/Apartment'};
    //       break;
    //     default:
    //       this.formData.property_room_type = {type: ''};
    //       break;
    //   }
    //
    // },
    // "formData.property_rent_type_id": function(val) {
    //
    //   switch(val) {
    //     case 1:
    //       this.formData.property_rent_type = {type: 'hour'};
    //       break;
    //     case 2:
    //       this.formData.property_rent_type = {type: 'night'};
    //       break;
    //     case 3:
    //       this.formData.property_rent_type = {type: 'week'};
    //       break;
    //     case 4:
    //       this.formData.property_rent_type = {type: 'month'};
    //       break;
    //     default:
    //       this.formData.property_rent_type = {type: ''};
    //       break;
    //   }
    // }
  }
}
</script>

<style lang="scss">

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scroll {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

#addRoomModal {
  height: 100%;
  z-index: 100000;
  .modal-dialog {
    max-width: 60vw; // For mobile should take about 100% width
    .modal-header {
      font-size: 13px;
      color: white;
      border-radius: 10px 10px 0 0;
      background: rgba(128, 0, 128, 0.7);
      padding: .5rem;
      text-transform: uppercase
    }

    .modal-content {
      width: 60vw; // For mobile should take about 100% width
      height: 80vh;
      border-radius: 10px;
      border: none;
      .modal-body {
        overflow-x: hidden;
        overflow-y: scroll;
        width: 60vw; // For mobile should take about 100% width
        height: 80vh;
      }
    }

    .modal-footer {
      justify-content: center;
    }

    .modal-border {
      border-radius: 10px;
      background: white;
      $border: 2px;
      position: relative;
      box-sizing: border-box;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        margin: -$border; /* !importanté */
        border-radius: inherit; /* !importanté */
        background: linear-gradient(to bottom, rgba(128, 0, 128, 0.7), rgba(25, 181, 254, 0.7));
      }
    }

    .close-btn {
      color: white;
      line-height: 30px;
      font-size: 26px;
      border-radius: 50%;
      padding: 1px 5px;
      border: 0;
    }

    .close-btn:hover {
      color: rgba(128, 0, 128, 0.7);
      transform: rotate(90deg);
      transition: 0.5s;
      -moz-transition: 0.5s;
    }
  }
}

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {

}
@media screen and (max-width: 767px) {
  #addRoomModal {
    .modal-dialog {
      max-width: 90vw;

      .modal-content {
        width: 90vw;

        .modal-body {
          width: 90vw;
        }
      }
    }
  }
}
@media screen and (min-width: 768px) {
  #addRoomModal {
    .modal-dialog {
      max-width: 60vw;

      .modal-content {
        width: 60vw;

        .modal-body {
          width: 60vw;
        }
      }
    }
  }
}
@media screen and (min-width: 992px) {

}
@media screen and (min-width: 1200px) {

}
@media (max-height: 800px) and (min-width: 1200px) {

}
@media screen and (min-width: 1680px) {

}
@media (min-height: 1200px) and (min-width: 1600px) {

}
@media screen and (min-width: 1920px) {

}

</style>