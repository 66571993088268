<template>
  <div class="share">
    <button @click.stop="preventParentClick()" class="btn-action-dropdown" type="button" :id="'shareDropdownList'" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
      <span data-tooltip="tooltip" class="m-0 p-0">
        <div class="review-icons-container" style="font-size: 30px;">
          <div class="position-relative icon-wrapper">
            <font-awesome-icon icon="share-square" class="share-icon"/>
          </div>
        </div>
        <span class="tooltiptext">{{shareSocials && shareSocials.tooltip_text ? shareSocials.tooltip_text : 'Share' }}</span>
      </span>
    </button>
    <ul class="dropdown-menu list-unstyled action-buttons" :aria-labelledby="'shareDropdownList'">
      <li v-if="shareSocials && shareSocials.copy" class="action-item" @mouseout="tooltipOut()">
        <span data-tooltip="tooltip">
          <button :class="processing || preview ? 'disabled action-button-copy' : 'action-button-copy'" @click="copyToClipboard(baseUrl + shareSocials.copy_link)">
            <span class="tooltiptext" id="copy-to-clipboard-tooltip">{{ computedCopyToClipboard }}</span>
            <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'clone' }" class="min-width-30 icon-copy" />
            Copy Link
          </button>
        </span>
      </li>
      <li v-if="shareSocials && shareSocials.facebook" class="action-item">
        <a class="text-decoration-none" :href="shareLinks.facebook + shareSocials.facebook_link" target="_blank">
          <button :class="processing || preview ? 'disabled action-button-facebook' : 'action-button-facebook'">
            <font-awesome-icon :icon="{ prefix: 'fab', iconName: 'facebook' }" class="min-width-30 icon-facebook" />
            Facebook
          </button>
        </a>
      </li>
      <li v-if="shareSocials && shareSocials.facebook_messenger" class="action-item d-block d-sm-none"> <!-- href="fb-messenger://share/?link= https:%2F%2Fdevelopers.facebook.com%2Fdocs%2Fsharing%2Freference%2Fsend-dialog&app_id=123456789" -->
        <a class="text-decoration-none" :href="shareLinks.messenger + shareSocials.facebook_messenger_link" target="_blank">
<!--        <a class="text-decoration-none" :href="'https://www.facebook.com/sharer/sharer.php?u=https://www.easetate.co.uk/' + shareSocials.facebook_messenger_link" target="_blank">-->
          <button :class="processing || preview ? 'disabled action-button-messenger' : 'action-button-messenger'">
            <font-awesome-icon :icon="{ prefix: 'fab', iconName: 'facebook-messenger' }" class="min-width-30 icon-messenger" />
            Messenger
          </button>
        </a>
      </li>
      <li v-if="shareSocials && shareSocials.twitter" class="action-item">
        <a class="text-decoration-none" :href="shareLinks.twitter + shareSocials.twitter_link" target="_blank">
          <button :class="processing || preview ? 'disabled action-button-twitter' : 'action-button-twitter'">
            <font-awesome-icon :icon="{ prefix: 'fab', iconName: 'twitter' }" class="min-width-30 icon-twitter" />
            Twitter
          </button>
        </a>
      </li>
      <li v-if="shareSocials && shareSocials.linkedin" class="action-item">
        <a class="text-decoration-none" :href="shareLinks.linkedin + shareSocials.twitter_link" target="_blank">
          <button :class="processing || preview ? 'disabled action-button-linkedin' : 'action-button-linkedin'">
            <font-awesome-icon :icon="{ prefix: 'fab', iconName: 'linkedin' }" class="min-width-30 icon-linkedin" />
            Linkedin
          </button>
        </a>
      </li>
      <li v-if="shareSocials && shareSocials.whatsapp" class="action-item d-block d-sm-none"> <!-- :href="'https://wa.me/?text=https://www.easetate.co.uk/' + shareSocials.whatsapp_link" -->
        <a class="text-decoration-none" :href="shareLinks.whatsapp + shareSocials.whatsapp_link" data-action="share/whatsapp/share" target="_blank">
          <button :class="processing || preview ? 'disabled action-button-whatsapp' : 'action-button-whatsapp'">
            <font-awesome-icon :icon="{ prefix: 'fab', iconName: 'whatsapp' }" class="min-width-30 icon-whatsapp" />
            WhatsApp
          </button>
        </a>
      </li>
      <li v-if="shareSocials && shareSocials.email" class="action-item">
        <a class="text-decoration-none" :href="shareLinks.email + shareSocials.email_link" target="_blank">
          <button :class="processing || preview ? 'disabled action-button-email' : 'action-button-email'">
            <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'envelope' }" class="min-width-30 icon-email" />
            Email
          </button>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Share',
  props: {
    adId: Number,
    socials: Object,
    preview: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      processing: false,
      copy_to_clipboard: 'Copy to clipboard',

      shareLinks: {
        facebook: 'https://www.facebook.com/sharer/sharer.php?u=https://www.easetate.co.uk/',
        messenger: 'fb-messenger://share/?link=https://www.easetate.co.uk/',
        twitter: 'https://twitter.com/intent/tweet?text=https://www.easetate.co.uk/',
        linkedin: 'https://www.linkedin.com/sharing/share-offsite/?url=https://www.easetate.co.uk/',
        whatsapp: 'whatsapp://send?text=https://www.easetate.co.uk/',
        email: 'mailto:?body=https://www.easetate.co.uk/'
      },
      shareSocials: '',

      // For preview mode
      shareLinksPreview: {
        facebook: 'preview/',
        messenger: 'preview/',
        twitter: 'preview/',
        linkedin: 'preview/',
        whatsapp: 'preview/',
        email: 'preview/',
      },
      // For preview mode
      defaultSocials: {
        facebook: true,
        facebook_link: 'ad-details/' + this.adId,
        twitter: true,
        twitter_link: 'ad-details/' + this.adId,
        email: true,
        email_link: 'ad-details/' + this.adId,
        linkedin: true,
        linkedin_link: 'ad-details/' + this.adId,
        whatsapp: true,
        whatsapp_link: 'ad-details/' + this.adId,
        facebook_messenger: true,
        facebook_messenger_link: 'ad-details/' + this.adId,
        copy: true,
        copy_link: 'ad-details/' + this.adId,
        tooltip_text: 'Share'
      },
    }
  },
  computed: {
    baseUrl() {
      return this.$baseUrl;
    },
    computedCopyToClipboard() {
      return this.copy_to_clipboard;
    }
  },
  methods: {
    preventParentClick() {
      // Do nothing, its just used to prevent parent function trigger (setActivePropertyDetailsThumbnail)
    },
    copyToClipboard(url) {
      navigator.clipboard.writeText(url);
      this.copy_to_clipboard = "Copied: " + url;
    },
    tooltipOut() {
      this.copy_to_clipboard = "Copy to clipboard";
    }
  },
  watch: {
    "adId": {
      handler(val) {
        if (val !== null) {
          this.shareSocials = this.socials;
        }
      }, deep: true, immediate: true
    },
    "preview": {
      handler(val) {
        if (val === true) {
          this.shareLinks = this.shareLinksPreview;
          this.shareSocials = this.defaultSocials;
        }
      }, deep: true, immediate: true
    }
  }
}
</script>

<style lang="scss">

.share .tooltiptext {
  position: absolute;
  top: -25px;
  width: max-content !important;
}

.share-icon {
  transform: scale(1);
  transition: 0.5s;
  -moz-transition: 0.5s;
  color: dodgerblue;
}

.share-icon path {
  stroke: black;
  stroke-width: 20;
}

.share-icon:hover {
  color: royalblue;
  transform: scale(1.1);
  transition: 0.5s;
  -moz-transition: 0.5s;
}

/* Start Action Buttons */
.share {
  .btn-action-dropdown {
    border: none;
    background-color: transparent; // #e9ecef
    cursor: pointer;
  }
  .btn-action-dropdown:hover {
    cursor: pointer;
  }
}

.action-dropdown:hover {
  cursor: pointer;
}

.share .action-buttons {
  z-index: 1001;
  .action-item {
    padding: 2px 5px 2px 5px;
    margin: 2px 5px 2px 5px;
    min-width: 80px;
    font-size: 13px;
  }

  .action-button-copy {
    text-align: start;
    min-height: 30px;
    width: 100%;
    background-color: unset;
    border: none;
    //border-bottom: 3px solid deepskyblue;
    min-width: 80px;
    transition: 0.5s;
    -moz-transition: 0.5s;
    transform: scale(1);
    .icon-copy {
      color: mediumpurple;
      transition: 0.5s;
      -moz-transition: 0.5s;
    }
  }

  .action-button-copy:hover {
    background-color: unset;
    border: none;
    transition: 0.5s;
    -moz-transition: 0.5s;
    transform: scale(1.1);
  }

  .action-button-copy:hover .icon-copy {
    color: mediumpurple;
    transition: 0.5s;
    -moz-transition: 0.5s;
  }

  .action-button-facebook {
    text-align: start;
    min-height: 30px;
    width: 100%;
    background-color: unset;
    border: none;
    //border-bottom: 3px solid deepskyblue;
    min-width: 80px;
    transition: 0.5s;
    -moz-transition: 0.5s;
    transform: scale(1);
    .icon-facebook {
      color: #4267B2;
      transition: 0.5s;
      -moz-transition: 0.5s;
    }
  }

  .action-button-facebook:hover {
    background-color: unset;
    border: none;
    transition: 0.5s;
    -moz-transition: 0.5s;
    transform: scale(1.1);
  }

  .action-button-facebook:hover .icon-facebook {
    color: #4267B2;
    transition: 0.5s;
    -moz-transition: 0.5s;
  }

  .action-button-messenger {
    text-align: start;
    min-height: 30px;
    width: 100%;
    background-color: unset;
    border: none;
    //border-bottom: 3px solid deepskyblue;
    min-width: 80px;
    transition: 0.5s;
    -moz-transition: 0.5s;
    transform: scale(1);
    .icon-messenger {
      color: #00B2FF;
      transition: 0.5s;
      -moz-transition: 0.5s;
    }
  }

  .action-button-messenger:hover {
    background-color: unset;
    border: none;
    transition: 0.5s;
    -moz-transition: 0.5s;
    transform: scale(1.1);
  }

  .action-button-messenger:hover .icon-messenger {
    color: #00B2FF;
    transition: 0.5s;
    -moz-transition: 0.5s;
  }

  .action-button-twitter {
    text-align: start;
    min-height: 30px;
    width: 100%;
    background-color: unset;
    border: none;
    //border-bottom: 3px solid deepskyblue;
    min-width: 80px;
    transition: 0.5s;
    -moz-transition: 0.5s;
    transform: scale(1);
    .icon-twitter {
      color: #1DA1F2;
      transition: 0.5s;
      -moz-transition: 0.5s;
    }
  }

  .action-button-twitter:hover {
    background-color: unset;
    border: none;
    transition: 0.5s;
    -moz-transition: 0.5s;
    transform: scale(1.1);
  }

  .action-button-twitter:hover .icon-twitter {
    color: #1DA1F2;
    transition: 0.5s;
    -moz-transition: 0.5s;
  }

  .action-button-linkedin {
    text-align: start;
    min-height: 30px;
    width: 100%;
    background-color: unset;
    border: none;
    //border-bottom: 3px solid deepskyblue;
    min-width: 80px;
    transition: 0.5s;
    -moz-transition: 0.5s;
    transform: scale(1);
    .icon-linkedin {
      color: #0077B5;
      transition: 0.5s;
      -moz-transition: 0.5s;
    }
  }

  .action-button-linkedin:hover {
    background-color: unset;
    border: none;
    transition: 0.5s;
    -moz-transition: 0.5s;
    transform: scale(1.1);
  }

  .action-button-linkedin:hover .icon-linkedin {
    color: #0077B5;
    transition: 0.5s;
    -moz-transition: 0.5s;
  }

  .action-button-whatsapp {
    text-align: start;
    min-height: 30px;
    width: 100%;
    background-color: unset;
    border: none;
    //border-bottom: 3px solid deepskyblue;
    min-width: 80px;
    transition: 0.5s;
    -moz-transition: 0.5s;
    transform: scale(1);
    .icon-whatsapp {
      color: #25D366;
      transition: 0.5s;
      -moz-transition: 0.5s;
    }
  }

  .action-button-whatsapp:hover {
    background-color: unset;
    border: none;
    transition: 0.5s;
    -moz-transition: 0.5s;
    transform: scale(1.1);
  }

  .action-button-whatsapp:hover .icon-whatsapp {
    color: #25D366;
    transition: 0.5s;
    -moz-transition: 0.5s;
  }

  .action-button-email {
    text-align: start;
    min-height: 30px;
    width: 100%;
    background-color: unset;
    border: none;
    //border-bottom: 3px solid deepskyblue;
    min-width: 80px;
    transition: 0.5s;
    -moz-transition: 0.5s;
    transform: scale(1);
    .icon-email {
      color: red;
      transition: 0.5s;
      -moz-transition: 0.5s;
    }
  }

  .action-button-email:hover {
    background-color: unset;
    border: none;
    transition: 0.5s;
    -moz-transition: 0.5s;
    transform: scale(1.1);
  }

  .action-button-email:hover .icon-email {
    color: red;
    transition: 0.5s;
    -moz-transition: 0.5s;
  }
}

.showReportTypeMenu {
  display: block;
  right: 0 !important;
  top: 100% !important;
  transition: 0.5s;
  -moz-transition: 0.5s;
}

.success-msg {
  position: fixed;
  top: 20%;
  left: 10%;
  z-index: 9999;
}

/* End Action Buttons */

</style>