<template>
  <!-- Modal -->
  <div class="modal fade" id="bookingModal" tabindex="-1" role="dialog" data-bs-backdrop="true" aria-labelledby="bookingModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-border">

        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="bookingModalLabel">{{ booking !== 'view' ? (booking === 'reserve' ? 'Reservation' : (booking === 'appoint' ? 'Book Service' : 'Booking')) : 'Book Viewing'}}</h5>
            <button type="button" id="closeBookingModal" class="btn btn-outline-light close close-btn" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body scroll-width-thin">
            <!-- Loading -->
            <Loader class="mt-2" :loading="loading" :small="false"></Loader>

            <!-- Form for viewing/booking date -->
            <div v-if="!loading" class="col-12 m-0 p-0 zindex-10">
              <div class="">
                <ValidationObserver vid="vo-1" ref="bookingFormObserver" v-slot="{ handleSubmit }">
                <form action="javascript:void(0)" method="post" enctype="multipart/form-data">
                  <div class="row m-0 p-0">
                    <div class="col-12 col-sm-12 col-md-3 col-lg-3 m-0 p-0">
                      <label class="pb-2">Request dates<span class="required"> *</span></label><br/>
                      <!--    mode="dateTime" :valid-hours="validHours" :minute-increment="15"   @input="processing=true"-->
                      <date-picker
                          v-show="booking === 'reserve'"
                          id="ranged"
                          :value="form.date_range"
                          v-model="form.date_range"
                          mode="date"
                          is24hr
                          :min-date='calculateMinDate'
                          :disabled-dates="disabledDates"
                          :reactive=true
                          :is-range=true
                          :show-weeknumbers=true
                          color="blue"
                          :attributes="attr"
                      />
                      <!-- max-date='new Date(new Date().setDate(new Date().getDate()+30))' -->
                      <date-picker
                          v-show="booking === 'view' || booking === 'appoint'"
                          id="singular"
                          :value="form.single_start_date"
                          v-model="form.single_start_date"
                          mode="dateTime"
                          is24hr
                          :minute-increment="15"
                          :min-date='calculateMinDate'
                          :max-date='calculateMaxDate'
                          :disabled-dates="disabledDates"
                          :valid-hours="validHours"
                          :reactive=true
                          :is-range=false
                          :show-weeknumbers=true
                          color="blue"
                          :attributes="attr"
                      />
                      <!-- I will use separate time date-pickers for start and end date (check-in and check-out), and for singular date (view_at) maybe? -->
                      <br/>
                      <label v-if="booking === 'reserve'" class="pb-2">Check-in<span class="required"> *</span></label>
                      <br v-if="booking === 'reserve'"/>
                      <date-picker
                          v-if="booking === 'reserve'"
                          id="ranged-check-in"
                          mode="time"
                          v-model="form.date_range.start"
                          is24hr
                          :minute-increment="15"
                          :valid-hours="validCheckInHours"
                          :reactive=true
                          style="min-width: 250px;"
                      />
                      <br v-if="booking === 'reserve'"/>
                      <label v-if="booking === 'reserve'" class="pb-2">Checkout<span class="required"> *</span></label>
                      <br v-if="booking === 'reserve'"/>
                      <date-picker
                          v-if="booking === 'reserve'"
                          id="ranged-check-out"
                          mode="time"
                          v-model="form.date_range.end"
                          is24hr
                          :minute-increment="15"
                          :valid-hours="validCheckOutHours"
                          :reactive=true
                          style="min-width: 250px;"
                      />
                      <p v-if="validationErrors && validationErrors.date_start" class="required-info text-center">
                        {{ validationErrors.date_start[0] }}
                      </p>
                      <p v-if="validationErrors && validationErrors.date_end" class="required-info text-center">
                        {{ validationErrors.date_end[0] }}
                      </p>
                    </div>

                    <div v-if="booking === 'reserve' || (booking === 'view' && !isLongTermRoom())" class="col-12 col-sm-12 col-md-9 col-lg-9 m-0 p-0 text-start">

                      <div v-if="booking === 'reserve'" class="row m-0 p-0">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 m-0 p-0">
                          <div class="row m-0 p-0">
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6">
                              <label class="pb-2">
                                <font-awesome-icon icon="user-friends" class="icon-fa" />
                                Guests
                                <span class="required"> *</span>
                              </label>
                              <select
                                  v-model="form.guests"
                                  class="select-placeholder filter-select cursor-pointer"
                                  @focus="handleFocus($event)"
                                  @focusout="handleFocusOut(form.guests, $event)"
                                  tabindex="-1"
                                  aria-label="guests"
                                  name="guests"
                                  id="guests"
                              >
                                <option value="" disabled>Number of guests</option>
                                <option :value="1">1</option>
                                <option :value="2">2</option>
                                <option :value="3">3</option>
                                <option :value="4">4</option>
                                <option :value="5">5</option>
                                <option :value="6">6</option>
                                <option :value="7">7</option>
                                <option :value="8">8</option>
                                <option :value="9">9</option>
                                <option :value="10">10</option>
                                <option :value="11">11</option>
                                <option :value="12">12</option>
                                <option :value="13">13</option>
                                <option :value="14">14</option>
                                <option :value="15">15</option>
                                <option :value="16">16</option>
                                <option :value="17">17</option>
                                <option :value="18">18</option>
                                <option :value="19">19</option>
                                <option :value="20">20+</option>
                              </select>
                              <p v-if="validationErrors && validationErrors.guests" class="required-info text-center">
                                {{ validationErrors.guests[0] }}
                              </p>
                            </div>

                            <div class="col-12 col-sm-12 col-md-6 col-lg-6">
                              <label class="pb-2">
                                <font-awesome-icon icon="paw" class="icon-fa" />
                                Pets
                              </label>
                              <select
                                  v-model="form.pets"
                                  class="select-placeholder filter-select cursor-pointer"
                                  @focus="handleFocus($event)"
                                  @focusout="handleFocusOut(form.pets, $event)"
                                  tabindex="-1"
                                  aria-label="pets"
                                  name="pets"
                                  id="pets"
                              >
                                <option value="" disabled>Number of pets</option>
                                <option :value="0">None</option>
                                <option :value="1">1</option>
                                <option :value="2">2</option>
                                <option :value="3">3</option>
                                <option :value="4">4</option>
                                <option :value="5">5</option>
                                <option :value="6">6</option>
                                <option :value="7">7</option>
                                <option :value="8">8</option>
                                <option :value="9">9</option>
                                <option :value="10">10</option>
                                <option :value="11">11</option>
                                <option :value="12">12</option>
                                <option :value="13">13</option>
                                <option :value="14">14</option>
                                <option :value="15">15</option>
                                <option :value="16">16</option>
                                <option :value="17">17</option>
                                <option :value="18">18</option>
                                <option :value="19">19</option>
                                <option :value="20">20+</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- Room Types (Only on Reserve)-->
                      <div v-if="(booking === 'reserve' && !isStudioFlat()) || (booking === 'view' && !isLongTermRoom())" class="col-12 col-sm-12 col-md-12 col-lg-12 m-0 p-0">
                        <div class="row m-0 p-0">

                          <!-- I need to loop all existing rooms but not of the property,
                          this rooms data must be get with some kind of call once 'date' is picked, so that
                          we can do all the necessary checks whether this specific room is still available or not
                          for time being I am using details of property passed to modal, just to see how data looks like
                          front-end wise...
                          -->
                          <!-- Room List -->
                          <div v-if="(booking === 'reserve' && !isStudioFlat())" class="col-12 col-sm-12 col-md-8 col-lg-8 m-0 p-0">
                            <div v-if="availableRooms && availableRooms.length > 0 && !loadingRooms" class="row m-0 p-0">
                              <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                <ul class="list-unstyled">
                                  <li class="room-list-header mt-1">
                                    <div class="row m-0 p-0">
                                      <div class="col-2 col-sm-2 col-md-2 col-lg-2 p-1 options-paragraph">
                                        Type
                                      </div>
                                      <div class="col-2 col-sm-2 col-md-2 col-lg-2 p-1 options-paragraph">
                                        Guests
                                      </div>
                                      <div class="col-2 col-sm-2 col-md-2 col-lg-2 p-1 options-paragraph">
                                        Price
                                      </div>
                                      <div class="col-1 col-sm-1 col-md-1 col-lg-1 p-1 options-paragraph">
                                        Avail
                                      </div>
                                      <div class="col-1 col-sm-1 col-md-1 col-lg-1 p-1 options-paragraph">
                                        <span data-tooltip="tooltip">
                                          <font-awesome-icon icon="paw" class="icon-fa" />
                                          <span class="tooltiptext">Pet Friendly</span>
                                        </span>
                                      </div>
                                      <div class="col-1 col-sm-1 col-md-1 col-lg-1 p-1 options-paragraph">
                                        <span data-tooltip="tooltip">
                                          <font-awesome-icon icon="smoking" class="icon-fa" />
                                          <span class="tooltiptext">Smoking Allowed</span>
                                        </span>
                                      </div>
                                      <div class="col-1 col-sm-1 col-md-1 col-lg-1 p-1 options-paragraph">
                                        Qty
                                      </div>
                                      <div class="col-2 col-sm-2 col-md-2 col-lg-2 p-1 options-paragraph">
                                        Total
                                      </div>
                                    </div>
                                  </li>
                                  <li @click="showRoomDetails(room)" class="room-list mt-1" v-for="room in availableRooms" v-bind:key="room.id">
                                    <div class="row m-0 p-0">
                                      <div class="col-2 col-sm-2 col-md-2 col-lg-2 p-1 options-paragraph">
                                        <div class="position-relative">
                                          <div class="text-truncate-item no-text-wrap overflow-hidden text-truncate ">
                                            {{ room.property_room_type.type }}
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-2 col-sm-2 col-md-2 col-lg-2 p-1 options-paragraph">
                                        {{ room.guests }}
                                      </div>
                                      <div class="col-2 col-sm-2 col-md-2 col-lg-2 p-1 options-paragraph">
                                        <div class="position-relative">
                                          <div class="text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                            <font-awesome-icon icon="pound-sign" class="icon-fa me-1" />{{ convertNumberToCommas(room.price) }} per {{ room.property_rent_type.type }}
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-1 col-sm-1 col-md-1 col-lg-1 p-1 options-paragraph">
                                        {{ room.quantity }}
                                      </div>
                                      <div class="col-1 col-sm-1 col-md-1 col-lg-1 p-1">
                                        <font-awesome-icon v-if="room.pet_friendly_room" icon="check" class="icon-fa green-text" />
                                        <font-awesome-icon v-else icon="times" class="icon-fa red-text" />
                                      </div>
                                      <div class="col-1 col-sm-1 col-md-1 col-lg-1 p-1">
                                        <font-awesome-icon v-if="room.smoking_room" icon="check" class="icon-fa green-text" />
                                        <font-awesome-icon v-else icon="times" class="icon-fa red-text" />
                                      </div>
                                      <div class="col-1 col-sm-1 col-md-1 col-lg-1 p-1">
                                        <!-- v-model="form.picked_rooms[room.id]" -->
                                        <input
                                            type="number"
                                            placeholder="..."
                                            min="0"
                                            :max="room.quantity"
                                            :name="'room_' + room.id"
                                            v-model="picked_rooms['room_' + room.id]"
                                            class="qty-input select-placeholder"
                                            @focus="handleFocus($event)"
                                            @focusout="handleFocusOut(picked_rooms, $event)"
                                            data-vv-as="single_room"
                                            name="single_room"
                                        >
                                      </div>
                                      <div class="col-2 col-sm-2 col-md-2 col-lg-2 p-1">
                                        <!-- This total price will be for specific room + number of days/hours -->
                                        <font-awesome-icon icon="pound-sign" class="icon-fa me-1" />{{ convertNumberToCommas(calculateTotalRoomPrice(room.price, picked_rooms['room_' + room.id], room.property_rent_type_id)) }}
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>

                            <div v-if="loadingRooms && booking === 'reserve' && !isStudioFlat()" class="row m-0 p-0">
                              <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                <ul class="list-unstyled">
                                  <li class="room-list-header mt-1 p-3">
                                    Please pick dates to see the list of available rooms
                                  </li>
                                </ul>
                              </div>
                            </div>

                            <div v-if="availableRooms && availableRooms.length == 0 && booking === 'reserve' && !isStudioFlat()" class="row m-0 p-0">
                              <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                <ul class="list-unstyled">
                                  <li class="room-list-header mt-1 p-3">
                                    There are no rooms for specified dates
                                  </li>
                                </ul>
                              </div>
                            </div>

                          </div>

                          <!-- Room Details -->
                          <div v-if="booking === 'reserve' && !isStudioFlat()" class="col-12 col-sm-12 col-md-4 col-lg-4">
                            <!-- New Component to display room details data -->
                            <div class="room-details mt-1">
                              <RoomDetails :formData="roomDetailsData"></RoomDetails>
                            </div>
                          </div>

<!--                          <div class="form-group col-12 pb-2 ps-5 pe-5">-->
<!--                            <label class="pb-2">Please choose space type<span class="required"> *</span></label>-->
<!--                            <ValidationProvider rules="required" v-slot="{ errors }">-->
<!--                              <select-->
<!--                                  v-model="form.room_type_id"-->
<!--                                  class="form-select select-placeholder"-->
<!--                                  @focus="handleFocus($event)"-->
<!--                                  @focusout="handleFocusOut(form.room_type_id, $event)"-->
<!--                                  tabindex="-1"-->
<!--                                  aria-label="room_type_id"-->
<!--                                  name="room_type_id"-->
<!--                                  id="roomType"-->
<!--                              >-->
<!--                                <option value="" disabled>Select space type</option>-->
<!--                                <option :value="1">Single Room</option>-->
<!--                                <option :value="2">Double Room</option>-->
<!--                                <option :value="3">Twin Room</option>-->
<!--                                <option :value="4">Twin Double Room</option>-->
<!--                                <option :value="5">Triple Room</option>-->
<!--                                <option :value="6">Quadruple Room</option>-->
<!--                                <option :value="7">Family Room</option>-->
<!--                                <option :value="8">Whole Place (Suite/Apartment)</option>-->
<!--                              </select>-->
<!--                              <span class="red-text"><small>{{ errors[0] }}</small></span>-->
<!--                            </ValidationProvider>-->
<!--                          </div>-->
                        </div>
                      </div>
                      <!-- Reserve Studio Flat -->
                      <div v-if="(booking === 'reserve' && isStudioFlat()) || (booking === 'view' && !isLongTermRoom())" class="col-12 col-sm-12 col-md-12 col-lg-12 m-0 p-0">
                        <div class="row m-0 p-0 mt-1">
                          <ul class="list-unstyled">
                            <li class="d-inline-block me-1 summary-list">
                              <strong>Price: £</strong> <span class="price-color">{{ convertNumberToCommas(adDetails.price) }}</span> {{ adDetails.is_let && adDetails.property_rent_type ? 'per ' + adDetails.property_rent_type.type : '' }}
                            </li>
                            <li v-if="adDetails.is_let" class="d-inline-block me-1 mt-1 mb-1 summary-list">
                              Max Guests:  {{ adDetails.tenants_guests ? adDetails.tenants_guests : 'N/A' }}
                            </li>
                            <li v-if="adDetails.is_sale" class="d-inline-block me-1 mt-1 mb-1 summary-list">
                              Tenure:  {{ adDetails.is_freehold ? 'Freehold' : 'Leasehold' }}
                            </li>
                            <li v-if="adDetails.is_sale && adDetails.is_investment" class="d-inline-block me-1 mt-1 mb-1 summary-list">
                              Investment
                              <font-awesome-icon v-if="adDetails.is_investment" icon="check" class="icon-fa green-text" />
                              <font-awesome-icon v-else icon="times" class="icon-fa red-text" />
                            </li>
                            <li v-if="adDetails.is_sale && adDetails.is_cash" class="d-inline-block me-1 mt-1 mb-1 summary-list">
                              Cash Only
                              <font-awesome-icon v-if="adDetails.is_cash" icon="check" class="icon-fa green-text" />
                              <font-awesome-icon v-else icon="times" class="icon-fa red-text" />
                            </li>
                            <li v-if="adDetails.is_sale && adDetails.is_auction" class="d-inline-block me-1 mt-1 mb-1 summary-list">
                              Auction
                              <font-awesome-icon v-if="adDetails.is_auction" icon="check" class="icon-fa green-text" />
                              <font-awesome-icon v-else icon="times" class="icon-fa red-text" />
                            </li>
                            <li v-if="adDetails.is_sale && adDetails.is_mixed_use" class="d-inline-block me-1 mt-1 mb-1 summary-list">
                              Mixed-Use
                              <font-awesome-icon v-if="adDetails.is_mixed_use" icon="check" class="icon-fa green-text" />
                              <font-awesome-icon v-else icon="times" class="icon-fa red-text" />
                            </li>
                          </ul>

                          <ul class="list-unstyled">
                            <li v-if="adDetails.is_let" class="d-inline-block me-1 mt-1 mb-1 summary-list">
                              Wi-Fi
                              <font-awesome-icon v-if="adDetails.property_details.wifi" icon="check" class="icon-fa green-text" />
                              <font-awesome-icon v-else icon="times" class="icon-fa red-text" />
                              {{ adDetails.property_details.wifi ? (adDetails.property_details.wifi_paid ? 'Paid' : 'Free') : '' }}
                            </li>
                            <li class="d-inline-block me-1 mt-1 mb-1 summary-list">
                              Pet Friendly
                              <font-awesome-icon v-if="adDetails.is_pet_friendly" icon="check" class="icon-fa green-text" />
                              <font-awesome-icon v-else icon="times" class="icon-fa red-text" />
                            </li>
                            <li v-if="adDetails.is_let" class="d-inline-block me-1 mt-1 mb-1 summary-list">
                              Smoking Rooms
                              <font-awesome-icon v-if="adDetails.property_details.smoking_rooms" icon="check" class="icon-fa green-text" />
                              <font-awesome-icon v-else icon="times" class="icon-fa red-text" />
                            </li>
                            <li v-if="adDetails.is_let" class="d-inline-block me-1 mt-1 mb-1 summary-list">
                              Non-smoking Rooms
                              <font-awesome-icon v-if="adDetails.property_details.non_smoking_rooms" icon="check" class="icon-fa green-text" />
                              <font-awesome-icon v-else icon="times" class="icon-fa red-text" />
                            </li>
                            <li class="d-inline-block me-1 mt-1 mb-1 summary-list">
                              Furnished
                              <font-awesome-icon v-if="adDetails.is_furnished" icon="check" class="icon-fa green-text" />
                              <font-awesome-icon v-else icon="times" class="icon-fa red-text" />
                            </li>
                            <li class="d-inline-block me-1 mt-1 mb-1 summary-list">
                              Accessible
                              <font-awesome-icon v-if="adDetails.is_accessible" icon="check" class="icon-fa green-text" />
                              <font-awesome-icon v-else icon="times" class="icon-fa red-text" />
                            </li>
                            <li v-if="adDetails.is_let" class="d-inline-block me-1 mt-1 mb-1 summary-list">
                              Events Allowed
                              <font-awesome-icon v-if="adDetails.events_allowed" icon="check" class="icon-fa green-text" />
                              <font-awesome-icon v-else icon="times" class="icon-fa red-text" />
                            </li>
                            <li v-if="adDetails.is_sale" class="d-inline-block me-1 mt-1 mb-1 summary-list">
                              Tenanted
                              <font-awesome-icon v-if="adDetails.is_tenanted" icon="check" class="icon-fa green-text" />
                              <font-awesome-icon v-else icon="times" class="icon-fa red-text" />
                            </li>
                            <li v-if="adDetails.is_sale" class="d-inline-block me-1 mt-1 mb-1 summary-list">
                              Shared Ownership
                              <font-awesome-icon v-if="adDetails.is_shared_ownership" icon="check" class="icon-fa green-text" />
                              <font-awesome-icon v-else icon="times" class="icon-fa red-text" />
                            </li>
                            <li v-if="adDetails.is_sale" class="d-inline-block me-1 mt-1 mb-1 summary-list">
                              HMO
                              <font-awesome-icon v-if="adDetails.is_hmo" icon="check" class="icon-fa green-text" />
                              <font-awesome-icon v-else icon="times" class="icon-fa red-text" />
                            </li>
                            <li v-if="adDetails.is_sale" class="d-inline-block me-1 mt-1 mb-1 summary-list">
                              Over 60's
                              <font-awesome-icon v-if="adDetails.is_over_60s" icon="check" class="icon-fa green-text" />
                              <font-awesome-icon v-else icon="times" class="icon-fa red-text" />
                            </li>
                            <li class="d-inline-block me-1 mt-1 mb-1 summary-list">
                              Seashore
                              <font-awesome-icon v-if="adDetails.is_seashore" icon="check" class="icon-fa green-text" />
                              <font-awesome-icon v-else icon="times" class="icon-fa red-text" />
                            </li>
                            <li class="d-inline-block me-1 mt-1 mb-1 summary-list">
                              Waterfront
                              <font-awesome-icon v-if="adDetails.is_waterfront" icon="check" class="icon-fa green-text" />
                              <font-awesome-icon v-else icon="times" class="icon-fa red-text" />
                            </li>
                            <li class="d-inline-block me-1 mt-1 mb-1 summary-list">
                              Balcony
                              <font-awesome-icon v-if="adDetails.property_details.balcony" icon="check" class="icon-fa green-text" />
                              <font-awesome-icon v-else icon="times" class="icon-fa red-text" />
                            </li>
                          </ul>

                          <ul class="list-unstyled">
                            <li class="d-inline-block me-1 mt-1 mb-1 summary-list">
                              Single Room: {{ adDetails.property_details.single_room }}
                            </li>
                            <li class="d-inline-block me-1 mt-1 mb-1 summary-list">
                              Double Room: {{ adDetails.property_details.double_room }}
                            </li>
                            <li class="d-inline-block me-1 mt-1 mb-1 summary-list">
                              Studio Flat
                              <font-awesome-icon v-if="adDetails.property_details.studio_room" icon="check" class="icon-fa green-text" />
                              <font-awesome-icon v-else icon="times" class="icon-fa red-text" />
                            </li>
                            <li class="d-inline-block me-1 mt-1 mb-1 summary-list">
                              Living Room
                              <font-awesome-icon v-if="adDetails.property_details.living_room" icon="check" class="icon-fa green-text" />
                              <font-awesome-icon v-else icon="times" class="icon-fa red-text" />
                            </li>
                            <li v-if="adDetails.is_sale" class="d-inline-block me-1 mt-1 mb-1 summary-list">
                              Dining Room
                              <font-awesome-icon v-if="adDetails.property_details.dining_room" icon="check" class="icon-fa green-text" />
                              <font-awesome-icon v-else icon="times" class="icon-fa red-text" />
                            </li>
                          </ul>

                          <ul class="list-unstyled">
                            <li class="d-inline-block me-1 mt-1 mb-1 summary-list">
                              Kitchen
                              <font-awesome-icon v-if="adDetails.property_details.kitchen" icon="check" class="icon-fa green-text" />
                              <font-awesome-icon v-else icon="times" class="icon-fa red-text" />
                            </li>
                            <li class="d-inline-block me-1 mt-1 mb-1 summary-list">
                              Fridge
                              <font-awesome-icon v-if="adDetails.property_details.fridge" icon="check" class="icon-fa green-text" />
                              <font-awesome-icon v-else icon="times" class="icon-fa red-text" />
                            </li>
                            <li class="d-inline-block me-1 mt-1 mb-1 summary-list">
                              Freezer
                              <font-awesome-icon v-if="adDetails.property_details.freezer" icon="check" class="icon-fa green-text" />
                              <font-awesome-icon v-else icon="times" class="icon-fa red-text" />
                            </li>
                            <li class="d-inline-block me-1 mt-1 mb-1 summary-list">
                              Microwave
                              <font-awesome-icon v-if="adDetails.property_details.microwave" icon="check" class="icon-fa green-text" />
                              <font-awesome-icon v-else icon="times" class="icon-fa red-text" />
                            </li>
                            <li class="d-inline-block me-1 mt-1 mb-1 summary-list">
                              Oven
                              <font-awesome-icon v-if="adDetails.property_details.oven" icon="check" class="icon-fa green-text" />
                              <font-awesome-icon v-else icon="times" class="icon-fa red-text" />
                            </li>
                            <li class="d-inline-block me-1 mt-1 mb-1 summary-list">
                              Kettle
                              <font-awesome-icon v-if="adDetails.property_details.kettle" icon="check" class="icon-fa green-text" />
                              <font-awesome-icon v-else icon="times" class="icon-fa red-text" />
                            </li>
                            <li class="d-inline-block me-1 mt-1 mb-1 summary-list">
                              Toaster
                              <font-awesome-icon v-if="adDetails.property_details.toaster" icon="check" class="icon-fa green-text" />
                              <font-awesome-icon v-else icon="times" class="icon-fa red-text" />
                            </li>
                            <li class="d-inline-block me-1 mt-1 mb-1 summary-list">
                              Coffee
                              <font-awesome-icon v-if="adDetails.property_details.coffee" icon="check" class="icon-fa green-text" />
                              <font-awesome-icon v-else icon="times" class="icon-fa red-text" />
                            </li>
                          </ul>

                          <ul class="list-unstyled">
                            <li class="d-inline-block me-1 mt-1 mb-1 summary-list">
                              WC
                              <font-awesome-icon v-if="adDetails.property_details.wc" icon="check" class="icon-fa green-text" />
                              <font-awesome-icon v-else icon="times" class="icon-fa red-text" />
                            </li>
                            <li class="d-inline-block me-1 mt-1 mb-1 summary-list">
                              Downstairs WC
                              <font-awesome-icon v-if="adDetails.property_details.downstairs_wc" icon="check" class="icon-fa green-text" />
                              <font-awesome-icon v-else icon="times" class="icon-fa red-text" />
                            </li>
                            <li class="d-inline-block me-1 mt-1 mb-1 summary-list">
                              Shower
                              <font-awesome-icon v-if="adDetails.property_details.shower" icon="check" class="icon-fa green-text" />
                              <font-awesome-icon v-else icon="times" class="icon-fa red-text" />
                            </li>
                            <li class="d-inline-block me-1 mt-1 mb-1 summary-list">
                              Bath
                              <font-awesome-icon v-if="adDetails.property_details.bath" icon="check" class="icon-fa green-text" />
                              <font-awesome-icon v-else icon="times" class="icon-fa red-text" />
                            </li>
                            <li class="d-inline-block me-1 mt-1 mb-1 summary-list">
                              Sink
                              <font-awesome-icon v-if="adDetails.property_details.sink" icon="check" class="icon-fa green-text" />
                              <font-awesome-icon v-else icon="times" class="icon-fa red-text" />
                            </li>
                          </ul>

                          <ul class="list-unstyled">
                            <li v-if="(booking === 'reserve') || (booking === 'view' && isLongTermRoom())" class="d-inline-block me-1 mt-1 mb-1 summary-list">
                              Parking
                              <font-awesome-icon v-if="adDetails.property_details.parking" icon="check" class="icon-fa green-text" />
                              <font-awesome-icon v-else icon="times" class="icon-fa red-text" />
                              {{ adDetails.property_details.parking ? (adDetails.property_details.parking_paid ? 'Paid: £ ' + adDetails.property_details.parking_fee : 'Free') : '' }}
                            </li>
                            <li v-if="(booking === 'reserve') || (booking === 'view' && isLongTermRoom())" class="d-inline-block me-1 mt-1 mb-1 summary-list">
                              Parking Spaces: {{ adDetails.property_details.parking_spaces ? adDetails.property_details.parking_spaces : 'N/A' }}
                            </li>
                            <li v-if="booking === 'view' && !isLongTermRoom()" class="d-inline-block me-1 mt-1 mb-1 summary-list">
                              Driveway: {{ adDetails.property_details.driveway ? adDetails.property_details.driveway : '0' }}
                            </li>
                            <li v-if="booking === 'view' && !isLongTermRoom()" class="d-inline-block me-1 mt-1 mb-1 summary-list">
                              Garage: {{ adDetails.property_details.garage ? adDetails.property_details.garage : '0' }}
                            </li>
                          </ul>
                        </div>
                      </div>

<!--                      <div v-if="(booking === 'view' && !isLongTermRoom()) || booking === 'appoint'" class="col-12 col-sm-12 col-md-12 col-lg-12 m-0 p-0">-->
<!--                        &lt;!&ndash; Premises Details or Service Details &ndash;&gt;-->
<!--                        <ul class="list-unstyled">-->
<!--                          <li class="d-inline-block me-1 mt-1 mb-1 summary-list">-->
<!--                            Tenure:  {{ adDetails.is_freehold ? 'Freehold' : 'Leasehold' }}-->
<!--                          </li>-->
<!--                          <li class="d-inline-block me-1 mt-1 mb-1 summary-list">-->
<!--                            Pet Friendly:  {{ adDetails.is_pet_friendly ? 'Yes' : 'No' }}-->
<!--                          </li>-->
<!--                        </ul>-->
<!--                        <ul class="list-unstyled">-->
<!--                          <li class="d-inline-block me-1 mt-1 mb-1 summary-list">-->
<!--                            Price: £ <span class="price-color">{{ calculateTotalPrice }}</span> {{ adDetails.property_rent_type ? 'per ' + adDetails.property_rent_type.type : '' }}-->
<!--                          </li>-->
<!--                        </ul>-->
<!--                      </div>-->

                    </div>

                    <!-- Long-Term Room -->
                    <div v-if="(booking === 'view' && isLongTermRoom())" class="col-12 col-sm-12 col-md-9 col-lg-9 m-0 p-0 text-start">
                      <div class="row m-0 p-0">

                        <!-- Room List -->
                        <div class="col-12 col-sm-12 col-md-8 col-lg-8 m-0 p-0">
                          <div v-if="adDetails.property_rooms && adDetails.property_rooms.length > 0" class="row m-0 p-0">
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                              <ul class="list-unstyled">
                                <li class="room-list-header mt-1">
                                  <div class="row m-0 p-0">
                                    <div class="col-1 col-sm-1 col-md-1 col-lg-1 p-1">
                                      Picked
                                    </div>
                                    <div class="col-3 col-sm-3 col-md-3 col-lg-3 p-1 options-paragraph">
                                      Type
                                    </div>
                                    <div class="col-2 col-sm-2 col-md-2 col-lg-2 p-1 options-paragraph">
                                      Guests
                                    </div>
                                    <div class="col-3 col-sm-3 col-md-3 col-lg-3 p-1 options-paragraph">
                                      Price
                                    </div>
                                    <div class="col-1 col-sm-1 col-md-1 col-lg-1 p-1 options-paragraph">
                                      Avail
                                    </div>
                                    <div class="col-1 col-sm-1 col-md-1 col-lg-1 p-1 options-paragraph">
                                        <span data-tooltip="tooltip">
                                          <font-awesome-icon icon="paw" class="icon-fa" />
                                          <span class="tooltiptext">Pet Friendly</span>
                                        </span>
                                    </div>
                                    <div class="col-1 col-sm-1 col-md-1 col-lg-1 p-1 options-paragraph">
                                        <span data-tooltip="tooltip">
                                          <font-awesome-icon icon="smoking" class="icon-fa" />
                                          <span class="tooltiptext">Smoking Allowed</span>
                                        </span>
                                    </div>
                                  </div>
                                </li>
                                <li @click="showRoomDetails(room)" class="room-list mt-1" v-for="room in adDetails.property_rooms" v-bind:key="room.id">
                                  <div class="row m-0 p-0">
                                    <div class="col-1 col-sm-1 col-md-1 col-lg-1 p-1">
                                      <!-- Picked room radio button -->
                                      <label class="radio-container-room">
                                        <input type="radio" name="many_rooms" :id="'radio_room_' + room.id" :value="room.id" />
                                        <span class="checkmark"></span>
                                      </label>
                                    </div>
                                    <div class="col-3 col-sm-3 col-md-3 col-lg-3 p-1 options-paragraph">
                                      <div class="position-relative">
                                        <div class="text-truncate-item no-text-wrap overflow-hidden text-truncate ">
                                          {{ room.property_room_type.type }}
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-2 col-sm-2 col-md-2 col-lg-2 p-1 options-paragraph">
                                      {{ room.guests }}
                                    </div>
                                    <div class="col-3 col-sm-3 col-md-3 col-lg-3 p-1 options-paragraph">
                                      <div class="position-relative">
                                        <div class="text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                          <font-awesome-icon icon="pound-sign" class="icon-fa me-1" />{{ convertNumberToCommas(room.price) }} per {{ room.property_rent_type.type }}
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-1 col-sm-1 col-md-1 col-lg-1 p-1 options-paragraph">
                                      {{ room.quantity }}
                                    </div>
                                    <div class="col-1 col-sm-1 col-md-1 col-lg-1 p-1">
                                      <font-awesome-icon v-if="room.pet_friendly_room" icon="check" class="icon-fa green-text" />
                                      <font-awesome-icon v-else icon="times" class="icon-fa red-text" />
                                    </div>
                                    <div class="col-1 col-sm-1 col-md-1 col-lg-1 p-1">
                                      <font-awesome-icon v-if="room.smoking_room" icon="check" class="icon-fa green-text" />
                                      <font-awesome-icon v-else icon="times" class="icon-fa red-text" />
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>

                        </div>

                        <!-- Room Details -->
                        <div class="col-12 col-sm-12 col-md-4 col-lg-4">
                          <!-- New Component to display room details data -->
                          <div class="room-details mt-1">
                            <RoomDetails :formData="roomDetailsData"></RoomDetails>
                          </div>
                        </div>
                      </div>

                    </div>

                    <!-- Service / Appointment -->
                    <div v-if="booking === 'appoint'" class="col-12 col-sm-12 col-md-9 col-lg-9 m-0 p-0 text-start">
                      <div class="row m-0 p-0">

                        <div class="col-12 col-sm-12 col-md-8 col-lg-8 m-0 p-0">
                          <ul class="list-unstyled mb-1">
                            <li class="d-inline-block me-1 summary-list">
                              <strong>Price: £</strong> <span class="price-color">{{ calculateTotalPrice }}</span> {{ adDetails.service_payment_type ? 'per ' + adDetails.service_payment_type.type : '' }}
                            </li>
                            <li class="d-inline-block me-1 mt-1 summary-list">
                              Service:  {{ adDetails && adDetails.occupation ? adDetails.occupation.type : '' }}
                            </li>
                            <li class="d-inline-block me-1 mt-1 summary-list">
                              Provider:  {{ adDetails && adDetails.contact ? adDetails.contact.name : '' }}
                            </li>
                          </ul>
                        </div>

                        <div class="col-12 col-sm-12 col-md-8 col-lg-8 m-0 p-0 mb-1 pb-1">
                          <label class="pb-2">
                            <font-awesome-icon :icon="['fas', 'map-marker-alt']" />
                            Address for required service
                            <span class="required"> *</span>
                          </label>
                          <div ref="geocoderBookServiceRef" class="geocoder-book-service" id="geocoderBookService"></div>
                          <ValidationProvider vid="vp-1" rules="required|min:3|max:200" v-slot="{ errors }">
                            <input type="text" name="serviceUserAddress" maxlength="200" v-model="form.service_user_address" id="serviceUserAddress" class="visually-hidden">
                            <span class="red-text"><small>{{ errors[0] }}</small></span>
                          </ValidationProvider>
                          <p v-if="validationErrors && validationErrors.service_user_address" class="required-info text-center">
                            {{ validationErrors.service_user_address[0] }}
                          </p>
                          <p v-if="validationErrors && validationErrors.postcode" class="required-info text-center">
                            {{ validationErrors.postcode[0] }}
                          </p>
                        </div>

                        <div class="col-12 col-sm-12 col-md-8 col-lg-8 m-0 p-0 mb-1 pb-1">
                          <div class="form-group">
                            <label class="pb-2">
                              <font-awesome-icon icon="pencil-alt" class="icon-fa" />
                              Brief Job Description
                              <span class="required"> *</span>
                            </label>
                            <ValidationProvider vid="vp-2" rules="required|min:10|max:500" v-slot="{ errors }">
                            <div class="input-group input-group-sm mb-3">
                              <textarea v-model="form.service_user_job_description"
                                        name="jobDescription"
                                        id="jobDescription"
                                        rows="6"
                                        cols="50"
                                        maxlength="500"
                                        class="form-control"
                                        aria-describedby="jobDescText"
                                        :disabled="processing"
                              />
                            </div>
                            {{ counter }} / 500
                            <br/>
                            <span class="red-text"><small>{{ errors[0] }}</small></span>
                            </ValidationProvider>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </form>
                </ValidationObserver>
              </div>
            </div>

          </div>
          <div class="modal-footer scroll-width-thin p-2">
            <div class="row m-0 p-0">
              <!-- Validation and Success Messages -->
              <div id="validationBox">
                <div v-if="validationMessage" class="success-info text-center">
                  {{ validationMessage }}
                </div>
                <div v-if="validationErrors" class="global-error mt-2">
                  <ValidationErrors :errors="validationErrors"/>
                </div>
              </div>
              <!-- Details of booking/reservation -->
              <div v-if="booking === 'view' || booking === 'reserve'" class="col-12 col-sm-12 col-md-10 col-lg-10 m-0 p-0 text-start">
                <div v-if="booking === 'view'">
                  <ul class="list-unstyled">
                    <li class="d-inline-block me-1 summary-list">
                      <strong>Price: £</strong> <span class="price-color">{{ calculateTotalPrice }}</span> {{ adDetails.is_let && adDetails.property_rent_type ? 'per ' + adDetails.property_rent_type.type : (form.picked_room && form.picked_room.property_rent_type ? 'per ' + form.picked_room.property_rent_type.type : '') }}
                    </li>
                  </ul>
                </div>
                <div v-if="booking === 'reserve'">
                  <ul class="list-unstyled">
                    <li class="d-inline-block me-1 summary-list">
                      <strong>Check-in:</strong> {{ (bookingsData.check_in_from && bookingsData.check_in_from !== null && bookingsData.check_in_from !== 'Flexible') ? moment.utc(bookingsData.check_in_from).format('HH:mm') + ' - ' + moment.utc(bookingsData.check_in_to).format('HH:mm') : 'Flexible'}}
                    </li>
                    <li class="d-inline-block me-1 summary-list">
                      <strong>Checkout:</strong> {{ (bookingsData.check_out_from && bookingsData.check_out_from !== null && bookingsData.check_out_from !== 'Flexible') ? moment.utc(bookingsData.check_out_from).format('HH:mm') + ' - ' + moment.utc(bookingsData.check_out_to).format('HH:mm') : 'Flexible'}}
                    </li>
                  <!--                        <p><strong>Base price:</strong> £{{ ad_details.price }} {{ ad_details.property_rent_type ? 'per ' + ad_details.property_rent_type.type : '' }}</p>-->
                  </ul>
                  <ul v-if="adDetails.discounts" class="list-unstyled">
                    <!-- Loop discounts -->
                    <li v-for="discount in adDetails.discounts" class="d-inline-block me-1 summary-list">
                      <strong>Discount {{ discount.discount_type && discount.discount_type.type ? discount.discount_type.type + ": " : '' }}</strong>
                      {{ (discount.discount_type && discount.discount_type.type && discount.discount_type.type === 'Number of Guests') ? 'Min Guests: ' + discount.discount_term : 'Term: ' + (discount.discount_term && discount.discount_term == '1' ? discount.discount_term + ' day' : discount.discount_term + ' days') }}
                      {{ discount.discount_fee_type && discount.discount_fee_type == 1 ? '| Deduct: ' + discount.discount_fee + '%' : '| Deduct: £ ' + discount.discount_fee }}
                    </li>
                  </ul>
                  <ul class="list-unstyled">
                    <li v-if="adDetails.property_details.cleaning_fee" class="d-inline-block me-1 summary-list">
                      <strong>Cleaning Fee:</strong> {{ adDetails.property_details.cleaning_fee ? '£ ' + adDetails.property_details.cleaning_fee + ' x ' + calculatePickedRooms : ''}}
                    </li>
                    <li v-if="adDetails.property_details.service_fee" class="d-inline-block me-1 summary-list">
                      <strong>Service Fee</strong> {{ adDetails.property_details.service_fee ? '£ ' + adDetails.property_details.service_fee + ' x ' + calculatePickedRooms : ''}}
                    </li>
                    <li v-if="adDetails.pet_friendly_fee" class="d-inline-block me-1 summary-list">
                      <strong>Pet Fee</strong> {{ adDetails.pet_friendly_fee ? '£ ' + adDetails.pet_friendly_fee + ' x ' + (form.pets ? form.pets : '0') : (adDetails.pet_friendly_paid ? '' : 'Free')}}
                    </li>
                    <li class="d-inline-block me-1 summary-list">
                      <strong>Total Price: £</strong> <span class="price-color">{{ calculateTotalPrice }}</span>
                    </li>
                  </ul>
                </div>
              </div>

              <!-- Details of Service/Appointment Appoint -->
              <div v-if="booking === 'appoint'" class="col-12 col-sm-12 col-md-10 col-lg-10 m-0 p-0 text-start">
                <div v-if="booking === 'appoint'">
                  <ul class="list-unstyled">
                    <li class="d-inline-block me-1 summary-list">
                      <strong>Price: £</strong> <span class="price-color">{{ calculateTotalPrice }}</span> {{ adDetails.service_payment_type ? 'per ' + adDetails.service_payment_type.type : '' }}
                    </li>
                  </ul>
                  <!-- For Working Hours corresponding columns in booking_calendars table is check_in_from and check_in_to -->
                  <ul class="list-unstyled">
                    <li class="d-inline-block me-1 summary-list">
                      <strong>Working Hours From:</strong> {{ (bookingsData.check_in_from && bookingsData.check_in_from !== null && bookingsData.check_in_from !== 'Flexible') ? moment.utc(bookingsData.check_in_from).format('HH:mm') : 'Flexible'}}
                    </li>
                    <li class="d-inline-block me-1 summary-list">
                      <strong>Working Hours To:</strong> {{ (bookingsData.check_in_to && bookingsData.check_in_to !== null && bookingsData.check_in_to !== 'Flexible') ? moment.utc(bookingsData.check_in_to).format('HH:mm') : 'Flexible'}}
                    </li>
                  </ul>
                </div>
              </div>

              <!-- Reserve/Book Button -->
              <div class="col-12 col-sm-12 col-md-2 col-lg-2 pt-2 align-self-center">
                <button type="submit"
                        :disabled="processing"
                        @click.prevent="book"
                        class="btn btn-outline-success"
                        id="sendBtnBooking"
                >{{ processing === false && loading === false ? (booking !== 'view' ? (booking === 'reserve' ? 'Reserve' : 'Book') : 'Book') : 'Processing...'}}
                </button>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import RoomDetails from "../RoomDetails";
import ValidationErrors from "../ValidationErrors";
import Loader from "../Loader";
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';

export default {
  name: 'Booking',
  props: ['adDetails', 'toUserId', 'adId', 'adType', 'booking', 'refresh'],
  components: {
    'RoomDetails': RoomDetails,
    'ValidationErrors': ValidationErrors,
    'Loader': Loader
  },
  data(){
    return {
      user: (this.$store.state.auth.authenticated === true && this.$store.state.auth.user) ? this.$store.state.auth.user : null,
      to_user_id: this.toUserId,
      ad_id: this.adId,
      ad_type: this.adType,
      booking_type: this.booking,
      ad_details: this.adDetails,
      refresh_rooms: false,
      newMessage: '',
      processing: false,
      loading: true,
      loadingRooms: true,
      validationMessage: '',
      validationErrors: '',
      validationSuccess: null,
      roomDetailsData: null,
      price: 0,
      counter: 0,
      form: {
        /* Date Picker */
        date_range: {
          start: null,
          end: null
        },
        single_start_date: null,
        date_start: null,
        date_end: null,
        /* End Date Picker */
        guests: '',
        pets: '',
        picked_room: '',
        service_user_address: '',
        service_user_postcode: '',
        service_user_job_description: ''
      },
      picked_rooms: {}, // object of picked rooms = [ 'room_' + room.id: quantity ] (It must be outside form, otherwise wont work as nested fields dont work with this approach)
      defaultForm: {
        /* Date Picker */
        date_range: {
          start: null,
          end: null
        },
        single_start_date: null,
        date_start: null,
        date_end: null,
        /* End Date Picker */
        guests: '',
        pets: '',
        picked_room: '',
        service_user_address: '',
        service_user_postcode: '',
        service_user_job_description: ''
      },
      oldStartHour: null,
      oldEndHour: null,
      disabledDates: [],
      validHours: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
      validWorkingHours: [],
      validCheckInHours: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
      validCheckOutHours: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
      defaultValidHours: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
      bookingCalendars: [],
      bookingsData: [],
      disabledRooms: [],
      availableRooms: []
    }
  },
  computed:{
    attr() {
      return [
        // {
        //   highlight:'red',
        //   dates: this.disabledDates,
        // },

        {
          key: 'today',
          highlight: { color: 'green', fillMode: 'outline'},
          dates: new Date(),
        }
      ];

    },
    calculateMinDate: function() {
      if (this.adDetails && this.adDetails.date_start && this.moment(this.adDetails.date_start) > this.moment()) {
        return this.moment(this.adDetails.date_start).format('YYYY-MM-DD HH:mm:ss');
      } else {
        return this.moment().format('YYYY-MM-DD HH:mm:ss');
      }
    },
    calculateMaxDate: function() {
      let that = this;
      let start = function() {
        if (that.adDetails && that.adDetails.date_start && that.moment(that.adDetails.date_start) > that.moment()) {
          return that.moment(that.adDetails.date_start);
        } else {
          return that.moment();
        }
      }
      let endDate = that.adDetails && that.adDetails.date_end ? that.moment(that.adDetails.date_end) : null;
      let startDate = start();
      if (startDate && endDate) {
        return endDate.format('YYYY-MM-DD HH:mm:ss');
      } else if (startDate) {
        return startDate.add(30, 'days').format('YYYY-MM-DD HH:mm:ss');
      }
    },
    calculatePickedRooms: function() {
      let roomCount = 0;
      if (this.picked_rooms && Object.keys(this.picked_rooms).length > 0) {
        for (let picked_room in this.picked_rooms) {
          roomCount = Number(roomCount) + Number(this.picked_rooms[picked_room]);
        }
        return roomCount;
      } else {
        if (this.isStudioFlat()) {
          return 1;
        } else {
          return roomCount;
        }
      }
    },
    calculateTotalPrice: function() {
      if (this.booking == 'reserve') {
        let cleaningFee = this.adDetails.property_details.cleaning_fee ? this.adDetails.property_details.cleaning_fee : 0;
        let serviceFee = this.adDetails.property_details.service_fee ? this.adDetails.property_details.service_fee : 0;
        let petFee = this.adDetails.pet_friendly_fee ? this.adDetails.pet_friendly_fee : 0;
        // We need to grab number of pets as petFee is calculated per pet
        let numberOfPets = this.form.pets ? this.form.pets : 0;
        let singularStartDate = this.form.single_start_date ? this.moment(this.form.single_start_date) : null;
        let startDate = this.form.date_range.start ? this.moment(this.form.date_range.start) : null;
        let endDate = this.form.date_range.end ? this.moment(this.form.date_range.end) : null;

        if (startDate && endDate) {
          // Studio Flat
          if (this.isStudioFlat()) {
            let price = this.calculateTotalRoomPrice(this.adDetails.price, 1, this.adDetails.property_rent_type_id);
            let sumPrice = Number(price) + Number(cleaningFee) + Number(serviceFee) + (Number(petFee) * Number(numberOfPets));
            // Check for discounts and apply if exists
            let totalPrice = this.calculateDiscounts(sumPrice, this.form.guests);
            this.form.price = totalPrice;
            return this.convertNumberToCommas2Fixed(totalPrice);

          // All other reservations than Studio Flat
          } else {
            let totalPriceRooms = 0;
            // Count rooms as serviceFee and cleaningFee is per stay per room
            let roomCount = 0;

            if (this.picked_rooms && Object.keys(this.picked_rooms).length > 0) {
              for (let picked_room in this.picked_rooms) {
                if (this.picked_rooms[picked_room]) {
                  let roomId = picked_room.substring(picked_room.indexOf('_') + 1);
                  let room = this.adDetails.property_rooms.filter(function (property_room) {
                    return property_room.id == roomId;
                  });
                  let roomPrice = this.calculateTotalRoomPrice(room[0].price, this.picked_rooms[picked_room], room[0].property_rent_type_id);
                  totalPriceRooms = Number(totalPriceRooms) + Number(roomPrice);
                  roomCount = Number(roomCount) + Number(this.picked_rooms[picked_room]);
                }
              }
            }

            let sumPrice = Number(totalPriceRooms) + (Number(cleaningFee) * Number(roomCount)) + (Number(serviceFee) * Number(roomCount)) + (Number(petFee) * Number(numberOfPets));
            // Check for discounts and apply if exists
            let totalPrice = this.calculateDiscounts(sumPrice, this.form.guests);
            this.form.price = totalPrice;
            return this.convertNumberToCommas2Fixed(totalPrice);
          }
        } else {
          return 'Pick dates please';
        }
      }
      if (this.booking == 'view') { // || this.booking == 'appoint'
        if (this.isLongTermRoom()) {
          let price = this.form.picked_room.price;
          this.form.price = this.form.picked_room.price;
          return this.convertNumberToCommas(price);
        } else {
          let price = this.adDetails.price;
          this.form.price = this.adDetails.price;
          return this.convertNumberToCommas(price);
        }
      }
      if (this.booking == 'appoint') {
        let price = this.adDetails.price;
        this.form.price = this.adDetails.price;
        return this.convertNumberToCommas(price);
      }
    },
  },
  async mounted() {
    // await this.getBookingCalendars(this.adId, this.adType);
    // await this.getBookingsByAdId(this.adId, this.adType);
  },
  // async updated() {
  //   await this.initGeocodeBox();
  // },
  methods:{
    async setGeocoder() {
      // Mapbox Geocoding Address Autocomplete
      let geocoder = '';

      geocoder = new MapboxGeocoder({
          accessToken: process.env.VUE_APP_MAPBOX_ACCESS_TOKEN,
          types: 'region,place,district,postcode,locality,address',
          limit: 7,
          countries: 'gb, ie, es', // Restrict to UK,Ireland and Spain only
        });
      return geocoder;
    },
    async initGeocodeBox() {
      let that = this;
      await this.$nextTick();
      let geocoder = await this.setGeocoder();
      let element = that.$refs.geocoderBookServiceRef;
      if (element) {
        element.innerHTML = '';
        if (element && !element.hasChildNodes()) {
          geocoder.addTo(element);
        }
      }

      // Add geocoder result to container.
      geocoder.on('result', (e) => {
        // For districts such as South Yorkshire, Hampshire etc.
        if (e.result.place_type.includes('district')) {
          for (let i = 0; i < e.result.context.length; i++) {
            if (e.result.context[i].id.includes('postcode')) {
              that.form.service_user_postcode = e.result.context[i].text;
            }
          }
          // For places, which are cities, such as Manchester, Leeds etc.
        } else if (e.result.place_type.includes('place')) {
          for (let i = 0; i < e.result.context.length; i++) {
            if (e.result.context[i].id.includes('postcode')) {
              that.form.service_user_postcode = e.result.context[i].text;
            }
          }
        } else if (e.result.place_type.includes('postcode')) {
          // Postcode
          if (e.result.text) {
            that.form.service_user_postcode = e.result.text;
          }
          // For addresses, detailed ones such as 7 California Street etc. and all the rest
        } else {
          for (let i = 0; i < e.result.context.length; i++) {
            if (e.result.context[i].id.includes('postcode')) {
              that.form.service_user_postcode = e.result.context[i].text;
            }
          }
        }

        // Full Address
        if (e.result.place_name) {
          that.form.service_user_address = e.result.place_name;
        } else {
          that.form.service_user_address = '';
        }
      });

      // Clear results container when search is cleared.
      geocoder.on('clear', () => {
        // results.innerText = '';
        that.form.service_user_address = '';
        that.form.service_user_postcode = '';
      });

    },
    async reload() {
      await this.reset();

      await this.getBookingCalendars(this.adId, this.adType);
      await this.getBookingsByAdId(this.adId, this.adType);

      this.loading = false;
      if (this.adType === 'Service') {
        await this.initGeocodeBox();
      }
    },
    reset() {
      Object.assign(this.$data, this.$options.data.apply(this));
    },
    handleFocus(event) {
      if(!event.target.classList.contains('select-clicked')) {
        event.target.classList.toggle('select-clicked');
      }
    },
    handleFocusOut(value, event) {
      if(value === null || value === '') {
        if(event.target.classList.contains('select-clicked')) {
          event.target.classList.toggle('select-clicked');
        }
      }
    },
    calculateDiscounts(totalPrice, guests) {
      let price = totalPrice;
      let now = this.moment().clone().startOf('day');
      let startDay = this.moment(this.form.date_range.start).clone().startOf('day');
      let endDay = this.moment(this.form.date_range.end).clone().startOf('day');
      let days = endDay.diff(startDay, 'days');
      let lastMinuteDays = startDay.diff(now, 'days');

      if (this.adDetails.discounts && this.adDetails.discounts.length > 0) {
        // Check discounts and apply where necessary
        for (let i=0; i<this.adDetails.discounts.length; i++) {
          // Number of Guests
          if (this.adDetails.discounts[i].discount_type && this.adDetails.discounts[i].discount_type.type && this.adDetails.discounts[i].discount_type.type === 'Number of Guests') {
            if (guests && Number(guests) >= Number(this.adDetails.discounts[i].discount_term)) {
              // Deduct based on type of deduct (discount_fee_type 1 = %, 2 = £)
              if (this.adDetails.discounts[i].discount_fee_type && this.adDetails.discounts[i].discount_fee_type == 1) {
                price = Number(price) - (Number(price) * (Number(this.adDetails.discounts[i].discount_fee) / 100));
              }
              if (this.adDetails.discounts[i].discount_fee_type && this.adDetails.discounts[i].discount_fee_type == 2) {
                price = Number(price) - Number(this.adDetails.discounts[i].discount_fee);
              }
            }
          }
          // Term
          if (this.adDetails.discounts[i].discount_type && this.adDetails.discounts[i].discount_type.type && this.adDetails.discounts[i].discount_type.type === 'Term') {
            if (days && Number(days) >= Number(this.adDetails.discounts[i].discount_term)) {
              // Deduct based on type of deduct (discount_fee_type 1 = %, 2 = £)
              if (this.adDetails.discounts[i].discount_fee_type && this.adDetails.discounts[i].discount_fee_type == 1) {
                price = Number(price) - (Number(price) * (Number(this.adDetails.discounts[i].discount_fee) / 100));
              }
              if (this.adDetails.discounts[i].discount_fee_type && this.adDetails.discounts[i].discount_fee_type == 2) {
                price = Number(price) - Number(this.adDetails.discounts[i].discount_fee);
              }
            }
          }
          // Last Minute
          if (this.adDetails.discounts[i].discount_type && this.adDetails.discounts[i].discount_type.type && this.adDetails.discounts[i].discount_type.type === 'Last Minute') {
            if (Number(lastMinuteDays) <= Number(this.adDetails.discounts[i].discount_term)) {
              // Deduct based on type of deduct (discount_fee_type 1 = %, 2 = £)
              if (this.adDetails.discounts[i].discount_fee_type && this.adDetails.discounts[i].discount_fee_type == 1) {
                price = Number(price) - (Number(price) * (Number(this.adDetails.discounts[i].discount_fee) / 100));
              }
              if (this.adDetails.discounts[i].discount_fee_type && this.adDetails.discounts[i].discount_fee_type == 2) {
                price = Number(price) - Number(this.adDetails.discounts[i].discount_fee);
              }
            }
          }
        }
        // With Discounts applied
        return price;

      } else {
        // No Discounts
        return price;
      }
    },
    calculateTotalRoomPrice(price, quantity, rent_type) {
      let start = this.moment(this.form.date_range.start);
      let end = this.moment(this.form.date_range.end);
      let startDay = this.moment(this.form.date_range.start).clone().startOf('day');
      let endDay = this.moment(this.form.date_range.end).clone().startOf('day');

      if (start && end) {
        // 2 = Hourly
        if (rent_type == 2) {
          let hours = end.diff(start, 'hours');
          return Number(price) * Number(quantity) * Number(hours);
        }
        // 3 = Nightly
        if (rent_type == 3) {
          let days = endDay.diff(startDay, 'days');
          if (days == 0) {
            days = 1;
          }
          return Number(price) * Number(quantity) * Number(days);
        }
      } else {
        return 0;
      }
    },
    convertNumberToCommas(num) {
      if (num && num !== '' && num !== null) {
        return num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return 0;
      }
    },
    // convertNumberToCommas Fixed 2 (for precise price calculations)
    convertNumberToCommas2Fixed(num) {
      if (num && num !== '' && num !== null) {
        let no = num.toFixed(2);
        return no.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return 0;
      }
    },
    isStudioFlat() {
      if (this.adDetails.property_type_details && this.adDetails.property_type_details.type_detail && this.adDetails.property_type_details.type_detail === 'Studio Flat') {
        return true;
      } else {
        return false;
      }
    },
    isLongTermRoom() {
      if (!this.adDetails.is_short_term && this.adDetails.property_category.type && this.adDetails.property_category.type === 'Room') {
        return true;
      } else {
        return false;
      }
    },
    showRoomDetails(room) {
      this.roomDetailsData = room;
      if (this.booking === 'view' && this.isLongTermRoom()) {
        // Set picked room as active
        let checkRoom = document.getElementById('radio_room_' + room.id);
        if (checkRoom) {
         checkRoom.checked = true;
         this.form.picked_room = room;
        }
      }
    },
    enumerateDaysBetweenDates(startDate, endDate) {
      let now = startDate.clone(), dates = [];

      while (now.isSameOrBefore(endDate)) {
        dates.push(now.format('DD-MM-YYYY'));
        now.add(1, 'days');
      }
      return dates;
    },
    async getRooms() {
      this.loadingRooms = true;
      this.processing = true;

      let that = this;
      let startDate = that.moment(that.form.date_range.start); // .format('DD-MM-YYYY')
      let endDate = that.moment(that.form.date_range.end); // .format('DD-MM-YYYY')

      // Loop rooms and set those which are not disabled
      let getRooms = new Promise(function(resolve, reject) {
        // I need to grab current date range (picked by user)
        // And search which rooms are available in that range of dates
        // Get all dates between start and end date
        let dates = that.enumerateDaysBetweenDates(startDate, endDate);

        // Get disabledRooms of specified dates
        let disabledRoomsArr = [];
        for (let a=0; a<dates.length; a++) {
          let item = that.disabledRooms.filter((disRoom) => {
            return disRoom[0] === dates[a]
          });

          if (item && item.length > 0) {
            for (let z=0; z<item[0][1].length; z++) {
              if (!disabledRoomsArr.includes(item[0][1][z])) {
                disabledRoomsArr.push(item[0][1][z]);
              }
            }
          }
        }
        resolve(disabledRoomsArr);
      });

      getRooms.then(
          function(disabledRoomsArr) {
            that.availableRooms = that.adDetails.property_rooms.filter(data => {
              return !disabledRoomsArr.includes(data.id)
            });

            that.loadingRooms = false;
            that.processing = false;
            },
          function(error) {
            that.loadingRooms = false;
            that.processing = false;
            console.log(error);
          }
      );
    },
    async getBookingsByAdId(id = null, type = null) {
      this.processing = true;
      if (id === null || type === null) {
        this.processing = false;
        return;
      }

      // id should be either property_id or service_id
      let formData = new FormData();

      formData.append('host_id', this.toUserId);
      formData.append('booking_type', this.booking);
      if (type === 'Property') {
        formData.append('property_id', id);
        formData.append('service_id', null);
      }
      if (type === 'Service') {
        formData.append('property_id', null);
        formData.append('service_id', id);
      }

      await axios.post('api/bookings-by-ad-id', formData).then(async ({data}) => {
        this.showError = false;
        this.validationSuccess = null;
        this.validationMessage = '';
        this.validationErrors = '';
        this.bookingsData = data;
      }).catch(({response})=>{
        if (response && response.data && response.data.errors) {
          this.validationErrors = response.data.errors;
          this.processing = false;
          const timeout = setTimeout(() => {
            this.validationErrors = '';
            clearTimeout(timeout);
          }, 3000)
        }
      }).finally(() => {
        this.processing = false
      })
    },
    async getBookingCalendars(id = null, type = null) {
      this.processing = true;
      if (id === null || type === null) {
        this.processing = false;
        return;
      }

      // id should be either property_id or service_id
      let formData = new FormData();

      if (type === 'Property') {
        formData.append('property_id', id);
        formData.append('service_id', null);
      }
      if (type === 'Service') {
        formData.append('property_id', null);
        formData.append('service_id', id);
      }

      await axios.post('api/bookings-calendar', formData).then(async ({data}) => {
        this.showError = false;
        this.validationSuccess = null;
        this.validationMessage = '';
        this.validationErrors = '';
        this.bookingCalendars = data.data;
      }).catch(({response})=>{
        if (response && response.data && response.data.errors) {
          this.validationErrors = response.data.errors;
          this.processing = false;
          const timeout = setTimeout(() => {
            this.validationErrors = '';
            clearTimeout(timeout);
          }, 3000)
        }
      }).finally(() => {
        this.processing = false
      })
    },
    async book() {
      this.processing = true;

      if (this.adType === 'Service') {
        const validateBookingFormObserver = await this.$refs.bookingFormObserver.validate();
        if (!validateBookingFormObserver) {
          this.processing = false;
          return;
        }
      }
      // Gather form data for new booking
      let formData = new FormData();

      formData.append('booking_type', this.booking);
      formData.append('user_id', this.user.id);
      formData.append('host_id', this.toUserId);
      // For reservation this will be total price for viewing/appoint this will be price of property/service
      formData.append('price', this.form.price);
      formData.append('pets', this.form.pets);
      formData.append('guests', this.form.guests);
      formData.append('date_start', this.form.date_start);
      formData.append('date_end', this.form.date_end);
      formData.append('date_now', this.moment().clone().startOf('day'));

      if (this.adType === 'Property') {
        formData.append('property_id', this.adId);

        // Check if any picked_rooms
        if (Object.keys(this.picked_rooms).length > 0) {
          // Clear picked_rooms where value > 0
          let picked_rooms = Object.fromEntries(Object.entries(this.picked_rooms).filter(([_, v]) => v > 0));
          let rooms = JSON.stringify(picked_rooms);
          formData.append("picked_rooms", rooms);
        }

        // View - Room
        formData.append('picked_room', JSON.stringify(this.form.picked_room));
      }
      if (this.adType === 'Service') {
        formData.append('service_id', this.adId);
        formData.append('service_user_address', this.form.service_user_address);
        formData.append('service_user_job_description', this.form.service_user_job_description);
      }

      // Send booking data to back-end, validate, on success send chat message to host
      // back-end will take care of sending confirmation emails to host and guest
      await axios.post('api/book', formData).then(async ({data}) => {
        // Trigger Send chat message to host
        await this.sendNewMessage();
        this.showError = false;
        this.validationErrors = null;
        await this.reload();
        this.validationMessage = "Your request was sent successfully. Please check your email for further information!";
        this.showError = true;
        const timeout = setTimeout(() => {
          this.showError = false;
          this.validationSuccess = null;
          this.validationMessage = '';
          clearTimeout(timeout);
        }, 5000);
        this.validationErrors = null;
      }).catch(({response})=>{
        if (response && response.data && response.data.errors) {
          this.validationErrors = response.data.errors;
          this.processing = false;
        }
      }).finally(() => {
        this.processing = false;
      })
      // either redirect to Dashboard->bookings, or just close modal but prior to modal being closed we must show sufficient message
      // to the user, such as 'Email confirmation sent, host should reply within 24h'
      // let closeBookingBtn = document.getElementById('closeBookingModal');
      // if (closeBookingBtn) {
      //   closeBookingBtn.click();
      // }
      this.processing = false;
    },
    async sendNewMessage() {
      if (this.user === null) {
        return;
      }
      this.processing = true;

      // message type
      let messageType = null;
      // For Book Viewing (Long-term Lets and Sale Ads)
      if (this.booking_type === 'view') {
        this.newMessage = '' + this.user.name + ' requested viewing on ' + this.moment(this.form.single_start_date).format('DD-MM-YYYY HH:mm');
        messageType = 'request_viewing';
      }
      // For Book Reservation (Short-term Lets Ads)
      if (this.booking_type === 'reserve') {
        this.newMessage = '' + this.user.name + ' requested reservation from ' + this.moment(this.form.date_range.start).format('DD-MM-YYYY HH:mm') + ' to ' + this.moment(this.form.date_range.end).format('DD-MM-YYYY HH:mm');
        messageType = 'request_reservation';
      }
      // For Book Appointment (Services Ads)
      if (this.booking_type === 'appoint') {
        this.newMessage = '' + this.user.name + ' requested service on ' + this.moment(this.form.single_start_date).format('DD-MM-YYYY HH:mm');
        messageType = 'request_appointment';
      }
      // axios call with this.newMessage value (make sure you do validation in the BE)
      let formData = new FormData();

      formData.append('chat_room_id', null);
      formData.append('from_user_id', this.user.id);
      formData.append('to_user_id', this.to_user_id);
      formData.append('message', this.newMessage);
      formData.append('message_type', messageType);

      if (this.adType === 'Property') {
        formData.append('property_id', this.adId);
      }
      if (this.adType === 'Service') {
        formData.append('service_id', this.adId);
      }

      let chatRoomId = null;
      await axios.post('api/user/' + this.user.id + '/messages/new/' + chatRoomId, formData).then(({data}) => {
        // Clear message
        this.newMessage = '';
        // Trigger to refresh messages?
        // this.validationMessage = "Your request was sent successfully!";
        // this.showError = true;
        // const timeout = setTimeout(() => {
        //   this.showError = false;
        //   this.validationSuccess = null;
        //   this.validationMessage = '';
        //   clearTimeout(timeout);
        // }, 2000);
        // this.validationErrors = null;
      }).catch(({response})=>{
        if (response && response.data && response.data.errors) {
          this.validationErrors = response.data.errors;
          this.processing = false;
          // const timeout = setTimeout(() => {
          //   this.validationErrors = '';
          //   clearTimeout(timeout);
          // }, 3000)
          // this.validationMessage = response.data.message;
        }
        // alert(data.message)
      }).finally(() => {
        this.processing = false;
      })
    },
    async refreshTimeInputs(indexMin = 'none', indexHr = 'none', timeType = 'none', valueMin = 'none', valueHr = 'none') {
      // timeType expected to be one of four states: 'ranged-start', 'ranged-end', 'ranged-both', 'none'
      // Singular Calendar
      if (this.booking_type === 'view' || this.booking_type === 'appoint') {
        const refreshHours = setInterval(function () {
          let calendar = document.getElementById('singular');
          if (calendar) {
            let vTime = calendar.getElementsByClassName('vc-time');
            if (vTime && vTime.length > 0) {
              let selectHours = vTime[0].querySelectorAll('.vc-select')[0].querySelector('select');
              this.processing = false;
              if (indexHr != 'none') {
                selectHours.selectedIndex = indexHr;
              }
              selectHours.dispatchEvent(new Event('change'));
            }
          }
          clearInterval(refreshHours);
        }, 500);
        const refreshMinutes = setInterval(function () {
          let calendar = document.getElementById('singular');
          if (calendar) {
            let vTime = calendar.getElementsByClassName('vc-time');
            if (vTime && vTime.length > 0) {
              let select = vTime[0].querySelectorAll('.vc-select')[1].querySelector('select');
              this.processing = false;
              if (indexMin != 'none') {
                select.selectedIndex = indexMin;
              }
              if (select.selectedIndex == '-1') {
                select.selectedIndex = 0;
              }
              select.dispatchEvent(new Event('change'));
            }
          }
          clearInterval(refreshMinutes);
        }, 500);
      }

      // Ranged Calendar
      if (this.booking_type === 'reserve') {
        const refreshHoursRangedStart = setInterval(function () {
          // let calendar = document.getElementById('ranged');
          let calendar = document.getElementById('ranged-check-in');
          if (calendar) {
            let vTime = calendar.getElementsByClassName('vc-time');
            if (vTime && vTime.length > 0) {
              let selectHours = vTime[0].querySelectorAll('.vc-select')[0].querySelector('select');
              this.processing = false;
              if ((timeType == 'ranged-start' || timeType == 'ranged-both') && indexHr != 'none') {
                selectHours.selectedIndex = indexHr;
              } else if ((timeType == 'ranged-start' || timeType == 'ranged-both') && valueHr != 'none') {
                selectHours.value = valueHr;
              }
              selectHours.dispatchEvent(new Event('change'));
            }
          }
          clearInterval(refreshHoursRangedStart);
        }, 600);
        const refreshMinutesRangedStart = setInterval(function () {
          // let calendar = document.getElementById('ranged');
          let calendar = document.getElementById('ranged-check-in');
          if (calendar) {
            let vTime = calendar.getElementsByClassName('vc-time');
            if (vTime && vTime.length > 0) {
              let select = vTime[0].querySelectorAll('.vc-select')[1].querySelector('select');
              this.processing = false;
              if ((timeType == 'ranged-start' || timeType == 'ranged-both') && indexMin != 'none') {
                select.selectedIndex = indexMin;
              } else if ((timeType == 'ranged-start' || timeType == 'ranged-both') && valueMin != 'none') {
                select.value = valueMin;
              }
              if (select.selectedIndex == '-1') {
                select.selectedIndex = 0;
              }
              select.dispatchEvent(new Event('change'));
            }
          }
          clearInterval(refreshMinutesRangedStart);
        }, 500);
        const refreshHoursRangedEnd = setInterval(function () {
          // let calendar = document.getElementById('ranged');
          let calendar = document.getElementById('ranged-check-out');
          if (calendar) {
            let vTime = calendar.getElementsByClassName('vc-time');
            if (vTime && vTime.length > 0) {
              // let selectHours = vTime[1].querySelectorAll('.vc-select')[0].querySelector('select');
              let selectHours = vTime[0].querySelectorAll('.vc-select')[0].querySelector('select');
              this.processing = false;
              if ((timeType == 'ranged-end' || timeType == 'ranged-both') && indexHr != 'none') {
                selectHours.selectedIndex = indexHr;
              } else if ((timeType == 'ranged-end' || timeType == 'ranged-both') && valueHr != 'none') {
                selectHours.value = valueHr;
              }
              selectHours.dispatchEvent(new Event('change'));
            }
          }
          clearInterval(refreshHoursRangedEnd);
        }, 600);
        const refreshMinutesRangedEnd = setInterval(function () {
          // let calendar = document.getElementById('ranged');
          let calendar = document.getElementById('ranged-check-out');
          if (calendar) {
            let vTime = calendar.getElementsByClassName('vc-time');
            if (vTime && vTime.length > 0) {
              // let select = vTime[1].querySelectorAll('.vc-select')[1].querySelector('select');
              let select = vTime[0].querySelectorAll('.vc-select')[1].querySelector('select');
              this.processing = false;
              if ((timeType == 'ranged-end' || timeType == 'ranged-both') && indexMin != 'none') {
                select.selectedIndex = indexMin;
              } else if ((timeType == 'ranged-end' || timeType == 'ranged-both') && valueMin != 'none') {
                select.value = valueMin;
              }
              if (select.selectedIndex == '-1') {
                select.selectedIndex = 0;
              }
              select.dispatchEvent(new Event('change'));
            }
          }
          clearInterval(refreshMinutesRangedEnd);
        }, 500);
      }
    }
  },
  watch: {
    refresh: {
      handler: async function (val) {
        this.loading = true;

        await this.reload();

        this.loading = false;
      }, deep: true, immediate: true
    },
    'adId': {
      handler: async function (val) {
        this.loading = true;

        await this.reload();

        this.loading = false;
      }, deep: true, immediate: true
    },
    'form.service_user_job_description': function (val) {
      this.counter = val.length;
    },
    'bookingsData': {
      handler: function (val) {
        let dates = [];
        let disabledRooms = [];
        if (val) {
          if (this.booking_type === 'view' || this.booking_type === 'appoint') {
            // Assign booked dates to disabled dates
            if (val.booked_dates && val.booked_dates.length > 0) {
              dates = [...this.disabledDates, ...val.booked_dates];
              this.disabledDates = dates;
            }
            // Assign check-in-from and check-in-to values to validCheckInHours
            // Here I need to check if check-in-from and check-in-to is present, not null and not 'flexible' or something
            if (val.check_in_from !== null && val.check_in_from !== 'Flexible' && val.check_in_to !== null && val.check_in_to !== 'Flexible') {
              let checkInFrom = this.moment(val.check_in_from).format('HH');
              let checkInTo = this.moment(val.check_in_to).format('HH');
              let validInHrs = this.defaultValidHours.filter(hour => checkInTo >= hour && hour >= checkInFrom);
              this.validHours = validInHrs;
              this.validWorkingHours = validInHrs;
            } else if (val.check_in_from !== null && val.check_in_from !== 'Flexible') {
              // Assign check-in-from values to validCheckInHours
              // Here I need to check if check-in-from is present, not null and not 'flexible' or something
              let checkInFrom = this.moment(val.check_in_from).format('HH');
              let validInHrs = this.defaultValidHours.filter(hour => hour >= checkInFrom);
              this.validHours = validInHrs;
              this.validWorkingHours = validInHrs;
            } else if (val.check_in_from !== null && val.check_in_from !== 'Flexible') {
              // Assign check-in-to values to validCheckInHours
              // Here I need to check if check-in-to is present, not null and not 'flexible' or something
              let checkInTo = this.moment(val.check_in_to).format('HH');
              let validInHrs = this.defaultValidHours.filter(hour => checkInTo >= hour);
              this.validHours = validInHrs;
              this.validWorkingHours = validInHrs;
            } else {
              this.validWorkingHours = {...this.defaultValidHours};
              this.validHours = {...this.defaultValidHours};
            }
          }
          if (this.booking_type === 'reserve') {
            // Assign booked dates to disabled dates
            if (val.booked_dates && val.booked_dates.length > 0) {
              dates = [...this.disabledDates, ...val.booked_dates];
              this.disabledDates = dates;
            }
            // Assign check-in values to validCheckInHours
            // Here I need to check if check_in is present, not null and not 'flexible' or something
            if (val.check_in_from !== null && val.check_in_from !== 'Flexible') {
              let checkInFrom = this.moment(val.check_in_from).format('HH');
              let checkInTo = this.moment(val.check_in_to).format('HH');
              let validInHrs = this.defaultValidHours.filter(hour => checkInTo >= hour && hour >= checkInFrom);
              this.validCheckInHours = validInHrs;
            } else {
              this.validCheckInHours = {...this.defaultValidHours};
            }
            // Assign checkout values to validCheckOutHours
            // Here I need to check if check_out is present, not null and not 'flexible' or something
            if (val.check_out_from !== null && val.check_out_from !== 'Flexible') {
              let checkOutFrom = this.moment(val.check_out_from).format('HH');
              let checkOutTo = this.moment(val.check_out_to).format('HH');
              let validOutHrs = this.defaultValidHours.filter(hour => checkOutTo >= hour && hour >= checkOutFrom);
              this.validCheckOutHours = validOutHrs;
            } else {
              this.validCheckOutHours = {...this.defaultValidHours};
            }
            // Assign disabled rooms
            if (val.disabled_rooms && Object.keys(val.disabled_rooms).length > 0) {
              let entries = Object.entries(val.disabled_rooms);
              disabledRooms = [...this.disabledRooms, ...entries];
              this.disabledRooms = disabledRooms;
            }
          }
        }
      }, deep: true
    },
    'bookingCalendars': {
      handler: function (val) {
        let dates = [];
        if (val.length > 0) {
          if (this.booking_type === 'view' || this.booking_type === 'appoint') {
            // Assign disabled dates
            if (val[0].disabled_dates !== null) {
              dates = [...this.disabledDates, ...val[0].disabled_dates];
              this.disabledDates = dates;
            }
          }
          if (this.booking_type === 'reserve') {
            // Assign disabled dates
            if (val[0].disabled_dates !== null) {
              dates = [...this.disabledDates, ...val[0].disabled_dates];
              this.disabledDates = dates;
            }
          }
        }
      }, deep: true
    },
    'form.date_range': {
      handler: async function (val, oldVal) {
        let oldStartHour = this.moment(oldVal.start).format('H');
        let hour = this.moment(val.start).format('H');
        let oldEndHour = this.moment(oldVal.end).format('H');
        let endHour = this.moment(val.end).format('H');
        // this.processing = true;
        // Assign check-out if exists and disable select
        if (this.bookingsData.check_out_from !== null && this.bookingsData.check_out_from !== 'Flexible') {
          let checkOutHour = this.moment.utc(this.bookingsData.check_out_from).format('H');
          let checkOutMinute = this.moment.utc(this.bookingsData.check_out_from).format('m');
          let checkOutToHour = this.moment.utc(this.bookingsData.check_out_to).format('H');
          let checkOutToMinute = this.moment.utc(this.bookingsData.check_out_to).format('m');
          // Continue only if checkout is at specific hour and minute
          if (checkOutHour == checkOutToHour && checkOutMinute == checkOutToMinute) {
            // let vTime = document.getElementById('ranged').getElementsByClassName('vc-time');
            let calendar = document.getElementById('ranged-check-out');
            if (calendar) {
              let vTime = calendar.getElementsByClassName('vc-time');
              if (vTime && vTime.length > 0) {
                // let selectHours = vTime[1].querySelectorAll('.vc-select')[0].querySelector('select');
                // let selectMinutes = vTime[1].querySelectorAll('.vc-select')[1].querySelector('select');
                let selectHours = vTime[0].querySelectorAll('.vc-select')[0].querySelector('select');
                let selectMinutes = vTime[0].querySelectorAll('.vc-select')[1].querySelector('select');
                if (selectMinutes && selectHours) {
                  this.processing = false;
                  selectHours.value = checkOutHour;
                  selectHours.disabled = true;
                  selectMinutes.value = checkOutMinute;
                  selectMinutes.disabled = true;
                  await this.refreshTimeInputs('none', 'none', 'ranged-end', checkOutMinute, checkOutHour);
                }
              }
            }
          }
        }
        // Assign check-in if exists and disable select
        if (this.bookingsData.check_in_from !== null && this.bookingsData.check_in_from !== 'Flexible') {
          let checkInHour = this.moment.utc(this.bookingsData.check_in_from).format('H');
          let checkInMinute = this.moment.utc(this.bookingsData.check_in_from).format('m');
          let checkInToHour = this.moment.utc(this.bookingsData.check_in_to).format('H');
          let checkInToMinute = this.moment.utc(this.bookingsData.check_in_to).format('m');
          // Continue only if checkout is at specific hour and minute
          if (checkInHour == checkInToHour && checkInMinute == checkInToMinute) {
            // let vTime = document.getElementById('ranged').getElementsByClassName('vc-time');
            let calendar = document.getElementById('ranged-check-in');
            if (calendar) {
              let vTime = calendar.getElementsByClassName('vc-time');
              if (vTime && vTime.length > 0) {
                // let selectHours = vTime[1].querySelectorAll('.vc-select')[0].querySelector('select');
                // let selectMinutes = vTime[1].querySelectorAll('.vc-select')[1].querySelector('select');
                let selectHours = vTime[0].querySelectorAll('.vc-select')[0].querySelector('select');
                let selectMinutes = vTime[0].querySelectorAll('.vc-select')[1].querySelector('select');
                if (selectMinutes && selectHours) {
                  this.processing = false;
                  selectHours.value = checkInHour;
                  selectHours.disabled = true;
                  selectMinutes.value = checkInMinute;
                  selectMinutes.disabled = true;
                  await this.refreshTimeInputs('none', 'none', 'ranged-start', checkInMinute, checkInHour);
                }
              }
            }
          }
        }

        if (val !== null) {
          if (val.start === null) {
            this.form.date_start = null;
          } else {
            this.form.date_start = this.moment(val.start).format('DD-MM-YYYY HH:mm');
          }
        } else {
          this.form.date_start = null;
        }
        if (val !== null) {
          if (val.end === null) {
            this.form.date_end = null;
          } else {
            this.form.date_end = this.moment(val.end).format('DD-MM-YYYY HH:mm');
          }
        } else {
          this.form.date_end = null;
        }

        // Deal with check_in and check_out minutes
        if (this.booking_type === 'reserve') {
          if (val !== null) {
            // Check-in choices
            if (val.start !== null) {
              if (hour != this.oldStartHour) {
                // RESET Minutes
                // let vTime = document.getElementById('ranged').getElementsByClassName('vc-time');
                let calendar = document.getElementById('ranged-check-in');
                if (calendar) {
                  let vTime = calendar.getElementsByClassName('vc-time');
                  if (vTime && vTime.length > 0) {
                    let select = vTime[0].querySelectorAll('.vc-select')[1].querySelector('select');
                    let defaultOption = select[0].cloneNode(true);
                    select.innerHTML = '';
                    for (let i = 0; i < 4; i++) {
                      if (i == 0) {
                        let defaultOption0 = defaultOption.cloneNode(true);
                        defaultOption0.value = 0;
                        defaultOption0.innerHTML = '00';
                        select.appendChild(defaultOption0);
                      }
                      if (i == 1) {
                        let defaultOption15 = defaultOption.cloneNode(true);
                        defaultOption15.value = 15;
                        defaultOption15.innerHTML = 15;
                        select.appendChild(defaultOption15);
                      }
                      if (i == 2) {
                        let defaultOption30 = defaultOption.cloneNode(true);
                        defaultOption30.value = 30;
                        defaultOption30.innerHTML = 30;
                        select.appendChild(defaultOption30);
                      }
                      if (i == 3) {
                        let defaultOption45 = defaultOption.cloneNode(true);
                        defaultOption45.value = 45;
                        defaultOption45.innerHTML = 45;
                        select.appendChild(defaultOption45);
                      }
                    }
                  }
                }

                // Check further
                // let oldStartHour = this.moment(oldVal.start).format('H');
                // let hour = this.moment(val.start).format('H');
                let checkInFromHour = this.moment.utc(this.bookingsData.check_in_from).format('H');
                let checkInToHour = this.moment.utc(this.bookingsData.check_in_to).format('H');
                // Check if its same hour
                if (checkInFromHour == checkInToHour) {
                  if (hour == checkInFromHour) {
                    let checkInFromMinute = this.moment.utc(this.bookingsData.check_in_from).format('m');
                    let checkInToMinute = this.moment.utc(this.bookingsData.check_in_to).format('m');
                    // let vTime = document.getElementById('ranged').getElementsByClassName('vc-time');
                    let calendar = document.getElementById('ranged-check-in');
                    if (calendar) {
                      let vTime = calendar.getElementsByClassName('vc-time');
                      if (vTime && vTime.length > 0) {
                        let select = vTime[0].querySelectorAll('.vc-select')[1].querySelector('select');
                        for (let i = select.length; i >= 0; i--) {
                          if (select[i] && (select[i].value < checkInFromMinute || select[i].value > checkInToMinute)) {
                            select[i].remove();
                          }
                        }
                      }
                    }
                  }
                } else {
                  // If check-in from and check-in to are different hours
                  if (hour == checkInFromHour) {
                    let checkInFromMinute = this.moment.utc(this.bookingsData.check_in_from).format('m');
                    // let vTime = document.getElementById('ranged').getElementsByClassName('vc-time');
                    let calendar = document.getElementById('ranged-check-in');
                    if (calendar) {
                      let vTime = calendar.getElementsByClassName('vc-time');
                      if (vTime && vTime.length > 0) {
                        let select = vTime[0].querySelectorAll('.vc-select')[1].querySelector('select');
                        for (let i = select.length; i >= 0; i--) {
                          if (select[i] && select[i].value < checkInFromMinute) {
                            select[i].remove();
                          }
                        }
                      }
                    }
                  }
                  if (hour == checkInToHour) {
                    let checkInToMinute = this.moment.utc(this.bookingsData.check_in_to).format('m');
                    // let vTime = document.getElementById('ranged').getElementsByClassName('vc-time');
                    let calendar = document.getElementById('ranged-check-in');
                    if (calendar) {
                      let vTime = calendar.getElementsByClassName('vc-time');
                      if (vTime && vTime.length > 0) {
                        let select = vTime[0].querySelectorAll('.vc-select')[1].querySelector('select');
                        for (let i = select.length; i >= 0; i--) {
                          if (select[i] && select[i].value > checkInToMinute) {
                            select[i].remove();
                          }
                        }
                      }
                    }
                  }
                }

                if (hour != oldStartHour && (hour == checkInFromHour || hour == checkInToHour)) {
                  this.processing = false;
                  await this.refreshTimeInputs(0, 'none', 'ranged-start');
                } else {
                  this.processing = false;
                  await this.refreshTimeInputs();
                }
                let that = this;
                const loadProcessing = setInterval(function () {
                  that.processing = false;
                  clearInterval(loadProcessing);
                }, 500);
              }
            }

            // Check-out choices
            if (val.end !== null) {
              if (endHour != this.oldEndHour) {
                // RESET Minutes
                // let vTime = document.getElementById('ranged').getElementsByClassName('vc-time');
                let calendar = document.getElementById('ranged-check-out');
                if (calendar) {
                  let vTime = calendar.getElementsByClassName('vc-time');
                  if (vTime && vTime.length > 0) {
                    // let select = vTime[1].querySelectorAll('.vc-select')[1].querySelector('select');
                    let select = vTime[0].querySelectorAll('.vc-select')[1].querySelector('select');
                    let defaultOption = select[0].cloneNode(true);
                    select.innerHTML = '';
                    for (let i = 0; i < 4; i++) {
                      if (i == 0) {
                        let defaultOption0 = defaultOption.cloneNode(true);
                        defaultOption0.value = 0;
                        defaultOption0.innerHTML = '00';
                        select.appendChild(defaultOption0);
                      }
                      if (i == 1) {
                        let defaultOption15 = defaultOption.cloneNode(true);
                        defaultOption15.value = 15;
                        defaultOption15.innerHTML = 15;
                        select.appendChild(defaultOption15);
                      }
                      if (i == 2) {
                        let defaultOption30 = defaultOption.cloneNode(true);
                        defaultOption30.value = 30;
                        defaultOption30.innerHTML = 30;
                        select.appendChild(defaultOption30);
                      }
                      if (i == 3) {
                        let defaultOption45 = defaultOption.cloneNode(true);
                        defaultOption45.value = 45;
                        defaultOption45.innerHTML = 45;
                        select.appendChild(defaultOption45);
                      }
                    }
                  }
                }

                // Check further
                // let oldEndHour = this.moment(oldVal.end).format('H');
                // let hour = this.moment(val.end).format('H');
                let checkOutFromHour = this.moment.utc(this.bookingsData.check_out_from).format('H');
                let checkOutToHour = this.moment.utc(this.bookingsData.check_out_to).format('H');
                // Check if its same hour
                if (checkOutFromHour == checkOutToHour) {
                  if (endHour == checkOutFromHour) {
                    let checkOutFromMinute = this.moment.utc(this.bookingsData.check_out_from).format('m');
                    let checkOutToMinute = this.moment.utc(this.bookingsData.check_out_to).format('m');
                    // let vTime = document.getElementById('ranged').getElementsByClassName('vc-time');
                    let calendar = document.getElementById('ranged-check-out');
                    if (calendar) {
                      let vTime = calendar.getElementsByClassName('vc-time');
                      if (vTime && vTime.length > 0) {
                        // let select = vTime[1].querySelectorAll('.vc-select')[1].querySelector('select');
                        let select = vTime[0].querySelectorAll('.vc-select')[1].querySelector('select');
                        for (let i = select.length; i > 0; i--) {
                          if (select[i] && (select[i].value > checkOutFromMinute || select[i].value < checkOutToMinute)) {
                            select[i].remove();
                          }
                        }
                      }
                    }
                  }
                } else {
                  // If check-out from and check-out to are different hours
                  if (endHour == checkOutFromHour) {
                    let checkOutFromMinute = this.moment.utc(this.bookingsData.check_out_from).format('m');
                    // let vTime = document.getElementById('ranged').getElementsByClassName('vc-time');
                    let calendar = document.getElementById('ranged-check-out');
                    if (calendar) {
                      let vTime = calendar.getElementsByClassName('vc-time');
                      if (vTime && vTime.length > 0) {
                        let select = vTime[0].querySelectorAll('.vc-select')[1].querySelector('select');
                        for (let i = select.length; i >= 0; i--) {
                          if (select[i] && select[i].value < checkOutFromMinute) {
                            select[i].remove();
                          }
                        }
                      }
                    }
                  }
                  if (endHour == checkOutToHour) {
                    let checkOutToMinute = this.moment.utc(this.bookingsData.check_out_to).format('m');
                    // let vTime = document.getElementById('ranged').getElementsByClassName('vc-time');
                    let calendar = document.getElementById('ranged-check-out');
                    if (calendar) {
                      let vTime = calendar.getElementsByClassName('vc-time');
                      if (vTime && vTime.length > 0) {
                        let select = vTime[0].querySelectorAll('.vc-select')[1].querySelector('select');
                        for (let i = select.length; i >= 0; i--) {
                          if (select[i] && select[i].value > checkOutToMinute) {
                            select[i].remove();
                          }
                        }
                      }
                    }
                  }
                }

                if (endHour != oldEndHour && (endHour == checkOutFromHour || endHour == checkOutToHour)) {
                  this.processing = false;
                  await this.refreshTimeInputs(0, 'none', 'ranged-end');
                } else {
                  this.processing = false;
                  await this.refreshTimeInputs();
                }
                let that = this;
                const loadProcessing = setInterval(function () {
                  that.processing = false;
                  clearInterval(loadProcessing);
                }, 500);
              }
            }
          }
          this.oldStartHour = hour;
          this.oldEndHour = endHour;
          this.refresh_rooms = true;
        }

      }, deep: true
    },
    'refresh_rooms': {
      handler: async function (val, oldVal) {
        if (val === true) {
          {
            // Get Rooms
            await this.getRooms();
            this.refresh_rooms = false;
          }
        } else {
          this.refresh_rooms = false;
        }
      }
      },
    'form.single_start_date': {
      handler: async function (val) {
        this.processing = true;
        if (val) {
          this.form.date_start = this.moment(val).format('DD-MM-YYYY HH:mm');
          // Deal with validHours
          // if (this.booking_type === 'view' || this.booking_type === 'appoint') {
          //   if (this.bookingsData.booked_date_hours && this.bookingsData.booked_date_hours[this.moment(val).format('DD-MM-YYYY')]) {
          //     let validHoursArr = Object.values(this.validHours);
          //     let bookingsDataHoursArr = Object.values(this.bookingsData.booked_date_hours[this.moment(val).format('DD-MM-YYYY')]);
          //
          //     let validHrs = validHoursArr.filter(hour => {
          //       // return !bookingsDataHoursArr.includes(hour.toString());
          //       return !bookingsDataHoursArr.some(bookedHour => parseInt(bookedHour) === parseInt(hour)); // parseInt(hour) !== parseInt(bookedHour)
          //     });
          //
          //     if (this.bookingCalendars && this.bookingCalendars[0] && this.bookingCalendars[0].disabled_hours) {
          //       let validHours = validHrs.filter(hour => {
          //         return !this.bookingCalendars[0].disabled_hours.some(disabledHour => parseInt(hour) === parseInt(disabledHour));
          //       });
          //       this.validHours = validHours;
          //     } else {
          //       this.validHours = validHrs;
          //     }
          //   } else {
          //     this.validHours = this.defaultValidHours;
          //   }
          // }
          if (this.booking_type === 'view' || this.booking_type === 'appoint') {
            if (this.bookingsData.booked_date_hours && this.bookingsData.booked_date_hours[this.moment(val).format('DD-MM-YYYY')]) {
              let validHoursArr = Object.values(this.validHours);
              let bookingsDataHoursArr = Object.values(this.bookingsData.booked_date_hours[this.moment(val).format('DD-MM-YYYY')]);

              let validHrs = validHoursArr.filter(hour => {
                // return !bookingsDataHoursArr.includes(hour.toString());
                return !bookingsDataHoursArr.some(bookedHour => parseInt(bookedHour) === parseInt(hour)); // parseInt(hour) !== parseInt(bookedHour)
              });

              if (this.bookingCalendars && this.bookingCalendars[0] && this.bookingCalendars[0].disabled_hours) {
                let validHours = validHrs.filter(hour => {
                  return !this.bookingCalendars[0].disabled_hours.some(disabledHour => parseInt(hour) === parseInt(disabledHour));
                });
                this.validHours = validHours;
              } else {
                this.validHours = validHrs;
              }
            } else if (this.validWorkingHours && this.validWorkingHours.length > 0) {
              this.validHours = this.validWorkingHours;
            } else {
              this.validHours = this.defaultValidHours;
            }
          }


          // Reset minutes but consider working hours
          let hour = this.moment(val).format('H');
          let date = this.moment(val).format('DD-MM-YYYY');

          // First we check against working hours from - to
          let workingHourFrom = null;
          let workingHourTo = null;
          let workingHourFromMinute = null;
          let workingHourToMinute = null;
          if (this.bookingsData && this.bookingsData.check_in_from && this.bookingsData.check_in_from !== null && this.bookingsData.check_in_from !== 'Flexible') {
            workingHourFrom = this.moment(this.bookingsData.check_in_from).format('H');
            workingHourFromMinute = this.moment(this.bookingsData.check_in_from).format('m');
          }
          if (this.bookingsData && this.bookingsData.check_in_to && this.bookingsData.check_in_to !== null && this.bookingsData.check_in_to !== 'Flexible') {
            workingHourTo = this.moment(this.bookingsData.check_in_to).format('H');
            workingHourToMinute = this.moment(this.bookingsData.check_in_to).format('m');
          }

          let singCalendar = document.getElementById('singular');
          if (singCalendar) {
            let vTime = singCalendar.getElementsByClassName('vc-time');
            if (vTime && vTime.length > 0) {
              let select = vTime[0].querySelectorAll('.vc-select')[1].querySelector('select');
              let defaultOption = null;
              if (select[0]) {
                defaultOption = select[0].cloneNode(true);
              } else {
                defaultOption = document.createElement("option");
                let attributes = select.getAttributeNames();
                if (attributes && attributes[0]) {
                  // Copy the attribute
                  defaultOption.setAttribute(attributes[0], '');
                }
              }
              select.innerHTML = '';
              for (let i = 0; i < 4; i++) {
                if (i == 0) {
                  if ((workingHourFrom && workingHourFrom == hour && workingHourFromMinute && workingHourFromMinute > '0')) {
                    // Skip
                  } else {
                    let defaultOption0 = defaultOption.cloneNode(true);
                    // defaultOption0.value = 0;
                    defaultOption0.setAttribute('value', 0);
                    defaultOption0.innerHTML = '00';
                    select.appendChild(defaultOption0);
                  }
                }
                if (i == 1) {
                  if ((workingHourFrom && workingHourFrom == hour && workingHourFromMinute && workingHourFromMinute > '15') ||
                      (workingHourTo && workingHourTo == hour && workingHourToMinute && workingHourToMinute < '15')
                  ) {
                    // Skip
                  } else {
                    let defaultOption15 = defaultOption.cloneNode(true);
                    // defaultOption15.value = 15;
                    defaultOption15.setAttribute('value', 15);
                    defaultOption15.innerHTML = 15;
                    select.appendChild(defaultOption15);
                  }
                }
                if (i == 2) {
                  if ((workingHourFrom && workingHourFrom == hour && workingHourFromMinute && workingHourFromMinute > '30') ||
                      (workingHourTo && workingHourTo == hour && workingHourToMinute && workingHourToMinute < '30')
                  ) {
                    // Skip
                  } else {
                    let defaultOption30 = defaultOption.cloneNode(true);
                    // defaultOption30.value = 30;
                    defaultOption30.setAttribute('value', 30);
                    defaultOption30.innerHTML = 30;
                    select.appendChild(defaultOption30);
                  }
                }
                if (i == 3) {
                  if ((workingHourFrom && workingHourFrom == hour && workingHourFromMinute && workingHourFromMinute > '45') ||
                      (workingHourTo && workingHourTo == hour && workingHourToMinute && workingHourToMinute < '45')
                  ) {
                    // Skip
                  } else {
                    let defaultOption45 = defaultOption.cloneNode(true);
                    // defaultOption45.value = 45;
                    defaultOption45.setAttribute('value', 45);
                    defaultOption45.innerHTML = 45;
                    select.appendChild(defaultOption45);
                  }
                }
              }
            }
          }

          // Deal with validMinutes - this one we must remove from DOM... as v-calendar does not provide functionality
          if (this.bookingsData.booked_date_hour_minutes &&
              this.bookingsData.booked_date_hour_minutes[date] &&
              this.bookingsData.booked_date_hour_minutes[date][hour]
          ) {
            let calendar = document.getElementById('singular');
            if (calendar) {
              let vTime = calendar.getElementsByClassName('vc-time');
              if (vTime && vTime.length > 0) {
                let select = vTime[0].querySelectorAll('.vc-select')[1].querySelector('select');
                for (let i = 0; i < this.bookingsData.booked_date_hour_minutes[date][hour].length; i++) {
                  let val = this.bookingsData.booked_date_hour_minutes[date][hour][i];
                  let option = select.querySelector(`option[value="${CSS.escape(val)}"]`);
                  if (option) {
                    option.remove();
                  }
                }
              }
            }
          }
          await this.refreshTimeInputs();
          let that = this;
          const loadProcessing = setInterval(function () {
            that.processing = false;
            clearInterval(loadProcessing);
          }, 500);
        }
      }, deep: true, immediate: true
    }
  }
}
</script>

<style lang="scss">

/* Map Box Geocoder */
#geocoder {
  z-index: 1;
}
.mapboxgl-ctrl-geocoder {
  border-radius: .25rem;
  border: 1px solid #ced4da;
  width: 100%;
  box-shadow: none;
  min-width: 100%;
}
/* End Map Box Geocoder */

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scroll {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* start tooltip - popups info on icons */
.tooltiptext {
  visibility: hidden;
  width: auto;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  text-align: center;
  font-size: 10px;
  border-radius: 6px;
  padding: 5px 5px;

  /* Position the tooltip */
  position: absolute;
  margin-left: 10px;
  z-index: 1000;
}

[data-tooltip]:hover .tooltiptext {
  visibility: visible;
  color: #fff;
}
/* end tooltip - popups info on icons */

/* Start Radio Button */
/* Customize the label (the container) */
.radio-container-room {
  display: block;
  position: relative;
  padding-left: 25px;
  //margin: 2px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.radio-container-room input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #eee;
  border-radius: 50%;
  border: 1px solid #ccc;
}

/* On mouse-over, add a grey background color */
.radio-container-room:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.radio-container-room input:checked ~ .checkmark {
  background-color: #2196F3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-container-room input:checked ~ .checkmark:after {
  display: inline-block;
}

/* Style the indicator (dot/circle) */
.radio-container-room .checkmark:after {
  top: 4px;
  left: 4px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}
/* End Radio Button */

#bookingModal {
  height: 100%;
  z-index: 100000;
  .modal-dialog {
    max-width: 85vw;
  }
  .modal-header {
    font-size: 13px;
    color: white;
    border-radius: 10px 10px 0 0;
    background: rgba(128, 0, 128, 0.7);
    padding: .5rem;
    text-transform: uppercase
  }
  .modal-content {
    border-radius: 10px;
    border: none;
    height: fit-content;
    .modal-body {
      overflow-x: hidden;
      overflow-y: scroll;
      height: 60vh;
    }
  }
  .modal-footer {
    display: block;
    overflow-x: hidden;
    overflow-y: scroll;
    max-height: 25vh;
    //justify-content: center;
  }
  .booking-textarea-container {
    height: 200px;
    max-height: 200px;
  }
  .booking-textarea-container textarea {
    height: 180px;
    max-height: 180px;
  }
  .modal-border {
    border-radius: 10px;
    background: white;
    $border: 2px;
    position: relative;
    box-sizing: border-box;
    &:before {
      content: '';
      position: absolute;
      top: 0; right: 0; bottom: 0; left: 0;
      z-index: -1;
      margin: -$border; /* !importanté */
      border-radius: inherit; /* !importanté */
      background: linear-gradient(to bottom, rgba(128, 0, 128, 0.7), rgba(25, 181, 254, 0.7));
    }
  }
  .close-btn {
    color: white;
    line-height: 30px;
    font-size: 26px;
    border-radius: 50%;
    padding: 1px 5px;
    border: 0;
  }
  .close-btn:hover {
    color: rgba(128, 0, 128, 0.7);
    transform: rotate(90deg);
    transition: 0.5s;
    -moz-transition: 0.5s;
  }
}

/* Start Room List */
.room-list-header {
  font-size: 12px;
  border-radius: 4px;
  border: 1px solid #ced4da;
  display: inline-block;
  width: 100%;
  padding: .375rem .75rem;
  line-height: 1.5;
  color: #212529;
  overflow-wrap: break-word !important;
}
.room-list {
  font-size: 12px;
  border-radius: 4px;
  border: 1px solid #ced4da;
  display: inline-block;
  width: 100%;
  padding: .375rem .75rem;
  line-height: 1.5;
  color: #212529;
  cursor: pointer;
  background-color: transparent;
  transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  //.edit-btn {
  //  border-radius: 50%;
  //  width: 25px;
  //  height: 25px;
  //  display: block;
  //  margin: auto;
  //  font-size: 9px;
  //  padding: 0;
  //  transition: 0.6s;
  //  border: 0;
  //  color: white;
  //  background-color: orange;
  //}
  //
  //.edit-btn:hover {
  //  color: black;
  //  border: 1px solid orange !important;
  //  background-color: white;
  //  transition: 0.6s;
  //}
  .qty-input {
    border-radius: .25rem;
    border: 1px solid #ced4da;
    width: 40px;
    //padding: .375rem .25rem .375rem .75rem;
  }
  .qty-input:focus {
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
  }
}
.room-list:hover {
  cursor: pointer;
  background-color: rgba(246, 246, 246, 1);
  transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
}
.room-details {
  font-size: 12px;
  border-radius: 4px;
  border: 1px solid #ced4da;
  width: 100%;
  //padding: .375rem .75rem;
  line-height: 1.5;
  color: #212529;
  transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
}
/* End Room List */

.summary-list {
  font-size: 12px;
  border-radius: 4px;
  border: 1px solid #ced4da;
  display: inline-block;
  width: auto;
  padding: .375rem .75rem;
  line-height: 1.5;
  color: #212529;
  overflow-wrap: break-word !important;
}

/* Start Text truncate - white space - for long texts and icons */
.list-relative {
  width: 100%;
  position: relative;
}

.text-truncate-item {
  width: 100%;
  box-sizing: border-box;
  transition: 0.7s;
  border-radius: 5px;
}

.text-truncate-item:hover {
  position: absolute;
  transition: 0.7s;
  box-sizing: border-box;
  overflow: visible !important;
  overflow-wrap: break-word !important;
  white-space: normal !important;
  background-color: white;
  border: 1px solid black;
  border-radius: 5px;
  padding: 0 0 0 1px;
  z-index: 1000;
}
/* End Text truncate - white space - for long texts and icons */

.chat-input-position {
  position: relative;
  transform: perspective(1px) translateY(20%);
}

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {

}
@media screen and (max-width: 767px) {
  #bookingModal {
    .modal-dialog {
      max-width: 95vw;
    }
  }
}
@media screen and (min-width: 768px) {
  #bookingModal {
    .modal-dialog {
      max-width: 85vw;
    }
  }
}
@media screen and (min-width: 992px) {

}
@media screen and (min-width: 1200px) {

}
@media (max-height: 800px) and (min-width: 1200px) {

}
@media screen and (min-width: 1680px) {

}
@media (min-height: 1200px) and (min-width: 1600px) {

}
@media screen and (min-width: 1920px) {

}

</style>