<template>
  <!-- Modal -->
  <div class="modal fade" id="chatModal" tabindex="-1" role="dialog" data-bs-backdrop="true" aria-labelledby="chatModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-border">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="chatModalLabel">Send private message</h5>
            <button type="button" id="closeChatModal" class="btn btn-outline-light close close-btn" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <!-- Form with textarea for new message -->
            <div class="col-12 zindex-10">
              <div class="">
                <form action="javascript:void(0)" method="post" enctype="multipart/form-data">
                  <div class="form-group col-12">
                    <div class="chat-message-textarea-container">
                      <div class="input-group input-group-sm mb-3">
                        <textarea v-model="newMessage"
                                  name="newChatMessage"
                                  id="newChatMessage"
                                  rows="6"
                                  cols="50"
                                  maxlength="2000"
                                  class="form-control"
                                  aria-describedby="sendBtnChatMsg"
                                  @keydown.enter.exact.prevent="sendNewMessage"
                                  @keydown.enter.shift.exact.prevent="newMessage += '\n'"
                                  :disabled="processing"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-12">
                    <button type="submit"
                            :disabled="processing"
                            @click.prevent="sendNewMessage"
                            class="btn btn-outline-success"
                            id="sendBtnChatMsg"
                    >Send
                    </button>
                  </div>
                </form>
              </div>
            </div>

          </div>
          <div class="modal-footer">
            <div v-if="this.validationMessage" class="success-info success-msg text-center">
              {{ this.validationMessage }}
            </div>
            <div v-if="validationErrors" class="global-error mt-2">
              <ValidationErrors :errors="validationErrors"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ValidationErrors from "./ValidationErrors"

export default {
  name: 'ChatModal',
  props: ['toUserId', 'fromUserId', 'adId', 'adType', 'adData', 'message', 'refresh'],
  components: {
    'ValidationErrors': ValidationErrors
  },
  data(){
    return {
      user: (this.$store.state.auth.authenticated === true && this.$store.state.auth.user) ? this.$store.state.auth.user : null,
      to_user_id: this.toUserId,
      ad_id: this.adId,
      ad_type: this.adType,
      newMessage: '',
      processing:false,
      validationMessage: '',
      validationErrors: '',
      validationSuccess: null
    }
  },
  methods:{
    async sendNewMessage() {
      if (this.newMessage === '') {
        return;
      }
      this.processing = true;
      // axios call with this.newMessage value (make sure you do validation in the BE)
      let formData = new FormData();

      let fromUserId = this.fromUserId ? this.fromUserId : this.$store.state.auth.user.id;

      formData.append('chat_room_id', null);
      formData.append('from_user_id', fromUserId);
      formData.append('to_user_id', this.toUserId);
      formData.append('message', this.newMessage);
      // formData.append('message_type', this.message_type);
      if (this.adType === 'Property') {
        formData.append('property_id', this.adId);
      }
      if (this.adType === 'Service') {
        formData.append('service_id', this.adId);
      }

      let chatRoomId = null;
      await axios.post('api/user/' + this.$store.state.auth.user.id + '/messages/new/' + chatRoomId, formData).then(({data}) => {
        // Clear message input textarea
        this.newMessage = '';
        // Trigger to refresh messages?
        this.validationMessage = "Your message was sent successfully!";
        this.showError = true;
        const timeout = setTimeout(() => {
          this.showError = false;
          this.validationSuccess = null;
          this.validationMessage = '';
          let closeChatBtn = document.getElementById('closeChatModal');
          if (closeChatBtn) {
            closeChatBtn.click();
          }
          clearTimeout(timeout);
        }, 2000);
        this.validationErrors = null;
      }).catch(({response})=>{
        if (response && response.data && response.data.errors) {
          this.validationErrors = response.data.errors;
          const timeout = setTimeout(() => {
            this.validationErrors = '';
            clearTimeout(timeout);
          }, 3000)
          // this.validationMessage = response.data.message;
        }
        // alert(data.message)
      }).finally(() => {
        this.processing = false
      })
    }
  },
  watch: {
    refresh: {
      handler: async function (val) {
        this.loading = true;

        // Action if necessary

        this.loading = false;
      }, deep: true, immediate: true
    },
    'adId': {
      handler: async function (val) {
        if (this.adType === 'Property' && this.message === null) {
          this.newMessage = "Hi,\n\nI am interested in the premises on " + this.adData.adDetails.address.full_address + ". Would you be so kind to contact me please.";
        }
        if (this.adType === 'Service' && this.message === null) {
          this.newMessage = "Hi,\n\nI am interested in your services. Would you be so kind to contact me please.";
        }
        if (this.message !== null) {
          this.newMessage = this.message;
        }
      }
    },
    'adType': {
      handler: async function (val) {
        if (val === 'Property' && this.message === null) {
          this.newMessage = "Hi,\n\nI am interested in the premises on " + this.adData.adDetails.address.full_address + ". Would you be so kind to contact me please.";
        }
        if (val === 'Service' && this.message === null) {
          this.newMessage = "Hi,\n\nI am interested in your services. Would you be so kind to contact me please.";
        }
        if (this.message !== null) {
          this.newMessage = this.message;
        }
      }
    }
  }
}
</script>

<style lang="scss">

#chatModal {
  height: 100%;
  z-index: 100000;
  .modal-header {
    font-size: 13px;
    color: white;
    border-radius: 10px 10px 0 0;
    background: rgba(128, 0, 128, 0.7);
    padding: .5rem;
    text-transform: uppercase
  }
  .modal-content {
    border-radius: 10px;
    border: none;
    height: fit-content;
  }
  .modal-footer {
    justify-content: center;
  }
  .chat-message-textarea-container {
    height: 200px;
    max-height: 200px;
  }
  .chat-message-textarea-container textarea {
    height: 180px;
    max-height: 180px;
  }
  .modal-border {
    border-radius: 10px;
    background: white;
    $border: 2px;
    position: relative;
    box-sizing: border-box;
    &:before {
      content: '';
      position: absolute;
      top: 0; right: 0; bottom: 0; left: 0;
      z-index: -1;
      margin: -$border; /* !importanté */
      border-radius: inherit; /* !importanté */
      background: linear-gradient(to bottom, rgba(128, 0, 128, 0.7), rgba(25, 181, 254, 0.7));
    }
  }
  .close-btn {
    color: white;
    line-height: 30px;
    font-size: 26px;
    border-radius: 50%;
    padding: 1px 5px;
    border: 0;
  }
  .close-btn:hover {
    color: rgba(128, 0, 128, 0.7);
    transform: rotate(90deg);
    transition: 0.5s;
    -moz-transition: 0.5s;
  }
}

.chat-input-position {
  position: relative;
  transform: perspective(1px) translateY(20%);
}

</style>