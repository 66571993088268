<template>
  <div class="validation-errors">
    <div v-if="this.errors">
      <ul class="alert alert-danger list-unstyled">
        <li v-for="(value, key, index) in this.errors">{{ value[0] }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ValidationErrors',
  props: ['errors'],
}
</script>