import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'
import router from '../router'
import location from './location'
import notifications from './notifications'
import searchFilters from './search-filters'
import searchServicesFilters from './search-services-filters'
import map from './map'
import properties from './properties'
import services from './services'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins:[
  ],
  state: {
  },
  mutations: {
    // If user is in sessionStorage, retrieve data, so that user is not logged out on refresh page
    initialiseUser(state, rootState) {
      if (sessionStorage.getItem('auth')) {
        let authState = JSON.parse(sessionStorage.getItem('auth'));
        if (new Date(authState.auth[0].expire) > new Date()) {
          rootState.auth.user = authState.auth[0].user;
          if (authState.auth[0].authenticated === true) {
            rootState.auth.authenticated = true;
          }
        } else {
          // Logout, remove sessionStorage('auth') and redirect to login page
          this.dispatch('auth/logout', null, {root:true})
          router.push({name: "login"}).catch(()=>{});
        }
      }
    },
    // If search filters present in sessionStorage, retrieve data
    initialiseSearchFilters(state, rootState) {
      if (sessionStorage.getItem('searchFilters')) {
        rootState.searchFilters.searchFilters = JSON.parse(sessionStorage.getItem('searchFilters'));
      }
    },
    // If search services filters present in sessionStorage, retrieve data
    initialiseSearchServicesFilters(state, rootState) {
      if (sessionStorage.getItem('searchServicesFilters')) {
        rootState.searchServicesFilters.searchServicesFilters = JSON.parse(sessionStorage.getItem('searchServicesFilters'));
      }
    },
    // Initialise property Categories Types Details if present in sessionStorage
    initialisePropertyCategoryOptions(state, rootState) {
      if (sessionStorage.getItem('propertyCategoriesOptions')) {
        rootState.properties.property_categories_options = JSON.parse(sessionStorage.getItem('propertyCategoriesOptions'));
      }
    },
    initialisePropertyTypesOptions(state, rootState) {
      if (sessionStorage.getItem('propertyTypesOptions')) {
        rootState.properties.property_types_options = JSON.parse(sessionStorage.getItem('propertyTypesOptions'));
      }
    },
    initialisePropResidentialTypesOptions(state, rootState) {
      if (sessionStorage.getItem('propResidentialTypesOptions')) {
        rootState.properties.prop_residential_types_options = JSON.parse(sessionStorage.getItem('propResidentialTypesOptions'));
      }
    },
    initialisePropCommercialTypesOptions(state, rootState) {
      if (sessionStorage.getItem('propCommercialTypesOptions')) {
        rootState.properties.prop_commercial_types_options = JSON.parse(sessionStorage.getItem('propCommercialTypesOptions'));
      }
    },
    initialisePropIndustrialTypesOptions(state, rootState) {
      if (sessionStorage.getItem('propIndustrialTypesOptions')) {
        rootState.properties.prop_industrial_types_options = JSON.parse(sessionStorage.getItem('propIndustrialTypesOptions'));
      }
    },
    initialisePropLandTypesOptions(state, rootState) {
      if (sessionStorage.getItem('propLandTypesOptions')) {
        rootState.properties.prop_land_types_options = JSON.parse(sessionStorage.getItem('propLandTypesOptions'));
      }
    },
    initialisePropLuxuryTypesOptions(state, rootState) {
      if (sessionStorage.getItem('propLuxuryTypesOptions')) {
        rootState.properties.prop_luxury_types_options = JSON.parse(sessionStorage.getItem('propLuxuryTypesOptions'));
      }
    },
    initialisePropSpecialUseTypesOptions(state, rootState) {
      if (sessionStorage.getItem('propSpecialUseTypesOptions')) {
        rootState.properties.prop_special_use_types_options = JSON.parse(sessionStorage.getItem('propSpecialUseTypesOptions'));
      }
    },
    initialisePropHouseTypeDetails(state, rootState) {
      if (sessionStorage.getItem('propHouseTypeDetails')) {
        rootState.properties.prop_house_type_details = JSON.parse(sessionStorage.getItem('propHouseTypeDetails'));
      }
    },
    initialisePropFlatTypeDetails(state, rootState) {
      if (sessionStorage.getItem('propFlatTypeDetails')) {
        rootState.properties.prop_flat_type_details = JSON.parse(sessionStorage.getItem('propFlatTypeDetails'));
      }
    },
    initialisePropOfficeTypeDetails(state, rootState) {
      if (sessionStorage.getItem('propOfficeTypeDetails')) {
        rootState.properties.prop_office_type_details = JSON.parse(sessionStorage.getItem('propOfficeTypeDetails'));
      }
    },
    initialisePropRetailTypeDetails(state, rootState) {
      if (sessionStorage.getItem('proRetailTypeDetails')) {
        rootState.properties.prop_retail_type_details = JSON.parse(sessionStorage.getItem('proRetailTypeDetails'));
      }
    },
    initialisePropLeisureTypeDetails(state, rootState) {
      if (sessionStorage.getItem('propLeisureTypeDetails')) {
        rootState.properties.prop_leisure_type_details = JSON.parse(sessionStorage.getItem('propLeisureTypeDetails'));
      }
    },
    initialisePropHealthcareTypeDetails(state, rootState) {
      if (sessionStorage.getItem('propHealthcareTypeDetails')) {
        rootState.properties.prop_healthcare_type_details = JSON.parse(sessionStorage.getItem('propHealthcareTypeDetails'));
      }
    },
    initialisePropParkingTypeDetails(state, rootState) {
      if (sessionStorage.getItem('propParkingTypeDetails')) {
        rootState.properties.prop_parking_type_details = JSON.parse(sessionStorage.getItem('propParkingTypeDetails'));
      }
    },
    initialisePropMultifamilyTypeDetails(state, rootState) {
      if (sessionStorage.getItem('propMultifamilyTypeDetails')) {
        rootState.properties.prop_multifamily_type_details = JSON.parse(sessionStorage.getItem('propMultifamilyTypeDetails'));
      }
    },
    initialisePropPowerPlantTypeDetails(state, rootState) {
      if (sessionStorage.getItem('propPowerPlantTypeDetails')) {
        rootState.properties.prop_power_plant_type_details = JSON.parse(sessionStorage.getItem('propPowerPlantTypeDetails'));
      }
    }
  },
  actions: {
    // Initialize mutation to check if we've got user in sessionStorage
    getUser({ commit, rootState }) {
      commit('initialiseUser', rootState)
    },
    // Initialize mutation to check if we've got searchFilters in sessionStorage
    getSearchFilters({ commit, rootState }) {
      commit('initialiseSearchFilters', rootState)
    },
    // Initialize mutation to check if we've got searchServiceFilters in sessionStorage
    getSearchServicesFilters({ commit, rootState }) {
      commit('initialiseSearchServicesFilters', rootState)
    },
    // Initialize mutation to check if we've got searchFilters in sessionStorage
    getPropCatTypeDetails({ commit, rootState }) {
      commit('initialisePropertyCategoryOptions', rootState)
      commit('initialisePropertyTypesOptions', rootState)
      commit('initialisePropResidentialTypesOptions', rootState)
      commit('initialisePropCommercialTypesOptions', rootState)
      commit('initialisePropIndustrialTypesOptions', rootState)
      commit('initialisePropLandTypesOptions', rootState)
      commit('initialisePropLuxuryTypesOptions', rootState)
      commit('initialisePropSpecialUseTypesOptions', rootState)
      commit('initialisePropHouseTypeDetails', rootState)
      commit('initialisePropFlatTypeDetails', rootState)
      commit('initialisePropOfficeTypeDetails', rootState)
      commit('initialisePropRetailTypeDetails', rootState)
      commit('initialisePropLeisureTypeDetails', rootState)
      commit('initialisePropHealthcareTypeDetails', rootState)
      commit('initialisePropParkingTypeDetails', rootState)
      commit('initialisePropMultifamilyTypeDetails', rootState)
      commit('initialisePropPowerPlantTypeDetails', rootState)
    }
  },
  modules: {
    auth,
    properties,
    services,
    location,
    notifications,
    searchFilters,
    searchServicesFilters,
    map
  }
})
