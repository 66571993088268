<template>
  <div class="container-list-properties">

    <div class="row">
      <div class="col-md-12 col-sm-12 properties" id="propertiesList">
          <!-- No Results -->
          <div class="fw-bold p-5 m-2" v-if="!pagination && noData">No results to show <br/>Try to amend your search criteria please</div>
          <!-- Loading -->
          <Loader :loading="loading" :small="false"></Loader>
          <!-- Loop properties --> <!-- I can swap ID for property.id rather than index ?  test it -->
          <div v-if="!loading && this.$store.state.properties.properties && this.$store.state.properties.properties.data">

          <!-- Confirmation Message -->
          <div>
            <p v-if="showReportConfirmation && validationSuccess" class="success-info success-msg text-center">
              <font-awesome-icon icon="thumbs-up" class="icon-fa" style="color: dodgerblue" /> {{ validationSuccess }}
            </p>
          </div>
          <!-- End Confirmation Message -->
          <div v-for="(property, index) in this.$store.state.properties.properties.data"
               :key="index"
          >
            <div :class="activePropertyDetailsThumbnail === property.id ? 'property-details p-2 active-property-details' : 'property-details p-2'"
                 :id="'prop-'+property.id"
                 v-on:click="openPropDetailsModal(property.id, index)"
            >
              <div class="row ps-3 m-0 text-start property-icons">
                <div class="col-1">
                  <ul class="list-unstyled">
                    <!-- Price -->
                    <li>
                      <span data-tooltip="tooltip">
                        <font-awesome-icon icon="pound-sign" class="icon-fa" />
                        <span class="tooltiptext">Price</span>
                      </span>
                    </li>
                  </ul>
                </div>
                <div class="col-7">
                  <ul class="list-unstyled w-100">
                    <!-- Price -->
                    <li class="list-relative">
                      <div v-if="showPriceRange(property)" class="text-truncate-item no-text-wrap overflow-hidden text-truncate">
                        <span v-if="showHourPriceRange(property)" class="price-color"> {{ calculateHourPriceRange(property) }} </span>
                        <span v-if="showHourPriceRange(property)" class="fw-bold"> per hour </span> <br v-if="showHourPriceRange(property)"/>
                        <span v-if="showNightPriceRange(property)" class="price-color"> {{ calculateNightPriceRange(property) }} </span>
                        <span v-if="showNightPriceRange(property)" class="fw-bold"> per night </span>
                        <!-- This only for Let Long-Term Rooms -->
                        <span v-if="showWeekPriceRange(property)" class="price-color"> {{ calculateWeekPriceRange(property) }} </span>
                        <span v-if="showWeekPriceRange(property)" class="fw-bold"> per week </span>
                        <span v-if="showMonthPriceRange(property)" class="price-color"> {{ calculateMonthPriceRange(property) }} </span>
                        <span v-if="showMonthPriceRange(property)" class="fw-bold"> per month </span>
                      </div>
                      <div v-else class="text-truncate-item no-text-wrap overflow-hidden text-truncate">
                        <span class="price-color"> {{ convertNumberToCommas(property.price) }} </span>
                        <span v-if="property.is_rent || checkAdIsLetLongTermOrStudio(property)" class="fw-bold"> per {{ property.property_rent_type.type }} </span>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="col-3 category-label-column">
                  <div v-if="property.is_sale" class="ms-1 category-label text-center" style="color: green; background-color: lightgreen;">
                    <span class="p-1">FOR SALE</span>
                  </div>
                  <div v-if="property.is_let" class="ms-1 category-label text-center" style="color: white; background-color: deepskyblue;">
                    <span class="p-1">TO LET</span>
                  </div>
                  <div v-if="property.is_buy" class="ms-1 category-label text-center" style="color: green; background-color: lightgreen;">
                    <span class="p-1">TO BUY</span>
                  </div>
                  <div v-if="property.is_rent" class="ms-1 category-label text-center" style="color: white; background-color: deepskyblue;">
                    <span class="p-1">TO RENT</span>
                  </div>
                </div>
                <div class="col-1">
                  <!-- Start Action Buttons -->
                  <div class="action-dropdown">
                    <button @click.stop="preventParentClick()" class="btn-action-dropdown" type="button" :id="'actionPropertyDropdownList'+property.id" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                      <font-awesome-icon icon="ellipsis-h" style="color: #868e96;" />
                    </button>
                    <ul class="dropdown-menu dropdown-menu-end list-unstyled action-buttons" :aria-labelledby="'actionPropertyDropdownList'+property.id">
                      <li class="action-item" @click.stop="toggleReportType(property.id)">
                        <!--                                @mouseover="toggleReportType(property.id)"-->
                        <button @click.stop="toggleReportType(property.id)"
                                type="button"
                                :id="'actionPropertyDropdownReportType'+property.id"
                                aria-expanded="false"
                                :class="processing ? 'disabled action-button-report' : 'action-button-report'"
                        >
                          <font-awesome-icon icon="exclamation" class="min-width-30 icon-report" />
                          REPORT
                        </button>
                        <!-- Report Type Dropdown -->
                        <ul @mouseleave="toggleReportType(property.id)" class="dropdown-menu dropdown-menu-start list-unstyled action-buttons" :id="'actionPropertyDropdownReportTypeMenu'+property.id" :aria-labelledby="'actionPropertyDropdownReportType'+property.id">
                          <li class="action-item">
                            <button :class="processing ? 'disabled action-button-spam' : 'action-button-spam'"
                                    @click.stop="reportAdvertise(property.id, 'Property', 'Spam')"
                            >
                              <font-awesome-icon icon="clone" class="min-width-30 icon-spam" />
                              SPAM
                            </button>
                          </li>
                          <li class="action-item">
                            <button :class="processing ? 'disabled action-button-scam' : 'action-button-scam'"
                                    @click.stop="reportAdvertise(property.id, 'Property', 'Scam')"
                            >
                              <font-awesome-icon icon="user-ninja" class="min-width-30 icon-scam" />
                              SCAM
                            </button>
                          </li>
                          <li class="action-item">
                            <button :class="processing ? 'disabled action-button-abuse' : 'action-button-abuse'"
                                    @click.stop="reportAdvertise(property.id, 'Property', 'Abuse')"
                            >
                              <font-awesome-icon icon="heart-broken" class="min-width-30 icon-abuse" />
                              ABUSE
                            </button>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div v-if="(property.is_buy || property.is_rent) && property.property_title" class="property-info-list-relative">
                <div class="text-start ps-3 pb-1 property-info text-truncate-item no-text-wrap overflow-hidden text-truncate">
                  <span class="fw-bold">Title:</span>
                  {{ property.property_title }}
                </div>
              </div>

              <div class="property-info-list-relative">
                <!-- property_category_id 7 = Room -->
                <!-- So we have
                property_type_details - such as Semi-detached etc
                property_type - such as Mansion, Warehouse, House etc
                property_category - such as Luxury, Room, Industrial, Commercial etc.
                -->
                <div v-if="property.property_category_id !== 7 && property.property_type && property.property_type_details" class="text-start ps-3 pb-1 property-info text-truncate-item no-text-wrap overflow-hidden text-truncate">
                <span v-if="property.property_category && property.property_category.type === 'Residential'" class='legend-residential'></span>
                <span v-if="property.property_category && property.property_category.type === 'Commercial'" class='legend-commercial'></span>
                <span v-if="property.property_category && property.property_category.type === 'Industrial'" class='legend-industrial'></span>
                <span v-if="property.property_category && property.property_category.type === 'Land'" class='legend-land'></span>
                <span v-if="property.property_category && property.property_category.type === 'Luxury'" class='legend-luxury'></span>
                <span v-if="property.property_category && property.property_category.type === 'Special Use'" class='legend-special-use'></span>
                <span v-if="property.property_category && property.property_category.type === 'Room'" class='legend-room'></span>

                <span class="fw-bold">
                  {{
                    property.property_type.type  +
                    ' ' +
                    property.property_type_details.type_detail
                  }}
                </span>
                  {{
                    ' - ' +
                    (property.address.county ? property.address.county : '') +
                    (property.address.city ? ', ' + property.address.city : '') +
                    (property.address.line_1 ? ', ' + property.address.line_1 : '') +
                    (property.address.postcode ? ', ' + property.address.postcode : '')
                  }}
                </div>
                <div v-else-if="property.property_category_id !== 7 && property.property_type && !property.property_type_details" class="text-start ps-3 pb-1 property-info text-truncate-item no-text-wrap overflow-hidden text-truncate">
                <span v-if="property.property_category && property.property_category.type === 'Residential'" class='legend-residential'></span>
                <span v-if="property.property_category && property.property_category.type === 'Commercial'" class='legend-commercial'></span>
                <span v-if="property.property_category && property.property_category.type === 'Industrial'" class='legend-industrial'></span>
                <span v-if="property.property_category && property.property_category.type === 'Land'" class='legend-land'></span>
                <span v-if="property.property_category && property.property_category.type === 'Luxury'" class='legend-luxury'></span>
                <span v-if="property.property_category && property.property_category.type === 'Special Use'" class='legend-special-use'></span>
                <span v-if="property.property_category && property.property_category.type === 'Room'" class='legend-room'></span>

                <span class="fw-bold">
                  {{
                    property.property_category.type +
                    ' ' +
                    property.property_type.type
                  }}
                </span>
                  {{
                    ' - ' +
                    (property.address.county ? property.address.county : '') +
                    (property.address.city ? ', ' + property.address.city : '') +
                    (property.address.line_1 ? ', ' + property.address.line_1 : '') +
                    (property.address.postcode ? ', ' + property.address.postcode : '')
                  }}
                </div>
                <div v-else class="text-start ps-3 pb-1 property-info text-truncate-item no-text-wrap overflow-hidden text-truncate">
                <span v-if="property.property_category && property.property_category.type === 'Residential'" class='legend-residential'></span>
                <span v-if="property.property_category && property.property_category.type === 'Commercial'" class='legend-commercial'></span>
                <span v-if="property.property_category && property.property_category.type === 'Industrial'" class='legend-industrial'></span>
                <span v-if="property.property_category && property.property_category.type === 'Land'" class='legend-land'></span>
                <span v-if="property.property_category && property.property_category.type === 'Luxury'" class='legend-luxury'></span>
                <span v-if="property.property_category && property.property_category.type === 'Special Use'" class='legend-special-use'></span>
                <span v-if="property.property_category && property.property_category.type === 'Room'" class='legend-room'></span>

                <span class="fw-bold">
                  {{
                    property.property_category.type
                  }}
                </span>
                  {{
                    ' - ' +
                    (property.address.county ? property.address.county : '') +
                    (property.address.city ? ', ' + property.address.city : '') +
                    (property.address.line_1 ? ', ' + property.address.line_1 : '') +
                    (property.address.postcode ? ', ' + property.address.postcode : '')
                  }}
                </div>
              </div>
              <!--            <div class="row property-info m-0 p-0 ps-3">-->
              <!--              <span class="fw-bold">Posted:</span>-->
              <!--              {{ calculateCreatedDate(property.created_at) }}-->
              <!--            </div>-->
              <!--            <div class="property-info m-0 pb-1 ps-3 text-start">-->
              <!--                <span class="fw-bold">Posted:</span>-->
              <!--                {{ calculateCreatedDate(property.created_at) }}-->
              <!--            </div>-->
              <div class="row property-info m-0 pt-1 pb-1 ps-3 text-start">
                <div class="col-6 m-0 p-0 text-start">
                  <span class="fw-bold">Posted:</span>
                  {{ calculateCreatedDate(property.created_at) }}
                </div>
                <div class="col-6 m-0 p-0 text-end position-relative">
                  <AddBookmark :id="property.id" :type="'Property'"></AddBookmark>
                </div>
              </div>
              <div v-if="property.is_let || property.is_rent" class="row property-info m-0 pt-1 pb-1 ps-3 text-start">
                <div class="col-12 m-0 p-0 text-start">
                  <font-awesome-icon icon="calendar-day" style="color: green;"/>
                  <span class="fw-bold">{{property.is_rent ? ' Required Dates:' : ' Availability:'}}</span>
                  {{ property.date_start ? (property.date_end ? (moment.utc(property.date_start).format('DD/MM/YYYY') + ' - ' + moment.utc(property.date_end).format('DD/MM/YYYY')) : (moment.utc(property.date_start).format('DD/MM/YYYY') + ' - ' + 'TBD')) : 'None' }}
                </div>
              </div>
              <div class="row property-thumbnails">
                <div class="col-8">
                  <div :id="'carouselCaptions-'+index" class="carousel slide" data-bs-ride="carousel">

                    <div class="carousel-inner">
                      <div v-if="property.is_cash" class="cash-caption d-none d-md-block">
                        <p>Cash Only</p>
                      </div>
                      <div v-if="property.is_auction" class="auction-caption d-none d-md-block">
                        <p>Auction</p>
                      </div>
                      <div v-if="property.is_coming_soon" class="coming-soon-caption d-none d-md-block">
                        <p>Coming Soon</p>
                      </div>
                      <div v-if="property.documents_property_images.length === 0">
                        <img :src="require(`@/assets/no-image-found.png`)"
                             class="d-block w-100 img-fluid thumbnails-height"
                             alt="..."
                        >
                      </div>
                      <!-- ${process.env.VUE_APP_S3_URL}  require(`${process.env.VUE_APP_S3_URL}${doc.file_path}`) -->
                      <div v-for="(doc, num) in property.documents_property_images" :key="num" :class="num === 0 ? 'active carousel-item' : 'carousel-item'">
<!--                        <img v-if="getImage(doc.file_name)" :src="require(`@/assets/${doc.file_name}`)" class="d-block w-100 img-fluid thumbnails-img" alt="...">-->
<!--                        <img v-else :src="require(`@/assets/no-image-found.png`)" class="d-block w-100 img-fluid thumbnails-img" alt="...">-->
                        <img :src="`${$s3bucket}${doc.file_path}`" @error="setDefaultImg" class="d-block w-100 img-fluid thumbnails-img" alt="...">
                      </div>
                    </div>

                    <button class="carousel-control-prev" type="button" :data-bs-target="'#carouselCaptions-'+index" data-bs-slide="prev" v-on:click.stop="preventParentClick()">
                      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                      <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" :data-bs-target="'#carouselCaptions-'+index" data-bs-slide="next" v-on:click.stop="preventParentClick()">
                      <span class="carousel-control-next-icon" aria-hidden="true"></span>
                      <span class="visually-hidden">Next</span>
                    </button>

                  </div>
                </div>
                <div class="col-4 p-0 m-0 property-details-section">
<!--                  <div class="row p-0 m-0 text-start property-icons">-->
<!--                    <div class="col-3">-->
<!--                      <ul class="list-unstyled">-->
<!--                        &lt;!&ndash; Price &ndash;&gt;-->
<!--                        <li>-->
<!--                      <span data-tooltip="tooltip">-->
<!--                        <font-awesome-icon icon="pound-sign" class="icon-fa" />-->
<!--                        <span class="tooltiptext">Price</span>-->
<!--                      </span>-->
<!--                        </li>-->
<!--                      </ul>-->
<!--                    </div>-->
<!--                    <div class="col-8">-->
<!--                      <ul class="list-unstyled w-100">-->
<!--                        &lt;!&ndash; Price &ndash;&gt;-->
<!--                        <li class="list-relative">-->
<!--                          <div class="text-truncate-item no-text-wrap overflow-hidden text-truncate">-->
<!--                            <span class="price-color"> {{ convertNumberToCommas(property.price) }} </span>-->
<!--                            <span v-if="property.is_rent || property.is_let" class="fw-bold"> per {{ property.property_rent_type.type }} </span>-->
<!--                          </div>-->
<!--                        </li>-->
<!--                      </ul>-->
<!--                    </div>-->
<!--                  </div>-->
                  <div v-if="!property.is_rent && !property.is_let" class="row p-0 m-0 text-start property-icons">
                    <div class="col-3">
                      <ul class="list-unstyled">
                        <!-- Tenure -->
                        <li>
                        <span data-tooltip="tooltip">
                          <font-awesome-icon icon="file-signature" class="icon-fa" />
                          <span class="tooltiptext">Tenure</span>
                        </span>
                        </li>
                      </ul>
                    </div>
                    <div class="col-8">
                      <ul class="list-unstyled w-100">
                        <!-- Tenure -->
                        <li class="list-relative">
                          <div class="text-truncate-item no-text-wrap overflow-hidden text-truncate">
                            {{ property.is_freehold ? 'Freehold' : 'Leasehold' }}
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="row m-0 p-0 property-icons custom-col-length text-start">
                    <div class="col-6 m-0 p-0">
                      <div class="row m-0 p-0 property-icons">
                        <div class="col-3">
                          <ul class="list-unstyled">
                            <!-- Suite/Apartment Bedrooms -->
                            <li v-if="calculateSuiteBeds(property.property_details)">
                          <span data-tooltip="tooltip">
                            <font-awesome-icon icon="building" class="icon-fa" />
                            <span class="tooltiptext">Suite/Apartment Beds</span>
                          </span>
                            </li>
                            <!-- Bedrooms -->
                            <li v-if="calculateBeds(property.property_details)">
                          <span data-tooltip="tooltip">
                            <font-awesome-icon icon="bed" class="icon-fa" />
                            <span class="tooltiptext">Bedrooms</span>
                          </span>
                            </li>
                            <!-- Living Rooms -->
                            <li v-if="property.property_details.living_room">
                          <span data-tooltip="tooltip">
                            <font-awesome-icon icon="couch" class="icon-fa" />
                            <span class="tooltiptext">Living Rooms</span>
                          </span>
                            </li>
                            <!-- Bathrooms -->
                            <li v-if="property.property_details.bath_room">
                          <span data-tooltip="tooltip">
                            <font-awesome-icon icon="bath" class="icon-fa" />
                            <span class="tooltiptext">Bathrooms</span>
                          </span>
                            </li>
                            <!-- Downstairs WC -->
                            <li v-if="property.property_details.downstairs_wc">
                          <span data-tooltip="tooltip">
                            <font-awesome-icon icon="toilet" class="icon-fa" />
                            <font-awesome-icon icon="hand-point-down" class="icon-fa" />
                            <span class="tooltiptext">Downstairs WC</span>
                          </span>
                            </li>
                            <!-- Garden -->
                            <li v-if="calculateGarden(property.property_details.front_garden, property.property_details.rear_garden)">
                          <span data-tooltip="tooltip">
                            <font-awesome-icon icon="seedling" />
                            <span class="tooltiptext">Garden</span>
                          </span>
                            </li>
                            <!-- Parking -->
                            <li v-if="calculateParking(property.property_details.driveway, property.property_details.garage, property.property_details.parking, property.property_details.parking_spaces)">
                          <span data-tooltip="tooltip">
                            <font-awesome-icon icon="parking" />
                            <span class="tooltiptext">Parking</span>
                          </span>
                            </li>
                          </ul>
                        </div>
                        <div class="col-3">
                          <ul class="list-unstyled">
                            <!-- Suite/Apartment Bedrooms -->
                            <li v-if="calculateSuiteBeds(property.property_details)">
                              {{ calculateSuiteBeds(property.property_details) }}
                            </li>
                            <!-- Bedrooms -->
                            <li v-if="calculateBeds(property.property_details)">
                              {{ calculateBeds(property.property_details) }}
                            </li>
                            <!-- Living Rooms -->
                            <li v-if="property.property_details.living_room">
                              {{ property.property_details.living_room }}
                            </li>
                            <!-- Bathrooms -->
                            <li v-if="property.property_details.bath_room">
                              {{ property.property_details.bath_room }}
                            </li>
                            <!-- Downstairs WC -->
                            <li v-if="property.property_details.downstairs_wc">
                              {{ property.property_details.downstairs_wc }}
                            </li>
                            <!-- Garden -->
                            <li v-if="calculateGarden(property.property_details.front_garden, property.property_details.rear_garden)">
                              {{ calculateGarden(property.property_details.front_garden, property.property_details.rear_garden) }}
                            </li>
                            <!-- Parking -->
                            <li v-if="calculateParking(property.property_details.driveway, property.property_details.garage, property.property_details.parking, property.property_details.parking_spaces)">
                              {{ calculateParking(property.property_details.driveway, property.property_details.garage, property.property_details.parking, property.property_details.parking_spaces) }}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="col-6 m-0 p-0">
                      <div class="row m-0 p-0 property-icons">
                        <div class="col-3">
                          <ul class="list-unstyled">
                            <!-- EPC Rating -->
                            <li v-if="property.epc_rating">
                          <span data-tooltip="tooltip">
                            <font-awesome-icon icon="poll-h" class="icon-fa" />
                            <span class="tooltiptext">EPC Rating</span>
                          </span>
                            </li>
                            <!-- Accessibility -->
                            <li v-if="property.is_accessible">
                          <span data-tooltip="tooltip">
                            <font-awesome-icon icon="wheelchair" class="icon-fa" />
                            <span class="tooltiptext">Accessibility</span>
                          </span>
                            </li>
                            <!-- Shared Ownership -->
                            <li v-if="property.is_shared_ownership">
                          <span data-tooltip="tooltip">
                            <font-awesome-icon icon="handshake" class="icon-fa" />
                            <span class="tooltiptext">Shared Ownership</span>
                          </span>
                            </li>
                            <!-- Over 60's only -->
                            <li v-if="property.is_over_60s">
                          <span data-tooltip="tooltip">
                            <font-awesome-icon icon="hand-holding-heart" class="icon-fa" />
                            <span class="tooltiptext">Over 60's only</span>
                          </span>
                            </li>
                            <!-- HMO -->
                            <li v-if="property.is_hmo">
                          <span data-tooltip="tooltip">
                            <font-awesome-icon icon="users" class="icon-fa" />
                            <span class="tooltiptext">HMO</span>
                          </span>
                            </li>
                            <!-- Furnished -->
                            <li v-if="property.is_furnished">
                          <span data-tooltip="tooltip">
                            <font-awesome-icon icon="chair" class="icon-fa" />
                            <span class="tooltiptext">Furnished</span>
                          </span>
                            </li>
                            <!-- Tenanted -->
                            <li v-if="property.is_tenanted">
                          <span data-tooltip="tooltip">
                            <font-awesome-icon icon="house-user" class="icon-fa" />
                            <span class="tooltiptext">Tenanted</span>
                          </span>
                            </li>
                          </ul>
                        </div>
                        <div class="col-3">
                          <ul class="list-unstyled">
                            <!-- EPC Rating -->
                            <li v-if="property.epc_rating && (!property.is_rent && !property.is_buy)">
                              {{ property.epc_rating }}
                            </li>
                            <!-- Accessibility -->
                            <li v-if="property.is_accessible">
                              Yes
                            </li>
                            <!-- Shared Ownership -->
                            <li v-if="property.is_shared_ownership">
                              Yes
                            </li>
                            <!-- Over 60's only -->
                            <li v-if="property.is_over_60s">
                              Yes
                            </li>
                            <!-- HMO -->
                            <li v-if="property.is_hmo">
                              Yes
                            </li>
                            <!-- Furnished -->
                            <li v-if="property.is_furnished">
                              Yes
                            </li>
                            <!-- Talented -->
                            <li v-if="property.is_tenanted">
                              Yes
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

           </div>
<!--            &lt;!&ndash; Start AdSense &ndash;&gt;-->
<!--            <div v-if="(index !== 0 && index % 4 === 0) || checkIfLastIndex(index)">-->
<!--              <AdSense></AdSense>-->
<!--            </div>-->
<!--            &lt;!&ndash; End AdSense &ndash;&gt;-->

          </div>
          </div>
          <!-- End Loop properties -->

          <!-- Pagination -->
          <ul class="pagination justify-content-center" v-if="pagination && !loading">
            <li class="page-item" :class="{disabled: metaData.current_page === 1}">
              <a class="page-link" @click="previous"><span aria-hidden="true">&laquo;</span></a>
            </li>
            <!-- HERE I NEED TO PROVIDE MY OWN CALCULATED number of pages? instead of last_page metaData.last_page -->
            <li
                v-for="number in paginationData"
                class="page-item"
                :class="{active: metaData.current_page === number}"
            >
                <a class="page-link" @click="navigateTo(null, number)">{{ number }}</a>
            </li>
            <li class="page-item" :class="{disabled: metaData.current_page === metaData.last_page}">
              <a class="page-link" @click="next"><span aria-hidden="true">&raquo;</span></a>
            </li>
          </ul>

          <!-- Prop Details Modal -->
          <transition name="fade">
              <div id="propDetailsModal" class="propDetailsModal modal-tab">
                <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 m-0 p-0">
                  <AdDetails v-bind:id="propDetailsId" v-on:closePropDetailsModal="closePropDetailsModal"></AdDetails>
                </div>
                <a href="javascript:void(0)" id="propDetailsModalCloseButton" class="ad-details-closebtn" v-on:click="closePropDetailsModal">
                  <font-awesome-icon icon="angle-double-left" id="adDetailsChevronCloseBtn" class="m-0 p-0" />
                </a>
              </div>
<!--                <a href="javascript:void(0)" id="propDetailsModalCloseButton" class="ad-details-closebtn" v-on:click="closePropDetailsModal">-->
<!--                  <font-awesome-icon icon="angle-double-left" id="adDetailsChevronCloseBtn" class="m-0 p-0" />-->
<!--                </a>-->
          </transition>
          <!-- End Prop Details Modal -->

      </div>

<!--      <div class="col-md-6 col-sm-12">-->
<!--        <Map/>-->
<!--      </div>-->
    </div>

  </div>
</template>

<script>
import axios from "axios"
import AdDetails from './AdDetails'
import Loader from "./Loader.vue"
import AddBookmark from "./AddBookmark"
import AdSense from "./AdSense"

export default {
  name: 'List',
  components: {
    'AdDetails': AdDetails,
    'Loader': Loader,
    'AddBookmark': AddBookmark,
    'AdSense': AdSense
  },
  props: {
  },
  data(){
    return {
      defaultImg: require('@/assets/no-image-found.png'),
      activePropertyDetailsModal: false,
      activePropertyDetailsThumbnail: undefined,
      propDetailsId: null,
      pagination: false,
      metaData: '',
      linksData: '',
      paginationData: '',
      loading: false,
      noData: false,
      processing: true,
      validationErrors: null,
      validationSuccess: null,
      showReportConfirmation: false
    }
  },
  mounted: function() {
    if (this.$store.state.properties.properties &&
        this.$store.state.properties.properties.data &&
        this.$store.state.properties.properties.data.length > 0
    ) {
      this.loading = false;
      this.noData = false;
      this.pagination = true;
      if (this.pagination) {
        this.calculatePagination();
      }
    } else {
      this.loading = false;
      this.noData = true;
      this.pagination = false;
    }
  },
  methods: {
    // Helpers for LET ads to reflect short-term and long-term lets data
    checkAdIsLetShortTermOrRoom(prop) {
      if (prop && prop.is_let && ((prop.is_short_term && !prop.property_details.studio_room) || prop.is_room)) {
        return true;
      } else {
        return false;
      }
    },
    checkAdIsLetLongTermOrStudio(prop) {
      if (prop && prop.is_let && ((!prop.is_short_term && !prop.is_room) || prop.property_details.studio_room)) {
        return true;
      } else {
        return false;
      }
    },
    showPriceRange(prop) {
      if (this.checkAdIsLetShortTermOrRoom(prop)) {
        return true;
      } else {
        return false;
      }
    },
    showHourPriceRange(prop) {
      if (prop.property_rooms && prop.property_rooms.length > 0) {
        // get hourly prices
        let hourlyPrices = prop.property_rooms.filter(obj => {
          if (obj.property_rent_type_id !== 2) {
            return false;
          } else {
            return true;
          }
        }).map(object => {
          return object.price;
        });

        if (hourlyPrices.length > 0) {
          return true;
        } else {
          return false;
        }
      }
    },
    calculateHourPriceRange(prop) {
      if (prop.property_rooms && prop.property_rooms.length > 0) {
        let maxHourlyPrice = null;
        let minHourlyPrice = null;

        // get hourly prices
        let hourlyPrices = prop.property_rooms.filter(obj => {
          if (obj.property_rent_type_id !== 2) {
            return false;
          } else {
            return true;
          }
        }).map(object => {
          return object.price;
        });

        if (hourlyPrices.length > 0) {
          maxHourlyPrice = Math.max.apply(null, hourlyPrices);
          minHourlyPrice = Math.min.apply(null, hourlyPrices);
        }

        if (minHourlyPrice && maxHourlyPrice) {
          if (minHourlyPrice == maxHourlyPrice) {
            return this.convertNumberToCommas(minHourlyPrice);
          } else {
            return this.convertNumberToCommas(minHourlyPrice) + ' - ' + this.convertNumberToCommas(maxHourlyPrice);
          }
        }
      }
    },
    showNightPriceRange(prop) {
      if (prop.property_rooms && prop.property_rooms.length > 0) {
        // get nightly prices
        let nightlyPrices = prop.property_rooms.filter(obj => {
          if (obj.property_rent_type_id !== 3) {
            return false;
          } else {
            return true;
          }
        }).map(object => {
          return object.price;
        });

        if (nightlyPrices.length > 0) {
          return true;
        } else {
          return false;
        }
      }
    },
    calculateNightPriceRange(prop) {
      if (prop.property_rooms && prop.property_rooms.length > 0) {
        let maxNightlyPrice = null;
        let minNightlyPrice = null;

        // get nightly prices
        let nightlyPrices = prop.property_rooms.filter(obj => {
          if (obj.property_rent_type_id !== 3) {
            return false;
          } else {
            return true;
          }
        }).map(object => {
          return object.price;
        });

        if (nightlyPrices.length > 0) {
          maxNightlyPrice = Math.max.apply(null, nightlyPrices);
          minNightlyPrice = Math.min.apply(null, nightlyPrices);
        }

        if (minNightlyPrice && maxNightlyPrice) {
          if (minNightlyPrice == maxNightlyPrice) {
            return this.convertNumberToCommas(minNightlyPrice);
          } else {
            return this.convertNumberToCommas(minNightlyPrice) + ' - ' + this.convertNumberToCommas(maxNightlyPrice);
          }
        }
      }
    },
    showWeekPriceRange(prop) {
      if (prop.property_rooms && prop.property_rooms.length > 0) {
        // get weekly prices
        let weeklyPrices = prop.property_rooms.filter(obj => {
          if (obj.property_rent_type_id !== 4) {
            return false;
          } else {
            return true;
          }
        }).map(object => {
          return object.price;
        });

        if (weeklyPrices.length > 0) {
          return true;
        } else {
          return false;
        }
      }
    },
    calculateWeekPriceRange(prop) {
      if (prop.property_rooms && prop.property_rooms.length > 0) {
        let maxWeeklyPrice = null;
        let minWeeklyPrice = null;

        // get weekly prices
        let weeklyPrices = prop.property_rooms.filter(obj => {
          if (obj.property_rent_type_id !== 4) {
            return false;
          } else {
            return true;
          }
        }).map(object => {
          return object.price;
        });

        if (weeklyPrices.length > 0) {
          maxWeeklyPrice = Math.max.apply(null, weeklyPrices);
          minWeeklyPrice = Math.min.apply(null, weeklyPrices);
        }

        if (minWeeklyPrice && maxWeeklyPrice) {
          if (minWeeklyPrice == maxWeeklyPrice) {
            return this.convertNumberToCommas(minWeeklyPrice);
          } else {
            return this.convertNumberToCommas(minWeeklyPrice) + ' - ' + this.convertNumberToCommas(maxWeeklyPrice);
          }
        }
      }
    },
    showMonthPriceRange(prop) {
      if (prop.property_rooms && prop.property_rooms.length > 0) {
        // get monthly prices
        let monthlyPrices = prop.property_rooms.filter(obj => {
          if (obj.property_rent_type_id !== 5) {
            return false;
          } else {
            return true;
          }
        }).map(object => {
          return object.price;
        });

        if (monthlyPrices.length > 0) {
          return true;
        } else {
          return false;
        }
      }
    },
    calculateMonthPriceRange(prop) {
      if (prop.property_rooms && prop.property_rooms.length > 0) {
        let maxMonthlyPrice = null;
        let minMonthlyPrice = null;

        // get monthly prices
        let monthlyPrices = prop.property_rooms.filter(obj => {
          if (obj.property_rent_type_id !== 5) {
            return false;
          } else {
            return true;
          }
        }).map(object => {
          return object.price;
        });

        if (monthlyPrices.length > 0) {
          maxMonthlyPrice = Math.max.apply(null, monthlyPrices);
          minMonthlyPrice = Math.min.apply(null, monthlyPrices);
        }

        if (minMonthlyPrice && maxMonthlyPrice) {
          if (minMonthlyPrice == maxMonthlyPrice) {
            return this.convertNumberToCommas(minMonthlyPrice);
          } else {
            return this.convertNumberToCommas(minMonthlyPrice) + ' - ' + this.convertNumberToCommas(maxMonthlyPrice);
          }
        }
      }
    },
    // End Helpers for LET ads to reflect short-term and long-term lets data
    navigateTo(url, page = null) {
      let propList = document.getElementById('mypropModal');
      if (propList) {
        propList.scrollTo({top: 0, behavior: 'smooth'});
      }
      this.loading = true;
      if (page) {
        url = this.linksData.first.substring(0, this.linksData.first.lastIndexOf('=') + 1) + page;
      }
      axios.post(url, this.$store.state.searchFilters.searchFilters).then(({data})=>{
        // I should really trigger setProperties from vuex
        this.$store.state.properties.properties = data;
        this.calculatePagination();
      }).catch(({response})=>{
        alert(response.data.message)
      }).finally(()=> {
        this.processing = false
        this.loading = false;
      })
    },
    previous() {
      if (this.linksData.prev) {
        this.navigateTo(this.linksData.prev)
      }
    },
    next() {
      if (this.linksData.next) {
        this.navigateTo(this.linksData.next)
      }
    },
    getImage(file_name) {
      try {
        return require(`@/assets/${file_name}`);
      } catch (e) {
        return false
      }
    },
    setDefaultImg(e) {
      e.target.src = require(`@/assets/no-image-found.png`);
    },
    toggleReportType(id) {
      let el = document.getElementById("actionPropertyDropdownReportTypeMenu"+id);
      if (el && el.classList.contains('showReportTypeMenu')) {
        el.classList.toggle('showReportTypeMenu');
        el.classList.toggle('show');
      } else if (el && !el.classList.contains('showReportTypeMenu')) {
        el.classList.toggle('showReportTypeMenu');
        el.classList.toggle('show');
      }
    },
    async reportAdvertise(id, type, report_type) {

      if (!confirm("Are you sure you want to report this advertise?")) {
        return;
      }
      this.processing = true;

      // Create form: type should be either Property or Service
      let requestForm = new FormData();
      requestForm.append("id", id);
      requestForm.append("type", type);
      requestForm.append("report_type", report_type);

      await axios.post("api/report/", requestForm).then(response => {
        this.validationSuccess = response.data.success;
        this.showReportConfirmation = true;
        const timeout = setTimeout(() => {
          this.showReportConfirmation = false;
          this.validationSuccess = null;
          clearTimeout(timeout);
        }, 4000);
        this.validationErrors = null;
      }).catch(({response}) => {
        if (response && response.data && response.data.errors) {
          this.validationErrors = response.data.errors;
          // this.validationMessage = data.message;
        }
      }).finally(() => {
        this.processing = false

        // Refresh user data so we get new favourites in the listing
        if (this.validationErrors === null) {
          this.$root.$emit('refreshUserProperties', null, this.$store.state.auth.user.id, 15);
        }
      })
    },
    openPropDetailsModal(id, index) {
      this.activePropertyDetailsModal = true;
      this.propDetailsId = id;
      // Set active prop thumbnail (white bg under card)
      this.setActivePropertyDetailsThumbnail(id);
      document.getElementById("propDetailsModal").style.transition = "all 0.4s";
      document.getElementById("propDetailsModal").style.opacity = '1';
      document.getElementById("propDetailsModal").style.visibility = "visible";
      // document.getElementById("propDetailsModal").style.width = "65vw";
      document.getElementById("propDetailsModal").style.border = "1px solid rgba(0,0,0,.125)";
      document.getElementById("propDetailsModal").style.boxShadow = "0rem 1rem 1rem rgba(0,0,0,.25)";
      document.getElementById("propDetailsModalCloseButton").style.visibility = "visible";
      document.getElementById("adDetailsChevronCloseBtn").style.visibility = "visible";
      let closeBtn = document.getElementById("propDetailsModalCloseButton");
      if (closeBtn && !closeBtn.classList.contains("pulse-animation-once")) {
        closeBtn.classList.toggle("pulse-animation-once");
      }
    },
    closePropDetailsModal() {
      let closeBtn = document.getElementById("propDetailsModalCloseButton");
      if (closeBtn && closeBtn.classList.contains("pulse-animation-once")) {
        closeBtn.classList.toggle("pulse-animation-once");
      }
      document.getElementById("propDetailsModal").style.transition = "all 0.4s";
      document.getElementById("propDetailsModal").style.opacity = '0';
      document.getElementById("adDetailsChevronCloseBtn").style.visibility = "hidden";
      document.getElementById("propDetailsModalCloseButton").style.visibility = "hidden";
      document.getElementById("propDetailsModal").style.visibility = "hidden";
      // document.getElementById("propDetailsModal").style.width = "0";
      document.getElementById("propDetailsModal").style.border = "none";
      document.getElementById("propDetailsModal").style.boxShadow = "none";
      this.propDetailsId = null;
      this.activePropertyDetailsModal = false;
      // Set inactive prop thumbnail (white bg under card)
      this.activePropertyDetailsThumbnail = undefined;
    },
    setActivePropertyDetailsThumbnail(id) {
      this.activePropertyDetailsThumbnail = id;
    },
    preventParentClick() {
      // Do nothing, its just used to prevent parent function trigger (setActivePropertyDetailsThumbnail)
    },
    checkIfLastIndex(index){
      return index === this.$store.state.properties.properties.data.length - 1;
    },
    calculateBeds(propDetails) {
      return Number(propDetails.single_room) + Number(propDetails.double_room) + Number(propDetails.twin_room) + Number(propDetails.twin_double_room) +
          Number(propDetails.triple_room) + Number(propDetails.quadruple_room) + Number(propDetails.family_room) + Number(propDetails.studio_room);
    },
    calculateSuiteBeds(propDetails) {
      return Number(propDetails.single_room_suite) + Number(propDetails.double_room_suite);
    },
    calculateParking(driveway = 0, garage = 0, parking = 0, parkingSpaces = 0) {
      if (parking && parkingSpaces > 0) {
        return Number(driveway) + Number(garage) + Number(parkingSpaces);
      } else {
        return Number(driveway) + Number(garage) + Number(parking);
      }
    },
    calculateGarden(frontGarden, rearGarden) {
      return frontGarden + rearGarden;
    },
    calculateCreatedDate(date) {
      let resultDate = null;

      let currentDate = new Date();
      let propDate = new Date(date);
      let numberOfDays = (currentDate.getTime() - propDate.getTime()) / (1000*60*60*24);

      switch (true) {
        case numberOfDays <= 1:
          resultDate = "Today";
          break;
        case (numberOfDays <= 30 && numberOfDays > 1):
          if(numberOfDays < 2) {
            resultDate = Math.round(numberOfDays)+" day ago";
          } else {
            resultDate = Math.round(numberOfDays)+" days ago";
          }
          break;
        case numberOfDays > 30:
          let numberOfMonths = Math.round(numberOfDays / 30);
          if(numberOfMonths === 1) {
            resultDate = numberOfMonths+" month ago";
          } else {
            resultDate = numberOfMonths+" months ago"
          }
          break;
      }

      return resultDate;

    },
    convertNumberToCommas(num) {
      if (num && num !== '' && num !== null) {
        return num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return 0;
      }
    },
    calculatePagination() {
      if(this.metaData && this.linksData) {

        // Current page any but total pages less than 10
        if (this.metaData.last_page < 10) {
          this.paginationData = this.metaData.last_page
        }

        // Current page 2 or less and last_page pages more than 10
        if (this.metaData.current_page <= 2 && this.metaData.last_page >= 10) {
          this.paginationData = [1, 2, 3, '...', this.metaData.last_page - 1, this.metaData.last_page]
        }

        // Current page 3 and last_page pages more than 10
        if (this.metaData.current_page === 3 && this.metaData.last_page >= 10) {
          this.paginationData = [1, 2, 3, 4, '...', this.metaData.last_page - 1, this.metaData.last_page]
        }

        // Current page 4 and last_page pages more than 10
        if (this.metaData.current_page === 4 && this.metaData.last_page >= 10) {
          this.paginationData = [1, 2, 3, 4, 5, '...', this.metaData.last_page - 1, this.metaData.last_page]
        }

        // When page 5 and last_page pages more than 10
        if (this.metaData.current_page === 5 && this.metaData.last_page >= 10) {
          this.paginationData = [1, 2, 3, this.metaData.current_page - 1, this.metaData.current_page, this.metaData.current_page + 1, '...', this.metaData.last_page - 1, this.metaData.last_page]
        }

        // When page 6 or more and last_page pages more than 10
        if (this.metaData.current_page >=6 && this.metaData.last_page >= 10) {
          this.paginationData = [1, 2, 3, '...', this.metaData.current_page - 1, this.metaData.current_page, this.metaData.current_page + 1, '...', this.metaData.last_page - 1, this.metaData.last_page]
        }

        // When page almost or equal last_page which is more than 10
        if (this.metaData.last_page >= 10 &&
            (this.metaData.current_page === this.metaData.last_page ||
                this.metaData.current_page === this.metaData.last_page - 1 ||
                this.metaData.current_page === this.metaData.last_page - 2
            )
        ) {
          this.paginationData = [1, 2, 3, '...', this.metaData.last_page - 3, this.metaData.last_page - 2, this.metaData.last_page - 1, this.metaData.last_page]
        }
      }
    },
    async calculatePage(id) {
      let page = 1;

      for (let i=0; i<this.$store.state.properties.properties_map.data.length; i++) {
        if (id === this.$store.state.properties.properties_map.data[i].id) {
          /*
          Problem here is with the wrong calculations as shown below, that's why we need at first Math.ceil and later Math.floor
          index 0 - id 1    0/3 = 0 (so we get 1 correct)
          index 1 - id 2    1/3 = 0.x (ceil makes it 1 correct)
          index 2 - id 3    2/3 = 0.x (ceil makes it 1 correct
          index 3 - id 4    3/3 = 1 (wrong, we need to make it 2)
          index 4 - id 5    4/3 = 1.x (ceil makes it 2 correct)
          index 5 - id 6    5/3 = 1.x (ceil makes it 2 correct)
          index 6 - id 7    6/3 = 2 (wrong, we need to make it 3)
           */
          page = Math.ceil(i/15) < 1 ? 1 : Math.floor(i/15) + 1;

          let url = '';
          if (this.linksData.next) {
            url = this.linksData.next.substring(0, this.linksData.next.lastIndexOf('=') + 1) + page;
          } else if (this.linksData.prev) {
            url = this.linksData.prev.substring(0, this.linksData.prev.lastIndexOf('=') + 1) + page;
          }

          if (url) {
            await this.navigateTo(url);
          }
        }
      }
    }
    // async getPage(id) {
    //   await this.calculatePage(id);
    // },
  },
  created: function () {
    this.$root.$on('openPropDetailsGlobal', (id, index) => {
      this.openPropDetailsModal(id, index);
    });
    this.$root.$on('openPropDetailsComponent', (id, index) => {

      // Calculate on which listing page is advertise and open this listing page
      this.calculatePage(id)

      this.openPropDetailsModal(id, index);
      // On list scroll into view the opened property
      let el = document.getElementById('prop-' + id); // index
      if (el) {
        el.scrollIntoView({behavior: "smooth", block: "center"} );
      }
    });
    // axios.get('api/properties',this.properties).then(({data})=>{
    //   this.properties = data['data']
    // }).catch(({response:{data}})=>{
    //   alert(data.message)
    // }).finally(()=>{
    //   this.processing = false
    // })
  },
  beforeDestroy() {
    // Must destroy global trigger before we leave the page/component, otherwise it will layer on each other
    // and when we call it, it will trigger several times, breaking functionality
    // this.$root.$off('openPropDetailsGlobal');
    // this.$root.$off('openPropDetailsComponent');
  },
  watch: {
    '$store.state.properties.loading': function() {
      this.loading = this.$store.state.properties.loading;
    },
    '$store.state.properties.properties': function() {
      this.metaData = this.$store.state.properties.properties.meta;
      this.linksData = this.$store.state.properties.properties.links;
      if (
          this.$store.state.properties.properties &&
          this.$store.state.properties.properties.data &&
          this.$store.state.properties.properties.data.length > 0
      ) {
        this.noData = false;
        this.pagination = true;
        this.calculatePagination();
      } else {
        this.noData = true;
        this.pagination = false;
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.category-label-column:hover {
  z-index: 9999;
  transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
}
.category-label {
  border: 1px solid #868e96;
  border-radius: 10px;
  width: 100px;
  min-width: 100px;
  overflow: hidden;
  white-space: nowrap;
  transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  &:hover {
    transition: 0.5s;
    -moz-transition: 0.5s;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    width: fit-content;
    overflow: visible;
  }
}

/* Start Action Buttons */
.properties .action-dropdown {
  z-index: 9999;
  min-height: 30px;
  text-align: end;
  background-color: rgba(255, 255, 255 , 0);
  color: #868e96;
  cursor: pointer;

  .dropdown-menu {
    top: unset;
    right: 5%;
    //right: unset;
    //left: unset;
    //left: unset;
    //right: unset;
  }
  .btn-action-dropdown {
    border: none;
    border-radius: 50%;
    background-color: #e9ecef;
    cursor: pointer;
  }
  .btn-action-dropdown:hover {
    cursor: pointer;
  }
}

.action-dropdown:hover {
  cursor: pointer;
}

.action-buttons {
  z-index: 1001;
  .action-item {
    padding: 2px 5px 2px 5px;
    margin: 2px 5px 2px 5px;
    min-width: 80px;
    font-size: 13px;
  }

  .action-button-report {
    text-align: start;
    min-height: 30px;
    width: 100%;
    background-color: unset;
    border: none;
    //border-bottom: 3px solid deepskyblue;
    min-width: 80px;
    transition: 0.5s;
    -moz-transition: 0.5s;
    transform: scale(1);
  }

  .action-button-report:hover {
    background-color: unset;
    border: none;
    transition: 0.5s;
    -moz-transition: 0.5s;
    transform: scale(1.1);
  }

  .action-button-report:hover .icon-report {
    color: red;
    transition: 0.5s;
    -moz-transition: 0.5s;
  }

  .action-button-spam {
    text-align: start;
    min-height: 30px;
    width: 100%;
    background-color: unset;
    border: none;
    //border-bottom: 3px solid deepskyblue;
    min-width: 80px;
    transition: 0.5s;
    -moz-transition: 0.5s;
    transform: scale(1);
  }

  .action-button-spam:hover {
    background-color: unset;
    border: none;
    transition: 0.5s;
    -moz-transition: 0.5s;
    transform: scale(1.1);
  }

  .action-button-spam:hover .icon-spam {
    color: mediumpurple;
    transition: 0.5s;
    -moz-transition: 0.5s;
  }

  .action-button-scam {
    text-align: start;
    min-height: 30px;
    width: 100%;
    background-color: unset;
    border: none;
    //border-bottom: 3px solid deepskyblue;
    min-width: 80px;
    transition: 0.5s;
    -moz-transition: 0.5s;
    transform: scale(1);
  }

  .action-button-scam:hover {
    background-color: unset;
    border: none;
    transition: 0.5s;
    -moz-transition: 0.5s;
    transform: scale(1.1);
  }

  .action-button-scam:hover .icon-scam {
    color: mediumblue;
    transition: 0.5s;
    -moz-transition: 0.5s;
  }

  .action-button-abuse {
    text-align: start;
    min-height: 30px;
    width: 100%;
    background-color: unset;
    border: none;
    //border-bottom: 3px solid deepskyblue;
    min-width: 80px;
    transition: 0.5s;
    -moz-transition: 0.5s;
    transform: scale(1);
  }

  .action-button-abuse:hover {
    background-color: unset;
    border: none;
    transition: 0.5s;
    -moz-transition: 0.5s;
    transform: scale(1.1);
  }

  .action-button-abuse:hover .icon-abuse {
    color: red;
    transition: 0.5s;
    -moz-transition: 0.5s;
  }
}

.showReportTypeMenu {
  display: block;
  right: 0 !important;
  top: 100% !important;
  transition: 0.5s;
  -moz-transition: 0.5s;
}

.success-msg {
  position: fixed;
  top: 20%;
  left: 10%;
  z-index: 9999;
}

/* End Action Buttons */

a.ad-details-closebtn {
  position: fixed;
  padding: 0 !important;
  width: 28px;
  height: 28px;
  top: 50%;
  left: 30.4%;
  font-size: 24px !important;
  z-index: 9999;
  visibility: hidden;
  transition: 0.7s;
  -webkit-transition-duration: 0.7s;
  color: #198754;
  border-radius: 50% 0 0 50%;
  //border: 2px solid rgba(0, 0, 0, .125);
  border-right: none !important;
  border-left: 1px solid rgba(0, 0, 0, 0.125) !important;
  border-top: 1px solid rgba(0, 0, 0, 0.125) !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
  line-height: 0;
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);

  :hover {
    color: red;
    transition: 0.7s;
    -webkit-transition-duration: 0.7s;
  }
}

a.ad-details-closebtn .tooltiptext:hover {
  color: white !important;
}

a.ad-details-closebtn #adDetailsChevronCloseBtn {
  position: absolute;
  top: 5%;
  left: 10%;
}

/* Double Chevron Animation Once */
.pulse-animation-once {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse 2s;
  animation-iteration-count: 2;
}
/* End Double Chevron Animation Once */

/* Start Text truncate - white space - for long texts and icons */
.property-info-list-relative {
  width: 100%;
  min-height: 25px;
  position: relative;
}

.list-relative {
  width: 100%;
  position: relative;
}

.text-truncate-item {
  width: 100%;
  box-sizing: border-box;
  transition: 0.7s;
  border-radius: 5px;
}

.text-truncate-item:hover {
  position: absolute;
  transition: 0.7s;
  box-sizing: border-box;
  overflow: visible !important;
  white-space: normal !important;
  background-color: white;
  border: 1px solid black;
  border-radius: 5px;
  padding: 0 0 0 1px;
  z-index: 1000;
}
/* End Text truncate - white space - for long texts and icons */

.properties {
  position: absolute;
  z-index: 99;
  min-height: 81vh;
  //height: 81vh;
  background-color: rgba(255, 255, 255, 85%);
  padding-bottom: 8%;
  .property-details {
    border-radius: 15px;
    transition: 0.5s;
    margin: 5px 0px 5px 0px;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
  }
  .active-property-details {
    background-color: white;
    transition: 0.5s;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
  }
  /* change transition duration to control the speed of fade effect */
  .carousel-item {
    transition: transform 1s ease-in-out;
  }

  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: opacity 0s 1s;
  }
}

.container-list-properties {
  width: 100%;
  height: 81vh;
  margin: auto;
  .row {
    margin: auto;
  }
  .col-6 {
    padding: 0;
  }
  .col-md-6 {
    padding: 0;
  }
}

/* Property Details Modal */

/* Fade effect for transition elements */
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.modal-tab::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.modal-tab {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* Ad Details Modal */
.propDetailsModal {
  height: 85vh;
  opacity: 0;
  visibility: hidden;
  width: 67vw;
  position: fixed;
  z-index: 1;
  top: 10.5vh;
  left: 32vw;
  //background-color: rgba(33, 150, 243, 50%);
  background-color: rgba(255, 255, 255, 70%);
  overflow-x: hidden;
  transition: 0.5s;
  border-radius: 10px;
  background-clip: border-box;
}

.propDetailsModal a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: black;
  display: block;
  transition: 0.3s;
}

.propDetailsModal a:hover {
  color: #818080;
}

//.propDetailsModal .closebtn {
//  position: absolute;
//  top: 50%;
//  left: 0;
//  font-size: 36px;
//  margin-left: 50px;
//}

.propDetailsModalStickyTop {
  width: 100%;
  height: 30px;
  transition: 0.5s;
  background-color: rgba(255, 255, 255, 70%);
}
.propDetailsModalStickyTop:hover {
  transition: 0.5s;
  background-color: rgba(255, 255, 255, 100%);
}

/* End of Ad Details Modal */

.property-info {
  cursor: pointer;
  font-size: 13px;
  //font-weight: bold;
}

.property-icons {
  --bs-gutter-x: 0;
  ul {
    margin: 0;
  }
}

.custom-col-length {
  .col-3 {
    width: 50% !important;
  }
}

/*
  START
  change some css so that images are always sized same
  use media queries to adjust that for mobile and other devices
*/

.property-thumbnails {
  .thumbnails-img {
    width: 100%;
    cursor: pointer;
    height: auto;
    max-height: 200px;
    min-height: auto;
    //max-height: 200px !important;
    //min-height: 200px !important;
  }
  .carousel-inner{
    width:100%;
    border-radius: 20px;
    .auction-caption {
      background-color: white;
      text-align: left;
      min-width: 80px;
      max-width: 80px;
      min-height: 25px;
      max-height: 25px;
      border-radius: 5% 5% 100% 5%;
      padding: 5px 5px 5px 10px;
      font-size: 11px;
      position: absolute;
      top: -1px;
      bottom: auto;
      left: -1px;
      z-index: 10;
    }
    .cash-caption {
      background-color: white;
      text-align: right;
      min-width: 80px;
      max-width: 80px;
      min-height: 25px;
      max-height: 25px;
      border-radius: 5% 5% 5% 100%;
      padding: 5px 3px 5px 5px;
      font-size: 11px;
      position: absolute;
      top: -1px;
      bottom: auto;
      right: 0;
      z-index: 10;
    }
    .coming-soon-caption {
      background-color: gold;
      color: black;
      text-align: center;
      min-width: 100%;
      max-width: 100%;
      min-height: 25px;
      max-height: 25px;
      border-radius: 100% 100% 3% 3%;
      padding: 5px 5px 5px 10px;
      font-size: 11px;
      position: absolute;
      //top: -1px;
      bottom: 0;
      left: -1px;
      z-index: 10;
    }
  }
  .property-details-section {
    .col-8 {
      width: 75% !important;
    }
  }
  .property-details-section *:hover {
    cursor: pointer !important;
  }
}
/*
  END
  change some css so that images are always sized same
  use media queries to adjust that for mobile and other devices
*/

/* start tooltip - popups info on icons */
.tooltiptext {
  visibility: hidden;
  width: auto;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  text-align: center;
  font-size: 10px;
  border-radius: 6px;
  padding: 5px 5px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1000;
}

[data-tooltip]:hover .tooltiptext {
  visibility: visible;
}
/* end tooltip - popups info on icons */

.pagination {
  cursor: pointer;
}

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
  .propDetailsModal a {
    font-size: 18px;
  }
}
@media screen and (max-width: 500px) {
  a.ad-details-closebtn {
    left: 1%;
  }
  /* Ad Details Modal */
  .propDetailsModal {
    width: 90vw;
    height: 80vh;
    top: 15vh !important;
    left: 5vw;
    z-index: 10;
  }
  .property-thumbnails {
    .thumbnails-img {
      min-height: 200px !important;
    }
  }
}
// 768px
@media screen and (min-width: 501px) {
  a.ad-details-closebtn {
    left: 2.3%;
  }
  /* Ad Details Modal */
  .propDetailsModal {
    width: 90vw;
    height: 80vh;
    top: 15vh !important;
    left: 5vw;
    z-index: 10;
  }
  .property-thumbnails {
    .thumbnails-img {
      min-height: 300px !important;
    }
  }
}
@media screen and (min-width: 769px) {
  a.ad-details-closebtn {
    left: 2.3%;
  }
  /* Ad Details Modal */
  .propDetailsModal {
    width: 90vw;
    height: 80vh;
    top: 15vh !important;
    left: 5vw;
    z-index: 10;
  }
  .property-thumbnails {
    .thumbnails-img {
      min-height: 400px !important;
    }
  }
}
@media screen and (min-width: 992px) {
  a.ad-details-closebtn {
    left: 39%;
  }
  /* Ad Details Modal */
  .propDetailsModal {
    width: 58vw;
    height: 84vh;
    top: 10.5vh !important;
    left: 41vw;
  }
  .property-thumbnails {
    .thumbnails-img {
      min-height: 200px !important;
    }
  }
}
@media screen and (min-width: 1200px) {
  a.ad-details-closebtn {
    left: 30.4%;
  }
  /* Ad Details Modal */
  .propDetailsModal {
    width: 67vw;
    height: 84vh;
    top: 10.5vh !important;
    left: 32vw;
  }
  .property-thumbnails {
    .thumbnails-img {
      min-height: 200px !important;
    }
  }
}
@media screen and (min-width: 1600px) {
  a.ad-details-closebtn {
    left: 30.6%;
  }
}
@media screen and (min-width: 1920px) {
  a.ad-details-closebtn {
    left: 31%;
  }
  /* Ad Details Modal */
  .propDetailsModal {
    width: 67vw;
    height: 84vh;
    top: 10.5vh !important;
    left: 32vw;
  }
  .property-thumbnails {
    .thumbnails-img {
      min-height: 300px !important;
    }
  }
}


</style>
