<template>
  <div class="map">
    <!-- Loading -->
    <Loader :loading="loading" :small="false"></Loader>
    <div id="minimapid" :class="customClass ? customClass : ''"></div>
  </div>
</template>

<script>
import Loader from "./Loader.vue"
import {mapActions} from 'vuex';
import mapboxgl from "mapbox-gl";
/* Map Box Draw */
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
/* End of Map Box Draw */
/* Turf */
import * as turf from '@turf/turf'

export default {
  name: 'MiniMap',
  components: {
    'Loader': Loader
  },
  props: {
    customClass: String,
    lat: [String, Number],
    lng: [String, Number],
    propCategory: String
    // map: null,
  },
  data(){
    return {
      eventSet: false,
      map: null,
      markers: [],
      drawbboxs: [],
      lastSearchLocation: '',
      loading: true
    }
  },
  methods: {
    initializeMap() {
      /* MapBox Map */
      mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_ACCESS_TOKEN;

      // Check if searchFilters location exist
      let lat = this.lat ? this.lat : '51.507321899999994';
      let lng = this.lng ? this.lng : '-0.12764739999999997';

      const map = new mapboxgl.Map({
        container: 'minimapid',
        style: 'mapbox://styles/mapbox/streets-v11',
        center: {lat:lat,lng:lng},
        location: '',
        zoom: this.propCategory && this.propCategory === 'Service' ? 10 : 15,
        maxZoom: 20
      });


      // Initialize the geolocate control.
      let geolocate = new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true
        },
        trackUserLocation: true
      });
      // Add the geolocate control to the map.
      map.addControl(geolocate, 'bottom-left');
      // Activates geolocation on map load
      map.on('load', function() {
        geolocate.trigger();

        /* Legend Data */
        const propCategories = [
          'Residential',
          'Commercial',
          'Industrial',
          'Land',
          'Luxury',
          'Special Use',
          'Room',
          'Multiple',
          'Service'
        ];
        const colors = [
          '#00bfff', // deepskyblue
          '#ff0000', // red
          '#0000ff', // blue
          '#00ff00', // lawngreen
          '#d986f3', // rgb(217, 134, 243)
          '#a52a2a', // saddlebrown
          '#ffff00', // yellow
          '#00bfff', // deepskyblue
          'linear-gradient(to right, red,orange,yellow,green,blue,indigo,violet)'
        ];
      });

      // Initialize navigation control.
      let nav = new mapboxgl.NavigationControl();
      // Add the navigation control to the map
      map.addControl(nav, 'bottom-right');

      this.map = map;

      // Set Marker
      this.setMarker(map);
      /* End MapBox Map */

    },
    setMarker(map) {
      this.$nextTick(async () => {
        // Remove current markers
        if (this.markers && this.markers.length > 0) {
          for (let i=0; i<this.markers.length; i++) {
            this.markers[i].remove();
          }
        }

        let jsonMarkersArr = [];
        let singularMarkerJson = [];
        let postType = '';

        // console.log(this.$store.state.properties.properties_map.data[i].documents_property_images);
        singularMarkerJson = {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [this.lng, this.lat]
          }
        }
        jsonMarkersArr.push(singularMarkerJson);

        for (let i = 0; i < jsonMarkersArr.length; i++) {
          if (jsonMarkersArr[i] != null) {
            // Singular Markers
            const el = document.createElement('div');

            // Apply different icon styling based on property category (classes are in helpers.scss)
            if (this.propCategory && this.propCategory === 'Residential') {
              el.className = 'marker-' + i + ' marker-residential';
            }
            if (this.propCategory && this.propCategory === 'Commercial') {
              el.className = 'marker-' + i + ' marker-commercial';
            }
            if (this.propCategory && this.propCategory === 'Industrial') {
              el.className = 'marker-' + i + ' marker-industrial';
            }
            if (this.propCategory && this.propCategory === 'Land') {
              el.className = 'marker-' + i + ' marker-land';
            }
            if (this.propCategory && this.propCategory === 'Luxury') {
              el.className = 'marker-' + i + ' marker-luxury';
            }
            if (this.propCategory && this.propCategory === 'Special Use') {
              el.className = 'marker-' + i + ' marker-special-use';
            }
            if (this.propCategory && this.propCategory === 'Room') {
              el.className = 'marker-' + i + ' marker-room';
            }
            if (this.propCategory && this.propCategory === 'Service') {
              el.className = 'marker-' + i + ' marker-residential';
            }

            let marker = new mapboxgl.Marker(el)
                .setLngLat(jsonMarkersArr[i].geometry.coordinates)
                .setRotation(-45)
                .addTo(map);

            // Assign markers to global scope of this component
            if (marker) {
              this.markers.push(marker);
            }
          }
        }
      });
    },
    // End Helpers for LET ads to reflect short-term and long-term lets data
    convertNumberToCommas(num) {
      if (num && num !== '' && num !== null) {
        return num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return '0';
      }
    },
    setDefaultImg(e) {
      e.target.src = require(`@/assets/no-image-found.png`);
    },
  },
  async mounted() {
    this.loading = true;
    await this.initializeMap();
    this.loading = false;
  },
  watch: {

  }
}
</script>
<!-- Second style is just for keyframes and animations, for time being they do not work with style scoped -->
<style lang="scss">
@keyframes test {
  from { opacity: 0.1; }
}

.blinking {
  animation: test 1s infinite alternate !important;
}
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.hideLegend {
  z-index: 1000;
  .btn-box-eye {
    display: block;
    align-content: center;
    vertical-align: middle;
    height: 100%;
    width: auto;
  }

  .btn-eye {
    color: white;
    background-color: white;
    line-height: 30px;
    font-size: 26px;
    border-radius: 50%;
    padding: 1px 5px;
    border: 0;
  }

  .btn-eye:hover {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    transform: scale(1);
    animation: pulse 2s infinite;
  }
}

.showLegend {
  display: none;
  position: absolute;
  top: 30vh;
  right: 1vh;
  z-index: 1000;
  .btn-box-eye {
    display: block;
    align-content: center;
    vertical-align: middle;
    height: 100%;
    width: auto;
  }

  .btn-eye {
    color: white;
    background-color: white;
    line-height: 30px;
    font-size: 26px;
    border-radius: 50%;
    padding: 1px 5px;
    border: 0;
  }

  .btn-eye:hover {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    transform: scale(1);
    animation: pulse 2s infinite;
  }
}

/* Start Map Legend */
.map-overlay {
  position: absolute;
  bottom: 40%;
  right: 0;
  background: #fff;
  margin-right: 10px;
  font-family: Arial, sans-serif;
  overflow: auto;
  border-radius: 3px;
  z-index: 3;
}

#legend {
  display: block;
  text-align: left;
  padding: 10px;
  box-shadow: 0 0 0 2px rgb(0 0 0 / 10%);
  line-height: 18px;
  height: 200px;
  margin-bottom: 40px;
  width: 150px;
}
/* End Map Legend */

.category-label {
  border: 1px solid #868e96;
  border-radius: 10px;
  width: 100px;
  min-width: 100px;
}

.animated-icon{
  width: 160px;
  height: 160px;
  background-color: rgba(255,255,255,100%);
  border-radius: 50%;
  box-shadow: 0px 0px 4px white;
  transition: all 1s;
}

.animated-icon:hover {
  transform: scale(1.2) !important;
  transition: all 1s;
}

#minimapid {
  display: block;
  width: 100px;
  height: 200px;
  min-width: 100%;
  min-height: 200px;
  background-color: white;
}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.map {
  width: 100%;
  height: 100%;
}

.mt--1 {
  margin-top: -16px;
}

.mt--2 {
  margin-top: -32px;
}

.mt--3 {
  margin-top: -48px;
}

.mt--4 {
  margin-top: -64px;
}

.mt--5 {
  margin-top: -80px;
}

</style>
