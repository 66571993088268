<template>
  <ConfirmationPopup ref="popup">
<!--    <h2 style="margin-top: 0">{{ title }}</h2>-->
    <div class="modal-header">
      {{ title }}
    </div>
    <div class="modal-content">
      <p>{{ message }}</p>
      <div v-if="allowCustomMsg">
        <div class="form-group col-12">
          <div class="input-group input-group-sm mb-3">
              <textarea v-model="customMsg"
                        name="customMessage"
                        id="customMessage"
                        placeholder="Optional message"
                        rows="6"
                        cols="50"
                        maxlength="1000"
                        class="form-control"
                        aria-describedby="sendBtnConfirmation"
                        @keydown.enter.shift.exact.prevent="customMsg += '\n'"
              />
          </div>
        </div>
      </div>
      <div class="btns">
        <button class="btn btn-outline-danger" @click="_cancel">{{ cancelButton }}</button>
        <button class="btn btn-outline-success" @click="_confirm">{{ okButton }}</button>
      </div>
    </div>
  </ConfirmationPopup>
</template>

<script>
import ConfirmationPopup from './ConfirmationPopup.vue'

export default {
  name: 'ConfirmationModal',
  props: ['data', 'refresh'],
  components: { ConfirmationPopup },

  data: () => ({
    // Parameters that change depending on the type of dialogue
    title: undefined,
    message: undefined, // Main text content
    okButton: undefined, // Text for confirm button; leave it empty because we don't know what we're using it for
    cancelButton: 'Go Back', // text for cancel button
    allowCustomMsg: false,

    // Private variables
    resolvePromise: undefined,
    rejectPromise: undefined,

    // Custom Msg
    customMsg: ''
  }),
  methods: {
    show(opts = {}) {
      this.title = opts.title
      this.message = opts.message
      this.okButton = opts.okButton
      if (opts.cancelButton) {
        this.cancelButton = opts.cancelButton
      }
      if (opts.allowCustomMsg) {
        this.allowCustomMsg = opts.allowCustomMsg
      } else {
        this.allowCustomMsg = false;
        this.customMsg = '';
      }
      // Once we set our config, we tell the popup modal to open
      this.$refs.popup.open()
      // Return promise so the caller can get results
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },

    _confirm() {
      this.$refs.popup.close()
      if (this.customMsg) {
        this.resolvePromise(this.customMsg)
      } else {
        this.resolvePromise(true)
      }
    },

    _cancel() {
      this.$refs.popup.close()
      this.resolvePromise(false)
      // Or you can throw an error
      // this.rejectPromise(new Error('User cancelled the dialogue'))
    },
  },
  watch: {
    refresh: {
      handler: async function (val) {
        this.loading = true;
        // Action if necessary
        this.loading = false;
      }, deep: true, immediate: true
    },
  }
}
</script>

<style scoped lang="scss">
.btns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.modal-header {
  font-size: 13px;
  color: white;
  border-radius: 10px 10px 0 0;
  background: rgba(128, 0, 128, 0.7);
  padding: .5rem;
  text-transform: uppercase
}

.modal-content {
  padding: .5rem;
  border: none;
}

</style>