import axios from 'axios'

export default {
    namespaced: true,
    state: {
        loading: false,
        notifications: null,
    },
    getters: {
        loading(state){
            return state.loading
        },
        notifications(state){
            return state.notifications
        }
    },
    mutations: {
        SET_LOADING (state, value) {
            state.loading = value
        },
        SET_NOTIFICATIONS (state, value) {
            state.notifications = value
        }
    },
    actions: {
        setNotifications({ commit }, payload) {
            commit('SET_LOADING', true)
            commit('SET_NOTIFICATIONS', payload);
            commit('SET_LOADING', false)
        }
    }
}