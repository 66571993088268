<template>
  <span>
    <ul class="list-unstyled d-inline-block">
      <li :key="star" v-for="star in stars" class="star d-inline-block">
        <!-- Full Star -->
        <font-awesome-icon v-if="star <= rating" icon="star" class="star-icon" style="color: yellow;"/>
        <!-- Half Star -->
        <font-awesome-icon v-if="(star > rating) && (star === Math.round(rating))" icon="star-half-alt" class="star-icon" style="color: yellow;"/>
        <!-- Empty Star -->
        <font-awesome-icon v-if="(star > rating) && (star !== Math.round(rating))" icon="star" class="star-icon" style="color: transparent;"/>
      </li>
    </ul>
    <ul v-if="showNumericRating" class="list-unstyled d-inline-block ps-1">
      <li class="d-inline-block">
        ({{ rating }})
      </li>
    </ul>
  </span>
</template>

<script>
import axios from "axios";
import ValidationErrors from "../ValidationErrors"

export default {
  name: 'ShowReviewRating',
  props: ['rating', 'showNumericRating'],
  components: {
    'ValidationErrors': ValidationErrors
  },
  data(){
    return {
      stars: 5,
      processing: false,
      validationMessage: '',
      validationErrors: '',
    }
  },
  methods: {

  }
}
</script>

<style lang="scss">

.star-icon path {
  stroke: #3d3d3d;
  stroke-width: 20;
}

</style>