<template>
  <div class="add-bookmark-container">
    <span data-tooltip="tooltip" v-on:click.stop="preventParentClick()">
      <font-awesome-icon icon="heart" :class="'bookmark-'+id" class="add-bookmark" @click="addBookmark(id, type)"/>
      <span :class="setTooltipClass">{{ favouriteExists ? 'Remove from Favourites' : 'Add to Favourites'}}</span>
    </span>
    <div v-if="this.showError" class="error-popup">
      <p class="required-info text-center">
        You must be logged in in order to use favourites feature
      </p>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {mapActions} from 'vuex'

export default {
  name: 'AddBookmark',
  // props: ['id', 'type', 'index', 'tooltipClass'],
  props: {
    id: Number,
    type: String,
    index: Number,
    tooltipClass: {
      type: String,
      default: null
    },
    preview: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: true,
      favouriteId: null,
      favouriteExists: false,
      validationErrors: null,
      showError: false,
      processing: false
    }
  },
  mounted() {
    this.checkBookmarkInDB();
  },
  computed: {
    setTooltipClass() {
      if (this.tooltipClass !== null) {
        return this.tooltipClass;
      } else {
        return 'tooltiptext';
      }
    }
  },
  methods: {
    preventParentClick() {
      // Do nothing its just to prevent clicking parent
    },
    ...mapActions({
      refreshUser:"auth/refreshUser"
    }),
    async addBookmark(id, type) {
      if (this.processing === true || this.preview === true) {
        return;
      }
      this.processing = true;
      if (this.$store.state.auth.authenticated === false) {
        this.showError = true;
        const timeout = setTimeout(() => {
          this.showError = false;
          this.processing = false;
          clearTimeout(timeout);
        }, 3000);
        return;
      }
      await this.checkBookmarkInDB();
      if (this.favouriteExists) {
        await axios.post('api/favourites/' + this.favouriteId + '/delete').then(response => {
          this.validationErrors = null;
          this.favouriteId = null;
        }).catch(({response}) => { // {response: {data}}
          if (response && response.data && response.data.errors) {
            this.validationErrors = response.data.errors;
            // this.validationMessage = data.message;
          }
        }).finally(() => {
          this.processing = false

          // Refresh user data so we get new favourites in the listing
          if (this.validationErrors === null) {
            this.refreshUser();
          }
        })
      } else {
        await axios.post('api/favourite', {id: id, type: type}).then(response => {
          this.validationErrors = null;
          this.favouriteId = response.data.id;
        }).catch(({response}) => { // {response: {data}}
          if (response && response.data && response.data.errors) {
            this.validationErrors = response.data.errors;
            // this.validationMessage = data.message;
          }
        }).finally(() => {
          this.processing = false

          // Refresh user data so we get new favourites in the listing
          if (this.validationErrors === null) {
            this.refreshUser();
          }
        })
      }
    },
    async checkBookmarkInDB() {
      if (this.$store.state.auth && this.$store.state.auth.authenticated === true && this.$store.state.auth.user.favourite_properties) {
        this.favouriteExists = false;
        // Check if bookmark exists in DB by checking if it exists in user favourites
        let count = this.$store.state.auth.user.favourite_properties.length;

        let element = 'bookmark-' + this.id;
        let el = document.getElementsByClassName(element);
        let countEl = el.length;

        for (let i=0; i<count; i++) {
          if (this.$store.state.auth.user.favourite_properties[i].property_id === this.id) {

            for (let c=0; c<countEl; c++) {
              if (el[c] && !el[c].classList.contains('existing-bookmark')) {
                el[c].classList.toggle('existing-bookmark');
              }
            }
            this.favouriteExists = true;
            this.favouriteId = this.$store.state.auth.user.favourite_properties[i].id
          }
        }

        // If bookmark was removed, toggle class
        if (!this.favouriteExists) {
          for (let c=0; c<countEl; c++) {
            if (el[c] && el[c].classList.contains('existing-bookmark')) {
              el[c].classList.toggle('existing-bookmark');
            }
          }
        }
      // If user is not logged in remove all bookmarks
      } else {
        let element = 'bookmark-' + this.id;
        let el = document.getElementsByClassName(element);
        let countEl = el.length;

        for (let c=0; c<countEl; c++) {
          if (el[c] && el[c].classList.contains('existing-bookmark')) {
            el[c].classList.toggle('existing-bookmark');
          }
        }

        this.favouriteExists = false;
        this.favouriteId = null;
      }
    }
  },
  watch: {
    '$store.state.auth': {
      handler: function (val) {
        this.loading = true;

        this.checkBookmarkInDB();

        this.loading = false;
      }, deep: true
    },
  }
}
</script>

<style scoped lang="scss">

/* start tooltip - popups info on icons */
.tooltiptext {
  visibility: hidden;
  width: auto;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  text-align: center;
  font-size: 10px;
  border-radius: 6px;
  padding: 5px 5px;

  /* Position the tooltip */
  position: absolute;
  left: 40%;
  top: 20px;
  z-index: 1000;
}

.tooltiptext-right {
  visibility: hidden;
  width: auto;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  text-align: center;
  font-size: 10px;
  border-radius: 6px;
  padding: 5px 5px;

  /* Position the tooltip */
  position: absolute;
  left: 10px;
  top: -20px;
  z-index: 1000;
}

[data-tooltip]:hover .tooltiptext {
  visibility: visible;
  color: #fff;
}

[data-tooltip]:hover .tooltiptext-right {
  visibility: visible;
  color: #fff;
}
/* end tooltip - popups info on icons */

.add-bookmark-container {
  width: 100%;
  height: auto;
}

.add-bookmark {
  transform: scale(1);
  transition: 0.5s;
  -moz-transition: 0.5s;
  color: limegreen;
}

.add-bookmark path {
  stroke: black;
  stroke-width: 20;
}

.add-bookmark:hover {
  color: red;
  transform: scale(1.1);
  transition: 0.5s;
  -moz-transition: 0.5s;
}

.existing-bookmark {
  color: red !important;
}

/* Error Message */
.error-popup {
  position: fixed;
  max-width: 150px;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  padding: 5px;
  z-index: 99999;
}

</style>