<template>
  <div class="cookie-consent">
    <div class="row p-0 m-0">
      <div class="col-8 p-0 m-0">
        This platform uses cookies to ensure you get best possible experience on Easetate.
        We strongly encourage to accept cookies for correct functionality of the platform.
      </div>
      <div class="col-4 p-0 m-0">
        <button type="button" @click.prevent="accept()" class="btn btn-outline-light me-3">Accept</button>
        <button type="button" @click.prevent="decline()" class="btn btn-outline-light">Decline</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CookieConsent',
  data () {
    return {
    }
  },
  methods: {
    accept() {
      let el = document.getElementsByClassName('cookie-consent');
      if (el) {
        el[0].classList.add('opacity-none');
      }
      const timeout = setTimeout(() => {
        localStorage.setItem("cookie-consent", "true");
        this.$emit('cookieChanged');
        clearTimeout(timeout);
      }, 1000);
    },
    decline() {
      let el = document.getElementsByClassName('cookie-consent');
      if (el) {
        el[0].classList.add('opacity-none');
      }
      const timeout = setTimeout(() => {
        sessionStorage.setItem("cookie-consent", "false");
        this.$emit('cookieChanged');
        clearTimeout(timeout);
      }, 1000);
    }
  }
}
</script>

<style>

.cookie-consent {
  display: block;
  opacity: 1;
  padding: 30px 10px 10px 10px;
  width: 100%;
  height: 120px;
  min-height: 120px;
  max-height: 120px;
  position: fixed;
  bottom: 20px;
  background-color: #2c3e50;
  color: #cecece;
  z-index: 9999;
  transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
}

.opacity-none {
  opacity: 0 !important;
  transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
}

</style>