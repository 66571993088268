import axios from 'axios'
import router from '../router'

export default {
    namespaced: true,
    state:{
        authenticated:false,
        user:{}
    },
    getters:{
        authenticated(state){
            return state.authenticated
        },
        user(state){
            return state.user
        }
    },
    mutations:{
        SET_AUTHENTICATED (state, value) {
            state.authenticated = value
        },
        SET_USER (state, value) {
            state.user = value
        },
        SET_PERSIST_USER (state, value) {
            // 1800 - 24 hours
            // 43200 - 30 days
            let expirationInMin = 43200;
            let expirationDate = new Date(new Date().getTime() + (60000 * expirationInMin));
            let auth = {'auth':[{'authenticated':state.authenticated, 'user':value, 'expire':expirationDate.toISOString()}]};
            sessionStorage.removeItem('auth');
            sessionStorage.setItem('auth', JSON.stringify(auth));
        }
    },
    actions:{
        login({commit}, payload){
            return axios.get('api/user').then(({data})=>{
                commit('SET_USER',data)
                commit('SET_AUTHENTICATED',true)
                commit('SET_PERSIST_USER',data)
                if (payload) {
                    router.push({path: payload}).catch(() => {
                    });
                } else {
                    router.push({name: 'dash-statistics'}).catch(() => {
                    });
                }
            }).catch(({response:{data}})=>{
                commit('SET_USER',{})
                commit('SET_AUTHENTICATED',false)
                commit('SET_PERSIST_USER',{})
            })
        },
        logout({commit}){
            commit('SET_USER',{})
            commit('SET_AUTHENTICATED',false)
            commit('SET_PERSIST_USER',{})
            router.push({name: 'login'}).catch(()=>{});
        },
        refreshUser({commit}){
            return axios.get('api/user').then(({data})=>{
                commit('SET_USER',data)
                commit('SET_AUTHENTICATED',true)
                commit('SET_PERSIST_USER',data)
            }).catch(({response:{data}})=>{
                commit('SET_USER',{})
                commit('SET_AUTHENTICATED',false)
                commit('SET_PERSIST_USER',{})
            })
        }
    }
}