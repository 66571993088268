import axios from 'axios'

export default {
    namespaced: true,
    state:{
        loading:false,
        searchServicesFilters:false
    },
    getters:{
        loading(state){
            return state.loading
        },
        searchServicesFilters(state){
            return state.searchServicesFilters
        }
    },
    mutations:{
        SET_LOADING (state, value) {
            state.loading = value
        },
        SET_SEARCH_SERVICES_FILTERS (state, value) {
            state.searchServicesFilters = value
        },
        SET_PERSIST_SEARCH_SERVICES_FILTERS (state, value) {
            sessionStorage.removeItem('searchServicesFilters');
            sessionStorage.setItem('searchServicesFilters', JSON.stringify(value));
        }
    },
    actions:{
        setSearchServicesFilters({ commit }, payload) {
            commit('SET_LOADING', true)
            commit('SET_SEARCH_SERVICES_FILTERS', payload);
            commit('SET_PERSIST_SEARCH_SERVICES_FILTERS',payload)
            commit('SET_LOADING', false)
        }
    }
}