<template>
  <transition name="fade">
    <div class="popup-modal" v-if="isVisible">
      <div class="window">
        <div class="modal-border">
          <slot></slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'ConfirmationPopup',

  data: () => ({
    isVisible: false,
  }),

  methods: {
    open() {
      this.isVisible = true
    },

    close() {
      this.isVisible = false
    },
  },
}
</script>

<style scoped lang="scss">
/* css class for the transition */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.popup-modal {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  z-index: 9999;
}

.window {
  background: #fff;
  border-radius: 10px;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 480px;
  margin-left: auto;
  margin-right: auto;
}

.modal-border {
  border-radius: 10px;
  background: white;
  $border: 2px;
  position: relative;
  box-sizing: border-box;
  &:before {
     content: '';
     position: absolute;
     top: 0; right: 0; bottom: 0; left: 0;
     z-index: -1;
     margin: -$border; /* !importanté */
     border-radius: inherit; /* !importanté */
     background: linear-gradient(to bottom, rgba(128, 0, 128, 0.7), rgba(25, 181, 254, 0.7));
   }
}
</style>