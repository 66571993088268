import axios from 'axios'

export default {
    namespaced: true,
    state:{
        loading:false,
        searchFilters:false
    },
    getters:{
        loading(state){
            return state.loading
        },
        searchFilters(state){
            return state.searchFilters
        }
    },
    mutations:{
        SET_LOADING (state, value) {
            state.loading = value
        },
        SET_SEARCH_FILTERS (state, value) {
            state.searchFilters = value
        },
        SET_PERSIST_SEARCH_FILTERS (state, value) {
            sessionStorage.removeItem('searchFilters');
            sessionStorage.setItem('searchFilters', JSON.stringify(value));
        }
    },
    actions:{
        setSearchFilters({ commit }, payload) {
            commit('SET_LOADING', true)
            commit('SET_SEARCH_FILTERS', payload);
            commit('SET_PERSIST_SEARCH_FILTERS',payload)
            commit('SET_LOADING', false)
        }
    }
}