<template>
  <div class="container-ad-details">

    <div class="row">
      <div class="col-md-12 col-sm-12 ad-details">
        <!-- No Results -->
        <div class="fw-bold pt-5 m-2" v-if="noData">No results to show <br/>Please choose property advertise</div>
        <!-- Loading -->
        <Loader class="mt-2" :loading="loading" :small="false"></Loader>
        <!-- Loop properties -->
        <div v-if="propertyDetails && !loading" class="pt-2 pb-2">

          <div class="row ad-details-thumbnails">
            <div class="col-12 p-0 m-0">
              <div id="carouselCaptionsPropDetails" class="carousel slide" data-bs-ride="carousel" data-bs-interval="false">

                <ol class="carousel-indicators no-scroll d-none d-md-block" id="carouselThumbnailsPropDetails"> <!-- modal-tab -->
                  <li v-if="propertyDetails.documents_property_images && propertyDetails.documents_property_images.length === 0">
                    <img :src="require(`@/assets/no-image-found.png`)"
                         class="d-block img-fluid carousel-indicators-thumbnails"
                         alt="..."
                    >
                  </li>
                  <li v-for="(doc, num) in propertyDetails.documents_property_images"
                      :key="num"
                      :data-bs-target="'#carouselCaptionsPropDetails'"
                      :data-bs-slide-to="num"
                      aria-current="true"
                      :aria-label="'Slide '+num"
                      :class="num === 0 ? 'active' : ''"
                      :id="'carousel-thumb-'+num"
                  >
                    <img :src="`${$s3bucket}${doc.file_path}`" @error="setDefaultImg"
                         class="d-block img-fluid carousel-indicators-thumbnails"
                         alt="..."
                    >
<!--                    <img v-if="getImage(doc.file_name)" :src="require(`@/assets/${doc.file_name}`)"-->
<!--                         class="d-block img-fluid carousel-indicators-thumbnails"-->
<!--                         alt="..."-->
<!--                    >-->
<!--                    <img v-else :src="require(`@/assets/no-image-found.png`)" class="d-block img-fluid carousel-indicators-thumbnails" alt="...">-->
                  </li>
                </ol>
                <div class="carousel-inner" id="adDetailItems">
                  <div v-if="propertyDetails.documents_property_images && propertyDetails.documents_property_images.length === 0">
                    <img :src="require(`@/assets/no-image-found.png`)"
                         class="d-block w-100 img-fluid thumbnails-height"
                         alt="..."
                    >
                  </div>
                  <div v-for="(doc, index) in propertyDetails.documents_property_images"
                       :key="index"
                       :class="index === 0 ? 'active carousel-item' : 'carousel-item'"
                       :id="'carousel-inner-'+index"
                  >
                    <img :src="`${$s3bucket}${doc.file_path}`" @error="setDefaultImg"
                         class="d-block w-100 img-fluid thumbnails-height"
                         alt="..."
                    >
<!--                    <img v-if="getImage(doc.file_name)" :src="require(`@/assets/${doc.file_name}`)"-->
<!--                         class="d-block w-100 img-fluid thumbnails-height"-->
<!--                         alt="..."-->
<!--                    >-->
<!--                    <img v-else :src="require(`@/assets/no-image-found.png`)" class="d-block w-100 img-fluid thumbnails-img" alt="...">-->
                  </div>
                </div>

                <button class="carousel-control-prev" type="button" :data-bs-target="'#carouselCaptionsPropDetails'" data-bs-slide="prev"> <!-- v-on:click="setActive(activeCarouselIndex-1)" -->
                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" :data-bs-target="'#carouselCaptionsPropDetails'" data-bs-slide="next"> <!-- v-on:click="setActive(activeCarouselIndex+1)" -->
                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Next</span>
                </button>

              </div>
            </div>
            <div class="col-12">
              <span aria-hidden="true" class="scroll-through-icon-span" v-on:click="scrollDetailsIntoView"><font-awesome-icon icon="chevron-circle-down" class="scroll-through-icon"/></span>
            </div>
            <div class="col-12 mt-2 mb-2" id="scrollIntoView">
              <div class="row text-center">
                <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-6">
                  <div v-if="propertyDetails && propertyDetails.address && propertyDetails.property_category" class="row property-info m-0 p-0">
                    <div class="text-start list-relative property-info-box p-0">
                      <!-- Show Mini Map with Marker -->
                      <div class="ps-1 pe-1">
                        <MiniMap :lat="propertyDetails.address.latitude" :lng="propertyDetails.address.longitude" :propCategory="propertyDetails.property_category.type" :customClass="'rounded-3'"></MiniMap>
                      </div>
                    </div>
                  </div>
                  <div class="row property-info m-0 p-0 mt-2">
                    <div class="text-start list-relative property-info-box p-0">
                      <!-- property_category_id 7 = Room -->
                      <div v-if="propertyDetails.property_category_id !== 7 && propertyDetails.property_type && propertyDetails.property_type_details" class="property-info-type text-truncate-item no-text-wrap overflow-hidden text-truncate ps-1 pe-1">
                        <span class="fw-bold">
                          {{
                            propertyDetails.property_type.type  +
                            ' ' +
                            propertyDetails.property_type_details.type_detail
                          }}
                        </span>
                        {{
                          ' - ' +
                          (propertyDetails.address.county ? propertyDetails.address.county : '') +
                          (propertyDetails.address.city ? ', ' + propertyDetails.address.city : '') +
                          (propertyDetails.address.line_1 ? ', ' + propertyDetails.address.line_1 : '') +
                          (propertyDetails.address.postcode ? ', ' + propertyDetails.address.postcode : '')
                        }}
                      </div>
                      <div v-else-if="propertyDetails.property_category_id !== 7 && propertyDetails.property_type && !propertyDetails.property_type_details" class="property-info-type text-truncate-item no-text-wrap overflow-hidden text-truncate ps-1 pe-1">
                        <span class="fw-bold">
                          {{
                            propertyDetails.property_category.type +
                            ' ' +
                            propertyDetails.property_type.type
                          }}
                        </span>
                        {{
                          ' - ' +
                          (propertyDetails.address.county ? propertyDetails.address.county : '') +
                          (propertyDetails.address.city ? ', ' + propertyDetails.address.city : '') +
                          (propertyDetails.address.line_1 ? ', ' + propertyDetails.address.line_1 : '') +
                          (propertyDetails.address.postcode ? ', ' + propertyDetails.address.postcode : '')
                        }}
                      </div>
                      <div v-else class="property-info-type text-truncate-item no-text-wrap overflow-hidden text-truncate ps-1 pe-1">
                        <span class="fw-bold">
                          {{
                            propertyDetails.property_category.type
                          }}
                        </span>
                        {{
                          ' - ' +
                          (propertyDetails.address.county ? propertyDetails.address.county : '') +
                          (propertyDetails.address.city ? ', ' + propertyDetails.address.city : '') +
                          (propertyDetails.address.line_1 ? ', ' + propertyDetails.address.line_1 : '') +
                          (propertyDetails.address.postcode ? ', ' + propertyDetails.address.postcode : '')
                        }}
                      </div>
                    </div>
                  </div>
                  <div class="row property-info m-0 p-0 mt-2">
                    <div class="col-7 col-xs-7 col-sm-7 col-md-7 col-lg-7 m-0 p-0">
                      <div class="text-start position-relative property-info-box p-0">
                        <div class="text-start property-info-type text-truncate-item no-text-wrap overflow-hidden text-truncate ps-1 pe-1">
                          <span class="fw-bold">Posted:</span>
                          {{ calculateCreatedDate(propertyDetails.created_at) }}
                        </div>
                      </div>
                    </div>
                    <div class="col-5 col-xs-5 col-sm-5 col-md-5 col-lg-5 m-0 p-0">
                      <div class="text-end position-relative p-0">
                        <p class="m-0">
                          <span v-if="propertyDetails.is_sale" class="p-1 category-label" style="color: green; background-color: lightgreen;">FOR SALE</span>
                          <span v-if="propertyDetails.is_let" class="p-1 category-label" style="color: white; background-color: deepskyblue;">TO LET</span>
                          <span v-if="propertyDetails.is_buy" class="p-1 category-label" style="color: green; background-color: lightgreen;">TO BUY</span>
                          <span v-if="propertyDetails.is_rent" class="p-1 category-label" style="color: white; background-color: deepskyblue;">TO RENT</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div v-if="propertyDetails.is_let || propertyDetails.is_rent" class="row property-info m-0 p-0 mt-2">
                    <div class="text-start position-relative property-info-box p-0">
                      <div class="text-start property-info-type text-truncate-item no-text-wrap overflow-hidden text-truncate ps-1 pe-1">
                        <font-awesome-icon icon="calendar-day" style="color: green;"/>
                        <span class="fw-bold">{{propertyDetails.is_rent ? ' Required Dates:' : ' Availability:'}}</span>
                        {{ propertyDetails.date_start ? (propertyDetails.date_end ? (moment.utc(propertyDetails.date_start).format('DD/MM/YYYY') + ' - ' + moment.utc(propertyDetails.date_end).format('DD/MM/YYYY')) : (moment.utc(propertyDetails.date_start).format('DD/MM/YYYY') + ' - ' + 'TBD')) : 'None' }}
                      </div>
                    </div>
                  </div>

                  <!-- Start Favourites, Share and Report -->
                  <div class="row property-info m-0 p-0 mt-2">
                    <div class="col-4 col-xs-4 col-sm-4 col-md-4 col-lg-4 m-0 p-0">
                      <div class="text-center position-relative property-info-box p-0">
                        <div class="text-center property-info-type ps-1 pe-1">
                          <AddBookmark v-if="propertyDetails.is_published" style="font-size: 30px;" :id="propertyDetails.id" :type="'Property'" :tooltipClass="'tooltiptext-right'"></AddBookmark>
                          <span class="text-black-50 small">Favourites</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-4 col-xs-4 col-sm-4 col-md-4 col-lg-4 m-0 p-0">
                      <div class="text-center position-relative property-info-box p-0">
                        <div class="text-center property-info-type ps-1 pe-1">
                          <Share :adId="id" :socials="setShareSocials()"></Share>
                          <span class="text-black-50 small">Share</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-4 col-xs-4 col-sm-4 col-md-4 col-lg-4 m-0 p-0">
                      <div class="text-center position-relative property-info-box p-0">
                        <div class="text-center property-info-type ps-1 pe-1">
                          <!-- Start Action Buttons -->
                          <div class="action-dropdown">
                            <button @click.stop="preventParentClick()"
                                    class="action-button-report"
                                    type="button"
                                    :id="'actionPropertyDropdownReportType'+propertyDetails.id"
                                    data-bs-toggle="dropdown"
                                    data-bs-auto-close="outside"
                                    aria-expanded="false"
                            >
                              <span data-tooltip="tooltip" class="m-0 p-0">
                                <div class="review-icons-container" style="font-size: 30px;">
                                  <div class="position-relative icon-wrapper">
                                    <font-awesome-icon icon="exclamation-triangle" class="icon-report" />
                                  </div>
                                </div>
                                <span class="tooltiptext">Report</span>
                              </span>
                            </button>
                            <!-- Report Type Dropdown  :id="'actionPropertyDropdownReportTypeMenu'+propertyDetails.id" -->
                            <ul class="dropdown-menu dropdown-menu-start list-unstyled action-buttons" :aria-labelledby="'actionPropertyDropdownReportType'+propertyDetails.id">
                              <li class="action-item">
                                <button :class="processing ? 'disabled action-button-spam' : 'action-button-spam'"
                                        @click.stop="reportAdvertise(propertyDetails.id, 'Property', 'Spam')"
                                >
                                  <font-awesome-icon icon="clone" class="min-width-30 icon-spam" />
                                  SPAM
                                </button>
                              </li>
                              <li class="action-item">
                                <button :class="processing ? 'disabled action-button-scam' : 'action-button-scam'"
                                        @click.stop="reportAdvertise(propertyDetails.id, 'Property', 'Scam')"
                                >
                                  <font-awesome-icon icon="user-ninja" class="min-width-30 icon-scam" />
                                  SCAM
                                </button>
                              </li>
                              <li class="action-item">
                                <button :class="processing ? 'disabled action-button-abuse' : 'action-button-abuse'"
                                        @click.stop="reportAdvertise(propertyDetails.id, 'Property', 'Abuse')"
                                >
                                  <font-awesome-icon icon="heart-broken" class="min-width-30 icon-abuse" />
                                  ABUSE
                                </button>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <span class="text-black-50 small">Report</span>
                      </div>

                    </div>
                  </div>

                  <div class="form-group col-12 text-center">
                    <!-- Start Horizontal Line with Icon -->
                    <div class="horizontal-separator-box">
                      <span class="hr-line-short"></span>
                      <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                      <span class="hr-line-short"></span>
                    </div>
                    <!-- End Horizontal Line with Icon -->

                    <!-- Information -->
                    <small class="p-3 fw-bold">Reviews</small>

                    <!-- Start Horizontal Line with Icon -->
                    <div class="horizontal-separator-box">
                      <span class="hr-line-short"></span>
                      <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                      <span class="hr-line-short"></span>
                    </div>
                    <!-- End Horizontal Line with Icon -->
                  </div>

                  <div class="row property-info m-0 p-0">
<!--                    <span data-tooltip="tooltip" class="m-0 p-0">-->
                      <div
                          class="row m-0 p-0"
                          data-bs-toggle="modal"
                          data-bs-target="#reviewsModal"
                          @click="sendReviewModal(propertyDetails, 'neighbourhood', 'Reviews')"
                      >
                        <div :class="this.$store.state.auth.user && this.$store.state.auth.authenticated ? 'col-2 col-xs-2 col-sm-2 col-md-2 col-lg-2 m-0 p-0' : 'col-4 col-xs-4 col-sm-4 col-md-4 col-lg-4 m-0 p-0'">
                          <span data-tooltip="tooltip" class="m-0 p-0">
                            <div class="text-start position-relative property-info-box p-0">
                              <div class="text-start property-info-type ps-1 pe-1">
                                <div class="review-icons-container" style="font-size: 30px;">
                                  <div class="position-relative icon-wrapper">
                                    <font-awesome-icon icon="city" class="neighbourhood-review" style="color: mediumpurple;"/>
                                    <font-awesome-icon icon="star" class="small-star" style="color: yellow;"/>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <span class="tooltiptext">Neighbourhood Reviews</span>
                          </span>
                        </div>
                        <div v-if="this.$store.state.auth.user && this.$store.state.auth.authenticated" class="col-2 col-xs-2 col-sm-2 col-md-2 col-lg-2 m-0 p-0">
                          <span data-tooltip="tooltip" class="m-0 p-0">
                            <div class="text-start position-relative property-info-box p-0"
                                 data-bs-toggle="modal"
                                 data-bs-target="#addReviewModal"
                                 @click.stop="sendAddNeighbourhoodReviewModal(propertyDetails, 'Property',  'Add Review', 'neighbourhood')"
                            >
                              <div class="text-start property-info-type ps-1 pe-1">
                                <div class="add-icon-container text-center mt-2">
                                  <font-awesome-icon icon="plus-circle" class="icon-fa add-icon" />
                                </div>
                              </div>
                            </div>
                            <span class="tooltiptext">Add Neighbourhood Review</span>
                          </span>
                        </div>
                        <div class="col-8 col-xs-8 col-sm-8 col-md-8 col-lg-8 m-0 p-0">
                          <span data-tooltip="tooltip" class="m-0 p-0">
                            <div class="text-start position-relative property-info-box p-0">
                              <div class="text-start property-info-type ps-1 pe-1 pt-3">
                                <!-- Use component here to display star rating -->
                                Rating: <ShowReviewRating :rating="calculateTotalRating(neighbourhoodReviews, 'neighbourhood')" :showNumericRating="true"></ShowReviewRating> (5 miles radius)
                              </div>
                            </div>
                            <span class="tooltiptext">Neighbourhood Reviews</span>
                          </span>
                        </div>
                      </div>
<!--                      <span class="tooltiptext">Neighbourhood Reviews</span>-->
<!--                    </span>-->

                    <span v-if="propertyDetails.is_let && propertyDetails.is_short_term" data-tooltip="tooltip" class="m-0 p-0">
                      <div
                          class="row m-0 p-0"
                          data-bs-toggle="modal"
                          data-bs-target="#reviewsModal"
                          @click="sendReviewModal(propertyDetails, 'host', 'Reviews')"
                      >
                        <div class="col-4 col-xs-4 col-sm-4 col-md-4 col-lg-4 m-0 p-0">
                          <div class="text-start position-relative property-info-box p-0">
                            <div class="text-start property-info-type ps-1 pe-1">
                              <div class="review-icons-container" style="font-size: 30px;">
                                  <div class="position-relative icon-wrapper">
                                    <font-awesome-icon icon="house-user" class="neighbourhood-review" style="color: lightblue;"/>
                                    <font-awesome-icon icon="star" class="small-star" style="color: yellow;"/>
                                  </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-8 col-xs-8 col-sm-8 col-md-8 col-lg-8 m-0 p-0">
                          <div class="text-start position-relative property-info-box p-0">
                            <div class="text-start property-info-type ps-1 pe-1 pt-3">
                              <!-- Use component here to display star rating -->
                              Rating: <ShowReviewRating :rating="calculateTotalRating(hostReviews, 'host')" :showNumericRating="true"></ShowReviewRating>
                            </div>
                          </div>
                        </div>
                      </div>
                      <span class="tooltiptext">Host Reviews</span>
                    </span>
                  </div>
                  <!-- End Favourites, Share and Report -->

                </div>

                <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-6">

                  <!-- CONTACT DETAILS - USER OR AGENCY IMAGE -->
                  <div class="row m-0 p-0 text-start marketed-by">
                    <!-- USER OR AGENCY IMAGE -->
                    <p class="marketed-by-heading">Marketed By</p>
                    <div class="col-9 m-0 p-0 ps-2 property-info">
                      <div v-if="propertyDetails.contact.company_name && checkUserSettings(propertyDetails.settings, 1)" class="list-relative-contact">
                        <!-- setting_type_id 1 = Company Name -->
                        <div class="text-truncate-item no-text-wrap overflow-hidden text-truncate">
                          <span class="fw-bold">Company:</span>
                          {{ propertyDetails.contact.company_name }}
                        </div>
                      </div>
                      <div v-if="propertyDetails.contact.address && propertyDetails.contact.address.full_address && checkUserSettings(propertyDetails.settings, 2)" class="list-relative-contact">
                        <!-- setting_type_id 2 = Address -->
                        <div class="text-truncate-item no-text-wrap overflow-hidden text-truncate">
                          <span class="fw-bold">Address:</span>
                          {{ propertyDetails.contact.address.full_address }}
                        </div>
                      </div>
                      <div class="list-relative-contact">
                        <div class="text-truncate-item no-text-wrap overflow-hidden text-truncate">
                          <span class="fw-bold">Contact:</span>
                          {{ propertyDetails.contact.name ? propertyDetails.contact.name : 'Anonymous' }}
                        </div>
                      </div>
                      <ul class="list-unstyled">
                        <!-- Landing -->
                        <!-- setting_type_id 4 = Landing -->
                        <li v-if="propertyDetails.contact.landing && checkUserSettings(propertyDetails.settings, 4)">
                            <span data-tooltip="tooltip">
                              <div class="row m-0 p-0">
                                <div class="col-1 m-0 p-0 pt-1">
                                  <font-awesome-icon icon="phone" class="contact-icons"/>
                                </div>
                                <div class="col-11 m-0 p-0 pt-1">
                                  <a :href="'tel:'+calculateLanding(propertyDetails.contact)" class="contact-url">
                                    {{ calculateLanding(propertyDetails.contact) }}
                                  </a>
                                  <span class="tooltiptext">Landing</span>
                                </div>
                              </div>
                            </span>
                        </li>
                        <!-- Mobile -->
                        <!-- setting_type_id 3 = Mobile -->
                        <li v-if="propertyDetails.contact.mobile && checkUserSettings(propertyDetails.settings, 3)">
                            <span data-tooltip="tooltip">
                              <div class="row m-0 p-0">
                                <div class="col-1 m-0 p-0 pt-1">
                                  <font-awesome-icon icon="mobile-alt" class="contact-icons"/>
                                </div>
                                <div class="col-11 m-0 p-0 pt-1">
                                  <a :href="'tel:'+calculateMobile(propertyDetails.contact)" alt="call" class="contact-url">
                                    {{ calculateMobile(propertyDetails.contact) }}
                                  </a>
                                  <span class="tooltiptext">Mobile</span>
                                </div>
                              </div>
                            </span>
                        </li>
                        <!-- Email -->
                        <!-- setting_type_id 5 = Email -->
                        <li v-if="propertyDetails.contact.email && checkUserSettings(propertyDetails.settings, 5)">
                            <span data-tooltip="tooltip">
                              <div class="row m-0 p-0">
                                <div class="col-1 m-0 p-0 pt-1">
                                  <font-awesome-icon icon="envelope" class="contact-icons"/>
                                </div>
                                <div class="col-11 m-0 p-0 pt-1">
                                  <a :href="'mailto:'+propertyDetails.contact.email" alt="email" class="contact-url">
                                    {{ propertyDetails.contact.email }}
                                  </a>
                                  <span class="tooltiptext">Email</span>
                                </div>
                              </div>
                            </span>
                        </li>
                      </ul>
                    </div>
                    <div class="col-3">
                      <div v-if="!propertyDetails.user.documents_user_images">
                        <img :src="require(`@/assets/no-user-image.png`)"
                             class="d-block img-fluid user-image"
                             alt="..."
                        >
                      </div>
                      <div v-else>
<!--                        <img :src="require(`@/assets/${propertyDetails.user.documents_user_images.file_name}`)"-->
<!--                             class="d-block img-fluid user-image"-->
<!--                             alt="..."-->
<!--                        >-->
                        <img :src="`${$s3bucket}${propertyDetails.user.documents_user_images.file_path}`" @error="setUserDefaultImg"
                             class="d-block img-fluid user-image"
                             alt="..."
                        >
<!--                        <img v-if="getImage(propertyDetails.user.documents_user_images.file_name)" :src="require(`@/assets/${propertyDetails.user.documents_user_images.file_name}`)"-->
<!--                             class="d-block img-fluid user-image"-->
<!--                             alt="..."-->
<!--                        >-->
<!--                        <img v-else :src="require(`@/assets/no-image-found.png`)"-->
<!--                             class="d-block img-fluid user-image"-->
<!--                             alt="..."-->
<!--                        >-->
                      </div>
                    </div>

                    <!-- Chat Message -->
                    <hr class="p-0 m-0"/>

                    <div v-if="this.$store.state.auth.authenticated === true && this.$store.state.auth.user" class="row p-0 m-0">
                      <div v-if="this.$store.state.auth.authenticated === true &&
                            this.$store.state.auth.user"
                           :class="(propertyDetails.is_rent == 1 || propertyDetails.is_buy == 1) ? 'col-12 col-sm-12 col-md-12 col-lg-12 text-center p-2' : 'col-12 col-sm-12 col-md-6 col-lg-6 text-center p-2'"
                      >
                        <button
                            data-bs-toggle="modal"
                            data-bs-target="#chatModal"
                            class="chat-msg-button"
                            @click="sendModal(propertyDetails, 'Property', null, 'Chat')"
                        >
                            <font-awesome-icon icon="comment-dots" class="contact-icons chat-icon"/> Send Message
                        </button>
  <!--                      &lt;!&ndash; Chat Modal - Messages &ndash;&gt;-->
  <!--                      <ChatModal class="chat-modal" :toUserId="propertyDetails.user.id" :adId="propertyDetails.id" adType="Property"></ChatModal>-->
                      </div>

                      <!-- Book Viewing -->
                      <div v-if="this.$store.state.auth.authenticated === true &&
                            this.$store.state.auth.user &&
                            (propertyDetails.is_let == 1 && propertyDetails.is_short_term == 0 || propertyDetails.is_sale == 1)"
                           class="col-12 col-sm-12 col-md-6 col-lg-6 text-center p-2"
                      >
                        <button
                            data-bs-toggle="modal"
                            data-bs-target="#bookingModal"
                            class="chat-msg-button"
                            @click="sendModal(propertyDetails, 'Property', 'view', 'Booking')"
                        >
                          <font-awesome-icon icon="calendar-day" class="contact-icons chat-icon"/> Book Viewing
                        </button>
  <!--                      &lt;!&ndash; Booking Modal &ndash;&gt;-->
  <!--                      <Booking class="booking-modal" :adDetails="propertyDetails" :toUserId="propertyDetails.user.id" :adId="propertyDetails.id" adType="Property" booking="view"></Booking>-->
                      </div>

                      <!-- Book Reservation -->
                      <div v-if="this.$store.state.auth.authenticated === true &&
                            this.$store.state.auth.user &&
                            (propertyDetails.is_let == 1 && propertyDetails.is_short_term == 1)"
                           class="col-12 col-sm-12 col-md-6 col-lg-6 text-center p-2"
                      >
                        <button
                            data-bs-toggle="modal"
                            data-bs-target="#bookingModal"
                            class="chat-msg-button"
                            @click="sendModal(propertyDetails, 'Property', 'reserve', 'Booking')"
                        >
                          <font-awesome-icon icon="calendar-day" class="contact-icons chat-icon"/> Reserve
                        </button>
  <!--                      &lt;!&ndash; Booking Modal &ndash;&gt;-->
  <!--                      <Booking class="booking-modal" :adDetails="propertyDetails" :toUserId="propertyDetails.user.id" :adId="propertyDetails.id" adType="Property" booking="reserve"></Booking>-->
                      </div>
                    </div>

                    <div v-else class="row p-0 m-0">
                      <div class="text-center">
                        <small>
                          <router-link to="/login" class="text-decoration-none">
                            Login
                          </router-link>
                            to send private messages or book via platform
                        </small>
                      </div>
                    </div>

                  </div>

                </div>
              </div>
            </div>
<!--            <div class="col-12 mt-2 mb-2">-->
<!--              <div class="row text-start">-->
<!--                &lt;!&ndash; Start Horizontal Line with Icon &ndash;&gt;-->
<!--                <div class="horizontal-separator-box">-->
<!--                  <div class="property-info-container">-->
<!--                    <div class="text-start list-relative"> &lt;!&ndash; ps-3 pb-2 &ndash;&gt;-->
<!--                      <div class="property-info text-truncate-item no-text-wrap overflow-hidden text-truncate ps-1 pe-1">-->
<!--                        {{ propertyDetails.property_type.type  +-->
<!--                          ' ' +-->
<!--                          propertyDetails.property_type_details.type_detail +-->
<!--                          ' - ' +-->
<!--                          propertyDetails.address.county +-->
<!--                          ', ' +-->
<!--                          propertyDetails.address.line_1 +-->
<!--                          ', ' +-->
<!--                          propertyDetails.address.postcode-->
<!--                        }}-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <span aria-hidden="true" class="scroll-through-icon-span" v-on:click="scrollDetailsIntoView"><font-awesome-icon icon="chevron-circle-down" class="scroll-through-icon"/></span>-->
<!--                  <span class="hr-line-invisible"></span>-->
<!--                </div>-->
<!--                <div class="row property-info m-0 p-0 ps-1">-->
<!--                  Posted: {{ calculateCreatedDate(propertyDetails.created_at) }}-->
<!--                </div>-->
<!--                &lt;!&ndash; End Horizontal Line with Icon &ndash;&gt;-->
<!--              </div>-->
<!--            </div>-->

            <!-- Start Horizontal Line with Icon -->
            <div class="horizontal-separator-box">
              <span class="hr-line"></span>
              <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
              <span class="hr-line"></span>
            </div>
            <!-- End Horizontal Line with Icon -->

            <!-- Information -->
            <small class="p-3 fw-bold">Premises Details</small>

            <!-- Start Horizontal Line with Icon -->
            <div class="horizontal-separator-box">
              <span class="hr-line"></span>
              <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
              <span class="hr-line"></span>
            </div>
            <!-- End Horizontal Line with Icon -->

            <div class="col-12 mt-2 mb-2">
              <div class="row text-start">
                <div class="row m-0 p-0 ps-2 pe-2">
                  <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-6">
                    <div class="row m-0 p-0 property-icons">
                      <!-- ICONS -->

                      <!-- Start Top row icons: Price, Tenure -->
                      <div class="col-12">
                        <div class="row m-0 p-0 top-row-icons">
                          <div class="col-6">
                            <div class="row m-0 p-0">
                              <div class="col-1 m-0 p-0">
                                <ul class="list-unstyled">
                                  <!-- Price -->
                                  <li>
                                  <span data-tooltip="tooltip">
                                    <font-awesome-icon icon="pound-sign" class="icon-fa min-width-30" />
                                    <span class="tooltiptext">Price</span>
                                  </span>
                                  </li>
                                </ul>
                              </div>
                              <div class="col-2">
                                <ul class="list-unstyled">
                                  <!-- Price -->
                                  <li class="list-relative">
                                    <div v-if="showPriceRange(propertyDetails)" class="text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                      <span v-if="showHourPriceRange(propertyDetails)" class="price-color"> {{ calculateHourPriceRange(propertyDetails) }} </span>
                                      <span v-if="showHourPriceRange(propertyDetails)" class="fw-bold"> per hour </span> <br v-if="showHourPriceRange(propertyDetails)"/>
                                      <span v-if="showNightPriceRange(propertyDetails)" class="price-color"> {{ calculateNightPriceRange(propertyDetails) }} </span>
                                      <span v-if="showNightPriceRange(propertyDetails)" class="fw-bold"> per night </span>
                                      <!-- This only for Let Long-Term Rooms -->
                                      <span v-if="showWeekPriceRange(propertyDetails)" class="price-color"> {{ calculateWeekPriceRange(propertyDetails) }} </span>
                                      <span v-if="showWeekPriceRange(propertyDetails)" class="fw-bold"> per week </span>
                                      <span v-if="showMonthPriceRange(propertyDetails)" class="price-color"> {{ calculateMonthPriceRange(propertyDetails) }} </span>
                                      <span v-if="showMonthPriceRange(propertyDetails)" class="fw-bold"> per month </span>
                                    </div>
                                    <div v-else class="text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                      <span class="price-color"> {{ convertNumberToCommas(propertyDetails.price) }} </span>
                                      <span v-if="propertyDetails.is_rent || checkAdIsLetLongTermOrStudio(propertyDetails)" class="fw-bold"> per {{ propertyDetails.property_rent_type.type }} </span>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div v-if="!propertyDetails.is_let && !propertyDetails.is_rent" class="col-6">
                            <div class="row m-0 p-0">
                              <div class="col-1 m-0 p-0">
                                <ul class="list-unstyled">
                                  <!-- Tenure -->
                                  <li>
                                    <span data-tooltip="tooltip">
                                      <font-awesome-icon icon="file-signature" class="icon-fa min-width-30" />
                                      <span class="tooltiptext">Tenure</span>
                                    </span>
                                  </li>
                                </ul>
                              </div>
                              <div class="col-2">
                                <ul class="list-unstyled">
                                  <!-- Tenure -->
                                  <li class="list-relative">
                                    <div class="text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                      {{ propertyDetails.is_freehold ? 'Freehold' : 'Leasehold' }}
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div v-if="!propertyDetails.is_rent && !propertyDetails.is_buy" class="col-6">
                            <div class="row m-0 p-0">
                              <div class="col-1 m-0 p-0">
                                <ul class="list-unstyled">
                                  <!-- EPC Rating -->
                                  <li v-if="propertyDetails.property_details.epc_rating">
                                    <span data-tooltip="tooltip">
                                      <font-awesome-icon icon="poll-h" class="icon-fa min-width-30" />
                                      <span class="tooltiptext">EPC Rating</span>
                                    </span>
                                  </li>
                                </ul>
                              </div>
                              <div class="col-2">
                                <ul class="list-unstyled">
                                  <!-- EPC Rating -->
                                  <li v-if="propertyDetails.property_details.epc_rating">
                                    {{ propertyDetails.property_details.epc_rating }}
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div v-if="propertyDetails.property_square_meter" class="col-6">
                            <div class="row m-0 p-0">
                              <div class="col-1 m-0 p-0">
                                <ul class="list-unstyled">
                                  <!-- Square Meter -->
                                  <li v-if="propertyDetails.property_square_meter">
                                    <span data-tooltip="tooltip">
                                      <font-awesome-icon icon="vector-square" class="icon-fa min-width-30" />
                                      <span class="tooltiptext">Area Square Meter</span>
                                    </span>
                                  </li>
                                </ul>
                              </div>
                              <div class="col-2">
                                <ul class="list-unstyled">
                                  <!-- Square Meter -->
                                  <li v-if="propertyDetails.property_square_meter" class="list-relative min-height-25">
                                    <div class="text-truncate-item-square-meter no-text-wrap overflow-hidden text-truncate">
                                      {{ propertyDetails.property_square_meter }} ㎡
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="row m-0 p-0">
                              <div class="col-1 m-0 p-0">
                                <ul class="list-unstyled">
                                  <!-- Guests/Tenants -->
                                  <li v-if="propertyDetails.tenants_guests">
                                    <span data-tooltip="tooltip">
                                      <font-awesome-icon icon="user-friends" class="icon-fa min-width-30" />
                                      <span class="tooltiptext">Max Guests/Tenants on Premises</span>
                                    </span>
                                  </li>
                                </ul>
                              </div>
                              <div class="col-2">
                                <ul class="list-unstyled">
                                  <!-- Guests/Tenants -->
                                  <li v-if="propertyDetails.tenants_guests" class="list-relative min-height-25">
                                    <div class="text-truncate-item-square-meter no-text-wrap overflow-hidden text-truncate">
                                      {{ propertyDetails.tenants_guests }}
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>

                          <div v-if="conditionsStatus" class="form-group col-12 text-center">
                            <!-- Start Horizontal Line with Icon -->
                            <div class="horizontal-separator-box">
                              <span class="hr-line-short"></span>
                              <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                              <span class="hr-line-short"></span>
                            </div>
                            <!-- End Horizontal Line with Icon -->

                            <!-- Information -->
                            <small class="p-3 fw-bold">Status</small>

                            <!-- Start Horizontal Line with Icon -->
                            <div class="horizontal-separator-box">
                              <span class="hr-line-short"></span>
                              <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                              <span class="hr-line-short"></span>
                            </div>
                            <!-- End Horizontal Line with Icon -->
                          </div>

                          <!-- Investment Section -->
                          <div v-if="conditionsStatus" class="col-6 m-0 p-0">
                            <ul class="list-unstyled icons-listing">
                              <!-- Cash Only -->
                              <li v-if="propertyDetails.is_cash">
                                <span data-tooltip="tooltip">
                                  <font-awesome-icon icon="coins" class="icon-fa min-width-30" />
                                  <span class="tooltiptext">Cash Only</span>
                                </span>
                                Cash Only
                              </li>
                              <!-- Auction -->
                              <li v-if="propertyDetails.is_auction">
                                <span data-tooltip="tooltip">
                                  <font-awesome-icon icon="gavel" class="icon-fa min-width-30" />
                                  <span class="tooltiptext">Auction</span>
                                </span>
                                Auction
                              </li>
                              <!-- Investment -->
                              <li v-if="propertyDetails.is_investment">
                                <span data-tooltip="tooltip">
                                  <font-awesome-icon icon="chart-line" class="icon-fa min-width-30" />
                                  <span class="tooltiptext">Investment</span>
                                </span>
                                Investment
                              </li>
                              <!-- Mixed Use -->
                              <li v-if="propertyDetails.is_mixed_use">
                                <span data-tooltip="tooltip">
                                  <font-awesome-icon icon="balance-scale" class="icon-fa min-width-30" />
                                  <span class="tooltiptext">Mixed-use Premises</span>
                                </span>
                                Mixed-use
                              </li>
                            </ul>
                          </div>
                          <div v-if="conditionsStatus" class="col-6 m-0 ps-1 p-0 border-start-separator">
                            <ul class="list-unstyled icons-listing">
                              <!-- Cash Only -->
                              <li v-if="propertyDetails.is_cash">
                                <span data-tooltip="tooltip">
                                  <font-awesome-icon icon="coins" class="icon-fa min-width-30" />
                                  <span class="tooltiptext">Cash Only</span>
                                </span>
                                Yes
                              </li>
                              <!-- Auction -->
                              <li v-if="propertyDetails.is_auction">
                                <span data-tooltip="tooltip">
                                  <font-awesome-icon icon="gavel" class="icon-fa min-width-30" />
                                  <span class="tooltiptext">Auction</span>
                                </span>
                                Yes
                              </li>
                              <!-- Investment -->
                              <li v-if="propertyDetails.is_investment">
                                <span data-tooltip="tooltip">
                                  <font-awesome-icon icon="chart-line" class="icon-fa min-width-30" />
                                  <span class="tooltiptext">Investment</span>
                                </span>
                                Yes
                              </li>
                              <!-- Mixed Use -->
                              <li v-if="propertyDetails.is_mixed_use">
                                <span data-tooltip="tooltip">
                                  <font-awesome-icon icon="balance-scale" class="icon-fa min-width-30" />
                                  <span class="tooltiptext">Mixed-use Premises</span>
                                </span>
                                Yes
                              </li>
                            </ul>
                          </div>

                          <div v-if="propertyDetails.is_investment && propertyDetails.investment_property_details" class="form-group col-12 text-center">
                            <!-- Start Horizontal Line with Icon -->
                            <div class="horizontal-separator-box">
                              <span class="hr-line-short"></span>
                              <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                              <span class="hr-line-short"></span>
                            </div>
                            <!-- End Horizontal Line with Icon -->

                            <!-- Information -->
                            <small class="p-3 fw-bold">Investment</small>

                            <!-- Start Horizontal Line with Icon -->
                            <div class="horizontal-separator-box">
                              <span class="hr-line-short"></span>
                              <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                              <span class="hr-line-short"></span>
                            </div>
                            <!-- End Horizontal Line with Icon -->
                          </div>

                          <!-- Investment Section -->
                          <div v-if="propertyDetails.is_investment && propertyDetails.investment_property_details" class="col-6 m-0 p-0">
                            <ul class="list-unstyled icons-listing">
                              <!-- ROI -->
                              <li v-if="propertyDetails.investment_property_details.roi">
                                <span data-tooltip="tooltip">
                                  <font-awesome-icon icon="chart-bar" class="icon-fa min-width-30" />
                                  <span class="tooltiptext">ROI</span>
                                </span>
                                ROI
                              </li>
                              <!-- ROA -->
                              <li v-if="propertyDetails.investment_property_details.roa">
                                <span data-tooltip="tooltip">
                                  <font-awesome-icon icon="chart-pie" class="icon-fa min-width-30" />
                                  <span class="tooltiptext">ROA</span>
                                </span>
                                ROA
                              </li>
                              <!-- Yield -->
                              <li v-if="propertyDetails.investment_property_details.yield">
                                <span data-tooltip="tooltip">
                                  <font-awesome-icon icon="calculator" class="icon-fa min-width-30" />
                                  <span class="tooltiptext">Yield</span>
                                </span>
                                Yield
                              </li>
                              <!-- Share Percentage -->
                              <li v-if="propertyDetails.investment_property_details.share_percentage">
                                <span data-tooltip="tooltip">
                                  <font-awesome-icon icon="handshake" class="icon-fa min-width-20" />
                                    <font-awesome-icon icon="percentage" class="icon-fa min-width-10" />
                                  <span class="tooltiptext">Share Percentage</span>
                                </span>
                                Share Percentage
                              </li>
                              <!-- Cashflow -->
                              <li v-if="propertyDetails.investment_property_details.cash_flow">
                                <span data-tooltip="tooltip">
                                  <font-awesome-icon icon="money-bill-wave" class="icon-fa min-width-30" />
                                  <span class="tooltiptext">Cashflow</span>
                                </span>
                                Cashflow
                              </li>
                              <!-- Maintenance Cost -->
                              <li v-if="propertyDetails.investment_property_details.maintenance_cost">
                                <span data-tooltip="tooltip">
                                  <font-awesome-icon icon="wallet" class="icon-fa min-width-30" />
                                  <span class="tooltiptext">Maintenance Cost</span>
                                </span>
                                Maintenance Cost
                              </li>
                              <!-- Number of Flats -->
                              <li v-if="propertyDetails.investment_property_details.number_of_flats">
                                <span data-tooltip="tooltip">
                                  <font-awesome-icon icon="building" class="icon-fa min-width-30" />
                                  <span class="tooltiptext">Number of Flats</span>
                                </span>
                                Number of Flats
                              </li>
                              <!-- Number of Blocks -->
                              <li v-if="propertyDetails.investment_property_details.number_of_blocks">
                                <span data-tooltip="tooltip">
                                  <font-awesome-icon icon="th" class="icon-fa min-width-30" />
                                  <span class="tooltiptext">Number of Blocks</span>
                                </span>
                                Number of Blocks
                              </li>
                              <!-- Number of Units -->
                              <li v-if="propertyDetails.investment_property_details.number_of_units">
                                <span data-tooltip="tooltip">
                                  <font-awesome-icon icon="stream" class="icon-fa min-width-30" />
                                  <span class="tooltiptext">Number of Units</span>
                                </span>
                                Number of Units
                              </li>
                            </ul>
                          </div>
                          <div v-if="propertyDetails.is_investment && propertyDetails.investment_property_details" class="col-6 m-0 ps-1 p-0 border-start-separator">
                            <ul class="list-unstyled icons-listing">
                              <!-- ROI -->
                              <li v-if="propertyDetails.investment_property_details.roi">
                                <span data-tooltip="tooltip">
                                  <font-awesome-icon icon="chart-bar" class="icon-fa min-width-30" />
                                  <span class="tooltiptext">ROI</span>
                                </span>
                                {{ propertyDetails.investment_property_details.roi ? propertyDetails.investment_property_details.roi + '%' : '' }}
                              </li>
                              <!-- ROA -->
                              <li v-if="propertyDetails.investment_property_details.roa">
                                <span data-tooltip="tooltip">
                                  <font-awesome-icon icon="chart-pie" class="icon-fa min-width-30" />
                                  <span class="tooltiptext">ROA</span>
                                </span>
                                {{ propertyDetails.investment_property_details.roa ? propertyDetails.investment_property_details.roa + '%' : '' }}
                              </li>
                              <!-- Yield -->
                              <li v-if="propertyDetails.investment_property_details.yield">
                                <span data-tooltip="tooltip">
                                  <font-awesome-icon icon="calculator" class="icon-fa min-width-30" />
                                  <span class="tooltiptext">Yield</span>
                                </span>
                                {{ propertyDetails.investment_property_details.yield ? propertyDetails.investment_property_details.yield + '%' : '' }}
                              </li>
                              <!-- Share Percentage -->
                              <li v-if="propertyDetails.investment_property_details.share_percentage">
                                <span data-tooltip="tooltip">
                                  <font-awesome-icon icon="handshake" class="icon-fa min-width-20" />
                                    <font-awesome-icon icon="percentage" class="icon-fa min-width-10" />
                                  <span class="tooltiptext">Share Percentage</span>
                                </span>
                                {{ propertyDetails.investment_property_details.share_percentage ? propertyDetails.investment_property_details.share_percentage + '%' : '' }}
                              </li>
                              <!-- Cashflow -->
                              <li v-if="propertyDetails.investment_property_details.cash_flow">
                                <span data-tooltip="tooltip">
                                  <font-awesome-icon icon="money-bill-wave" class="icon-fa min-width-30" />
                                  <span class="tooltiptext">Cashflow</span>
                                </span>
                                {{ propertyDetails.investment_property_details.cash_flow ? '£' + convertNumberToCommas(propertyDetails.investment_property_details.cash_flow) : '' }}
                              </li>
                              <!-- Maintenance Cost -->
                              <li v-if="propertyDetails.investment_property_details.maintenance_cost">
                                <span data-tooltip="tooltip">
                                  <font-awesome-icon icon="wallet" class="icon-fa min-width-30" />
                                  <span class="tooltiptext">Maintenance Cost</span>
                                </span>
                                {{ propertyDetails.investment_property_details.maintenance_cost ? '£' + convertNumberToCommas(propertyDetails.investment_property_details.maintenance_cost) : '' }}
                              </li>
                              <!-- Number of Flats -->
                              <li v-if="propertyDetails.investment_property_details.number_of_flats">
                                <span data-tooltip="tooltip">
                                  <font-awesome-icon icon="building" class="icon-fa min-width-30" />
                                  <span class="tooltiptext">Number of Flats</span>
                                </span>
                                {{ propertyDetails.investment_property_details.number_of_flats }}
                              </li>
                              <!-- Number of Blocks -->
                              <li v-if="propertyDetails.investment_property_details.number_of_blocks">
                                <span data-tooltip="tooltip">
                                  <font-awesome-icon icon="th" class="icon-fa min-width-30" />
                                  <span class="tooltiptext">Number of Blocks</span>
                                </span>
                                {{ propertyDetails.investment_property_details.number_of_blocks }}
                              </li>
                              <!-- Number of Units -->
                              <li v-if="propertyDetails.investment_property_details.number_of_units">
                                <span data-tooltip="tooltip">
                                  <font-awesome-icon icon="stream" class="icon-fa min-width-30" />
                                  <span class="tooltiptext">Number of Units</span>
                                </span>
                                {{ propertyDetails.investment_property_details.number_of_units }}
                              </li>
                            </ul>
                          </div>

                        </div>
                      </div>
                      <!-- End Top row icons: Price, Tenure -->

                      <div v-if="conditionsSuiteApartment" class="form-group col-12 text-center">
                        <!-- Start Horizontal Line with Icon -->
                        <div class="horizontal-separator-box">
                          <span class="hr-line-short"></span>
                          <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                          <span class="hr-line-short"></span>
                        </div>
                        <!-- End Horizontal Line with Icon -->

                        <!-- Information -->
                        <small class="p-3 fw-bold">Suite/Apartment</small>

                        <!-- Start Horizontal Line with Icon -->
                        <div class="horizontal-separator-box">
                          <span class="hr-line-short"></span>
                          <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                          <span class="hr-line-short"></span>
                        </div>
                        <!-- End Horizontal Line with Icon -->
                      </div>

                      <!-- New Icons Two Columns -->
                      <div v-if="conditionsSuiteApartment" class="col-6 m-0 p-0">
                        <ul class="list-unstyled icons-listing">
                          <!-- Suite/Apartment (Whole Place LET short-term not ROOM) -->
                          <li v-if="calculateSuiteBeds(propertyDetails.property_details)">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="building" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Suite/Apartment Bedrooms</span>
                            </span>
                            <span> Suite/Apartment Beds</span>
                          </li>
                          <!-- Single Bed Suite -->
                          <li v-if="propertyDetails.property_details.single_room_suite" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="dice-one" class="icon-fa min-width-10" />
                              <font-awesome-icon icon="bed" class="icon-fa min-width-20" />
                              <span class="tooltiptext">Single Bedroom</span>
                            </span>
                            Single Bedroom
                          </li>
                          <!-- Double Bed Suite -->
                          <li v-if="propertyDetails.property_details.double_room_suite" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="dice-two" class="icon-fa min-width-10" />
                              <font-awesome-icon icon="bed" class="icon-fa min-width-20" />
                              <span class="tooltiptext">Double Bedroom</span>
                            </span>
                            Double Bedroom
                          </li>
                        </ul>
                      </div>
                      <div v-if="conditionsSuiteApartment" class="col-6 m-0 ps-1 p-0 border-start-separator">
                        <ul class="list-unstyled icons-listing">
                          <!-- Suite/Apartment (Whole Place LET short-term not ROOM) -->
                          <li v-if="calculateSuiteBeds(propertyDetails.property_details)">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="building" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Suite/Apartment Bedrooms</span>
                            </span>
                            {{ calculateSuiteBeds(propertyDetails.property_details) }}
                          </li>
                          <!-- Single Bed Suite -->
                          <li v-if="propertyDetails.property_details.single_room_suite" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="dice-one" class="icon-fa min-width-10" />
                              <font-awesome-icon icon="bed" class="icon-fa min-width-20" />
                              <span class="tooltiptext">Single Bedroom</span>
                            </span>
                            {{ propertyDetails.property_details.single_room_suite }}
                          </li>
                          <!-- Double Bed Suite -->
                          <li v-if="propertyDetails.property_details.double_room_suite" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="dice-two" class="icon-fa min-width-10" />
                              <font-awesome-icon icon="bed" class="icon-fa min-width-20" />
                              <span class="tooltiptext">Double Bedroom</span>
                            </span>
                            {{ propertyDetails.property_details.double_room_suite }}
                          </li>
                        </ul>
                      </div>

                      <div v-if="conditionsMainFeatures" class="form-group col-12 text-center">
                        <!-- Start Horizontal Line with Icon -->
                        <div class="horizontal-separator-box">
                          <span class="hr-line-short"></span>
                          <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                          <span class="hr-line-short"></span>
                        </div>
                        <!-- End Horizontal Line with Icon -->

                        <!-- Information -->
                        <small class="p-3 fw-bold">Rooms</small>

                        <!-- Start Horizontal Line with Icon -->
                        <div class="horizontal-separator-box">
                          <span class="hr-line-short"></span>
                          <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                          <span class="hr-line-short"></span>
                        </div>
                        <!-- End Horizontal Line with Icon -->
                      </div>

                      <!-- New Icons Two Columns -->
                      <div v-if="conditionsMainFeatures" class="col-6 m-0 p-0">
                        <ul class="list-unstyled icons-listing">
                          <!-- Bedrooms -->
                          <li v-if="calculateBeds(propertyDetails.property_details)">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="bed" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Bedrooms</span>
                            </span>
                            <span> Bedrooms</span>
                          </li>
                          <!-- Single Bed -->
                          <li v-if="propertyDetails.property_details.single_room" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="dice-one" class="icon-fa min-width-10" />
                              <font-awesome-icon icon="bed" class="icon-fa min-width-20" />
                              <span class="tooltiptext">Single Bedroom</span>
                            </span>
                            Single Bedroom
                          </li>
                          <!-- Double Bed -->
                          <li v-if="propertyDetails.property_details.double_room" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="dice-two" class="icon-fa min-width-10" />
                              <font-awesome-icon icon="bed" class="icon-fa min-width-20" />
                              <span class="tooltiptext">Double Bedroom</span>
                            </span>
                            Double Bedroom
                          </li>
                          <!-- Twin Bed -->
                          <li v-if="propertyDetails.property_details.twin_room && checkAdIsLetShortTermOrRoom(propertyDetails)" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="dice-one" class="icon-fa min-width-10" />
                              <font-awesome-icon icon="dice-one" class="icon-fa min-width-10" />
                              <font-awesome-icon icon="bed" class="icon-fa min-width-20" />
                              <span class="tooltiptext">Twin Bedroom</span>
                            </span>
                            Twin Bedroom
                          </li>
                          <!-- Twin Double Bed -->
                          <li v-if="propertyDetails.property_details.twin_double_room && checkAdIsLetShortTermOrRoom(propertyDetails)" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="dice-two" class="icon-fa min-width-10" />
                              <font-awesome-icon icon="dice-two" class="icon-fa min-width-10" />
                              <font-awesome-icon icon="bed" class="icon-fa min-width-20" />
                              <span class="tooltiptext">Twin Double Bedroom</span>
                            </span>
                            Twin Double Bedroom
                          </li>
                          <!-- Triple Bed -->
                          <li v-if="propertyDetails.property_details.triple_room && checkAdIsLetShortTermOrRoom(propertyDetails)" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="dice-three" class="icon-fa min-width-10" />
                              <font-awesome-icon icon="bed" class="icon-fa min-width-20" />
                              <span class="tooltiptext">Triple Bedroom</span>
                            </span>
                            Triple Bedroom
                          </li>
                          <!-- Quadruple Bed -->
                          <li v-if="propertyDetails.property_details.quadruple_room && checkAdIsLetShortTermOrRoom(propertyDetails)" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="dice-four" class="icon-fa min-width-10" />
                              <font-awesome-icon icon="bed" class="icon-fa min-width-20" />
                              <span class="tooltiptext">Quadruple Bedroom</span>
                            </span>
                            Quadruple Bedroom
                          </li>
                          <!-- Family Bed -->
                          <li v-if="propertyDetails.property_details.family_room && checkAdIsLetShortTermOrRoom(propertyDetails)" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="child" class="icon-fa min-width-10" />
                              <font-awesome-icon icon="bed" class="icon-fa min-width-20" />
                              <span class="tooltiptext">Family Bedroom</span>
                            </span>
                            Family Bedroom
                          </li>
                          <!-- Studio Room -->
                          <li v-if="propertyDetails.property_details.studio_room" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="dice-d6" class="icon-fa min-width-10" />
                              <font-awesome-icon icon="bed" class="icon-fa min-width-20" />
                              <span class="tooltiptext">Studio Flat</span>
                            </span>
                            Studio Flat
                          </li>
                          <!-- Living Room -->
                          <li v-if="propertyDetails.property_details.living_room">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="couch" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Living Room</span>
                            </span>
                            <span> Living Room</span>
                          </li>
                          <!-- Dining Room -->
                          <li v-if="propertyDetails.property_details.dining_room">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="utensils" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Dining Room</span>
                            </span>
                            <span> Dining Room</span>
                          </li>
                          <!-- Box Room -->
                          <li v-if="propertyDetails.property_details.box_room">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="boxes" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Box Room</span>
                            </span>
                            <span> Box Room</span>
                          </li>
                        </ul>
                      </div>
                      <div v-if="conditionsMainFeatures" class="col-6 m-0 ps-1 p-0 border-start-separator">
                        <ul class="list-unstyled icons-listing">
                          <!-- Bedrooms -->
                          <li v-if="calculateBeds(propertyDetails.property_details)">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="bed" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Bedrooms</span>
                            </span>
                            {{ calculateBeds(propertyDetails.property_details) }}
                          </li>
                          <!-- Single Bed -->
                          <li v-if="propertyDetails.property_details.single_room" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="dice-one" class="icon-fa min-width-10" />
                              <font-awesome-icon icon="bed" class="icon-fa min-width-20" />
                              <span class="tooltiptext">Single Bedroom</span>
                            </span>
                            {{ propertyDetails.property_details.single_room }}
                          </li>
                          <!-- Double Bed -->
                          <li v-if="propertyDetails.property_details.double_room" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="dice-two" class="icon-fa min-width-10" />
                              <font-awesome-icon icon="bed" class="icon-fa min-width-20" />
                              <span class="tooltiptext">Double Bedroom</span>
                            </span>
                            {{ propertyDetails.property_details.double_room }}
                          </li>
                          <!-- Twin Bed -->
                          <li v-if="propertyDetails.property_details.twin_room && checkAdIsLetShortTermOrRoom(propertyDetails)" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="dice-one" class="icon-fa min-width-10" />
                              <font-awesome-icon icon="dice-one" class="icon-fa min-width-10" />
                              <font-awesome-icon icon="bed" class="icon-fa min-width-20" />
                              <span class="tooltiptext">Twin Bedroom</span>
                            </span>
                            {{ propertyDetails.property_details.twin_room }}
                          </li>
                          <!-- Twin Double Bed -->
                          <li v-if="propertyDetails.property_details.twin_double_room && checkAdIsLetShortTermOrRoom(propertyDetails)" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="dice-two" class="icon-fa min-width-10" />
                              <font-awesome-icon icon="dice-two" class="icon-fa min-width-10" />
                              <font-awesome-icon icon="bed" class="icon-fa min-width-20" />
                              <span class="tooltiptext">Twin Double Bedroom</span>
                            </span>
                            {{ propertyDetails.property_details.twin_double_room }}
                          </li>
                          <!-- Triple Bed -->
                          <li v-if="propertyDetails.property_details.triple_room && checkAdIsLetShortTermOrRoom(propertyDetails)" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="dice-three" class="icon-fa min-width-10" />
                              <font-awesome-icon icon="bed" class="icon-fa min-width-20" />
                              <span class="tooltiptext">Triple Bedroom</span>
                            </span>
                            {{ propertyDetails.property_details.triple_room }}
                          </li>
                          <!-- Quadruple Bed -->
                          <li v-if="propertyDetails.property_details.quadruple_room && checkAdIsLetShortTermOrRoom(propertyDetails)" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="dice-four" class="icon-fa min-width-10" />
                              <font-awesome-icon icon="bed" class="icon-fa min-width-20" />
                              <span class="tooltiptext">Quadruple Bedroom</span>
                            </span>
                            {{ propertyDetails.property_details.quadruple_room }}
                          </li>
                          <!-- Family Bed -->
                          <li v-if="propertyDetails.property_details.family_room && checkAdIsLetShortTermOrRoom(propertyDetails)" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="child" class="icon-fa min-width-10" />
                              <font-awesome-icon icon="bed" class="icon-fa min-width-20" />
                              <span class="tooltiptext">Family Bedroom</span>
                            </span>
                            {{ propertyDetails.property_details.family_room }}
                          </li>
                          <!-- Studio Room -->
                          <li v-if="propertyDetails.property_details.studio_room" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="dice-d6" class="icon-fa min-width-10" />
                              <font-awesome-icon icon="bed" class="icon-fa min-width-20" />
                              <span class="tooltiptext">Studio Flat</span>
                            </span>
                            Yes
                          </li>
                          <!-- Living Room -->
                          <li v-if="propertyDetails.property_details.living_room">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="couch" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Living Room</span>
                            </span>
                            {{ propertyDetails.property_details.living_room }}
                          </li>
                          <!-- Dining Room -->
                          <li v-if="propertyDetails.property_details.dining_room">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="utensils" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Dining Room</span>
                            </span>
                            {{ propertyDetails.property_details.dining_room }}
                          </li>
                          <!-- Box Room -->
                          <li v-if="propertyDetails.property_details.box_room">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="boxes" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Box Room</span>
                            </span>
                            {{ propertyDetails.property_details.box_room }}
                          </li>
                        </ul>
                      </div>

                      <div v-if="conditionsKitchen" class="form-group col-12 text-center">
                        <!-- Start Horizontal Line with Icon -->
                        <div class="horizontal-separator-box">
                          <span class="hr-line-short"></span>
                          <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                          <span class="hr-line-short"></span>
                        </div>
                        <!-- End Horizontal Line with Icon -->

                        <!-- Information -->
                        <small class="p-3 fw-bold">Kitchen</small>

                        <!-- Start Horizontal Line with Icon -->
                        <div class="horizontal-separator-box">
                          <span class="hr-line-short"></span>
                          <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                          <span class="hr-line-short"></span>
                        </div>
                        <!-- End Horizontal Line with Icon -->
                      </div>

                      <!-- New Icons Two Columns -->
                      <div v-if="conditionsKitchen" class="col-6 m-0 p-0">
                        <ul class="list-unstyled icons-listing">
                          <!-- Kitchen -->
                          <li v-if="propertyDetails.property_details.kitchen">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="fire-alt" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Kitchen</span>
                            </span>
                            <span> Kitchen</span>
                          </li>
                          <!-- Oven -->
                          <li v-if="propertyDetails.property_details.oven" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="burn" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Oven</span>
                            </span>
                            Oven
                          </li>
                          <!-- Fridge -->
                          <li v-if="propertyDetails.property_details.fridge" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="hamburger" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Fridge</span>
                            </span>
                            Fridge
                          </li>
                          <!-- Freezer -->
                          <li v-if="propertyDetails.property_details.freezer" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="snowflake" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Freezer</span>
                            </span>
                            Freezer
                          </li>
                          <!-- Microwave -->
                          <li v-if="propertyDetails.property_details.microwave" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="pager" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Microwave</span>
                            </span>
                            Microwave
                          </li>
                          <!-- Kettle -->
                          <li v-if="propertyDetails.property_details.kettle" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="mug-hot" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Kettle</span>
                            </span>
                            Kettle
                          </li>
                          <!-- Toaster -->
                          <li v-if="propertyDetails.property_details.toaster" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="bread-slice" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Toaster</span>
                            </span>
                            Toaster
                          </li>
                          <!-- Coffee -->
                          <li v-if="propertyDetails.property_details.coffee" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="coffee" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Coffee</span>
                            </span>
                            Coffee
                          </li>
                        </ul>
                      </div>
                      <div v-if="conditionsKitchen" class="col-6 m-0 ps-1 p-0 border-start-separator">
                        <ul class="list-unstyled icons-listing">
                          <!-- Kitchen -->
                          <li v-if="propertyDetails.property_details.kitchen">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="fire-alt" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Kitchen</span>
                            </span>
                            {{ propertyDetails.property_details.kitchen }}
                          </li>
                          <!-- Oven -->
                          <li v-if="propertyDetails.property_details.oven" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="burn" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Oven</span>
                            </span>
                            Yes
                          </li>
                          <!-- Fridge -->
                          <li v-if="propertyDetails.property_details.fridge" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="hamburger" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Fridge</span>
                            </span>
                            Yes
                          </li>
                          <!-- Freezer -->
                          <li v-if="propertyDetails.property_details.freezer" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="snowflake" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Freezer</span>
                            </span>
                            Yes
                          </li>
                          <!-- Microwave -->
                          <li v-if="propertyDetails.property_details.microwave" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="pager" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Microwave</span>
                            </span>
                            Yes
                          </li>
                          <!-- Kettle -->
                          <li v-if="propertyDetails.property_details.kettle" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="mug-hot" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Kettle</span>
                            </span>
                            Yes
                          </li>
                          <!-- Toaster -->
                          <li v-if="propertyDetails.property_details.toaster" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="bread-slice" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Toaster</span>
                            </span>
                            Yes
                          </li>
                          <!-- Coffee -->
                          <li v-if="propertyDetails.property_details.coffee" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="coffee" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Coffee</span>
                            </span>
                            Yes
                          </li>
                        </ul>
                      </div>

                      <div v-if="conditionsBathroom" class="form-group col-12 text-center">
                        <!-- Start Horizontal Line with Icon -->
                        <div class="horizontal-separator-box">
                          <span class="hr-line-short"></span>
                          <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                          <span class="hr-line-short"></span>
                        </div>
                        <!-- End Horizontal Line with Icon -->

                        <!-- Information -->
                        <small class="p-3 fw-bold">Bathroom</small>

                        <!-- Start Horizontal Line with Icon -->
                        <div class="horizontal-separator-box">
                          <span class="hr-line-short"></span>
                          <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                          <span class="hr-line-short"></span>
                        </div>
                        <!-- End Horizontal Line with Icon -->
                      </div>

                      <!-- New Icons Two Columns -->
                      <div v-if="conditionsBathroom" class="col-6 m-0 p-0">
                        <ul class="list-unstyled icons-listing">
                          <!-- Bathrooms -->
                          <li v-if="conditionsBathroom">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="bath" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Bathrooms</span>
                            </span>
                            <span> Bathrooms</span>
                          </li>
                          <!-- WC -->
                          <li v-if="propertyDetails.property_details.wc" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="toilet" class="icon-fa min-width-30" />
                              <span class="tooltiptext">WC</span>
                            </span>
                            WC
                          </li>
                          <!-- Downstairs WC -->
                          <li v-if="propertyDetails.property_details.downstairs_wc" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="toilet" class="icon-fa min-width-20" />
                              <font-awesome-icon icon="hand-point-down" class="icon-fa min-width-10" />
                              <span class="tooltiptext">Downstairs WC</span>
                            </span>
                            Downstairs WC
                          </li>
                          <!-- Shower -->
                          <li v-if="propertyDetails.property_details.shower" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="shower" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Shower</span>
                            </span>
                            Shower
                          </li>
                          <!-- Bath -->
                          <li v-if="propertyDetails.property_details.bath" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="bath" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Bath</span>
                            </span>
                            Bath
                          </li>
                          <!-- Sink -->
                          <li v-if="propertyDetails.property_details.sink" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="sink" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Sink</span>
                            </span>
                            Sink
                          </li>
                        </ul>
                      </div>
                      <div v-if="conditionsBathroom" class="col-6 m-0 ps-1 p-0 border-start-separator">
                        <ul class="list-unstyled icons-listing">
                          <!-- Bathrooms -->
                          <li v-if="conditionsBathroom">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="bath" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Bathrooms</span>
                            </span>
                            {{ propertyDetails.property_details.bath_room ? propertyDetails.property_details.bath_room : 0 }}
                          </li>
                          <!-- WC -->
                          <li v-if="propertyDetails.property_details.wc" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="toilet" class="icon-fa min-width-30" />
                              <span class="tooltiptext">WC</span>
                            </span>
                            {{ propertyDetails.property_details.wc }}
                          </li>
                          <!-- Downstairs WC -->
                          <li v-if="propertyDetails.property_details.downstairs_wc" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="toilet" class="icon-fa min-width-20" />
                              <font-awesome-icon icon="hand-point-down" class="icon-fa min-width-10" />
                              <span class="tooltiptext">Downstairs WC</span>
                            </span>
                            {{ propertyDetails.property_details.downstairs_wc }}
                          </li>
                          <!-- Shower -->
                          <li v-if="propertyDetails.property_details.shower" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="shower" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Shower</span>
                            </span>
                            {{ propertyDetails.property_details.shower }}
                          </li>
                          <!-- Bath -->
                          <li v-if="propertyDetails.property_details.bath" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="bath" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Bath</span>
                            </span>
                            {{ propertyDetails.property_details.bath }}
                          </li>
                          <!-- Sink -->
                          <li v-if="propertyDetails.property_details.sink" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="sink" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Sink</span>
                            </span>
                            {{ propertyDetails.property_details.sink }}
                          </li>
                        </ul>
                      </div>

                      <div v-if="conditionsStructural" class="form-group col-12 text-center">
                        <!-- Start Horizontal Line with Icon -->
                        <div class="horizontal-separator-box">
                          <span class="hr-line-short"></span>
                          <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                          <span class="hr-line-short"></span>
                        </div>
                        <!-- End Horizontal Line with Icon -->

                        <!-- Information -->
                        <small class="p-3 fw-bold">Structural</small>

                        <!-- Start Horizontal Line with Icon -->
                        <div class="horizontal-separator-box">
                          <span class="hr-line-short"></span>
                          <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                          <span class="hr-line-short"></span>
                        </div>
                        <!-- End Horizontal Line with Icon -->
                      </div>

                      <div v-if="conditionsStructural" class="col-6 m-0 p-0">
                        <ul class="list-unstyled icons-listing">
                          <!-- Garden -->
                          <li v-if="conditionsGarden">
                              <span data-tooltip="tooltip">
                                <font-awesome-icon icon="seedling" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Garden</span>
                              </span>
                            Garden
                          </li>
                          <!-- Front Garden -->
                          <li v-if="propertyDetails.property_details.front_garden" class="ps-3 sub-icons-font">
                              <span data-tooltip="tooltip">
                                <font-awesome-icon icon="leaf" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Front Garden</span>
                              </span>
                            Front Garden
                          </li>
                          <!-- Rear Garden -->
                          <li v-if="propertyDetails.property_details.rear_garden" class="ps-3 sub-icons-font">
                              <span data-tooltip="tooltip">
                                <font-awesome-icon icon="tree" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Rear Garden</span>
                              </span>
                            Rear Garden
                          </li>
                          <!-- Garden Terrace -->
                          <li v-if="propertyDetails.property_details.garden_terrace" class="ps-3 sub-icons-font">
                              <span data-tooltip="tooltip">
                                <font-awesome-icon icon="ruler-horizontal" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Garden Terrace</span>
                              </span>
                            Garden Terrace
                          </li>
                          <!-- Conservatory -->
                          <li v-if="propertyDetails.property_details.conservatory" class="ps-3 sub-icons-font">
                              <span data-tooltip="tooltip">
                                <font-awesome-icon icon="store-alt" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Conservatory</span>
                              </span>
                            Conservatory
                          </li>
                          <!-- Green House -->
                          <li v-if="propertyDetails.property_details.green_house" class="ps-3 sub-icons-font">
                              <span data-tooltip="tooltip">
                                <font-awesome-icon icon="border-none" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Green House</span>
                              </span>
                            Green House
                          </li>
                          <!-- Shed -->
                          <li v-if="propertyDetails.property_details.shed" class="ps-3 sub-icons-font">
                              <span data-tooltip="tooltip">
                                <font-awesome-icon icon="box-open" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Shed</span>
                              </span>
                            Shed
                          </li>
                          <!-- Parking -->
                          <li v-if="calculateParking(propertyDetails.property_details.driveway, propertyDetails.property_details.garage, propertyDetails.property_details.parking, propertyDetails.property_details.parking_spaces)">
                              <span data-tooltip="tooltip">
                                <font-awesome-icon icon="parking" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Parking</span>
                              </span>
                            Parking
                          </li>
                          <!-- Driveway -->
                          <li v-if="propertyDetails.property_details.driveway" class="ps-3 sub-icons-font">
                              <span data-tooltip="tooltip">
                                <font-awesome-icon icon="car" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Driveway</span>
                              </span>
                            Driveway
                          </li>
                          <!-- Garage -->
                          <li v-if="propertyDetails.property_details.garage" class="ps-3 sub-icons-font">
                              <span data-tooltip="tooltip">
                                <font-awesome-icon icon="warehouse" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Garage</span>
                              </span>
                            Garage
                          </li>
                          <!-- Front Porch -->
                          <li v-if="propertyDetails.property_details.front_porch">
                              <span data-tooltip="tooltip">
                                <font-awesome-icon icon="door-closed" class="icon-fa min-width-20" />
                                <font-awesome-icon icon="level-down-alt" class="icon-fa min-width-10" />
                                <span class="tooltiptext">Front Porch</span>
                              </span>
                            Front Porch
                          </li>
                          <!-- Rear Porch -->
                          <li v-if="propertyDetails.property_details.rear_porch">
                              <span data-tooltip="tooltip">
                                <font-awesome-icon icon="door-closed" class="icon-fa min-width-20" />
                                <font-awesome-icon icon="level-up-alt" class="icon-fa min-width-10" />
                                <span class="tooltiptext">Rear Porch</span>
                              </span>
                            Rear Porch
                          </li>
                          <!-- Floors -->
                          <li v-if="propertyDetails.property_details.floors">
                              <span data-tooltip="tooltip">
                                <font-awesome-icon icon="layer-group" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Floors</span>
                              </span>
                            Floors
                          </li>
                          <!-- Attic/Loft -->
                          <li v-if="propertyDetails.property_details.attic">
                              <span data-tooltip="tooltip">
                                <font-awesome-icon icon="caret-square-up" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Attic/Loft</span>
                              </span>
                            Attic/Loft
                          </li>
                          <!-- Basement -->
                          <li v-if="propertyDetails.property_details.basement">
                              <span data-tooltip="tooltip">
                                <font-awesome-icon icon="caret-square-down" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Basement</span>
                              </span>
                            Basement
                          </li>
                          <!-- Balcony -->
                          <li v-if="propertyDetails.property_details.balcony">
                              <span data-tooltip="tooltip">
                                <font-awesome-icon icon="chess-rook" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Balcony</span>
                              </span>
                            Balcony
                          </li>
                          <!-- Charging Station -->
                          <li v-if="propertyDetails.property_details.charging_station">
                              <span data-tooltip="tooltip">
                                <font-awesome-icon icon="charging-station" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Charging Station</span>
                              </span>
                            Charging Station
                          </li>
                          <!-- Solar Panels -->
                          <li v-if="propertyDetails.property_details.solar_panels">
                              <span data-tooltip="tooltip">
                                <font-awesome-icon icon="solar-panel" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Solar Panels</span>
                              </span>
                            Solar Panels
                          </li>
                        </ul>
                      </div>
                      <div v-if="conditionsStructural" class="col-6 m-0 ps-1 p-0 border-start-separator">
                        <ul class="list-unstyled icons-listing">
                          <!-- Garden -->
                          <li v-if="conditionsGarden">
                            <span data-tooltip="tooltip">
                                <font-awesome-icon icon="seedling" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Garden</span>
                            </span>
                            {{ calculateGarden(propertyDetails.property_details.front_garden, propertyDetails.property_details.rear_garden) > 0 ? calculateGarden(propertyDetails.property_details.front_garden, propertyDetails.property_details.rear_garden) : '0' }}
                          </li>
                          <!-- Front Garden -->
                          <li v-if="propertyDetails.property_details.front_garden" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                                <font-awesome-icon icon="leaf" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Front Garden</span>
                            </span>
                            Yes
                          </li>
                          <!-- Rear Garden -->
                          <li v-if="propertyDetails.property_details.rear_garden" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                                <font-awesome-icon icon="tree" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Rear Garden</span>
                            </span>
                            Yes
                          </li>
                          <!-- Garden Terrace -->
                          <li v-if="propertyDetails.property_details.garden_terrace" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                                <font-awesome-icon icon="ruler-horizontal" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Garden Terrace</span>
                            </span>
                            Yes
                          </li>
                          <!-- Conservatory -->
                          <li v-if="propertyDetails.property_details.conservatory" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                                <font-awesome-icon icon="store-alt" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Conservatory</span>
                            </span>
                            Yes
                          </li>
                          <!-- Green House -->
                          <li v-if="propertyDetails.property_details.green_house" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                                <font-awesome-icon icon="border-none" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Green House</span>
                            </span>
                            {{ propertyDetails.property_details.green_house }}
                          </li>
                          <!-- Shed -->
                          <li v-if="propertyDetails.property_details.shed" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                                <font-awesome-icon icon="box-open" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Shed</span>
                            </span>
                            {{ propertyDetails.property_details.shed }}
                          </li>
                          <!-- Parking -->
                          <li v-if="calculateParking(propertyDetails.property_details.driveway, propertyDetails.property_details.garage, propertyDetails.property_details.parking, propertyDetails.property_details.parking_spaces)">
                            <span data-tooltip="tooltip">
                                <font-awesome-icon icon="parking" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Parking</span>
                            </span>
                            <span v-if="checkAdIsLetShortTermOrRoom(propertyDetails)">
                              {{ calculateParking(propertyDetails.property_details.driveway, propertyDetails.property_details.garage, propertyDetails.property_details.parking, propertyDetails.property_details.parking_spaces) }} / {{ propertyDetails.property_details.parking_paid ? 'Paid: £ ' + convertNumberToCommas(propertyDetails.property_details.parking_fee) : 'Free'}}
                            </span>
                            <span v-else>
                              {{ calculateParking(propertyDetails.property_details.driveway, propertyDetails.property_details.garage, propertyDetails.property_details.parking, propertyDetails.property_details.parking_spaces) }}
                            </span>
                          </li>
                          <!-- Driveway -->
                          <li v-if="propertyDetails.property_details.driveway" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                                <font-awesome-icon icon="car" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Driveway</span>
                            </span>
                            {{ propertyDetails.property_details.driveway }}
                          </li>
                          <!-- Garage -->
                          <li v-if="propertyDetails.property_details.garage" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                                <font-awesome-icon icon="warehouse" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Garage</span>
                            </span>
                            {{ propertyDetails.property_details.garage }}
                          </li>
                          <!-- Front Porch -->
                          <li v-if="propertyDetails.property_details.front_porch">
                            <span data-tooltip="tooltip">
                                <font-awesome-icon icon="door-closed" class="icon-fa min-width-20" />
                                <font-awesome-icon icon="level-down-alt" class="icon-fa min-width-10" />
                                <span class="tooltiptext">Front Porch</span>
                            </span>
                            Yes
                          </li>
                          <!-- Rear Porch -->
                          <li v-if="propertyDetails.property_details.rear_porch">
                            <span data-tooltip="tooltip">
                                <font-awesome-icon icon="door-closed" class="icon-fa min-width-20" />
                                <font-awesome-icon icon="level-up-alt" class="icon-fa min-width-10" />
                                <span class="tooltiptext">Rear Porch</span>
                            </span>
                            Yes
                          </li>
                          <!-- Floors -->
                          <li v-if="propertyDetails.property_details.floors">
                            <span data-tooltip="tooltip">
                                <font-awesome-icon icon="layer-group" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Floors</span>
                            </span>
                            {{ propertyDetails.property_details.floors }}
                          </li>
                          <!-- Attic/Loft -->
                          <li v-if="propertyDetails.property_details.attic">
                            <span data-tooltip="tooltip">
                                <font-awesome-icon icon="caret-square-up" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Attic/Loft</span>
                            </span>
                            Yes
                          </li>
                          <!-- Basement -->
                          <li v-if="propertyDetails.property_details.basement">
                            <span data-tooltip="tooltip">
                                <font-awesome-icon icon="caret-square-down" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Basement</span>
                            </span>
                            Yes
                          </li>
                          <!-- Balcony -->
                          <li v-if="propertyDetails.property_details.balcony">
                            <span data-tooltip="tooltip">
                                <font-awesome-icon icon="chess-rook" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Balcony</span>
                            </span>
                            Yes
                          </li>
                          <!-- Charging Station -->
                          <li v-if="propertyDetails.property_details.charging_station">
                            <span data-tooltip="tooltip">
                                <font-awesome-icon icon="charging-station" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Charging Station</span>
                            </span>
                            Yes
                          </li>
                          <!-- Solar Panels -->
                          <li v-if="propertyDetails.property_details.solar_panels">
                            <span data-tooltip="tooltip">
                                <font-awesome-icon icon="solar-panel" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Solar Panels</span>
                            </span>
                            Yes
                          </li>
                        </ul>
                      </div>

                      <div v-if="conditionsAdditionals" class="form-group col-12 text-center">
                        <!-- Start Horizontal Line with Icon -->
                        <div class="horizontal-separator-box">
                          <span class="hr-line-short"></span>
                          <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                          <span class="hr-line-short"></span>
                        </div>
                        <!-- End Horizontal Line with Icon -->

                        <!-- Information -->
                        <small class="p-3 fw-bold">Additional Info</small>

                        <!-- Start Horizontal Line with Icon -->
                        <div class="horizontal-separator-box">
                          <span class="hr-line-short"></span>
                          <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                          <span class="hr-line-short"></span>
                        </div>
                        <!-- End Horizontal Line with Icon -->
                      </div>

                      <div v-if="conditionsAdditionals" class="col-6 m-0 p-0">
                        <ul class="list-unstyled icons-listing">
                          <!-- Accessibility -->
                          <li v-if="propertyDetails.is_accessible">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="wheelchair" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Accessibility</span>
                            </span>
                            Accessibility
                          </li>
                          <!-- Shared Ownership -->
                          <li v-if="propertyDetails.is_shared_ownership">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="handshake" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Shared Ownership</span>
                            </span>
                            Shared Ownership
                          </li>
                          <!-- Over 60's only -->
                          <li v-if="propertyDetails.is_over_60s">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="hand-holding-heart" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Over 60's only</span>
                            </span>
                            Over 60's only
                          </li>
                          <!-- HMO -->
                          <li v-if="propertyDetails.is_hmo">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="users" class="icon-fa min-width-30" />
                              <span class="tooltiptext">HMO</span>
                            </span>
                            HMO
                          </li>
                          <!-- Furnished -->
                          <li v-if="propertyDetails.is_furnished">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="chair" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Furnished</span>
                            </span>
                            Furnished
                          </li>
                          <!-- Tenanted -->
                          <li v-if="propertyDetails.is_tenanted">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="house-user" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Tenanted</span>
                            </span>
                            Tenanted
                          </li>
                          <!-- Pet Friendly -->
                          <li v-if="propertyDetails.is_pet_friendly">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="paw" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Pet Friendly</span>
                            </span>
                            Pet Friendly
                          </li>
                          <!-- Events Allowed -->
                          <li v-if="propertyDetails.events_allowed">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="music" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Events Allowed</span>
                            </span>
                            Events Allowed
                          </li>
                          <!-- DSS Accepted -->
                          <li v-if="propertyDetails.is_dss_accepted">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="user-shield" class="icon-fa min-width-30" />
                              <span class="tooltiptext">DSS Accepted</span>
                            </span>
                            DSS Accepted
                          </li>
                          <!-- Easements and Covenants -->
                          <li v-if="propertyDetails.property_details.easements">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="exclamation-circle" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Easements and Covenants</span>
                            </span>
                            Easements and Covenants
                          </li>
                          <!-- Holiday -->
                          <li v-if="propertyDetails.is_holiday">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="plane" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Holiday</span>
                            </span>
                            Holiday
                          </li>
                          <!-- Seashore -->
                          <li v-if="propertyDetails.is_seashore">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="water" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Seashore</span>
                            </span>
                            Seashore
                          </li>
                          <!-- Waterfront -->
                          <li v-if="propertyDetails.is_waterfront">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="home" class="icon-fa min-width-20 max-width-20" />
                              <font-awesome-icon icon="water" class="icon-fa min-width-10 max-width-10" />
                              <span class="tooltiptext">Waterfront</span>
                            </span>
                            Waterfront
                          </li>
                        </ul>
                      </div>
                      <div v-if="conditionsAdditionals" class="col-6 m-0 ps-1 p-0 border-start-separator">
                        <ul class="list-unstyled icons-listing">
                          <!-- Accessibility -->
                          <li v-if="propertyDetails.is_accessible">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="wheelchair" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Accessibility</span>
                            </span>
                            Yes
                          </li>
                          <!-- Shared Ownership -->
                          <li v-if="propertyDetails.is_shared_ownership">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="handshake" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Shared Ownership</span>
                            </span>
                            Yes
                          </li>
                          <!-- Over 60's only -->
                          <li v-if="propertyDetails.is_over_60s">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="hand-holding-heart" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Over 60's only</span>
                            </span>
                            Yes
                          </li>
                          <!-- HMO -->
                          <li v-if="propertyDetails.is_hmo">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="users" class="icon-fa min-width-30" />
                              <span class="tooltiptext">HMO</span>
                            </span>
                            Yes
                          </li>
                          <!-- Furnished -->
                          <li v-if="propertyDetails.is_furnished">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="chair" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Furnished</span>
                            </span>
                            Yes
                          </li>
                          <!-- Tenanted -->
                          <li v-if="propertyDetails.is_tenanted">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="house-user" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Tenanted</span>
                            </span>
                            Yes
                          </li>
                          <!-- Pet Friendly -->
                          <li v-if="propertyDetails.is_pet_friendly">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="paw" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Pet Friendly</span>
                            </span>
                            <span v-if="propertyDetails.is_let && propertyDetails.is_short_term">
                              {{ propertyDetails.pet_friendly_paid ? 'Paid: £ ' + convertNumberToCommas(propertyDetails.pet_friendly_fee) : 'Free' }}
                            </span>
                            <span v-else>
                              Yes
                            </span>
                          </li>
                          <!-- Events Allowed -->
                          <li v-if="propertyDetails.events_allowed">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="music" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Events Allowed</span>
                            </span>
                            Yes
                          </li>
                          <!-- DSS Accepted -->
                          <li v-if="propertyDetails.is_dss_accepted">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="user-shield" class="icon-fa min-width-30" />
                              <span class="tooltiptext">DSS Accepted</span>
                            </span>
                            Yes
                          </li>
                          <!-- Easements and Covenants -->
                          <li v-if="propertyDetails.property_details.easements">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="exclamation-circle" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Easements and Covenants</span>
                            </span>
                            Yes
                          </li>
                          <!-- Holiday -->
                          <li v-if="propertyDetails.is_holiday">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="plane" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Holiday</span>
                            </span>
                            Yes
                          </li>
                          <!-- Seashore -->
                          <li v-if="propertyDetails.is_seashore">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="water" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Seashore</span>
                            </span>
                            Yes
                          </li>
                          <!-- Waterfront -->
                          <li v-if="propertyDetails.is_waterfront">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="home" class="icon-fa min-width-20 max-width-20" />
                              <font-awesome-icon icon="water" class="icon-fa min-width-10 max-width-10" />
                              <span class="tooltiptext">Waterfront</span>
                            </span>
                            Yes
                          </li>
                        </ul>
                      </div>

                      <div v-if="conditionsServicesAndAmenities" class="form-group col-12 text-center">
                        <!-- Start Horizontal Line with Icon -->
                        <div class="horizontal-separator-box">
                          <span class="hr-line-short"></span>
                          <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                          <span class="hr-line-short"></span>
                        </div>
                        <!-- End Horizontal Line with Icon -->

                        <!-- Information -->
                        <small class="p-3 fw-bold">Services & Amenities</small>

                        <!-- Start Horizontal Line with Icon -->
                        <div class="horizontal-separator-box">
                          <span class="hr-line-short"></span>
                          <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                          <span class="hr-line-short"></span>
                        </div>
                        <!-- End Horizontal Line with Icon -->
                      </div>

                      <div v-if="conditionsServicesAndAmenities" class="col-6 m-0 p-0">
                        <ul class="list-unstyled icons-listing">
                          <!-- Room Service -->
                          <li v-if="propertyDetails.property_details.room_service">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="door-open" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Room Service</span>
                            </span>
                            Room Service
                          </li>
                          <!-- Cleaning Service -->
                          <li v-if="propertyDetails.property_details.cleaning_service">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="hands-wash" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Cleaning Service</span>
                            </span>
                            Cleaning Service
                          </li>
                          <!-- Wi-Fi -->
                          <li v-if="propertyDetails.property_details.wifi">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="wifi" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Wi-Fi</span>
                            </span>
                            Wi-Fi
                          </li>
                          <!-- Breakfast -->
                          <li v-if="propertyDetails.property_details.breakfast">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="bread-slice" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Breakfast</span>
                            </span>
                            Breakfast
                          </li>
                          <!-- Gym -->
                          <li v-if="propertyDetails.property_details.gym">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="dumbbell" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Gym</span>
                            </span>
                            Gym
                          </li>
                          <!-- Cinema Room -->
                          <li v-if="propertyDetails.property_details.cinema_room">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="film" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Cinema Room</span>
                            </span>
                            Cinema Room
                          </li>
                          <!-- Sauna -->
                          <li v-if="propertyDetails.property_details.sauna">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="thermometer-full" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Sauna</span>
                            </span>
                            Sauna
                          </li>
                          <!-- Hot Tub -->
                          <li v-if="propertyDetails.property_details.hot_tub">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="hot-tub" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Hot Tub</span>
                            </span>
                            Hot Tub
                          </li>
                          <!-- Spa -->
                          <li v-if="propertyDetails.property_details.spa">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="spa" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Spa</span>
                            </span>
                            Spa
                          </li>
                          <!-- Inside Swimming Pool -->
                          <li v-if="propertyDetails.property_details.inside_pool">
                              <span data-tooltip="tooltip">
                                <font-awesome-icon icon="swimmer" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Inside Pool</span>
                              </span>
                            Inside Swimming Pool
                          </li>
                          <!-- Outside Swimming Pool -->
                          <li v-if="propertyDetails.property_details.outside_pool">
                              <span data-tooltip="tooltip">
                                <font-awesome-icon icon="swimming-pool" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Outside Pool</span>
                              </span>
                            Outside Swimming Pool
                          </li>
                          <!-- Bicycle -->
                          <li v-if="propertyDetails.property_details.bicycle">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="bicycle" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Bicycle</span>
                            </span>
                            Bicycle
                          </li>
                          <!-- Fire Rings -->
                          <li v-if="propertyDetails.property_details.fire_rings">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="fire" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Fire Rings</span>
                            </span>
                            Fire Rings
                          </li>
                          <!-- Bar -->
                          <li v-if="propertyDetails.property_details.bar">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="cocktail" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Bar</span>
                            </span>
                            Bar
                          </li>
                          <!-- Restaurant -->
                          <li v-if="propertyDetails.property_details.restaurant">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="utensils" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Restaurant</span>
                            </span>
                            Restaurant
                          </li>
                          <!-- 24h Front Desk -->
                          <li v-if="propertyDetails.property_details.h24_front_desk">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="concierge-bell" class="icon-fa min-width-30" />
                              <span class="tooltiptext">24h Front Desk</span>
                            </span>
                            24h Front Desk
                          </li>
                          <!-- Airport Shuttle -->
                          <li v-if="propertyDetails.property_details.airport_shuttle">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="plane-arrival" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Airport Shuttle</span>
                            </span>
                            Airport Shuttle
                          </li>
                          <!-- Smoking Rooms -->
                          <li v-if="propertyDetails.property_details.smoking_rooms">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="smoking" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Smoking Rooms</span>
                            </span>
                            Smoking Rooms
                          </li>
                          <!-- Non Smoking Rooms -->
                          <li v-if="propertyDetails.property_details.non_smoking_rooms">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="smoking-ban" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Non Smoking Rooms</span>
                            </span>
                            Non Smoking Rooms
                          </li>
                          <!-- Air Conditioning -->
                          <li v-if="propertyDetails.property_details.air_conditioning">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="temperature-low" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Air Conditioning</span>
                            </span>
                            Air Conditioning
                          </li>
                          <!-- Security Cameras -->
                          <li v-if="propertyDetails.property_details.security_cameras">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="eye" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Security Cameras</span>
                            </span>
                            Security Cameras
                          </li>
                          <!-- Hammocks -->
                          <li v-if="propertyDetails.property_details.hammocks">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="feather" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Hammocks</span>
                            </span>
                            Hammocks
                          </li>
                          <!-- Stove -->
                          <li v-if="propertyDetails.property_details.stove">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="fire" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Stove</span>
                            </span>
                            Stove
                          </li>
                        </ul>
                      </div>
                      <div v-if="conditionsServicesAndAmenities" class="col-6 m-0 ps-1 p-0 border-start-separator">
                        <ul class="list-unstyled icons-listing">
                          <!-- Room Service -->
                          <li v-if="propertyDetails.property_details.room_service">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="door-open" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Room Service</span>
                            </span>
                            <span v-if="propertyDetails.is_let && propertyDetails.is_short_term">
                              {{ propertyDetails.property_details.room_service_paid ? 'Paid: £ ' + convertNumberToCommas(propertyDetails.property_details.service_fee) : 'Free' }}
                            </span>
                            <span v-else>
                              Yes
                            </span>
                          </li>
                          <!-- Cleaning Service -->
                          <li v-if="propertyDetails.property_details.cleaning_service">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="hands-wash" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Cleaning Service</span>
                            </span>
                            <span v-if="propertyDetails.is_let && propertyDetails.is_short_term">
                              {{ propertyDetails.property_details.cleaning_service_paid ? 'Paid: £ ' + convertNumberToCommas(propertyDetails.property_details.cleaning_fee) : 'Free' }}
                            </span>
                            <span v-else>
                              Yes
                            </span>
                          </li>
                          <!-- Wi-Fi -->
                          <li v-if="propertyDetails.property_details.wifi">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="wifi" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Wi-Fi</span>
                            </span>
                            <span v-if="propertyDetails.is_let && propertyDetails.is_short_term">
                              {{ propertyDetails.property_details.wifi_paid ? 'Paid' : 'Free' }}
                            </span>
                            <span v-else>
                              Yes
                            </span>
                          </li>
                          <!-- Breakfast -->
                          <li v-if="propertyDetails.property_details.breakfast">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="bread-slice" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Breakfast</span>
                            </span>
                            <span v-if="propertyDetails.is_let && propertyDetails.is_short_term">
                              {{ propertyDetails.property_details.breakfast_paid ? 'Paid' : 'Free' }}
                            </span>
                            <span v-else>
                              Yes
                            </span>
                          </li>
                          <!-- Gym -->
                          <li v-if="propertyDetails.property_details.gym">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="dumbbell" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Gym</span>
                            </span>
                            <span v-if="propertyDetails.is_let && propertyDetails.is_short_term">
                              {{ propertyDetails.property_details.gym_paid ? 'Paid' : 'Free' }}
                            </span>
                            <span v-else>
                              Yes
                            </span>
                          </li>
                          <!-- Cinema Room -->
                          <li v-if="propertyDetails.property_details.cinema_room">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="film" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Cinema Room</span>
                            </span>
                            <span v-if="propertyDetails.is_let && propertyDetails.is_short_term">
                              {{ propertyDetails.property_details.cinema_room_paid ? 'Paid' : 'Free' }}
                            </span>
                            <span v-else>
                              Yes
                            </span>
                          </li>
                          <!-- Sauna -->
                          <li v-if="propertyDetails.property_details.sauna">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="thermometer-full" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Sauna</span>
                            </span>
                            <span v-if="propertyDetails.is_let && propertyDetails.is_short_term">
                              {{ propertyDetails.property_details.sauna_paid ? 'Paid' : 'Free' }}
                            </span>
                            <span v-else>
                              Yes
                            </span>
                          </li>
                          <!-- Hot Tub -->
                          <li v-if="propertyDetails.property_details.hot_tub">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="hot-tub" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Hot Tub</span>
                            </span>
                            <span v-if="propertyDetails.is_let && propertyDetails.is_short_term">
                              {{ propertyDetails.property_details.hot_tub_paid ? 'Paid' : 'Free' }}
                            </span>
                            <span v-else>
                              Yes
                            </span>
                          </li>
                          <!-- Spa -->
                          <li v-if="propertyDetails.property_details.spa">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="spa" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Spa</span>
                            </span>
                            <span v-if="propertyDetails.is_let && propertyDetails.is_short_term">
                              {{ propertyDetails.property_details.spa_paid ? 'Paid' : 'Free' }}
                            </span>
                            <span v-else>
                              Yes
                            </span>
                          </li>
                          <!-- Inside Swimming Pool -->
                          <li v-if="propertyDetails.property_details.inside_pool">
                            <span data-tooltip="tooltip">
                                <font-awesome-icon icon="swimmer" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Inside Pool</span>
                            </span>
                            <span v-if="propertyDetails.is_let && propertyDetails.is_short_term">
                              {{ propertyDetails.property_details.inside_pool_paid ? 'Paid' : 'Free' }}
                            </span>
                            <span v-else>
                              Yes
                            </span>
                          </li>
                          <!-- Outside Swimming Pool -->
                          <li v-if="propertyDetails.property_details.outside_pool">
                            <span data-tooltip="tooltip">
                                <font-awesome-icon icon="swimming-pool" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Outside Pool</span>
                            </span>
                            <span v-if="propertyDetails.is_let && propertyDetails.is_short_term">
                              {{ propertyDetails.property_details.outside_pool_paid ? 'Paid' : 'Free' }}
                            </span>
                            <span v-else>
                              Yes
                            </span>
                          </li>
                          <!-- Bicycle -->
                          <li v-if="propertyDetails.property_details.bicycle">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="bicycle" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Bicycle</span>
                            </span>
                            <span v-if="propertyDetails.is_let && propertyDetails.is_short_term">
                              {{ propertyDetails.property_details.bicycle_paid ? 'Paid' : 'Free' }}
                            </span>
                            <span v-else>
                              Yes
                            </span>
                          </li>
                          <!-- Fire Rings -->
                          <li v-if="propertyDetails.property_details.fire_rings">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="fire" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Fire Rings</span>
                            </span>
                            <span v-if="propertyDetails.is_let && propertyDetails.is_short_term">
                              {{ propertyDetails.property_details.fire_rings_paid ? 'Paid' : 'Free' }}
                            </span>
                            <span v-else>
                              Yes
                            </span>
                          </li>
                          <!-- Bar -->
                          <li v-if="propertyDetails.property_details.bar">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="cocktail" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Bar</span>
                            </span>
                            Yes
                          </li>
                          <!-- Restaurant -->
                          <li v-if="propertyDetails.property_details.restaurant">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="utensils" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Restaurant</span>
                            </span>
                            Yes
                          </li>
                          <!-- 24h Front Desk -->
                          <li v-if="propertyDetails.property_details.h24_front_desk">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="concierge-bell" class="icon-fa min-width-30" />
                              <span class="tooltiptext">24h Front Desk</span>
                            </span>
                            Yes
                          </li>
                          <!-- Airport Shuttle -->
                          <li v-if="propertyDetails.property_details.airport_shuttle">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="plane-arrival" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Airport Shuttle</span>
                            </span>
                            Yes
                          </li>
                          <!-- Smoking Rooms -->
                          <li v-if="propertyDetails.property_details.smoking_rooms">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="smoking" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Smoking Rooms</span>
                            </span>
                            Yes
                          </li>
                          <!-- Non Smoking Rooms -->
                          <li v-if="propertyDetails.property_details.non_smoking_rooms">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="smoking-ban" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Non Smoking Rooms</span>
                            </span>
                            Yes
                          </li>
                          <!-- Air Conditioning -->
                          <li v-if="propertyDetails.property_details.air_conditioning">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="temperature-low" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Air Conditioning</span>
                            </span>
                            Yes
                          </li>
                          <!-- Security Cameras -->
                          <li v-if="propertyDetails.property_details.security_cameras">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="eye" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Security Cameras</span>
                            </span>
                            Yes
                          </li>
                          <!-- Hammocks -->
                          <li v-if="propertyDetails.property_details.hammocks">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="feather" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Hammocks</span>
                            </span>
                            Yes
                          </li>
                          <!-- Stove -->
                          <li v-if="propertyDetails.property_details.stove">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="fire" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Stove</span>
                            </span>
                            Yes
                          </li>
                        </ul>
                      </div>

                      <div v-if="conditionsPolicies" class="form-group col-12 text-center">
                        <!-- Start Horizontal Line with Icon -->
                        <div class="horizontal-separator-box">
                          <span class="hr-line-short"></span>
                          <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                          <span class="hr-line-short"></span>
                        </div>
                        <!-- End Horizontal Line with Icon -->

                        <!-- Information -->
                        <small class="p-3 fw-bold">Policies</small>

                        <!-- Start Horizontal Line with Icon -->
                        <div class="horizontal-separator-box">
                          <span class="hr-line-short"></span>
                          <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                          <span class="hr-line-short"></span>
                        </div>
                        <!-- End Horizontal Line with Icon -->
                      </div>

                      <div v-if="conditionsPolicies" class="col-6 m-0 p-0">
                        <ul class="list-unstyled icons-listing">
                          <!-- Check-In -->
                          <li v-if="propertyDetails.check_in_flexible || propertyDetails.check_in_from">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="calendar-check" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Check-in Policy</span>
                            </span>
                            Check-in
                          </li>
                          <!-- Checkout -->
                          <li v-if="propertyDetails.check_out_flexible || propertyDetails.check_out_from">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="calendar-minus" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Checkout Policy</span>
                            </span>
                            Checkout
                          </li>
                          <!-- Check-in Instructions -->
                          <li v-if="propertyDetails.check_in_instructions && propertyDetails.check_in_instructions.type">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="hourglass-start" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Check-in Instructions</span>
                            </span>
                            Check-in Instructions
                          </li>
                          <!-- Checkout Instructions -->
                          <li v-if="propertyDetails.check_out_instructions && propertyDetails.check_out_instructions.type">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="hourglass-end" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Checkout Instructions</span>
                            </span>
                            Checkout Instructions
                          </li>
                          <!-- Free Cancellation Period -->
                          <li v-if="propertyDetails.cancellation_period">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="calendar-times" class="icon-fa min-width-30" />
                              <span class="tooltiptext">What is the accepted free cancellation period</span>
                            </span>
                            Free Cancellation Period
                          </li>
                          <!-- Late Cancellation Rule -->
                          <li v-if="propertyDetails.cancellation_rule && propertyDetails.cancellation_rule.rule">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="ban" class="icon-fa min-width-30" />
                              <span class="tooltiptext">What will happen if you cancel after the 'free cancellation period'</span>
                            </span>
                            Late Cancellation Rule
                          </li>
                        </ul>
                      </div>
                      <div v-if="conditionsPolicies" class="col-6 m-0 ps-1 p-0 border-start-separator">
                        <ul class="list-unstyled icons-listing">
                          <!-- Check-In -->
                          <li v-if="propertyDetails.check_in_flexible || propertyDetails.check_in_from">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="calendar-check" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Check-in Policy</span>
                            </span>
                            <span v-if="propertyDetails.check_in_flexible">
                              Flexible
                            </span>
                            <span v-else-if="propertyDetails.check_in_from">
                              {{ this.moment.utc(propertyDetails.check_in_from).format('HH:mm') }} {{ propertyDetails.check_in_to ? ' - ' + this.moment.utc(propertyDetails.check_in_to).format('HH:mm') : '' }}
                            </span>
                          </li>
                          <!-- Checkout -->
                          <li v-if="propertyDetails.check_out_flexible || propertyDetails.check_out_from">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="calendar-minus" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Checkout Policy</span>
                            </span>
                            <span v-if="propertyDetails.check_out_flexible">
                              Flexible
                            </span>
                            <span v-else-if="propertyDetails.check_out_from">
                              {{ this.moment.utc(propertyDetails.check_out_from).format('HH:mm') }} {{ propertyDetails.check_out_to ? ' - ' + this.moment.utc(propertyDetails.check_out_to).format('HH:mm') : '' }}
                            </span>
                          </li>
                          <!-- Check-in Instructions -->
                          <li v-if="propertyDetails.check_in_instructions && propertyDetails.check_in_instructions.type">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="hourglass-start" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Check-in Instructions</span>
                            </span>
                            {{ propertyDetails.check_in_instructions && propertyDetails.check_in_instructions.type ? propertyDetails.check_in_instructions.type : '' }}
                          </li>
                          <!-- Checkout Instructions -->
                          <li v-if="propertyDetails.check_out_instructions && propertyDetails.check_out_instructions.type">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="hourglass-end" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Checkout Instructions</span>
                            </span>
                            {{ propertyDetails.check_out_instructions && propertyDetails.check_out_instructions.type ? propertyDetails.check_out_instructions.type : '' }}
                          </li>
                          <!-- Free Cancellation Period -->
                          <li v-if="propertyDetails.cancellation_period">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="calendar-times" class="icon-fa min-width-30" />
                              <span class="tooltiptext">What is the accepted free cancellation period</span>
                            </span>
                            {{ propertyDetails.cancellation_period ? propertyDetails.cancellation_period : '' }}
                          </li>
                          <!-- Late Cancellation Rule -->
                          <li v-if="propertyDetails.cancellation_rule && propertyDetails.cancellation_rule.rule">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="ban" class="icon-fa min-width-30" />
                              <span class="tooltiptext">What will happen if you cancel after the 'free cancellation period'</span>
                            </span>
                            {{ propertyDetails.cancellation_rule && propertyDetails.cancellation_rule.rule ? propertyDetails.cancellation_rule.rule : '' }}{{ (propertyDetails.cancellation_rule && propertyDetails.cancellation_rule.rule && propertyDetails.cancellation_rule.rule == 'Fixed Fee Charged') ? ': £ ' + propertyDetails.cancellation_fee : '' }}
                          </li>
                        </ul>
                      </div>

                      <div v-if="conditionsDiscounts" class="form-group col-12 text-center">
                        <!-- Start Horizontal Line with Icon -->
                        <div class="horizontal-separator-box">
                          <span class="hr-line-short"></span>
                          <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                          <span class="hr-line-short"></span>
                        </div>
                        <!-- End Horizontal Line with Icon -->

                        <!-- Information -->
                        <small class="p-3 fw-bold">Discounts</small>

                        <!-- Start Horizontal Line with Icon -->
                        <div class="horizontal-separator-box">
                          <span class="hr-line-short"></span>
                          <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                          <span class="hr-line-short"></span>
                        </div>
                        <!-- End Horizontal Line with Icon -->
                      </div>

                      <div v-if="conditionsDiscounts" class="col-6 m-0 p-0">
                        <ul class="list-unstyled icons-listing">
                          <!-- Discounts -->
                          <li v-for="(discount, index) in propertyDetails.discounts" :key="index">
                            <!-- Term Discount -->
                            <div v-if="discount.discount_type && discount.discount_type.type === 'Term'">
                              <span data-tooltip="tooltip">
                                <font-awesome-icon icon="tag" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Term Discount</span>
                              </span>
                              <span>
                                Term Discount
                              </span>
                            </div>
                            <!-- Last-minute Discount -->
                            <div v-if="discount.discount_type && discount.discount_type.type === 'Last Minute'">
                              <span data-tooltip="tooltip">
                                <font-awesome-icon icon="clock" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Last-minute Discount</span>
                              </span>
                              <span>
                                Last-minute Discount
                              </span>
                            </div>
                            <!-- Guest Number Discount -->
                            <div v-if="discount.discount_type && discount.discount_type.type === 'Number of Guests'">
                              <span data-tooltip="tooltip">
                                <font-awesome-icon icon="user-friends" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Guest Number Discount</span>
                              </span>
                              <span>
                                Guest Number Discount
                              </span>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div v-if="conditionsDiscounts" class="col-6 m-0 ps-1 p-0 border-start-separator">
                        <ul class="list-unstyled icons-listing">
                          <!-- Discounts -->
                          <li v-for="(discount, index) in propertyDetails.discounts" :key="index">
                            <!-- Term Discount -->
                            <div v-if="discount.discount_type && discount.discount_type.type === 'Term'">
                              <span data-tooltip="tooltip">
                                <font-awesome-icon icon="tag" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Term Discount</span>
                              </span>
                              <span>
                                {{ discount.discount_term && discount.discount_term == '1' ? discount.discount_term + ' day' : discount.discount_term + ' days' }} {{ discount.discount_fee_type && discount.discount_fee_type == 1 ? discount.discount_fee + '% off' : '£' + discount.discount_fee + ' off'}}
                              </span>
                            </div>
                            <!-- Last-minute Discount -->
                            <div v-if="discount.discount_type && discount.discount_type.type === 'Last Minute'">
                              <span data-tooltip="tooltip">
                                <font-awesome-icon icon="clock" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Last-minute Discount</span>
                              </span>
                              <span>
                                {{ discount.discount_term && discount.discount_term == '1' ? discount.discount_term + ' day' : discount.discount_term + ' days' }} {{ discount.discount_fee_type && discount.discount_fee_type == 1 ? discount.discount_fee + '% off' : '£' + discount.discount_fee + ' off' }}
                              </span>
                            </div>
                            <!-- Guest Number Discount -->
                            <div v-if="discount.discount_type && discount.discount_type.type === 'Number of Guests'">
                              <span data-tooltip="tooltip">
                                <font-awesome-icon icon="user-friends" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Guest Number Discount</span>
                              </span>
                              <span>
                                {{ discount.discount_term ? discount.discount_term + '+ guests' : '' }} {{ discount.discount_fee_type && discount.discount_fee_type == 1 ? discount.discount_fee + '% off' : '£' + discount.discount_fee + ' off' }}
                              </span>
                            </div>
                          </li>
                        </ul>
                      </div>

                      <div v-if="conditionsHouseRules" class="form-group col-12 text-center">
                        <!-- Start Horizontal Line with Icon -->
                        <div class="horizontal-separator-box">
                          <span class="hr-line-short"></span>
                          <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                          <span class="hr-line-short"></span>
                        </div>
                        <!-- End Horizontal Line with Icon -->

                        <!-- Information -->
                        <small class="p-3 fw-bold">House Rules</small>

                        <!-- Start Horizontal Line with Icon -->
                        <div class="horizontal-separator-box">
                          <span class="hr-line-short"></span>
                          <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                          <span class="hr-line-short"></span>
                        </div>
                        <!-- End Horizontal Line with Icon -->
                      </div>

                      <div v-if="conditionsHouseRules" class="col-12 m-0 p-0">
                        <ul class="list-unstyled">
                          <li class="rule-list mt-1" v-for="rule in propertyDetails.rules">
                            {{ rule.rule }}
                          </li>
                        </ul>
                      </div>

                      <!-- Start Horizontal Line with Icon -->
                      <div class="horizontal-separator-box">
                        <span class="hr-line-short"></span>
                        <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                        <span class="hr-line-short"></span>
                      </div>
                      <!-- End Horizontal Line with Icon -->

                      <!-- Property Description Header -->
                      <div class="row text-center">
                        <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                          <p class="fw-bold m-0 p-0">Description</p>
                        </div>
                      </div>

                      <!-- Start Horizontal Line with Icon -->
                      <div class="horizontal-separator-box">
                        <span class="hr-line-short"></span>
                        <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                        <span class="hr-line-short"></span>
                      </div>
                      <!-- End Horizontal Line with Icon -->

                      <!-- Property Description Text -->
                      <div class="row text-start">
                        <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                          <div id="propDescription" class="m-0 p-0"></div>
                        </div>
                      </div>

                    </div>
                  </div>
                  <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-6">
                    <div class="row m-0 p-0">
                      <div v-if="!propertyDetails.is_rent && !propertyDetails.is_buy" class="col-12">
                        <!-- FLOORPLANS -->
                        <div id="carouselFloorplans" class="carousel slide" data-bs-ride="carousel" data-bs-interval="false">

                          <div class="carousel-inner" id="floorplansItems">
                            <div v-if="propertyDetails.documents_floorplans_images && propertyDetails.documents_floorplans_images.length === 0">
                              <img :src="require(`@/assets/no-image-found.png`)"
                                   class="d-block w-100 img-fluid thumbnails-floorplan-height"
                                   alt="..."
                              >
                            </div>
                            <!-- :class="index === activeCarouselFloorplansIndex ? 'active carousel-item' : 'carousel-item'" -->
                            <div v-for="(doc, index) in propertyDetails.documents_floorplans_images"
                                 :key="index"
                                 :class="index === 0 ? 'active carousel-item' : 'carousel-item'"
                                 :id="'carousel-inner-floorplan-'+index"
                            >
                              <img :src="`${$s3bucket}${doc.file_path}`" @error="setDefaultImg"
                                   class="d-block w-100 img-fluid thumbnails-floorplan-height"
                                   alt="..."
                              >
<!--                              <img v-if="getImage(doc.file_name)" :src="require(`@/assets/${doc.file_name}`)"-->
<!--                                   class="d-block w-100 img-fluid thumbnails-floorplan-height"-->
<!--                                   alt="..."-->
<!--                              >-->
<!--                              <img v-else :src="require(`@/assets/no-image-found.png`)"-->
<!--                                   class="d-block w-100 img-fluid thumbnails-floorplan-height"-->
<!--                                   alt="..."-->
<!--                              >-->
                              <!--                    <div class="carousel-caption d-none d-md-block">-->
                              <!--                      <h5>{{ index }} slide</h5>-->
                              <!--                      <p>Some representative placeholder content for the {{ index }} slide.</p>-->
                              <!--                    </div>-->
                            </div>
                          </div>

                          <button class="carousel-control-prev" type="button" :data-bs-target="'#carouselFloorplans'" data-bs-slide="prev"> <!-- v-on:click="setActiveFloorplan(activeCarouselFloorplansIndex-1)" -->
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Previous</span>
                          </button>
                          <button class="carousel-control-next" type="button" :data-bs-target="'#carouselFloorplans'" data-bs-slide="next"> <!-- v-on:click="setActiveFloorplan(activeCarouselFloorplansIndex+1)" -->
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Next</span>
                          </button>

                        </div>
                      </div>

<!--                      <div class="col-12 mt-2">-->
<!--                        &lt;!&ndash; Start AdSense &ndash;&gt;-->
<!--                        <AdSense></AdSense>-->
<!--                        <AdSense></AdSense>-->
<!--                        <AdSense></AdSense>-->
<!--                        <AdSense></AdSense>-->
<!--                        <AdSense></AdSense>-->
<!--                        <AdSense></AdSense>-->
<!--                        &lt;!&ndash; End AdSense &ndash;&gt;-->
<!--                      </div>-->

                    </div>
                  </div>
                </div>
              </div>

            </div>

            <!-- Start Horizontal Line with Icon -->
            <div class="horizontal-separator-box mt-4 mb-4">
              <span class="hr-line"></span>
              <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
              <span class="hr-line"></span>
            </div>
            <!-- End Horizontal Line with Icon -->

<!--            &lt;!&ndash; Property Info Details &ndash;&gt;-->
<!--            <div class="row text-start">-->
<!--              <div class="col-12 col-xs-6 col-sm-6 col-md-6 col-lg-6">-->
<!--                <ul class="list-unstyled">-->
<!--                  <li>-->
<!--                    <p class="fw-bold m-0 p-0">Description</p>-->
<!--                  </li>-->
<!--                  <li>-->
<!--                    <div id="propDescription" class="m-0 p-0"></div> &lt;!&ndash; {{ propertyDetails.property_description }} &ndash;&gt;-->
<!--                  </li>-->
<!--                </ul>-->
<!--              </div>-->
<!--              <div class="col-12 col-xs-6 col-sm-6 col-md-6 col-lg-6">-->
<!--                <div class="row m-0 p-0">-->

<!--                  <div class="col-12 mt-2">-->
<!--                    &lt;!&ndash; Start AdSense &ndash;&gt;-->
<!--                    <AdSense></AdSense>-->
<!--                    <AdSense></AdSense>-->
<!--                    <AdSense></AdSense>-->
<!--                    &lt;!&ndash; End AdSense &ndash;&gt;-->
<!--                  </div>-->

<!--                </div>-->
<!--              </div>-->
<!--            </div>-->

          </div>
        </div>
        <!-- End Loop properties -->
<!--        <a href="javascript:void(0)" id="propDetailsModalCloseButton" class="closebtn" v-on:click="closeParentPropDetailsModal">-->
<!--          <font-awesome-icon icon="angle-double-left" id="adDetailsChevronCloseBtn" class="m-0 p-0" />-->
<!--        </a>-->
      </div>

    </div>
    <div v-if="validationErrors && showError" class="global-error">
      <ValidationErrors :errors="validationErrors"/>
    </div>

  </div>
</template>

<script>
import axios from "axios"
import Loader from "./Loader.vue"
import AddBookmark from "./AddBookmark"
import ChatModal from "./ChatModal"
import Booking from "./Bookings/Booking"
import AdSense from "./AdSense"
import ValidationErrors from "./ValidationErrors";
import ShowReviewRating from "./Reviews/ShowReviewRating";
import Share from "./ShareSocials/Share";
import MiniMap from "./MiniMap";

export default {
  name: 'AdDetails',
  components: {
    'Loader': Loader,
    'AddBookmark': AddBookmark,
    'ChatModal': ChatModal,
    'Booking': Booking,
    'AdSense': AdSense,
    'ValidationErrors': ValidationErrors,
    'ShowReviewRating': ShowReviewRating,
    'Share': Share,
    'MiniMap': MiniMap
  },
  props: {
    id: Number,
  },
  data() {
    return {
      propertyDetails: null,
      activeCarouselIndex: 0,
      activeCarouselFloorplansIndex: 0,
      loading: false,
      noData: true,
      validationErrors: null,
      showError: false,
      showReportConfirmation: false,
      processing: false,
      // Reviews
      hostReviews: '',
      neighbourhoodReviews: ''
    }
  },
  mounted: function () {
    // Its totally wrong, why are we getting current user settings? we should get advertise user settings
    // this.getUserSettings();
  },
  computed: {
    conditionsHouseRules() {
      return (this.propertyDetails.rules && this.propertyDetails.rules.length > 0);
    },
    conditionsDiscounts() {
      return this.propertyDetails.discounts && this.propertyDetails.discounts.length > 0;
    },
    conditionsPolicies() {
      return this.propertyDetails.check_in_flexible ||
          this.propertyDetails.check_out_flexible ||
          this.propertyDetails.check_in_from ||
          this.propertyDetails.check_in_to ||
          this.propertyDetails.check_out_from ||
          this.propertyDetails.check_out_to ||
          this.propertyDetails.cancellation_period ||
          this.propertyDetails.cancellation_late_rule_id

    },
    conditionsSuiteApartment() {
      return this.propertyDetails.property_details.single_room_suite || this.propertyDetails.property_details.double_room_suite;
    },
    conditionsMainFeatures() {
      return this.propertyDetails.property_details.single_room ||
          this.propertyDetails.property_details.double_room ||
          this.propertyDetails.property_details.studio_room ||
          this.propertyDetails.property_details.living_room ||
          this.propertyDetails.property_details.dining_room ||
          this.propertyDetails.property_details.box_room
    },
    conditionsStructural() {
      return this.propertyDetails.property_details.front_garden ||
          this.propertyDetails.property_details.rear_garden ||
          this.propertyDetails.property_details.garden_terrace ||
          this.propertyDetails.property_details.conservatory ||
          this.propertyDetails.property_details.green_house ||
          this.propertyDetails.property_details.shed ||
          this.propertyDetails.property_details.driveway ||
          this.propertyDetails.property_details.garage ||
          this.propertyDetails.property_details.parking ||
          this.propertyDetails.property_details.front_porch ||
          this.propertyDetails.property_details.rear_porch ||
          this.propertyDetails.property_details.floors ||
          this.propertyDetails.property_details.attic ||
          this.propertyDetails.property_details.basement ||
          this.propertyDetails.property_details.balcony ||
          this.propertyDetails.property_details.charging_station ||
          this.propertyDetails.property_details.solar_panels
    },
    conditionsStatus() {
      return this.propertyDetails.is_mixed_use ||
          this.propertyDetails.is_auction ||
          this.propertyDetails.is_cash ||
          this.propertyDetails.is_investment
    },
    conditionsAdditionals() {
      return this.propertyDetails.is_accessible ||
          this.propertyDetails.events_allowed ||
          this.propertyDetails.is_shared_ownership ||
          this.propertyDetails.is_over_60s ||
          this.propertyDetails.is_hmo ||
          this.propertyDetails.is_furnished ||
          this.propertyDetails.is_tenanted ||
          this.propertyDetails.is_pet_friendly ||
          this.propertyDetails.is_dss_accepted ||
          this.propertyDetails.property_details.easements ||
          this.propertyDetails.is_holiday ||
          this.propertyDetails.is_seashore ||
          this.propertyDetails.is_waterfront
    },
    conditionsServicesAndAmenities() {
      return this.propertyDetails.property_details.gym ||
          this.propertyDetails.property_details.cinema_room ||
          this.propertyDetails.property_details.sauna ||
          this.propertyDetails.property_details.hot_tub ||
          this.propertyDetails.property_details.inside_pool ||
          this.propertyDetails.property_details.outside_pool ||
          this.propertyDetails.property_details.wifi ||
          this.propertyDetails.property_details.spa ||
          this.propertyDetails.property_details.bicycle ||
          this.propertyDetails.property_details.fire_rings ||
          this.propertyDetails.property_details.bar ||
          this.propertyDetails.property_details.smoking_rooms ||
          this.propertyDetails.property_details.non_smoking_rooms ||
          this.propertyDetails.property_details.air_conditioning ||
          this.propertyDetails.property_details.hammocks ||
          this.propertyDetails.property_details.security_cameras ||
          this.propertyDetails.property_details.room_service ||
          this.propertyDetails.property_details.cleaning_service ||
          this.propertyDetails.property_details.breakfast ||
          this.propertyDetails.property_details.restaurant ||
          this.propertyDetails.property_details.h24_front_desk ||
          this.propertyDetails.property_details.airport_shuttle
    },
    conditionsBathroom() {
      return this.propertyDetails.property_details.bath_room ||
          this.propertyDetails.property_details.wc ||
          this.propertyDetails.property_details.downstairs_wc ||
          this.propertyDetails.property_details.shower ||
          this.propertyDetails.property_details.bath ||
          this.propertyDetails.property_details.sink
    },
    conditionsKitchen() {
      return this.propertyDetails.property_details.kitchen ||
          this.propertyDetails.property_details.oven ||
          this.propertyDetails.property_details.fridge ||
          this.propertyDetails.property_details.freezer ||
          this.propertyDetails.property_details.microwave ||
          this.propertyDetails.property_details.kettle ||
          this.propertyDetails.property_details.toaster ||
          this.propertyDetails.property_details.coffee
    },
    conditionsGarden() {
      return this.calculateGarden(this.propertyDetails.property_details.front_garden, this.propertyDetails.property_details.rear_garden) ||
          this.propertyDetails.property_details.garden_terrace ||
          this.propertyDetails.property_details.conservatory ||
          this.propertyDetails.property_details.green_house ||
          this.propertyDetails.property_details.shed
    }
  },
  methods: {
    /* *** REVIEWS *** */
    calculateTotalRating(reviews, type) {
      if (reviews && reviews.length > 0) {
        let sum = 0;

        if (type && type == 'host') {
          reviews.forEach((review) => {
            sum = sum + ((review.review_communication + review.review_cleanliness + review.review_location + review.review_value) / 4);
          });
        }
        if (type && type == 'neighbourhood') {
          reviews.forEach((review) => {
            sum = sum + ((review.review_amenities + review.review_crime + review.review_community + review.review_location) / 4);
          });
        }

        return Math.round(((sum / reviews.length) + Number.EPSILON) * 10) / 10;
      } else {
        return 0;
      }
    },
    async getHostReviews() {
      this.processing = true;

      let data = new FormData();
      data.append("ad_id", this.id);

      await axios.post('api/host-reviews', data).then(({data})=>{
        this.hostReviews = data.data;
        this.validationErrors = null;
      }).catch(({response})=>{
        if (response && response.data && response.data.errors) {
          this.validationErrors = response.data.errors;
          this.validationMessage = response.data.message;
        }
      }).finally(()=>{
        this.processing = false;
      })
    },
    async getNeighbourhoodReviews() {
      this.processing = true;

      let data = new FormData();
      data.append("ad_id", this.id);
      data.append("address_id", this.propertyDetails.address.id);
      data.append("radius", 5); // 5 miles

      await axios.post('api/neighbourhood-reviews', data).then(({data})=>{
        this.neighbourhoodReviews = data.data;
        this.validationErrors = null;
      }).catch(({response})=>{
        if (response && response.data && response.data.errors) {
          this.validationErrors = response.data.errors;
          this.validationMessage = response.data.message;
        }
      }).finally(()=>{
        this.processing = false;
      })
    },
    /* *** END REVIEWS *** */
    setShareSocials() {
      return {
        facebook: true,
        facebook_link: 'ad-details/' + this.id,
        twitter: true,
        twitter_link: 'ad-details/' + this.id,
        email: true,
        email_link: 'ad-details/' + this.id,
        linkedin: true,
        linkedin_link: 'ad-details/' + this.id,
        whatsapp: true,
        whatsapp_link: 'ad-details/' + this.id,
        facebook_messenger: true,
        facebook_messenger_link: 'ad-details/' + this.id,
        copy: true,
        copy_link: 'ad-details/' + this.id,
        tooltip_text: 'Share'
      };
    },
    // Helpers for LET ads to reflect short-term and long-term lets data
    checkAdIsLetShortTermOrRoom(prop) {
      if (prop && prop.is_let && ((prop.is_short_term && !prop.property_details.studio_room) || prop.is_room)) {
        return true;
      } else {
        return false;
      }
    },
    checkAdIsLetLongTermOrStudio(prop) {
      if (prop && prop.is_let && ((!prop.is_short_term && !prop.is_room) || prop.property_details.studio_room)) {
        return true;
      } else {
        return false;
      }
    },
    showPriceRange(prop) {
      if (this.checkAdIsLetShortTermOrRoom(prop)) {
        return true;
      } else {
        return false;
      }
    },
    showHourPriceRange(prop) {
      if (prop.property_rooms && prop.property_rooms.length > 0) {
        // get hourly prices
        let hourlyPrices = prop.property_rooms.filter(obj => {
          if (obj.property_rent_type_id !== 2) {
            return false;
          } else {
            return true;
          }
        }).map(object => {
          return object.price;
        });

        if (hourlyPrices.length > 0) {
          return true;
        } else {
          return false;
        }
      }
    },
    calculateHourPriceRange(prop) {
      if (prop.property_rooms && prop.property_rooms.length > 0) {
        let maxHourlyPrice = null;
        let minHourlyPrice = null;

        // get hourly prices
        let hourlyPrices = prop.property_rooms.filter(obj => {
          if (obj.property_rent_type_id !== 2) {
            return false;
          } else {
            return true;
          }
        }).map(object => {
          return object.price;
        });

        if (hourlyPrices.length > 0) {
          maxHourlyPrice = Math.max.apply(null, hourlyPrices);
          minHourlyPrice = Math.min.apply(null, hourlyPrices);
        }

        if (minHourlyPrice && maxHourlyPrice) {
          if (minHourlyPrice == maxHourlyPrice) {
            return this.convertNumberToCommas(minHourlyPrice);
          } else {
            return this.convertNumberToCommas(minHourlyPrice) + ' - ' + this.convertNumberToCommas(maxHourlyPrice);
          }
        }
      }
    },
    showNightPriceRange(prop) {
      if (prop.property_rooms && prop.property_rooms.length > 0) {
        // get nightly prices
        let nightlyPrices = prop.property_rooms.filter(obj => {
          if (obj.property_rent_type_id !== 3) {
            return false;
          } else {
            return true;
          }
        }).map(object => {
          return object.price;
        });

        if (nightlyPrices.length > 0) {
          return true;
        } else {
          return false;
        }
      }
    },
    calculateNightPriceRange(prop) {
      if (prop.property_rooms && prop.property_rooms.length > 0) {
        let maxNightlyPrice = null;
        let minNightlyPrice = null;

        // get nightly prices
        let nightlyPrices = prop.property_rooms.filter(obj => {
          if (obj.property_rent_type_id !== 3) {
            return false;
          } else {
            return true;
          }
        }).map(object => {
          return object.price;
        });

        if (nightlyPrices.length > 0) {
          maxNightlyPrice = Math.max.apply(null, nightlyPrices);
          minNightlyPrice = Math.min.apply(null, nightlyPrices);
        }

        if (minNightlyPrice && maxNightlyPrice) {
          if (minNightlyPrice == maxNightlyPrice) {
            return this.convertNumberToCommas(minNightlyPrice);
          } else {
            return this.convertNumberToCommas(minNightlyPrice) + ' - ' + this.convertNumberToCommas(maxNightlyPrice);
          }
        }
      }
    },
    showWeekPriceRange(prop) {
      if (prop.property_rooms && prop.property_rooms.length > 0) {
        // get weekly prices
        let weeklyPrices = prop.property_rooms.filter(obj => {
          if (obj.property_rent_type_id !== 4) {
            return false;
          } else {
            return true;
          }
        }).map(object => {
          return object.price;
        });

        if (weeklyPrices.length > 0) {
          return true;
        } else {
          return false;
        }
      }
    },
    calculateWeekPriceRange(prop) {
      if (prop.property_rooms && prop.property_rooms.length > 0) {
        let maxWeeklyPrice = null;
        let minWeeklyPrice = null;

        // get weekly prices
        let weeklyPrices = prop.property_rooms.filter(obj => {
          if (obj.property_rent_type_id !== 4) {
            return false;
          } else {
            return true;
          }
        }).map(object => {
          return object.price;
        });

        if (weeklyPrices.length > 0) {
          maxWeeklyPrice = Math.max.apply(null, weeklyPrices);
          minWeeklyPrice = Math.min.apply(null, weeklyPrices);
        }

        if (minWeeklyPrice && maxWeeklyPrice) {
          if (minWeeklyPrice == maxWeeklyPrice) {
            return this.convertNumberToCommas(minWeeklyPrice);
          } else {
            return this.convertNumberToCommas(minWeeklyPrice) + ' - ' + this.convertNumberToCommas(maxWeeklyPrice);
          }
        }
      }
    },
    showMonthPriceRange(prop) {
      if (prop.property_rooms && prop.property_rooms.length > 0) {
        // get monthly prices
        let monthlyPrices = prop.property_rooms.filter(obj => {
          if (obj.property_rent_type_id !== 5) {
            return false;
          } else {
            return true;
          }
        }).map(object => {
          return object.price;
        });

        if (monthlyPrices.length > 0) {
          return true;
        } else {
          return false;
        }
      }
    },
    calculateMonthPriceRange(prop) {
      if (prop.property_rooms && prop.property_rooms.length > 0) {
        let maxMonthlyPrice = null;
        let minMonthlyPrice = null;

        // get monthly prices
        let monthlyPrices = prop.property_rooms.filter(obj => {
          if (obj.property_rent_type_id !== 5) {
            return false;
          } else {
            return true;
          }
        }).map(object => {
          return object.price;
        });

        if (monthlyPrices.length > 0) {
          maxMonthlyPrice = Math.max.apply(null, monthlyPrices);
          minMonthlyPrice = Math.min.apply(null, monthlyPrices);
        }

        if (minMonthlyPrice && maxMonthlyPrice) {
          if (minMonthlyPrice == maxMonthlyPrice) {
            return this.convertNumberToCommas(minMonthlyPrice);
          } else {
            return this.convertNumberToCommas(minMonthlyPrice) + ' - ' + this.convertNumberToCommas(maxMonthlyPrice);
          }
        }
      }
    },
    // End Helpers for LET ads to reflect short-term and long-term lets data
    sendReviewModal(adDetails, reviewType, modalType) {
      // Neighbourhood Necessary data:
      // adId, propertyDetails(address), reviewType (neighbourhood)
      // Service Necessary data:
      // adId, serviceDetails, reviewType (service), toUserId (service provider id), serviceOccupationId
      // Host Necessary data:
      // adId, propertyDetails, reviewType (host), toUserId (host id)
      // Guest Necessary data:
      // adId, propertyDetails, reviewType (guest), toUserId (guest id)
      let data = {
        adId: adDetails.id,
        adDetails: adDetails,
        toUserId: adDetails.user.id,
        reviewType: reviewType
      };
      this.$root.$emit('openModal', data, modalType);
    },
    sendModal(propertyDetails, adType, booking = null, modalType) {
      let data = {
        adDetails: propertyDetails,
        toUserId: propertyDetails.user.id,
        fromUserId: null,
        adId: propertyDetails.id,
        adType: adType,
        booking: booking,
        message: null
      };
      if (modalType === 'Booking') {
        // We use below to refresh modal in App.vue by changing one of its bool keys
        this.$root.$emit('refreshModal', 'bookingModal');
      }
      this.$root.$emit('openModal', data, modalType);
    },
    sendAddNeighbourhoodReviewModal(adData, adType, modalType, reviewType = null) {
      // Only authenticated user can open this modal
      if (this.$store.state.auth.user && this.$store.state.auth.authenticated && this.$store.state.auth.user.id) {
        let data = {
          adDetails: adData,
          toUserId: null,
          fromUserId: this.$store.state.auth.user.id,
          adId: adData.id,
          adType: 'Property',
          reviewType: reviewType ? reviewType : null,
          message: ""
        };
        this.$root.$emit('openModal', data, modalType);
      }
    },
    async reportAdvertise(id, type, report_type) {

      if (!confirm("Are you sure you want to report this advertise?")) {
        return;
      }
      this.processing = true;

      // Create form: type should be either Property or Service
      let requestForm = new FormData();
      requestForm.append("id", id);
      requestForm.append("type", type);
      requestForm.append("report_type", report_type);

      await axios.post("api/report/", requestForm).then(response => {
        this.validationSuccess = response.data.success;
        this.showReportConfirmation = true;
        const timeout = setTimeout(() => {
          this.showReportConfirmation = false;
          this.validationSuccess = null;
          clearTimeout(timeout);
        }, 4000);
        this.validationErrors = null;
      }).catch(({response}) => {
        if (response && response.data && response.data.errors) {
          this.validationErrors = response.data.errors;
          // this.validationMessage = data.message;
        }
      }).finally(() => {
        this.processing = false

        // Refresh user data so we get new favourites in the listing
        if (this.validationErrors === null) {
          this.$root.$emit('refreshUserProperties', null, this.$store.state.auth.user.id, 15);
        }
      })
    },
    preventParentClick() {
      // Do nothing, its just used to prevent parent function trigger (setActivePropertyDetailsThumbnail)
    },
    getImage(file_name) {
      try {
        return require(`@/assets/${file_name}`);
      } catch (e) {
        return false
      }
    },
    setDefaultImg(e) {
      e.target.src = require(`@/assets/no-image-found.png`);
    },
    setUserDefaultImg(e) {
      e.target.src = require(`@/assets/no-user-image.png`);
    },
    // Loop user settings
    checkUserSettings(settings, setting_type_id) {
      for (let i = 0; i < settings.length; i++) {
        if (settings[i].setting_type_id === setting_type_id) {
          if (settings[i].setting_value === '1') {
            return true;
          } else {
            return false;
          }
        }
      }
      // If there is no setting_type_id for that user, return false
      return false;
    },
    async getPropertyDetails(id) {
      this.noData = false;
      this.loading = true;
      // if (this.$store.state.properties.properties.data) {
      //   for (let i = 0; i < this.$store.state.properties.properties.data.length; i++) {
      //     if (id === this.$store.state.properties.properties.data[i].id) {
      //       this.propertyDetails = this.$store.state.properties.properties.data[i];
      //     }
      //   }
      // }

      await axios.get('api/properties/' + id).then(({data}) => {
        this.propertyDetails = data.data;
        this.loading = false;
      }).catch(({response}) => {
        if (response && response.data && response.data.errors) {
          this.validationErrors = response.data.errors;
        }
        this.loading = false;
        this.noData = true;
        // alert(response.data.message)
      }).finally(() => {
        this.processing = false

        if (this.propertyDetails.property_description) {
          // Set Description Html Field
          this.parseHTML(this.propertyDetails.property_description, "propDescription");
        }
      })
    },
    closeParentPropDetailsModal() {
      this.$emit('closePropDetailsModal');
    },
    parseHTML(html, elementId) {
      let element = document.getElementById(elementId);
      element.innerHTML = '';
      element.insertAdjacentHTML('beforeend', html);
    },
    calculateMobile(user) {
      if (user.mobile_prefix && user.mobile_prefix !== '') {
        return user.mobile_prefix + ' ' + user.mobile;
      } else {
        return user.mobile;
      }
    },
    calculateLanding(user) {
      if (user.landing_prefix && user.landing_prefix !== '') {
        return user.landing_prefix + ' ' + user.landing;
      } else {
        return user.landing;
      }
    },
    calculateBeds(propDetails) {
      return Number(propDetails.single_room) + Number(propDetails.double_room) + Number(propDetails.twin_room) + Number(propDetails.twin_double_room) +
          Number(propDetails.triple_room) + Number(propDetails.quadruple_room) + Number(propDetails.family_room) + Number(propDetails.studio_room);
    },
    calculateSuiteBeds(propDetails) {
      return Number(propDetails.single_room_suite) + Number(propDetails.double_room_suite);
    },
    calculateParking(driveway = 0, garage = 0, parking = 0, parkingSpaces = 0) {
      if (parking && parkingSpaces > 0) {
        return Number(driveway) + Number(garage) + Number(parkingSpaces);
      } else {
        return Number(driveway) + Number(garage) + Number(parking);
      }
    },
    calculateGarden(frontGarden, rearGarden) {
      return Number(frontGarden) + Number(rearGarden);
    },
    calculateCreatedDate(date) {
      let resultDate = null;

      let currentDate = new Date();
      let propDate = new Date(date);
      let numberOfDays = (currentDate.getTime() - propDate.getTime()) / (1000 * 60 * 60 * 24);

      switch (true) {
        case numberOfDays <= 1:
          resultDate = "Today";
          break;
        case (numberOfDays <= 30 && numberOfDays > 1):
          if (numberOfDays < 2) {
            resultDate = Math.round(numberOfDays) + " day ago";
          } else {
            resultDate = Math.round(numberOfDays) + " days ago";
          }
          break;
        case numberOfDays > 30:
          let numberOfMonths = Math.round(numberOfDays / 30);
          if (numberOfMonths === 1) {
            resultDate = numberOfMonths + " month ago";
          } else {
            resultDate = numberOfMonths + " months ago"
          }
          break;
      }

      return resultDate;

    },
    convertNumberToCommas(num) {
      if (num && num !== '' && num !== null) {
        return num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return 0;
      }
    },
    scrollDetailsIntoView() {
      /* Use of double !! does not return dom element, just bool true/false */
      let doesElExist = !!document.getElementById("scrollIntoView");
      if (doesElExist) {
        document.getElementById("scrollIntoView").scrollIntoView({behavior: 'smooth', block: 'start'});
      }
    },
    /* I might not need that anymore, up there removed its trigger on prev and next slides */
    setActive(index) {
      /*
        As slides are counted from 0 to x slides, negative values would mean we want to
        end up on LAST possible slide, hence if index < 0 we count number of child elements (slides)
        and assign last number -1 (elements count might be 1-2-3, but slides counts from 0-1-2)

        This solution works, nevertheless FAST clicking on slide change interrupts it, I might need
        to set it as async and promise
      */
      let countChildren = document.getElementById('adDetailItems').childElementCount;

      if (countChildren) {
        switch (index) {
          case index < 0:
            // console.log('index < 0');
            // console.log('Index: ' + index);
            // console.log('Count children: ' + countChildren);
            this.activeCarouselIndex = countChildren - 1;
            // console.log(this.activeCarouselIndex);
            break;
          case (index >= 0 && index <= (countChildren - 1)):
            // console.log('index >= 0');
            // console.log('Index: ' + index);
            this.activeCarouselIndex = index;
            break;
          case index > (countChildren - 1):
            // console.log('index = '+ (countChildren));
            // console.log('Index: ' + index);
            this.activeCarouselIndex = 0;
            break;
        }
      }
    },
    /* I might not need that anymore, up there removed its trigger on prev and next slides */
    setActiveFloorplan(index) {
      /*
        As slides are counted from 0 to x slides, negative values would mean we want to
        end up on LAST possible slide, hence if index < 0 we count number of child elements (slides)
        and assign last number -1 (elements count might be 1-2-3, but slides counts from 0-1-2)

        This solution works, nevertheless FAST clicking on slide change interrupts it, I might need
        to set it as async and promise
      */
      let countFloorplansChildren = document.getElementById('floorplansItems').childElementCount;

      if (countFloorplansChildren) {
        switch (index) {
          case index < 0:
            // console.log('index < 0');
            // console.log('Index: ' + index);
            // console.log('Count children: ' + countChildren);
            this.activeCarouselFloorplansIndex = countFloorplansChildren - 1;
            // console.log(this.activeCarouselIndex);
            break;
          case (index >= 0 && index <= (countFloorplansChildren - 1)):
            // console.log('index >= 0');
            // console.log('Index: ' + index);
            this.activeCarouselFloorplansIndex = index;
            break;
          case index > (countFloorplansChildren - 1):
            // console.log('index = '+ (countChildren));
            // console.log('Index: ' + index);
            this.activeCarouselFloorplansIndex = 0;
            break;
        }
      }
    },
  },
  updated() {
    this.$nextTick(function () {
      // Code that will run only after the entire view has been re-rendered

      // /*
      //     AdDetail Slides carousel
      //     Cheating a bit, as v-for bootstrap 5 carousel seems to not be as reactive as I would like it to be.
      //     It does not reset v-for INDEX on pure carousel data change, so I had to use below functionality,
      //     so that I can remove all current 'active' class and set activeCarouselIndex back to 0 (start point)
      //    */
      // let carouselItems = document.getElementById('adDetailItems');
      // let countChildren = null;
      //
      // if(carouselItems != null) {
      //   countChildren = document.getElementById('adDetailItems').childElementCount;
      // }
      // if(carouselItems != null && countChildren > 0) {
      //   let i = 0;
      //   for(i; i<countChildren; i++) {
      //     let carouselElement = document.getElementById('carousel-inner-'+i);
      //     if(carouselElement) {
      //       if (document.getElementById('carousel-inner-' + i).classList.contains('active')) {
      //         document.getElementById('carousel-inner-' + i).classList.remove('active');
      //       }
      //     }
      //   }
      //
      //   let carouselFirstElement = document.getElementById('carousel-inner-0');
      //   if(carouselFirstElement) {
      //     if (!document.getElementById('carousel-inner-0').classList.contains('active')) {
      //       document.getElementById('carousel-inner-0').classList.add('active');
      //     }
      //   }
      // }
      //
      // this.activeCarouselIndex = 0;
      //
      // /* Floorplan carousel */
      // let carouselFloorplanItems = document.getElementById('floorplansItems');
      // let countFloorplanChildren = null;
      //
      // if(carouselFloorplanItems != null) {
      //   countFloorplanChildren = document.getElementById('floorplansItems').childElementCount;
      // }
      // if(carouselFloorplanItems != null && countFloorplanChildren > 0) {
      //   let i = 0;
      //   for(i; i<countFloorplanChildren; i++) {
      //     let carouselFloorplanElement = document.getElementById('carousel-inner-floorplan-'+i);
      //     if(carouselFloorplanElement) {
      //       if (document.getElementById('carousel-inner-floorplan-' + i).classList.contains('active')) {
      //         document.getElementById('carousel-inner-floorplan-' + i).classList.remove('active');
      //       }
      //     }
      //   }
      //
      //   let carouselFloorplanElement = document.getElementById('carousel-inner-floorplan-0');
      //   if(carouselFloorplanElement) {
      //     if (!document.getElementById('carousel-inner-floorplan-0').classList.contains('active')) {
      //       document.getElementById('carousel-inner-floorplan-0').classList.add('active');
      //     }
      //   }
      // }
      //
      // this.activeCarouselFloorplansIndex = 0;
    })
  },
  watch: {
    "id": {
      handler: async function (id) {
        if (id === null) {
          this.loading = true;
          this.propertyDetails = null;
          this.loading = false;
        }
        if (id !== null) {
          await this.getPropertyDetails(this.$props.id);

          // Get reviews
          await this.getHostReviews();
          await this.getNeighbourhoodReviews();

          /*
            AdDetail Slides carousel
            Cheating a bit, as v-for bootstrap 5 carousel seems to not be as reactive as I would like it to be.
            It does not reset v-for INDEX on pure carousel data change, so I had to use below functionality,
            so that I can remove all current 'active' class and set activeCarouselIndex back to 0 (start point)
           */
          let carouselItems = document.getElementById('adDetailItems');
          let countChildren = null;

          if (carouselItems != null) {
            countChildren = document.getElementById('adDetailItems').childElementCount;
          }

          let carouselThumbnails = document.getElementById('carouselThumbnailsPropDetails');
          let countCarouselThumbnailsChildren = null;

          if (carouselThumbnails != null) {
            countCarouselThumbnailsChildren = document.getElementById('carouselThumbnailsPropDetails').childElementCount;
          }

          if (carouselItems != null && countChildren > 0) {
            let i = 0;
            for (i; i < countChildren; i++) {
              let carouselElement = document.getElementById('carousel-inner-' + i);
              let carouselThumbElement = document.getElementById('carousel-thumb-' + i);
              if (carouselElement && carouselThumbElement) {
                if (document.getElementById('carousel-inner-' + i).classList.contains('active')) {
                  document.getElementById('carousel-inner-' + i).classList.remove('active');
                }
                if (document.getElementById('carousel-thumb-' + i).classList.contains('active')) {
                  document.getElementById('carousel-thumb-' + i).classList.remove('active');
                }
              }
            }

            let carouselFirstElement = document.getElementById('carousel-inner-0');
            let carouselThumbFirstElement = document.getElementById('carousel-thumb-0');
            if (carouselFirstElement && carouselThumbFirstElement) {
              if (!document.getElementById('carousel-inner-0').classList.contains('active')) {
                document.getElementById('carousel-inner-0').classList.add('active');
              }
              if (!document.getElementById('carousel-thumb-0').classList.contains('active')) {
                document.getElementById('carousel-thumb-0').classList.add('active');
              }
            }
          }

          // this.activeCarouselIndex = 0;

          /* Floorplan carousel */
          let carouselFloorplanItems = document.getElementById('floorplansItems');
          let countFloorplanChildren = null;

          if (carouselFloorplanItems != null) {
            countFloorplanChildren = document.getElementById('floorplansItems').childElementCount;
          }
          if (carouselFloorplanItems != null && countFloorplanChildren > 0) {
            let i = 0;
            for (i; i < countFloorplanChildren; i++) {
              let carouselFloorplanElement = document.getElementById('carousel-inner-floorplan-' + i);
              if (carouselFloorplanElement) {
                if (document.getElementById('carousel-inner-floorplan-' + i).classList.contains('active')) {
                  document.getElementById('carousel-inner-floorplan-' + i).classList.remove('active');
                }
              }
            }

            let carouselFloorplanElement = document.getElementById('carousel-inner-floorplan-0');
            if (carouselFloorplanElement) {
              if (!document.getElementById('carousel-inner-floorplan-0').classList.contains('active')) {
                document.getElementById('carousel-inner-floorplan-0').classList.add('active');
              }
            }
          }

          // this.activeCarouselFloorplansIndex = 0;
        }
      }, deep: true, immediate: true,
    },
    "validationErrors": function (val) {
      if (val !== null) {
        this.showError = true;
        const timeout = setTimeout(() => {
          this.$root.$emit('globalRefreshUser');
          this.showError = false;
          if (this.validationErrors && this.validationErrors.ad_does_not_exist) {
            this.$emit('closePropDetailsModal');
          }
          clearTimeout(timeout);
        }, 4000);
        return;
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

/* Start Review Icons */
.review-icons-container {
  width: 100%;
  height: auto;
  .icon-wrapper {
    width: fit-content;
    width: -moz-fit-content;
  }
}

.small-star {
  transform: scale(1);
  transition: 0.5s;
  -moz-transition: 0.5s;
  color: yellow;
  font-size: 12px;
  position: absolute;
  top: 4px;
  right: -6px;
}

.small-star path {
  stroke: black;
  stroke-width: 20;
}

.neighbourhood-review {
  transform: scale(1);
  transition: 0.5s;
  -moz-transition: 0.5s;
  color: limegreen;
}

.neighbourhood-review path {
  stroke: black;
  stroke-width: 20;
}

.neighbourhood-review:hover {
  color: red;
  transform: scale(1.1);
  transition: 0.5s;
  -moz-transition: 0.5s;
}

.add-icon-container {
  width: 100%;
  height: 100%;
}

.add-icon {
  color: #0d6efd;
  font-size: 25px;
  border: 1px solid #0d6efd;
  border-radius: 50%;
  transition: 0.5s;
  -moz-transition: 0.5s;
}

.add-icon:hover {
  color: white;
  background-color: #0d6efd;
  border: 1px solid #0d6efd;
  transform: rotate(90deg);
  transition: 0.5s;
  -moz-transition: 0.5s;
}
/* End Review Icons */

/* Start Action Buttons */
.ad-details .action-dropdown {
  z-index: 9999;
  min-height: 30px;
  text-align: center;
  background-color: rgba(255, 255, 255 , 0);
  color: #868e96;
  cursor: pointer;

  .dropdown-menu {
    //top: 15px !important;
  }

  .icon-report {
    transform: scale(1);
    transition: 0.5s;
    -moz-transition: 0.5s;
    color: orange;
  }

  .icon-report path {
    stroke: black;
    stroke-width: 20;
  }

  .icon-report:hover {
    color: red;
    transform: scale(1.1);
    transition: 0.5s;
    -moz-transition: 0.5s;
  }

  .action-button-report {
    background-color: unset;
    border: none;
    transition: 0.5s;
    -moz-transition: 0.5s;
  }

  .action-button-report:hover {
    background-color: unset;
    border: none;
    transition: 0.5s;
    -moz-transition: 0.5s;
  }

  .action-button-report:hover .icon-report {
    color: red;
    transition: 0.5s;
    -moz-transition: 0.5s;
  }

  .action-button-report .tooltiptext {
    position: absolute;
    top: -25px;
    width: max-content !important;
  }
}

.action-dropdown:hover {
  cursor: pointer;
}

.action-buttons {
  z-index: 1001;
  .action-item {
    padding: 2px 5px 2px 5px;
    margin: 2px 5px 2px 5px;
    min-width: 80px;
    font-size: 13px;
  }

  .action-button-spam {
    text-align: start;
    min-height: 30px;
    width: 100%;
    background-color: unset;
    border: none;
    //border-bottom: 3px solid deepskyblue;
    min-width: 80px;
    transition: 0.5s;
    -moz-transition: 0.5s;
    transform: scale(1);
  }

  .action-button-spam:hover {
    background-color: unset;
    border: none;
    transition: 0.5s;
    -moz-transition: 0.5s;
    transform: scale(1.1);
  }

  .action-button-spam:hover .icon-spam {
    color: mediumpurple;
    transition: 0.5s;
    -moz-transition: 0.5s;
  }

  .action-button-scam {
    text-align: start;
    min-height: 30px;
    width: 100%;
    background-color: unset;
    border: none;
    //border-bottom: 3px solid deepskyblue;
    min-width: 80px;
    transition: 0.5s;
    -moz-transition: 0.5s;
    transform: scale(1);
  }

  .action-button-scam:hover {
    background-color: unset;
    border: none;
    transition: 0.5s;
    -moz-transition: 0.5s;
    transform: scale(1.1);
  }

  .action-button-scam:hover .icon-scam {
    color: mediumblue;
    transition: 0.5s;
    -moz-transition: 0.5s;
  }

  .action-button-abuse {
    text-align: start;
    min-height: 30px;
    width: 100%;
    background-color: unset;
    border: none;
    //border-bottom: 3px solid deepskyblue;
    min-width: 80px;
    transition: 0.5s;
    -moz-transition: 0.5s;
    transform: scale(1);
  }

  .action-button-abuse:hover {
    background-color: unset;
    border: none;
    transition: 0.5s;
    -moz-transition: 0.5s;
    transform: scale(1.1);
  }

  .action-button-abuse:hover .icon-abuse {
    color: red;
    transition: 0.5s;
    -moz-transition: 0.5s;
  }
}

.success-msg {
  position: fixed;
  top: 20%;
  left: 10%;
  z-index: 9999;
}

/* End Action Buttons */

/* Start Add House Rules */
.rule-list {
  border-radius: 4px;
  border: 1px solid #ced4da;
  display: inline-block;
  width: 100%;
  padding: .375rem .75rem;
  line-height: 1.5;
  color: #212529;
}
/* End Add House Rules */

.category-label {
  border: 1px solid #868e96;
  border-radius: 10px;
  width: 100px;
  min-width: 100px;
}

/* Global Error if AdDetail does not exist */
.global-error {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 9999;
}
/* Start Text truncate - white space - for long texts and icons */
.list-relative-contact {
  width: 100%;
  position: relative;
  min-height: 25px;
}

.list-relative {
  width: 100%;
  position: relative;
}

.text-truncate-item-square-meter {
  box-sizing:border-box;
  transition: 0.7s;
  border-radius: 5px;
}

.text-truncate-item-square-meter:hover {
  position: absolute;
  transition: 0.7s;
  box-sizing:border-box;
  overflow: visible !important;
  white-space: normal !important;
  background-color: white;
  border: 1px solid black;
  padding: 0 0 0 1px;
  border-radius: 5px;
}

.text-truncate-item {
  width: 100%;
  box-sizing:border-box;
  transition: 0.7s;
  border-radius: 5px;
}

.text-truncate-item:hover {
  position: absolute;
  transition: 0.7s;
  box-sizing:border-box;
  overflow: visible !important;
  white-space: normal !important;
  background-color: white;
  border: 1px solid black;
  padding: 0 0 0 1px;
  border-radius: 5px;
  z-index: 1000;
}
/* End Text truncate - white space - for long texts and icons */

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scroll {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.user-image {
  //width: 100px;
  //height: 100px;
  width: 100%;
  height: 100%;
  max-width: 120px;
  margin: auto;
  border-radius: 50%;
  border: 1px solid #9e9e9e;
}

.property-info-container {
  width: 48%;
  height: 100%;
  display: inline-block;
  vertical-align: middle;
}

.property-info-box {
  min-height: 20px;
}

.property-info-type {
  cursor: pointer;
  font-size: 13px;
  //font-weight: bold;
}

.property-info {
  cursor: pointer;
  font-size: 13px;
  //font-weight: bold;
  margin-left: -4%;
}

.ad-details {
  position: absolute;
  z-index: 99;
  background-color: rgba(255, 255, 255, 70%);
  //a.closebtn {
  //  position: fixed; // fixed
  //  width: 24px;
  //  height: 28px;
  //  top: 46%;
  //  left: 31%; // 31 %
  //  font-size: 24px;
  //  z-index: 9999;
  //  visibility: hidden;
  //  transition: 0.7s;
  //  -webkit-transition-duration: 0.7s;
  //  color: #198754;
  //  border-radius: 50% 0 0 50%;
  //  //border: 2px solid rgba(0, 0, 0, .125);
  //  border-right: none !important;
  //  border-left: 1px solid rgba(0, 0, 0, 0.125) !important;
  //  border-top: 1px solid rgba(0, 0, 0, 0.125) !important;
  //  border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
  //  line-height: 0;
  //  background-color: rgba(255, 255, 255, 0.8);
  //  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  //  :hover {
  //    color: red;
  //    transition: 0.7s;
  //    -webkit-transition-duration: 0.7s;
  //  }
  //}
  //a.closebtn .tooltiptext:hover {
  //  color: white !important;
  //}
  //a.closebtn #adDetailsChevronCloseBtn {
  //  position: absolute;
  //  top: 0;
  //  left: 0;
  //}
}

/* Double Chevron Animation Once */
.pulse-animation-once {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse 2s;
  animation-iteration-count: 2;
}
/* End Double Chevron Animation Once */

.container-ad-details {
  width: 100%;
  height: 81vh;
  margin: auto;
  .row {
    margin: auto;
  }
  .col-6 {
    padding: 0;
  }
  .col-md-6 {
    padding: 0;
  }
}

/*
  START
  change some css so that images are always sized same
  use media queries to adjust that for mobile and other devices
*/

.ad-details-thumbnails {
  #carouselCaptionsPropDetails {
    .carousel-indicators {
      position: absolute;
      display: block;
      list-style: none;
      text-align: center;
      margin: 0;
      padding: 0;
      left: 80% !important;
      top: 0;
      overflow-x: scroll;
      .active {
        transition: 0.7s !important;
        opacity: 1 !important;
        background-color: rgba(255,255,255,0) !important;
      }
    }
    .carousel-control-prev-icon {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' stroke='%23000' viewBox='0 0 10 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
    }
    .carousel-control-next-icon {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' stroke='%23000' viewBox='0 0 10 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
    }
    .carousel-indicators li {
      //width: 200px;
      //height: 150px;
      width: auto;
      height: auto;
      max-height: 180px;
      transition: 0.7s;
      opacity: 0.5;
      display: block;
      border-radius: 5px;
      .carousel-indicators-thumbnails {
        width: 100%;
        height: auto;
        max-height: 150px;
        min-height: auto;
        //max-height: 150px;
        //min-height: 150px;
        border-radius: 5px;
      }
    }
    .thumbnails-height {
      width: 100%;
      height: auto;
      max-height: 500px;
      min-height: auto;
      //max-height: 550px;
      //min-height: 550px;
    }

    .carousel-inner {
      width: 79%;
      border-radius: 5px;
      height: auto;
      max-height: 500px;
      min-height: auto;
      //max-height: 550px;
      //min-height: 550px;
      background-clip: border-box;
      border: 1px solid rgba(0, 0, 0, .125);
      box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
    }

    .carousel-control-next {
      right: 21%;
    }
  }
  #carouselFloorplans {
    .carousel-control-prev-icon {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' stroke='%23000' viewBox='0 0 14 10'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
    }
    .carousel-control-next-icon {
      margin-left: 20px;
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' stroke='%23000' viewBox='0 0 14 10'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
    }
    .thumbnails-floorplan-height {
      width: 100%;
      height: auto;
      max-height: 300px;
      min-height: auto;
      //max-height: 250px;
      //min-height: 250px;
    }
    .carousel-inner {
      width: 100%;
      border-radius: 5px;
      height: auto;
      max-height: 300px;
      min-height: auto;
      //max-height: 250px;
      //min-height: 250px;
      background-clip: border-box;
      border: 1px solid rgba(0, 0, 0, .125);
      box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
    }
  }
  /* change transition duration to control the speed of fade effect */
  .carousel-item {
    transition: transform 1s ease-in-out;
  }

  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: opacity 1s 1s;
  }
}
/*
  END
  change some css so that images are always sized same
  use media queries to adjust that for mobile and other devices
*/

/* start tooltip - popups info on icons */
.tooltiptext {
  visibility: hidden;
  width: auto;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  text-align: center;
  font-size: 10px;
  border-radius: 6px;
  padding: 5px 5px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1000;
}

[data-tooltip]:hover .tooltiptext {
  visibility: visible;
  color: #fff;
}
/* end tooltip - popups info on icons */

.property-icons {
  font-size: 14px;
  --bs-gutter-x: 0;
  .col-1 {
    width: 14.33333333% !important;
  }
  .col-2 {
    width: 14.66666667% !important;
  }
  .top-row-icons {
    .col-2 {
      width: 80.66666667% !important;
    }
  }
}

.sub-icons-font {
  font-size: small;
}

ul.icons-listing li {
  margin: 5px 0 5px 0;
}

/* Start Contact Area */
.booking-modal {
  left: 0;
  top: 10%;
}

.chat-modal {
  left: 0;
  top: 20%;
}

.marketed-by {
  border-radius: 10px;
  background: white;
  $border: 2px;
  position: relative;
  padding: 30% 2em;
  box-sizing: border-box;
  &:before {
    content: '';
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    z-index: -1;
    margin: -$border; /* !importanté */
    border-radius: inherit; /* !importanté */
    //background: linear-gradient(to bottom, #b209b2, #19b5fe);
    background: linear-gradient(to bottom, rgba(128, 0, 128, 0.7), rgba(25, 181, 254, 0.7));
  }
  p.marketed-by-heading {
    font-size: 13px;
    color: white;
    border-radius: 10px 10px 0 0;
    //background: linear-gradient(to right, rgba(25, 181, 254, 0.5) 25%, rgba(128, 0, 128, 0.5));
    background: rgba(128, 0, 128, 0.7);
    padding: .5rem;
    text-transform: uppercase;
    text-shadow: 2px 1px #3c3c3c;
  }
  .chat-msg-button {
    padding: 5px;
    border-radius: 10px;
    border: 1px solid #19b5fe;
    background-color: white; // #19b5fe
    color: #19b5fe;
    transition: 0.5s;
    -moz-transition: 0.5s;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
  }
  .chat-msg-button:hover {
    background: rgba(128, 0, 128, 0.7);
    border: 1px solid rgba(128, 0, 128, 0.7);
    color: white;
    transition: 0.5s;
    -moz-transition: 0.5s;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
  }
  .chat-icon {
    color: #19b5fe;
    transition: 0.5s;
    -moz-transition: 0.5s;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
  }
  .chat-msg-button:hover .chat-icon {
    color: white;
    transition: 0.5s;
    -moz-transition: 0.5s;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
  }
}

.contact-icons {
  font-size: 16px;
  color: darkblue;
}

a.contact-url {
  color: black;
  transition: 0.6s;
  text-decoration: none;
  :hover {
    transition: 0.6s;
    color: black;
  }
}

.list-horizontal {
  list-style-type: none;
  text-align: center;
  padding-inline-start: 0;
  li.list-item-horizontal {
    display: inline-block;
  }
}
/* End Contact Area */

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-width: 499px) {
  .ad-details {
    a.closebtn {
      left: 3%;
    }
  }
  #carouselFloorplans {
    .thumbnails-floorplan-height {
      min-height: 150px !important;
    }
    .carousel-inner {
      min-height: 150px !important;
    }
  }
  #carouselCaptionsPropDetails {
    .carousel-indicators-thumbnails {
      width: 100%;
      min-height: 90px !important;
    }
    .thumbnails-height {
      min-height: 200px !important;
    }
    .carousel-inner {
      min-height: 200px !important;
      width: 100% !important;
    }
    .carousel-control-prev {
      width: 10%;
    }
    .carousel-control-next {
      right: 0 !important;
      width: 10%;
    }
  }
}
@media screen and (min-width: 500px) {
  .ad-details {
    a.closebtn {
      left: 3%;
    }
  }
  #carouselFloorplans {
    .thumbnails-floorplan-height {
      min-height: 250px !important;
    }
    .carousel-inner {
      min-height: 250px !important;
    }
  }
  #carouselCaptionsPropDetails {
    .carousel-indicators-thumbnails {
      width: 100%;
      min-height: 90px !important;
    }
    .thumbnails-height {
      min-height: 250px !important;
    }
    .carousel-inner {
      min-height: 250px !important;
      width: 100% !important;
    }
    .carousel-control-prev {
      width: 10%;
    }
    .carousel-control-next {
      right: 0 !important;
      width: 10%;
    }
  }
}
@media screen and (min-width: 580px) {
  .ad-details {
    a.closebtn {
      left: 3%;
    }
  }
  #carouselFloorplans {
    .thumbnails-floorplan-height {
      min-height: 250px !important;
    }
    .carousel-inner {
      min-height: 250px !important;
    }
  }
  #carouselCaptionsPropDetails {
    .carousel-indicators-thumbnails {
      width: 100%;
      min-height: 90px !important;
    }
    .thumbnails-height {
      min-height: 250px !important;
    }
    .carousel-inner {
      min-height: 250px !important;
      width: 100% !important;
    }
    .carousel-control-prev {
      width: 10%;
    }
    .carousel-control-next {
      right: 0 !important;
      width: 10%;
    }
  }
}
@media screen and (min-width: 768px) {
  .ad-details {
    a.closebtn {
      top: 46%;
      left: 3%;
    }
  }
  #carouselFloorplans {
    .thumbnails-floorplan-height {
      min-height: 350px !important;
    }
    .carousel-inner {
      min-height: 350px !important;
    }
  }
  #carouselCaptionsPropDetails {
    .carousel-indicators-thumbnails {
      width: 100%;
      min-height: 120px !important;
    }
    .thumbnails-height {
      min-height: 350px !important;
    }
    .carousel-inner {
      min-height: 350px !important;
      width: 79% !important;
    }
    .carousel-control-prev {
      width: 10%;
    }
    .carousel-control-next {
      right: 21% !important;
      width: 10%;
    }
  }
}
@media screen and (min-width: 992px) {
  .ad-details {
    a.closebtn {
      top: 46%;
      left: 40%;
    }
  }
  #carouselFloorplans {
    .thumbnails-floorplan-height {
      min-height: 200px !important;
    }
    .carousel-inner {
      min-height: 200px !important;
    }
  }
  #carouselCaptionsPropDetails {
    .carousel-indicators-thumbnails {
      width: 100%;
      min-height: 120px !important;
    }
    .thumbnails-height {
      min-height: 350px !important;
    }
    .carousel-inner {
      min-height: 350px !important;
    }
  }
}
@media screen and (min-width: 1200px) {
  .ad-details {
    a.closebtn {
      top: 46%;
      left: 31%;
    }
  }
  #carouselFloorplans {
    .thumbnails-floorplan-height {
      min-height: 250px !important;
    }
    .carousel-inner {
      min-height: 250px !important;
    }
  }
  #carouselCaptionsPropDetails {
    .carousel-indicators-thumbnails {
      width: 100%;
      min-height: 150px !important;
    }
    .thumbnails-height {
      min-height: 450px !important;
    }
    .carousel-inner {
      min-height: 450px !important;
    }
  }
}
@media screen and (min-width: 1440px) {
  .ad-details {
    a.closebtn {
      top: 46%;
      left: 31%;
    }
  }
}
@media screen and (min-width: 1920px) {
  .ad-details {
    a.closebtn {
      top: 46%;
      left: 31%;
    }
  }
  #carouselFloorplans {
    .thumbnails-floorplan-height {
      min-height: 350px !important;
    }
    .carousel-inner {
      min-height: 350px !important;
    }
  }
  #carouselCaptionsPropDetails {
    .carousel-indicators-thumbnails {
      width: 100%;
      min-height: 150px !important;
    }
    .thumbnails-height {
      min-height: 550px !important;
    }
    .carousel-inner {
      min-height: 550px !important;
    }
  }
}

</style>
