<template>
  <div class="reviews-host">
    <div class="container h-100">
      <div class="row h-100 pb-4">
        <!-- Loading -->
        <Loader class="mt-2" :loading="loading" :small="false"></Loader>

        <div v-if="!loading && reviews && !hideRating" class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
          Rating: <ShowReviewRating :rating="calculateTotalRating" :showNumericRating="true"></ShowReviewRating>
        </div>

        <div v-if="!loading" :key="review.id" v-for="review in filteredReviews" class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <div class="card shadow sm m-3">
            <div class="card-body">
              <div class="row m-0 p-0">
                <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 m-0 p-0">
                  <div class="row m-0 p-0">
                    <!-- Additional info about guest -->
                    <div v-if="showAdditionalInfo" class="col-3 col-xs-3 col-sm-3 col-md-3 col-lg-3 m-0 p-0">
                      <div class="row m-0 p-0">
                        <div class="col-5 col-xs-5 col-sm-5 col-md-5 col-lg-5 m-0 p-0 text-start align-self-center align-content-center overflow-hidden">
                          <div v-if="!review.booking || !review.booking.guest || !review.booking.guest.documents_user_images">
                            <img :src="require(`@/assets/no-user-image.png`)"
                                 class="img-fluid user-image"
                                 alt="..."
                            >
                          </div>
                          <div v-else>
                            <img :src="`${$s3bucket}${review.booking.guest.documents_user_images.file_path}`" @error="setUserDefaultImg"
                                 class="img-fluid user-image"
                                 alt="..."
                            >
                          </div>
                        </div>
                        <div class="col-7 col-xs-7 col-sm-7 col-md-7 col-lg-7 m-0 p-0 text-start align-self-center align-content-center overflow-hidden">
                          <span class="user-name">
                            {{ review && review.booking && review.booking.property && review.booking.property.contact ? review.booking.property.contact.name : '' }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div :class="showAdditionalInfo ? 'col-8 col-xs-8 col-sm-8 col-md-8 col-lg-8 m-0 p-0' : 'col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 m-0 p-0'">
                      <span>
                        {{
                          (review.property.address.street ? review.property.address.street : '') +
                          (review.property.address.city ? ', ' + review.property.address.city : '') +
                          (review.property.address.county ? ', ' + review.property.address.county : '') +
                          (review.property.address.postcode ? ', ' + review.property.address.postcode : '')
                        }}
                      </span>
                    </div>
                  </div>
                </div>
                <hr/>
                <div class="col-8 col-xs-8 col-sm-8 col-md-8 col-lg-8 m-0 p-0">
                  <div class="row text-start">
                    <div class="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                      <h5 class="text-start">{{ review && review.user && review.user.contact ? review.user.contact.name : 'Anonymous' }} (<font-awesome-icon icon="star" class="star-icon" style="color: yellow;"/>{{review.review_star}})</h5>
                    </div>
                    <div class="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                      <ShowReviewRating :rating="review.review_star" :showNumericRating="true"></ShowReviewRating>
                    </div>
                  </div>
                  <!-- Only if 'review_text" exists show HR and COMMENT -->
                  <hr v-if="review.review_text && review.review_text.length > 0"/>
                  <p v-if="review.review_text && review.review_text.length > 0">{{ review.review_text }}</p>
                </div>
                <div class="col-4 col-xs-4 col-sm-4 col-md-4 col-lg-4 m-0 p-0">
                  <span class="review-date float-end">{{ moment(review.created_at).format('DD/MM/YYYY') }}</span>
                  <div v-if="review.property.documents_property_images.length === 0">
                    <img :src="require(`@/assets/no-image-found.png`)"
                         class="d-block w-100 img-fluid review-prop-img"
                         alt="..."
                    >
                  </div>
                  <div v-else>
                    <img :src="`${$s3bucket}${review.property.documents_property_images[0].file_path}`" @error="setDefaultImg" class="d-block w-100 img-fluid review-prop-img" alt="...">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="!loading && filteredReviews && filteredReviews.length < 1" class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
          <hr/>
          No reviews to show
        </div>

        <div class="col-12 mb-2 mt-2">
          <p v-if="validationMessage" class="success-info success-msg text-center">
            {{ validationMessage }}
          </p>
          <div v-if="validationErrors" class="global-error mt-2">
            <ValidationErrors :errors="validationErrors"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ShowReviewRating from "./ShowReviewRating"
import ValidationErrors from "../ValidationErrors"
import Loader from "../Loader";

export default {
  name: 'ReviewsGuest',
  props: ['adId', 'adDetails', 'toUserId', 'filters', 'refresh', 'reviewsFeed', 'hideRating', 'showAdditionalInfo'],
  components: {
    'ShowReviewRating': ShowReviewRating,
    'ValidationErrors': ValidationErrors,
    'Loader': Loader
  },
  data(){
    return {
      reviews: '',
      processing: false,
      loading: true,
      validationMessage: '',
      validationErrors: '',
      // Flag used to trigger filters from watcher
      triggerFilters: false,
    }
  },
  async mounted() {
    this.loading = true;
    if (!this.reviewsFeed) {
      await this.getGuestReviews();
    }
    this.loading = false;
  },
  computed: {
    calculateTotalRating() {
      if (this.reviews && this.reviews.length > 0) {
        let sum = 0;
        this.reviews.forEach((review) => {
          sum = sum + review.review_star;
        });
        return Math.round(((sum / this.reviews.length) + Number.EPSILON) * 10) / 10;
      } else {
        return 0;
      }
    },
    filteredReviews() {
      let flag = this.triggerFilters;
      if (this.filters) {
        let rating = 0;
        let reviews = [...this.reviews];

        for (let i=reviews.length; i>0; i--) {
          // rating = this.calculateRating(reviews[i-1]);
          rating = reviews[i-1].review_star;
          let id = reviews[i-1].id;

          // From 0 to 1.99
          if (this.filters.one_star === false && rating <= 1.99) {
            reviews = reviews.filter((obj) => parseInt(obj.id) !== parseInt(id));
          }
          // From 2 to 2.99
          if (this.filters.two_star === false && rating >= 2 && rating <= 2.99) {
            reviews = reviews.filter((obj) => parseInt(obj.id) !== parseInt(id));
          }
          // From 3 to 3.99
          if (this.filters.three_star === false && rating >= 3 && rating <= 3.99) {
            reviews = reviews.filter((obj) => parseInt(obj.id) !== parseInt(id));
          }
          // From 4 to 4.99
          if (this.filters.four_star === false && rating >= 4 && rating <= 4.99) {
            reviews = reviews.filter((obj) => parseInt(obj.id) !== parseInt(id));
          }
          // From 5
          if (this.filters.five_star === false && rating === 5) {
            reviews = reviews.filter((obj) => parseInt(obj.id) !== parseInt(id));
          }
        }

        return reviews;
      } else {
        return this.reviews;
      }
    }
  },
  methods:{
    setUserDefaultImg(e) {
      e.target.src = require(`@/assets/no-user-image.png`);
    },
    // calculateRating(review) {
    //   return Math.round((((review.review_communication + review.review_cleanliness + review.review_location + review.review_value) / 4) + Number.EPSILON) * 10) / 10;
    // },
    async getGuestReviews() {
      this.processing = true;

      let data = new FormData();
      data.append("guest_id", this.toUserId);

      await axios.post('api/guest-reviews', data).then(({data})=>{
        this.reviews = data.data;
        this.validationErrors = null;
        this.validationMessage = null;
      }).catch(({response})=>{
        if (response && response.data && response.data.errors) {
          this.validationErrors = response.data.errors;
          this.validationMessage = response.data.message;
        }
      }).finally(()=>{
        this.processing = false;
      })
    },
    setDefaultImg(e) {
      e.target.src = require(`@/assets/no-image-found.png`);
    }
  },
  watch: {
    reviewsFeed: {
      handler: async function (val) {
        this.loading = true;
        this.reviews = this.reviewsFeed;
        this.loading = false;
      }, deep: true, immediate: true
    },
    refresh: {
      handler: async function (val) {
        this.loading = true;
        if (!this.reviewsFeed) {
          await this.getGuestReviews();
        }
        this.loading = false;
      }, deep: true, immediate: true
    },
    adId: {
      handler: async function (val) {
        this.loading = true;
        if (!this.reviewsFeed) {
          await this.getGuestReviews();
        }
        this.loading = false;
      }, deep: true, immediate: true
    },
    filters: {
      handler: function (val) {
        if (val) {
          this.triggerFilters = !this.triggerFilters;
        }
      }, deep: true, immediate: true
    }
  }
}
</script>

<style lang="scss">

.star-icon path {
  stroke: #3d3d3d;
  stroke-width: 20;
}

.review-date {
  font-size: 13px;
}

.review-prop-img {
  width: 100%;
  cursor: pointer;
  height: auto;
  max-height: 200px;
  min-height: auto;
  border-radius: 10px;
}

</style>