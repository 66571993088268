<template>
  <div class="reviews-neighbourhood">
    <div class="container h-100">
      <div class="row h-100 pb-4">
        <!-- Loading -->
        <Loader class="mt-2" :loading="loading" :small="false"></Loader>

        <div v-if="!loading && reviews && !hideRating" class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
          Rating: <ShowReviewRating :rating="calculateTotalRating" :showNumericRating="true"></ShowReviewRating>
        </div>

        <div v-if="!loading" :key="review.id" v-for="review in filteredReviews" class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <div class="card shadow sm m-3">
            <div class="card-body">
              <span>
                {{
                  (review.address.street ? review.address.street : '') +
                  (review.address.city ? ', ' + review.address.city : '') +
                  (review.address.county ? ', ' + review.address.county : '') +
                  (review.address.postcode ? ', ' + review.address.postcode : '')
                }}
              </span>
              <hr/>
              <h5 class="text-start">{{ review && review.user && review.user.contact ? review.user.contact.name : 'Anonymous' }} (<font-awesome-icon icon="star" class="star-icon" style="color: yellow;"/>{{calculateRating(review)}}) <span class="review-date float-end">{{ moment(review.created_at).format('DD/MM/YYYY') }}</span></h5>
              <hr/>
              <div class="row text-start">
                <div class="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                  Amenities
                </div>
                <div class="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                  <ShowReviewRating :rating="review.review_amenities" :showNumericRating="true"></ShowReviewRating>
                </div>
              </div>
              <div class="row text-start">
                <div class="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                  Crime
                </div>
                <div class="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                  <ShowReviewRating :rating="review.review_crime" :showNumericRating="true"></ShowReviewRating>
                </div>
              </div>
              <div class="row text-start">
                <div class="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                  Community
                </div>
                <div class="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                  <ShowReviewRating :rating="review.review_community" :showNumericRating="true"></ShowReviewRating>
                </div>
              </div>
              <div class="row text-start">
                <div class="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                  Location
                </div>
                <div class="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                  <ShowReviewRating :rating="review.review_location" :showNumericRating="true"></ShowReviewRating>
                </div>
              </div>
              <!-- Only if 'review_text" exists show HR and COMMENT -->
              <hr v-if="review.review_text && review.review_text.length > 0"/>
              <p v-if="review.review_text && review.review_text.length > 0">{{ review.review_text }}</p>
            </div>
          </div>
        </div>

        <div v-if="!loading && filteredReviews && filteredReviews.length < 1" class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
          <hr/>
          No reviews to show
        </div>

        <div class="col-12 mb-2 mt-2">
          <p v-if="validationMessage" class="success-info success-msg text-center">
            {{ validationMessage }}
          </p>
          <div v-if="validationErrors" class="global-error mt-2">
            <ValidationErrors :errors="validationErrors"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ShowReviewRating from "./ShowReviewRating"
import ValidationErrors from "../ValidationErrors"
import Loader from "../Loader";

export default {
  name: 'ReviewsNeighbourhood',
  props: ['adId', 'adDetails', 'toUserId', 'filters', 'refresh', 'reviewsFeed', 'hideRating'],
  components: {
    'ShowReviewRating': ShowReviewRating,
    'ValidationErrors': ValidationErrors,
    'Loader': Loader
  },
  data(){
    return {
      reviews: '',
      processing: false,
      loading: true,
      validationMessage: '',
      validationErrors: '',
      // Flag used to trigger filters from watcher
      triggerFilters: false,
      radiusHolder: ''
    }
  },
  async mounted() {
    this.loading = true;
    if (!this.reviewsFeed) {
      await this.getNeighbourhoodReviews();
    }
    this.loading = false;
  },
  computed: {
    calculateTotalRating() {
      if (this.reviews && this.reviews.length > 0) {
        let sum = 0;
        this.reviews.forEach((review) => {
          sum = sum + ((review.review_amenities + review.review_crime + review.review_community + review.review_location) / 4);
        });
        return Math.round(((sum / this.reviews.length) + Number.EPSILON) * 10) / 10;
      } else {
        return 0;
      }
    },
    filteredReviews() {
      let flag = this.triggerFilters;
      if (this.filters) {
        let rating = 0;
        let reviews = [...this.reviews];

        for (let i=reviews.length; i>0; i--) {
          rating = this.calculateRating(reviews[i-1]);
          let id = reviews[i-1].id;

          // From 0 to 1.99
          if (this.filters.one_star === false && rating <= 1.99) {
            reviews = reviews.filter((obj) => parseInt(obj.id) !== parseInt(id));
          }
          // From 2 to 2.99
          if (this.filters.two_star === false && rating >= 2 && rating <= 2.99) {
            reviews = reviews.filter((obj) => parseInt(obj.id) !== parseInt(id));
          }
          // From 3 to 3.99
          if (this.filters.three_star === false && rating >= 3 && rating <= 3.99) {
            reviews = reviews.filter((obj) => parseInt(obj.id) !== parseInt(id));
          }
          // From 4 to 4.99
          if (this.filters.four_star === false && rating >= 4 && rating <= 4.99) {
            reviews = reviews.filter((obj) => parseInt(obj.id) !== parseInt(id));
          }
          // From 5
          if (this.filters.five_star === false && rating === 5) {
            reviews = reviews.filter((obj) => parseInt(obj.id) !== parseInt(id));
          }
        }

        return reviews;
      } else {
        return this.reviews;
      }
    }
  },
  methods: {
    calculateRating(review) {
      return Math.round((((review.review_amenities + review.review_crime + review.review_community + review.review_location) / 4) + Number.EPSILON) * 10) / 10;
    },
    async getNeighbourhoodReviews(filters = null) {
      this.processing = true;

      let radius = 5;
      if (filters && filters.radius) {
        radius = filters.radius;
      }

      let data = new FormData();
      data.append("ad_id", this.adId);
      data.append("address_id", this.adDetails.address.id);
      data.append("radius", radius); // 5 miles

      await axios.post('api/neighbourhood-reviews', data).then(({data})=>{
        this.reviews = data.data;
        this.validationErrors = null;
        this.validationMessage = null;
      }).catch(({response})=>{
        if (response && response.data && response.data.errors) {
          this.validationErrors = response.data.errors;
          this.validationMessage = response.data.message;
        }
      }).finally(()=>{
        this.processing = false;
      })
    },
  },
  watch: {
    reviewsFeed: {
      handler: async function (val) {
        this.loading = true;
        this.reviews = this.reviewsFeed;
        this.loading = false;
      }, deep: true, immediate: true
    },
    refresh: {
      handler: async function (val) {
        this.loading = true;
        if (!this.reviewsFeed) {
          await this.getNeighbourhoodReviews();
        }
        this.loading = false;
      }, deep: true, immediate: true
    },
    adId: {
      handler: async function (val) {
        this.loading = true;
        if (!this.reviewsFeed) {
          await this.getNeighbourhoodReviews();
        }
        this.loading = false;
      }, deep: true, immediate: true
    },
    filters: {
      handler: async function (val, oldVal) {
        if (val) {
          if (this.radiusHolder !== val.radius) {
            this.loading = true;
            this.processing = true;
            await this.getNeighbourhoodReviews(val);
            this.radiusHolder = val.radius;
            this.loading = false;
            this.processing = false;
          }
          this.triggerFilters = !this.triggerFilters;
        }
      }, deep: true, immediate: true
    }
  }
}
</script>

<style lang="scss">

.star-icon path {
  stroke: #3d3d3d;
  stroke-width: 20;
}

.review-date {
  font-size: 13px;
}

</style>