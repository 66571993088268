<template>
  <div>
    <transition name="fade" v-show="activeSearch">
      <div id="searchModal" class="search-component">
        <div id="searchBarBox" class="search-bar-box" tabindex="-1">
          <form action="javascript:void(0)" method="post" enctype="multipart/form-data"> <!-- class="d-flex" -->
            <div class="form-position form-font-size">
              <div class="row m-0 p-0 border-radius-5">
                <div class="col-12 col-sm-4 col-md-4 col-lg-4 p-0 m-0 border-lr border-radius-l-5">
                  <div class="search-bar position-relative text-start">
                    <label class="m-0 p-0 ps-1 search-filters-label">Search Area</label>
                    <div class="form-floating-label geocoder-input-container cursor-pointer" id="geocoder"></div>
<!--                    <label class="static-label-top">Search Area</label>-->
                  </div>
                </div>
                <div class="col-6 col-sm-2 col-md-2 col-lg-2 p-0 m-0 border-lr">
                  <!-- #RAF Experiment later on with this class searchformfld -->
                  <div class="dropdown-container-relative text-start">
                    <!--                      @focus="handleFocus($event)"-->
                    <!--                      @focusout="handleFocusOut(searchForm.ad_type, $event)"-->
                  <label class="p-0 m-0 ps-1 search-filters-label">Type</label>
                  <select
                      v-model="searchForm.ad_type"
                      class="select-placeholder search-filter-btns dropdown-btn cursor-pointer text-start chevron-toggle ps-1"
                      tabindex="-1"
                      aria-label="ad_type"
                      name="ad_type"
                      id="ad_type"
                  >
                    <option value="" disabled>Ad Type</option>
                    <option :value="1">To Buy</option> <!-- Buy searches for Sell -->
                    <option :value="2">To Rent</option> <!-- Rent searches for Let -->
                    <option :value="3">To Buy & To Rent</option> <!-- Buy and Rent searches for Sell and Let -->
                    <option :value="4">For Sale</option> <!-- Sell searches for Buy -->
                    <option :value="5">To Let</option> <!-- Let searches for Rent -->
                    <option :value="6">For Sale & To Let</option> <!-- Sell and Let searches for Buy and Rent -->
                  </select>
<!--                  <label class="static-label-top">Ad Type</label>-->
                  </div>
                </div>
<!--                <div class="col-2 p-0 m-0 border-lr">-->
<!--                  <select-->
<!--                      v-model="searchForm.radius"-->
<!--                      class="select-placeholder search-filter-btns"-->
<!--                      @focus="handleFocus($event)"-->
<!--                      @focusout="handleFocusOut(searchForm.radius, $event)"-->
<!--                      tabindex="-1"-->
<!--                      aria-label="radius"-->
<!--                      name="radius"-->
<!--                      id="radius"-->
<!--                  >-->
<!--                    <option value="" disabled>Radius</option>-->
<!--                    <option :value="1">+0 miles</option>-->
<!--                    <option :value="2">+1 mile</option>-->
<!--                    <option :value="3">+3 miles</option>-->
<!--                    <option :value="4">+5 miles</option>-->
<!--                    <option :value="5">+10 miles</option>-->
<!--                    <option :value="6">+15 miles</option>-->
<!--                    <option :value="7">+20 miles</option>-->
<!--                    <option :value="8">+30 miles</option>-->
<!--                    <option :value="9">+40 miles</option>-->
<!--                    <option :value="10">+50 miles</option>-->
<!--                  </select>-->
<!--                </div>-->
                <div class="col-6 col-sm-1 col-md-1 col-lg-1 p-0 m-0 border-lr form-floating-label">
                  <div class="dropdown-container-relative text-start">
                    <!--                        @focus="handleFocus($event)"-->
                    <!--                        @focusout="handleFocusOut(searchForm.min_beds ? searchForm.min_beds : searchForm.max_beds, $event)"-->
                    <label class="p-0 m-0 ps-1 search-filters-label">Beds</label>
                    <button
                        type="button"
                        id="dropdownBeds"
                        class="search-filter-btns select-placeholder text-start cursor-pointer dropdown-toggle dropdown-btn"
                        data-bs-toggle="dropdown"
                        data-bs-auto-close="outside"
                    >
                      {{ searchForm.beds }}
                    </button>
<!--                    <label class="static-label-top">Beds</label>-->
                    <div class="dropdown-menu dropdown-menu-filters" aria-labelledby="dropdownBeds">
                      <div class="row m-0 p-0">
                        <div class="col-6 m-0 p-1">
                          <!--
                                 @focus="handleFocus($event)"
                              @focusout="handleFocusOut(searchForm.min_beds, $event)"
                              -->
                          <select required
                              v-model="searchForm.min_beds"
                              class="select-placeholder search-filter-btns cursor-pointer dropdown-btn"
                              tabindex="-1"
                              aria-label="min_beds"
                              name="min_beds"
                              id="min_beds"
                          >
                            <option value="" disabled>Min Beds</option>
                            <option :value="1">1</option>
                            <option :value="2">2</option>
                            <option :value="3">3</option>
                            <option :value="4">4</option>
                            <option :value="5">5</option>
                            <option :value="6">6</option>
                            <option :value="7">7</option>
                            <option :value="8">8</option>
                            <option :value="9">9</option>
                            <option :value="10">10</option>
                            <option :value="11">11+</option>
                          </select>
                        </div>
                        <div class="col-6 m-0 p-1">
                          <!--                              @focus="handleFocus($event)"-->
                          <!--                              @focusout="handleFocusOut(searchForm.max_beds, $event)"-->
                          <select
                              v-model="searchForm.max_beds"
                              class="select-placeholder search-filter-btns cursor-pointer dropdown-btn"
                              tabindex="-1"
                              aria-label="max_beds"
                              name="max_beds"
                              id="max_beds"
                          >
                            <option value="" disabled>Max Beds</option>
                            <option :value="1">1</option>
                            <option :value="2">2</option>
                            <option :value="3">3</option>
                            <option :value="4">4</option>
                            <option :value="5">5</option>
                            <option :value="6">6</option>
                            <option :value="7">7</option>
                            <option :value="8">8</option>
                            <option :value="9">9</option>
                            <option :value="10">10</option>
                            <option :value="11">11+</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-sm-2 col-md-2 col-lg-2 p-0 m-0 border-lr">
                  <div class="dropdown-container-relative text-start">
                    <!--                        @focus="handleFocus($event)"-->
                    <!--                        @focusout="handleFocusOut(searchForm.min_price ? searchForm.min_price : searchForm.max_price, $event)"-->
                    <label class="p-0 m-0 ps-1 search-filters-label">Price</label>
                    <button
                        type="button"
                        id="dropdownPrice"
                        class="search-filter-btns select-placeholder text-start cursor-pointer dropdown-toggle dropdown-btn"
                        data-bs-toggle="dropdown"
                        data-bs-auto-close="outside"
                    >
                      {{ searchForm.price !== 'Price' ? convertNumberToCommas(searchForm.price) : searchForm.price }}
                    </button>
<!--                    <label class="static-label-top">Price</label>-->
                    <div class="dropdown-menu dropdown-menu-filters" aria-labelledby="dropdownPrice">
                      <div class="row m-0 p-0">
                        <div class="col-6 m-0 p-1">
                          <!--                              @focus="handleFocus($event)"-->
                          <!--                              @focusout="handleFocusOut(searchForm.min_price, $event)"-->
                          <select
                              v-model="searchForm.min_price"
                              class="select-placeholder search-filter-btns cursor-pointer dropdown-btn"
                              tabindex="-1"
                              aria-label="min_price"
                              name="min_price"
                              id="min_price"
                          >
                            <option value="" disabled>Min Price</option>
                            <option :value="0">£0</option>
                            <option :value="50">£50</option>
                            <option :value="100">£100</option>
                            <option :value="150">£150</option>
                            <option :value="200">£200</option>
                            <option :value="250">£250</option>
                            <option :value="500">£500</option>
                            <option :value="750">£750</option>
                            <option :value="1000">£1,000</option>
                            <option :value="1250">£1,250</option>
                            <option :value="1500">£1,500</option>
                            <option :value="1750">£1,750</option>
                            <option :value="2000">£2,000</option>
                            <option :value="3000">£3,000</option>
                            <option :value="4000">£4,000</option>
                            <option :value="5000">£5,000</option>
                            <option :value="10000">£10,000</option>
                            <option :value="20000">£20,000</option>
                            <option :value="30000">£30,000</option>
                            <option :value="40000">£40,000</option>
                            <option :value="50000">£50,000</option>
                            <option :value="60000">£60,000</option>
                            <option :value="70000">£70,000</option>
                            <option :value="80000">£80,000</option>
                            <option :value="90000">£90,000</option>
                            <option :value="100000">£100,000</option>
                            <option :value="110000">£110,000</option>
                            <option :value="120000">£120,000</option>
                            <option :value="130000">£130,000</option>
                            <option :value="140000">£140,000</option>
                            <option :value="150000">£150,000</option>
                            <option :value="160000">£160,000</option>
                            <option :value="170000">£170,000</option>
                            <option :value="180000">£180,000</option>
                            <option :value="190000">£190,000</option>
                            <option :value="200000">£200,000</option>
                            <option :value="210000">£210,000</option>
                            <option :value="220000">£220,000</option>
                            <option :value="230000">£230,000</option>
                            <option :value="240000">£240,000</option>
                            <option :value="250000">£250,000</option>
                            <option :value="260000">£260,000</option>
                            <option :value="270000">£270,000</option>
                            <option :value="280000">£280,000</option>
                            <option :value="290000">£290,000</option>
                            <option :value="300000">£300,000</option>
                            <option :value="310000">£310,000</option>
                            <option :value="320000">£320,000</option>
                            <option :value="330000">£330,000</option>
                            <option :value="340000">£340,000</option>
                            <option :value="350000">£350,000</option>
                            <option :value="360000">£360,000</option>
                            <option :value="370000">£370,000</option>
                            <option :value="380000">£380,000</option>
                            <option :value="390000">£390,000</option>
                            <option :value="400000">£400,000</option>
                            <option :value="410000">£410,000</option>
                            <option :value="420000">£420,000</option>
                            <option :value="430000">£430,000</option>
                            <option :value="440000">£440,000</option>
                            <option :value="450000">£450,000</option>
                            <option :value="460000">£460,000</option>
                            <option :value="470000">£470,000</option>
                            <option :value="480000">£480,000</option>
                            <option :value="490000">£490,000</option>
                            <option :value="500000">£500,000</option>
                            <option :value="550000">£550,000</option>
                            <option :value="600000">£600,000</option>
                            <option :value="650000">£650,000</option>
                            <option :value="700000">£700,000</option>
                            <option :value="750000">£750,000</option>
                            <option :value="800000">£800,000</option>
                            <option :value="850000">£850,000</option>
                            <option :value="900000">£900,000</option>
                            <option :value="950000">£950,000</option>
                            <option :value="1000000">£1,000,000</option>
                            <option :value="1250000">£1,250,000</option>
                            <option :value="1500000">£1,500,000</option>
                            <option :value="1750000">£1,750,000</option>
                            <option :value="2000000">£2,000,000</option>
                            <option :value="2250000">£2,250,000</option>
                            <option :value="2500000">£2,500,000</option>
                            <option :value="2750000">£2,750,000</option>
                            <option :value="3000000">£3,000,000</option>
                            <option :value="3250000">£3,250,000</option>
                            <option :value="3500000">£3,500,000</option>
                            <option :value="3750000">£3,750,000</option>
                            <option :value="4000000">£4,000,000</option>
                            <option :value="4250000">£4,250,000</option>
                            <option :value="4500000">£4,500,000</option>
                            <option :value="4750000">£4,750,000</option>
                            <option :value="5000000">£5,000,000</option>
                            <option :value="7500000">£7,500,000</option>
                            <option :value="10000000">£10,000,000</option>
                            <option :value="15000000">£15,000,000</option>
                            <option :value="20000000">£20,000,000</option>
                          </select>
                        </div>
                        <div class="col-6 m-0 p-1">
                          <!--                              @focus="handleFocus($event)"-->
                          <!--                              @focusout="handleFocusOut(searchForm.max_price, $event)"-->
                          <select
                              v-model="searchForm.max_price"
                              class="select-placeholder search-filter-btns cursor-pointer dropdown-btn"
                              tabindex="-1"
                              aria-label="max_price"
                              name="max_price"
                              id="max_price"
                          >
                            <option value="" disabled>Max Price</option>
                            <option :value="0">£0</option>
                            <option :value="50">£50</option>
                            <option :value="100">£100</option>
                            <option :value="150">£150</option>
                            <option :value="200">£200</option>
                            <option :value="250">£250</option>
                            <option :value="500">£500</option>
                            <option :value="750">£750</option>
                            <option :value="1000">£1,000</option>
                            <option :value="1250">£1,250</option>
                            <option :value="1500">£1,500</option>
                            <option :value="1750">£1,750</option>
                            <option :value="2000">£2,000</option>
                            <option :value="3000">£3,000</option>
                            <option :value="4000">£4,000</option>
                            <option :value="5000">£5,000</option>
                            <option :value="10000">£10,000</option>
                            <option :value="20000">£20,000</option>
                            <option :value="30000">£30,000</option>
                            <option :value="40000">£40,000</option>
                            <option :value="50000">£50,000</option>
                            <option :value="60000">£60,000</option>
                            <option :value="70000">£70,000</option>
                            <option :value="80000">£80,000</option>
                            <option :value="90000">£90,000</option>
                            <option :value="100000">£100,000</option>
                            <option :value="110000">£110,000</option>
                            <option :value="120000">£120,000</option>
                            <option :value="130000">£130,000</option>
                            <option :value="140000">£140,000</option>
                            <option :value="150000">£150,000</option>
                            <option :value="160000">£160,000</option>
                            <option :value="170000">£170,000</option>
                            <option :value="180000">£180,000</option>
                            <option :value="190000">£190,000</option>
                            <option :value="200000">£200,000</option>
                            <option :value="210000">£210,000</option>
                            <option :value="220000">£220,000</option>
                            <option :value="230000">£230,000</option>
                            <option :value="240000">£240,000</option>
                            <option :value="250000">£250,000</option>
                            <option :value="260000">£260,000</option>
                            <option :value="270000">£270,000</option>
                            <option :value="280000">£280,000</option>
                            <option :value="290000">£290,000</option>
                            <option :value="300000">£300,000</option>
                            <option :value="310000">£310,000</option>
                            <option :value="320000">£320,000</option>
                            <option :value="330000">£330,000</option>
                            <option :value="340000">£340,000</option>
                            <option :value="350000">£350,000</option>
                            <option :value="360000">£360,000</option>
                            <option :value="370000">£370,000</option>
                            <option :value="380000">£380,000</option>
                            <option :value="390000">£390,000</option>
                            <option :value="400000">£400,000</option>
                            <option :value="410000">£410,000</option>
                            <option :value="420000">£420,000</option>
                            <option :value="430000">£430,000</option>
                            <option :value="440000">£440,000</option>
                            <option :value="450000">£450,000</option>
                            <option :value="460000">£460,000</option>
                            <option :value="470000">£470,000</option>
                            <option :value="480000">£480,000</option>
                            <option :value="490000">£490,000</option>
                            <option :value="500000">£500,000</option>
                            <option :value="550000">£550,000</option>
                            <option :value="600000">£600,000</option>
                            <option :value="650000">£650,000</option>
                            <option :value="700000">£700,000</option>
                            <option :value="750000">£750,000</option>
                            <option :value="800000">£800,000</option>
                            <option :value="850000">£850,000</option>
                            <option :value="900000">£900,000</option>
                            <option :value="950000">£950,000</option>
                            <option :value="1000000">£1,000,000</option>
                            <option :value="1250000">£1,250,000</option>
                            <option :value="1500000">£1,500,000</option>
                            <option :value="1750000">£1,750,000</option>
                            <option :value="2000000">£2,000,000</option>
                            <option :value="2250000">£2,250,000</option>
                            <option :value="2500000">£2,500,000</option>
                            <option :value="2750000">£2,750,000</option>
                            <option :value="3000000">£3,000,000</option>
                            <option :value="3250000">£3,250,000</option>
                            <option :value="3500000">£3,500,000</option>
                            <option :value="3750000">£3,750,000</option>
                            <option :value="4000000">£4,000,000</option>
                            <option :value="4250000">£4,250,000</option>
                            <option :value="4500000">£4,500,000</option>
                            <option :value="4750000">£4,750,000</option>
                            <option :value="5000000">£5,000,000</option>
                            <option :value="7500000">£7,500,000</option>
                            <option :value="10000000">£10,000,000</option>
                            <option :value="15000000">£15,000,000</option>
                            <option :value="20000000">£20,000,000</option>
                          </select>
                        </div>
                        <div class="col-12 m-0 p-1">
<!--                          @focus="handleFocus($event)"-->
<!--                          @focusout="handleFocusOut(searchForm.property_rent_type_id, $event)"-->
                          <select
                              v-model="searchForm.property_rent_type_id"
                              class="select-placeholder search-filter-btns cursor-pointer dropdown-btn"
                              tabindex="-1"
                              aria-label="property_rent_type_id"
                              name="property_rent_type_id"
                              id="property_rent_type_id"
                          >
                            <option value="" disabled>Frequency</option>
                            <option :value="1">Not Applicable (N/A)</option>
                            <option :value="2">Per Hour</option>
                            <option :value="3">Per Night</option>
                            <option :value="4">Per Week</option>
                            <option :value="5">Per Month</option>
                            <option :value="6">To Be Discussed (TBD)</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-sm-2 col-md-2 col-lg-2 p-0 m-0 border-lr">
                  <div class="dropdown-container-relative text-start">
<!--                    @focus="handleFocus($event)"-->
<!--                    @focusout="handleFocusOut(searchForm.checked, $event)"-->
                  <label class="p-0 m-0 ps-1 search-filters-label">Property Type</label>
                  <button
                      type="button"
                      id="dropdownPropType"
                      class="search-filter-btns select-placeholder text-start dropdown-toggle dropdown-btn"
                      data-bs-toggle="dropdown"
                      data-bs-auto-close="outside"
                  >
                    <ul style="vertical-align: middle;" v-if="propertyType && propertyType.length > 0" class="d-inline-block list-unstyled overflow-hidden max-width-100 p-0 m-0">
                      <li class="d-inline-block p-0 m-0 me-1" v-for="(type, index) in propertyType" :key="index">
                        <div>{{ type }} </div>
                      </li>
                    </ul>
                    <div class="d-inline-block" v-else>Property Type</div>
                  </button>
<!--                  <label class="static-label-top">Property Type</label>-->
                  <div class="dropdown-menu dropdown-menu-prop-type-fixed" aria-labelledby="dropdownPropType">

                    <div class="row m-0 p-0">
                      <!-- Residential - Houses -->
                      <div class="col-4 m-0 p-1">
                        <hr/>
                        <div class="col-12 m-1 p-0 filter-prop-label">
                          <label class="form-check-label me-1 ms-1 fw-bold" for="houses">
                          <input type="checkbox" v-model="searchForm.houses" @click="actionPropType('houses', $event)" id="houses" class="form-check-input m-1">
                          Houses
                          </label>
                        </div>
                        <hr/>
                        <div class="col-12 m-1 p-0">
                          <label class="form-check-label me-1 ms-1" for="detached_house">
                          <input type="checkbox" v-model="searchForm.detached_house" id="detached_house" class="form-check-input m-1">
                          Detached House
                          </label>
                        </div>
                        <div class="col-12 m-1 p-0">
                          <label class="form-check-label me-1 ms-1" for="semi_detached_house">
                          <input type="checkbox" v-model="searchForm.semi_detached_house" id="semi_detached_house" class="form-check-input m-1">
                          Semi-Detached House
                          </label>
                        </div>
                        <div class="col-12 m-1 p-0">
                          <label class="form-check-label me-1 ms-1" for="mid_terraced">
                          <input type="checkbox" v-model="searchForm.mid_terraced" id="mid_terraced" class="form-check-input m-1">
                          Mid-Terraced House
                          </label>
                        </div>
                        <div class="col-12 m-1 p-0">
                          <label class="form-check-label me-1 ms-1" for="end_terraced">
                          <input type="checkbox" v-model="searchForm.end_terraced" id="end_terraced" class="form-check-input m-1">
                          End-Terraced House
                          </label>
                        </div>
                        <div class="col-12 m-1 p-0">
                          <label class="form-check-label me-1 ms-1" for="detached_bungalow">
                          <input type="checkbox" v-model="searchForm.detached_bungalow" id="detached_bungalow" class="form-check-input m-1">
                          Detached Bungalow
                          </label>
                        </div>
                        <div class="col-12 m-1 p-0">
                          <label class="form-check-label me-1 ms-1" for="semi_detached_bungalow">
                          <input type="checkbox" v-model="searchForm.semi_detached_bungalow" id="semi_detached_bungalow" class="form-check-input m-1">
                          Semi-Detached Bungalow
                          </label>
                        </div>
                        <div class="col-12 m-1 p-0">
                          <label class="form-check-label me-1 ms-1" for="cottage">
                          <input type="checkbox" v-model="searchForm.cottage" id="cottage" class="form-check-input m-1">
                          Cottage
                          </label>
                        </div>
                      </div>
                      <!-- Residential - Flats -->
                      <div class="col-4 m-0 p-1">
                        <hr/>
                        <div class="col-12 m-1 p-0 filter-prop-label">
                          <label class="form-check-label me-1 ms-1 fw-bold" for="flats">
                          <input type="checkbox" v-model="searchForm.flats" @click="actionPropType('flats', $event)" id="flats" class="form-check-input m-1">
                          Flats
                          </label>
                        </div>
                        <hr/>
                        <div class="col-12 m-1 p-0">
                          <label class="form-check-label me-1 ms-1" for="flat">
                          <input type="checkbox" v-model="searchForm.flat" id="flat" class="form-check-input m-1">
                          Flat
                          </label>
                        </div>
                        <div class="col-12 m-1 p-0">
                          <label class="form-check-label me-1 ms-1" for="studio">
                          <input type="checkbox" v-model="searchForm.studio" id="studio" class="form-check-input m-1">
                          Studio Flat
                          </label>
                        </div>
                        <div class="col-12 m-1 p-0">
                          <label class="form-check-label me-1 ms-1" for="maisonette">
                          <input type="checkbox" v-model="searchForm.maisonette" id="maisonette" class="form-check-input m-1">
                          Maisonette
                          </label>
                        </div>
                      </div>
                      <!-- Land -->
                      <div class="col-4 m-0 p-1">
                        <hr/>
                        <div class="col-12 m-1 p-0 filter-prop-label">
                          <label class="form-check-label me-1 ms-1 fw-bold" for="land">
                          <input type="checkbox" v-model="searchForm.land" @click="actionPropType('land', $event)" id="land" class="form-check-input m-1">
                          Land
                          </label>
                        </div>
                        <hr/>
                        <div class="col-12 m-1 p-0">
                          <label class="form-check-label me-1 ms-1" for="greenfield">
                          <input type="checkbox" v-model="searchForm.greenfield" id="greenfield" class="form-check-input m-1">
                          Greenfield
                          </label>
                        </div>
                        <div class="col-12 m-1 p-0">
                          <label class="form-check-label me-1 ms-1" for="brownfield">
                          <input type="checkbox" v-model="searchForm.brownfield" id="brownfield" class="form-check-input m-1">
                          Brownfield
                          </label>
                        </div>
                      </div>
                      <!-- Commercial - Retail -->
                      <div class="col-4 m-0 p-1">
                        <hr/>
                        <div class="col-12 m-1 p-0 filter-prop-label">
                          <label class="form-check-label me-1 ms-1 fw-bold" for="retail">
                          <input type="checkbox" v-model="searchForm.retail" @click="actionPropType('retail', $event)" id="retail" class="form-check-input m-1">
                          Retail
                          </label>
                        </div>
                        <hr/>
                        <div class="col-12 m-1 p-0">
                          <label class="form-check-label me-1 ms-1" for="shop">
                          <input type="checkbox" v-model="searchForm.shop" id="shop" class="form-check-input m-1">
                          Shop
                          </label>
                        </div>
                        <div class="col-12 m-1 p-0">
                          <label class="form-check-label me-1 ms-1" for="shopping_mall">
                          <input type="checkbox" v-model="searchForm.shopping_mall" id="shopping_mall" class="form-check-input m-1">
                          Shopping Mall
                          </label>
                        </div>
                        <div class="col-12 m-1 p-0">
                          <label class="form-check-label me-1 ms-1" for="retail_store">
                          <input type="checkbox" v-model="searchForm.retail_store" id="retail_store" class="form-check-input m-1">
                          Retail Store
                          </label>
                        </div>
                      </div>
                      <!-- Commercial - Leisure -->
                      <div class="col-4 m-0 p-1">
                        <hr/>
                        <div class="col-12 m-1 p-0 filter-prop-label">
                          <label class="form-check-label me-1 ms-1 fw-bold" for="leisure">
                          <input type="checkbox" v-model="searchForm.leisure" @click="actionPropType('leisure', $event)" id="leisure" class="form-check-input m-1">
                          Leisure
                          </label>
                        </div>
                        <hr/>
                        <div class="col-12 m-1 p-0">
                          <label class="form-check-label me-1 ms-1" for="restaurant_cafe">
                          <input type="checkbox" v-model="searchForm.restaurant_cafe" id="restaurant_cafe" class="form-check-input m-1">
                          Restaurant / Cafe
                          </label>
                        </div>
                        <div class="col-12 m-1 p-0">
                          <label class="form-check-label me-1 ms-1" for="takeaway">
                          <input type="checkbox" v-model="searchForm.takeaway" id="takeaway" class="form-check-input m-1">
                          Takeaway
                          </label>
                        </div>
                        <div class="col-12 m-1 p-0">
                          <label class="form-check-label me-1 ms-1" for="pub">
                          <input type="checkbox" v-model="searchForm.pub" id="pub" class="form-check-input m-1">
                          Pub
                          </label>
                        </div>
                        <div class="col-12 m-1 p-0">
                          <label class="form-check-label me-1 ms-1" for="entertainment">
                          <input type="checkbox" v-model="searchForm.entertainment" id="entertainment" class="form-check-input m-1">
                          Entertainment
                          </label>
                        </div>
                        <div class="col-12 m-1 p-0">
                          <label class="form-check-label me-1 ms-1" for="sport_healthcare_facility">
                          <input type="checkbox" v-model="searchForm.sport_healthcare_facility" id="sport_healthcare_facility" class="form-check-input m-1">
                          Sport / Healthcare Facility
                          </label>
                        </div>
                        <div class="col-12 m-1 p-0">
                          <label class="form-check-label me-1 ms-1" for="hotel_tourism">
                          <input type="checkbox" v-model="searchForm.hotel_tourism" id="hotel_tourism" class="form-check-input m-1">
                          Hotel / Tourism
                          </label>
                        </div>
                      </div>
                      <!-- Commercial - Offices -->
                      <div class="col-4 m-0 p-1">
                        <hr/>
                        <div class="col-12 m-1 p-0 filter-prop-label">
                          <label class="form-check-label me-1 ms-1 fw-bold" for="offices">
                          <input type="checkbox" v-model="searchForm.offices" @click="actionPropType('offices', $event)" id="offices" class="form-check-input m-1">
                          Offices
                          </label>
                        </div>
                        <hr/>
                        <div class="col-12 m-1 p-0">
                          <label class="form-check-label me-1 ms-1" for="traditional_office">
                          <input type="checkbox" v-model="searchForm.traditional_office" id="traditional_office" class="form-check-input m-1">
                          Traditional Office
                          </label>
                        </div>
                        <div class="col-12 m-1 p-0">
                          <label class="form-check-label me-1 ms-1" for="serviced_office">
                          <input type="checkbox" v-model="searchForm.serviced_office" id="serviced_office" class="form-check-input m-1">
                          Serviced Office
                          </label>
                        </div>
                        <div class="col-12 m-1 p-0">
                          <label class="form-check-label me-1 ms-1" for="managed_office">
                          <input type="checkbox" v-model="searchForm.managed_office" id="managed_office" class="form-check-input m-1">
                          Managed Office
                          </label>
                        </div>
                      </div>
                      <!-- Commercial - Healthcare -->
                      <div class="col-4 m-0 p-1">
                        <hr/>
                        <div class="col-12 m-1 p-0 filter-prop-label">
                          <label class="form-check-label me-1 ms-1 fw-bold" for="healthcare">
                          <input type="checkbox" v-model="searchForm.healthcare" @click="actionPropType('healthcare', $event)" id="healthcare" class="form-check-input m-1">
                          Healthcare
                          </label>
                        </div>
                        <hr/>
                        <div class="col-12 m-1 p-0">
                          <label class="form-check-label me-1 ms-1" for="pharmacy">
                          <input type="checkbox" v-model="searchForm.pharmacy" id="pharmacy" class="form-check-input m-1">
                          Pharmacy
                          </label>
                        </div>
                        <div class="col-12 m-1 p-0">
                          <label class="form-check-label me-1 ms-1" for="medical_center">
                          <input type="checkbox" v-model="searchForm.medical_center" id="medical_center" class="form-check-input m-1">
                          Medical Center
                          </label>
                        </div>
                        <div class="col-12 m-1 p-0">
                          <label class="form-check-label me-1 ms-1" for="nursing_home">
                          <input type="checkbox" v-model="searchForm.nursing_home" id="nursing_home" class="form-check-input m-1">
                          Nursing Home
                          </label>
                        </div>
                        <div class="col-12 m-1 p-0">
                          <label class="form-check-label me-1 ms-1" for="hospital">
                          <input type="checkbox" v-model="searchForm.hospital" id="hospital" class="form-check-input m-1">
                          Hospital
                          </label>
                        </div>
                      </div>
                      <!-- Commercial - Garage/Parking -->
                      <div class="col-4 m-0 p-1">
                        <hr/>
                        <div class="col-12 m-1 p-0 filter-prop-label">
                          <label class="form-check-label me-1 ms-1 fw-bold" for="garage_parking">
                          <input type="checkbox" v-model="searchForm.garage_parking" @click="actionPropType('garage_parking', $event)" id="garage_parking" class="form-check-input m-1">
                          Garage / Parking
                          </label>
                        </div>
                        <hr/>
                        <div class="col-12 m-1 p-0">
                          <label class="form-check-label me-1 ms-1" for="parking_space">
                          <input type="checkbox" v-model="searchForm.parking_space" id="parking_space" class="form-check-input m-1">
                          Parking Space
                          </label>
                        </div>
                        <div class="col-12 m-1 p-0">
                          <label class="form-check-label me-1 ms-1" for="single_garage">
                          <input type="checkbox" v-model="searchForm.single_garage" id="single_garage" class="form-check-input m-1">
                          Single Garage
                          </label>
                        </div>
                        <div class="col-12 m-1 p-0">
                          <label class="form-check-label me-1 ms-1" for="multiple_garages">
                          <input type="checkbox" v-model="searchForm.multiple_garages" id="multiple_garages" class="form-check-input m-1">
                          Multiple Garages
                          </label>
                        </div>
                        <div class="col-12 m-1 p-0">
                          <label class="form-check-label me-1 ms-1" for="parking_lot">
                          <input type="checkbox" v-model="searchForm.parking_lot" id="parking_lot" class="form-check-input m-1">
                          Parking Lot
                          </label>
                        </div>
                        <div class="col-12 m-1 p-0">
                          <label class="form-check-label me-1 ms-1" for="multi_storey_parking">
                          <input type="checkbox" v-model="searchForm.multi_storey_parking" id="multi_storey_parking" class="form-check-input m-1">
                          Multi-Storey Parking
                          </label>
                        </div>
                      </div>
                      <!-- Commercial - Multifamily -->
                      <div class="col-4 m-0 p-1">
                        <hr/>
                        <div class="col-12 m-1 p-0 filter-prop-label">
                          <label class="form-check-label me-1 ms-1 fw-bold" for="multifamily">
                          <input type="checkbox" v-model="searchForm.multifamily" @click="actionPropType('multifamily', $event)" id="multifamily" class="form-check-input m-1">
                          Multifamily
                          </label>
                        </div>
                        <hr/>
                        <div class="col-12 m-1 p-0">
                          <label class="form-check-label me-1 ms-1" for="duplex">
                          <input type="checkbox" v-model="searchForm.duplex" id="duplex" class="form-check-input m-1">
                          Duplex
                          </label>
                        </div>
                        <div class="col-12 m-1 p-0">
                          <label class="form-check-label me-1 ms-1" for="block_of_flats">
                          <input type="checkbox" v-model="searchForm.block_of_flats" id="block_of_flats" class="form-check-input m-1">
                          Block of Flats
                          </label>
                        </div>
                        <div class="col-12 m-1 p-0">
                          <label class="form-check-label me-1 ms-1" for="apartment_complex">
                          <input type="checkbox" v-model="searchForm.apartment_complex" id="apartment_complex" class="form-check-input m-1">
                          Apartment Complex
                          </label>
                        </div>
                      </div>
                      <!-- Luxury -->
                      <div class="col-4 m-0 p-1">
                        <hr/>
                        <div class="col-12 m-1 p-0 filter-prop-label">
                          <label class="form-check-label me-1 ms-1 fw-bold" for="luxury">
                          <input type="checkbox" v-model="searchForm.luxury" @click="actionPropType('luxury', $event)" id="luxury" class="form-check-input m-1">
                          Luxury
                          </label>
                        </div>
                        <hr/>
                        <div class="col-12 m-1 p-0">
                          <label class="form-check-label me-1 ms-1" for="mansion">
                          <input type="checkbox" v-model="searchForm.mansion" id="mansion" class="form-check-input m-1">
                          Mansion
                          </label>
                        </div>
                        <div class="col-12 m-1 p-0">
                          <label class="form-check-label me-1 ms-1" for="villa">
                            <input type="checkbox" v-model="searchForm.villa" id="villa" class="form-check-input m-1">
                            Villa
                          </label>
                        </div>
                      </div>
                      <!-- Special Use -->
                      <div class="col-4 m-0 p-1">
                        <hr/>
                        <div class="col-12 m-1 p-0 filter-prop-label">
                          <label class="form-check-label me-1 ms-1 fw-bold" for="special_use">
                          <input type="checkbox" v-model="searchForm.special_use" @click="actionPropType('special_use', $event)" id="special_use" class="form-check-input m-1">
                          Special Use
                          </label>
                        </div>
                        <hr/>
                        <div class="col-12 m-1 p-0">
                          <label class="form-check-label me-1 ms-1" for="church">
                          <input type="checkbox" v-model="searchForm.church" id="church" class="form-check-input m-1">
                          Church
                          </label>
                        </div>
                        <div class="col-12 m-1 p-0">
                          <label class="form-check-label me-1 ms-1" for="cemetery">
                          <input type="checkbox" v-model="searchForm.cemetery" id="cemetery" class="form-check-input m-1">
                          Cemetery
                          </label>
                        </div>
                        <div class="col-12 m-1 p-0">
                          <label class="form-check-label me-1 ms-1" for="park">
                          <input type="checkbox" v-model="searchForm.park" id="park" class="form-check-input m-1">
                          Park
                          </label>
                        </div>
                        <div class="col-12 m-1 p-0">
                          <label class="form-check-label me-1 ms-1" for="school">
                          <input type="checkbox" v-model="searchForm.school" id="school" class="form-check-input m-1">
                          School
                          </label>
                        </div>
                        <div class="col-12 m-1 p-0">
                          <label class="form-check-label me-1 ms-1" for="barn">
                          <input type="checkbox" v-model="searchForm.barn" id="barn" class="form-check-input m-1">
                          Barn
                          </label>
                        </div>
                        <div class="col-12 m-1 p-0">
                          <label class="form-check-label me-1 ms-1" for="campsite">
                            <input type="checkbox" v-model="searchForm.campsite" id="campsite" class="form-check-input m-1">
                            Campsite
                          </label>
                        </div>
                      </div>
                      <!--Rooms -->
                      <div class="col-4 m-0 p-1">
                        <hr/>
                        <div class="col-12 m-1 p-0 filter-prop-label">
                          <label class="form-check-label me-1 ms-1 fw-bold" for="rooms">
                          <input type="checkbox" v-model="searchForm.rooms" @click="actionPropType('rooms', $event)" id="rooms" class="form-check-input m-1">
                          Rooms
                          </label>
                        </div>
                        <hr/>
                        <div class="col-12 m-1 p-0">
                          <label class="form-check-label me-1 ms-1" for="single_room">
                          <input type="checkbox" v-model="searchForm.single_room" id="single_room" class="form-check-input m-1">
                          Single Room
                          </label>
                        </div>
                        <div class="col-12 m-1 p-0">
                          <label class="form-check-label me-1 ms-1" for="double_room">
                          <input type="checkbox" v-model="searchForm.double_room" id="double_room" class="form-check-input m-1">
                          Double Room
                          </label>
                        </div>
                        <div class="col-12 m-1 p-0">
                          <label class="form-check-label me-1 ms-1" for="twin_room">
                          <input type="checkbox" v-model="searchForm.twin_room" id="twin_room" class="form-check-input m-1">
                          Twin Room
                          </label>
                        </div>
                        <div class="col-12 m-1 p-0">
                          <label class="form-check-label me-1 ms-1" for="twin_double_room">
                          <input type="checkbox" v-model="searchForm.twin_double_room" id="twin_double_room" class="form-check-input m-1">
                          Twin Double Room
                          </label>
                        </div>
                        <div class="col-12 m-1 p-0">
                          <label class="form-check-label me-1 ms-1" for="triple_room">
                          <input type="checkbox" v-model="searchForm.triple_room" id="triple_room" class="form-check-input m-1">
                          Triple Room
                          </label>
                        </div>
                        <div class="col-12 m-1 p-0">
                          <label class="form-check-label me-1 ms-1" for="quadruple_room">
                          <input type="checkbox" v-model="searchForm.quadruple_room" id="quadruple_room" class="form-check-input m-1">
                          Quadruple Room
                          </label>
                        </div>
                        <div class="col-12 m-1 p-0">
                          <label class="form-check-label me-1 ms-1" for="family_room">
                          <input type="checkbox" v-model="searchForm.family_room" id="family_room" class="form-check-input m-1">
                          Family Room
                          </label>
                        </div>
                      </div>
                      <!-- Industrial - Warehouses -->
                      <div class="col-4 m-0 p-1">
                        <hr/>
                        <div class="col-12 m-1 p-0 filter-prop-label">
                          <label class="form-check-label me-1 ms-1 fw-bold" for="warehouses">
                          <input type="checkbox" v-model="searchForm.warehouses" @click="actionPropType('warehouses', $event)" id="warehouses" class="form-check-input m-1">
                          Warehouses
                          </label>
                        </div>
                        <hr/>
                      </div>
                      <!-- Industrial - Factories -->
                      <div class="col-4 m-0 p-1">
                        <hr/>
                        <div class="col-12 m-1 p-0 filter-prop-label">
                          <label class="form-check-label me-1 ms-1 fw-bold" for="factories">
                          <input type="checkbox" v-model="searchForm.factories" @click="actionPropType('factories', $event)" id="factories" class="form-check-input m-1">
                          Factories
                          </label>
                        </div>
                        <hr/>
                      </div>
                      <!-- Industrial - Power Plants -->
                      <div class="col-4 m-0 p-1">
                        <hr/>
                        <div class="col-12 m-1 p-0 filter-prop-label">
                          <label class="form-check-label me-1 ms-1 fw-bold" for="power_plants">
                          <input type="checkbox" v-model="searchForm.power_plants" @click="actionPropType('power_plants', $event)" id="power_plants" class="form-check-input m-1">
                          Power Plants
                          </label>
                        </div>
                        <hr/>
                        <div class="col-12 m-1 p-0">
                          <label class="form-check-label me-1 ms-1" for="hydroelectric_plant">
                          <input type="checkbox" v-model="searchForm.hydroelectric_plant" id="hydroelectric_plant" class="form-check-input m-1">
                          Hydroelectric Plant
                          </label>
                        </div>
                        <div class="col-12 m-1 p-0">
                          <label class="form-check-label me-1 ms-1" for="coal_fired_plant">
                          <input type="checkbox" v-model="searchForm.coal_fired_plant" id="coal_fired_plant" class="form-check-input m-1">
                          Coal-fired Plant
                          </label>
                        </div>
                        <div class="col-12 m-1 p-0">
                          <label class="form-check-label me-1 ms-1" for="diesel_fired_plant">
                          <input type="checkbox" v-model="searchForm.diesel_fired_plant" id="diesel_fired_plant" class="form-check-input m-1">
                          Diesel-fired Plant
                          </label>
                        </div>
                        <div class="col-12 m-1 p-0">
                          <label class="form-check-label me-1 ms-1" for="geothermal_plant">
                          <input type="checkbox" v-model="searchForm.geothermal_plant" id="geothermal_plant" class="form-check-input m-1">
                          Geothermal Plant
                          </label>
                        </div>
                        <div class="col-12 m-1 p-0">
                          <label class="form-check-label me-1 ms-1" for="gas_fired_plant">
                          <input type="checkbox" v-model="searchForm.gas_fired_plant" id="gas_fired_plant" class="form-check-input m-1">
                          Gas-fired Plant
                          </label>
                        </div>
                        <div class="col-12 m-1 p-0">
                          <label class="form-check-label me-1 ms-1" for="solar_power_plant">
                          <input type="checkbox" v-model="searchForm.solar_power_plant" id="solar_power_plant" class="form-check-input m-1">
                          Solar Power Plant
                          </label>
                        </div>
                        <div class="col-12 m-1 p-0">
                          <label class="form-check-label me-1 ms-1" for="wind_power_plant">
                          <input type="checkbox" v-model="searchForm.wind_power_plant" id="wind_power_plant" class="form-check-input m-1">
                          Wind Power Plant
                          </label>
                        </div>
                        <div class="col-12 m-1 p-0">
                          <label class="form-check-label me-1 ms-1" for="tidal_power_plant">
                          <input type="checkbox" v-model="searchForm.tidal_power_plant" id="tidal_power_plant" class="form-check-input m-1">
                          Tidal Power Plant
                          </label>
                        </div>
                      </div>

                    </div>
                  </div>
                  </div>
                </div>
                <div class="col-12 col-sm-1 col-md-1 col-lg-1 p-0 m-0 border-lr dropdown-btn border-radius-r-5">
                  <div class="row p-0 m-0 h-100">
                    <div class="col-6 p-0 m-0">
                      <div v-if="searchForm.ad_type && (searchForm.ad_type === 2 || searchForm.ad_type === 3 || searchForm.ad_type === 5 || searchForm.ad_type === 6)"
                           class="dropdown-container-relative text-start"
                      >
<!--                        @focus="handleFocus($event)"-->
<!--                        @focusout="handleFocusOut(searchForm.min_price ? searchForm.min_price : searchForm.max_price, $event)"-->
                        <label class="p-0 m-0 ps-1 search-filters-label">Dates</label>
                        <button
                            type="button"
                            id="dropdownDateRange"
                            class="search-filter-btns select-placeholder dropdown-toggle dropdown-btn"
                            data-bs-toggle="dropdown"
                            data-bs-auto-close="outside"
                        >
                          <font-awesome-icon icon="calendar-day" :class="datePresent === true ? 'calendar-data-set' : 'calendar-data-unset'"/>
                        </button>
<!--                        <label class="static-label-top">Dates</label>-->
                        <div class="dropdown-menu dropdown-menu-date dropdown-menu-date-filters" aria-labelledby="dropdownDateRange">
                          <div class="row m-0 p-0 text-center">
                            <div class="col-12 m-0 p-1 text-center">
                              <button class="btn btn-danger m-1" @click="clearDates()">Clear dates</button>
                              <date-picker
                                  :value="searchForm.date_range"
                                  v-model="searchForm.date_range"
                                  mode="date"
                                  :is-range=true
                                  :show-weeknumbers=true
                                  color="red"
                                  :attributes="attr"
                              />
                              <div class="row p-0 m-1">
                                <div class="col-12 m-1">
                                  <div class="form-control">
                                    <strong>From:</strong> {{searchForm.date_range && searchForm.date_range.start ? moment.utc(searchForm.date_range.start).format('DD-MM-YYYY') : 'None'}}
                                  </div>
                                </div>
                                <div class="col-12 m-1">
                                  <div class="form-control">
                                    <strong>To:</strong> {{searchForm.date_range && searchForm.date_range.end ? moment.utc(searchForm.date_range.end).format('DD-MM-YYYY') : 'None'}}
                                  </div>
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-6 p-0 m-0">
                      <button id="searchFilterButton" class="btn btn-outline-primary" v-on:click.prevent="searchProps" type="submit"><font-awesome-icon icon="search-location" class="icon-fa" /></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div id="filtersContainer"> <!-- btn btn-outline-success btn-round zindex-max -->
            <button id="filtersButton" class="btn-outline-success btn-round-filters" v-on:click="activeFilters = !activeFilters"><font-awesome-icon icon="angle-double-down" id="filterIcon" class="m-0 p-0" /></button>
          </div>
        </div>
        <transition name="fade" v-if="activeFilters">
          <div id="openFilters" class="openFilters scroll-width-thin" tabindex="-1">
            <div id="filters-form" class="filters-form">
            <form class="">
              <div class="row m-0 p-0 text-start">

                <!-- Start Main input fields -->
                <div class="row m-0 p-0">

                  <!-- Start Horizontal Line with Icon -->
                  <div class="horizontal-separator-box">
                    <span class="hr-line-short"></span>
                    <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                    <span class="hr-line-short"></span>
                  </div>
                  <!-- End Horizontal Line with Icon -->

                  <div v-if="!searchForm.ad_type" class="m-2 p-2 fw-bold text-center">
                    Please choose advertise type to see more advanced filters.
                  </div>

                  <div v-if="searchForm.ad_type" class="m-2 p-2 fw-bold text-center">
                    What should be included in your search?
                  </div>

                  <div v-if="searchForm.ad_type && (searchForm.ad_type === 5 || searchForm.ad_type === 6)" class="row m-0 p-0">
                    <div class="col-12 p-0 mt-1 mb-1">
                      <div class="row m-0 p-0">
                        <!-- Long-Short-Both Term Stays -->
                        <div class="col-2 col-sm-4 col-md-4 col-lg-2">
                          <p class="options-paragraph m-0 p-0 me-2 d-inline">Let ads term: </p>
                          <font-awesome-icon icon="calendar-day" class="icon-fa" />
                        </div>
                        <div class="col-10 col-sm-8 col-md-8 col-lg-10">
                          <label class="radio-container">Long-Term Let
                            <input type="radio" name="long_short_both_term" id="long_term" value="1" v-model="searchForm.is_long_short_term" />
                            <span class="checkmark"></span>
                          </label>
                          <label class="radio-container">Short-Term Stay
                            <input type="radio" name="long_short_both_term" id="short_term" value="2" v-model="searchForm.is_long_short_term" />
                            <span class="checkmark"></span>
                          </label>
                          <label class="radio-container">Both Long-Term Let & Short-Term Stays
                            <input type="radio" name="long_short_both_term" id="both_term" value="3" v-model="searchForm.is_long_short_term" />
                            <span class="checkmark"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Tenure -->
                  <!-- searchForm.ad_type 5 = Let; searchForm.ad_type 2 = Rent; -->
                  <div v-if="searchForm.ad_type && (searchForm.ad_type !== 5 && searchForm.ad_type !== 2)" class="col-12 p-0 mt-1 mb-1">
                    <div class="row m-0 p-0">
                      <div class="col-2 col-sm-4 col-md-4 col-lg-2 pt-2">
                        <p class="options-paragraph m-0 p-0 me-2 d-inline">Tenure</p>
                        <font-awesome-icon icon="file-signature" class="icon-fa" />
                      </div>
                      <div class="col-10 col-sm-8 col-md-8 col-lg-10">
<!--                        @focus="handleFocus($event)"-->
<!--                        @focusout="handleFocusOut(searchForm.tenure, $event)"-->
                        <select
                            v-model="searchForm.tenure"
                            class="select-placeholder filter-select cursor-pointer"
                            tabindex="-1"
                            aria-label="tenure"
                            name="tenure"
                            id="tenure"
                        >
                          <option value="" disabled>Tenure</option>
                          <option :value="1">All</option>
                          <option :value="2">Freehold</option>
                          <option :value="3">Leasehold</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <!-- Number of Guests-->
                  <div v-if="searchForm.ad_type && (searchForm.ad_type == 5 || searchForm.ad_type == 6)" class="col-12 p-0 mt-1 mb-1">
                    <div class="row m-0 p-0">
                      <div class="col-2 col-sm-4 col-md-4 col-lg-2 pt-2">
                        <div class="list-relative">
                          <p class="options-paragraph m-0 p-0 me-2 d-inline">Guests/Tenants</p>
                          <font-awesome-icon icon="user-friends" class="icon-fa" />
                        </div>
                      </div>
                      <div class="col-10 col-sm-8 col-md-8 col-lg-10 pt-2">
                        <div class="row m-0 p-0">
                          <div class="col-6 m-0 p-0 pe-1">
<!--                            @focus="handleFocus($event)"-->
<!--                            @focusout="handleFocusOut(searchForm.guests, $event)"-->
                            <select
                                v-model="searchForm.guests"
                                class="select-placeholder filter-select cursor-pointer"
                                tabindex="-1"
                                aria-label="guests"
                                name="guests"
                                id="guests"
                            >
                              <option value="" disabled>Number of guests/tenants</option>
                              <option :value="1">1</option>
                              <option :value="2">2</option>
                              <option :value="3">3</option>
                              <option :value="4">4</option>
                              <option :value="5">5</option>
                              <option :value="6">6</option>
                              <option :value="7">7</option>
                              <option :value="8">8</option>
                              <option :value="9">9</option>
                              <option :value="10">10</option>
                              <option :value="11">11</option>
                              <option :value="12">12</option>
                              <option :value="13">13</option>
                              <option :value="14">14</option>
                              <option :value="15">15</option>
                              <option :value="16">16</option>
                              <option :value="17">17</option>
                              <option :value="18">18</option>
                              <option :value="19">19</option>
                              <option :value="20">20+</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Square Meter -->
                  <div v-if="searchForm.ad_type" class="col-12 p-0 mt-1 mb-1">
                    <div class="row m-0 p-0">
                      <div class="col-2 col-sm-4 col-md-4 col-lg-2 pt-2">
                        <div class="list-relative">
                              <p class="options-paragraph m-0 p-0 me-2 d-inline">Square Meter</p>
                              <font-awesome-icon icon="vector-square" class="icon-fa" />
                        </div>
                      </div>
                      <div class="col-10 col-sm-8 col-md-8 col-lg-10 pt-2">
                        <div class="row m-0 p-0">
                          <div class="col-6 m-0 p-0 pe-1">
<!--                            @focus="handleFocus($event)"-->
<!--                            @focusout="handleFocusOut(searchForm.min_square_meter, $event)"-->
                            <select
                                v-model="searchForm.min_square_meter"
                                class="select-placeholder filter-select cursor-pointer"
                                tabindex="-1"
                                aria-label="min_square_meter"
                                name="min_square_meter"
                                id="min_square_meter"
                            >
                              <option value="" disabled>Min Square Meter</option>
                              <option :value="0">0 sqm</option>
                              <option :value="10">10 sqm</option>
                              <option :value="20">20 sqm</option>
                              <option :value="30">30 sqm</option>
                              <option :value="40">40 sqm</option>
                              <option :value="50">50 sqm</option>
                              <option :value="60">60 sqm</option>
                              <option :value="70">70 sqm</option>
                              <option :value="80">80 sqm</option>
                              <option :value="90">90 sqm</option>
                              <option :value="100">100 sqm</option>
                              <option :value="150">150 sqm</option>
                              <option :value="200">200 sqm</option>
                              <option :value="250">250 sqm</option>
                              <option :value="300">300 sqm</option>
                              <option :value="350">350 sqm</option>
                              <option :value="400">400 sqm</option>
                              <option :value="450">450 sqm</option>
                              <option :value="500">500 sqm</option>
                              <option :value="750">750 sqm</option>
                              <option :value="1000">1000 sqm</option>
                              <option :value="1250">1250 sqm</option>
                              <option :value="1500">1500 sqm</option>
                              <option :value="1750">1750 sqm</option>
                              <option :value="2000">2000 sqm</option>
                              <option :value="3000">3000 sqm</option>
                              <option :value="4000">4000 sqm</option>
                              <option :value="5000">5000 sqm</option>
                              <option :value="6000">6000 sqm</option>
                              <option :value="7000">7000 sqm</option>
                              <option :value="8000">8000 sqm</option>
                              <option :value="9000">9000 sqm</option>
                              <option :value="10000">10000+ sqm</option>
                            </select>
                          </div>
                          <div class="col-6 m-0 p-0 ps-1">
<!--                            @focus="handleFocus($event)"-->
<!--                            @focusout="handleFocusOut(searchForm.max_square_meter, $event)"-->
                            <select
                                v-model="searchForm.max_square_meter"
                                class="select-placeholder filter-select cursor-pointer"
                                tabindex="-1"
                                aria-label="max_square_meter"
                                name="max_square_meter"
                                id="max_square_meter"
                            >
                              <option value="" disabled>Max Square Meter</option>
                              <option :value="0">0 sqm</option>
                              <option :value="10">10 sqm</option>
                              <option :value="20">20 sqm</option>
                              <option :value="30">30 sqm</option>
                              <option :value="40">40 sqm</option>
                              <option :value="50">50 sqm</option>
                              <option :value="60">60 sqm</option>
                              <option :value="70">70 sqm</option>
                              <option :value="80">80 sqm</option>
                              <option :value="90">90 sqm</option>
                              <option :value="100">100 sqm</option>
                              <option :value="150">150 sqm</option>
                              <option :value="200">200 sqm</option>
                              <option :value="250">250 sqm</option>
                              <option :value="300">300 sqm</option>
                              <option :value="350">350 sqm</option>
                              <option :value="400">400 sqm</option>
                              <option :value="450">450 sqm</option>
                              <option :value="500">500 sqm</option>
                              <option :value="750">750 sqm</option>
                              <option :value="1000">1000 sqm</option>
                              <option :value="1250">1250 sqm</option>
                              <option :value="1500">1500 sqm</option>
                              <option :value="1750">1750 sqm</option>
                              <option :value="2000">2000 sqm</option>
                              <option :value="3000">3000 sqm</option>
                              <option :value="4000">4000 sqm</option>
                              <option :value="5000">5000 sqm</option>
                              <option :value="6000">6000 sqm</option>
                              <option :value="7000">7000 sqm</option>
                              <option :value="8000">8000 sqm</option>
                              <option :value="9000">9000 sqm</option>
                              <option :value="10000">10000+ sqm</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- ad_type 4 - Sell  // ad_type 5 - Let // ad_type 6 - Sell & Let -->
                  <div v-if="searchForm.ad_type && (searchForm.ad_type == 4 || searchForm.ad_type == 5 || searchForm.ad_type == 6) && (searchForm.is_long_short_term == 1 || searchForm.is_long_short_term == 3)" class="row m-0 p-0">
                    <div class="col-12 p-0 mt-1 mb-1">
                      <div class="row m-0 p-0">
                        <!-- Is Coming Soon -->
                        <div class="col-3 col-sm-4 col-md-4 col-lg-3">
                          <p class="options-paragraph m-0 p-0 me-2 d-inline">Coming soon advertises</p>
                          <font-awesome-icon icon="clock" />
                        </div>
                        <div class="col-3 col-sm-2 col-md-2 col-lg-3">
                          <label class="switch">
                            <input type="checkbox" v-model="searchForm.is_coming_soon">
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Start Horizontal Line with Icon -->
                <div class="horizontal-separator-box">
                  <span class="hr-line-short"></span>
                  <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                  <span class="hr-line-short"></span>
                </div>
                <!-- End Horizontal Line with Icon -->

                <!-- Bathroom & Downstairs WC -->
                <div v-if="searchForm.ad_type" class="row m-0 p-0">
                  <div class="col-12 p-0 mt-1 mb-1">
                    <div class="row m-0 p-0">
                      <!-- Bathrooms -->
                      <div class="col-6 p-0">
                        <div class="row m-0 p-0">
                          <div class="col-4 col-sm-8 col-md-8 col-lg-4">
                            <p class="options-paragraph m-0 p-0 me-2 d-inline">Bathrooms</p>
                            <font-awesome-icon icon="bath" class="icon-fa" />
                          </div>
                          <div class="col-8 col-sm-4 col-md-4 col-lg-8">
                            <!--                                @focus="handleFocus($event)"-->
                            <!--                                @focusout="handleFocusOut(searchForm.bathrooms, $event)"-->
                            <select
                                v-model="searchForm.bathrooms"
                                class="select-placeholder filter-select cursor-pointer"
                                tabindex="-1"
                                aria-label="bathrooms"
                                name="bathrooms"
                                id="bathrooms"
                            >
                              <option value="" disabled>...</option>
                              <option :value="1">1</option>
                              <option :value="2">2</option>
                              <option :value="3">3</option>
                              <option :value="4">4</option>
                              <option :value="5">5+</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <!-- Downstairs WC -->
                      <div class="col-6 p-0">
                        <div class="row m-0 p-0">
                          <div class="col-6 col-sm-8 col-md-8 col-lg-6">
                            <p class="options-paragraph m-0 p-0 me-2 d-inline">Downstairs WC</p>
                            <font-awesome-icon icon="toilet" class="icon-fa" />
                          </div>
                          <div class="col-6 col-sm-4 col-md-4 col-lg-6">
                            <label class="switch">
                              <input type="checkbox" v-model="searchForm.is_downstairs_wc">
                              <span class="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Start Horizontal Line with Icon -->
                <!-- searchForm.ad_type 5 = Let; searchForm.ad_type 2 = Rent -->
                <div v-if="searchForm.ad_type && (searchForm.ad_type !== 5 && searchForm.ad_type !== 2)" class="horizontal-separator-box">
                  <span class="hr-line-short"></span>
                  <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                  <span class="hr-line-short"></span>
                </div>
                <!-- End Horizontal Line with Icon -->

                <!-- Cash or Auction -->
                <!-- searchForm.ad_type 5 = Let; searchForm.ad_type 2 = Rent -->
                <div v-if="searchForm.ad_type && (searchForm.ad_type !== 5 && searchForm.ad_type !== 2)" class="row m-0 p-0">
                  <div class="col-12 p-0 mt-1 mb-1">
                    <div class="row m-0 p-0">
                      <!-- Cash Only -->
                      <div class="col-6 p-0">
                        <div class="row m-0 p-0">
                          <div class="col-6 col-sm-8 col-md-8 col-lg-6">
                            <p class="options-paragraph m-0 p-0 me-2 d-inline">Cash Only</p>
                            <font-awesome-icon icon="coins" class="icon-fa" />
                          </div>
                          <div class="col-6 col-sm-4 col-md-4 col-lg-6">
<!--                            <label class="switch">-->
<!--                              <input type="checkbox" v-model="searchForm.is_cash">-->
<!--                              <span class="slider round"></span>-->
<!--                            </label>-->
                            <label class="radio-container">Show
                              <input type="radio" name="yes_no_only_cash" id="yes_cash" value="3" v-model="searchForm.is_cash" />
                              <span class="checkmark"></span>
                            </label>
                            <label class="radio-container">Hide
                              <input type="radio" name="yes_no_only_cash" id="no_cash" value="1" v-model="searchForm.is_cash" />
                              <span class="checkmark"></span>
                            </label>
                            <label class="radio-container">Only
                              <input type="radio" name="yes_no_only_cash" id="only_cash" value="2" v-model="searchForm.is_cash" />
                              <span class="checkmark"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <!-- Auction -->
                      <div class="col-6 p-0">
                        <div class="row m-0 p-0">
                          <div class="col-6 col-sm-8 col-md-8 col-lg-6">
                            <p class="options-paragraph m-0 p-0 me-2 d-inline">Auction</p>
                            <font-awesome-icon icon="gavel" class="icon-fa" />
                          </div>
                          <div class="col-6 col-sm-4 col-md-4 col-lg-6">
<!--                            <label class="switch">-->
<!--                              <input type="checkbox" v-model="searchForm.is_auction">-->
<!--                              <span class="slider round"></span>-->
<!--                            </label>-->
<!--                            <input class="ms-2" type="checkbox" v-model="searchForm.no_auction" id="no_auction" name="no_auction">-->
<!--                            <label for="no_auction">Hide Auctions</label>-->
                            <label class="radio-container">Show
                              <input type="radio" name="yes_no_only_auction" id="yes_auction" value="3" v-model="searchForm.is_auction" />
                              <span class="checkmark"></span>
                            </label>
                            <label class="radio-container">Hide
                              <input type="radio" name="yes_no_only_auction" id="no_auction" value="1" v-model="searchForm.is_auction" />
                              <span class="checkmark"></span>
                            </label>
                            <label class="radio-container">Only
                              <input type="radio" name="yes_no_only_auction" id="only_auction" value="2" v-model="searchForm.is_auction" />
                              <span class="checkmark"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

<!--                &lt;!&ndash; Start Horizontal Line with Icon &ndash;&gt;-->
<!--                &lt;!&ndash; searchForm.ad_type 3 = Let; searchForm.ad_type 4 = Rent &ndash;&gt;-->
<!--                <div v-if="searchForm.ad_type && (searchForm.ad_type !== 3 && searchForm.ad_type !== 4)" class="horizontal-separator-box">-->
<!--                  <span class="hr-line-short"></span>-->
<!--                  <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>-->
<!--                  <span class="hr-line-short"></span>-->
<!--                </div>-->
<!--                &lt;!&ndash; End Horizontal Line with Icon &ndash;&gt;-->

                <!-- Investment Properties -->
                <!-- searchForm.ad_type 5 = Let; searchForm.ad_type 2 = Rent -->
                <div v-if="searchForm.ad_type && (searchForm.ad_type !== 5 && searchForm.ad_type !== 2)" class="row m-0 p-0">
                  <div class="col-12 p-0 mt-1 mb-1">
                    <div class="row m-0 p-0">
                      <!-- Investment -->
                      <div class="col-6 p-0">
                        <div class="row m-0 p-0">
                          <div class="col-6 col-sm-8 col-md-8 col-lg-6">
                            <p class="options-paragraph m-0 p-0 me-2 d-inline">Investment</p>
                            <font-awesome-icon icon="chart-line" class="icon-fa" />
                          </div>
                          <div class="col-6 col-sm-4 col-md-4 col-lg-6">
<!--                            <label class="switch">-->
<!--                              <input type="checkbox" v-model="searchForm.is_investment">-->
<!--                              <span class="slider round"></span>-->
<!--                            </label>-->
                            <label class="radio-container">Show
                              <input type="radio" name="yes_no_only_investment" id="yes_investment" value="3" v-model="searchForm.is_investment" />
                              <span class="checkmark"></span>
                            </label>
                            <label class="radio-container">Hide
                              <input type="radio" name="yes_no_only_investment" id="no_investment" value="1" v-model="searchForm.is_investment" />
                              <span class="checkmark"></span>
                            </label>
                            <label class="radio-container">Only
                              <input type="radio" name="yes_no_only_investment" id="only_investment" value="2" v-model="searchForm.is_investment" />
                              <span class="checkmark"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <!-- Mixed-use Property -->
                      <div class="col-6 p-0">
                        <div class="row m-0 p-0">
                          <div class="col-6 col-sm-8 col-md-8 col-lg-6">
                            <p class="options-paragraph m-0 p-0 me-2 d-inline">Mixed use</p>
                            <font-awesome-icon icon="balance-scale" class="icon-fa" />
                          </div>
                          <div class="col-6 col-sm-4 col-md-4 col-lg-6">
<!--                            <label class="switch">-->
<!--                              <input type="checkbox" v-model="searchForm.is_mixed_use">-->
<!--                              <span class="slider round"></span>-->
<!--                            </label>-->
                            <label class="radio-container">Show
                              <input type="radio" name="yes_no_only_mixed_use" id="yes_mixed_use" value="3" v-model="searchForm.is_mixed_use" />
                              <span class="checkmark"></span>
                            </label>
                            <label class="radio-container">Hide
                              <input type="radio" name="yes_no_only_mixed_use" id="no_mixed_use" value="1" v-model="searchForm.is_mixed_use" />
                              <span class="checkmark"></span>
                            </label>
                            <label class="radio-container">Only
                              <input type="radio" name="yes_no_only_mixed_use" id="only_mixed_use" value="2" v-model="searchForm.is_mixed_use" />
                              <span class="checkmark"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Start Options input fields -->

                <!-- Start Horizontal Line with Icon -->
                <div v-if="searchForm.ad_type" class="horizontal-separator-box">
                  <span class="hr-line-short"></span>
                  <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                  <span class="hr-line-short"></span>
                </div>
                <!-- End Horizontal Line with Icon -->

                <div v-if="searchForm.ad_type" class="row m-0 p-0">
                  <div class="col-12 p-0 mt-1 mb-1">
                    <div class="row m-0 p-0">
                      <!-- Front Garden -->
                      <div class="col-3 col-sm-4 col-md-4 col-lg-3">
                        <p class="options-paragraph m-0 p-0 me-2 d-inline">Front Garden</p>
                        <font-awesome-icon icon="leaf" />
                      </div>
                      <div class="col-3 col-sm-2 col-md-2 col-lg-3">
                        <label class="switch">
                          <input type="checkbox" v-model="searchForm.front_garden">
                          <span class="slider round"></span>
                        </label>
                      </div>
                      <!-- Garden Terrace -->
                      <div class="col-3 col-sm-4 col-md-4 col-lg-3">
                        <p class="options-paragraph m-0 p-0 me-2 d-inline">Garden Terrace</p>
                        <font-awesome-icon icon="ruler-horizontal" />
                      </div>
                      <div class="col-3 col-sm-2 col-md-2 col-lg-3">
                        <label class="switch">
                          <input type="checkbox" v-model="searchForm.garden_terrace">
                          <span class="slider round"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="searchForm.ad_type" class="row m-0 p-0">
                  <div class="col-12 p-0 mt-1 mb-1">
                    <div class="row m-0 p-0">
                      <!-- Rear Garden -->
                      <div class="col-3 col-sm-4 col-md-4 col-lg-3">
                        <p class="options-paragraph m-0 p-0 me-2 d-inline">Rear Garden</p>
                        <font-awesome-icon icon="tree" />
                      </div>
                      <div class="col-3 col-sm-2 col-md-2 col-lg-3">
                        <label class="switch">
                          <input type="checkbox" v-model="searchForm.rear_garden">
                          <span class="slider round"></span>
                        </label>
                      </div>
                      <!-- Conservatory -->
                      <div class="col-3 col-sm-4 col-md-4 col-lg-3">
                        <p class="options-paragraph m-0 p-0 me-2 d-inline">Conservatory</p>
                        <font-awesome-icon icon="store-alt" />
                      </div>
                      <div class="col-3 col-sm-2 col-md-2 col-lg-3">
                        <label class="switch">
                          <input type="checkbox" v-model="searchForm.conservatory">
                          <span class="slider round"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="searchForm.ad_type" class="row m-0 p-0">
                  <div class="col-12 p-0 mt-1 mb-1">
                    <div class="row m-0 p-0">
                      <!-- Green House -->
                      <div class="col-3 col-sm-4 col-md-4 col-lg-3">
                        <p class="options-paragraph m-0 p-0 me-2 d-inline">Green House</p>
                        <font-awesome-icon icon="border-none" />
                      </div>
                      <div class="col-3 col-sm-2 col-md-2 col-lg-3">
                        <label class="switch">
                          <input type="checkbox" v-model="searchForm.green_house">
                          <span class="slider round"></span>
                        </label>
                      </div>
                      <!-- Shed -->
                      <div class="col-3 col-sm-4 col-md-4 col-lg-3">
                        <p class="options-paragraph m-0 p-0 me-2 d-inline">Shed</p>
                        <font-awesome-icon icon="box-open" />
                      </div>
                      <div class="col-3 col-sm-2 col-md-2 col-lg-3">
                        <label class="switch">
                          <input type="checkbox" v-model="searchForm.shed">
                          <span class="slider round"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Start Horizontal Line with Icon -->
                <div v-if="searchForm.ad_type" class="horizontal-separator-box">
                  <span class="hr-line-short"></span>
                  <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                  <span class="hr-line-short"></span>
                </div>
                <!-- End Horizontal Line with Icon -->

                <div v-if="searchForm.ad_type" class="row m-0 p-0">
                  <div class="col-12 p-0 mt-1 mb-1">
                    <div class="row m-0 p-0">
                      <!-- Front Porch -->
                      <div class="col-3 col-sm-4 col-md-4 col-lg-3">
                        <p class="options-paragraph m-0 p-0 me-2 d-inline">Front Porch</p>
                        <font-awesome-icon icon="door-closed" class="icon-fa" />
                        <font-awesome-icon icon="level-down-alt" class="icon-fa" />
                      </div>
                      <div class="col-3 col-sm-2 col-md-2 col-lg-3">
                        <label class="switch">
                          <input type="checkbox" v-model="searchForm.front_porch">
                          <span class="slider round"></span>
                        </label>
                      </div>
                      <!-- Attic / Loft -->
                      <div class="col-3 col-sm-4 col-md-4 col-lg-3">
                          <p class="options-paragraph m-0 p-0 me-2 d-inline">Attic/Loft</p>
                          <font-awesome-icon icon="caret-square-up" class="icon-fa" />
                      </div>
                      <div class="col-3 col-sm-2 col-md-2 col-lg-3">
                        <label class="switch">
                          <input type="checkbox" v-model="searchForm.attic">
                          <span class="slider round"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="searchForm.ad_type" class="row m-0 p-0">
                  <div class="col-12 p-0 mt-1 mb-1">
                    <div class="row m-0 p-0">
                      <!-- Rear Porch -->
                      <div class="col-3 col-sm-4 col-md-4 col-lg-3">
                        <p class="options-paragraph m-0 p-0 me-2 d-inline">Rear Porch</p>
                        <font-awesome-icon icon="door-closed" class="icon-fa" />
                        <font-awesome-icon icon="level-up-alt" class="icon-fa" />
                      </div>
                      <div class="col-3 col-sm-2 col-md-2 col-lg-3">
                        <label class="switch">
                          <input type="checkbox" v-model="searchForm.rear_porch">
                          <span class="slider round"></span>
                        </label>
                      </div>
                      <!-- Basement -->
                      <div class="col-3 col-sm-4 col-md-4 col-lg-3">
                        <p class="options-paragraph m-0 p-0 me-2 d-inline">Basement</p>
                        <font-awesome-icon icon="caret-square-down" class="icon-fa" />
                      </div>
                      <div class="col-3 col-sm-2 col-md-2 col-lg-3">
                        <label class="switch">
                          <input type="checkbox" v-model="searchForm.basement">
                          <span class="slider round"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="searchForm.ad_type" class="row m-0 p-0">
                  <div class="col-12 p-0 mt-1 mb-1">
                    <div class="row m-0 p-0">
                      <!-- Charging Station -->
                      <div class="col-3 col-sm-4 col-md-4 col-lg-3">
                        <p class="options-paragraph m-0 p-0 me-2 d-inline">Charging Station</p>
                        <font-awesome-icon icon="charging-station" class="icon-fa" />
                      </div>
                      <div class="col-3 col-sm-2 col-md-2 col-lg-3">
                        <label class="switch">
                          <input type="checkbox" v-model="searchForm.charging_station">
                          <span class="slider round"></span>
                        </label>
                      </div>
                      <!-- Solar Panels -->
                      <div class="col-3 col-sm-4 col-md-4 col-lg-3">
                        <p class="options-paragraph m-0 p-0 me-2 d-inline">Solar Panels</p>
                        <font-awesome-icon icon="solar-panel" class="icon-fa" />
                      </div>
                      <div class="col-3 col-sm-2 col-md-2 col-lg-3">
                        <label class="switch">
                          <input type="checkbox" v-model="searchForm.solar_panels">
                          <span class="slider round"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="searchForm.ad_type" class="row m-0 p-0">
                  <div class="col-12 p-0 mt-1 mb-1">
                    <div class="row m-0 p-0">
                      <!-- Balcony -->
                      <div class="col-3 col-sm-4 col-md-4 col-lg-3">
                        <p class="options-paragraph m-0 p-0 me-2 d-inline">Balcony</p>
                        <font-awesome-icon icon="chess-rook" class="icon-fa" />
                      </div>
                      <div class="col-3 col-sm-2 col-md-2 col-lg-3">
                        <label class="switch">
                          <input type="checkbox" v-model="searchForm.balcony">
                          <span class="slider round"></span>
                        </label>
                      </div>
                      <!-- Floors -->
                      <div class="col-3 col-sm-4 col-md-4 col-lg-3">
                          <p class="options-paragraph m-0 p-0 me-2 d-inline">Floors</p>
                          <font-awesome-icon icon="layer-group" class="icon-fa" />
                      </div>
                      <div class="col-3 col-sm-2 col-md-2 col-lg-3">
                        <!--                            @focus="handleFocus($event)"-->
                        <!--                            @focusout="handleFocusOut(searchForm.floors, $event)"-->
                        <select
                            v-model="searchForm.floors"
                            class="select-placeholder filter-select cursor-pointer"
                            tabindex="-1"
                            aria-label="floors"
                            name="floors"
                            id="floors"
                        >
                          <option value="" disabled>...</option>
                          <option :value="1">1</option>
                          <option :value="2">2</option>
                          <option :value="3">3</option>
                          <option :value="4">4</option>
                          <option :value="5">5+</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="searchForm.ad_type" class="row m-0 p-0">
                  <div class="col-12 p-0 mt-1 mb-1">
                    <div class="row m-0 p-0">
                      <!-- Driveway (Sale, Rent, Buy) & Parking (Let short-term or long-term ROOM) -->
                      <div class="col-3 col-sm-4 col-md-4 col-lg-3">
                        <p class="options-paragraph m-0 p-0 me-2 d-inline">Parking/Driveway</p>
                        <font-awesome-icon icon="car" />
                      </div>
                      <div class="col-3 col-sm-2 col-md-2 col-lg-3">
                        <label class="switch">
                          <input type="checkbox" v-model="searchForm.driveway">
                          <span class="slider round"></span>
                        </label>
                      </div>
                      <!-- Garage -->
                      <div class="col-3 col-sm-4 col-md-4 col-lg-3">
                        <p class="options-paragraph m-0 p-0 me-2 d-inline">Garage</p>
                        <font-awesome-icon icon="warehouse" />
                      </div>
                      <div class="col-3 col-sm-2 col-md-2 col-lg-3">
                        <label class="switch">
                          <input type="checkbox" v-model="searchForm.garage">
                          <span class="slider round"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- End Options input fields -->

                <!-- Start Horizontal Line with Icon -->
                <div v-if="searchForm.ad_type" class="horizontal-separator-box">
                  <span class="hr-line-short"></span>
                  <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                  <span class="hr-line-short"></span>
                </div>
                <!-- End Horizontal Line with Icon -->

                <!-- First row of 2 switches -->
                <div v-if="searchForm.ad_type" class="row m-0 p-0">
                  <div class="col-12 p-0 mt-1 mb-1">
                    <div class="row m-0 p-0">
                      <!-- Accessible -->
                      <div class="col-6 p-0">
                        <div class="row m-0 p-0">
                          <div class="col-6 col-sm-8 col-md-8 col-lg-6">
                            <p class="options-paragraph m-0 p-0 me-2 d-inline">Accessible</p>
                            <font-awesome-icon icon="wheelchair" class="icon-fa" />
                          </div>
                          <div class="col-6 col-sm-4 col-md-4 col-lg-6">
                            <label class="switch">
                              <input type="checkbox" v-model="searchForm.is_accessible">
                              <span class="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <!-- Shared Ownership -->
                      <div class="col-6 p-0">
                        <div class="row m-0 p-0">
                          <div class="col-6 col-sm-8 col-md-8 col-lg-6">
                            <p class="options-paragraph m-0 p-0 me-2 d-inline">Shared Ownership</p>
                            <font-awesome-icon icon="handshake" class="icon-fa" />
                          </div>
                          <div class="col-6 col-sm-4 col-md-4 col-lg-6">
                            <label class="switch">
                              <input type="checkbox" v-model="searchForm.is_shared_ownership">
                              <span class="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Second row of 2 switches -->
                <div v-if="searchForm.ad_type" class="row m-0 p-0">
                  <div class="col-12 p-0 mt-1 mb-1">
                    <div class="row m-0 p-0">
                      <!-- Over 60's -->
                      <div class="col-6 p-0">
                        <div class="row m-0 p-0">
                          <div class="col-6 col-sm-8 col-md-8 col-lg-6">
                            <p class="options-paragraph m-0 p-0 me-2 d-inline">Over 60's</p>
                            <font-awesome-icon icon="hand-holding-heart" class="icon-fa" />
                          </div>
                          <div class="col-6 col-sm-4 col-md-4 col-lg-6">
                            <label class="switch">
                              <input type="checkbox" v-model="searchForm.is_over_60s">
                              <span class="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <!-- HMO -->
                      <div class="col-6 p-0">
                        <div class="row m-0 p-0">
                          <div class="col-6 col-sm-8 col-md-8 col-lg-6">
                            <p class="options-paragraph m-0 p-0 me-2 d-inline">HMO</p>
                            <font-awesome-icon icon="users" class="icon-fa" />
                          </div>
                          <div class="col-6 col-sm-4 col-md-4 col-lg-6">
                            <label class="switch">
                              <input type="checkbox" v-model="searchForm.is_hmo">
                              <span class="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Third row of 2 switches -->
                <div v-if="searchForm.ad_type" class="row m-0 p-0">
                  <div class="col-12 p-0 mt-1 mb-1">
                    <div class="row m-0 p-0">
                      <!-- Furnished -->
                      <div class="col-6 p-0">
                        <div class="row m-0 p-0">
                          <div class="col-6 col-sm-8 col-md-8 col-lg-6">
                            <p class="options-paragraph m-0 p-0 me-2 d-inline">Furnished</p>
                            <font-awesome-icon icon="chair" class="icon-fa" />
                          </div>
                          <div class="col-6 col-sm-4 col-md-4 col-lg-6">
                            <label class="switch">
                              <input type="checkbox" v-model="searchForm.is_furnished">
                              <span class="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <!-- Tenanted -->
                      <div class="col-6 p-0">
                        <div class="row m-0 p-0">
                          <div class="col-6 col-sm-8 col-md-8 col-lg-6">
                            <p class="options-paragraph m-0 p-0 me-2 d-inline">Tenanted</p>
                            <font-awesome-icon icon="house-user" class="icon-fa" />
                          </div>
                          <div class="col-6 col-sm-4 col-md-4 col-lg-6">
                            <label class="switch">
                              <input type="checkbox" v-model="searchForm.is_tenanted">
                              <span class="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Fourth row of 2 switches -->
                <div v-if="searchForm.ad_type" class="row m-0 p-0">
                  <div class="col-12 p-0 mt-1 mb-1">
                    <div class="row m-0 p-0">
                      <!-- Pet Friendly -->
                      <div class="col-6 p-0">
                        <div class="row m-0 p-0">
                          <div class="col-6 col-sm-8 col-md-8 col-lg-6">
                            <p class="options-paragraph m-0 p-0 me-2 d-inline">Pet Friendly</p>
                            <font-awesome-icon icon="paw" class="icon-fa" />
                          </div>
                          <div class="col-6 col-sm-4 col-md-4 col-lg-6">
                            <label class="switch">
                              <input type="checkbox" v-model="searchForm.is_pet_friendly">
                              <span class="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <!-- DSS Accepted -->
                      <div class="col-6 p-0">
                        <div class="row m-0 p-0">
                          <div class="col-6 col-sm-8 col-md-8 col-lg-6">
                            <p class="options-paragraph m-0 p-0 me-2 d-inline">DSS Accepted</p>
                            <font-awesome-icon icon="user-shield" class="icon-fa" />
                          </div>
                          <div class="col-6 col-sm-4 col-md-4 col-lg-6">
                            <label class="switch">
                              <input type="checkbox" v-model="searchForm.is_dss_accepted">
                              <span class="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Fifth row of 2 switches -->
                <div v-if="searchForm.ad_type" class="row m-0 p-0">
                  <div class="col-12 p-0 mt-1 mb-1">
                    <div class="row m-0 p-0">
                      <!-- Easements and Covenants -->
                      <div class="col-6 p-0">
                        <div class="row m-0 p-0">
                          <div class="col-6 col-sm-8 col-md-8 col-lg-6">
                            <p class="options-paragraph m-0 p-0 me-2 d-inline">Easements & Covenants</p>
                            <font-awesome-icon icon="exclamation-circle" class="icon-fa" />
                          </div>
                          <div class="col-6 col-sm-4 col-md-4 col-lg-6">
                            <label class="switch">
                              <input type="checkbox" v-model="searchForm.easements">
                              <span class="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <!-- Holiday -->
                      <div class="col-6 p-0">
                        <div class="row m-0 p-0">
                          <div class="col-6 col-sm-8 col-md-8 col-lg-6">
                            <p class="options-paragraph m-0 p-0 me-2 d-inline">Holiday</p>
                            <font-awesome-icon icon="plane" class="icon-fa" />
                          </div>
                          <div class="col-6 col-sm-4 col-md-4 col-lg-6">
                            <label class="switch">
                              <input type="checkbox" v-model="searchForm.is_holiday">
                              <span class="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Sixth row of 2 switches -->
                <div v-if="searchForm.ad_type" class="row m-0 p-0">
                  <div class="col-12 p-0 mt-1 mb-1">
                    <div class="row m-0 p-0">
                      <!-- Seashore -->
                      <div class="col-6 p-0">
                        <div class="row m-0 p-0">
                          <div class="col-6 col-sm-8 col-md-8 col-lg-6">
                            <p class="options-paragraph m-0 p-0 me-2 d-inline">Seashore</p>
                            <font-awesome-icon icon="water" class="icon-fa" />
                          </div>
                          <div class="col-6 col-sm-4 col-md-4 col-lg-6">
                            <label class="switch">
                              <input type="checkbox" v-model="searchForm.is_seashore">
                              <span class="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <!-- Waterfront -->
                      <div class="col-6 p-0">
                        <div class="row m-0 p-0">
                          <div class="col-6 col-sm-8 col-md-8 col-lg-6">
                            <p class="options-paragraph m-0 p-0 me-2 d-inline">Waterfront</p>
                            <font-awesome-icon icon="home" class="icon-fa" />
                            <font-awesome-icon icon="water" class="icon-fa" />
                          </div>
                          <div class="col-6 col-sm-4 col-md-4 col-lg-6">
                            <label class="switch">
                              <input type="checkbox" v-model="searchForm.is_waterfront">
                              <span class="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Sixth row of 2 switches -->
                <div v-if="searchForm.ad_type && (searchForm.ad_type === 5 || searchForm.ad_type === 6)" class="row m-0 p-0">
                  <div class="col-12 p-0 mt-1 mb-1">
                    <div class="row m-0 p-0">
                      <!-- Events Allowed -->
                      <div class="col-6 p-0">
                        <div class="row m-0 p-0">
                          <div class="col-6 col-sm-8 col-md-8 col-lg-6">
                            <p class="options-paragraph m-0 p-0 me-2 d-inline">Events Allowed</p>
                            <font-awesome-icon icon="music" class="icon-fa" />
                          </div>
                          <div class="col-6 col-sm-4 col-md-4 col-lg-6">
                            <label class="switch">
                              <input type="checkbox" v-model="searchForm.events_allowed">
                              <span class="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Start Horizontal Line with Icon -->
                <div v-if="searchForm.ad_type" class="horizontal-separator-box">
                  <span class="hr-line-short"></span>
                  <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                  <span class="hr-line-short"></span>
                </div>
                <!-- End Horizontal Line with Icon -->

                <!-- First row of 2 switches - EXTRAS -->
                <div v-if="searchForm.ad_type" class="row m-0 p-0">
                  <div class="col-12 p-0 mt-1 mb-1">
                    <div class="row m-0 p-0">
                      <!-- Inside Swimming Pool -->
                      <div class="col-6 p-0">
                        <div class="row m-0 p-0">
                          <div class="col-6 col-sm-8 col-md-8 col-lg-6">
                              <p class="options-paragraph m-0 p-0 me-2 d-inline">Inside Pool</p>
                              <font-awesome-icon icon="swimmer" class="icon-fa" />
                          </div>
                          <div class="col-6 col-sm-4 col-md-4 col-lg-6">
                            <label class="switch">
                              <input type="checkbox" v-model="searchForm.inside_pool">
                              <span class="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <!-- Cinema Room -->
                      <div class="col-6 p-0">
                        <div class="row m-0 p-0">
                          <div class="col-6 col-sm-8 col-md-8 col-lg-6">
                            <p class="options-paragraph m-0 p-0 me-2 d-inline">Cinema Room</p>
                            <font-awesome-icon icon="film" class="icon-fa" />
                          </div>
                          <div class="col-6 col-sm-4 col-md-4 col-lg-6">
                            <label class="switch">
                              <input type="checkbox" v-model="searchForm.cinema_room">
                              <span class="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Second row of 2 switches - EXTRAS -->
                <div v-if="searchForm.ad_type" class="row m-0 p-0">
                  <div class="col-12 p-0 mt-1 mb-1">
                    <div class="row m-0 p-0">
                      <!-- Outside Swimming Pool -->
                      <div class="col-6 p-0">
                        <div class="row m-0 p-0">
                          <div class="col-6 col-sm-8 col-md-8 col-lg-6">
                            <p class="options-paragraph m-0 p-0 me-2 d-inline">Outside Pool</p>
                            <font-awesome-icon icon="swimming-pool" class="icon-fa" />
                          </div>
                          <div class="col-6 col-sm-4 col-md-4 col-lg-6">
                            <label class="switch">
                              <input type="checkbox" v-model="searchForm.outside_pool">
                              <span class="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <!-- Gym -->
                      <div class="col-6 p-0">
                        <div class="row m-0 p-0">
                          <div class="col-6 col-sm-8 col-md-8 col-lg-6">
                            <p class="options-paragraph m-0 p-0 me-2 d-inline">Gym</p>
                            <font-awesome-icon icon="dumbbell" class="icon-fa" />
                          </div>
                          <div class="col-6 col-sm-4 col-md-4 col-lg-6">
                            <label class="switch">
                              <input type="checkbox" v-model="searchForm.gym">
                              <span class="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Third row of 2 switches - EXTRAS -->
                <div v-if="searchForm.ad_type" class="row m-0 p-0">
                  <div class="col-12 p-0 mt-1 mb-1">
                    <div class="row m-0 p-0">
                      <!-- Sauna -->
                      <div class="col-6 p-0">
                        <div class="row m-0 p-0">
                          <div class="col-6 col-sm-8 col-md-8 col-lg-6">
                            <p class="options-paragraph m-0 p-0 me-2 d-inline">Sauna</p>
                            <font-awesome-icon icon="thermometer-full" class="icon-fa" />
                          </div>
                          <div class="col-6 col-sm-4 col-md-4 col-lg-6">
                            <label class="switch">
                              <input type="checkbox" v-model="searchForm.sauna">
                              <span class="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <!-- Stove -->
                      <div class="col-6 p-0">
                        <div class="row m-0 p-0">
                          <div class="col-6 col-sm-8 col-md-8 col-lg-6">
                            <p class="options-paragraph m-0 p-0 me-2 d-inline">Stove</p>
                            <font-awesome-icon icon="fire" class="icon-fa" />
                          </div>
                          <div class="col-6 col-sm-4 col-md-4 col-lg-6">
                            <label class="switch">
                              <input type="checkbox" v-model="searchForm.stove">
                              <span class="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Fourth row of 2 switches - EXTRAS -->
                <div v-if="searchForm.ad_type" class="row m-0 p-0">
                  <div class="col-12 p-0 mt-1 mb-1">
                    <div class="row m-0 p-0">
                      <!-- Hot Tub -->
                      <div class="col-6 p-0">
                        <div class="row m-0 p-0">
                          <div class="col-6 col-sm-8 col-md-8 col-lg-6">
                            <p class="options-paragraph m-0 p-0 me-2 d-inline">Hot Tub</p>
                            <font-awesome-icon icon="hot-tub" class="icon-fa" />
                          </div>
                          <div class="col-6 col-sm-4 col-md-4 col-lg-6">
                            <label class="switch">
                              <input type="checkbox" v-model="searchForm.hot_tub">
                              <span class="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <!-- Spa -->
                      <div class="col-6 p-0">
                        <div class="row m-0 p-0">
                          <div class="col-6 col-sm-8 col-md-8 col-lg-6">
                            <p class="options-paragraph m-0 p-0 me-2 d-inline">Spa</p>
                            <font-awesome-icon icon="spa" class="icon-fa" />
                          </div>
                          <div class="col-6 col-sm-4 col-md-4 col-lg-6">
                            <label class="switch">
                              <input type="checkbox" v-model="searchForm.spa">
                              <span class="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Fifth row of 2 switches - EXTRAS -->
                <div v-if="searchForm.ad_type" class="row m-0 p-0">
                  <div class="col-12 p-0 mt-1 mb-1">
                    <div class="row m-0 p-0">
                      <!-- Bicycle -->
                      <div class="col-6 p-0">
                        <div class="row m-0 p-0">
                          <div class="col-6 col-sm-8 col-md-8 col-lg-6">
                            <p class="options-paragraph m-0 p-0 me-2 d-inline">Bicycle</p>
                            <font-awesome-icon icon="bicycle" class="icon-fa" />
                          </div>
                          <div class="col-6 col-sm-4 col-md-4 col-lg-6">
                            <label class="switch">
                              <input type="checkbox" v-model="searchForm.bicycle">
                              <span class="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <!-- Air Conditioning -->
                      <div class="col-6 p-0">
                        <div class="row m-0 p-0">
                          <div class="col-6 col-sm-8 col-md-8 col-lg-6">
                            <p class="options-paragraph m-0 p-0 me-2 d-inline">Air Conditioning</p>
                            <font-awesome-icon icon="temperature-low" class="icon-fa" />
                          </div>
                          <div class="col-6 col-sm-4 col-md-4 col-lg-6">
                            <label class="switch">
                              <input type="checkbox" v-model="searchForm.air_conditioning">
                              <span class="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Sixth row of 2 switches - EXTRAS -->
                <div v-if="searchForm.ad_type" class="row m-0 p-0">
                  <div class="col-12 p-0 mt-1 mb-1">
                    <div class="row m-0 p-0">
                      <!-- Bar -->
                      <div class="col-6 p-0">
                        <div class="row m-0 p-0">
                          <div class="col-6 col-sm-8 col-md-8 col-lg-6">
                            <p class="options-paragraph m-0 p-0 me-2 d-inline">Bar</p>
                            <font-awesome-icon icon="cocktail" class="icon-fa" />
                          </div>
                          <div class="col-6 col-sm-4 col-md-4 col-lg-6">
                            <label class="switch">
                              <input type="checkbox" v-model="searchForm.bar">
                              <span class="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <!-- Security Cameras -->
                      <div class="col-6 p-0">
                        <div class="row m-0 p-0">
                          <div class="col-6 col-sm-8 col-md-8 col-lg-6">
                            <p class="options-paragraph m-0 p-0 me-2 d-inline">Security Cameras</p>
                            <font-awesome-icon icon="eye" class="icon-fa" />
                          </div>
                          <div class="col-6 col-sm-4 col-md-4 col-lg-6">
                            <label class="switch">
                              <input type="checkbox" v-model="searchForm.security_cameras">
                              <span class="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Seventh row of 2 switches - EXTRAS -->
                <div v-if="searchForm.ad_type" class="row m-0 p-0">
                  <div class="col-12 p-0 mt-1 mb-1">
                    <div class="row m-0 p-0">
                      <!-- Fire Rings -->
                      <div class="col-6 p-0">
                        <div class="row m-0 p-0">
                          <div class="col-6 col-sm-8 col-md-8 col-lg-6">
                            <p class="options-paragraph m-0 p-0 me-2 d-inline">Fire Rings</p>
                            <font-awesome-icon icon="fire" class="icon-fa" />
                          </div>
                          <div class="col-6 col-sm-4 col-md-4 col-lg-6">
                            <label class="switch">
                              <input type="checkbox" v-model="searchForm.fire_rings">
                              <span class="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <!-- Hammocks -->
                      <div class="col-6 p-0">
                        <div class="row m-0 p-0">
                          <div class="col-6 col-sm-8 col-md-8 col-lg-6">
                            <p class="options-paragraph m-0 p-0 me-2 d-inline">Hammocks</p>
                            <font-awesome-icon icon="feather" class="icon-fa" />
                          </div>
                          <div class="col-6 col-sm-4 col-md-4 col-lg-6">
                            <label class="switch">
                              <input type="checkbox" v-model="searchForm.hammocks">
                              <span class="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="text-center" v-if="searchForm.ad_type && (searchForm.ad_type === 5 || searchForm.ad_type === 6) && (searchForm.is_long_short_term == 2 || searchForm.is_long_short_term == 3)">
                  <!-- Start Horizontal Line with Icon -->
                  <div v-if="searchForm.ad_type" class="horizontal-separator-box">
                    <span class="hr-line-short"></span>
                    <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                    <span class="hr-line-short"></span>
                  </div>
                  <!-- End Horizontal Line with Icon -->

                  <small class="p-3 fw-bold">Additional for short-term stays</small>

                  <!-- Start Horizontal Line with Icon -->
                  <div v-if="searchForm.ad_type" class="horizontal-separator-box">
                    <span class="hr-line-short"></span>
                    <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                    <span class="hr-line-short"></span>
                  </div>
                  <!-- End Horizontal Line with Icon -->
                </div>

                <!-- Row of 2 switches -->
                <div v-if="searchForm.ad_type && (searchForm.ad_type === 5 || searchForm.ad_type === 6) && (searchForm.is_long_short_term == 2 || searchForm.is_long_short_term == 3)" class="row m-0 p-0">
                  <div class="col-12 p-0 mt-1 mb-1">
                    <div class="row m-0 p-0">
                      <!-- Wifi -->
                      <div class="col-6 p-0">
                        <div class="row m-0 p-0">
                          <div class="col-6 col-sm-8 col-md-8 col-lg-6">
                            <p class="options-paragraph m-0 p-0 me-2 d-inline">Wi-Fi</p>
                            <font-awesome-icon icon="wifi" class="icon-fa" />
                          </div>
                          <div class="col-6 col-sm-4 col-md-4 col-lg-6">
                            <label class="switch">
                              <input type="checkbox" v-model="searchForm.wifi">
                              <span class="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <!-- Breakfast -->
                      <div class="col-6 p-0">
                        <div class="row m-0 p-0">
                          <div class="col-6 col-sm-8 col-md-8 col-lg-6">
                            <p class="options-paragraph m-0 p-0 me-2 d-inline">Breakfast</p>
                            <font-awesome-icon icon="bread-slice" class="icon-fa" />
                          </div>
                          <div class="col-6 col-sm-4 col-md-4 col-lg-6">
                            <label class="switch">
                              <input type="checkbox" v-model="searchForm.breakfast">
                              <span class="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Row of 2 switches -->
                <div v-if="searchForm.ad_type && (searchForm.ad_type === 5 || searchForm.ad_type === 6) && (searchForm.is_long_short_term == 2 || searchForm.is_long_short_term == 3)" class="row m-0 p-0">
                  <div class="col-12 p-0 mt-1 mb-1">
                    <div class="row m-0 p-0">
                      <!-- Smoking Rooms -->
                      <div class="col-6 p-0">
                        <div class="row m-0 p-0">
                          <div class="col-6 col-sm-8 col-md-8 col-lg-6">
                            <p class="options-paragraph m-0 p-0 me-2 d-inline">Smoking Rooms</p>
                            <font-awesome-icon icon="smoking" class="icon-fa" />
                          </div>
                          <div class="col-6 col-sm-4 col-md-4 col-lg-6">
                            <label class="switch">
                              <input type="checkbox" v-model="searchForm.smoking_rooms">
                              <span class="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <!-- Non Smoking Rooms -->
                      <div class="col-6 p-0">
                        <div class="row m-0 p-0">
                          <div class="col-6 col-sm-8 col-md-8 col-lg-6">
                            <p class="options-paragraph m-0 p-0 me-2 d-inline">Non Smoking Rooms</p>
                            <font-awesome-icon icon="smoking-ban" class="icon-fa" />
                          </div>
                          <div class="col-6 col-sm-4 col-md-4 col-lg-6">
                            <label class="switch">
                              <input type="checkbox" v-model="searchForm.non_smoking_rooms">
                              <span class="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Row of 2 switches -->
                <div v-if="searchForm.ad_type && (searchForm.ad_type === 5 || searchForm.ad_type === 6) && (searchForm.is_long_short_term == 2 || searchForm.is_long_short_term == 3)" class="row m-0 p-0">
                  <div class="col-12 p-0 mt-1 mb-1">
                    <div class="row m-0 p-0">
                      <!-- Room Service -->
                      <div class="col-6 p-0">
                        <div class="row m-0 p-0">
                          <div class="col-6 col-sm-8 col-md-8 col-lg-6">
                            <p class="options-paragraph m-0 p-0 me-2 d-inline">Room Service</p>
                            <font-awesome-icon icon="door-open" class="icon-fa" />
                          </div>
                          <div class="col-6 col-sm-4 col-md-4 col-lg-6">
                            <label class="switch">
                              <input type="checkbox" v-model="searchForm.room_service">
                              <span class="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <!-- Cleaning Service -->
                      <div class="col-6 p-0">
                        <div class="row m-0 p-0">
                          <div class="col-6 col-sm-8 col-md-8 col-lg-6">
                            <p class="options-paragraph m-0 p-0 me-2 d-inline">Cleaning Service</p>
                            <font-awesome-icon icon="hands-wash" class="icon-fa" />
                          </div>
                          <div class="col-6 col-sm-4 col-md-4 col-lg-6">
                            <label class="switch">
                              <input type="checkbox" v-model="searchForm.cleaning_service">
                              <span class="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Row of 2 switches -->
                <div v-if="searchForm.ad_type && (searchForm.ad_type === 5 || searchForm.ad_type === 6) && (searchForm.is_long_short_term == 2 || searchForm.is_long_short_term == 3)" class="row m-0 p-0">
                  <div class="col-12 p-0 mt-1 mb-1">
                    <div class="row m-0 p-0">
                      <!-- 24h Front Desk -->
                      <div class="col-6 p-0">
                        <div class="row m-0 p-0">
                          <div class="col-6 col-sm-8 col-md-8 col-lg-6">
                            <p class="options-paragraph m-0 p-0 me-2 d-inline">24h Front Desk</p>
                            <font-awesome-icon icon="concierge-bell" class="icon-fa" />
                          </div>
                          <div class="col-6 col-sm-4 col-md-4 col-lg-6">
                            <label class="switch">
                              <input type="checkbox" v-model="searchForm.h24_front_desk">
                              <span class="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <!-- Airport Shuttle -->
                      <div class="col-6 p-0">
                        <div class="row m-0 p-0">
                          <div class="col-6 col-sm-8 col-md-8 col-lg-6">
                            <p class="options-paragraph m-0 p-0 me-2 d-inline">Airport Shuttle</p>
                            <font-awesome-icon icon="plane-arrival" class="icon-fa" />
                          </div>
                          <div class="col-6 col-sm-4 col-md-4 col-lg-6">
                            <label class="switch">
                              <input type="checkbox" v-model="searchForm.airport_shuttle">
                              <span class="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Row of 2 switches -->
                <div v-if="searchForm.ad_type && (searchForm.ad_type === 5 || searchForm.ad_type === 6) && (searchForm.is_long_short_term == 2 || searchForm.is_long_short_term == 3)" class="row m-0 p-0">
                  <div class="col-12 p-0 mt-1 mb-1">
                    <div class="row m-0 p-0">
                      <!-- Restaurant -->
                      <div class="col-6 p-0">
                        <div class="row m-0 p-0">
                          <div class="col-6 col-sm-8 col-md-8 col-lg-6">
                            <p class="options-paragraph m-0 p-0 me-2 d-inline">Restaurant</p>
                            <font-awesome-icon icon="utensils" class="icon-fa" />
                          </div>
                          <div class="col-6 col-sm-4 col-md-4 col-lg-6">
                            <label class="switch">
                              <input type="checkbox" v-model="searchForm.restaurant">
                              <span class="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </form>
            </div>
          </div>
        </transition>
      </div>
    </transition>
    <div id="searchButton">
      <span data-tooltip="tooltip">
        <div class="no-text-wrap overflow-hidden text-truncate">
          <button id="search-component" :class="['btn btn-outline-primary btn-vuenav btn-round z-index-special ', {active: activeSearch}]" v-on:click="activeSearch = !activeSearch"><font-awesome-icon icon="search" class="ms-0" /></button>
          <span class="nav-btn-search-tooltiptext">Search Filters</span>
        </div>
      </span>
<!--      <button id="search-component" :class="['btn btn-outline-primary btn-vuenav btn-round z-index-special ', {active: activeSearch}]" v-on:click="activeSearch = !activeSearch"><font-awesome-icon icon="search" class="ms-0" /></button>-->
    </div>
<!--    <div id="searchButton">-->
<!--      <button id="search-component" :class="['btn btn-outline-primary btn-round z-index-special ', {active: activeSearch}]" v-on:click="activeSearch = !activeSearch"><font-awesome-icon icon="search" class="ms-0" /></button>-->
<!--    </div>-->
  </div>
</template>

<script>
import axios from "axios";
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import PreviewAdDetails from "./PreviewAdDetails";
import {mapActions} from "vuex";

export default {
  name:"SearchFilters",
  components: {PreviewAdDetails},
  data(){
    return {
      user: this.$store.state.auth.user,
      activeSearch: false,
      activeFilters: false,
      datePresent: false,
      propertyType: [],
      searchForm: {
        /* Ad Type */
        ad_type: '',
        is_sale: 0,
        is_rent: 0,
        is_buy: 0,
        is_let: 0,
        /* Sort By */
        newest: false,
        oldest: false,
        highest_price: false,
        lowest_price: false,
        /* Address and radius */
        address: '',
        line_1: '',
        line_2: '',
        street: '',
        postcode: '',
        city: '',
        county: '',
        boundary_box: '',
        longitude: '',
        latitude: '',
        radius: '',
        /* Beds */
        min_beds: '',
        max_beds: '',
        beds: 'Beds',
        /* Price */
        min_price: '',
        max_price: '',
        price: 'Price',
        property_rent_type_id: '',
        /* Start Property Types */
        checked: false,
        /* Residential Houses */
        houses: false,
        detached_house: false,
        semi_detached_house: false,
        mid_terraced: false,
        end_terraced: false,
        detached_bungalow: false,
        semi_detached_bungalow: false,
        cottage: false,
        /* Residential Flats */
        flats: false,
        flat: false,
        studio: false,
        maisonette: false,
        /* Commercial Offices */
        offices: false,
        traditional_office: false,
        serviced_office: false,
        managed_office: false,
        /* Commercial Retail */
        retail: false,
        shop: false,
        shopping_mall: false,
        retail_store: false,
        /* Commercial Leisure */
        leisure: false,
        restaurant_cafe: false,
        takeaway: false,
        pub: false,
        entertainment: false,
        sport_healthcare_facility: false,
        hotel_tourism: false,
        /* Commercial Healthcare */
        healthcare: false,
        pharmacy: false,
        medical_center: false,
        nursing_home: false,
        hospital: false,
        /* Commercial Garage/Parking */
        garage_parking: false,
        parking_space: false,
        single_garage: false,
        multiple_garages: false,
        parking_lot: false,
        multi_storey_parking: false,
        /* Commercial Multifamily */
        multifamily: false,
        duplex: false,
        block_of_flats: false,
        apartment_complex: false,
        /* Industrial Warehouse */
        warehouses: false,
        /* Industrial Factory */
        factories: false,
        /* Commercial Powerplant */
        power_plants: false,
        hydroelectric_plant: false,
        coal_fired_plant: false,
        diesel_fired_plant: false,
        geothermal_plant: false,
        gas_fired_plant: false,
        solar_power_plant: false,
        wind_power_plant: false,
        tidal_power_plant: false,
        /* Land */
        land: false,
        greenfield: false,
        brownfield: false,
        /* Luxury */
        luxury: false,
        mansion: false,
        villa: false,
        /* Special Use */
        special_use: false,
        church: false,
        cemetery: false,
        park: false,
        school: false,
        barn: false,
        campsite: false,
        /* Rooms */
        rooms: false,
        single_room: false,
        double_room: false,
        twin_room: false,
        twin_double_room: false,
        triple_room: false,
        quadruple_room: false,
        family_room: false,
        /* End Property Types */
        /* Start Date Range */
        date_range: {
          start: null,
          end: null
        },
        date_start: null,
        date_end: null,
        /* End Date Range */
        /* Start Filters */
        is_coming_soon: false,
        guests: '',
        is_long_short_term: 3,
        tenure: '',
        min_square_meter: '',
        max_square_meter: '',
        bathrooms: '',
        is_downstairs_wc: false,
        is_cash: 3,
        is_auction: 3,
        // no_auction: false,
        is_investment: 3,
        is_mixed_use: 3,
        front_garden: false,
        rear_garden: false,
        green_house: false,
        garden_terrace: false,
        conservatory: false,
        shed: false,
        front_porch: false,
        rear_porch: false,
        balcony: false,
        attic: false,
        basement: false,
        charging_station: false,
        solar_panels: false,
        floors: '',
        driveway: false, // driveway and parking in one filter
        garage: false,
        /* Extra Options */
        is_accessible: false,
        is_shared_ownership: false,
        is_over_60s: false,
        is_hmo: false,
        is_furnished: false,
        is_tenanted: false,
        is_pet_friendly: false,
        events_allowed: false,
        is_dss_accepted: false,
        easements: false,
        is_holiday: false,
        is_seashore: false,
        is_waterfront: false,
        /* Additionals */
        inside_pool: false,
        outside_pool: false,
        cinema_room: false,
        gym: false,
        sauna: false,
        spa: false,
        stove: false,
        hot_tub: false,
        bicycle: false,
        wifi: false, // Let only
        cleaning_service: false, // Let only
        room_service: false, // Let only
        restaurant: false, // Let only
        bar: false,
        h24_front_desk: false, // Let only
        smoking_rooms: false, // Let only
        non_smoking_rooms: false, // Let only
        air_conditioning: false,
        airport_shuttle: false, // Let only
        security_cameras: false,
        fire_rings: false, // Let only
        hammocks: false,
        breakfast: false , // Let only
        /* End Filters */
        drawbboxs: ''
      },
      properties: null,
    }
  },
  async mounted() {
    let that = this;
    // On mobile we want search filters to be closed
    let checkIfMobile = setInterval(function () {
        if (!that.isMobile()) {
          // this.activeSearch = !this.activeSearch;
          that.activeSearch = true;
        } else {
          that.closeSearchBox();
        }
        clearInterval(checkIfMobile);
    }, 600);

    this.$root.$on('setFilters', (searchFilters) => {
      this.setSearchFilters(searchFilters);
    });

    if(this.$store.state.searchFilters.searchFilters !== false) {
      this.searchForm = {...this.$store.state.searchFilters.searchFilters};
    }
    await this.initGeocodeBox();

    this.setSearchFilters(this.searchForm);
  },
  beforeDestroy() {
    // Must destroy global trigger before we leave the page/component, otherwise it will layer on each other
    // and when we call it, it will trigger several times, breaking functionality
    this.$root.$off('setFilters');
  },
  computed:{
    attr() {
      return [
        {
          highlight:'red',
          dates: this.disabledDates,
        },

        {
          key: 'today',
          highlight: { color: 'green', fillMode: 'outline'},
          dates: new Date(),
        }
      ];

    },
  },
  methods:{
    handleFocus(event) {
      if(!event.target.classList.contains('select-clicked')) {
        event.target.classList.toggle('select-clicked');
      }
    },
    handleFocusOut(value, event) {
      if(value === null || value === '') {
        if(event.target.classList.contains('select-clicked')) {
          event.target.classList.toggle('select-clicked');
        }
      }
    },
    convertNumberToCommas(num) {
      if (num && num !== '' && num !== null) {
        return num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return 0;
      }
    },
    clearDates() {
      this.searchForm.date_range = null;
      this.searchForm.date_start = null;
      this.searchForm.date_end = null;
    },
    ...mapActions({
      setSearchFilters:'searchFilters/setSearchFilters'
    }),
    searchProps() {
      // Create a media condition that targets viewports of max-width 767px
      const mediaQueryMax767 = window.matchMedia('(max-width: 767px)')
      // Check if the media query is true
      if (mediaQueryMax767.matches) {
        this.closeSearchBox();
      } else {
        // close #openFilter window, so it does not cover visibility after search
        this.closeFilters();
      }

      // Emit event from parent (Nav.vue) which will emit event from further parent (App.vue)
      this.setSearchFilters(this.searchForm);
      this.$emit('refreshPropsNav', this.searchForm);
      // Open List.vue to see list of properties
      this.$root.$emit('openListComponent');
    },
    actionPropType(type, event) {
      if (type === 'houses') {
        if (event.target.checked) {
          this.searchForm.detached_house = true;
          this.searchForm.semi_detached_house = true;
          this.searchForm.mid_terraced = true;
          this.searchForm.end_terraced = true;
          this.searchForm.detached_bungalow = true;
          this.searchForm.semi_detached_bungalow = true;
          this.searchForm.cottage = true;
        } else {
          this.searchForm.detached_house = false;
          this.searchForm.semi_detached_house = false;
          this.searchForm.mid_terraced = false;
          this.searchForm.end_terraced = false;
          this.searchForm.detached_bungalow = false;
          this.searchForm.semi_detached_bungalow = false;
          this.searchForm.cottage = false;
          this.searchForm.houses = false;
        }
      }
      if (type === 'flats') {
        if (event.target.checked) {
          this.searchForm.flat = true;
          this.searchForm.studio = true;
          this.searchForm.maisonette = true;
        } else {
          this.searchForm.flat = false;
          this.searchForm.studio = false;
          this.searchForm.maisonette = false;
          this.searchForm.flats = false;
        }
      }
      if (type === 'land') {
        if (event.target.checked) {
          this.searchForm.greenfield = true;
          this.searchForm.brownfield = true;
        } else {
          this.searchForm.greenfield = false;
          this.searchForm.brownfield = false;
          this.searchForm.land = false;
        }
      }
      if (type === 'retail') {
        if (event.target.checked) {
          this.searchForm.shop = true;
          this.searchForm.shopping_mall = true;
          this.searchForm.retail_store = true;
        } else {
          this.searchForm.shop = false;
          this.searchForm.shopping_mall = false;
          this.searchForm.retail_store = false;
          this.searchForm.retail = false;
        }
      }
      if (type === 'leisure') {
        if (event.target.checked) {
          this.searchForm.restaurant_cafe = true;
          this.searchForm.takeaway = true;
          this.searchForm.pub = true;
          this.searchForm.entertainment = true;
          this.searchForm.sport_healthcare_facility = true;
          this.searchForm.hotel_tourism = true;
        } else {
          this.searchForm.restaurant_cafe = false;
          this.searchForm.takeaway = false;
          this.searchForm.pub = false;
          this.searchForm.entertainment = false;
          this.searchForm.sport_healthcare_facility = false;
          this.searchForm.hotel_tourism = false;
          this.searchForm.leisure = false;
        }
      }
      if (type === 'offices') {
        if (event.target.checked) {
          this.searchForm.traditional_office = true;
          this.searchForm.serviced_office = true;
          this.searchForm.managed_office = true;
        } else {
          this.searchForm.traditional_office = false;
          this.searchForm.serviced_office = false;
          this.searchForm.managed_office = false;
          this.searchForm.offices = false;
        }
      }
      if (type === 'healthcare') {
        if (event.target.checked) {
          this.searchForm.pharmacy = true;
          this.searchForm.medical_center = true;
          this.searchForm.nursing_home = true;
          this.searchForm.hospital = true;
        } else {
          this.searchForm.pharmacy = false;
          this.searchForm.medical_center = false;
          this.searchForm.nursing_home = false;
          this.searchForm.hospital = false;
          this.searchForm.healthcare = false;
        }
      }
      if (type === 'garage_parking') {
        if (event.target.checked) {
          this.searchForm.parking_space = true;
          this.searchForm.single_garage = true;
          this.searchForm.multiple_garages = true;
          this.searchForm.parking_lot = true;
          this.searchForm.multi_storey_parking = true;
        } else {
          this.searchForm.parking_space = false;
          this.searchForm.single_garage = false;
          this.searchForm.multiple_garages = false;
          this.searchForm.parking_lot = false;
          this.searchForm.multi_storey_parking = false;
          this.searchForm.garage_parking = false;
        }
      }
      if (type === 'multifamily') {
        if (event.target.checked) {
          this.searchForm.duplex = true;
          this.searchForm.block_of_flats = true;
          this.searchForm.apartment_complex = true;
        } else {
          this.searchForm.duplex = false;
          this.searchForm.block_of_flats = false;
          this.searchForm.apartment_complex = false;
          this.searchForm.multifamily = false;
        }
      }
      if (type === 'luxury') {
        if (event.target.checked) {
          this.searchForm.mansion = true;
          this.searchForm.villa = true;
        } else {
          this.searchForm.mansion = false;
          this.searchForm.villa = false;
          this.searchForm.luxury = false;
        }
      }
      if (type === 'special_use') {
        if (event.target.checked) {
          this.searchForm.church = true;
          this.searchForm.cemetery = true;
          this.searchForm.park = true;
          this.searchForm.school = true;
          this.searchForm.barn = true;
          this.searchForm.campsite = true;
        } else {
          this.searchForm.church = false;
          this.searchForm.cemetery = false;
          this.searchForm.park = false;
          this.searchForm.school = false;
          this.searchForm.barn = false;
          this.searchForm.campsite = false;
          this.searchForm.special_use = false;
        }
      }
      if (type === 'rooms') {
        if (event.target.checked) {
          this.searchForm.single_room = true;
          this.searchForm.double_room = true;
          this.searchForm.twin_room = true;
          this.searchForm.twin_double_room = true;
          this.searchForm.triple_room = true;
          this.searchForm.quadruple_room = true;
          this.searchForm.family_room = true;
        } else {
          this.searchForm.single_room = false;
          this.searchForm.double_room = false;
          this.searchForm.twin_room = false;
          this.searchForm.twin_double_room = false;
          this.searchForm.triple_room = false;
          this.searchForm.quadruple_room = false;
          this.searchForm.family_room = false;
          this.searchForm.rooms = false;
        }
      }
      if (type === 'warehouses') {
        if (event.target.checked) {
          // ...
        } else {
          this.searchForm.warehouses = false;
        }
      }
      if (type === 'factories') {
        if (event.target.checked) {
          // ...
        } else {
          this.searchForm.factories = false;
        }
      }
      if (type === 'power_plants') {
        if (event.target.checked) {
          this.searchForm.hydroelectric_plant = true;
          this.searchForm.coal_fired_plant = true;
          this.searchForm.diesel_fired_plant = true;
          this.searchForm.geothermal_plant = true;
          this.searchForm.gas_fired_plant = true;
          this.searchForm.solar_power_plant = true;
          this.searchForm.wind_power_plant = true;
          this.searchForm.tidal_power_plant = true;
        } else {
          this.searchForm.hydroelectric_plant = false;
          this.searchForm.coal_fired_plant = false;
          this.searchForm.diesel_fired_plant = false;
          this.searchForm.geothermal_plant = false;
          this.searchForm.gas_fired_plant = false;
          this.searchForm.solar_power_plant = false;
          this.searchForm.wind_power_plant = false;
          this.searchForm.tidal_power_plant = false;
          this.searchForm.power_plants = false;
        }
      }

      this.setPropertyType();
    },
    setPropertyType() {
      // Filter Property Categories and add to an array of picked options
      if (this.searchForm.houses === true) {
        if (!this.propertyType.includes('Houses')) {
          this.propertyType.push('Houses');
        }
      } else {
        if (this.propertyType.includes('Houses')) {
          let array = this.propertyType.filter(e => e !== 'Houses');
          this.propertyType = array;
        }
      }
      if (this.searchForm.flats === true) {
        if (!this.propertyType.includes('Flats')) {
          this.propertyType.push('Flats');
        }
      } else {
        if (this.propertyType.includes('Flats')) {
          let array = this.propertyType.filter(e => e !== 'Flats');
          this.propertyType = array;
        }
      }
      if (this.searchForm.land === true) {
        if (!this.propertyType.includes('Land')) {
          this.propertyType.push('Land');
        }
      } else {
        if (this.propertyType.includes('Land')) {
          let array = this.propertyType.filter(e => e !== 'Land');
          this.propertyType = array;
        }
      }
      if (this.searchForm.retail === true) {
        if (!this.propertyType.includes('Retail')) {
          this.propertyType.push('Retail');
        }
      } else {
        if (this.propertyType.includes('Retail')) {
          let array = this.propertyType.filter(e => e !== 'Retail');
          this.propertyType = array;
        }
      }
      if (this.searchForm.leisure === true) {
        if (!this.propertyType.includes('Leisure')) {
          this.propertyType.push('Leisure');
        }
      } else {
        if (this.propertyType.includes('Leisure')) {
          let array = this.propertyType.filter(e => e !== 'Leisure');
          this.propertyType = array;
        }
      }
      if (this.searchForm.offices === true) {
        if (!this.propertyType.includes('Offices')) {
          this.propertyType.push('Offices');
        }
      } else {
        if (this.propertyType.includes('Offices')) {
          let array = this.propertyType.filter(e => e !== 'Offices');
          this.propertyType = array;
        }
      }
      if (this.searchForm.healthcare === true) {
        if (!this.propertyType.includes('Healthcare')) {
          this.propertyType.push('Healthcare');
        }
      } else {
        if (this.propertyType.includes('Healthcare')) {
          let array = this.propertyType.filter(e => e !== 'Healthcare');
          this.propertyType = array;
        }
      }
      if (this.searchForm.garage_parking === true) {
        if (!this.propertyType.includes('Garage/Parking')) {
          this.propertyType.push('Garage/Parking');
        }
      } else {
        if (this.propertyType.includes('Garage/Parking')) {
          let array = this.propertyType.filter(e => e !== 'Garage/Parking');
          this.propertyType = array;
        }
      }
      if (this.searchForm.multifamily === true) {
        if (!this.propertyType.includes('Multifamily')) {
          this.propertyType.push('Multifamily');
        }
      } else {
        if (this.propertyType.includes('Multifamily')) {
          let array = this.propertyType.filter(e => e !== 'Multifamily');
          this.propertyType = array;
        }
      }
      if (this.searchForm.luxury === true) {
        if (!this.propertyType.includes('Luxury')) {
          this.propertyType.push('Luxury');
        }
      } else {
        if (this.propertyType.includes('Luxury')) {
          let array = this.propertyType.filter(e => e !== 'Luxury');
          this.propertyType = array;
        }
      }
      if (this.searchForm.special_use === true) {
        if (!this.propertyType.includes('Special Use')) {
          this.propertyType.push('Special Use');
        }
      } else {
        if (this.propertyType.includes('Special Use')) {
          let array = this.propertyType.filter(e => e !== 'Special Use');
          this.propertyType = array;
        }
      }
      if (this.searchForm.rooms === true) {
        if (!this.propertyType.includes('Rooms')) {
          this.propertyType.push('Rooms');
        }
      } else {
        if (this.propertyType.includes('Rooms')) {
          let array = this.propertyType.filter(e => e !== 'Rooms');
          this.propertyType = array;
        }
      }
      if (this.searchForm.warehouses === true) {
        if (!this.propertyType.includes('Warehouses')) {
          this.propertyType.push('Warehouses');
        }
      } else {
        if (this.propertyType.includes('Warehouses')) {
          let array = this.propertyType.filter(e => e !== 'Warehouses');
          this.propertyType = array;
        }
      }
      if (this.searchForm.factories === true) {
        if (!this.propertyType.includes('Factories')) {
          this.propertyType.push('Factories');
        }
      } else {
        if (this.propertyType.includes('Factories')) {
          let array = this.propertyType.filter(e => e !== 'Factories');
          this.propertyType = array;
        }
      }
      if (this.searchForm.power_plants === true) {
        if (!this.propertyType.includes('Power Plants')) {
          this.propertyType.push('Power Plants');
        }
      } else {
        if (this.propertyType.includes('Power Plants')) {
          let array = this.propertyType.filter(e => e !== 'Power Plants');
          this.propertyType = array;
        }
      }
    },
    openSearchBox() {
      if(document.body.contains(document.getElementById("searchModal"))) {
        document.getElementById("searchModal").style.transition = "all 0.5s";
        document.getElementById("searchModal").style.height = "auto"; // 7vh
        document.getElementById("searchModal").style.visibility = "visible";
        document.getElementById("searchModal").style.border = "border: 0px solid rgba(0, 0, 0, 0.125)";
        document.getElementById("searchModal").style.boxShadow = "0 0.5rem 1rem rgb(0 0 0 / 15%)";
        document.getElementById("searchModal").style.opacity = "1";

        const timeout = setTimeout(
            function() {
              document.getElementById("searchModal").style.overflow = "visible";
              document.getElementById("filtersButton").style.transition = "all 0.5s"
              document.getElementById("filtersButton").style.opacity = "1";
              document.getElementById("searchBarBox").focus();
              clearTimeout(timeout);
            }, 700);
        //border: 0px solid rgba(0, 0, 0, 0.125);
        //box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
      }
      if(this.activeFilters) {
        document.getElementById("searchModal").style.height = "60vh";
      }
    },
    closeSearchBox() {
      if(document.body.contains(document.getElementById("openFilters"))) {
        // document.getElementById("openFilters").style.height = "0";
        // document.getElementById("filters-form").style.opacity = "0";
        // this.activeFilters = !this.activeFilters;
      }

      if(document.body.contains(document.getElementById("searchModal"))) {
        document.getElementById("searchModal").style.transition = "all 0.5s";
        // document.getElementById("searchModal").style.height = "0";
        document.getElementById("searchModal").style.visibility = "hidden";
        document.getElementById("searchModal").style.border = "none";
        document.getElementById("searchModal").style.boxShadow = "none";
        document.getElementById("searchModal").style.opacity = "0";

        const timeout = setTimeout(
            function() {
              document.getElementById("searchModal").style.overflow = "hidden";
              document.getElementById("filtersButton").style.transition = "all 0.5s"
              document.getElementById("filtersButton").style.opacity = "0";
              clearTimeout(timeout);
            }, 700);
      }
    },
    openFilters() {
      if(document.body.contains(document.getElementById("searchModal"))) {
        let filterIcon = document.getElementById("filterIcon");
        if (!filterIcon.classList.contains("rotate180")) {
          filterIcon.classList.toggle("rotate180");
        }
        // Create a media condition that targets viewports of max-width 575px
        const mediaQueryMax575 = window.matchMedia('(max-width: 575px)')
        // Check if the media query is true
        if (mediaQueryMax575.matches) {
          document.getElementById("searchModal").style.transition = "all 0.5s"
          document.getElementById("searchModal").style.height = "80vh";
        } else {
          document.getElementById("searchModal").style.transition = "all 0.5s"
          document.getElementById("searchModal").style.height = "60vh";
        }
      }

      const timeout = setTimeout(
          function() {
            if(document.body.contains(document.getElementById("openFilters"))) {
              document.getElementById("filters-form").style.transition = "all 0.5s";
              document.getElementById("openFilters").style.height = "60vh";
              document.getElementById("filters-form").style.opacity = "1";
            }
            clearTimeout(timeout);
          }, 600);
    },
    closeFilters() {
      if(document.body.contains(document.getElementById("searchModal"))) {
        document.getElementById("searchModal").style.height = "auto"; // 7vh
        let filterIcon = document.getElementById("filterIcon");
        if (filterIcon.classList.contains("rotate180")) {
          filterIcon.classList.toggle("rotate180");
        }
      }
      if(document.body.contains(document.getElementById("openFilters"))) {
        document.getElementById("filters-form").style.transition = "all 0.1s";
        document.getElementById("filters-form").style.opacity = "0";
        document.getElementById("openFilters").style.transition = "all 0.5s";
        document.getElementById("openFilters").style.height = "0";
        document.getElementById("openFilters").style.opacity = "0";
        if(this.activeFilters === true) {
          this.activeFilters = !this.activeFilters;
        }
      }
    },
    initGeocodeBox(type) {
      this.$nextTick(() => {

        // Mapbox Geocoding Address Autocomplete
        let geocoder = '';
        // searchForm.ad_type 1 = Buy; searchForm.ad_type 2 = Rent; searchForm.ad_type 3 = Buy & Rent
        // We want to search only by locality if thats possible
        if(type === 1 || type === 2 || type === 3) {
          geocoder = new MapboxGeocoder({
            accessToken: process.env.VUE_APP_MAPBOX_ACCESS_TOKEN,
            types: 'region,place,district,postcode,locality',
            limit: 7,
            countries: 'gb, ie, es', // Restrict to UK,Ireland and Spain only
          });
        } else {
          geocoder = new MapboxGeocoder({
            accessToken: process.env.VUE_APP_MAPBOX_ACCESS_TOKEN,
            types: 'region,place,district,postcode,locality,address',
            limit: 7,
            countries: 'gb, ie, es', // Restrict to UK,Ireland and Spain only
          });
        }

        let geoCheck = document.getElementById('geocoder');
        if (geoCheck) {
          if(geoCheck.children.length <= 0) {
            geocoder.addTo('#geocoder');
          } else {
            geoCheck.innerHTML = '';
            geocoder.addTo(geoCheck);
            // Ideally we would resset searchBox at this point, slightly below we are setting its value based on
            // searchFilters, on the other hand it could irritate people that when they changed AdType their 'address'
            // was restarted as well
          }
        }

        // #addressPrepopulated if exists in searchFilters
        if(this.$store.state.searchFilters.searchFilters && this.$store.state.searchFilters.searchFilters.address) {
          // Later I will use this solution, I am awaiting for second parameter to be added
          // https://github.com/mapbox/mapbox-gl-geocoder/pull/425
          // geocoder.setInput(this.$store.state.searchFilters.searchFilters.address);
          geocoder._inputEl.value = this.$store.state.searchFilters.searchFilters.address;
          // geocoder.query(this.$store.state.searchFilters.searchFilters.address);
        }

        // Set address to London if undefined (default value that we want)
        if (this.$store.state.searchFilters.searchFilters.address == undefined) {
          geocoder._inputEl.value = "London, Greater London, England, United Kingdom"
        }

        // Add geocoder result to container.
        geocoder.on('result', (e) => {
          // console.log(e);
          // results.innerText = JSON.stringify(e.result, null, 2);

          // Reset all values before new are assigned
          this.searchForm.address = '';
          this.searchForm.longitude = '';
          this.searchForm.latitude = '';
          this.searchForm.boundary_box = '';
          this.searchForm.line_1 = '';
          this.searchForm.line_2 = '';
          this.searchForm.street = '';
          this.searchForm.postcode = '';
          this.searchForm.city = '';
          this.searchForm.county = '';

          // For districts such as South Yorkshire, Hampshire etc.

          // Highly likely I wont need that on searchFilters
          if(e.result.place_type.includes('district')) {
            for (let i = 0; i < e.result.context.length; i++) {
              if (e.result.context[i].id.includes('postcode')) {
                this.searchForm.postcode = e.result.context[i].text;
              }
              if (e.result.context[i].id.includes('locality')) {
                this.searchForm.line_2 = e.result.context[i].text;
              }
              if (e.result.context[i].id.includes('place')) {
                this.searchForm.city = e.result.context[i].text;
              }
              if (e.result.context[i].id.includes('country')) {
                this.searchForm.country = e.result.context[i].text;
              }
            }
            // district
            if (e.result.text) {
              this.searchForm.county = e.result.text;
            }
            // For places, which are cities, such as Manchester, Leeds etc.
          } else if (e.result.place_type.includes('place')) {
            for (let i = 0; i < e.result.context.length; i++) {
              if (e.result.context[i].id.includes('postcode')) {
                this.searchForm.postcode = e.result.context[i].text;
              }
              if (e.result.context[i].id.includes('locality')) {
                this.searchForm.line_2 = e.result.context[i].text;
              }
              if (e.result.context[i].id.includes('district')) {
                this.searchForm.county = e.result.context[i].text;
              }
              if (e.result.context[i].id.includes('country')) {
                this.searchForm.country = e.result.context[i].text;
              }
            }
            // Place - City
            if (e.result.text) {
              this.searchForm.city = e.result.text;
            }
          } else if (e.result.place_type.includes('postcode')) {
            for(let i=0; i < e.result.context.length; i++) {
              if(e.result.context[i].id.includes('locality')) {
                this.searchForm.line_2 = e.result.context[i].text;
              }
              if(e.result.context[i].id.includes('district')) {
                this.searchForm.county = e.result.context[i].text;
              }
              if(e.result.context[i].id.includes('country')) {
                this.searchForm.country = e.result.context[i].text;
              }
            }
            // Postcode
            if(e.result.text) {
              this.searchForm.postcode = e.result.text;
            }
            // For locality such as Cudworth, Cosham etc.
          } else if (e.result.place_type.includes('locality')) {
            for (let i = 0; i < e.result.context.length; i++) {
              if (e.result.context[i].id.includes('district')) {
                this.searchForm.county = e.result.context[i].text;
              }
              if (e.result.context[i].id.includes('place')) {
                this.searchForm.city = e.result.context[i].text;
              }
              if (e.result.context[i].id.includes('country')) {
                this.searchForm.country = e.result.context[i].text;
              }
            }
            // Locality
            if (e.result.text) {
              this.searchForm.line_2 = e.result.text;
            }
            // For addresses, detailed ones such as 7 California Street etc. and all the rest
          } else {
            for(let i=0; i < e.result.context.length; i++) {
              if(e.result.context[i].id.includes('postcode')) {
                this.searchForm.postcode = e.result.context[i].text;
              }
              if(e.result.context[i].id.includes('locality')) {
                this.searchForm.line_2 = e.result.context[i].text;
              }
              if(e.result.context[i].id.includes('place')) {
                this.searchForm.city = e.result.context[i].text;
              }
              if(e.result.context[i].id.includes('district')) {
                this.searchForm.county = e.result.context[i].text;
              }
              if(e.result.context[i].id.includes('country')) {
                this.searchForm.country = e.result.context[i].text;
              }
            }
            // First line of address
            if(e.result.text || e.result.address) {
              this.searchForm.line_1 = (e.result.address ? e.result.address + ' ' : '') + (e.result.text ? e.result.text : '');
            }
            if(e.result.text) {
              this.searchForm.street = (e.result.text ? e.result.text : '');
            }
          }

          // Full Address
          if(e.result.place_name) {
            this.searchForm.address = e.result.place_name;
          }
          // Coordinates
          if(e.result.center) {
            this.searchForm.longitude = e.result.center[0];
            this.searchForm.latitude = e.result.center[1];
          }
          // Boundary Box
          if(e.result.bbox) {
            this.searchForm.boundary_box = e.result.bbox;
          }
        });

        // Clear results container when search is cleared.
        geocoder.on('clear', () => {
          // results.innerText = '';
          this.searchForm.address = '';
          this.searchForm.longitude = '';
          this.searchForm.latitude = '';
          this.searchForm.boundary_box = '';
          this.searchForm.line_1 = '';
          this.searchForm.line_2 = '';
          this.searchForm.street = '';
          this.searchForm.postcode = '';
          this.searchForm.city = '';
          this.searchForm.county = '';
        });

      });
    },
    isMobile() {
      if( screen.width <= 767 ) {
        return true;
      }
      else {
        return false;
      }
    }
  },
  created() {
    // // On mobile we want search filters to be closed
    // if (!this.isMobile()) {
    //   // this.activeSearch = !this.activeSearch;
    //   this.activeSearch = true;
    // } else {
    //   this.activeSearch = false;
    // }

    // // Search properties
    // axios.get('api/properties',this.properties).then(({data})=>{
    //   this.properties = data['data']
    // }).catch(({response:{data}})=>{
    //   alert(data.message)
    // }).finally(()=>{
    //   this.processing = false
    // })
  },
  watch: {
    "activeSearch": {
      handler: function (val) {
        if(this.activeSearch === true) {
          this.openSearchBox();
        } else {
          this.closeSearchBox();
        }
      }, deep: true, immediate: true
    },
    // 'activeSearch': function (val) {
    //   console.log(this.activeSearch);
    //   if(this.activeSearch === true) {
    //     console.log('here');
    //     this.openSearchBox();
    //   } else {
    //     console.log('there');
    //     this.closeSearchBox();
    //   }
    // },
    'activeFilters': function (val) {
      if(this.activeFilters === true) {
        this.openFilters();
      } else {
        this.closeFilters();
      }
    },
    'searchForm': {
      handler: function (val) {
        // console.log(JSON.stringify(this.searchForm) === JSON.stringify(this.$store.state.searchFilters.searchFilters));
        let searchBtn = document.getElementById("searchFilterButton");
        if (searchBtn && !searchBtn.classList.contains("search-filter-button") && JSON.stringify(this.searchForm) !== JSON.stringify(this.$store.state.searchFilters.searchFilters)) {
          searchBtn.classList.toggle("search-filter-button");
        }
        if (searchBtn && JSON.stringify(this.searchForm) === JSON.stringify(this.$store.state.searchFilters.searchFilters)) {
          if (searchBtn.classList.contains("search-filter-button")) {
            searchBtn.classList.toggle("search-filter-button");
          }
        }
      }, deep: true
    },
    'searchForm.ad_type': function (val) {
      // Buy
      if(val === 1) {
        this.searchForm.is_buy = 1;
        this.searchForm.is_sale = 0;
        this.searchForm.is_let = 0;
        this.searchForm.is_rent = 0;
        this.searchForm.is_coming_soon = false;
      }
      // Rent
      if(val === 2) {
        this.searchForm.is_buy = 0;
        this.searchForm.is_sale = 0;
        this.searchForm.is_let = 0;
        this.searchForm.is_rent = 1;
        this.searchForm.is_coming_soon = false;
      }
      // Buy & Rent
      if(val === 3) {
        this.searchForm.is_buy = 1;
        this.searchForm.is_sale = 0;
        this.searchForm.is_let = 0;
        this.searchForm.is_rent = 1;
        this.searchForm.is_coming_soon = false;
      }
      // Sell
      if(val === 4) {
        this.searchForm.is_buy = 0;
        this.searchForm.is_sale = 1;
        this.searchForm.is_let = 0;
        this.searchForm.is_rent = 0;
      }
      // Let
      if(val === 5) {
        this.searchForm.is_buy = 0;
        this.searchForm.is_sale = 0;
        this.searchForm.is_let = 1;
        this.searchForm.is_rent = 0;
      }
      // Sell & Let
      if(val === 6) {
        this.searchForm.is_buy = 0;
        this.searchForm.is_sale = 1;
        this.searchForm.is_let = 1;
        this.searchForm.is_rent = 0;
      }

      // this.initGeocodeBox(val);
    },
    'searchForm.min_price': function (val) {
      this.searchForm.price = '£' + val + (this.searchForm.max_price !== '' ? ' - £' + this.searchForm.max_price : '');
    },
    'searchForm.max_price': function (val) {
      this.searchForm.price = (this.searchForm.min_price !== '' ? '£' + this.searchForm.min_price : '') + ' - £' + val;
    },
    'searchForm.min_beds': function (val) {
      this.searchForm.beds = val + (this.searchForm.max_beds !== '' ? ' - ' + this.searchForm.max_beds : '');
    },
    'searchForm.max_beds': function (val) {
      this.searchForm.beds = (this.searchForm.min_beds !== '' ? this.searchForm.min_beds : '') + ' - ' + val;
    },
    /* Watch Property Type Filters */
    /* Residential - Houses */
    'searchForm.detached_house': function (val) {
      if(val === true) {
        this.searchForm.detached_house = true;
        this.searchForm.houses = true;
      }
      this.setPropertyType();
    },
    'searchForm.semi_detached_house': function (val) {
      if(val === true) {
        this.searchForm.semi_detached_house = true;
        this.searchForm.houses = true;
      }
      this.setPropertyType();
    },
    'searchForm.mid_terraced': function (val) {
      if(val === true) {
        this.searchForm.mid_terraced = true;
        this.searchForm.houses = true;
      }
      this.setPropertyType();
    },
    'searchForm.end_terraced': function (val) {
      if(val === true) {
        this.searchForm.end_terraced = true;
        this.searchForm.houses = true;
      }
      this.setPropertyType();
    },
    'searchForm.detached_bungalow': function (val) {
      if(val === true) {
        this.searchForm.detached_bungalow = true;
        this.searchForm.houses = true;
      }
      this.setPropertyType();
    },
    'searchForm.semi_detached_bungalow': function (val) {
      if(val === true) {
        this.searchForm.semi_detached_bungalow = true;
        this.searchForm.houses = true;
      }
      this.setPropertyType();
    },
    'searchForm.cottage': function (val) {
      if(val === true) {
        this.searchForm.cottage = true;
        this.searchForm.houses = true;
      }
      this.setPropertyType();
    },
    /* Residential - Flats */
    'searchForm.flat': function (val) {
      if(val === true) {
        this.searchForm.flat = true;
        this.searchForm.flats = true;
      }
      this.setPropertyType();
    },
    'searchForm.studio': function (val) {
      if(val === true) {
        this.searchForm.studio = true;
        this.searchForm.flats = true;
      }
      this.setPropertyType();
    },
    'searchForm.maisonette': function (val) {
      if(val === true) {
        this.searchForm.maisonette = true;
        this.searchForm.flats = true;
      }
      this.setPropertyType();
    },
    /* Commercial - Offices */
    'searchForm.traditional_office': function (val) {
      if(val === true) {
        this.searchForm.traditional_office = true;
        this.searchForm.offices = true;
      }
      this.setPropertyType();
    },
    'searchForm.serviced_office': function (val) {
      if(val === true) {
        this.searchForm.serviced_office = true;
        this.searchForm.offices = true;
      }
      this.setPropertyType();
    },
    'searchForm.managed_office': function (val) {
      if(val === true) {
        this.searchForm.managed_office = true;
        this.searchForm.offices = true;
      }
      this.setPropertyType();
    },
    /* Commercial - Retail */
    'searchForm.shop': function (val) {
      if(val === true) {
        this.searchForm.shop = true;
        this.searchForm.retail = true;
      }
      this.setPropertyType();
    },
    'searchForm.shopping_mall': function (val) {
      if(val === true) {
        this.searchForm.shopping_mall = true;
        this.searchForm.retail = true;
      }
      this.setPropertyType();
    },
    'searchForm.retail_store': function (val) {
      if(val === true) {
        this.searchForm.retail_store = true;
        this.searchForm.retail = true;
      }
      this.setPropertyType();
    },
    /* Commercial - Leisure */
    'searchForm.restaurant_cafe': function (val) {
      if(val === true) {
        this.searchForm.restaurant_cafe = true;
        this.searchForm.leisure = true;
      }
      this.setPropertyType();
    },
    'searchForm.takeaway': function (val) {
      if(val === true) {
        this.searchForm.takeaway = true;
        this.searchForm.leisure = true;
      }
      this.setPropertyType();
    },
    'searchForm.pub': function (val) {
      if(val === true) {
        this.searchForm.pub = true;
        this.searchForm.leisure = true;
      }
      this.setPropertyType();
    },
    'searchForm.entertainment': function (val) {
      if(val === true) {
        this.searchForm.entertainment = true;
        this.searchForm.leisure = true;
      }
      this.setPropertyType();
    },
    'searchForm.sport_healthcare_facility': function (val) {
      if(val === true) {
        this.searchForm.sport_healthcare_facility = true;
        this.searchForm.leisure = true;
      }
      this.setPropertyType();
    },
    'searchForm.hotel_tourism': function (val) {
      if(val === true) {
        this.searchForm.hotel_tourism = true;
        this.searchForm.leisure = true;
      }
      this.setPropertyType();
    },
    /* Commercial Healthcare */
    'searchForm.pharmacy': function (val) {
      if(val === true) {
        this.searchForm.pharmacy = true;
        this.searchForm.healthcare = true;
      }
      this.setPropertyType();
    },
    'searchForm.medical_center': function (val) {
      if(val === true) {
        this.searchForm.medical_center = true;
        this.searchForm.healthcare = true;
      }
      this.setPropertyType();
    },
    'searchForm.nursing_home': function (val) {
      if(val === true) {
        this.searchForm.nursing_home = true;
        this.searchForm.healthcare = true;
      }
      this.setPropertyType();
    },
    'searchForm.hospital': function (val) {
      if(val === true) {
        this.searchForm.hospital = true;
        this.searchForm.healthcare = true;
      }
      this.setPropertyType();
    },
    /* Commercial - Garage/Parking */
    'searchForm.parking_space': function (val) {
      if(val === true) {
        this.searchForm.parking_space = true;
        this.searchForm.garage_parking = true;
      }
      this.setPropertyType();
    },
    'searchForm.single_garage': function (val) {
      if(val === true) {
        this.searchForm.single_garage = true;
        this.searchForm.garage_parking = true;
      }
      this.setPropertyType();
    },
    'searchForm.multiple_garages': function (val) {
      if(val === true) {
        this.searchForm.multiple_garages = true;
        this.searchForm.garage_parking = true;
      }
      this.setPropertyType();
    },
    'searchForm.parking_lot': function (val) {
      if(val === true) {
        this.searchForm.parking_lot = true;
        this.searchForm.garage_parking = true;
      }
      this.setPropertyType();
    },
    'searchForm.multi_storey_parking': function (val) {
      if(val === true) {
        this.searchForm.multi_storey_parking = true;
        this.searchForm.garage_parking = true;
      }
      this.setPropertyType();
    },
    /* Commercial - Multifamily */
    'searchForm.duplex': function (val) {
      if(val === true) {
        this.searchForm.duplex = true;
        this.searchForm.multifamily = true;
      }
      this.setPropertyType();
    },
    'searchForm.block_of_flats': function (val) {
      if(val === true) {
        this.searchForm.block_of_flats = true;
        this.searchForm.multifamily = true;
      }
      this.setPropertyType();
    },
    'searchForm.apartment_complex': function (val) {
      if(val === true) {
        this.searchForm.apartment_complex = true;
        this.searchForm.multifamily = true;
      }
      this.setPropertyType();
    },
    /* Industrial - Warehouse */
    /* Industrial Factory */
    /* Commercial - Powerplants */
    'searchForm.hydroelectric_plant': function (val) {
      if(val === true) {
        this.searchForm.hydroelectric_plant = true;
        this.searchForm.power_plants = true;
      }
      this.setPropertyType();
    },
    'searchForm.coal_fired_plant': function (val) {
      if(val === true) {
        this.searchForm.coal_fired_plant = true;
        this.searchForm.power_plants = true;
      }
      this.setPropertyType();
    },
    'searchForm.diesel_fired_plant': function (val) {
      if(val === true) {
        this.searchForm.diesel_fired_plant = true;
        this.searchForm.power_plants = true;
      }
      this.setPropertyType();
    },
    'searchForm.geothermal_plant': function (val) {
      if(val === true) {
        this.searchForm.geothermal_plant = true;
        this.searchForm.power_plants = true;
      }
      this.setPropertyType();
    },
    'searchForm.gas_fired_plant': function (val) {
      if(val === true) {
        this.searchForm.gas_fired_plant = true;
        this.searchForm.power_plants = true;
      }
      this.setPropertyType();
    },
    'searchForm.solar_power_plant': function (val) {
      if(val === true) {
        this.searchForm.solar_power_plant = true;
        this.searchForm.power_plants = true;
      }
      this.setPropertyType();
    },
    'searchForm.wind_power_plant': function (val) {
      if(val === true) {
        this.searchForm.wind_power_plant = true;
        this.searchForm.power_plants = true;
      }
      this.setPropertyType();
    },
    'searchForm.tidal_power_plant': function (val) {
      if(val === true) {
        this.searchForm.tidal_power_plant = true;
        this.searchForm.power_plants = true;
      }
      this.setPropertyType();
    },
    /* Land */
    'searchForm.greenfield': function (val) {
      if(val === true) {
        this.searchForm.greenfield = true;
        this.searchForm.land = true;
      }
      this.setPropertyType();
    },
    'searchForm.brownfield': function (val) {
      if(val === true) {
        this.searchForm.brownfield = true;
        this.searchForm.land = true;
      }
      this.setPropertyType();
    },
    /* Luxury */
    'searchForm.mansion': function (val) {
      if(val === true) {
        this.searchForm.mansion = true;
        this.searchForm.luxury = true;
      }
      this.setPropertyType();
    },
    'searchForm.villa': function (val) {
      if(val === true) {
        this.searchForm.villa = true;
        this.searchForm.luxury = true;
      }
      this.setPropertyType();
    },
    /* Special Use */
    'searchForm.church': function (val) {
      if(val === true) {
        this.searchForm.church = true;
        this.searchForm.special_use = true;
      }
      this.setPropertyType();
    },
    'searchForm.cemetery': function (val) {
      if(val === true) {
        this.searchForm.cemetery = true;
        this.searchForm.special_use = true;
      }
      this.setPropertyType();
    },
    'searchForm.park': function (val) {
      if(val === true) {
        this.searchForm.park = true;
        this.searchForm.special_use = true;
      }
      this.setPropertyType();
    },
    'searchForm.school': function (val) {
      if(val === true) {
        this.searchForm.school = true;
        this.searchForm.special_use = true;
      }
      this.setPropertyType();
    },
    'searchForm.barn': function (val) {
      if(val === true) {
        this.searchForm.barn = true;
        this.searchForm.special_use = true;
      }
      this.setPropertyType();
    },
    'searchForm.campsite': function (val) {
      if(val === true) {
        this.searchForm.campsite = true;
        this.searchForm.special_use = true;
      }
      this.setPropertyType();
    },
    /* Rooms */
    'searchForm.single_room': function (val) {
      if(val === true) {
        this.searchForm.single_room = true;
        this.searchForm.rooms = true;
      }
      this.setPropertyType();
    },
    'searchForm.double_room': function (val) {
      if(val === true) {
        this.searchForm.double_room = true;
        this.searchForm.rooms = true;
      }
      this.setPropertyType();
    },
    'searchForm.twin_room': function (val) {
      if(val === true) {
        this.searchForm.twin_room = true;
        this.searchForm.rooms = true;
      }
      this.setPropertyType();
    },
    'searchForm.twin_double_room': function (val) {
      if(val === true) {
        this.searchForm.twin_double_room = true;
        this.searchForm.rooms = true;
      }
      this.setPropertyType();
    },
    'searchForm.triple_room': function (val) {
      if(val === true) {
        this.searchForm.triple_room = true;
        this.searchForm.rooms = true;
      }
      this.setPropertyType();
    },
    'searchForm.quadruple_room': function (val) {
      if(val === true) {
        this.searchForm.quadruple_room = true;
        this.searchForm.rooms = true;
      }
      this.setPropertyType();
    },
    'searchForm.family_room': function (val) {
      if(val === true) {
        this.searchForm.family_room = true;
        this.searchForm.rooms = true;
      }
      this.setPropertyType();
    },
    'searchForm.date_range': {
      handler: function (val) {
        if (val !== null) {
          if (val.start === null) {
            this.searchForm.date_start = null;
            this.datePresent = false;
          } else {
            this.searchForm.date_start = this.moment(val.start).format('DD-MM-YYYY');
            this.datePresent = true;
          }
        } else {
          this.searchForm.date_start = null;
          this.datePresent = false;
        }
        if (val !== null) {
          if (val.start === null) {
            this.searchForm.date_start = null;
            this.datePresent = false;
          } else {
            this.searchForm.date_end = this.moment(val.end).format('DD-MM-YYYY');
            this.datePresent = true;
          }
        } else {
          this.searchForm.date_end = null;
          this.datePresent = false;
        }
      }, deep: true
    },
    'searchForm.is_long_short_term': {
      handler: function (val) {
        if (val != '1' || val != '3') {
          this.searchForm.is_coming_soon = false;
        }
      }
    },
    '$store.state.searchFilters.searchFilters': {
      handler: function (val) {
        this.searchForm = {...this.$store.state.searchFilters.searchFilters};
      }, deep: true
    },
  }
}
</script>

<style lang="scss">

/* start tooltip - popups info on icons */
.nav-btn-search-tooltiptext {
  visibility: hidden;
  width: auto;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  text-align: center;
  font-size: 10px;
  border-radius: 6px;
  padding: 5px 5px;

  /* Position the tooltip */
  position: absolute;
  top: -28px;
  z-index: 1000;
}

[data-tooltip]:hover .nav-btn-search-tooltiptext {
  visibility: visible;
  color: #fff;
}
/* end tooltip - popups info on icons */

/* Map Box Geocoder */
#geocoder {
  z-index: 1;
}
.mapboxgl-ctrl-geocoder {
  border-radius: .25rem 0 0 .25rem;
  //border: 1px solid #ced4da;
  width: 100%;
  box-shadow: none;
  min-width: 100%;
}

/* Input box on focus */
.mapboxgl-ctrl-geocoder--input {
  font-size: 14px;
  color: #9e9e9e !important;
}
.mapboxgl-ctrl-geocoder--input:focus {
  border-radius: 5px;
  font-size: 14px;
}

/* Search Results size */
.mapboxgl-ctrl-geocoder .suggestions li .mapboxgl-ctrl-geocoder--suggestion {
  font-size: 12px !important;
}
/* End Map Box Geocoder */

/* Fade effect for transition elements */
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.openFilters {
  max-height: 50vh;
  overflow: hidden;
  overflow-y: scroll;
  padding: 10px 1px 10px 10px;
  margin-top: 10px;
  font-size: 14px;
}

#filters-form {
  opacity: 0;
}

/* Important so that when searchBox is focused there is no lines around it */
#searchBarBox:focus-visible {
  outline: none!important;
}

/* Important so that searchFilters are up-front when focused */
#searchModal:focus {
  z-index: 99999 !important;
}

/* Important so that searchFilters are up-front when focused */
#searchModal:focus-within {
  z-index: 99999 !important;
}

.dropdown-btn {
  color: #212529;
  background-color: #fff;
}

.search-bar * {
  //border-radius: 0;
  border: none !important;
  outline: 0;
  box-shadow: none;
}

.search-bar * :focus{
  border-radius: 5px !important;
  color: rgba(0, 0, 0, 0.75) !important;
  outline: 0;
  box-shadow: none;
  outline: thin dotted !important;
}

.search-component {
  .search-filter-btns {
    border-radius: 0;
    border: none;
    outline: 0;
    box-shadow: none;
    width: 100%;
    height: 36px;
    overflow: hidden;
  }
  #searchFilterButton {
    width: 100%;
    height: 100%;
  }
}

select.chevron-toggle {
  -moz-appearance:none; /* Firefox */
  -webkit-appearance:none; /* Safari and Chrome */
  appearance:none;
  background: white;
  background-image: url("data:image/svg+xml;utf8,<svg fill='darkgrey' height='21' viewBox='0 0 24 24' width='21' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 45%;
}

/* For IE (thanks to @SaiManoj) */
select.chevron-toggle::-ms-expand {
  display: none;
}

.search-filter-btns:focus {
  border-radius: 5px !important;
  color: rgba(0, 0, 0, 0.75) !important;
  outline: 0;
  box-shadow: none;
  outline: thin dotted !important;
}

/* dropdown buttons in filters */
.dropdown-container-relative {
  position: relative;
  width: 100%;
  height: 100%;
}

.dropdown-menu {
  //position: absolute;
  //width: 220px;
  //top: 100%;
  //left: -20% !important;
}

.form-font-size {
  font-size: 14px;
}
.form-position {
  position: relative;
}

.dropdown-menu-filters {
  position: absolute;
  width: 220px;
  top: 100%;
}

.calendar-data-set {
  color: green;
}

.calendar-data-unset {
  color: red;
}

.dropdown-menu-date {
  left: -15vw !important;
}

.dropdown-menu-date-filters {
  position: absolute;
  width: 265px;
  top: 100%;
}

.filter-select {
  border-radius: 10px;
  border: 1px solid #9e9e9e;
  background-color: white;
  width: 100%;
  padding: 5px;
}

.dropdown-menu-prop-type-fixed {
  position: fixed !important;
  width: 100%;
  top: 100%;
  left: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 450px;
}

.filter-prop-label {
  background-color: #eeeeee;
  border-radius: 10px;
}

/* Start Search Filters Expand Filters Button - Double Chevron */
.rotate180 {
  transition: 0.7s;
  -webkit-transition-duration: 0.7s;
  transform: rotate(180deg);
}

.btn-round-filters {
  border-radius: 0 0 50% 50% !important;
  line-height: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  transition: 0.7s;
  -webkit-transition-duration: 0.7s;
  border-top: none !important;
  border-left: 1px solid rgba(0,0,0,.125) !important;
  border-right: 1px solid rgba(0,0,0,.125) !important;
  border-bottom: 1px solid rgba(0,0,0,.125) !important;
}

#filtersButton {
  background-color: white;
  //background-color: #fcfcfc;
  transition: 0.7s;
  -webkit-transition-duration: 0.7s;
  position: absolute;
  bottom: -17px;
  //left: 48.3%;
  left: 48.3%;
  right: 48%;
  width: 30px;
  height: auto;
  //transform: translate(-50%);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse 2s;
  animation-iteration-count: 2;
}

#filtersButton:hover {
  color: red;
}

/* Double Chevron Animation Once */
.pulse-animation-once {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse 2s;
  animation-iteration-count: 2;
}
/* End Double Chevron Animation Once */

/* End Search Filters Expand Filters Button - Double Chevron */

/* Search Button Animation */
.search-filter-button {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse 2s infinite;
}
/* End Search Button Animation */

.btn-round {
  border-radius: 50% !important;
}

.z-index-special {
  z-index: 9999 !important;
}

/* .btn RAF CHANGE */
#searchModal .btn {
  border: 0 !important;
}

.btn-check:focus + .btn-outline-success, .btn-outline-success:focus {
  box-shadow: none !important;
}

.btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
  box-shadow: none !important;
}

.border-none {
  border: none;
}

.border-lr {
  border: none;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
}

.border-left {
  border: none;
  border-left: 1px solid transparent;
}

.border-right {
  border: none;
  border-right: 1px solid transparent;
}

#searchButton {
  //position: absolute;
  transition: margin-left .5s;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

//#searchButton {
//  position: absolute;
//  transition: margin-left .5s;
//  padding: 16px;
//}

/* search bar */
.search-component {
  height: 0;
  width: 60vw;
  position: absolute; // was fixed
  z-index: 10;
  top: 11%;
  left: 50%;
  transform: translate(-50%);
  background-color: rgba(255, 255, 255, 90%);
  overflow: visible;
  opacity: 0;
  transition: 0.5s;
  border-radius: 5px;
}

.search-component a {
  padding: 8px 8px 8px 10px;
  text-decoration: none;
  font-size: 25px;
  color: black;
  display: block;
  transition: 0.3s;
}

.search-component a:hover {
  color: #f1f1f1;
}

.search-component .closebtn {
  position: absolute;
  top: -10px;
  right: 0px;
  font-size: 36px;
  margin-left: 50px;
}

.vuenav-btns {
  position: absolute;
  top: 50px;
  height: 35%;
  width: 25%;
}

.openbtnLeft {
  position: relative;
  top: 0;
  left: 0;
  font-size: 20px;
  cursor: pointer;
  background-color: rgba(33, 150, 243, 50%);
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 0 50% 50% 0;
}

.openbtnLeft:hover {
  background-color: #444;
}

/* ===== Toggles ===== */
/* The switch - the box around the slider */
.switch {
  position: relative;
  text-align: center;
  display: inline-block;
  line-height: 20px;
  -moz-block-height: 20px;
  width: 40px;
  height: 20px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 17px;
  width: 17px;
  left: 1px;
  bottom: 1.5px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

.form-check-label {
  width: 100%;
  height: auto;
  padding: 0 5px 0 5px;
  border-radius: 10px;
}
.form-check-label:hover {
  background-color: #e9ecef;
  cursor: pointer;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* End Slider */

/* Start Radio Button */
/* Customize the label (the container) */
.radio-container {
  display: inline-block;
  position: relative;
  padding-left: 25px;
  margin: 2px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #eee;
  border-radius: 50%;
  border: 1px solid #ccc;
}

/* On mouse-over, add a grey background color */
.radio-container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.radio-container input:checked ~ .checkmark {
  background-color: #2196F3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-container input:checked ~ .checkmark:after {
  display: inline-block;
}

/* Style the indicator (dot/circle) */
.radio-container .checkmark:after {
  top: 4px;
  left: 4px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}
/* End Radio Button */
/* ===== End Toggles ===== */

/* ===== Start Placeholders in search filters ===== */
.searchformfld{
  position: relative;
  margin: 5px 0px;
}
.searchformfld label{
  position: absolute;
  padding-left: 10px;
  top:15px;
  cursor: text;

}
.searchformfld input:focus + label,.searchformfld input:not(:placeholder-shown) + label{
  opacity:1;
  transform: scale(.9) translateY(-100%) translateX(-10px);
  color:#000;
}
.searchformfld input:focus{
  border:1px solid #000;
  outline-color: #000;
}
.searchformfld{
  padding: 15px;
  margin:15px 0px;
}
.searchformfld input{
  width:100%;
  padding-left: 10px;
}
.searchformfld label,.searchformfld input{
  transition: all 0.2s;
  transition-timing-function: ease;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  opacity:0.5;
}

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
  .search-component {}
  .search-component a {font-size: 18px;}
}
@media screen and (max-width: 575px) {
  .dropdown-menu-date {
    left: 0 !important;
  }
  .search-component .mapboxgl-ctrl-geocoder {
    border-radius: .25rem .25rem 0 0 !important;
  }
  .search-component .border-radius-r-5 {
    border-radius: 0 0 .25rem .25rem !important;
  }
  .search-component .search-filter-btns:focus {
    border-radius: 2px !important;
  }
  .border-lr {
    border: none !important;
    border-left: none !important;
    border-right: none !important;
  }
  .border-left {
    border: none !important;
    border-left: none !important;
  }
  .border-right {
    border: none !important;
    border-right: none !important;
  }
  .search-component {
    width: 90vw;
  }
}
@media screen and (min-width: 576px) {
  .dropdown-menu-date {
    left: -35vw !important;
  }
  .border-none {
    border: none !important;
  }
  .border-lr {
    border: none !important;
    border-left: 1px solid transparent !important;
    border-right: 1px solid transparent !important;
  }
  .border-left {
    border: none !important;
    border-left: 1px solid transparent !important;
  }
  .border-right {
    border: none !important;
    border-right: 1px solid transparent !important;
  }
  .search-component {
    width: 90vw;
  }
}
@media screen and (min-width: 768px) {
  .dropdown-menu-date {
    left: -25vw !important;
  }
  .border-none {
    border: none !important;
  }
  .border-lr {
    border: none !important;
    border-left: 1px solid transparent !important;
    border-right: 1px solid transparent !important;
  }
  .border-left {
    border: none !important;
    border-left: 1px solid transparent !important;
  }
  .border-right {
    border: none !important;
    border-right: 1px solid transparent !important;
  }
  .search-component {
    width: 90vw;
  }
}
@media screen and (min-width: 992px) {
  .dropdown-menu-date {
    left: -15vw !important;
  }
  .border-none {
    border: none !important;
  }
  .border-lr {
    border: none !important;
    border-left: 1px solid transparent !important;
    border-right: 1px solid transparent !important;
  }
  .border-left {
    border: none !important;
    border-left: 1px solid transparent !important;
  }
  .border-right {
    border: none !important;
    border-right: 1px solid transparent !important;
  }
  .search-component {
    width: 60vw;
  }
}
@media screen and (min-width: 1200px) {
  .border-none {
    border: none !important;
  }
  .border-lr {
    border: none !important;
    border-left: 1px solid transparent !important;
    border-right: 1px solid transparent !important;
  }
  .border-left {
    border: none !important;
    border-left: 1px solid transparent !important;
  }
  .border-right {
    border: none !important;
    border-right: 1px solid transparent !important;
  }
  .search-component {
    width: 60vw;
  }
}
@media screen and (min-width: 1920px) {
  .border-none {
    border: none !important;
  }
  .border-lr {
    border: none !important;
    border-left: 1px solid transparent !important;
    border-right: 1px solid transparent !important;
  }
  .border-left {
    border: none !important;
    border-left: 1px solid transparent !important;
  }
  .border-right {
    border: none !important;
    border-right: 1px solid transparent !important;
  }
  .search-component {
    width: 60vw;
  }
}

</style>
