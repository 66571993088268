<template>
  <div id="app" class="scroll-width-thin">
<!--  <input onmousemove="this.value=event.clientX+':'+event.clientY" value="Mouse over me">-->
      <Nav ref="nav" @refreshProps="getProps"/>
      <div id="mainContent" class="main-content pt-2"> <!-- mt-5 -->
  <!--      <SearchFilters/>-->
  <!--      <Chat/>-->
        <div class="pt-5"> <!-- mt-5 pt-2 -->
  <!--        <Map v-show="this.$route.name === 'map'"/>-->
          <router-view @refreshProps="getProps" v-if="is_mounted"/>
        </div>
        <div class="video-parallax">
          <!-- Add poster="adress_url_to_image.jpg" to <video> tag to have backfall image -->
          <video id="backgroundVidParallax" style="pointer-events: none;" preload="auto" autoplay loop muted>
<!--            <source :src="require(`@/assets/videos/City.mp4`)" @error="setDefaultImg" type="video/mp4" />-->
<!--            <source :src="require(`@/assets/videos/CityPurple.mp4`)" @error="setDefaultImg" type="video/mp4" />-->
            <source :src="require(`@/assets/videos/Cloud.mp4`)" type="video/mp4" />
<!--            <source :src="require(`@/assets/videos/Commercial.mp4`)" @error="setDefaultImg" type="video/mp4" />--> <!-- FAJNE v.noc ale nocne (swiatla) -->


<!--            <source :src="require(`@/assets/videos/La.mp4`)" @error="setDefaultImg" type="video/mp4" />-->
<!--            <source :src="require(`@/assets/videos/London.mp4`)" @error="setDefaultImg" type="video/mp4" />-->
<!--            <source :src="require(`@/assets/videos/Miami.mp4`)" @error="setDefaultImg" type="video/mp4" />-->
<!--            <source :src="require(`@/assets/videos/Skyscrapers.mp4`)" @error="setDefaultImg" type="video/mp4" />-->
<!--            <source :src="require(`@/assets/videos/Water.mp4`)" @error="setDefaultImg" type="video/mp4" /> &lt;!&ndash; FAJNE v.dzien &ndash;&gt;-->
          </video>
        </div>
      </div>
      <div class="container-for-video-parallax">
        <!-- Can be left empty, just give it min-height -->
      </div>
      <AdSense></AdSense>
      <Bookmarks/>
      <CookieConsent v-if="!getCookieConsent" @cookieChanged="toggleCookieChanged"/>
      <Footer/>
      <!-- Start Modals -->
      <AddRoomModal ref="addRoomModal" :formData="addRoomModalData" :refresh="refreshAddRoomModal"/>
      <Booking ref="bookingModal" class="booking-modal" :adDetails="bookingModalData.adDetails" :toUserId="bookingModalData.toUserId" :adId="bookingModalData.adId" :adType="bookingModalData.adType" :booking="bookingModalData.booking" :refresh="refreshBookingModal"/>
      <ChatModal ref="chatModal" class="chat-modal" :toUserId="chatModalData.toUserId" :fromUserId="chatModalData.fromUserId" :adId="chatModalData.adId" :adType=chatModalData.adType :adData="chatModalData" :message="chatModalData.message" :refresh="refreshChatModal"></ChatModal>
      <ConfirmationModal ref="confirmationModal" :data="confirmationModalData" :refresh="refreshConfirmationModal"></ConfirmationModal>
      <RaiseIssueModal ref="raiseIssueModal" class="raise-issue-modal" :bookingId="raiseIssueModalData.bookingId" :adId="raiseIssueModalData.adId" :adType=raiseIssueModalData.adType :adData="raiseIssueModalData" :options="raiseIssueModalData.options" :message="raiseIssueModalData.message" :refresh="refreshRaiseIssueModal"></RaiseIssueModal>
      <ReviewsModal ref="reviewsModal" class="reviews-modal" :adId="reviewsModalData.adId" :adDetails="reviewsModalData.adDetails" :reviewType="reviewsModalData.reviewType" :toUserId="reviewsModalData.toUserId" :refresh="refreshReviewsModal"></ReviewsModal>
      <AddReviewModal ref="addReviewModal" class="add-review-modal" :adId="addReviewModalData.adId" :adDetails="addReviewModalData.adDetails" :reviewType="addReviewModalData.reviewType" :adType="addReviewModalData.adType" :toUserId="addReviewModalData.toUserId" :fromUserId="addReviewModalData.fromUserId" :refresh="refreshAddReviewModal"></AddReviewModal>
      <FormDraftModal ref="formDraftModal" class="form-draft-modal" :formData="formDraftModalData.formData" :drafts="formDraftModalData.drafts" :type="formDraftModalData.type" :userId="formDraftModalData.userId" :refresh="refreshFormDraftModal"></FormDraftModal>
    <!-- End Modals class="booking-modal"  -->
  </div>
</template>

<script>
import {mapActions} from 'vuex';
import Nav from './components/Nav';
import Map from './components/Map';
import SearchFilters from './components/SearchFilters';
import Chat from './components/Chat';
import Footer from './components/Footer';
import Bookmarks from './components/Bookmarks';
import CookieConsent from './components/CookieConsent';
import AdSense from './components/AdSense';
import ValidationErrors from './components/ValidationErrors';
// Start Modals
import AddRoomModal from './components/AddRoomModal';
import Booking from './components/Bookings/Booking';
import ChatModal from './components/ChatModal';
import ConfirmationModal from './components/ConfirmationModal/ConfirmationModal';
import RaiseIssueModal from './components/RaiseIssueModal';
import ReviewsModal from './components/Reviews/ReviewsModal';
import AddReviewModal from './components/Reviews/AddReviewModal';
import FormDraftModal from './components/FormDrafts/FormDraftModal';
// End Modals
import axios from 'axios';

export default {
  name:"App",
  components: {
    'Nav': Nav,
    'Map': Map,
    'SearchFilters': SearchFilters,
    'Chat': Chat,
    'Bookmarks': Bookmarks,
    'Footer': Footer,
    'CookieConsent': CookieConsent,
    'AdSense': AdSense,
    'ValidationErrors': ValidationErrors,
    // Start Modals
    'AddRoomModal': AddRoomModal,
    'Booking': Booking,
    'ChatModal': ChatModal,
    'ConfirmationModal': ConfirmationModal,
    'RaiseIssueModal': RaiseIssueModal,
    'ReviewsModal': ReviewsModal,
    'AddReviewModal': AddReviewModal,
    'FormDraftModal': FormDraftModal
    // End Modals
  },
  data() {
    return {
      cookieChanged: false,
      is_mounted: false,
      first_property_search: false,
      user:this.$store.state.auth.user,
      location: this.$store.state.location,
      // Add Room Modal
      addRoomModalData: '',
      refreshAddRoomModal: false,
      // Booking Modal
      bookingModalData: '',
      refreshBookingModal: false,
      // Chat Modal
      chatModalData: '',
      refreshChatModal: false,
      // Raise Issue Modal
      raiseIssueModalData: '',
      refreshRaiseIssueModal: false,
      // Confirmation Modal
      confirmationModalData: '',
      refreshConfirmationModal: false,
      // Reviews Modal
      reviewsModalData: '',
      refreshReviewsModal: false,
      // AddReview Modal
      addReviewModalData: '',
      refreshAddReviewModal: false,
      // Form Draft Modal
      formDraftModalData: '',
      refreshFormDraftModal: false,
      // /* **** ORIGINAL DATA WHEN WE AUTOMATED THIS, HOWEVER FOR NOW I HARDCODED STUFF **** */
      // // Property Category / Type / Details
      // /* Main Level Dropdown - Holds property categories */
      // propertyCategoriesOptions: [],
      // propertyTypesOptions: [],
      // /* First Level Dropdown - Holds property types according to property categories - skipped room as no subitems */
      // propResidentialTypesOptions: [],
      // propCommercialTypesOptions: [],
      // propIndustrialTypesOptions: [],
      // propLandTypesOptions: [],
      // propLuxuryTypesOptions: [],
      // propSpecialUseTypesOptions: [],
      // /* Second Level Dropdown - Holds property type details according to property types */
      // // Residential
      // propHouseTypeDetails: [],
      // propFlatTypeDetails: [],
      // // Commercial
      // propOfficeTypeDetails: [],
      // propRetailTypeDetails: [],
      // propLeisureTypeDetails: [],
      // propHealthcareTypeDetails: [],
      // propParkingTypeDetails: [],
      // propMultifamilyTypeDetails: [],
      // // Industrial (we skipped Warehouse and Factory as no subcategories)
      // propPowerPlantTypeDetails: []

      // Property Category / Type / Details
      /* Main Level Dropdown - Holds property categories */
      propertyCategoriesOptions: [
        {
          id: 1,
          type: 'Residential'
        },
        {
          id: 2,
          type: 'Commercial'
        },
        {
          id: 3,
          type: 'Industrial'
        },
        {
          id: 4,
          type: 'Land'
        },
        {
          id: 5,
          type: 'Luxury'
        },
        {
          id: 6,
          type: 'Special Use'
        },
        {
          id: 7,
          type: 'Room'
        }
      ],
      propertyTypesOptions: [
        // Residential id 1
        {
          id: 1,
          property_category_id: 1,
          type: 'House'
        },
        {
          id: 2,
          property_category_id: 1,
          type: 'Flat'
        },
        // Commercial id 2
        {
          id: 3,
          property_category_id: 2,
          type: 'Office'
        },
        {
          id: 4,
          property_category_id: 2,
          type: 'Retail'
        },
        {
          id: 5,
          property_category_id: 2,
          type: 'Leisure'
        },
        {
          id: 6,
          property_category_id: 2,
          type: 'Healthcare'
        },
        {
          id: 7,
          property_category_id: 2,
          type: 'Garage/Parking'
        },
        {
          id: 8,
          property_category_id: 2,
          type: 'Multifamily'
        },
        // Industrial id 3
        {
          id: 9,
          property_category_id: 3,
          type: 'Warehouse'
        },
        {
          id: 10,
          property_category_id: 3,
          type: 'Factory'
        },
        {
          id: 11,
          property_category_id: 3,
          type: 'Power Plants'
        },
        // Land id 4
        {
          id: 12,
          property_category_id: 4,
          type: 'Greenfield'
        },
        {
          id: 13,
          property_category_id: 4,
          type: 'Brownfield'
        },
        // Luxury id 5
        {
          id: 14,
          property_category_id: 5,
          type: 'Mansion'
        },
        {
          id: 15,
          property_category_id: 5,
          type: 'Villa'
        },
        // Special Use id 6
        {
          id: 16,
          property_category_id: 6,
          type: 'Church'
        },
        {
          id: 17,
          property_category_id: 6,
          type: 'Cemetery'
        },
        {
          id: 18,
          property_category_id: 6,
          type: 'Park'
        },
        {
          id: 19,
          property_category_id: 6,
          type: 'School'
        },
        {
          id: 20,
          property_category_id: 6,
          type: 'Barn'
        },
        {
          id: 21,
          property_category_id: 6,
          type: 'Campsite'
        }
      ],
      /* First Level Dropdown - Holds property types according to property categories - skipped room as no subitems */
      propResidentialTypesOptions: [
        {
          id: 1,
          property_category_id: 1,
          type: 'House'
        },
        {
          id: 2,
          property_category_id: 1,
          type: 'Flat'
        }
      ],
      propCommercialTypesOptions: [
        {
          id: 3,
          property_category_id: 2,
          type: 'Office'
        },
        {
          id: 4,
          property_category_id: 2,
          type: 'Retail'
        },
        {
          id: 5,
          property_category_id: 2,
          type: 'Leisure'
        },
        {
          id: 6,
          property_category_id: 2,
          type: 'Healthcare'
        },
        {
          id: 7,
          property_category_id: 2,
          type: 'Garage/Parking'
        },
        {
          id: 8,
          property_category_id: 2,
          type: 'Multifamily'
        }
      ],
      propIndustrialTypesOptions: [
        {
          id: 9,
          property_category_id: 3,
          type: 'Warehouse'
        },
        {
          id: 10,
          property_category_id: 3,
          type: 'Factory'
        },
        {
          id: 11,
          property_category_id: 3,
          type: 'Power Plants'
        }
      ],
      propLandTypesOptions: [
        {
          id: 12,
          property_category_id: 4,
          type: 'Greenfield'
        },
        {
          id: 13,
          property_category_id: 4,
          type: 'Brownfield'
        }
      ],
      propLuxuryTypesOptions: [
        {
          id: 14,
          property_category_id: 5,
          type: 'Mansion'
        },
        {
          id: 15,
          property_category_id: 5,
          type: 'Villa'
        }
      ],
      propSpecialUseTypesOptions: [
        {
          id: 16,
          property_category_id: 6,
          type: 'Church'
        },
        {
          id: 17,
          property_category_id: 6,
          type: 'Cemetery'
        },
        {
          id: 18,
          property_category_id: 6,
          type: 'Park'
        },
        {
          id: 19,
          property_category_id: 6,
          type: 'School'
        },
        {
          id: 20,
          property_category_id: 6,
          type: 'Barn'
        },
        {
          id: 21,
          property_category_id: 6,
          type: 'Campsite'
        }
      ],
      /* Second Level Dropdown - Holds property type details according to property types */
      // Residential
      propHouseTypeDetails: [
        {
          id: 1,
          property_type_id: 1,
          type_detail: 'Detached'
        },
        {
          id: 2,
          property_type_id: 1,
          type_detail: 'Semi-Detached'
        },
        {
          id: 3,
          property_type_id: 1,
          type_detail: 'Mid-Terraced'
        },
        {
          id: 4,
          property_type_id: 1,
          type_detail: 'End-Terraced'
        },
        {
          id: 5,
          property_type_id: 1,
          type_detail: 'Detached Bungalow'
        },
        {
          id: 6,
          property_type_id: 1,
          type_detail: 'Semi-Detached Bungalow'
        },
        {
          id: 7,
          property_type_id: 1,
          type_detail: 'Cottage'
        }
      ],
      propFlatTypeDetails: [
        {
          id: 8,
          property_type_id: 2,
          type_detail: 'Studio Flat'
        },
        {
          id: 9,
          property_type_id: 2,
          type_detail: 'Flat'
        },
        {
          id: 10,
          property_type_id: 2,
          type_detail: 'Maisonette'
        }
      ],
      // Commercial
      propOfficeTypeDetails: [
        {
          id: 11,
          property_type_id: 3,
          type_detail: 'Traditional Office'
        },
        {
          id: 12,
          property_type_id: 3,
          type_detail: 'Serviced Office'
        },
        {
          id: 13,
          property_type_id: 3,
          type_detail: 'Managed Office'
        }
      ],
      propRetailTypeDetails: [
        {
          id: 14,
          property_type_id: 4,
          type_detail: 'Shop'
        },
        {
          id: 15,
          property_type_id: 4,
          type_detail: 'Shopping Mall'
        },
        {
          id: 16,
          property_type_id: 4,
          type_detail: 'Retail Store'
        }
      ],
      propLeisureTypeDetails: [
        {
          id: 17,
          property_type_id: 5,
          type_detail: 'Restaurant / Cafe'
        },
        {
          id: 18,
          property_type_id: 5,
          type_detail: 'Takeaway'
        },
        {
          id: 19,
          property_type_id: 5,
          type_detail: 'Pub'
        },
        {
          id: 20,
          property_type_id: 5,
          type_detail: 'Entertainment'
        },
        {
          id: 21,
          property_type_id: 5,
          type_detail: 'Sport / Healthcare Facility'
        },
        {
          id: 22,
          property_type_id: 5,
          type_detail: 'Hotel / Tourism'
        }
      ],
      propHealthcareTypeDetails: [
        {
          id: 23,
          property_type_id: 6,
          type_detail: 'Pharmacy'
        },
        {
          id: 24,
          property_type_id: 6,
          type_detail: 'Medical Center'
        },
        {
          id: 25,
          property_type_id: 6,
          type_detail: 'Nursing Home'
        },
        {
          id: 26,
          property_type_id: 6,
          type_detail: 'Hospital'
        }
      ],
      propParkingTypeDetails: [
        {
          id: 27,
          property_type_id: 7,
          type_detail: 'Parking Space'
        },
        {
          id: 28,
          property_type_id: 7,
          type_detail: 'Single Garage'
        },
        {
          id: 29,
          property_type_id: 7,
          type_detail: 'Multiple Garages'
        },
        {
          id: 30,
          property_type_id: 7,
          type_detail: 'Parking Lot'
        },
        {
          id: 31,
          property_type_id: 7,
          type_detail: 'Multi-storey Parking'
        }
      ],
      propMultifamilyTypeDetails: [
        {
          id: 32,
          property_type_id: 8,
          type_detail: 'Duplex'
        },
        {
          id: 33,
          property_type_id: 8,
          type_detail: 'Block of Flats'
        },
        {
          id: 34,
          property_type_id: 8,
          type_detail: 'Apartment Complex'
        }
      ],
      // Industrial (we skipped Warehouse and Factory as no subcategories)
      propPowerPlantTypeDetails: [
        {
          id: 35,
          property_type_id: 11,
          type_detail: 'Hydroelectric Plant'
        },
        {
          id: 36,
          property_type_id: 11,
          type_detail: 'Coal-fired Plant'
        },
        {
          id: 37,
          property_type_id: 11,
          type_detail: 'Diesel-fired Plant'
        },
        {
          id: 38,
          property_type_id: 11,
          type_detail: 'Geothermal Plant'
        },
        {
          id: 39,
          property_type_id: 11,
          type_detail: 'Gas-fired Plant'
        },
        {
          id: 40,
          property_type_id: 11,
          type_detail: 'Solar Power Plant'
        },
        {
          id: 41,
          property_type_id: 11,
          type_detail: 'Wind Power Plant'
        },
        {
          id: 42,
          property_type_id: 11,
          type_detail: 'Tidal Power Plant'
        }
      ]
    }
  },
  async created() {
    // each call needs csrf token to work, so we call this on app load once.
    // await axios.get('/sanctum/csrf-cookie').catch(() => {
    //   alert('Something went wrong, Contact admin <br> ErrorCode: csrf')
    // })

    // Create global events
    this.$root.$on('globalRefreshUser', () => this.globalRefreshUser());
    this.$root.$on('openModal', (data, type, fnName = null, passData = null, callback = null) => this.openModal(data, type, fnName, passData, callback));
    this.$root.$on('refreshModal', (modalType) => this.refreshModal(modalType));

    // If we get error 401 which is unauthenticated we logout and redirect to login
    axios.interceptors.response.use(
        response => {
          return response
        },
        error => {
          switch (error.response.status) {
            case 401: // Not logged in
              // Logout, remove sessionStorage('auth') and redirect to login page
              this.signOut();
              break;
            case 419: // Session expired
            case 503: // Down for maintenance
              // Logout, remove sessionStorage('auth') and redirect to login page
              this.signOut();
              this.$router.push({name: "login"}).catch(()=>{});
              break;
            case 500:
              alert('Oops, something went wrong! The team have been notified.');
              break;
            default:
              // Allow individual requests to handle other errors
              return Promise.reject(error);
          }
        }
    )
  },
  beforeDestroy() {
    // Must destroy global trigger before we leave the page/component, otherwise it will layer on each other
    // and when we call it, it will trigger several times, breaking functionality
    this.$root.$off('globalRefreshUser');
    this.$root.$off('openModal');
    this.$root.$off('refreshModal');
  },
  async mounted() {
    // each call needs csrf token to work, so we call this on app load once.
    await axios.get('/sanctum/csrf-cookie').catch(() => {
      alert('Something went wrong, Contact admin <br> ErrorCode: csrf')
    })

    // get search filters if present
    let searchFilters = null;
    if (this.$store.state.searchFilters.searchFilters !== false) {
      searchFilters = this.$store.state.searchFilters.searchFilters;
    }
    await this.getProps(searchFilters);
    this.is_mounted = true;

    // Get property categories, types and details into state if not present already
    if(!this.$store.state.properties.property_categories_options ||
       !this.$store.state.properties.property_types_options ||
       !this.$store.state.properties.prop_house_type_details
    ) {
      await this.setPropCategories();
      // Wait until we get prop categories and until they are assigned to propertyCategoriesOptions
      // await this.getAllPropertyCategories();

      // Loop through all categories and assign property types accordingly
      // for (const [key, value] of Object.entries(this.propertyCategoriesOptions)) {
      //   await this.assignPropertyTypes(value);
      // }
      // Wait until we get prop types and until they are assigned to propertyTypesOptions
      // await this.getAllPropertyTypes();
      // Loop through all property types and assign property type details accordingly
      // for (const [key, value] of Object.entries(this.propertyTypesOptions)) {
      //   await this.assignPropertyTypeDetails(value);
      // }
    }
  },
  computed: {
    getCookieConsent() {
      // Used to re-compute cookie settings
      let cookieChanged = this.cookieChanged;
      // Get cookie from session, if exists means it was declined
      let cookie = sessionStorage.getItem("cookie-consent");
      if (cookie) {
        return true;
      } else {
        // Else we get cookie from localStorage which means it was accepted (if it does not exists it will return null)
        return localStorage.getItem("cookie-consent");
      }
    }
  },
  methods: {
    toggleCookieChanged() {
      this.cookieChanged = !this.cookieChanged;
    },
    setDefaultImg(e) {
      e.target.src = require(`@/assets/no-image-found.png`);
    },
    async refreshModal(modalType) {
      switch (modalType) {
        case 'addRoomModal':
          this.refreshAddRoomModal = !this.refreshAddRoomModal;
          break;
        case 'bookingModal':
          this.refreshBookingModal = !this.refreshBookingModal;
          break;
        case 'chatModal':
          this.refreshChatModal = !this.refreshChatModal;
          break;
        case 'confirmationModal':
          this.refreshRaiseIssueModal = !this.refreshRaiseIssueModal;
          break;
        case 'raiseIssueModal':
          this.refreshConfirmationModal = !this.refreshConfirmationModal;
          break;
        case 'reviewsModal':
          this.refreshReviewsModal = !this.refreshReviewsModal;
          break;
        case 'addReviewModal':
          this.refreshAddReviewModal = !this.refreshAddReviewModal;
          break;
        case 'formDraftModal':
          this.refreshFormDraftModal = !this.refreshFormDraftModal;
          break;
      }
    },
    async openModal(modalData, modalType, fnName = null, passData = null, callback = null) {
      console.log('global modal opened')
      if (modalType === 'Add Room') {
        this.addRoomModalData = '';
        this.addRoomModalData = {...modalData};
      }
      if (modalType === 'Booking') {
        this.bookingModalData = '';
        this.bookingModalData = {...modalData};
      }
      if (modalType === 'Chat') {
        this.chatModalData = '';
        this.chatModalData = {...modalData};
      }
      if (modalType === 'Raise Issue') {
        // Note: modalData can contain options, this parameter must have options.title and options.options['Option1', 'Option2']
        this.raiseIssueModalData = '';
        this.raiseIssueModalData = {...modalData};
      }
      if (modalType === 'Confirmation') {
        this.confirmationModalData = '';
        this.confirmationModalData = {...modalData};

        const ok = await this.$refs.confirmationModal.show(
            {
              title: this.confirmationModalData.title,
              message: this.confirmationModalData.message,
              okButton: this.confirmationModalData.okButton ? this.confirmationModalData.okButton : 'Confirm',
              cancelButton: this.confirmationModalData.cancelButton ? this.confirmationModalData.cancelButton : 'Go Back',
              allowCustomMsg: this.confirmationModalData.allowCustomMsg ? this.confirmationModalData.allowCustomMsg : false
            }
        );
        // If you throw an error, the method will terminate here unless you surround it with try/catch
        if (ok) {
          passData['customMsg'] = ok;
          callback(fnName, true, passData);
          // return true;
        } else {
          callback(fnName, false, passData);
          return false;
        }
      }
      if (modalType === 'Reviews') {
        this.reviewsModalData = '';
        this.reviewsModalData = {...modalData};
      }
      if (modalType === 'Add Review') {
        this.addReviewModalData = '';
        this.addReviewModalData = {...modalData};
      }
      if (modalType === 'Form Draft') {
        this.formDraftModalData = '';
        this.formDraftModalData = {...modalData};
      }
    },
    ...mapActions({
      refreshUser:"auth/refreshUser"
    }),
    globalRefreshUser() {
      this.refreshUser();
    },
    ...mapActions({
      signOut:"auth/logout"
    }),
    async logout(){
      await axios.post('api/logout').then(({data})=>{
        this.signOut()
        this.$router.push({name: "login"}).catch(()=>{});
      })
    },
    ...mapActions({
      getProperties:'properties/getProperties',
      getPropertiesMap:'properties/getPropertiesMap'
    }),
    async getProps(filters = null) {
      this.processing = true
      let payload = null;
      if (filters === null) {
        payload = {
          is_let: 1,
          is_sale: 1,
          // is_buy: 1,
          // is_rent: 1
          // Initial Address is London
          address: 'London, Greater London, England, United Kingdom',
          line_1: '',
          line_2: '',
          street: '',
          postcode: '',
          city: 'London',
          county: 'Greater London',
          longitude: '-0.12764739999999997',
          latitude: '51.507321899999994',
          ad_type: 6
        };
      } else {
        payload = filters;
      }
      // Check if ad type is set, if not make sure to set let and sale
      if (payload.is_let !== 1 && payload.is_rent !== 1 && payload.is_sale !== 1 && payload.is_buy !== 1) {
        payload.is_let = 1;
        payload.is_sale = 1;
        payload.ad_type = 6;
        payload.address = 'London, Greater London, England, United Kingdom';
        payload.line_1 = '';
        payload.line_2 = '';
        payload.street = '';
        payload.postcode = '';
        payload.city = 'London';
        payload.county = 'Greater London';
        payload.longitude = '-0.12764739999999997';
        payload.latitude = '51.507321899999994';
      }

      // Check if its first loading of the app to prevent unnecessary error messages
      if (this.first_property_search) {
        this.first_property_search = false;
      } else {
        await this.getProperties(payload);
        await this.getPropertiesMap(payload);
      }
      this.processing = false
    },
    ...mapActions({
      setLocation:'location/setLocation'
    }),
    ...mapActions({
      setPropertyCategories:'properties/setPropertyCategories',
    }),
    async setPropCategories() {
      let payload = {
        propertyCategoriesOptions: this.propertyCategoriesOptions,
        propertyTypesOptions: this.propertyTypesOptions,
        /* First Level Dropdown - Holds property types according to property categories - skipped room as no subitems */
        propResidentialTypesOptions: this.propResidentialTypesOptions,
        propCommercialTypesOptions: this.propCommercialTypesOptions,
        propIndustrialTypesOptions: this.propIndustrialTypesOptions,
        propLandTypesOptions: this.propLandTypesOptions,
        propLuxuryTypesOptions: this.propLuxuryTypesOptions,
        propSpecialUseTypesOptions: this.propSpecialUseTypesOptions,
        /* Second Level Dropdown - Holds property type details according to property types */
        // Residential
        propHouseTypeDetails: this.propHouseTypeDetails,
        propFlatTypeDetails: this.propFlatTypeDetails,
        // Commercial
        propOfficeTypeDetails: this.propOfficeTypeDetails,
        propRetailTypeDetails: this.propRetailTypeDetails,
        propLeisureTypeDetails: this.propLeisureTypeDetails,
        propHealthcareTypeDetails: this.propHealthcareTypeDetails,
        propParkingTypeDetails: this.propParkingTypeDetails,
        propMultifamilyTypeDetails: this.propMultifamilyTypeDetails,
        // Industrial (we skipped Warehouse and Factory as no subcategories)
        propPowerPlantTypeDetails: this.propPowerPlantTypeDetails,
      };
      await this.setPropertyCategories(payload);
    },
    // async setLoc(bool) {
    //   this.processing = true
    //   await this.setLocation(bool)
    //   this.processing = false
    // },
    // ...mapActions({
    //   getMap:'map/getMap',
    // }),
    // async getMapp(map) {
    //   this.processing = true
    //   await this.getMap(map)
    //   this.processing = false
    // }

    // Get property categories and add them to state
    getAllPropertyCategories: async function() {
      let headers = {
        'Retry-After': 3
      }
      return axios.post('api/property-categories', [], {headers: headers}).then(({data}) => {
        this.propertyCategoriesOptions = data;
      })
    },
    getAllPropertyTypes: async function() {
      let headers = {
        'Retry-After': 3
      }
      return axios.post('api/property-types',[],{headers: headers}).then(({data})=>{
        // console.log('all property types');
        // console.log(data);
        this.propertyTypesOptions = data;
      })
    },
    getPropertyTypes(variable, index) {
      let headers = {
        'Retry-After': 3
      }
      axios.post('api/property-types', index, {headers: headers}).then(({data})=>{
        // console.log('specific property types');
        // console.log(data);
        // Holds name of property name to which we want to assign data
        let propName = variable;
        this[propName] = data;
      })
    },
    getPropertyTypeDetails(variable, index) {
      let headers = {
        'Retry-After': 3
      }
      axios.post('api/property-type-details', index, {headers: headers}).then(({data})=>{
        // Holds name of property name to which we want to assign data
        let propName = variable;
        this[propName] = data;
      })
    },
    /*
      propTypes = Property Types derived from getPropertyTypes()
     */
    async assignPropertyTypes(propTypes) {
      // Residential
      if(propTypes.id === 1) {
        this.getPropertyTypes('propResidentialTypesOptions', propTypes.id);
      }
      // Commercial
      if(propTypes.id === 2) {
        this.getPropertyTypes('propCommercialTypesOptions', propTypes.id);
      }
      // Industrial
      if(propTypes.id === 3) {
        this.getPropertyTypes('propIndustrialTypesOptions', propTypes.id);
      }
      // Land
      if(propTypes.id === 4) {
        this.getPropertyTypes('propLandTypesOptions', propTypes.id);
      }
      // Luxury
      if(propTypes.id === 5) {
        this.getPropertyTypes('propLuxuryTypesOptions', propTypes.id);
      }
      // Special Use
      if(propTypes.id === 6) {
        this.getPropertyTypes('propSpecialUseTypesOptions', propTypes.id);
      }
    },
    /*
      propTypeDetails = Property Type Details derived from getPropertyTypeDetails()
     */
    async assignPropertyTypeDetails(propTypeDetails) {
      // House
      if(propTypeDetails.id === 1) {
        this.getPropertyTypeDetails('propHouseTypeDetails', propTypeDetails.id);
      }
      // Flat
      if(propTypeDetails.id === 2) {
        this.getPropertyTypeDetails('propFlatTypeDetails', propTypeDetails.id);
      }
      // Office
      if(propTypeDetails.id === 3) {
        this.getPropertyTypeDetails('propOfficeTypeDetails', propTypeDetails.id);
      }
      // Retail
      if(propTypeDetails.id === 4) {
        this.getPropertyTypeDetails('propRetailTypeDetails', propTypeDetails.id);
      }
      // Leisure
      if(propTypeDetails.id === 5) {
        this.getPropertyTypeDetails('propLeisureTypeDetails', propTypeDetails.id);
      }
      // Healthcare
      if(propTypeDetails.id === 6) {
        this.getPropertyTypeDetails('propHealthcareTypeDetails', propTypeDetails.id);
      }
      // Parking
      if(propTypeDetails.id === 7) {
        this.getPropertyTypeDetails('propParkingTypeDetails', propTypeDetails.id);
      }
      // Multifamily
      if(propTypeDetails.id === 8) {
        this.getPropertyTypeDetails('propMultifamilyTypeDetails', propTypeDetails.id);
      }
      // Power Plant
      if(propTypeDetails.id === 11) {
        this.getPropertyTypeDetails('propPowerPlantTypeDetails', propTypeDetails.id);
      }
    },
  },
  watch: {
    // We watch when last prop type details are assigned so we can trigger function responsible for adding all that data to state (Vuex)
    'propPowerPlantTypeDetails': function (val) {
      if (val) {
        this.setPropCategories();
      }
    },
    // // Watch user if its session expired
    // '$store.state.auth': function () {
    //   if (this.$store.state.auth && this.$store.state.auth.expire) {
    //     if (new Date(this.$store.state.auth.expire) < new Date()) {
    //       sessionStorage.removeItem('auth');
    //     }
    //   }
    // }
  }
}
</script>

<style lang="scss">
// Background Video Parallax
.container-for-video-parallax {
  display: block;
  min-height: 400px;
  height: 400px;
  min-width: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0);
}

.video-parallax {
  padding: 0;
  margin: 0;
}
.video-parallax video {
  //padding-top: 100px;
  min-width: 100%;
  position: fixed;
  top:0;
  left: 0;
  z-index: -999;
}
//.video-parallax {
//
//  #backgroundVidParallax {
//    width: 100%;
//    height: auto;
//  }
//}


.adsbygoogle {
  //border: 1px solid red !important;
}

body {
  margin: 0;
}

* {
  //font-family: "Lucida Handwriting", cursive !important;
  //font-family: "Times New Roman",serif !important;
  //font-family: "Garamond", serif !important;
  font-family: "Verdana", sans-serif !important;
  //font-family: 'Open Sans', Roboto, sans-serif;
}

#app {
  //font-family: Avenir, Helvetica, Arial, sans-serif;
  //font-family: Mons, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.main-content {
  min-height: 610px; // 630px If we change that to different size there will be a gap between map and footer
  background-color: #f8f8fa;
  $border: 4px;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    z-index: -1;
    height: auto;
    /* !important */
    margin: 0 0 0 -$border; /* Only bottom border */
    background: linear-gradient(to left, rgba(128, 0, 128, 0.7), rgba(25, 181, 254, 0.7));
  }
}

// Overwrite main content height and position so we can have transparent bg
.main-content-transparent {
  min-height: 610px; // 630px If we change that to different size there will be a gap between map and footer
  background-color: transparent;
  $border: 4px;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    z-index: -1;
    height: 5px;
    /* !important */
    margin: 0 0 0 -$border; /* Only bottom border */
    background: linear-gradient(to left, rgba(128, 0, 128, 0.7), rgba(25, 181, 254, 0.7));
  }
}

.modal-tab {
  position: fixed;
  top: 9.8vh;
  z-index: 1000;
}

//
//.modal-filters {
//  position: absolute;
//  top: 50px;
//  right: 100px;
//  bottom: 0;
//  left: 0;
//  z-index: 10040;
//  overflow: auto;
//  overflow-y: auto;
//}

//.modal-content {
//  position: absolute;
//  top: 20px;
//  left: -34vw !important;
//  z-index: 1000;
//  overflow: auto;
//}

///* Style the links inside the sidenav */
//#leftSidenav a {
//  position: fixed; /* Position them relative to the browser window */
//  left: -23vw; /* Position them outside of the screen */
//  transition: 0.5s; /* Add transition on hover */
//  padding: 15px; /* 15px padding */
//  width: 25vw; /* Set a specific width */
//  text-decoration: none; /* Remove underline */
//  font-size: 20px; /* Increase font size */
//  color: white; /* White text color */
//  border-radius: 0 5px 5px 0; /* Rounded corners on the top right and bottom right side */
//  z-index: 1000;
//}
//
//#leftSidenav a:hover {
//  left: 0; /* On mouse-over, make the elements appear as they should */
//  height: 100vh;
//  transition: 1.2s;
//}
//
//#filters {
//  top: 50px;
//  background-color: #2196F3; /* Blue #2196F3 */
//}
//#filters:hover {
//  top: 50px;
//  background-color: rgba(33, 150, 243, 50%); /* Blue */
//}

///* Style the links inside the sidenav */
//#rightSidenav a {
//  position: fixed; /* Position them relative to the browser window */
//  right: -23vw; /* Position them outside of the screen */
//  transition: 0.5s; /* Add transition on hover */
//  padding: 15px; /* 15px padding */
//  width: 25vw; /* Set a specific width */
//  text-decoration: none; /* Remove underline */
//  font-size: 20px; /* Increase font size */
//  color: white; /* White text color */
//  border-radius: 5px 0 0 5px; /* Rounded corners on the top right and bottom right side */
//  z-index: 1000;
//}
//
//#rightSidenav a:hover {
//  right: 0; /* On mouse-over, make the elements appear as they should */
//  height: 100vh;
//  transition: 1.2s;
//}
//
//#chat {
//  top: 50px;
//  background-color: rgba(33, 150, 243, 50%); /* Blue #2196F3 */
//}
//#chat:hover {
//  top: 50px;
//  background-color: rgba(33, 150, 243, 50%); /* Blue */
//}

</style>

<!--Navbar zrob w samym CSS, bo pozniej mozesz potrzebować możliwości zmiany koloru (skórki), wtedy lepiej nadpisac istniejace-->
<!--kolory niz podmieniac obrazek czy svg...-->

<!--Zrób to tak, top div prostokąt z logiem w centrum,-->
<!--mid div spłaszcz, nadaj mu kształt przez border-radius etc.-->
<!--i użyj go jako extension trzeciego diva, mid div musi być w bottom divie 'fixed to top'-->
<!--bottom div ma byc prostokatem i zawierac mid div przypietego do gory oraz normalne elementy swoje menu-->
<!--Calosc mozesz spiac jednym calym duzym divem jako 'nav' nazwany, sprawdz jak to bedzie dzialac-->
<!--z collapsible i mobile nav-->



<!--<svg viewBox="0 0 1536 200" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path fill="rgba(47, 73, 94, 1)" d="M 0 0 C 311.6 0 467.4 110 779 110 L 779 110 L 779 0 L 0 0 Z" stroke-width="0"></path> <path fill="rgba(47, 73, 94, 1)" d="M 778 110 C 1081.2 110 1232.8 0 1536 0 L 1536 0 L 1536 0 L 778 0 Z" stroke-width="0"></path> </svg>-->