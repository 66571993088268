<template>
  <div class="footer mb-3">
      <footer class="text-center text-lg-start text-white" id="footer" style="background-color: #45526e">
        <div class="container p-4 pb-0">
          <!-- Section: Links -->
          <section class="">
            <div class="row">
              <div class="col-md-3 col-lg-3 col-xl-3 mx-auto mt-3">
                <h6 class="text-uppercase letter-spacing-2  mb-4 font-weight-bold footer-header">
                  Easetate
                </h6>
                <p>
                  Committed to deliver best technological solutions to Landlords, Tenants and Service Providers.
                </p>
                <div style="width: 180px; height: 40px; overflow: hidden; background-color: white; border-radius: 10px;">
                  <img src="@/assets/logo-easetate.png" alt="" width="100%" class="d-inline-block align-text-top img-fluid logo-name" />
                </div>
              </div>

              <hr class="w-100 clearfix d-md-none" />

              <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mt-3">
                <h6 class="text-uppercase letter-spacing-2 mb-4 font-weight-bold footer-header">Information</h6>
                <p>
                  <router-link class="text-decoration-none" to="/terms-and-conditions">
                    <a class="footer-link">Terms & Conditions</a>
                  </router-link>
                </p>
                <p>
                  <router-link class="text-decoration-none" to="/privacy-policy">
                    <a class="footer-link">Privacy Policy</a>
                  </router-link>
                </p>
                <p>
                  <router-link class="text-decoration-none" to="/cookie-policy">
                    <a class="footer-link">Cookie Policy</a>
                  </router-link>
                </p>
                <p>
                  <router-link class="text-decoration-none" to="/qna">
                    <a class="footer-link">Q&A</a>
                  </router-link>
                </p>
              </div>

              <hr class="w-100 clearfix d-md-none" />

              <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mt-3">
                <h6 class="text-uppercase letter-spacing-2  mb-4 font-weight-bold footer-header">
                  Actions
                </h6>
                <p>
                  <router-link class="text-decoration-none" to="/careers">
                    <a class="footer-link">Careers</a>
                  </router-link>
                </p>
                <p>
                  <router-link class="text-decoration-none" to="/about">
                    <a class="footer-link">About us</a>
                  </router-link>
                </p>
                <p>
                  <router-link class="text-decoration-none" to="/contact">
                    <a class="footer-link">Contact us</a>
                  </router-link>
                </p>
                <p>
                  <router-link class="text-decoration-none" to="/donate">
                    <a class="footer-link-color-donate"><font-awesome-icon icon="hand-holding-heart" class="icon-fa ms-1 me-1" /> Donate</a>
                  </router-link>
                </p>
              </div>

              <hr class="w-100 clearfix d-md-none" />

              <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mt-3">
                <h6 class="text-uppercase letter-spacing-2 mb-4 font-weight-bold footer-header">Contact</h6>
                <p><font-awesome-icon icon="home" /> United Kingdom</p>
                <p><a class="text-decoration-none footer-link" href="mailto:support@easetate.co.uk"><font-awesome-icon icon="envelope" /> support@easetate.co.uk</a></p>
                <p><a class="text-decoration-none footer-link" href="#"><font-awesome-icon icon="phone" /> Upcoming</a></p>
              </div>

            </div>
          </section>
          <!-- Section: Links -->

          <hr class="my-3">

          <!-- Section: Copyright -->
          <section class="p-3 pt-0">
            <div class="row d-flex align-items-center">
              <div class="col-md-7 col-lg-8 text-center text-md-start">
                <div class="p-3">
                  © 2022 - {{currentYear}} Copyright:
                  <router-link to="/" class="text-decoration-none">
                    <a class="text-white">Easetate</a>
                  </router-link>
                </div>
              </div>

              <div class="col-md-5 col-lg-4 ml-lg-0 text-center text-md-end">
                <ul class="list-unstyled">
                  <li class="d-inline-block position-relative">
                    <!-- Facebook Easetate -->
                    <span data-tooltip="tooltip" class="m-0 p-0">
                      <div class="position-relative">
                        <a class="footer-brand-link" href="https://www.facebook.com/Easetate" role="button"><font-awesome-icon :icon="{ prefix: 'fab', iconName: 'facebook' }" /></a>
                      </div>
                      <span class="tooltiptext-footer">Easetate Facebook</span>
                    </span>
                  </li>
                  <li class="d-inline-block position-relative">
                    <!-- LinkedIn Easetate -->
                    <span data-tooltip="tooltip" class="m-0 p-0">
                      <div class="position-relative">
                         <a class="footer-brand-link" href="https://www.linkedin.com/in/easetate-24745a264/" role="button"><font-awesome-icon :icon="{ prefix: 'fab', iconName: 'linkedin' }" /></a>
                      </div>
                      <span class="tooltiptext-footer">Easetate LinkedIn</span>
                    </span>
                  </li>
                  <li class="d-inline-block position-relative">
                    <!-- Facebook LnT -->
                    <span data-tooltip="tooltip" class="m-0 p-0">
                      <div class="position-relative">
                        <a class="footer-brand-link" href="https://www.facebook.com/groups/401449803559228/" role="button"><font-awesome-icon :icon="{ prefix: 'fab', iconName: 'facebook' }" /></a>
                      </div>
                      <span class="tooltiptext-footer">Landlords and Tenants Facebook</span>
                    </span>
                  </li>
                </ul>
<!--                &lt;!&ndash; LinkedIn Easetate &ndash;&gt;-->
<!--                <a class="footer-brand-link" href="https://www.linkedin.com/in/easetate-24745a264/" role="button"><font-awesome-icon :icon="{ prefix: 'fab', iconName: 'linkedin' }" /></a>-->
<!--                &lt;!&ndash; Facebook LnT &ndash;&gt;-->
<!--                <a class="footer-brand-link" href="https://www.facebook.com/groups/401449803559228/" role="button"><font-awesome-icon :icon="{ prefix: 'fab', iconName: 'facebook' }" /></a>-->
<!--                &lt;!&ndash; Twitter &ndash;&gt;-->
<!--                <a class="footer-brand-link" role="button"><font-awesome-icon :icon="{ prefix: 'fab', iconName: 'twitter' }" /></a>-->
<!--                &lt;!&ndash; Google &ndash;&gt;-->
<!--                <a class="footer-brand-link" role="button"><font-awesome-icon :icon="{ prefix: 'fab', iconName: 'google' }" /></a>-->
              </div>

            </div>
          </section>
          <!-- Section: Copyright -->
        </div>
      </footer>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  data () {
    return {
      currentYear: new Date().getFullYear()
    }
  }
}
</script>

<style lang="scss">

/* start tooltip - popups info on icons */
.tooltiptext-footer {
  visibility: hidden;
  width: max-content !important;
  width: -moz-max-content !important;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  text-align: center;
  font-size: 10px;
  border-radius: 6px;
  padding: 5px 5px;

  /* Position the tooltip */
  top: -40px;
  left: 0;
  position: absolute;
  z-index: 1000;
}

[data-tooltip]:hover .tooltiptext-footer {
  visibility: visible;
  color: #fff;
}

.footer {
  //background-color: #f8f8fa;
  $border: 4px;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    z-index: -1;
    /* !important */
    margin: 0 -$border 0 0; /* Only top border */
    background: linear-gradient(to left, rgba(128, 0, 128, 0.7), rgba(25, 181, 254, 0.7));
  }
}

.footer-header {
  text-shadow: 3px 2px #3c3c3c;
}
.footer-link {
  text-decoration: none;
  color: white;
  border: 1px solid rgba(0,0,0,0);
  padding: 5px 5px 5px 0px;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
}
.footer-link:hover {
  padding: 5px;
  background-color: #3c3c3c;
  border: 1px solid #3c3c3c;
  border-radius: 10px;
  color: white;
  cursor: pointer;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
}
.footer-brand-link {
  text-decoration: none;
  color: white;
  border: 1px solid rgba(0,0,0,0);
  padding: 10px;
  margin: 10px;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
}
.footer-brand-link:hover {
  padding: 10px;
  background-color: #3c3c3c;
  border: 1px solid #3c3c3c;
  border-radius: 10px;
  color: white;
  cursor: pointer;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
}
.footer-link-color-donate {
  text-decoration: none;
  border-radius: 10px;
  padding: 5px 5px 5px 0px;
  min-width: 80px;
  color: red !important;
  letter-spacing: 0.1em;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
}
.footer-link-color-donate:hover {
  background-color: #ff8484;
  padding: 5px;
  border-radius: 10px;
  color: white !important;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
}
</style>