<template>
  <!-- Modal -->
  <div class="modal fade" id="reviewsModal" tabindex="-1" role="dialog" data-bs-backdrop="true" aria-labelledby="reviewsModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-border">

        <div class="modal-content">
          <div class="modal-header">
            <div class="col-10 col-xs-10 col-sm-10 col-md-3 col-lg-3 m-0 p-0 text-start d-inline-block">
              <h5 class="modal-title" id="reviewsModalLabel">{{ setModalTitle }}</h5>
            </div>
            <div class="col-2 col-xs-2 col-sm-2 col-md-3 col-lg-3 m-0 p-0 text-end d-inline-block d-sm-none">
              <button type="button" id="closeBookingModalMobile" class="btn btn-outline-light close close-btn" data-bs-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <!-- Filters -->
            <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <ul class="list-unstyled m-0 p-1">
                <li class="d-inline-block">
                  <label class="form-check-label me-1 ms-1" for="one_star">
                    <input type="checkbox" v-model="filters.one_star" id="one_star" class="form-check-input m-1">
                    <span class="star-number">1</span> <font-awesome-icon icon="star" class="star-icon" style="color: yellow;"/>
                  </label>
                </li>
                <li class="d-inline-block">
                  <label class="form-check-label me-1 ms-1" for="two_star">
                    <input type="checkbox" v-model="filters.two_star" id="two_star" class="form-check-input m-1">
                    <span class="star-number">2</span> <font-awesome-icon icon="star" class="star-icon" style="color: yellow;"/>
                  </label>
                </li>
                <li class="d-inline-block">
                  <label class="form-check-label me-1 ms-1" for="three_star">
                    <input type="checkbox" v-model="filters.three_star" id="three_star" class="form-check-input m-1">
                    <span class="star-number">3</span> <font-awesome-icon icon="star" class="star-icon" style="color: yellow;"/>
                  </label>
                </li>
                <li class="d-inline-block">
                  <label class="form-check-label me-1 ms-1" for="four_star">
                    <input type="checkbox" v-model="filters.four_star" id="four_star" class="form-check-input m-1">
                    <span class="star-number">4</span> <font-awesome-icon icon="star" class="star-icon" style="color: yellow;"/>
                  </label>
                </li>
                <li class="d-inline-block">
                  <label class="form-check-label me-1 ms-1" for="five_star">
                    <input type="checkbox" v-model="filters.five_star" id="five_star" class="form-check-input m-1">
                    <span class="star-number">5</span> <font-awesome-icon icon="star" class="star-icon" style="color: yellow;"/>
                  </label>
                </li>
                <li v-if="reviewType === 'neighbourhood'" class="d-inline-block">
                  <select
                      v-model="filters.radius"
                      class="form-select form-radius select-placeholder cursor-pointer"
                      @focus="handleFocus($event)"
                      @focusout="handleFocusOut(filters.radius, $event)"
                      tabindex="-1"
                      aria-label="title"
                      name="title"
                      id="title"
                  >
                    <option value="" disabled>Radius</option>
                    <option v-for="(item , index) in radiusOptions" v-bind:key="index" :value="item.value" >
                      {{item.text}}
                    </option>
                  </select>
                </li>
              </ul>
            </div>
            <!-- End Filters -->
            <div class="col-2 col-xs-2 col-sm-2 col-md-3 col-lg-3 text-end d-none d-sm-block">
              <button type="button" id="closeBookingModal" class="btn btn-outline-light close close-btn" data-bs-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
          <div class="modal-body scroll-width-thin">
            <!-- Loading -->
            <Loader class="mt-2" :loading="loading" :small="false"></Loader>

            <!-- Reviews -->
            <div v-if="!loading" class="col-12 m-0 p-0 zindex-10">
              <div class="">
                <!-- Based on reviewType show correct reviews component -->
                <ReviewsNeighbourhood v-if="reviewType === 'neighbourhood'" :adId="adId" :adDetails="adDetails" :toUserId="toUserId" :filters="filters" :refresh="refresh"></ReviewsNeighbourhood>
                <ReviewsHost v-if="reviewType === 'host'" :adId="adId" :adDetails="adDetails" :toUserId="toUserId" :filters="filters" :refresh="refresh"></ReviewsHost>
                <ReviewsGuest v-if="reviewType === 'guest'" :adId="adId" :adDetails="adDetails" :toUserId="toUserId" :filters="filters" :refresh="refresh"></ReviewsGuest>
                <ReviewsServices v-if="reviewType === 'service'" :adId="adId" :adDetails="adDetails" :toUserId="toUserId" :filters="filters" :refresh="refresh"></ReviewsServices>
              </div>
            </div>

          </div>
          <div class="modal-footer p-2">
            <div class="row m-0 p-0">
              <!-- Validation and Success Messages -->
              <div id="validationBox">
                <div v-if="this.validationMessage" class="success-info success-msg text-center">
                  {{ this.validationMessage }}
                </div>
                <div v-if="validationErrors" class="global-error mt-2">
                  <ValidationErrors :errors="validationErrors"/>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ReviewsNeighbourhood from "./ReviewsNeighbourhood";
import ReviewsHost from "./ReviewsHost";
import ReviewsGuest from "./ReviewsGuest";
import ReviewsServices from "./ReviewsServices";
import ValidationErrors from "../ValidationErrors";
import Loader from "../Loader";

export default {
  name: 'ReviewsModal',
  props: ['adId', 'adDetails', 'reviewType', 'toUserId', 'refresh'],
  components: {
    'ReviewsNeighbourhood': ReviewsNeighbourhood,
    'ReviewsHost': ReviewsHost,
    'ReviewsGuest': ReviewsGuest,
    'ReviewsServices': ReviewsServices,
    'ValidationErrors': ValidationErrors,
    'Loader': Loader
  },
  data(){
    return {
      user: (this.$store.state.auth.authenticated === true && this.$store.state.auth.user) ? this.$store.state.auth.user : null,
      ad_id: this.adId,
      review_type: this.reviewType,
      processing: false,
      loading: true,
      validationMessage: '',
      validationErrors: '',
      validationSuccess: null,
      filters: {
        one_star: true,
        two_star: true,
        three_star: true,
        four_star: true,
        five_star: true,
        radius: ''
      },
      radiusOptions: [
        { value: '1', text: '1 Mile' },
        { value: '2', text: '2 Miles' },
        { value: '3', text: '3 Miles' },
        { value: '4', text: '4 Miles' },
        { value: '5', text: '5 Miles' },
        { value: '6', text: '6 Miles' },
        { value: '7', text: '7 Miles' },
        { value: '8', text: '8 Miles' },
        { value: '9', text: '9 Miles' },
        { value: '10', text: '10 Miles' },
        { value: '15', text: '15 Miles' },
      ],
    }
  },
  computed:{
    setModalTitle() {
      if (this.reviewType) {
        switch(this.reviewType) {
          case 'neighbourhood':
            return 'Neighbourhood Reviews';
            break;
          case 'host':
            return 'Host Reviews';
            break;
          case 'service':
            return 'Service Reviews';
            break;
          case 'guest':
            return 'Guest Reviews';
            break;
          default:
            return 'Reviews';
            break;
        }
      } else {
        return 'Reviews'
      }
    }
  },
  async mounted() {
  },
  methods:{
    async reload() {
      await this.reset();

      // await this.getBookingCalendars(this.adId, this.adType);
      // await this.getBookingsByAdId(this.adId, this.adType);
      this.loading = false;
    },
    reset() {
      Object.assign(this.$data, this.$options.data.apply(this));
    },
    handleFocus(event) {
      if(!event.target.classList.contains('select-clicked')) {
        event.target.classList.toggle('select-clicked');
      }
    },
    handleFocusOut(value, event) {
      if(value === null || value === '') {
        if(event.target.classList.contains('select-clicked')) {
          event.target.classList.toggle('select-clicked');
        }
      }
    }
  },
  watch: {
    refresh: {
      handler: async function (val) {
        this.loading = true;

        // Action if necessary

        this.loading = false;
      }, deep: true, immediate: true
    },
    'adId': {
      handler: async function (val) {
        this.loading = true;

        await this.reload();

        this.loading = false;
      }, deep: true, immediate: true
    }
  }
}
</script>

<style lang="scss">

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scroll {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* start tooltip - popups info on icons */
.tooltiptext {
  visibility: hidden;
  width: auto;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  text-align: center;
  font-size: 10px;
  border-radius: 6px;
  padding: 5px 5px;

  /* Position the tooltip */
  position: absolute;
  margin-left: 10px;
  z-index: 1000;
}

[data-tooltip]:hover .tooltiptext {
  visibility: visible;
  color: #fff;
}
/* end tooltip - popups info on icons */

.form-check-label {
  transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
}
.form-check-label:hover {
  transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
}

.form-check-label:hover .star-number {
  color: black;
}

.form-check-input:hover {
  cursor: pointer;
}

#reviewsModal {
  height: 100%;
  z-index: 100000;
  .modal-dialog {
    max-width: 85vw;
  }
  .modal-header {
    font-size: 13px;
    color: white;
    border-radius: 10px 10px 0 0;
    background: rgba(128, 0, 128, 0.7);
    padding: .5rem;
    text-transform: uppercase
  }
  .modal-content {
    border-radius: 10px;
    border: none;
    height: fit-content;
    .modal-body {
      overflow-x: hidden;
      overflow-y: scroll;
      height: 75vh;
    }
  }
  .modal-footer {
    display: block;
    //justify-content: center;
  }
  .booking-textarea-container {
    height: 200px;
    max-height: 200px;
  }
  .booking-textarea-container textarea {
    height: 180px;
    max-height: 180px;
  }
  .modal-border {
    border-radius: 10px;
    background: white;
    $border: 2px;
    position: relative;
    box-sizing: border-box;
    &:before {
      content: '';
      position: absolute;
      top: 0; right: 0; bottom: 0; left: 0;
      z-index: -1;
      margin: -$border; /* !importanté */
      border-radius: inherit; /* !importanté */
      background: linear-gradient(to bottom, rgba(128, 0, 128, 0.7), rgba(25, 181, 254, 0.7));
    }
  }
  .close-btn {
    color: white;
    line-height: 30px;
    font-size: 26px;
    border-radius: 50%;
    padding: 1px 5px;
    border: 0;
  }
  .close-btn:hover {
    color: rgba(128, 0, 128, 0.7);
    transform: rotate(90deg);
    transition: 0.5s;
    -moz-transition: 0.5s;
  }

  .form-select.form-radius {
    margin-left: 10px;
    padding: 0 25px 0 3px !important;
    font-size: 13px;
  }
}

/* Start Text truncate - white space - for long texts and icons */
.list-relative {
  width: 100%;
  position: relative;
}

.text-truncate-item {
  width: 100%;
  box-sizing: border-box;
  transition: 0.7s;
  border-radius: 5px;
}

.text-truncate-item:hover {
  position: absolute;
  transition: 0.7s;
  box-sizing: border-box;
  overflow: visible !important;
  overflow-wrap: break-word !important;
  white-space: normal !important;
  background-color: white;
  border: 1px solid black;
  border-radius: 5px;
  padding: 0 0 0 1px;
  z-index: 1000;
}
/* End Text truncate - white space - for long texts and icons */

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {

}
@media screen and (max-width: 767px) {
  #reviewsModal {
    .modal-dialog {
      max-width: 95vw;
    }
    .modal-header {
      display: block;
    }
  }
}
@media screen and (min-width: 768px) {
  #reviewsModal {
    .modal-dialog {
      max-width: 85vw;
    }
    .modal-header {
      display: flex;
    }
  }
}
@media screen and (min-width: 992px) {

}
@media screen and (min-width: 1200px) {

}
@media (max-height: 800px) and (min-width: 1200px) {

}
@media screen and (min-width: 1680px) {

}
@media (min-height: 1200px) and (min-width: 1600px) {

}
@media screen and (min-width: 1920px) {

}

</style>