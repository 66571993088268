<template>
  <div class="map mt--5">
    <!-- Loading -->
    <Loader :loading="loading" :small="false"></Loader>
    <div id="mapid"></div>
    <div class="map-overlay scroll-width-thin" id="legend">
      <div class="d-block text-center">
        <div class="hideLegend" id="hideBtn">
          <div class="btn-box-eye" @click="hideLegend()">
            <button class="btn-eye">
              <font-awesome-icon icon="eye" style="color: red;" />
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="showLegend" id="showBtn">
      <div class="btn-box-eye" @click="showLegend()">
        <button class="btn-eye">
          <font-awesome-icon icon="eye" style="color: #0d6efd;" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from "./Loader.vue"
import {mapActions} from 'vuex';
import mapboxgl from "mapbox-gl";
/* Map Box Draw */
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
/* End of Map Box Draw */
/* Turf */
import * as turf from '@turf/turf'

export default {
  name: 'Map',
  components: {
    'Loader': Loader
  },
  props: {
    msg: String,
    // map: null,
  },
  data(){
      return {
        eventSet: false,
        map: null,
        markers: [],
        drawbboxs: [],
        lastSearchLocation: '',
        loading: true
      }
  },
  methods: {
    hideLegend() {
      let legend = document.getElementById('legend');
      let showBtn = document.getElementById('showBtn');
      if (legend) {
        legend.style.display = 'none';
      }
      if (showBtn) {
        showBtn.style.display = 'block';
      }
    },
    showLegend() {
      let legend = document.getElementById('legend');
      let showBtn = document.getElementById('showBtn');
      if (legend) {
        legend.style.display = 'block';
      }
      if (showBtn) {
        showBtn.style.display = 'none';
      }
    },
    ...mapActions({
      getMap:'map/getMap',
      initMap:'map/initMap'
    }),
    initializeMap() {
      /* MapBox Map */
      mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_ACCESS_TOKEN;

      // Check if searchFilters location exist
      let lat = this.$store.state.searchFilters.searchFilters && this.$store.state.searchFilters.searchFilters.latitude ? this.$store.state.searchFilters.searchFilters.latitude : '51.507321899999994';
      let lng = this.$store.state.searchFilters.searchFilters && this.$store.state.searchFilters.searchFilters.longitude ? this.$store.state.searchFilters.searchFilters.longitude : '-0.12764739999999997';

      const map = new mapboxgl.Map({
        container: 'mapid',
        style: 'mapbox://styles/mapbox/streets-v11',
        center: {lat:lat,lng:lng},
        location: '',
        zoom: 12,
        maxZoom: 20
      });

      // Draw modes
      const modes = MapboxDraw.modes;

      // Add and initialize mapbox draw functionality and controller
      const draw = new MapboxDraw({
        modes: modes,
        displayControlsDefault: false,
        // Select which mapbox-gl-draw control buttons to add to the map.
        controls: {
          polygon: true,
          trash: true
        },
        // This one I will need on AddEditAd.vue component when adding the area
        // Set mapbox-gl-draw to draw by default.
        // The user does not have to click the polygon control button first.
        //   defaultMode: 'draw_polygon'
      });

      // First type of select (first click)
      modes.simple_select.onClick = function (state, e) {
        // Get clicked element info such as innerHtml and className
        // let classes = String(e.originalEvent.srcElement.className);
        // let iHTML = String(e.originalEvent.srcElement.innerHTML);

        let classes = String(e.originalEvent.target.className);
        let iHTML = String(e.originalEvent.target.innerHTML);

        // Check if we clicked feature (draw polygon)
        let feature = e.featureTarget && Object.keys(e.featureTarget).length !== 0 ? e.featureTarget.properties.id : null;

        let selectedId = draw.getSelectedIds() ? draw.getSelectedIds() : null;
        // console.log('START CHECKS');
        // console.log(classes);
        // console.log(iHTML);
        // console.log(feature);
        // console.log('is selected');
        // console.log(draw.getSelectedIds());
        // console.log(draw.getMode());
        // console.log('END CHECKS');
        // console.log('target and selectedID')
        // console.log(feature);
        // console.log(selectedId);
        if (feature && !iHTML) {
          // console.log(draw.isSelected(feature));
          if (selectedId) {
            if (selectedId[0] === feature) {
              draw.changeMode('direct_select', {featureId: feature});
            } else {
              draw.changeMode('simple_select');
              draw.changeMode('simple_select', {featureIds: feature});
            }
          } else {
            draw.changeMode('simple_select', {featureIds: feature});
          }
        }
        if (classes.includes('marker') || iHTML.includes('£')) {
          draw.changeMode('simple_select');
        }
        if (!feature) {
          draw.changeMode('simple_select');
        }
      }

      // onTap or onTouchEnd experiment with those MODES #RAF

      // Second type of select (second click - enter edit mode of polygon)
      modes.direct_select.onClick = function (state, e) {
        // Get clicked element info such as innerHtml and className
        // let classes = String(e.originalEvent.srcElement.className);
        // let iHTML = String(e.originalEvent.srcElement.innerHTML);
        let classes = String(e.originalEvent.target.className);
        let iHTML = String(e.originalEvent.target.innerHTML);

        // Check if we clicked feature (draw polygon)
        let feature = e.featureTarget && Object.keys(e.featureTarget).length !== 0 ? e.featureTarget.properties.id : null;

        let selectedId = draw.getSelectedIds() ? draw.getSelectedIds() : null;
        // console.log('target and selectedID')
        // console.log(feature);
        // console.log(selectedId);
        // console.log('START CHECKS');
        // console.log(classes);
        // console.log(iHTML);
        // console.log(feature);
        // console.log('END CHECKS');

        // If we click different polygon than currently focused
        if (feature && !iHTML) {
          if (selectedId) {
            // console.log(selectedId);
            if (selectedId[0] !== feature) {
              draw.changeMode('simple_select');
              draw.changeMode('simple_select', {featureIds: feature});
            }
          }
        }
        if (classes.includes('marker') || iHTML.includes('£')) {
          draw.changeMode('simple_select');
        }
        if (!feature) {
          draw.changeMode('simple_select');
        }
      }

      // Initialize the geolocate control.
      let geolocate = new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true
        },
        trackUserLocation: true
      });
      // Add the geolocate control to the map.
      map.addControl(geolocate, 'bottom-left');
      // Activates geolocation on map load
      map.on('load', function() {
        geolocate.trigger();

        /* Legend Data */
        const propCategories = [
          'Residential',
          'Commercial',
          'Industrial',
          'Land',
          'Luxury',
          'Special Use',
          'Room',
          'Multiple'
        ];
        const colors = [
          '#00bfff', // deepskyblue
          '#ff0000', // red
          '#0000ff', // blue
          '#00ff00', // lawngreen
          '#d986f3', // rgb(217, 134, 243)
          '#a52a2a', // saddlebrown
          '#ffff00', // yellow
          'linear-gradient(to right, red,orange,yellow,green,blue,indigo,violet)'
        ];

        // create legend
        const legend = document.getElementById('legend');

        // remove legend items if they exist
        const legendItems = document.getElementsByClassName('legend-item');

        if (legendItems.length > 0) {
          legendItems.forEach((legendItem, i) => {
            legendItem.remove();
          });
        }
        // while(legendItems.length > 0){
        //   legendItems[0].parentNode.removeChild(legendItems[0]);
        // }

        propCategories.forEach((propCategory, i) => {
          const color = colors[i];
          const item = document.createElement('div');
          item.className = 'legend-item';
          const key = document.createElement('span');
          key.style.display = 'inline-block';
          key.style.width = '10px';
          key.style.height = '10px';
          key.style.marginRight = '5px';
          key.style.borderRadius = '20%';
          key.style.border = '1px solid black';

          if (propCategory === 'Multiple') {
            key.style.backgroundImage = color;
          } else {
            key.style.backgroundColor = color;
          }

          const value = document.createElement('span');
          value.innerHTML = `${propCategory}`;
          item.appendChild(key);
          item.appendChild(value);
          legend.appendChild(item);
        });
      });

      // Initialize navigation control.
      let nav = new mapboxgl.NavigationControl();
      // Add the navigation control to the map
      map.addControl(nav, 'bottom-right');

      // // Add and initialize mapbox draw functionality and controller
      // const draw = new MapboxDraw({
      //   modes: modes,
      //   displayControlsDefault: false,
      //   // Select which mapbox-gl-draw control buttons to add to the map.
      //   controls: {
      //     polygon: true,
      //     trash: true
      //   },
      //   // This one I will need on AddEditAd.vue component when adding the area
      //   // Set mapbox-gl-draw to draw by default.
      //   // The user does not have to click the polygon control button first.
      //   //   defaultMode: 'draw_polygon'
      // });

      map.addControl(draw, 'bottom-right');

      map.on('draw.create', updateArea.bind(this));
      map.on('draw.delete', updateArea.bind(this));
      map.on('draw.update', updateArea.bind(this));

      function updateArea(e) {
        let mode = draw.getMode();
        // console.log(mode);
        // console.log(e);
        const data = draw.getAll();
        // console.log(data);
        // If delete reset drawbboxs filters
        if (e.type === 'draw.delete') {
          this.drawbboxs = [];
          for(let i=0; i<data.features.length; i++) {
            this.drawbboxs.push(data.features[i].geometry.coordinates[0]);
          }

          let searchFilters = this.$store.state.searchFilters.searchFilters;
          if (this.drawbboxs.length > 0) {
            searchFilters.drawbboxs = JSON.stringify(this.drawbboxs);
          } else {
            searchFilters.drawbboxs = null;
          }

          // Set searchFilters
          this.$root.$emit('setFilters', searchFilters);
          // Perform new search from drawing
          this.$emit('refreshProps', searchFilters);
        }

        if (data.features && data.features.length > 0) {
          this.drawbboxs = [];
          for(let i=0; i<data.features.length; i++) {
            this.drawbboxs.push(data.features[i].geometry.coordinates[0]);
          }

          let searchFilters = this.$store.state.searchFilters.searchFilters;
          if (this.drawbboxs.length > 0) {
            searchFilters.drawbboxs = JSON.stringify(this.drawbboxs);
          } else {
            searchFilters.drawbboxs = null;
          }

          // Set lastSearchLocation
          if (searchFilters.address) {
            this.lastSearchLocation = searchFilters.address;
          }

          // Set searchFilters
          this.$root.$emit('setFilters', searchFilters);
          // Perform new search from drawing
          this.$emit('refreshProps', searchFilters);

        } else {
          if (e.type !== 'draw.delete')
            alert('Click the map to draw a polygon.');
        }
      }

      this.map = map;
      this.setMarkers(map);

      /* End MapBox Map */

    },
    setMarkers(map) {
      this.$nextTick(async () => {
        // Remove current markers
        if (this.markers && this.markers.length > 0) {
          for (let i=0; i<this.markers.length; i++) {
            this.markers[i].remove();
          }
        }

        // // DRAW SEARCH (we need to remove unnecessary properties from the properties_map and properties store.state
        // // so that we have exactly same matches between map and list (important for click -> toggle correct prop data)
        // let propertiesCoordinates = [];
        // for(let i=0; i<this.$store.state.properties.properties_map.data.length; i++) {
        //   propertiesCoordinates.push([this.$store.state.properties.properties_map.data[i].address.longitude, this.$store.state.properties.properties_map.data[i].address.latitude]);
        // }
        // console.log('properties coordinates');
        // console.log(propertiesCoordinates);
        // let points = turf.points(propertiesCoordinates);
        // // let points = turf.points([
        // //   [-1.5415396473078772, 53.51143389634202],
        // //   [-1.5315396473078772, 53.51143389634202],
        // // ]);
        // console.log('points');
        // console.log(points);
        //
        // // let searchWithin = turf.polygon([[
        // //   [-1.0126650090430758, 53.63531897990961],
        // //   [-1.6915396473078772, 53.61143389634202],
        // //   [-1.6800332979186976, 53.38299311705097],
        // //   [-0.8789037216741065, 53.42757998777387],
        // //   [-1.0126650090430758, 53.63531897990961],
        // // ]]);
        // let ptsWithin = [];
        // console.log(this.drawbboxs);
        // for(let i=0; i<this.drawbboxs.length; i++) {
        //   let searchWithin = turf.polygon([this.drawbboxs[i]]);
        //   ptsWithin.push(turf.pointsWithinPolygon(points, searchWithin));
        // }
        //
        // // let ptsWithin = turf.pointsWithinPolygon(points, searchWithin);
        // console.log('pts within');
        // console.log(ptsWithin);
        // // END DRAW SEARCH

        let jsonMarkersArr = [];
        let singularMarkerJson = [];
        let multipleMarkerJson = [];
        let postType = '';

        if (this.$store.state.properties.properties_map.data) {
          for (let i = 0; i < this.$store.state.properties.properties_map.data.length; i++) {
            // Get post type
            // console.log(this.$store.state.properties.properties_map.data[i]);
            if (this.$store.state.properties.properties_map.data[i].is_let === 1) {
              postType = 'Let';
            }
            if (this.$store.state.properties.properties_map.data[i].is_rent === 1) {
              postType = 'Rent';
            }
            if (this.$store.state.properties.properties_map.data[i].is_buy === 1) {
              postType = 'Buy';
            }
            if (this.$store.state.properties.properties_map.data[i].is_sale === 1) {
              postType = 'Sale';
            }

            // Set prop_cat_type
            let propCatType = '';
            if (this.$store.state.properties.properties_map.data[i].property_category.type !== 'Room' && this.$store.state.properties.properties_map.data[i].property_type && this.$store.state.properties.properties_map.data[i].property_type_details) {
              propCatType = this.$store.state.properties.properties_map.data[i].property_type.type + ' ' + this.$store.state.properties.properties_map.data[i].property_type_details.type_detail;
            } else if (this.$store.state.properties.properties_map.data[i].property_category.type !== 'Room' && this.$store.state.properties.properties_map.data[i].property_type && !this.$store.state.properties.properties_map.data[i].property_type_details) {
              propCatType = this.$store.state.properties.properties_map.data[i].property_category.type + ' ' + this.$store.state.properties.properties_map.data[i].property_type.type;
            } else {
              propCatType = this.$store.state.properties.properties_map.data[i].property_category.type;
            }

            // Here I will need to make distinction between Sale/Let and Buy/Rent
            // Based on the type either create singularMarkerJson (Sale/Let) or areaMarkerJson (Buy/Rent)
            // For time being I will only show Sale/Let on the map, Buy/Rent will be only displayed on their respective minimaps + maybe hover(click) on listing will show on main map
            // if (postType === 'Buy' || postType === 'Rent') {
            //   return;
            // }

            // Check if price range
            let isPriceRange = await this.showPriceRange(this.$store.state.properties.properties_map.data[i]);
            let showHourRange = await this.showHourPriceRange(this.$store.state.properties.properties_map.data[i]);
            let showNightRange = await this.showNightPriceRange(this.$store.state.properties.properties_map.data[i]);

            // console.log(this.$store.state.properties.properties_map.data[i]);
            // console.log('Is Price Range: ' + isPriceRange);
            // console.log('Hour Range: ' + showHourRange);
            // console.log('Night Range: ' + showNightRange);
            // Calculate price range
            let priceRange = null;
            // We want to show night price range only, if its not present then we consider to show hour price range
            if (isPriceRange && showNightRange) {
              priceRange = await this.calculateNightPriceRange(this.$store.state.properties.properties_map.data[i]);
            } else if (isPriceRange && showHourRange) {
              priceRange = await this.calculateHourPriceRange(this.$store.state.properties.properties_map.data[i]);
            }

            // This two checks are for Let Long-Term - ROOM
            let showWeekRange = await this.showWeekPriceRange(this.$store.state.properties.properties_map.data[i]);
            let showMonthRange = await this.showMonthPriceRange(this.$store.state.properties.properties_map.data[i]);
            // console.log('Week Range: ' + showHourRange);
            // console.log('Month Range: ' + showNightRange);
            // We want to show week price range only, if its not present then we consider to show month price range
            if (isPriceRange && showWeekRange) {
              priceRange = await this.calculateWeekPriceRange(this.$store.state.properties.properties_map.data[i]);
              // console.log(priceRange);
            } else if (isPriceRange && showMonthRange) {
              priceRange = await this.calculateMonthPriceRange(this.$store.state.properties.properties_map.data[i]);
              // console.log(priceRange);
            }

            let rentType = this.$store.state.properties.properties_map.data[i].property_rent_type ? this.$store.state.properties.properties_map.data[i].property_rent_type.type : '';
            // Set rent type
            if (isPriceRange && (showHourRange || showNightRange)) {
              rentType = showHourRange ? (showNightRange ? 'night' : 'hour') : (showNightRange ? 'night' : '');
            }
            if (isPriceRange && (showWeekRange || showMonthRange)) {
              rentType = showMonthRange ? (showWeekRange ? 'week' : 'month') : (showWeekRange ? 'week' : '');
            }

            // console.log(this.$store.state.properties.properties_map.data[i].documents_property_images.length > 0);
            // console.log(this.$store.state.properties.properties_map.data[i].documents_property_images);
            // console.log('index id: ' + i);
            // console.log(this.$store.state.properties.properties_map.data[i].documents_property_images);
            singularMarkerJson = {
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: [this.$store.state.properties.properties_map.data[i].address.longitude, this.$store.state.properties.properties_map.data[i].address.latitude]
              },
              properties: {
                0: {
                  // Index is very important here, as same index is used on the listing, when we click on map and open it on list
                  // it takes from that index to find correct listing (I need to consider what will happen in case we rearrange
                  // listing, lets say filter by price or by date when it was added etc)
                  index: i,
                  is_viewed: false,
                  id: this.$store.state.properties.properties_map.data[i].id,
                  type: postType,
                  rent_type: rentType ? rentType : '',
                  category: this.$store.state.properties.properties_map.data[i].property_category.type,
                  prop_cat_type: propCatType,
                  title: this.$store.state.properties.properties_map.data[i].property_title ? this.$store.state.properties.properties_map.data[i].property_title : '',
                  address: (this.$store.state.properties.properties_map.data[i].address.county ? this.$store.state.properties.properties_map.data[i].address.county : '') + (this.$store.state.properties.properties_map.data[i].address.city ? ', ' + this.$store.state.properties.properties_map.data[i].address.city : '') + (this.$store.state.properties.properties_map.data[i].address.line_1 ? ', ' + this.$store.state.properties.properties_map.data[i].address.line_1 : '') + (this.$store.state.properties.properties_map.data[i].address.postcode ? ', ' + this.$store.state.properties.properties_map.data[i].address.postcode : ''),
                  description: 'We will see later on',
                  price: isPriceRange ? priceRange : this.$store.state.properties.properties_map.data[i].price,
                  is_price_range: isPriceRange,
                  images: this.$store.state.properties.properties_map.data[i].documents_property_images.length > 0 ? this.$store.state.properties.properties_map.data[i].documents_property_images : null,
                  tenure: this.$store.state.properties.properties_map.data[i].is_freehold ? (this.$store.state.properties.properties_map.data[i].is_freehold === 1 ? 'Freehold' : 'Leasehold') : '',
                  epc: this.$store.state.properties.properties_map.data[i].property_details.epc_rating,
                  details: this.$store.state.properties.properties_map.data[i].property_details,
                  date_start: this.$store.state.properties.properties_map.data[i].date_start ? this.moment.utc(this.$store.state.properties.properties_map.data[i].date_start).format('DD-MM-YYYY') : '',
                  date_end: this.$store.state.properties.properties_map.data[i].date_end ? this.moment.utc(this.$store.state.properties.properties_map.data[i].date_end).format('DD-MM-YYYY') : '',
                  coming_soon: this.$store.state.properties.properties_map.data[i].is_coming_soon ? 'Coming Soon' : '',
                  auction: this.$store.state.properties.properties_map.data[i].is_auction ? 'Auction' : '',
                  cash: this.$store.state.properties.properties_map.data[i].is_cash ? 'Cash Only' : '',
                  mixed: this.$store.state.properties.properties_map.data[i].is_mixed_use ? 'Mixed Use' : '',
                  shared: this.$store.state.properties.properties_map.data[i].is_shared_ownership ? 'Shared Ownership' : '',
                  hmo: this.$store.state.properties.properties_map.data[i].is_hmo ? 'HMO' : '',
                  investment: this.$store.state.properties.properties_map.data[i].is_investment ? 'Investment' : ''
                }
              }
            }
            jsonMarkersArr.push(singularMarkerJson);
          }

          // Here I need to iterate all jsonMarkersArr and separate markers from same location into one place and real singular markers into another
          // Further we need to make sure that all functionality below works well with it.
          let groupedMarkers = [];
          let markersToRemove = [];
          let jsonMarkersCopy = { ...jsonMarkersArr };
          // console.log(jsonMarkersCopy);
          // Object.assign(jsonMarkersCopy, jsonMarkersArr);
          for (let i = 0; i < jsonMarkersArr.length; i++) {
            // Don't loop NULL, as those are already grouped and removed (set to null) for a reason
            if (jsonMarkersArr[i] !== null) {
              for (let a = 0; a < Object.keys(jsonMarkersCopy).length; a++) {
                // Calculate distance between points, if distance smaller than requested, make it clustered/grouped

                // Skip the check on itself (this if assures that)
                if (i !== a) {
                  // console.log(i);
                  let from = turf.point(jsonMarkersArr[i].geometry.coordinates);
                  let to = turf.point(jsonMarkersCopy[a].geometry.coordinates);
                  let options = {units: 'miles'};

                  let distance = turf.distance(from, to, options);

                  // console.log(distance)
                  // Distance in miles for group markers (should be fairly small)
                  if (distance <= 0.02) {
                    // console.log('distance was smaller than 0.2 miles')
                    // console.log(jsonMarkersArr[i]);
                    // console.log(jsonMarkersCopy[a]);
                    //
                    // console.log(typeof(jsonMarkersArr[i]));
                    // console.log(typeof(jsonMarkersArr[i].properties));
                    // console.log('current properties');
                    // console.log(jsonMarkersArr[i]['properties']);
                    // console.log('property to be added');
                    // console.log(jsonMarkersCopy[a]['properties']);

                    // Add new property only if it does not exist yet
                    if (!jsonMarkersArr[i].properties.hasOwnProperty(a)) {
                      // console.log(a);
                      // console.log(jsonMarkersArr[i].properties);
                      // console.log(jsonMarkersCopy[a].properties[0]);
                      jsonMarkersArr[i].properties[a] = {};
                      Object.assign(jsonMarkersArr[i].properties[a], jsonMarkersCopy[a].properties[0]); // jsonMarkersArr[i].properties,

                      groupedMarkers.push(multipleMarkerJson);

                      // Assign current marker to removed markers array
                      markersToRemove[a] = a;
                      // Remove current marker from main loop by setting it to null
                      jsonMarkersArr[a] = null;
                    }
                  }
                }
              }
            }
          }

          // Remove grouped markers from original array - loop in reverse - set it as null to not break indexes
          for (let i = markersToRemove.length - 1; i >= 0; i--) {
            if (jsonMarkersArr.hasOwnProperty(markersToRemove[i])) {
              jsonMarkersArr[i] = null;
              // jsonMarkersArr.splice(i, 1);
            }
          }
          // console.log('Markers to remove');
          // console.log(markersToRemove);
          // console.log('Revised markers array');
          // console.log(jsonMarkersArr);

          // For time being I will only show Sale/Let on the map, Buy/Rent will be only displayed on their respective minimaps + maybe hover(click) on listing will show on main map
          // if (postType === 'Buy' || postType === 'Rent') {
          //   return;
          // }

          // console.log('markers to be looped');
          // console.log(jsonMarkersArr);
          for (let i = 0; i < jsonMarkersArr.length; i++) {
            // We removed (set to null) grouped markers, so we need to skip those here
            if (jsonMarkersArr[i] != null) {

              // console.log(Object.keys(jsonMarkersArr[i].properties).length > 1);
              if (Object.keys(jsonMarkersArr[i].properties).length > 1) {
                // Grouped Markers

                const el = document.createElement('div');

                // console.log(Object.keys(jsonMarkersArr[i].properties).length);
                // console.log(Object.keys(jsonMarkersArr[i]).length);
                // Apply different icon styling based on property category (classes are in helpers.scss)
                if (Object.keys(jsonMarkersArr[i].properties).length > 1) {
                  // console.log('group marker');
                  el.className = 'marker-' + i + ' marker-group';
                }

                // calculate price (better might be to do price range - smallest to biggest in listing )
                let price =  'ads: ' + Object.keys(jsonMarkersArr[i].properties).length;

                el.innerHTML = '<p class="price-tag-group"><b>' + price + '</b></p>'

                // Start Set html for popups, so that we get correct images and property details inside
                // For group markers
                let markersKeys = Object.keys(jsonMarkersArr[i].properties);
                let html = "<div id=\"previewPropExternal-" + jsonMarkersArr[i].properties[0].id + "\" class=\"cursor-pointer\">";
                for (let g = 0; g < markersKeys.length; g++) {
                  html = html + "<div id=\"previewProp-" + jsonMarkersArr[i].properties[markersKeys[g]].id + "\" class=\"row cursor-pointer\">";
                  html = html + "<div class=\"col-12\">";
                  if (!jsonMarkersArr[i].properties[markersKeys[g]].images || jsonMarkersArr[i].properties[markersKeys[g]].images.length === 0) {
                    html = html + "<div><img src=\"" + require('@/assets/no-image-found.png') + "\"" +
                        "                    class=\"d-block w-100 img-fluid img-marker\"" +
                        "                    alt=\"...\"" +
                        "                    ></div>";
                  } else {
                    // Check if image exists (s3Bucket)
                    let imageExists = this.checkImage(`${this.$s3bucket}${jsonMarkersArr[i].properties[markersKeys[g]].images[0].file_path}`);

                    html = html + "<div>";

                    if (imageExists) {
                      html = html + "<img src=\"" + `${this.$s3bucket}${jsonMarkersArr[i].properties[markersKeys[g]].images[0].file_path}` + "\"" +
                          "                    class=\"d-block w-100 img-fluid img-marker\" alt=\"...\"></div>";
                    } else {
                      html = html + "<img src=\"" + require('@/assets/no-image-found.png') + "\"\n" +
                          "                    class=\"d-block w-100 img-fluid img-marker\"\n" +
                          "                    alt=\"...\"></div>";
                    }
                  }
                  html = html + "</div>";

                  html = html + "<div class=\"col-12 text-start\">";
                  html = html + "<div class=\"row m-0 p-0 ps-2 pe-2\">";
                  html = html + "<div class=\"col-12 text-center m-0 p-0\">";
                  html = html + "<p class=\"m-1 p-0\">";
                  if (jsonMarkersArr[i].properties[markersKeys[g]].type === 'Sale') {
                    html = html + "<span class=\"p-1 category-label\"\n" +
                        " style=\"color: green; background-color: lightgreen;\">FOR SALE</span>"
                  }
                  if (jsonMarkersArr[i].properties[markersKeys[g]].type === 'Let') {
                    html = html + "<span class=\"p-1 category-label\"\n" +
                        " style=\"color: white; background-color: deepskyblue;\">TO LET</span>"
                  }
                  if (jsonMarkersArr[i].properties[markersKeys[g]].type === 'Buy') {
                    html = html + "<span class=\"p-1 category-label\"\n" +
                        " style=\"color: green; background-color: lightgreen;\">TO BUY</span>"
                  }
                  if (jsonMarkersArr[i].properties[markersKeys[g]].type === 'Rent') {
                    html = html + "<span class=\"p-1 category-label\"\n" +
                        " style=\"color: white; background-color: deepskyblue;\">TO RENT</span>"
                  }
                  html = html + "</p>";
                  html = html + "</div>";
                  html = html + "</div>";

                  if (jsonMarkersArr[i].properties[markersKeys[g]].prop_cat_type) {
                    html = html + "<div class=\"row m-0 p-0 ps-2 pe-2\">";
                    html = html + "<div class=\"col-12 m-0 p-0\">";
                    html = html + "<p class=\"m-0 p-0\">"
                    if (jsonMarkersArr[i].properties[markersKeys[g]].category === 'Residential') {
                      html = html + "<span class='legend-residential'></span> ";
                    }
                    if (jsonMarkersArr[i].properties[markersKeys[g]].category === 'Commercial') {
                      html = html + "<span class='legend-commercial'></span> ";
                    }
                    if (jsonMarkersArr[i].properties[markersKeys[g]].category === 'Industrial') {
                      html = html + "<span class='legend-industrial'></span> ";
                    }
                    if (jsonMarkersArr[i].properties[markersKeys[g]].category === 'Land') {
                      html = html + "<span class='legend-land'></span> ";
                    }
                    if (jsonMarkersArr[i].properties[markersKeys[g]].category === 'Luxury') {
                      html = html + "<span class='legend-luxury'></span> ";
                    }
                    if (jsonMarkersArr[i].properties[markersKeys[g]].category === 'Special Use') {
                      html = html + "<span class='legend-special-use'></span> ";
                    }
                    if (jsonMarkersArr[i].properties[markersKeys[g]].category === 'Room') {
                      html = html + "<span class='legend-room'></span> ";
                    }
                    html = html + jsonMarkersArr[i].properties[markersKeys[g]].prop_cat_type;
                    html = html + "</p>"
                    html = html + "</div>";
                    html = html + "</div>";
                  }

                  if (jsonMarkersArr[i].properties[markersKeys[g]].address) {
                    html = html + "<div class=\"row m-0 p-0 ps-2 pe-2\">";
                    html = html + "<div class=\"col-12 m-0 p-0\">";
                    html = html + "<p class=\"m-0 p-0\">" + jsonMarkersArr[i].properties[markersKeys[g]].address + "</p>";
                    html = html + "</div>";
                    html = html + "</div>";
                  }

                  if (jsonMarkersArr[i].properties[markersKeys[g]].date_start && jsonMarkersArr[i].properties[markersKeys[g]].type === 'Rent') {
                    html = html + "<div class=\"row m-0 p-0 ps-2 pe-2\">";
                    html = html + "<div class=\"col-12 m-0 p-0\">";
                    if (jsonMarkersArr[i].properties[markersKeys[g]].date_start && jsonMarkersArr[i].properties[markersKeys[g]].date_end) {
                      html = html + "<p class=\"m-0 p-0\"><span class=\"fw-bold\">Required Dates: </span>" + jsonMarkersArr[i].properties[markersKeys[g]].date_start + " - " + jsonMarkersArr[i].properties[markersKeys[g]].date_end + "</p>";
                    } else {
                      html = html + "<p class=\"m-0 p-0\"><span class=\"fw-bold\">Required Dates: </span>" + jsonMarkersArr[i].properties[markersKeys[g]].date_start + " - " + "TBD" + "</p>";
                    }
                    html = html + "</div>";
                    html = html + "</div>";
                  }

                  if (jsonMarkersArr[i].properties[markersKeys[g]].date_start && jsonMarkersArr[i].properties[markersKeys[g]].type === 'Let') {
                    html = html + "<div class=\"row m-0 p-0 ps-2 pe-2\">";
                    html = html + "<div class=\"col-12 m-0 p-0\">";
                    if (jsonMarkersArr[i].properties[markersKeys[g]].date_start && jsonMarkersArr[i].properties[markersKeys[g]].date_end) {
                      html = html + "<p class=\"m-0 p-0\"><span class=\"fw-bold\">Availability: </span>" + jsonMarkersArr[i].properties[markersKeys[g]].date_start + " - " + jsonMarkersArr[i].properties[markersKeys[g]].date_end + "</p>";
                    } else {
                      html = html + "<p class=\"m-0 p-0\"><span class=\"fw-bold\">Availability: </span>" + jsonMarkersArr[i].properties[markersKeys[g]].date_start + " - " + "TBD" + "</p>";
                    }
                    html = html + "</div>";
                    html = html + "</div>";
                  }

                  if (jsonMarkersArr[i].properties[markersKeys[g]].title) {
                    html = html + "<div class=\"row m-0 p-0 ps-2 pe-2\">";
                    html = html + "<div class=\"col-12 m-0 p-0\">";
                    html = html + "<p class=\"m-0 p-0\"><span class=\"fw-bold\">Title: </span>" + jsonMarkersArr[i].properties[markersKeys[g]].title + "</p>";
                    html = html + "</div>";
                    html = html + "</div>";
                  }

                  html = html + "<div class=\"row m-0 p-0 ps-2 pe-2\">";
                  html = html + "<div class=\"col-6 m-0 p-0\">";
                  html = html + "<p class=\"m-0 p-0\"><span class=\"fw-bold\">Price:</span> <span class=\"price-color\">£" + this.convertNumberToCommas(jsonMarkersArr[i].properties[markersKeys[g]].price) + "</span> ";
                  if (jsonMarkersArr[i].properties[markersKeys[g]].type === 'Rent' || jsonMarkersArr[i].properties[markersKeys[g]].type === 'Let') {
                    html = html + "<span> per " + jsonMarkersArr[i].properties[markersKeys[g]].rent_type + " </span>";
                  }
                  html = html + "</p>";
                  html = html + "</div>";

                  if (jsonMarkersArr[i].properties[markersKeys[g]].type !== 'Rent' && jsonMarkersArr[i].properties[markersKeys[g]].type !== 'Let') {
                    html = html + "<div class=\"col-6 m-0 p-0\">";
                    html = html + "<p class=\"m-0 p-0\"><span class=\"fw-bold\">Tenure:</span> " + jsonMarkersArr[i].properties[markersKeys[g]].tenure + "</p>";
                    html = html + "</div>";
                  }
                    html = html + "</div>";

                  html = html + "<div class=\"row m-0 p-0 ps-2 pe-2\">";
                  if (jsonMarkersArr[i].properties[markersKeys[g]].details.single_room_suite && jsonMarkersArr[i].properties[markersKeys[g]].details.double_room_suite) {
                    html = html + "<div class=\"col-12 m-0 p-0\">";
                    html = html + "<p class=\"m-0 p-0\"><span class=\"fw-bold\">Suite/Apartment Beds:</span> " + this.calculateSuiteBeds(jsonMarkersArr[i].properties[markersKeys[g]].details) + "</p>";
                    html = html + "</div>";
                  }

                  html = html + "<div class=\"col-6 m-0 p-0\">";
                  html = html + "<p class=\"m-0 p-0\"><span class=\"fw-bold\">Bedrooms:</span> " + this.calculateBeds(jsonMarkersArr[i].properties[markersKeys[g]].details) + "</p>";
                  html = html + "</div>";

                  html = html + "<div class=\"col-6 m-0 p-0\">";
                  html = html + "<p class=\"m-0 p-0\"><span class=\"fw-bold\">Living Rooms:</span> " + jsonMarkersArr[i].properties[markersKeys[g]].details.living_room + "</p>";
                  html = html + "</div>";
                  html = html + "<div class=\"col-6 m-0 p-0\">";
                  html = html + "<p class=\"m-0 p-0\"><span class=\"fw-bold\">Parking:</span> " + this.calculateParking(jsonMarkersArr[i].properties[markersKeys[g]].details.driveway, jsonMarkersArr[i].properties[markersKeys[g]].details.garage, jsonMarkersArr[i].properties[markersKeys[g]].details.parking, jsonMarkersArr[i].properties[markersKeys[g]].details.parking_spaces) + "</p>";
                  html = html + "</div>";

                  html = html + "<div class=\"col-6 m-0 p-0\">";
                  html = html + "<p class=\"m-0 p-0\"><span class=\"fw-bold\">Garden:</span> " + this.calculateGarden(jsonMarkersArr[i].properties[markersKeys[g]].details.front_garden, jsonMarkersArr[i].properties[markersKeys[g]].details.rear_garden) + "</p>";
                  html = html + "</div>";

                  html = html + "<div class=\"col-6 m-0 p-0\">";
                  html = html + "<p class=\"m-0 p-0\"><span class=\"fw-bold\">Bathrooms:</span> " + jsonMarkersArr[i].properties[markersKeys[g]].details.bath_room + "</p>";
                  html = html + "</div>";

                  html = html + "<div class=\"col-6 m-0 p-0\">";
                  html = html + "<p class=\"m-0 p-0\"><span class=\"fw-bold\">Downstairs WC:</span> " + jsonMarkersArr[i].properties[markersKeys[g]].details.downstairs_wc + "</p>";
                  html = html + "</div>";
                  html = html + "</div>";

                  html = html + "<div class=\"row m-0 p-0 text-center\">";
                  if (jsonMarkersArr[i].properties[markersKeys[g]].auction) {
                    html = html + "<div class=\"col-6 m-0 p-1\">";
                    html = html + "<p class=\"info-label m-0\"><span class=\"fw-bold\">" + jsonMarkersArr[i].properties[markersKeys[g]].auction + "</span></p>";
                    html = html + "</div>";
                  }

                  if (jsonMarkersArr[i].properties[markersKeys[g]].coming_soon) {
                    html = html + "<div class=\"col-6 m-0 p-1\">";
                    html = html + "<p class=\"info-label m-0\"><span class=\"fw-bold\">" + jsonMarkersArr[i].properties[markersKeys[g]].coming_soon + "</span></p>";
                    html = html + "</div>";
                  }

                  if (jsonMarkersArr[i].properties[markersKeys[g]].cash) {
                    html = html + "<div class=\"col-6 m-0 p-1\">";
                    html = html + "<p class=\"info-label m-0\"><span class=\"fw-bold\">" + jsonMarkersArr[i].properties[markersKeys[g]].cash + "</span></p>";
                    html = html + "</div>";
                  }

                  if (jsonMarkersArr[i].properties[markersKeys[g]].mixed) {
                    html = html + "<div class=\"col-6 m-0 p-1\">";
                    html = html + "<p class=\"info-label m-0\"><span class=\"fw-bold\">" + jsonMarkersArr[i].properties[markersKeys[g]].mixed + "</span></p>";
                    html = html + "</div>";
                  }

                  if (jsonMarkersArr[i].properties[markersKeys[g]].shared) {
                    html = html + "<div class=\"col-6 m-0 p-1\">";
                    html = html + "<p class=\"info-label m-0\"><span class=\"fw-bold\">" + jsonMarkersArr[i].properties[markersKeys[g]].shared + "</span></p>";
                    html = html + "</div>";
                  }

                  if (jsonMarkersArr[i].properties[markersKeys[g]].hmo) {
                    html = html + "<div class=\"col-6 m-0 p-1\">";
                    html = html + "<p class=\"info-label m-0\"><span class=\"fw-bold\">" + jsonMarkersArr[i].properties[markersKeys[g]].hmo + "</span></p>";
                    html = html + "</div>";
                  }

                  if (jsonMarkersArr[i].properties[markersKeys[g]].investment) {
                    html = html + "<div class=\"col-6 m-0 p-1\">";
                    html = html + "<p class=\"info-label m-0\"><span class=\"fw-bold\">" + jsonMarkersArr[i].properties[markersKeys[g]].investment + "</span></p>";
                    html = html + "</div>";
                  }

                  html = html + "</div>";


                  html = html + "</div>";

                  html = html + "</div>";

                  html = html + "<hr/>";
                }
                html = html + "</div>";
                // End Set html for popups
                let self = this;

                let marker = new mapboxgl.Marker(el)
                    .setLngLat(jsonMarkersArr[i].geometry.coordinates)
                    .setPopup(
                        // I can add custom class, I need to work out how to make nice transitions (fade in fade out)
                        new mapboxgl.Popup({offset: 25, className: "popup-transition popup-map", closeOnClick: true})
                            .setHTML(html) // html should be valid for singular marker but also for multiple markers (clustered or same location)
                            .on('open', e => {
                              // Mark marker as viewed (changes color of marker/price popup)
                              if (el) {
                                let children = el.children;
                                if (children && children[0]) {
                                  children[0].classList.add('marker-viewed');
                                }
                              }

                              for (let g = 0; g < markersKeys.length; g++) {
                                // boolean values used to assign event listeners just once
                                let propName = 'eventSet' + i + 'prop' + g;
                                this[propName] = false;

                                let idName = "previewProp-" + jsonMarkersArr[i].properties[markersKeys[g]].id;
                                const btn = document.getElementById(idName);
                                // We need to pass property ID and its INDEX to the previewProperty function, so that it displays correct one on the listing
                                // EDIT: I need to pass property ID, index is not necessary anymore... it seems so..
                                let callback = function () {
                                  // Open preview property data
                                  self.previewProperty(jsonMarkersArr[i].properties[markersKeys[g]].id, jsonMarkersArr[i].properties[markersKeys[g]].index)
                                };
                                // once: true is very important below, otherwise we get same handler created several times
                                if (this[propName] === false) {
                                  btn.removeEventListener('click', callback, true);
                                  btn.addEventListener('click', callback, true);
                                  this[propName] = true;
                                }
                              }
                            })
                        // .on('close', e => {
                        //     let idName = "previewProp-" + i;
                        //     const btn = document.getElementById(idName);
                        //     let callback = function () { self.previewProperty() };
                        //     if(btn) {
                        //       btn.removeEventListener('click', callback,true);
                        //     }
                        //     console.log('removed');
                        //   })
                    )
                    .setRotation(-45)
                    .addTo(map);

                // Assign markers to global scope of this component
                if (marker) {
                  this.markers.push(marker);

                  /* Start Add touch listeners on markers and map-canvas */
                  // Get current marker
                  let currentMarker = document.getElementsByClassName('marker-' + i);

                  let callbackFunction = function (event) {
                    event.stopPropagation();
                    // Check if there is already a popup on the map and if so, remove it
                    let previousPopup = document.getElementsByClassName('mapboxgl-popup');
                    if (Object.entries(previousPopup).length > 0) {
                      previousPopup[0].parentNode.removeChild(previousPopup[0]);
                    }

                    marker.togglePopup();
                  };
                  // #RAF Check if you can use tap rather than touchend
                  // attach touch event to marker so it works on mobile
                  currentMarker[0].removeEventListener('touchend', callbackFunction, {passive: false, capture:true});
                  currentMarker[0].addEventListener('touchend', callbackFunction, {passive: false, capture:true});

                  // Get map canvas
                  let mapCanvas = document.getElementsByClassName('mapboxgl-canvas');

                  let dragging = false;

                  let canvasTouchEndCallbackFunction = () => {
                    if (dragging) {
                      return;
                    }
                    // Check if there is already a popup on the map and if so, remove it
                    let previousPopup = document.getElementsByClassName('mapboxgl-popup');
                    if (Object.entries(previousPopup).length > 0) {
                      previousPopup[0].parentNode.removeChild(previousPopup[0]);
                    }
                  }

                  let canvasDragCallbackFunction = () => {
                    dragging = true;
                  }

                  let canvasTouchStartCallbackFunction = (e) => {
                    // e.preventDefault()
                    // e.stopPropagation()
                    dragging = false;
                  }

                  // #RAF passive : false should prevent error which occurs in console, however it does not work...
                  // I need to work this out...
                  // Listen on touch on mapCanvas and close marker popup
                  if (Object.entries(mapCanvas).length > 0) {
                    mapCanvas[0].removeEventListener('touchstart', canvasTouchStartCallbackFunction, {passive: false, capture: true});
                    mapCanvas[0].addEventListener('touchstart', canvasTouchStartCallbackFunction, {passive: false, capture:true});
                    mapCanvas[0].removeEventListener('touchmove', canvasDragCallbackFunction, {passive: false, capture: true});
                    mapCanvas[0].addEventListener('touchmove', canvasDragCallbackFunction, {passive: false, capture:true});
                    mapCanvas[0].removeEventListener('touchend', canvasTouchEndCallbackFunction, {passive: false, capture: true});
                    mapCanvas[0].addEventListener('touchend', canvasTouchEndCallbackFunction, {passive: false, capture:true});
                  }
                  /* End Add touch listeners on markers and map-canvas */
                }

              } else if (Object.keys(jsonMarkersArr[i].properties).length < 2) {
                // Singular Markers

                const el = document.createElement('div');

                // Apply different icon styling based on property category (classes are in helpers.scss)
                if (jsonMarkersArr[i].properties[0].category === 'Residential') {
                  el.className = 'marker-' + i + ' marker-residential';
                }
                if (jsonMarkersArr[i].properties[0].category === 'Commercial') {
                  el.className = 'marker-' + i + ' marker-commercial';
                }
                if (jsonMarkersArr[i].properties[0].category === 'Industrial') {
                  el.className = 'marker-' + i + ' marker-industrial';
                }
                if (jsonMarkersArr[i].properties[0].category === 'Land') {
                  el.className = 'marker-' + i + ' marker-land';
                }
                if (jsonMarkersArr[i].properties[0].category === 'Luxury') {
                  el.className = 'marker-' + i + ' marker-luxury';
                }
                if (jsonMarkersArr[i].properties[0].category === 'Special Use') {
                  el.className = 'marker-' + i + ' marker-special-use';
                }
                if (jsonMarkersArr[i].properties[0].category === 'Room') {
                  el.className = 'marker-' + i + ' marker-room';
                }

                // Set price either for LET short-term/room or other types
                let price = 0;

                if (jsonMarkersArr[i].properties[0].is_price_range) {
                  // calculate price
                  price = jsonMarkersArr[i].properties[0].price;

                  // assign price above the marker according to the length of the price
                  if (price.length <= 6) {
                    el.innerHTML = '<p class="price-tag-ranged-short"><b>£' + price + '</b></p>'
                  }
                  if (6 < price.length) {
                    el.innerHTML = '<p class="price-tag-ranged-long"><b>£' + price + '</b></p>'
                  }

                } else {
                  // calculate price
                  price = this.convertNumberToCommas(jsonMarkersArr[i].properties[0].price);

                  // assign price above the marker according to the length of the price
                  if (price.length <= 3) {
                    el.innerHTML = '<p class="price-tag-shorter"><b>£' + price + '</b></p>'
                  }
                  if (3 < price.length && price.length <= 6) {
                    el.innerHTML = '<p class="price-tag-short"><b>£' + price + '</b></p>'
                  }
                  if (6 < price.length && price.length <= 8) {
                    el.innerHTML = '<p class="price-tag-medium"><b>£' + price + '</b></p>'
                  }
                  if (8 < price.length && price.length <= 10) {
                    el.innerHTML = '<p class="price-tag-long"><b>£' + price + '</b></p>'
                  }
                  if (10 < price.length && price.length <= 12) {
                    el.innerHTML = '<p class="price-tag-extralong"><b>£' + price + '</b></p>'
                  }
                }

                // Start Set html for popups, so that we get correct images and property details inside
                let html = "<div id=\"previewProp-" + jsonMarkersArr[i].properties[0].id + "\" class=\"row cursor-pointer\">";
                html = html + "<div class=\"col-12\">";
                if (!jsonMarkersArr[i].properties[0].images || jsonMarkersArr[i].properties[0].images.length === 0) {
                  html = html + "<div><img src=\"" + require('@/assets/no-image-found.png') + "\"" +
                      "                    class=\"d-block w-100 img-fluid img-marker\"" +
                      "                    alt=\"...\"" +
                      "                    ></div>";
                } else {
                  // Check if image exists (s3Bucket)
                  let imageExists = this.checkImage(`${this.$s3bucket}${jsonMarkersArr[i].properties[0].images[0].file_path}`);

                  html = html + "<div>";

                  // BELOW OPTION IS WORKING HOWEVER ONERROR IS OBSOLETE, SO ITS BETTER TO USE DIFFERENT APPROACH IF POSSIBLE
                  // html = html + "<img src=\"" + `${this.$s3bucket}${jsonMarkersArr[i].properties[0].images[0].file_path}` + "\" onerror=\"javascript:this.onerror=null;this.src='" + require('@/assets/no-image-found.png') + "'\"\n" +
                  //     "                                   class=\"d-block w-100 img-fluid img-marker\"\n" +
                  //     "                                   alt=\"...\"\n" +
                  //     "                              >"

                  if (imageExists) {
                    html = html + "<img src=\"" + `${this.$s3bucket}${jsonMarkersArr[i].properties[0].images[0].file_path}` + "\"\n" +
                        "                                   class=\"d-block w-100 img-fluid img-marker\"\n" +
                        "                                   alt=\"...\"\n" +
                        "                              >"
                  } else {
                    html = html + "<img src=\"" + require('@/assets/no-image-found.png') + "\"\n" +
                        "                    class=\"d-block w-100 img-fluid img-marker\"\n" +
                        "                    alt=\"...\"></div>";
                  }
                }
                html = html + "</div>";

                html = html + "<div class=\"col-12 text-start\">";
                html = html + "<div class=\"row m-0 p-0 ps-2 pe-2\">";
                html = html + "<div class=\"col-12 text-center m-0 p-0\">";
                html = html + "<p class=\"m-1 p-0\">";
                if (jsonMarkersArr[i].properties[0].type === 'Sale') {
                  html = html + "<span class=\"p-1 category-label\"\n" +
                      " style=\"color: green; background-color: lightgreen;\">FOR SALE</span>"
                }
                if (jsonMarkersArr[i].properties[0].type === 'Let') {
                  html = html + "<span class=\"p-1 category-label\"\n" +
                      " style=\"color: white; background-color: deepskyblue;\">TO LET</span>"
                }
                if (jsonMarkersArr[i].properties[0].type === 'Buy') {
                  html = html + "<span class=\"p-1 category-label\"\n" +
                      " style=\"color: green; background-color: lightgreen;\">TO BUY</span>"
                }
                if (jsonMarkersArr[i].properties[0].type === 'Rent') {
                  html = html + "<span class=\"p-1 category-label\"\n" +
                      " style=\"color: white; background-color: deepskyblue;\">TO RENT</span>"
                }
                html = html + "</p>";
                html = html + "</div>";
                html = html + "</div>";

                if (jsonMarkersArr[i].properties[0].prop_cat_type) {
                  html = html + "<div class=\"row m-0 p-0 ps-2 pe-2\">";
                  html = html + "<div class=\"col-12 m-0 p-0\">";
                  html = html + "<p class=\"m-0 p-0\">"
                  if (jsonMarkersArr[i].properties[0].category === 'Residential') {
                    html = html + "<span class='legend-residential'></span> ";
                  }
                  if (jsonMarkersArr[i].properties[0].category === 'Commercial') {
                    html = html + "<span class='legend-commercial'></span> ";
                  }
                  if (jsonMarkersArr[i].properties[0].category === 'Industrial') {
                    html = html + "<span class='legend-industrial'></span> ";
                  }
                  if (jsonMarkersArr[i].properties[0].category === 'Land') {
                    html = html + "<span class='legend-land'></span> ";
                  }
                  if (jsonMarkersArr[i].properties[0].category === 'Luxury') {
                    html = html + "<span class='legend-luxury'></span> ";
                  }
                  if (jsonMarkersArr[i].properties[0].category === 'Special Use') {
                    html = html + "<span class='legend-special-use'></span> ";
                  }
                  if (jsonMarkersArr[i].properties[0].category === 'Room') {
                    html = html + "<span class='legend-room'></span> ";
                  }
                  html = html + jsonMarkersArr[i].properties[0].prop_cat_type;
                  html = html + "</p>"
                  html = html + "</div>";
                  html = html + "</div>";
                }

                if (jsonMarkersArr[i].properties[0].address) {
                  html = html + "<div class=\"row m-0 p-0 ps-2 pe-2\">";
                  html = html + "<div class=\"col-12 m-0 p-0\">";
                  html = html + "<p class=\"m-0 p-0\">" + jsonMarkersArr[i].properties[0].address + "</p>";
                  html = html + "</div>";
                  html = html + "</div>";
                }

                if (jsonMarkersArr[i].properties[0].date_start && jsonMarkersArr[i].properties[0].type === 'Rent') {
                  html = html + "<div class=\"row m-0 p-0 ps-2 pe-2\">";
                  html = html + "<div class=\"col-12 m-0 p-0\">";
                  if (jsonMarkersArr[i].properties[0].date_start && jsonMarkersArr[i].properties[0].date_end) {
                    html = html + "<p class=\"m-0 p-0\"><span class=\"fw-bold\">Required Dates: </span>" + jsonMarkersArr[i].properties[0].date_start + " - " + jsonMarkersArr[i].properties[0].date_end + "</p>";
                  } else {
                    html = html + "<p class=\"m-0 p-0\"><span class=\"fw-bold\">Required Dates: </span>" + jsonMarkersArr[i].properties[0].date_start + " - " + "TBD" + "</p>";
                  }
                  html = html + "</div>";
                  html = html + "</div>";
                }

                if (jsonMarkersArr[i].properties[0].date_start && jsonMarkersArr[i].properties[0].type === 'Let') {
                  html = html + "<div class=\"row m-0 p-0 ps-2 pe-2\">";
                  html = html + "<div class=\"col-12 m-0 p-0\">";
                  if (jsonMarkersArr[i].properties[0].date_start && jsonMarkersArr[i].properties[0].date_end) {
                    html = html + "<p class=\"m-0 p-0\"><span class=\"fw-bold\">Availability: </span>" + jsonMarkersArr[i].properties[0].date_start + " - " + jsonMarkersArr[i].properties[0].date_end + "</p>";
                  } else {
                    html = html + "<p class=\"m-0 p-0\"><span class=\"fw-bold\">Availability: </span>" + jsonMarkersArr[i].properties[0].date_start + " - " + "TBD" + "</p>";
                  }
                  html = html + "</div>";
                  html = html + "</div>";
                }

                if (jsonMarkersArr[i].properties[0].title) {
                  html = html + "<div class=\"row m-0 p-0 ps-2 pe-2\">";
                  html = html + "<div class=\"col-12 m-0 p-0\">";
                  html = html + "<p class=\"m-0 p-0\"><span class=\"fw-bold\">Title: </span>" + jsonMarkersArr[i].properties[0].title + "</p>";
                  html = html + "</div>";
                  html = html + "</div>";
                }

                html = html + "<div class=\"row m-0 p-0 ps-2 pe-2\">";
                html = html + "<div class=\"col-6 m-0 p-0\">";
                html = html + "<p class=\"m-0 p-0\"><span class=\"fw-bold\">Price:</span> <span class=\"price-color\">£" + this.convertNumberToCommas(jsonMarkersArr[i].properties[0].price) + "</span> ";
                if (jsonMarkersArr[i].properties[0].type === 'Rent' || jsonMarkersArr[i].properties[0].type === 'Let') {
                  html = html + "<span> per " + jsonMarkersArr[i].properties[0].rent_type + " </span>";
                }
                html = html + "</p>";
                html = html + "</div>";

                if (jsonMarkersArr[i].properties[0].type !== 'Rent' && jsonMarkersArr[i].properties[0].type !== 'Let') {
                  html = html + "<div class=\"col-6 m-0 p-0\">";
                  html = html + "<p class=\"m-0 p-0\"><span class=\"fw-bold\">Tenure:</span> " + jsonMarkersArr[i].properties[0].tenure + "</p>";
                  html = html + "</div>";
                }
                  html = html + "</div>";

                html = html + "<div class=\"row m-0 p-0 ps-2 pe-2\">";
                if (jsonMarkersArr[i].properties[0].details.single_room_suite && jsonMarkersArr[i].properties[0].details.double_room_suite) {
                  html = html + "<div class=\"col-12 m-0 p-0\">";
                  html = html + "<p class=\"m-0 p-0\"><span class=\"fw-bold\">Apartment/Suite Beds:</span> " + this.calculateSuiteBeds(jsonMarkersArr[i].properties[0].details) + "</p>";
                  html = html + "</div>";
                }

                html = html + "<div class=\"col-6 m-0 p-0\">";
                html = html + "<p class=\"m-0 p-0\"><span class=\"fw-bold\">Bedrooms:</span> " + this.calculateBeds(jsonMarkersArr[i].properties[0].details) + "</p>";
                html = html + "</div>";

                html = html + "<div class=\"col-6 m-0 p-0\">";
                html = html + "<p class=\"m-0 p-0\"><span class=\"fw-bold\">Living Rooms:</span> " + jsonMarkersArr[i].properties[0].details.living_room + "</p>";
                html = html + "</div>";
                html = html + "<div class=\"col-6 m-0 p-0\">";
                html = html + "<p class=\"m-0 p-0\"><span class=\"fw-bold\">Parking:</span> " + this.calculateParking(jsonMarkersArr[i].properties[0].details.driveway, jsonMarkersArr[i].properties[0].details.garage, jsonMarkersArr[i].properties[0].details.parking, jsonMarkersArr[i].properties[0].details.parking_spaces) + "</p>";
                html = html + "</div>";

                html = html + "<div class=\"col-6 m-0 p-0\">";
                html = html + "<p class=\"m-0 p-0\"><span class=\"fw-bold\">Garden:</span> " + this.calculateGarden(jsonMarkersArr[i].properties[0].details.front_garden, jsonMarkersArr[i].properties[0].details.rear_garden) + "</p>";
                html = html + "</div>";

                html = html + "<div class=\"col-6 m-0 p-0\">";
                html = html + "<p class=\"m-0 p-0\"><span class=\"fw-bold\">Bathrooms:</span> " + jsonMarkersArr[i].properties[0].details.bath_room + "</p>";
                html = html + "</div>";

                html = html + "<div class=\"col-6 m-0 p-0\">";
                html = html + "<p class=\"m-0 p-0\"><span class=\"fw-bold\">Downstairs WC:</span> " + jsonMarkersArr[i].properties[0].details.downstairs_wc + "</p>";
                html = html + "</div>";
                html = html + "</div>";

                html = html + "<div class=\"row m-0 p-0 text-center\">";
                if (jsonMarkersArr[i].properties[0].auction) {
                  html = html + "<div class=\"col-6 m-0 p-1\">";
                  html = html + "<p class=\"info-label m-0\"><span class=\"fw-bold\">" + jsonMarkersArr[i].properties[0].auction + "</span></p>";
                  html = html + "</div>";
                }

                if (jsonMarkersArr[i].properties[0].coming_soon) {
                  html = html + "<div class=\"col-6 m-0 p-1\">";
                  html = html + "<p class=\"info-label m-0\"><span class=\"fw-bold\">" + jsonMarkersArr[i].properties[0].coming_soon + "</span></p>";
                  html = html + "</div>";
                }

                if (jsonMarkersArr[i].properties[0].cash) {
                  html = html + "<div class=\"col-6 m-0 p-1\">";
                  html = html + "<p class=\"info-label m-0\"><span class=\"fw-bold\">" + jsonMarkersArr[i].properties[0].cash + "</span></p>";
                  html = html + "</div>";
                }

                if (jsonMarkersArr[i].properties[0].mixed) {
                  html = html + "<div class=\"col-6 m-0 p-1\">";
                  html = html + "<p class=\"info-label m-0\"><span class=\"fw-bold\">" + jsonMarkersArr[i].properties[0].mixed + "</span></p>";
                  html = html + "</div>";
                }

                if (jsonMarkersArr[i].properties[0].shared) {
                  html = html + "<div class=\"col-6 m-0 p-1\">";
                  html = html + "<p class=\"info-label m-0\"><span class=\"fw-bold\">" + jsonMarkersArr[i].properties[0].shared + "</span></p>";
                  html = html + "</div>";
                }

                if (jsonMarkersArr[i].properties[0].hmo) {
                  html = html + "<div class=\"col-6 m-0 p-1\">";
                  html = html + "<p class=\"info-label m-0\"><span class=\"fw-bold\">" + jsonMarkersArr[i].properties[0].hmo + "</span></p>";
                  html = html + "</div>";
                }

                if (jsonMarkersArr[i].properties[0].investment) {
                  html = html + "<div class=\"col-6 m-0 p-1\">";
                  html = html + "<p class=\"info-label m-0\"><span class=\"fw-bold\">" + jsonMarkersArr[i].properties[0].investment + "</span></p>";
                  html = html + "</div>";
                }

                html = html + "</div>";


                html = html + "</div>";

                html = html + "</div>";
                // End Set html for popups
                let self = this;

                // boolean values used to assign event listeners just once
                let propName = 'eventSet' + i;
                this[propName] = false;

                let marker = new mapboxgl.Marker(el)
                    .setLngLat(jsonMarkersArr[i].geometry.coordinates)
                    .setPopup(
                        // I can add custom class, I need to work out how to make nice transitions (fade in fade out)
                        new mapboxgl.Popup({offset: 25, className: "popup-transition popup-map", closeOnClick: true})
                            .setHTML(html) // html should be valid for singular marker but also for multiple markers (clustered or same location)
                            .on('open', e => {
                              // Mark marker as viewed (changes color of marker/price popup)
                              if (el) {
                                let children = el.children;
                                if (children && children[0]) {
                                  children[0].classList.add('marker-viewed');
                                }
                              }

                              let idName = "previewProp-" + jsonMarkersArr[i].properties[0].id;
                              const btn = document.getElementById(idName);
                              // We need to pass property ID and its INDEX to the previewProperty function, so that it displays correct one on the listing
                              // EDIT: I need to pass property ID, index is not necessary anymore... it seems so..
                              let callback = function () {
                                // Open preview property data
                                self.previewProperty(jsonMarkersArr[i].properties[0].id, jsonMarkersArr[i].properties[0].index)
                              };
                              // once: true is very important below, otherwise we get same handler created several times
                              if (this[propName] === false) {
                                btn.removeEventListener('click', callback, true);
                                btn.addEventListener('click', callback, true);
                                this[propName] = true;
                              }
                            })
                        // .on('close', e => {
                        //     let idName = "previewProp-" + i;
                        //     const btn = document.getElementById(idName);
                        //     let callback = function () { self.previewProperty() };
                        //     if(btn) {
                        //       btn.removeEventListener('click', callback,true);
                        //     }
                        //     console.log('removed');
                        //   })
                    )
                    .setRotation(-45)
                    .addTo(map);

                // Assign markers to global scope of this component
                if (marker) {
                  this.markers.push(marker);

                  /* Start Add touch listeners on markers and map-canvas */
                  // Get current marker
                  let currentMarker = document.getElementsByClassName('marker-' + i);

                  let callbackFunction = function (event) {
                    event.stopPropagation();
                    // Check if there is already a popup on the map and if so, remove it
                    let previousPopup = document.getElementsByClassName('mapboxgl-popup');
                    if (Object.entries(previousPopup).length > 0) {
                      previousPopup[0].parentNode.removeChild(previousPopup[0]);
                    }

                    marker.togglePopup();
                  };
                  // #RAF Check if you can use tap rather than touchend
                  // attach touch event to marker so it works on mobile
                  currentMarker[0].removeEventListener('touchend', callbackFunction);
                  currentMarker[0].addEventListener('touchend', callbackFunction);

                  // Get map canvas
                  let mapCanvas = document.getElementsByClassName('mapboxgl-canvas');

                  let dragging = false;

                  let canvasTouchEndCallbackFunction = () => {
                    if (dragging) {
                      return;
                    }
                    // Check if there is already a popup on the map and if so, remove it
                    let previousPopup = document.getElementsByClassName('mapboxgl-popup');
                    if (Object.entries(previousPopup).length > 0) {
                      previousPopup[0].parentNode.removeChild(previousPopup[0]);
                    }
                  }

                  let canvasDragCallbackFunction = () => {
                    dragging = true;
                  }

                  let canvasTouchStartCallbackFunction = (e) => {
                    // e.preventDefault()
                    // e.stopPropagation()
                    dragging = false;
                  }

                  // #RAF passive : false should prevent error which occurs in console, however it does not work...
                  // I need to work this out...
                  // Listen on touch on mapCanvas and close marker popup
                  if (Object.entries(mapCanvas).length > 0) {
                    mapCanvas[0].removeEventListener('touchstart', canvasTouchStartCallbackFunction, {passive: false, capture: true});
                    mapCanvas[0].addEventListener('touchstart', canvasTouchStartCallbackFunction, {passive: false, capture: true});
                    mapCanvas[0].removeEventListener('touchmove', canvasDragCallbackFunction, {passive: false, capture: true});
                    mapCanvas[0].addEventListener('touchmove', canvasDragCallbackFunction, {passive: false, capture: true});
                    mapCanvas[0].removeEventListener('touchend', canvasTouchEndCallbackFunction, {passive: false, capture: true});
                    mapCanvas[0].addEventListener('touchend', canvasTouchEndCallbackFunction, {passive: false, capture: true});
                  }
                  /* End Add touch listeners on markers and map-canvas */
                }

              }



            // End if grouped markers set to null
            }
            // End loop markers array
          }
        }

      });
    },
    mapFlyToLocation() {
      // If previous address was exactly same, do not flyTo the location again, as otherwise it would trigger on every draw edit
      if(
          this.$store.state.searchFilters.searchFilters &&
          this.$store.state.searchFilters.searchFilters.drawbboxs &&
          this.$store.state.searchFilters.searchFilters.drawbboxs.length > 0 &&
          this.lastSearchLocation === this.$store.state.searchFilters.searchFilters.address
      ) {
        return;
      }
      let latitude = this.$store.state.searchFilters.searchFilters.latitude ? this.$store.state.searchFilters.searchFilters.latitude : null;
      let longitude = this.$store.state.searchFilters.searchFilters.longitude ? this.$store.state.searchFilters.searchFilters.longitude : null;
      // Check if we hold data of city in searchFilters, if not, that would mean we are searching county (apply different zoom)
      let county = this.$store.state.searchFilters.searchFilters.city ? false : true;

      if (latitude && longitude) {
        this.map.flyTo({
          center: [
            longitude,
            latitude
          ],
          zoom: county === true ? 8 : 12,
          essential: true
        });
      }
    },
    // Helpers for LET ads to reflect short-term and long-term lets data
    async checkAdIsLetShortTermOrRoom(prop) {
      if (prop && prop.is_let && ((prop.is_short_term && !prop.property_details.studio_room) || prop.is_room)) {
        return true;
      } else {
        return false;
      }
    },
    checkAdIsLetLongTermOrStudio(prop) {
      if (prop && prop.is_let && ((!prop.is_short_term && !prop.is_room) || prop.property_details.studio_room)) {
        return true;
      } else {
        return false;
      }
    },
    async showPriceRange(prop) {
      if (await this.checkAdIsLetShortTermOrRoom(prop)) {
        return true;
      } else {
        return false;
      }
    },
    async showHourPriceRange(prop) {
      if (prop.property_rooms && prop.property_rooms.length > 0) {
        // get hourly prices
        let hourlyPrices = prop.property_rooms.filter(obj => {
          if (obj.property_rent_type_id !== 2) {
            return false;
          } else {
            return true;
          }
        }).map(object => {
          return object.price;
        });

        if (hourlyPrices.length > 0) {
          return true;
        } else {
          return false;
        }
      }
    },
    async calculateHourPriceRange(prop) {
      if (prop.property_rooms && prop.property_rooms.length > 0) {
        let maxHourlyPrice = null;
        let minHourlyPrice = null;

        // get hourly prices
        let hourlyPrices = prop.property_rooms.filter(obj => {
          if (obj.property_rent_type_id !== 2) {
            return false;
          } else {
            return true;
          }
        }).map(object => {
          return object.price;
        });

        if (hourlyPrices.length > 0) {
          maxHourlyPrice = Math.max.apply(null, hourlyPrices);
          minHourlyPrice = Math.min.apply(null, hourlyPrices);
        }

        if (minHourlyPrice && maxHourlyPrice) {
          if (minHourlyPrice == maxHourlyPrice) {
            return this.convertNumberToCommas(minHourlyPrice);
          } else {
            return this.convertNumberToCommas(minHourlyPrice) + ' - ' + this.convertNumberToCommas(maxHourlyPrice);
          }
        }
      }
    },
    async showNightPriceRange(prop) {
      if (prop.property_rooms && prop.property_rooms.length > 0) {
        // get nightly prices
        let nightlyPrices = prop.property_rooms.filter(obj => {
          if (obj.property_rent_type_id !== 3) {
            return false;
          } else {
            return true;
          }
        }).map(object => {
          return object.price;
        });

        if (nightlyPrices.length > 0) {
          return true;
        } else {
          return false;
        }
      }
    },
    async calculateNightPriceRange(prop) {
      if (prop.property_rooms && prop.property_rooms.length > 0) {
        let maxNightlyPrice = null;
        let minNightlyPrice = null;

        // get nightly prices
        let nightlyPrices = prop.property_rooms.filter(obj => {
          if (obj.property_rent_type_id !== 3) {
            return false;
          } else {
            return true;
          }
        }).map(object => {
          return object.price;
        });

        if (nightlyPrices.length > 0) {
          maxNightlyPrice = Math.max.apply(null, nightlyPrices);
          minNightlyPrice = Math.min.apply(null, nightlyPrices);
        }

        if (minNightlyPrice && maxNightlyPrice) {
          if (minNightlyPrice == maxNightlyPrice) {
            return this.convertNumberToCommas(minNightlyPrice);
          } else {
            return this.convertNumberToCommas(minNightlyPrice) + ' - ' + this.convertNumberToCommas(maxNightlyPrice);
          }
        }
      }
    },
    async showWeekPriceRange(prop) {
      if (prop.property_rooms && prop.property_rooms.length > 0) {
        // get weekly prices
        let weeklyPrices = prop.property_rooms.filter(obj => {
          if (obj.property_rent_type_id !== 4) {
            return false;
          } else {
            return true;
          }
        }).map(object => {
          return object.price;
        });

        if (weeklyPrices.length > 0) {
          return true;
        } else {
          return false;
        }
      }
    },
    async calculateWeekPriceRange(prop) {
      if (prop.property_rooms && prop.property_rooms.length > 0) {
        let maxWeeklyPrice = null;
        let minWeeklyPrice = null;

        // get weekly prices
        let weeklyPrices = prop.property_rooms.filter(obj => {
          if (obj.property_rent_type_id !== 4) {
            return false;
          } else {
            return true;
          }
        }).map(object => {
          return object.price;
        });

        if (weeklyPrices.length > 0) {
          maxWeeklyPrice = Math.max.apply(null, weeklyPrices);
          minWeeklyPrice = Math.min.apply(null, weeklyPrices);
        }

        if (minWeeklyPrice && maxWeeklyPrice) {
          if (minWeeklyPrice == maxWeeklyPrice) {
            return this.convertNumberToCommas(minWeeklyPrice);
          } else {
            return this.convertNumberToCommas(minWeeklyPrice) + ' - ' + this.convertNumberToCommas(maxWeeklyPrice);
          }
        }
      }
    },
    async showMonthPriceRange(prop) {
      if (prop.property_rooms && prop.property_rooms.length > 0) {
        // get monthly prices
        let monthlyPrices = prop.property_rooms.filter(obj => {
          if (obj.property_rent_type_id !== 5) {
            return false;
          } else {
            return true;
          }
        }).map(object => {
          return object.price;
        });

        if (monthlyPrices.length > 0) {
          return true;
        } else {
          return false;
        }
      }
    },
    async calculateMonthPriceRange(prop) {
      if (prop.property_rooms && prop.property_rooms.length > 0) {
        let maxMonthlyPrice = null;
        let minMonthlyPrice = null;

        // get monthly prices
        let monthlyPrices = prop.property_rooms.filter(obj => {
          if (obj.property_rent_type_id !== 5) {
            return false;
          } else {
            return true;
          }
        }).map(object => {
          return object.price;
        });

        if (monthlyPrices.length > 0) {
          maxMonthlyPrice = Math.max.apply(null, monthlyPrices);
          minMonthlyPrice = Math.min.apply(null, monthlyPrices);
        }

        if (minMonthlyPrice && maxMonthlyPrice) {
          if (minMonthlyPrice == maxMonthlyPrice) {
            return this.convertNumberToCommas(minMonthlyPrice);
          } else {
            return this.convertNumberToCommas(minMonthlyPrice) + ' - ' + this.convertNumberToCommas(maxMonthlyPrice);
          }
        }
      }
    },
    // End Helpers for LET ads to reflect short-term and long-term lets data
    convertNumberToCommas(num) {
      if (num && num !== '' && num !== null) {
        return num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return '0';
      }
    },
    calculateBeds(propDetails) {
      return Number(propDetails.single_room) + Number(propDetails.double_room) + Number(propDetails.twin_room) + Number(propDetails.twin_double_room) +
          Number(propDetails.triple_room) + Number(propDetails.quadruple_room) + Number(propDetails.family_room) + Number(propDetails.studio_room);
    },
    calculateSuiteBeds(propDetails) {
      return Number(propDetails.single_room_suite) + Number(propDetails.double_room_suite);
    },
    calculateParking(driveway = 0, garage = 0, parking = 0, parkingSpaces = 0) {
      if (parking && parkingSpaces > 0) {
        return Number(driveway) + Number(garage) + Number(parkingSpaces);
      } else {
        return Number(driveway) + Number(garage) + Number(parking);
      }
    },
    calculateGarden(frontGarden, rearGarden) {
      return frontGarden + rearGarden;
    },
    calculateCreatedDate(date) {
      let resultDate = null;

      let currentDate = new Date();
      let propDate = new Date(date);
      let numberOfDays = (currentDate.getTime() - propDate.getTime()) / (1000*60*60*24);

      switch (true) {
        case numberOfDays <= 1:
          resultDate = "Today";
          break;
        case (numberOfDays <= 30 && numberOfDays > 1):
          if(numberOfDays < 2) {
            resultDate = Math.round(numberOfDays)+" day ago";
          } else {
            resultDate = Math.round(numberOfDays)+" days ago";
          }
          break;
        case numberOfDays > 30:
          let numberOfMonths = Math.round(numberOfDays / 30);
          if(numberOfMonths === 1) {
            resultDate = numberOfMonths+" month ago";
          } else {
            resultDate = numberOfMonths+" months ago"
          }
          break;
      }

      return resultDate;

    },
    getImage(file_name) {
      try {
        return require(`@/assets/${file_name}`);
      } catch (e) {
        return false
      }
    },
    setDefaultImg(e) {
      e.target.src = require(`@/assets/no-image-found.png`);
    },
    checkImage(url) {
      let img = new Image();
      img.src = url;

      if (img.complete) {
        // console.log('true - image exists');
        return true;
      } else {
        // console.log('false - image doesnt exists');
        return false;
      }
    },
    previewProperty(id, index) {
      // async await would be beneficial here? So that first we open List component and then PropDetails
      let mapTrigger = true;
      this.$root.$emit('openListComponent', mapTrigger);
      this.$root.$emit('openPropDetailsComponent', id, index);
    },
  },
  async mounted() {
    this.loading = true;
    await this.initializeMap();
    // this.initMap() (I've decided to move map out of store to component itself, I will keep markers in store)
    // this.getMap()
    this.loading = false;
  },
  watch: {
    // If change in properties_map we need to revise markers on map, however only if map is initialised
    '$store.state.properties.properties_map': {
      handler: function (val) {
        this.mapFlyToLocation();
        this.setMarkers(this.map);
      }, deep: true
    },
    '$store.state.properties.loading': {
      handler: function (val) {
        this.loading = this.$store.state.properties.loading;
      }, deep: true
    }
  }
}
</script>
<!-- Second style is just for keyframes and animations, for time being they do not work with style scoped -->
<style lang="scss">
@keyframes test {
  from { opacity: 0.1; }
}

.blinking {
  animation: test 1s infinite alternate !important;
}
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.hideLegend {
  z-index: 1000;
  .btn-box-eye {
    display: block;
    align-content: center;
    vertical-align: middle;
    height: 100%;
    width: auto;
  }

  .btn-eye {
    color: white;
    background-color: white;
    line-height: 30px;
    font-size: 26px;
    border-radius: 50%;
    padding: 1px 5px;
    border: 0;
  }

  .btn-eye:hover {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    transform: scale(1);
    animation: pulse 2s infinite;
  }
}

.showLegend {
  display: none;
  position: absolute;
  top: 30vh;
  right: 1vh;
  z-index: 1000;
  .btn-box-eye {
    display: block;
    align-content: center;
    vertical-align: middle;
    height: 100%;
    width: auto;
  }

  .btn-eye {
    color: white;
    background-color: white;
    line-height: 30px;
    font-size: 26px;
    border-radius: 50%;
    padding: 1px 5px;
    border: 0;
  }

  .btn-eye:hover {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    transform: scale(1);
    animation: pulse 2s infinite;
  }
}

/* Start Map Legend */
.map-overlay {
  position: absolute;
  bottom: 40%;
  right: 0;
  background: #fff;
  margin-right: 10px;
  font-family: Arial, sans-serif;
  overflow: auto;
  border-radius: 3px;
  z-index: 3;
}

#legend {
  display: block;
  text-align: left;
  padding: 10px;
  box-shadow: 0 0 0 2px rgb(0 0 0 / 10%);
  line-height: 18px;
  height: 200px;
  margin-bottom: 40px;
  width: 150px;
}
/* End Map Legend */

.category-label {
  border: 1px solid #868e96;
  border-radius: 10px;
  width: 100px;
  min-width: 100px;
}

.animated-icon{
  width: 160px;
  height: 160px;
  background-color: rgba(255,255,255,100%);
  border-radius: 50%;
  box-shadow: 0px 0px 4px white;
  transition: all 1s;
}

.animated-icon:hover {
  transform: scale(1.2) !important;
  transition: all 1s;
}

#mapid {
  display:block;
  width: 100px;
  height: 88vh;
  min-width: 100%;
  min-height: 88vh;
  background-color: white;
}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

#map {
  width: 100%;
  height: 100%;
}

.map {
  display:block;
  width: 100px;
  height: 88vh;
  min-width: 100%;
  min-height: 88vh; // 90vh
  background-color: white;
}

.mt--1 {
  margin-top: -16px;
}

.mt--2 {
  margin-top: -32px;
}

.mt--3 {
  margin-top: -48px;
}

.mt--4 {
  margin-top: -64px;
}

.mt--5 {
  margin-top: -80px;
}

</style>
