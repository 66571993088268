<template>
  <div id="nav-bg" class="nav-bg sticky"> <!-- fixed-top - I need to use sticky + % for height to prevent squezing -->
    <nav class="navbar navbar-expand-md navbar-light nav-main">
      <div class="container-fluid justify-content-center ms-5 me-5">
        <div class="mx-auto order-0 max-height-30">
          <!-- Start Notifications Dropdown -->
          <ul class="navbar-nav flex-row mobile-flex-wrap justify-content-center ms-auto d-block d-sm-block d-md-none d-lg-none d-xl-none d-xxl-none">
            <li v-if="this.$store.state.auth.authenticated === true && this.$store.state.auth.user" class="nav-item dropdown notifications-icon">
              <router-link to="/services" class="text-decoration-none">
                <a class="nav-link nav-link-color notification-icon" href="#" id="notificationDropdownMobile" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <div class="notifications-counter" v-if="notifications && notifications.length > 0">{{notifications.length <= 99 ? notifications.length : "99"}}</div>
                  <font-awesome-icon icon="bell" :class="newNotification ? 'icon-fa notification-icon-notify' : 'icon-fa notification-icon-not-notify'"/>
                </a>
                <ul class="dropdown-menu notifications-dropdown scroll-width-thin" aria-labelledby="notificationDropdownMobile">
                  <router-link to="/dashboard-notifications" class="text-decoration-none cursor-pointer">
                    <li class="text-center cursor-pointer"><p class="dropdown-item excerpt-in-login cursor-pointer">Notifications</p></li>
                  </router-link>
                  <li><hr class="dropdown-divider"/></li>
                  <li v-for="(notification, index) in notifications" :key="index" class="notification-list-li">
                    <router-link :to="{path: notification.data.url + '/' + notification.data.chat_room_id}" class="text-decoration-none" @click.native.prevent="markNotificationAsRead(notification.data.notification_id, notification.data.url, notification.data.chat_room_id)">
                      <div class="notification-item text-center">
                        {{notification.data.message}}
                        <hr class="dropdown-divider"/>
                        {{( moment.utc(notification.created_at).format('DD-MM-YYYY') < moment.utc().format('DD-MM-YYYY') ) ? moment.utc(notification.created_at).format('DD-MM-YYYY') : moment.utc(notification.created_at).format('H:mm')}}
                      </div>
                    </router-link>
                  </li>
                  <li v-if="notifications && notifications.length === 0" class="text-center"><p class="dropdown-item excerpt-in-login">No notifications</p></li>
                </ul>
              </router-link>
            </li>
          </ul>
          <!-- End Notifications Dropdown -->
          <router-link to="/" class="me-auto text-decoration-none">
            <a class="navbar-brand mx-auto logo text-decoration-none" href="#">
              <div style="width: 180px; height: 35px; overflow: hidden; margin-left: -20px;">
              <img src="@/assets/logo-easetate.png" alt="" width="100%" height="30" class="d-inline-block align-text-top img-fluid logo-name" />
              </div>
            </a>
          </router-link>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target=".dual-collapse2">
            <span class="navbar-toggler-icon"></span>
          </button>
        </div>
      </div>
    </nav>
    <nav class="navbar navbar-expand-md navbar-dark max-height-60 nav-bottom">
      <div class="container-fluid justify-content-center m-auto align-items-start"> <!-- justify-content-center -->
        <div class="navbar-collapse align-items-start collapse w-100 order-1 order-md-0 dual-collapse2 z-index-special mobile-top-margin">
          <ul class="navbar-nav justify-content-center flex-row me-auto">
            <li class="nav-item">
              <router-link to="/donate" class="text-decoration-none">
                <a class="nav-link nav-link-color-donate" @click="closeMobileMenu()"><font-awesome-icon icon="hand-holding-heart" class="icon-fa ms-1 me-1" />Donate</a>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link :key="this.$route.fullPath" :to="{ name: 'addeditad' }" class="text-decoration-none">
<!--                <a class="nav-link nav-link-color">Add Property</a>-->
                <a class="nav-link nav-link-color-add-property float-start btn-add" @click="closeMobileMenu()">Add Property <font-awesome-icon icon="plus-circle" class="icon-fa add-icon" /></a>
              </router-link>
            </li>
<!--            <li class="nav-item">-->
<!--              <router-link :key="this.$route.fullPath" :to="{ name: 'addeditadmultistep' }" class="text-decoration-none">-->
<!--                &lt;!&ndash;                <a class="nav-link nav-link-color">Add Property</a>&ndash;&gt;-->
<!--                <a class="nav-link nav-link-color-add-property float-start btn-add" @click="closeMobileMenu()">Add Property MultiStep <font-awesome-icon icon="plus-circle" class="icon-fa add-icon" /></a>-->
<!--              </router-link>-->
<!--            </li>-->
            <li class="nav-item">
              <router-link :key="this.$route.fullPath" :to="{ name: 'addeditservice' }" class="text-decoration-none">
<!--                <a class="nav-link nav-link-color">Add Service</a>-->
                <a class="nav-link nav-link-color-add-service float-start btn-add" @click="closeMobileMenu()">Add Service <font-awesome-icon icon="plus-circle" class="icon-fa add-icon" /></a>
              </router-link>
            </li>
<!--            <li class="nav-item active">-->
<!--              <router-link :key="this.$route.fullPath" :to="{ name: 'addeditad', query: { type: 'sell' }}" class="text-decoration-none">-->
<!--                <a class="nav-link nav-link-color">Sell</a>-->
<!--              </router-link>-->
<!--            </li>-->
<!--            <li class="nav-item active">-->
<!--              <router-link :key="this.$route.fullPath" :to="{ name: 'addeditad', query: { type: 'buy' }}" class="text-decoration-none">-->
<!--                <a class="nav-link nav-link-color">Buy</a>-->
<!--              </router-link>-->
<!--            </li>-->
<!--            <li class="nav-item">-->
<!--              <router-link :key="this.$route.fullPath" :to="{ name: 'addeditad', query: { type: 'let' }}" class="text-decoration-none">-->
<!--                <a class="nav-link nav-link-color">Let</a>-->
<!--              </router-link>-->
<!--            </li>-->
<!--            <li class="nav-item">-->
<!--              <router-link :key="this.$route.fullPath" :to="{ name: 'addeditad', query: { type: 'rent' }}" class="text-decoration-none">-->
<!--                <a class="nav-link nav-link-color">Rent</a>-->
<!--              </router-link>-->
<!--            </li>-->

<!--            <li class="nav-item">-->
<!--              <router-link to="/swap" class="text-decoration-none">-->
<!--                <a class="nav-link nav-link-color">Swap</a>-->
<!--              </router-link>-->
<!--            </li>-->
          </ul>
        </div>
<!--        <div class="mx-auto order-0 nav-mid">-->
<!--        </div>-->
        <div class="mx-auto order-0 position-relative vuenav-btns">
<!--          <ul style="list-style-type:none" class="nav-buttons-menu">-->
<!--            <li style="display:inline-block;">-->
          <div class="row">
            <div class="col-4">
              <transition name="fade">
                <div id="mypropModal" class="propModal prop-modal-tab">
                  <div class="col-sm-12 col-md-12 col-lg-12 sticky-top propModalStickyTop">
                    <router-link v-if="this.$store.state.auth.user.contact && this.$store.state.auth.user.contact.email && this.$store.state.auth.authenticated === true" to="/add-edit-ad" class="text-decoration-none">
<!--                      <button class="btn btn-success float-start addbtn" v-on:click="closePropModal"> + Add</button>-->
                      <button  v-on:click="closePropModal" class="btn btn-outline-primary float-start btn-add addbtn" type="button">Add <font-awesome-icon icon="plus-circle" class="icon-fa add-icon" /></button>
                    </router-link>
                    <div> <!-- search-filter-btns -->
                      <select
                          v-model="sortBy"
                          class="select-placeholder-sortbybtn float-end me-5 sortbybtn cursor-pointer"
                          @focus="handleFocus($event)"
                          @focusout="handleFocusOut(sortBy, $event)"
                          tabindex="-1"
                          aria-label="sortBy"
                          name="sortBy"
                          id="sortBy"
                      >
                        <option value="" disabled>Sort by</option>
                        <option class="text-black" :value="1">Newest</option>
                        <option class="text-black" :value="2">Oldest</option>
                        <option class="text-black" :value="3">Lowest Price</option>
                        <option class="text-black" :value="4">Highest Price</option>
                      </select>
                    </div>
                    <div class="closebtn-box">
<!--                      <a href="javascript:void(0)" class="closebtn float-end" v-on:click="closePropModal">×</a>-->
                      <button v-on:click="closePropModal" class="btn btn-outline-primary float-start close-btn" type="button">×</button>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-12">
                    <List ref="childListAdDetailsRef"/>
                  </div>
                </div>
              </transition>

              <span data-tooltip="tooltip">
                <div class="no-text-wrap overflow-hidden text-truncate">
                  <button id="propModal" v-on:click="openPropModal" class="btn btn-outline-primary btn-vuenav openbtnPropModal btn-round"><font-awesome-icon icon="list-alt" /></button>
                  <span class="nav-btn-tooltiptext">List</span>
                </div>
              </span>

<!--              <button id="propModal" v-on:click="openPropModal" class="btn btn-outline-primary btn-vuenav openbtnPropModal btn-round"><font-awesome-icon icon="list-alt" /></button>-->
<!--              <div id="mainPropModal">-->
<!--                <button id="propModal" v-on:click="openPropModal" class="btn btn-outline-primary btn-vuenav openbtnPropModal btn-round"><font-awesome-icon icon="list-alt" /></button>-->
<!--              </div>-->
            </div>
            <div class="col-4">
              <SearchFilters @refreshPropsNav="refreshPropsNav"/>
            </div>
            <div class="col-4">
              <span data-tooltip="tooltip">
                <div class="no-text-wrap overflow-hidden text-truncate">
                 <router-link to="/" class="text-decoration-none">
                    <button class="btn btn-outline-success btn-vuenav btn-round"><font-awesome-icon icon="map-marked" /></button>
                  </router-link>
                  <span class="nav-btn-tooltiptext">Map View</span>
                </div>
              </span>
<!--              <router-link to="/" class="text-decoration-none">-->
<!--                <button class="btn btn-outline-success btn-vuenav btn-round"><font-awesome-icon icon="map-marked" /></button>-->
<!--              </router-link>-->
            </div>
          </div>
<!--              <transition name="fade">-->
<!--                <div id="mypropModal" class="propModal modal-tab">-->
<!--                  <div class="col-sm-12 col-md-12 col-lg-12 sticky-top propModalStickyTop">-->
<!--                    <router-link v-if="this.$store.state.auth.user.email && this.$store.state.auth.authenticated === true" to="/add-edit-ad" class="text-decoration-none">-->
<!--                      <button class="btn btn-success float-start addbtn" v-on:click="closePropModal"> + Add</button>-->
<!--                    </router-link>-->
<!--                    <a href="javascript:void(0)" class="closebtn float-end" v-on:click="closePropModal">×</a>-->
<!--                  </div>-->
<!--                  <div class="col-sm-12 col-md-12 col-lg-12">-->
<!--                    <List ref="childListAdDetailsRef"/>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </transition>-->
<!--              <div id="mainPropModal">-->
<!--                <button id="propModal" v-on:click="openPropModal" class="btn btn-outline-primary zindex btn-vuenav openbtnPropModal btn-round"><font-awesome-icon icon="list-alt" /></button>-->
<!--              </div>-->
<!--              <div id="mainPropModal">-->
<!--                <button id="propModal" v-on:click="openPropModal" class="btn btn-outline-primary mt-3 zindex btn-vuenav openbtnPropModal btn-round"><font-awesome-icon icon="list-alt" /></button>-->
<!--              </div>-->
<!--            </li>-->
<!--            <li style="display:inline-block;">-->
<!--              <SearchFilters @refreshPropsNav="refreshPropsNav"/>-->
<!--            </li>-->
<!--            <li style="display:inline-block;">-->
<!--              <router-link to="/" class="text-decoration-none">-->
<!--                <button class="btn btn-outline-success zindex btn-vuenav btn-round"><font-awesome-icon icon="map-marked" /></button>-->
<!--              </router-link>-->
<!--              <router-link to="/" class="me-auto text-decoration-none">-->
<!--                <button class="btn btn-outline-success m-3 zindex btn-vuenav btn-round"><font-awesome-icon icon="map-marked" /></button>-->
<!--              </router-link>-->
<!--            </li>-->
<!--          </ul>-->
        </div>
        <div class="navbar-collapse collapse w-100 order-3 dual-collapse2 z-index-special">
          <ul class="navbar-nav flex-row mobile-flex-wrap justify-content-center ms-auto">
<!--            <li class="nav-item">-->
<!--              <router-link to="/auctions" class="text-decoration-none">-->
<!--                <a class="nav-link nav-link-color">AUCTIONS</a>-->
<!--              </router-link>-->
<!--            </li>-->
<!--            <li class="nav-item">-->
<!--              <router-link to="/investors" class="text-decoration-none">-->
<!--                <a class="nav-link nav-link-color">INVESTORS</a>-->
<!--              </router-link>-->
<!--            </li>-->
            <li class="nav-item" @click="closeMobileMenu()">
              <router-link to="/services" class="text-decoration-none">
                <a class="nav-link nav-link-color">Services</a>
              </router-link>
            </li>
            <!-- Start Notifications Dropdown -->
            <li v-if="this.$store.state.auth.authenticated === true && this.$store.state.auth.user" class="nav-item dropdown d-none d-md-block">
<!--              <router-link to="/services" class="text-decoration-none">-->
                <a class="nav-link nav-link-color notification-icon" href="#" id="notificationDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <div class="notifications-counter" v-if="notifications && notifications.length > 0">{{notifications.length <= 99 ? notifications.length : "99"}}</div>
                  <font-awesome-icon icon="bell" :class="newNotification ? 'icon-fa notification-icon-notify' : 'icon-fa notification-icon-not-notify'"/>
                </a>
                <ul class="dropdown-menu notifications-dropdown scroll-width-thin" aria-labelledby="notificationDropdown">
                  <router-link to="/dashboard-notifications" class="text-decoration-none cursor-pointer">
                    <li class="text-center cursor-pointer"><p class="dropdown-item excerpt-in-login cursor-pointer">Notifications</p></li>
                  </router-link>
                  <li><hr class="dropdown-divider"/></li>
                  <li v-for="(notification, index) in notifications" :key="index" class="notification-list-li">
                    <router-link :to="{path: notification.data.url + '/' + notification.data.chat_room_id}" class="text-decoration-none" @click.native.prevent="markNotificationAsRead(notification.data.notification_id, notification.data.url, notification.data.chat_room_id)">
                          <div class="notification-item text-center">
                            {{notification.data.message}}
                            <hr class="dropdown-divider"/>
                            {{ ( moment.utc(notification.created_at).isBefore(moment.utc().subtract(1, 'days')) ) ? moment.utc(notification.created_at).format('DD-MM-YYYY') : moment.utc(notification.created_at).format('HH:mm')}}
                          </div>
                    </router-link>
                  </li>
                  <li v-if="notifications && notifications.length === 0" class="text-center"><p class="dropdown-item excerpt-in-login">No notifications</p></li>
                </ul>
<!--              </router-link>-->
            </li>
            <!-- End Notifications Dropdown -->

            <!-- Start Horizontal Line with Icon -->
            <div class="horizontal-separator-box mobile-separator">
              <span class="hr-line-short"></span>
              <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
              <span class="hr-line-short"></span>
            </div>
            <!-- End Horizontal Line with Icon -->

            <li v-if="this.$store.state.auth.user.contact && this.$store.state.auth.user.contact.email" class="nav-item nav-item-logged-in dropdown">
              <a class="nav-link nav-link-color nav-link-name dropdown-toggle" href="#" id="userDropDown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                <span data-tooltip="dove-tooltip">
                  <a href="https://www.biblegateway.com/passage/?search=John%203%3A16&version=KJV" target="_blank" class="dove" v-on:click.stop="openBibleUrl">
                    <font-awesome-icon icon="dove" class="icon-fa dove-icon" />
                    <span class="dove-tooltiptext">J 3:16</span>
                  </a>
                </span> <div class="excerpt">{{ this.$store.state.auth.user.contact.name }}</div> <!-- .toUpperCase() -->
              </a>
              <ul class="dropdown-menu login-dropdown" aria-labelledby="userDropDown">
                <li class="p-2">
                  <div v-if="!this.$store.state.auth.user.documents_user_images">
                    <img :src="require(`@/assets/no-user-image.png`)"
                         class="d-block img-fluid user-image"
                         alt="..."
                    >
                  </div>
                  <div v-else>
                    <img :src="`${$s3bucket}${this.$store.state.auth.user.documents_user_images.file_path}`" @error="setUserDefaultImg"
                         class="d-block img-fluid user-image"
                         alt="..."
                    >
<!--                    <img v-else :src="require(`@/assets/no-image-found.png`)"-->
<!--                         class="d-block img-fluid user-image"-->
<!--                         alt="..."-->
<!--                    >-->
                  </div>
                </li>
                <li class="text-center"><p class="dropdown-item excerpt-in-login">Hi {{ this.$store.state.auth.user.contact.name }}!</p></li>
                <li><hr class="dropdown-divider"/></li>
                <li @click="closeMobileMenu()"><router-link to="/dashboard-statistics" class="text-decoration-none"><a class="dropdown-item" href="#">Dashboard</a></router-link></li>
                <li v-if="this.$store.state.auth.user && this.$store.state.auth.user.is_host">
                  <a class="text-decoration-none dropdown-item" target="_blank" href="https://dashboard.stripe.com/login">Financial Dashboard</a>
<!--                  <a class="text-decoration-none dropdown-item" target="_blank" :href="`${this.$apiUrl}` + '/api/stripe/dashboard?id=' + this.$store.state.auth.user.id">Financial Dashboard</a>-->
                </li>
                <li @click="closeMobileMenu()"><router-link to="/dashboard-portfolio" class="text-decoration-none"><a class="dropdown-item" href="#">Portfolio</a></router-link></li>
                <li @click="closeMobileMenu()"><router-link to="/dashboard-user-messages" class="text-decoration-none"><a class="dropdown-item" href="#">Messages</a></router-link></li>
                <li @click="closeMobileMenu()"><router-link to="/dashboard-user-settings" class="text-decoration-none"><a class="dropdown-item" href="#">User Settings</a></router-link></li>
                <li><hr class="dropdown-divider"/></li>
                <li @click="closeMobileMenu()"><a class="dropdown-item text-decoration-none" href="javascript:void(0)" @click="logout">Logout</a></li>
              </ul>
            </li>
            <li v-if="!this.$store.state.auth.user.contact || !this.$store.state.auth.user.contact.email" class="nav-item mobile-block-item">
              <router-link to="/login" class="text-decoration-none">
                <a class="nav-link nav-link-color login-color" @click="closeMobileMenu()">Login</a>
              </router-link>
            </li>
            <li v-if="!this.$store.state.auth.user.contact || !this.$store.state.auth.user.contact.email" class="nav-item">
              <router-link to="/register" class="text-decoration-none">
                <a class="nav-link nav-link-color login-color" @click="closeMobileMenu()">Register</a>
              </router-link>
            </li>
          </ul>
        </div>
      </div>

      <!-- SearchFilters Errors - Position Absolute - we want it in Nav due to positioning -->
      <div v-show="errors" class="search-filter-errors">
        <div v-if="this.$store.state.properties.errors || this.$store.state.properties.errors_map">
          <p class="fw-bold" v-for="(error, index) in this.$store.state.properties.errors" :key="index">
            {{ index === 'address' || index === 'ad_type' ? error[0] : '' }}
          </p>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import List from './List'
import SearchFilters from './SearchFilters'
import {mapActions} from "vuex";
import axios from "axios";
import AdSense from "./AdSense";

export default {
  name:"Nav",
  components: {
    AdSense,
    'List': List,
    'SearchFilters': SearchFilters
  },
  data(){
    return {
      user: this.$store.state.auth.user,
      notifications: this.$store.state.notifications ? this.$store.state.notifications.notifications : null, // ''
      newNotification: false,
      activePropertiesModal: false,
      errors: false,
      sortBy: '',
      processing: false
    }
  },
  created() {
    this.$root.$on('openListComponent', () => this.openPropModal());
    this.$root.$on('refreshNotifications', () => this.getNotifications());

    // HERE WE HAVE PROBLEM HOUSTON, WHEN USER GETS LOGGED IN, WE SHOULD TRIGGER BELOW STUFF
    // BUT WE TRY JUST ONCE ON CREATED(), HENCE IF USER WAS NOT LOGGED IN, ECHO.PRIVATE listener
    // IS NOT EVEN CREATED!
    // SO IDEALLY TO RESOLVE MY PROBLEM WITH NOTIFICATIONS NOT BEING NOTICED IS TO ACTUALLY MAKE SURE
    // THAT ONCE USER IS LOGGED IN WE WANT BELOW TO RUN
    // Notifications channel per user
    // if (this.$store.state.auth.authenticated === true && this.$store.state.auth.user) {
    //   this.startListeningForNotifications();
      // Experiment with this approach instead (event) - might not need it as seems like Echo.private listens to .notifications now
      // Echo.private('Notifications.' + this.$store.state.auth.user.id)
      //     .listen(".private.notifications.send",(notification) => {
      //       console.log('Got event...');
      //       console.log(notification);
      //       console.log(notification.notification);
      //       let chatRoomMessagesContainer = document.getElementById('chatRoomMessages' + notification.notification.data.chat_room_id);
      //       if (chatRoomMessagesContainer === null) {
      //         this.notifications.unshift(notification.notification); // push
      //         if (this.newNotification === false) {
      //           this.newNotification = true;
      //         }
      //       } else {
      //         // Mark notification as read
      //         this.markNotificationAsRead(notification.notification.data.notification_id, notification.notification.data.url, notification.notification.data.chat_room_id)
      //       }
      //     });
    // }
  },
  beforeDestroy() {
    // Must destroy global trigger before we leave the page/component, otherwise it will layer on each other
    // and when we call it, it will trigger several times, breaking functionality
    this.$root.$off('openListComponent');
    this.$root.$off('refreshNotifications');
  },
  mounted () {
    this.getNotifications();
  },
  methods:{
    closeMobileMenu() {
      // Create a media condition that targets viewports of max-width 767px
      const mediaQueryMax767 = window.matchMedia('(max-width: 767px)')
      // Check if the media query is true
      if (mediaQueryMax767.matches) {
        let menu = document.getElementsByClassName('dual-collapse2');
        if (menu) {
          for (let i = 0; i < menu.length; i++) {
            let bsCollapse = new bootstrap.Collapse(menu[i])
            bsCollapse.toggle();
          }
        }
      }
    },
    startListeningForNotifications() {
      // First stop listening just in case we are listening already
      Echo.private('App.Models.User.' + this.$store.state.auth.user.id).stopListening('.Illuminate\\Notifications\\Events\\BroadcastNotificationCreated');

      // Start new listening for notifications
      Echo.private('App.Models.User.' + this.$store.state.auth.user.id).notification((notification) => {
        // console.log(notification.notification);
        // If message is already read, dont push notification (we check that by checking for unique chatroom element id in DashboardUserMessages.vue component)
        let chatRoomMessagesContainer = document.getElementById('chatRoomMessages' + notification.notification.data.chat_room_id);
        if (chatRoomMessagesContainer === null) {
          this.notifications.unshift(notification.notification); // push
          if (this.newNotification === false) {
            this.newNotification = true;
          }
        } else {
          // Mark notification as read
          this.markNotificationAsRead(notification.notification.data.notification_id, notification.notification.data.url, notification.notification.data.chat_room_id)
        }
        // this.getNotifications();
      });
    },
    ...mapActions({
      setNotifications:'notifications/setNotifications',
    }),
    async getNotifications() {
      if (this.$store.state.auth && this.$store.state.auth.user.id) {
        this.processing = true;
        await axios.post('api/user/' + this.$store.state.auth.user.id + '/notifications').then(({data})=> {
          // this.notifications = data;
          this.setNotifications(data);

          // // Toggle newNotification for notification icon styling
          // if (this.$store.state.notifications.notifications.length > 0) { // this.notifications.length
          //   this.newNotification = true;
          // } else {
          //   this.newNotification = false;
          // }

          this.validationErrors = null;
        }).catch(({response}) => { // {response: {data}}
          if (response && response.data && response.data.errors) {
            this.validationErrors = response.data.errors;
            // this.validationMessage = data.message;
          }
        }).finally(() => {
          this.processing = false
        })
      }
    },
    async markNotificationAsRead(notificationId, url, chatRoomId) {
      if (this.$store.state.auth && this.$store.state.auth.user.id && notificationId && url && chatRoomId) {
        this.processing = true;
        await axios.post('api/user/' + this.$store.state.auth.user.id + '/notifications/' + notificationId).then(({data})=> {
          this.getNotifications();
          // Seems like I dont need that, router-link still works anyways
          // this.$router.push({path: url + '/' + chatRoomId})
          this.validationErrors = null;
        }).catch(({response}) => { // {response: {data}}
          if (response && response.data && response.data.errors) {
            this.validationErrors = response.data.errors;
            // this.validationMessage = data.message;
          }
        }).finally(() => {
          this.processing = false
        })
      }
    },
    handleFocus(event) {
      if(!event.target.classList.contains('select-clicked')) {
        event.target.classList.toggle('select-clicked');
      }
    },
    handleFocusOut(value, event) {
      if(value === null || value === '') {
        if(event.target.classList.contains('select-clicked')) {
          event.target.classList.toggle('select-clicked');
        }
      }
    },
    // getImage(file_name) {
    //   try {
    //     return require(`@/assets/${file_name}`);
    //   } catch (e) {
    //     return false
    //   }
    // },
    setUserDefaultImg(e) {
      e.target.src = require(`@/assets/no-user-image.png`);
    },
    displayErrors() {
      this.errors = true;
      const timeout = setTimeout(() => {
        this.errors = false;
        clearTimeout(timeout);
      }, 5000);
    },
    ...mapActions({
      signOut:"auth/logout"
    }),
    async logout(){
      await axios.post('api/logout').then(({data})=>{
        this.signOut()
        this.$router.push({name: "login"}).catch(()=>{});
      })
    },
    refreshPropsNav(formData = null) {
      // Emit event from parent (App.vue)
      this.$emit('refreshProps', formData);
    },
    openPropModal(mapTrigger = false) {
      if(mapTrigger === false) {
        this.activePropertiesModal = true;
      } else {
        this.activePropertiesModal = !this.activePropertiesModal;
      }
      if(this.activePropertiesModal === true) {
        document.getElementById("mypropModal").style.left = "0";
      } else {
        this.closePropModal();
      }
    },
    closePropModal() {
      document.getElementById("mypropModal").style.transition = "all 1.5s";
      document.getElementById("mypropModal").style.left = "-100vw";
      if(this.$refs.childListAdDetailsRef) {
        this.$refs.childListAdDetailsRef.closePropDetailsModal();
        if (this.activePropertiesModal === true) {
          this.activePropertiesModal = !this.activePropertiesModal;
        }
      }
    },
    openBibleUrl() {
      window.open('https://www.biblegateway.com/passage/?search=John%203%3A16&version=KJV');
    }
  },
  watch: {
    '$store.state.notifications.notifications': {
      handler: function (val) {
        // Toggle newNotification for notification icon styling
        this.notifications = this.$store.state.notifications.notifications;
        if (this.$store.state.notifications.notifications.length > 0) { // this.notifications.length
          this.newNotification = true;
        } else {
          this.newNotification = false;
        }
      }, deep: true
    },
    '$store.state.auth': {
      handler: function (val) {
        if (this.$store.state.auth && this.$store.state.auth.user.id) {
          this.startListeningForNotifications();
          this.getNotifications();
        }
      }, deep: true
    },
    '$store.state.properties.errors': function (val) {
      this.displayErrors();
    },
    'sortBy': function (val) {
      if (this.$store.state.searchFilters.searchFilters) {

        let searchFilters = this.$store.state.searchFilters.searchFilters;

        switch(val) {
          case 1:
            searchFilters.newest = true;
            searchFilters.oldest = false;
            searchFilters.lowest_price = false;
            searchFilters.highest_price = false;
            break;
          case 2:
            searchFilters.newest = false;
            searchFilters.oldest = true;
            searchFilters.lowest_price = false;
            searchFilters.highest_price = false;
            break;
          case 3:
            searchFilters.newest = false;
            searchFilters.oldest = false;
            searchFilters.lowest_price = true;
            searchFilters.highest_price = false;
            break;
          case 4:
            searchFilters.newest = false;
            searchFilters.oldest = false;
            searchFilters.lowest_price = false;
            searchFilters.highest_price = true;
            break;
        }

        // Set searchFilters
        this.$root.$emit('setFilters', searchFilters);
        // Perform new search
        this.$emit('refreshProps', searchFilters);
      }
    }
  }
}
</script>

<style lang="scss">

/* Start Errors on searchFilter */
.search-filter-errors {
  border-radius: 10px;
  box-shadow: 0rem 0.5rem 0.5rem rgba(0, 0, 0, .25);
  background-color: white;
  padding: 20px;
  position: absolute;
  height: auto;
  width: 300px;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 40vh;
  z-index: 10000;
  color: red;
  text-align: center;
  transition: 0.5s;
}
/* End Errors on searchFilter */

/* Fade effect for transition elements */
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

/* Start Excerpt For Name in Nav */

.nav-link-name {
  max-width: 200px;
  //border: 1px dashed #0d6efd;
  background-color: #e9ecef;
  letter-spacing: 0 !important;
}

.excerpt {
  display: inline-block;
  vertical-align:top;
  width: auto;
  min-width: 100px;
  max-width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.excerpt:hover {
  white-space: normal;
  overflow: visible;
  overflow-wrap: break-word;
}

.login-dropdown {
  //width: 100%;
  min-width: 150px !important;
  right: 0 !important;
  left: unset !important;
}

.notifications-counter {
  position: absolute;
  top: 0;
  right: 13%;
  display: inline-block;
  border-radius: 50%;
  border: 1px solid red;
  background-color: red;
  font-size: 11px;
  color: white;
  z-index: 2;
  width: 25px;
  height: 25px;
}

.notifications-counter:hover {
  color: white !important;
}

.notifications-counter:before,
.notifications-counter:after {
  content: '\200B';
  display: inline-block;
  line-height: 4px;
  padding-top: 40%;
  padding-bottom: 40%;
}

.notifications-counter:before {
  padding-left: 3px;
}

.notifications-counter:after {
  padding-right: 3px;
}

.notification-icon {
  color: white !important;
  .notification-icon-not-notify {
    color: white !important;
    stroke: black;
    stroke-width: 20px;
  }
  .notification-icon-notify {
    color: #ffce00 !important; // rgba(25, 181, 254, 1)
    stroke: black;
    stroke-width: 20px;
    transition: 1s;
    animation: wiggle 2s infinite;
    -moz-animation: wiggle 2s infinite;
    -o-animation: wiggle 2s infinite;
    -webkit-animation: wiggle 2s infinite;
  }
}

/* Wiggle Animation */
@keyframes wiggle {
  0%, 7% {
    transform: rotateZ(0);
  }
  15% {
    transform: rotateZ(-15deg);
  }
  20% {
    transform: rotateZ(10deg);
  }
  25% {
    transform: rotateZ(-10deg);
  }
  30% {
    transform: rotateZ(6deg);
  }
  35% {
    transform: rotateZ(-4deg);
  }
  40%, 100% {
    transform: rotateZ(0);
  }
}
/* End Wiggle Animation */

.notifications-dropdown {
  width: 100%;
  min-width: 250px !important;
  max-height: 500px;
  right: 0 !important;
  left: unset !important;
  overflow-x: hidden;
  overflow-y: scroll;
  z-index: 1000;
  .notification-list-li {
    margin: 5px;
  }
  .notification-item {
    display: block;
    width: 100%;
    padding: .25rem 1rem;
    clear: both;
    font-weight: 200;
    font-size: 12px;
    color: #212529;
    text-align: inherit;
    text-decoration: none;
    background-color: transparent;
    border-radius: 10px;
    border: 1px solid #e9ecef;
    transition: 0.5s;
    -moz-transition: 0.5s;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    //.notification-link {
    //  //padding: .25rem 1rem;
    //  padding: .25rem 1rem;
    //  //clear: both;
    //  font-weight: 200;
    //  font-size: 12px;
    //  color: #212529;
    //  text-align: inherit;
    //  text-decoration: none;
    //  background-color: transparent;
    //}
  }
  .notification-item:hover {
    transition: 0.5s;
    -moz-transition: 0.5s;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    background-color: #e9ecef;
  }
}

.user-image {
  width: 100px;
  height: 100%;
  margin: auto;
  border-radius: 50%;
  border: 1px solid #9e9e9e;
}

.excerpt-in-login {
  cursor: default;
  display: inline-block;
  vertical-align:top;
  width: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.excerpt-in-login:hover {
  white-space: normal;
  overflow: visible;
  overflow-wrap: break-word;
}

/* End Excerpt For Name in Nav */

.dove {
  color: white !important;
  :hover {
    color: white !important;
  }
  .dove-icon {
    stroke: black;
    stroke-width: 20px;
  }
}

/* start tooltip - popups info on icons */
.nav-btn-tooltiptext {
  visibility: hidden;
  width: auto;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  text-align: center;
  font-size: 10px;
  border-radius: 6px;
  padding: 5px 5px;

  /* Position the tooltip */
  position: absolute;
  top: -28px;
  z-index: 1000;
}

[data-tooltip]:hover .nav-btn-tooltiptext {
  visibility: visible;
  color: #fff;
}
/* end tooltip - popups info on icons */

/* start tooltip - popups info on icons */
.dove-tooltiptext {
  visibility: hidden;
  width: auto;
  background-color: black;
  color: #fff;
  text-align: center;
  font-size: 10px;
  border-radius: 6px;
  padding: 5px 5px;
  transition: 0.5s;

  /* Position the tooltip */
  position: absolute;
  overflow: visible;
  top: -12%;
  left: -25%;
  z-index: 9999;
}

[data-tooltip]:hover .dove-tooltiptext {
  transition: 0.5s;
  visibility: visible;
  color: #fff;
}
/* end tooltip - popups info on icons */

#mainPropModal {
  position: absolute;
  transition: margin-left .5s;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.prop-modal-tab::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.prop-modal-tab {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* left side bar - properties list */
.propModal {
  height: 90%;
  width: 30vw;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: -100vw;
  background-color: rgba(33, 150, 243, 50%);
  // this overflow if removed resolves problem with tooltips but at the same time breaks the rest of it
  overflow-x: hidden;
  transition: all 1s;
  background-clip: border-box;
  box-shadow: 1rem 0.5rem 1rem rgba(0,0,0,.25);
}

//.propModal a {
//  padding: 8px 8px 8px 32px;
//  text-decoration: none;
//  font-size: 25px;
//  color: black;
//  display: block;
//  transition: 0.3s;
//}
.propModal .addbtn {
  margin: 8px 8px 0 8px;
  color: white;
  border: 0;
}

.propModal .addbtn .add-icon {
  //transform: rotate(25deg);
  transition: 0.5s;
  -moz-transition: 0.5s;
}

.propModal .addbtn:hover .add-icon {
  transform: rotate(90deg);
  transition: 0.5s;
  -moz-transition: 0.5s;
}

.btn-add {
  white-space: nowrap;
}

.propModal .sortbybtn {
  border: none;
  margin: 8px 8px 0 8px;
  padding: 8px 8px 8px 8px;
  border-radius: 5px;
  //color: #212529;
  //background-color: #fff;
  color: white;
  background-color: rgba(255, 255, 255, 0);
}

.propModal .select-placeholder-sortbybtn option {
  border: none;
  outline: none;
}

.propModal .select-placeholder-sortbybtn option:hover {
  border: none;
  outline: none;
}

/* Placeholders */
.propModal .select-placeholder-sortbybtn {
  border: none;
  outline: none;
  color: white !important;
  cursor: pointer;
}

.propModal .select-placeholder-sortbybtn:focus {
  border: none;
  outline: none;
}

.propModal .select-placeholder-sortbybtn .select-clicked {
  color: white !important;
}

.propModal .select-placeholder-sortbybtn::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white !important;
  opacity: 1; /* Firefox */
}

.propModal .select-placeholder-sortbybtn:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: white !important;
}

.propModal .select-placeholder-sortbybtn::-ms-input-placeholder { /* Microsoft Edge */
  color: white !important;
}

.filter-select {
  border-radius: 5px;
  border: 1px solid #9e9e9e;
  background-color: white;
  width: 100%;
  padding: 5px;
}

.propModal .closebtn-box {
  position: absolute;
  top: 20%;
  right: 0;
  display: block;
  align-content: center;
  vertical-align: middle;
  height: 100%;
  width: auto;
}

.propModal .close-btn {
  color: white;
  line-height: 30px;
  font-size: 26px;
  border-radius: 50%;
  padding: 1px 5px;
  border: 0;
}

.propModal .close-btn:hover {
  transform: rotate(90deg);
  transition: 0.5s;
  -moz-transition: 0.5s;
}

//.propModal .closebtn {
//  //position: absolute;
//  //top: -10px;
//  //right: 0;
//  font-size: 36px;
//  //margin-left: 50px;
//  height: 30px;
//  width: 30px;
//  align-self: center;
//  vertical-align: middle;
//  text-decoration: none;
//  color: white;
//  display: block;
//  transition: 0.3s;
//  //padding: 8px 8px 0 8px;
//}
//
//.propModal .closebtn:hover {
//  border-radius: 50%;
//  background-color: #0d6efd;
//}

.propModalStickyTop {
  width: 100%;
  height: 50px;
  transition: 0.5s;
  background-color: rgba(25, 181, 254, 50%);
  background-clip: border-box;
  box-shadow: 0 0.2rem 0.3rem rgba(0,0,0,.15);
}
.propModalStickyTop:hover {
  transition: 0.5s;
  background-color: rgba(25, 181, 254, 100%);
}

.openbtnPropModal {
  //position: absolute;
  top: 0;
  //font-size: 20px;
  cursor: pointer;
  background-color: rgba(33, 150, 243, 50%);
  color: white;
  //padding: 10px 15px;
  border: none;
  border-radius: 0 50% 50% 0;
  transition: 0.5s;
  .active {
    animation: pulse 2s infinite !important;
  }
}

//.openbtnPropModal {
//  position: absolute;
//  top: 0;
//  left: -40px;
//  font-size: 20px;
//  cursor: pointer;
//  background-color: rgba(33, 150, 243, 50%);
//  color: white;
//  padding: 10px 15px;
//  border: none;
//  border-radius: 0 50% 50% 0;
//  transition: 0.5s;
//}

.openbtnPropModal:hover {
  background-color: #444;
  transition: 0.5s;
}

.sticky {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 9999;
}

.nav-bg {
  background-color: rgba(255, 255, 255, 80%); /* For browsers that do not support gradients */
  background-clip: border-box;
  box-shadow: 1rem 0 1rem rgba(0,0,0,.25);
  background-image: linear-gradient(rgba(255, 255, 255, 100%) 80%, rgba(255, 255, 255, 0%));
  //z-index: 9 !important; // Important to overwrite fixed-top class z-index
  min-height: 9.8%;
  max-height: 9.8%;
  height: 9.8%;
  .navbar-nav .nav-link {
    text-align: center;
    padding: 3px 5px 3px 5px;
    margin: 0 2px 0 2px;
  }
  .nav-link-color {
    border-radius: 5px;
    min-width: 80px;
    color: #3d3d3d !important;
    transition: 0.5s;
    letter-spacing: 0.1em;
  }
  .nav-link-color:hover {
    background-color: #e9ecef;
    border-radius: 5px;
    color: black !important;
    transition: 0.5s;
  }
  .nav-link-color-donate {
    border-radius: 5px;
    min-width: 80px;
    color: red !important;
    transition: 0.5s;
    letter-spacing: 0.1em;
  }
  .nav-link-color-donate:hover {
    background-color: #ff8484;
    border-radius: 5px;
    color: white !important;
    transition: 0.5s;
  }
  .nav-link-color-add-property {
    border-color: rgba(13, 110, 253, 1);
    border-radius: 5px;
    min-width: 80px;
    color: rgba(13, 110, 253, 1) !important;
    transition: 0.5s;
    letter-spacing: 0.1em;
    //line-height: 5px;
  }
  .nav-link-color-add-property:hover {
    background-color: rgba(13, 110, 253, 0.6);
    border-radius: 5px;
    color: white !important;
    transition: 0.5s;
  }
  .nav-link-color-add-service {
    border-color: rgba(147, 112, 219, 1);
    border-radius: 5px;
    min-width: 80px;
    color: rgba(147, 112, 219, 1) !important;
    transition: 0.5s;
    letter-spacing: 0.1em;
    //line-height: 5px;
  }
  .nav-link-color-add-service:hover {
    background-color: rgba(147, 112, 219, 0.6);
    border-radius: 5px;
    color: white !important;
    transition: 0.5s;
  }
  .login-color {
    color: #0d6efd !important;
  }
  .login-color:hover {
    background-color: rgba(13, 110, 253, 0.6);
    color: white !important;
  }
  .btn-add {
    white-space: nowrap;
  }

  .btn-add .add-icon {
    transition: 0.5s;
    -moz-transition: 0.5s;
  }

  .btn-add:hover .add-icon {
    transform: rotate(90deg);
    transition: 0.5s;
    -moz-transition: 0.5s;
  }
}

.nav-bottom {
  //background-color: #2c3e50;
  //background-color: rgba(25, 181, 254, 100%);
  //background-color: rgba(255, 255, 255, 50%);
}

.vuenav-btns {
  position: absolute;
  //top: 12px;
  top: 10px; // 0px;
  height: 35%;
  width: 25%;
  .btn-outline-primary {
    transition: 0.7s !important;
  }
  .btn-outline-primary:hover {
    transition: 0.7s !important;
  }
  .btn-outline-success {
    transition: 0.7s !important;
  }
  .btn-outline-success:hover {
    transition: 0.7s !important;
  }
}

.btn-round {
  border-radius: 50% !important;
  padding: 3px !important;
  padding-left: 8px !important;
  padding-right: 8px !important;
  border: 0 !important;
}

.btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
  box-shadow: none !important;
}

// Buttons in nav List, Search and Map
.btn-vuenav.btn-outline-primary  {
  background-color: white;
  color: #0d6efd;
}
.btn-vuenav.btn-outline-primary:hover  {
  background-color: #0d6efd !important;
  border-color: #0d6efd !important;
  color: white !important;
}
.btn-vuenav.btn-outline-primary:active  {
  color: #0d6efd !important;
}
.btn-vuenav.btn-outline-primary.active {
  animation: pulse 2s infinite !important;
}
.btn-vuenav.btn-outline-success  {
  background-color: white;
  color: #198754;
}
.btn-vuenav.btn-outline-success:hover  {
  background-color: #198754 !important;
  border-color: #198754 !important;
  color: white !important;
}
.btn-vuenav.btn-outline-success:active  {
  color: #198754 !important;
}

@keyframes pulse {
  0% {
    transform: scale(0.90);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 6px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.90);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
//.btn-vuenav {
//  //padding: 3px !important;
//  right: -25px;
//}

.nav-main {
  max-height: 30px;
  overflow: visible;
  z-index: 98;
  //background-color: rgb(69, 82, 110);
  //background-color: rgba(255, 255, 255, 50%);
}
.nav-mid {
  position: absolute;
  top: -3px;
  height: 35%;
  left: 37%;
  width: 25%;
  //background-color: rgb(69, 82, 110); // white
  //background-color: rgba(255, 255, 255, 50%);
  border-color: #000 transparent transparent transparent;
  border-radius: 0 0 10% 10%/ 0 0 100% 100%;
}
//50%/0px 0px 100px 100px;
.logo {
  position: relative;
  width: 100%;
  top: 5px;
}
.logo-name {
  font-size: larger;
  font-family: Trattatello,fantasy !important;
  color: rgb(245, 214, 15) !important;
  text-shadow: 1px 2px black !important;
}

.mobile-separator {
  display: none;
}

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
  .propModal a {
    font-size: 18px;
  }
}
@media screen and (max-width: 767px) {
  .nav-link-name {
    max-width: 300px !important;
  }
  /* Start Notification icon for mobile - until medium screen */
  .notifications-icon {
    z-index: 1000;
    position: absolute !important;
    left: 5%;
    top: 75%;
    visibility: visible;
    display: block;
  }
  /* End Notification icon for mobile - until medium screen */
  /* Start Nav, List and searchFilters adjustment */
  .nav-bg {
    min-height: 9% !important; // 8vh
    max-height: 9% !important;
    height: 9% !important;
  }
  .prop-modal-tab {
    top: 10% !important; // 9vh
  }
  .search-component {
    top: 150% !important; // 9vh
  }
  /* End Nav, List and searchFilters adjustment */
  .nav-bottom .mobile-top-margin {
    margin-top: 32px;
  }
  .nav-bottom .navbar-collapse {
    background-color: white;
    box-shadow: 0 1rem 1rem rgba(0, 0, 0, .25);
    .mobile-flex-wrap {
      flex-wrap: wrap;
    }
    .navbar-nav {
      margin: auto 0;
      align-items: center;
      .nav-item {
        border: 1px solid #e3e3e3;
        border-radius: 10px;
        margin: 5px;
        width: 70px;
      }
      .nav-item-logged-in {
        width: 300px;
      }
    }
  }
  .navbar-toggler {
    position: absolute;
    right: 5%;
    top: 50%;
  }
  .mobile-separator {
    margin: 5px 0 20px 0;
    display: block;
    flex: 1 1 90%;
    width: 100%;
    height: 1px;
  }
  .vuenav-btns {
    min-width: 150px;
    top: 10px;
  }
  .nav-link-color {
    min-width: 50px !important;
    font-size: 10px;
  }
  .nav-link-color-donate {
    min-height: 50px;
    min-width: 50px !important;
    font-size: 10px;
    white-space: normal !important;
  }
  .nav-link-color-add-property {
    min-height: 50px;
    min-width: 50px !important;
    font-size: 10px;
    white-space: normal !important;
  }
  .nav-link-color-add-service {
    min-height: 50px;
    min-width: 50px !important;
    font-size: 10px;
    white-space: normal !important;
  }
  .propModal {
    width: 100vw;
  }
}
@media screen and (min-width: 768px) {
  .nav-link-name {
    max-width: 200px !important;
  }
  /* Start Nav, List and searchFilters adjustment */
  .nav-bg {
    min-height: 9% !important; // 8vh
    max-height: 9% !important;
    height: 9% !important;
  }
  .prop-modal-tab {
    top: 7% !important; // 8vh
  }
  .search-component {
    top: 150% !important; // 9vh
  }
  /* End Nav, List and searchFilters adjustment */
  .mobile-separator {
    display: none;
  }
  .vuenav-btns {
    min-width: 150px;
    top: 10px; // 0px
  }
  .nav-link-color {
    min-width: 50px !important;
    font-size: 10px;
  }
  .nav-link-color-donate {
    min-height: unset;
    min-width: 50px !important;
    font-size: 10px;
    white-space: nowrap !important;
  }
  .nav-link-color-add-property {
    min-height: unset;
    min-width: 50px !important;
    font-size: 10px;
    white-space: nowrap !important;
  }
  .nav-link-color-add-service {
    min-height: unset;
    min-width: 50px !important;
    font-size: 10px;
    white-space: nowrap !important;
  }
  .propModal {
    width: 100vw;
  }
}
@media screen and (min-width: 992px) {
  /* Start Nav, List and searchFilters adjustment */
  .nav-bg {
    min-height: 9% !important; // 8vh
    max-height: 9% !important;
    height: 9% !important;
  }
  .prop-modal-tab {
    top: 10% !important; //8vh
  }
  .search-component {
    top: 150% !important; //9vh
  }
  /* End Nav, List and searchFilters adjustment */
  .mobile-separator {
    display: none;
  }
  .vuenav-btns {
    min-width: 150px;
    top: 10px; // 0px
  }
  .nav-link-color {
    min-width: 80px !important;
    font-size: 12px;
  }
  .nav-link-color-donate {
    min-height: unset;
    min-width: 80px !important;
    font-size: 12px;
    white-space: nowrap !important;
  }
  .nav-link-color-add-property {
    min-height: unset;
    min-width: 80px !important;
    font-size: 12px;
    white-space: nowrap !important;
  }
  .nav-link-color-add-service {
    min-height: unset;
    min-width: 80px !important;
    font-size: 12px;
    white-space: nowrap !important;
  }
  .propModal {
    width: 40vw;
  }
}
@media screen and (min-width: 1200px) {
  /* Start Nav, List and searchFilters adjustment */
  .nav-bg {
    min-height: 10% !important; // 9vh
    max-height: 10% !important;
    height: 10% !important;
  }
  .prop-modal-tab {
    top: 10% !important; // 9vh
  }
  .search-component {
    top: 150% !important; // 10vh
  }
  /* End Nav, List and searchFilters adjustment */
  .mobile-separator {
    display: none;
  }
  .vuenav-btns {
    min-width: 150px;
    top: 10px; // 0px
  }
  .nav-link-color {
    min-width: 80px !important;
    font-size: 16px;
  }
  .nav-link-color-donate {
    min-height: unset;
    min-width: 80px !important;
    font-size: 16px;
    white-space: nowrap !important;
  }
  .nav-link-color-add-property {
    min-height: unset;
    min-width: 80px !important;
    font-size: 16px;
    white-space: nowrap !important;
  }
  .nav-link-color-add-service {
    min-height: unset;
    min-width: 80px !important;
    font-size: 16px;
    white-space: nowrap !important;
  }
  .propModal {
    width: 30vw;
  }
}
@media (max-height: 800px) and (min-width: 1200px) {
  /* Start Nav, List and searchFilters adjustment */
  nav-bg {
    min-height: 10vh !important; // 9vh
    max-height: 10vh !important;
    height: 10vh !important;
  }
  .prop-modal-tab {
    top: 10.5% !important; // 9vh
  }
  .search-component {
    top: 150% !important; // 10vh
  }
  /* End Nav, List and searchFilters adjustment */
}
@media screen and (min-width: 1680px) {
  /* Start Nav, List and searchFilters adjustment */
  .nav-bg {
    min-height: 9% !important; // 8vh
    max-height: 9% !important;
    height: 9% !important;
  }
  .prop-modal-tab {
    top: 8% !important; // 8vh
  }
  .search-component {
    top: 150% !important; // 9vh
  }
  /* End Nav, List and searchFilters adjustment */
  .mobile-separator {
    display: none;
  }
  .vuenav-btns {
    min-width: 150px;
    top: 10px; // 0px
  }
  .nav-link-color {
    min-width: 80px !important;
    font-size: 16px;
  }
  .nav-link-color-donate {
    min-height: unset;
    min-width: 80px !important;
    font-size: 16px;
    white-space: nowrap !important;
  }
  .nav-link-color-add-property {
    min-height: unset;
    min-width: 80px !important;
    font-size: 16px;
    white-space: nowrap !important;
  }
  .nav-link-color-add-service {
    min-height: unset;
    min-width: 80px !important;
    font-size: 16px;
    white-space: nowrap !important;
  }
  .propModal {
    width: 30vw;
  }
}
@media (min-height: 1200px) and (min-width: 1600px) {
  /* Start Nav, List and searchFilters adjustment */
  .nav-bg {
    min-height: 9% !important; // 8vh
    max-height: 9% !important;
    height: 9% !important;
  }
  .prop-modal-tab {
    top: 8.5% !important; // 8vh
  }
  .search-component {
    top: 150% !important; // 9vh
  }
  /* End Nav, List and searchFilters adjustment */
}
@media screen and (min-width: 1920px) {
  /* Start Nav, List and searchFilters adjustment */
  .nav-bg {
    min-height: 8% !important; // 7vh
    max-height: 8% !important;
    height: 8% !important;
  }
  .prop-modal-tab {
    top: 8.5% !important; // 7vh
  }
  .search-component {
    top: 150% !important; // 8vh
  }
  /* End Nav, List and searchFilters adjustment */

  .mobile-separator {
    display: none;
  }
  .vuenav-btns {
    min-width: 150px;
    top: 10px; // 0px
  }
  .nav-link-color {
    min-width: 80px !important;
    font-size: 20px;
  }
  .nav-link-color-donate {
    min-height: unset;
    min-width: 80px !important;
    font-size: 20px;
    white-space: nowrap !important;
  }
  .nav-link-color-add-property {
    min-height: unset;
    min-width: 80px !important;
    font-size: 20px;
    white-space: nowrap !important;
  }
  .nav-link-color-add-service {
    min-height: unset;
    min-width: 80px !important;
    font-size: 20px;
    white-space: nowrap !important;
  }
}

</style>