import axios from 'axios'
import mapboxgl from 'mapbox-gl';

export default {
    namespaced: true,
    state:{
        loading:false,
        map: ''
    },
    getters:{
        loading(state){
            return state.loading
        },
        map(state){
            return state.map
        }
    },
    mutations:{
        SET_LOADING (state, value) {
            state.loading = value
        },
        SET_MAP (state, value) {
            state.map = value;
        }
    },
    actions:{
        initMap({ commit, rootState }) {
            commit('SET_LOADING', true)

            // let map = L.map('mapid');

            /* MapBox Map */
            mapboxgl.accessToken = 'pk.eyJ1IjoibW9kemVsMTk5MCIsImEiOiJja3ZodTNtbGMzemliMnRva3JtdG5vbHhkIn0.ZBC_pFryjkUXrbZ6Z617BA';
            // Below not working, not sure why, cannot access env variables, returned as undefined
            // process.env.VUE_APP_MAPBOX_MAP_ACCESS_TOKEN;
            let map = new mapboxgl.Map({
                container: 'mapid',
                style: 'mapbox://styles/mapbox/streets-v11',
                center: {lat:51.507321899999994,lng:-0.12764739999999997},
                location: '',
                zoom: 12,
                maxZoom: 20
            });

            // Initialize the geolocate control.
            let geolocate = new mapboxgl.GeolocateControl({
                positionOptions: {
                    enableHighAccuracy: true
                },
                trackUserLocation: true
            });
            // Add the geolocate control to the map.
            map.addControl(geolocate, 'bottom-left');
            // Activates geolocation on map load
            map.on('load', function() {
                geolocate.trigger();
            });

            // Initialize navigation control.
            let nav = new mapboxgl.NavigationControl();
            // Add the navigation control to the map
            map.addControl(nav, 'bottom-right');

            /* End MapBox Map */

            commit('SET_MAP', map);
            commit('SET_LOADING', false)
        },
        getMap({ commit, rootState }) {
            commit('SET_LOADING', true)

            /* MapBox Map */
            let getMap = rootState.map.map;
            if(getMap) {
                // getMap.on('locationfound', () => {
                //     commit('location/SET_LOCATION', true, {root: true});
                // });
                //
                // getMap.on('locationerror', () => {
                //     commit('location/SET_LOCATION', false, {root: true});
                //     getMap.setView([51.505, -0.09], 10);
                // });
            }
            /* End MapBox Map */

            // let getMap = rootState.map.map;
            // if(getMap) {  // https://tiles.stadiamaps.com/tiles/osm_bright/{z}/{x}/{y}{r}.png Stadia Osm-Bright (it is paid after 2500 requests)
            //     L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            //         maxZoom: 20,
            //         attribution: '<a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
            //     }).addTo(getMap);
            //
            //     if (rootState.location.location === true) {
            //         getMap.locate({setView: true, maxZoom: 12});
            //     } else {
            //         getMap.setView([51.505, -0.09], 10);
            //         var localLocate = setInterval(function () {
            //             getMap.locate({setView: true, maxZoom: 12});
            //             clearInterval(localLocate);
            //         }, 1000);
            //     }
            //
            //     getMap.on('locationfound', () => {
            //         commit('location/SET_LOCATION', true, {root: true});
            //     });
            //
            //     getMap.on('locationerror', () => {
            //         commit('location/SET_LOCATION', false, {root: true});
            //         getMap.setView([51.505, -0.09], 10);
            //     });
            //
            //     commit("SET_MAP", getMap);
            //     commit("SET_LOADING", false)
            // }
            commit('SET_LOADING', false)
        }
    }
}