<template>
  <div class="contact"> <!-- pt-4 -->
    <div class="container h-100">
      <div class="row h-100 align-items-center pt-4 pb-4">
        <div class="col-12 col-md-6 offset-md-3">
          <div class="card shadow sm">
            <div class="card-body">
              <!-- Loading -->
              <Loader class="mt-2" :loading="loading" :small="false"></Loader>

              <div v-if="!loading">
                <form v-if="review_type && ad_id && ad_details" action="javascript:void(0)" class="row" method="post">
                  <!-- Additional info about neighbourhood -->
                  <div v-if="review_type === 'neighbourhood'" class="form-group col-12">
                    <div class="row m-2">
                      <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center align-self-center align-content-center">
                        {{
                          (ad_details && ad_details.address.street ? ad_details.address.street : '') +
                          (ad_details && ad_details.address.city ? ', ' + ad_details.address.city : '') +
                          (ad_details && ad_details.address.county ? ', ' + ad_details.address.county : '') +
                          (ad_details && ad_details.address.postcode ? ', ' + ad_details.address.postcode : '')
                        }}
                      </div>
                    </div>
                  </div>
                  <hr v-if="review_type === 'neighbourhood'"/>
                  <!-- End Additional info about neighbourhood -->

                  <!-- Additional info about host -->
                  <div v-if="review_type === 'host'" class="form-group col-12">
                    <div class="row m-2">
                      <div class="col-4 col-xs-4 col-sm-4 col-md-4 col-lg-4 m-0 p-0">
                        <div class="row m-0 p-0">
                          <div class="col-5 col-xs-5 col-sm-5 col-md-5 col-lg-5 m-0 p-0 text-start align-self-center align-content-center overflow-hidden">
                            <div v-if="!ad_details.host.documents_user_images">
                              <img :src="require(`@/assets/no-user-image.png`)"
                                   class="img-fluid user-image"
                                   alt="..."
                              >
                            </div>
                            <div v-else>
                              <img :src="`${$s3bucket}${ad_details.host.documents_user_images.file_path}`" @error="setUserDefaultImg"
                                   class="img-fluid user-image"
                                   alt="..."
                              >
                            </div>
                          </div>
                          <div class="col-7 col-xs-7 col-sm-7 col-md-7 col-lg-7 m-0 p-0 text-start align-self-center align-content-center overflow-hidden">
                            <span class="user-name">
                              {{ ad_details && ad_details.property && ad_details.property.contact ? ad_details.property.contact.name : '' }}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="col-8 col-xs-8 col-sm-8 col-md-8 col-lg-8 text-start align-self-center align-content-center">
                        {{
                          (ad_details && ad_details.property.address.street ? ad_details.property.address.street : '') +
                          (ad_details && ad_details.property.address.city ? ', ' + ad_details.property.address.city : '') +
                          (ad_details && ad_details.property.address.county ? ', ' + ad_details.property.address.county : '') +
                          (ad_details && ad_details.property.address.postcode ? ', ' + ad_details.property.address.postcode : '')
                        }}
                      </div>
                    </div>
                  </div>
                  <hr v-if="review_type === 'host'"/>
                  <!-- End Additional info about host -->

                  <!-- Additional info about guest -->
                  <div v-if="review_type === 'guest'" class="form-group col-12">
                    <div class="row m-2">
                      <div class="col-4 col-xs-4 col-sm-4 col-md-4 col-lg-4 m-0 p-0">
                        <div class="row m-0 p-0">
                          <div class="col-5 col-xs-5 col-sm-5 col-md-5 col-lg-5 m-0 p-0 text-start align-self-center align-content-center overflow-hidden">
                            <div v-if="!ad_details.guest.documents_user_images">
                              <img :src="require(`@/assets/no-user-image.png`)"
                                   class="img-fluid user-image"
                                   alt="..."
                              >
                            </div>
                            <div v-else>
                              <img :src="`${$s3bucket}${ad_details.guest.documents_user_images.file_path}`" @error="setUserDefaultImg"
                                   class="img-fluid user-image"
                                   alt="..."
                              >
                            </div>
                          </div>
                          <div class="col-7 col-xs-7 col-sm-7 col-md-7 col-lg-7 m-0 p-0 text-start align-self-center align-content-center overflow-hidden">
                            <span class="user-name">
                              {{ ad_details && ad_details.property && ad_details.property.contact ? ad_details.property.contact.name : '' }}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="col-8 col-xs-8 col-sm-8 col-md-8 col-lg-8 text-start align-self-center align-content-center">
                        {{
                          (ad_details && ad_details.property.address.street ? ad_details.property.address.street : '') +
                          (ad_details && ad_details.property.address.city ? ', ' + ad_details.property.address.city : '') +
                          (ad_details && ad_details.property.address.county ? ', ' + ad_details.property.address.county : '') +
                          (ad_details && ad_details.property.address.postcode ? ', ' + ad_details.property.address.postcode : '')
                        }}
                      </div>
                    </div>
                  </div>
                  <hr v-if="review_type === 'guest'"/>
                  <!-- End Additional info about guest -->

                  <!-- Additional info about service provider -->
                  <div v-if="review_type === 'service'" class="form-group col-12">
                    <div class="row m-2">
                      <div class="col-4 col-xs-4 col-sm-4 col-md-4 col-lg-4 m-0 p-0">
                        <div class="row m-0 p-0">
                          <div class="col-5 col-xs-5 col-sm-5 col-md-5 col-lg-5 m-0 p-0 text-start align-self-center align-content-center overflow-hidden">
                            <div v-if="!ad_details.host.documents_user_images">
                              <img :src="require(`@/assets/no-user-image.png`)"
                                   class="img-fluid user-image"
                                   alt="..."
                              >
                            </div>
                            <div v-else>
                              <img :src="`${$s3bucket}${ad_details.host.documents_user_images.file_path}`" @error="setUserDefaultImg"
                                   class="img-fluid user-image"
                                   alt="..."
                              >
                            </div>
                          </div>
                          <div class="col-7 col-xs-7 col-sm-7 col-md-7 col-lg-7 m-0 p-0 text-start align-self-center align-content-center overflow-hidden">
                            <span class="user-name">
                              {{ ad_details && ad_details.service && ad_details.service.contact ? ad_details.service.contact.name : '' }}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="col-8 col-xs-8 col-sm-8 col-md-8 col-lg-8 text-start align-self-center align-content-center">
                        {{
                          (ad_details && ad_details.service && ad_details.service.occupation ? ad_details.service.occupation.type : '')
                        }}
                      </div>
                    </div>
                  </div>
                  <hr v-if="review_type === 'service'"/>
                  <!-- End Additional info about service provider -->

                  <div v-if="review_type === 'host' || review_type === 'service'" class="form-group col-12">
                    <!-- Review Communication -->
                    <div class="row m-2">
                      <div class="col-4 col-xs-4 col-sm-4 col-md-4 col-lg-4 m-0 p-0 text-start">
                        Communication
                      </div>
                      <div class="col-8 col-xs-8 col-sm-8 col-md-8 col-lg-8 text-start">
                        <ul class="list d-inline-block">
                          <li :key="star" v-for="star in 5" :class="{ 'active': star <= review.review_communication }" @click="rate(star, 'communication')" class="star">
                            <!-- Full Star -->
                            <font-awesome-icon v-if="star <= review.review_communication" icon="star" class="star-icon" style="color: yellow;"/>
                            <!-- Empty Star -->
                            <font-awesome-icon v-if="(star > review.review_communication)" icon="star" class="star-icon" style="color: transparent;"/>
                          </li>
                        </ul>
                        <span class="ps-2 d-inline-block">{{ review.review_communication }} of 5</span>
                      </div>
                    </div>
                  </div>
                  <div v-if="review_type === 'host'" class="form-group col-12">
                    <!-- Review Cleanliness -->
                    <div class="row m-2">
                      <div class="col-4 col-xs-4 col-sm-4 col-md-4 col-lg-4 m-0 p-0 text-start">
                        Cleanliness
                      </div>
                      <div class="col-8 col-xs-8 col-sm-8 col-md-8 col-lg-8 text-start">
                        <ul class="list d-inline-block">
                          <li :key="star" v-for="star in 5" :class="{ 'active': star <= review.review_cleanliness }" @click="rate(star, 'cleanliness')" class="star">
                            <!-- Full Star -->
                            <font-awesome-icon v-if="star <= review.review_cleanliness" icon="star" class="star-icon" style="color: yellow;"/>
                            <!-- Empty Star -->
                            <font-awesome-icon v-if="(star > review.review_cleanliness)" icon="star" class="star-icon" style="color: transparent;"/>
                          </li>
                        </ul>
                        <span class="ps-2 d-inline-block">{{ review.review_cleanliness }} of 5</span>
                      </div>
                    </div>
                  </div>
                  <div v-if="review_type === 'host' || review_type === 'neighbourhood'" class="form-group col-12">
                    <!-- Review Location -->
                    <div class="row m-2">
                      <div class="col-4 col-xs-4 col-sm-4 col-md-4 col-lg-4 m-0 p-0 text-start">
                        Location
                      </div>
                      <div class="col-8 col-xs-8 col-sm-8 col-md-8 col-lg-8 text-start">
                        <ul class="list d-inline-block">
                          <li :key="star" v-for="star in 5" :class="{ 'active': star <= review.review_location }" @click="rate(star, 'location')" class="star">
                            <!-- Full Star -->
                            <font-awesome-icon v-if="star <= review.review_location" icon="star" class="star-icon" style="color: yellow;"/>
                            <!-- Empty Star -->
                            <font-awesome-icon v-if="(star > review.review_location)" icon="star" class="star-icon" style="color: transparent;"/>
                          </li>
                        </ul>
                        <span class="ps-2 d-inline-block">{{ review.review_location }} of 5</span>
                      </div>
                    </div>
                  </div>
                  <div v-if="review_type === 'host' || review_type === 'service'" class="form-group col-12">
                    <!-- Review Value -->
                    <div class="row m-2">
                      <div class="col-4 col-xs-4 col-sm-4 col-md-4 col-lg-4 m-0 p-0 text-start">
                        Value
                      </div>
                      <div class="col-8 col-xs-8 col-sm-8 col-md-8 col-lg-8 text-start">
                        <ul class="list d-inline-block">
                          <li :key="star" v-for="star in 5" :class="{ 'active': star <= review.review_value }" @click="rate(star, 'value')" class="star">
                            <!-- Full Star -->
                            <font-awesome-icon v-if="star <= review.review_value" icon="star" class="star-icon" style="color: yellow;"/>
                            <!-- Empty Star -->
                            <font-awesome-icon v-if="(star > review.review_value)" icon="star" class="star-icon" style="color: transparent;"/>
                          </li>
                        </ul>
                        <span class="ps-2 d-inline-block">{{ review.review_value }} of 5</span>
                      </div>
                    </div>
                  </div>
                  <div v-if="review_type === 'neighbourhood'" class="form-group col-12">
                    <!-- Review Amenities -->
                    <div class="row m-2">
                      <div class="col-4 col-xs-4 col-sm-4 col-md-4 col-lg-4 m-0 p-0 text-start">
                        Amenities
                      </div>
                      <div class="col-8 col-xs-8 col-sm-8 col-md-8 col-lg-8 text-start">
                        <ul class="list d-inline-block">
                          <li :key="star" v-for="star in 5" :class="{ 'active': star <= review.review_amenities }" @click="rate(star, 'amenities')" class="star">
                            <!-- Full Star -->
                            <font-awesome-icon v-if="star <= review.review_amenities" icon="star" class="star-icon" style="color: yellow;"/>
                            <!-- Empty Star -->
                            <font-awesome-icon v-if="(star > review.review_amenities)" icon="star" class="star-icon" style="color: transparent;"/>
                          </li>
                        </ul>
                        <span class="ps-2 d-inline-block">{{ review.review_amenities }} of 5</span>
                      </div>
                    </div>
                  </div>
                  <div v-if="review_type === 'neighbourhood'" class="form-group col-12">
                    <!-- Review Crime -->
                    <div class="row m-2">
                      <div class="col-4 col-xs-4 col-sm-4 col-md-4 col-lg-4 m-0 p-0 text-start">
                        Crime
                      </div>
                      <div class="col-8 col-xs-8 col-sm-8 col-md-8 col-lg-8 text-start">
                        <ul class="list d-inline-block">
                          <li :key="star" v-for="star in 5" :class="{ 'active': star <= review.review_crime }" @click="rate(star, 'crime')" class="star">
                            <!-- Full Star -->
                            <font-awesome-icon v-if="star <= review.review_crime" icon="star" class="star-icon" style="color: yellow;"/>
                            <!-- Empty Star -->
                            <font-awesome-icon v-if="(star > review.review_crime)" icon="star" class="star-icon" style="color: transparent;"/>
                          </li>
                        </ul>
                        <span class="ps-2 d-inline-block">{{ review.review_crime }} of 5</span>
                      </div>
                    </div>
                  </div>
                  <div v-if="review_type === 'neighbourhood'" class="form-group col-12">
                    <!-- Review Community -->
                    <div class="row m-2">
                      <div class="col-4 col-xs-4 col-sm-4 col-md-4 col-lg-4 m-0 p-0 text-start">
                        Community
                      </div>
                      <div class="col-8 col-xs-8 col-sm-8 col-md-8 col-lg-8 text-start">
                        <ul class="list d-inline-block">
                          <li :key="star" v-for="star in 5" :class="{ 'active': star <= review.review_community }" @click="rate(star, 'community')" class="star">
                            <!-- Full Star -->
                            <font-awesome-icon v-if="star <= review.review_community" icon="star" class="star-icon" style="color: yellow;"/>
                            <!-- Empty Star -->
                            <font-awesome-icon v-if="(star > review.review_community)" icon="star" class="star-icon" style="color: transparent;"/>
                          </li>
                        </ul>
                        <span class="ps-2 d-inline-block">{{ review.review_community }} of 5</span>
                      </div>
                    </div>
                  </div>
                  <div v-if="review_type === 'service'" class="form-group col-12">
                    <!-- Review Professionalism -->
                    <div class="row m-2">
                      <div class="col-4 col-xs-4 col-sm-4 col-md-4 col-lg-4 m-0 p-0 text-start">
                        Professionalism
                      </div>
                      <div class="col-8 col-xs-8 col-sm-8 col-md-8 col-lg-8 text-start">
                        <ul class="list d-inline-block">
                          <li :key="star" v-for="star in 5" :class="{ 'active': star <= review.review_professionalism }" @click="rate(star, 'professionalism')" class="star">
                            <!-- Full Star -->
                            <font-awesome-icon v-if="star <= review.review_professionalism" icon="star" class="star-icon" style="color: yellow;"/>
                            <!-- Empty Star -->
                            <font-awesome-icon v-if="(star > review.review_professionalism)" icon="star" class="star-icon" style="color: transparent;"/>
                          </li>
                        </ul>
                        <span class="ps-2 d-inline-block">{{ review.review_professionalism }} of 5</span>
                      </div>
                    </div>
                  </div>
                  <div v-if="review_type === 'guest'" class="form-group col-12">
                    <!-- Review Star -->
                    <div class="row m-2">
                      <div class="col-4 col-xs-4 col-sm-4 col-md-4 col-lg-4 m-0 p-0 text-start">
                        Review
                      </div>
                      <div class="col-8 col-xs-8 col-sm-8 col-md-8 col-lg-8 text-start">
                        <ul class="list d-inline-block">
                          <li :key="star" v-for="star in 5" :class="{ 'active': star <= review.review_star }" @click="rate(star, 'star')" class="star">
                            <!-- Full Star -->
                            <font-awesome-icon v-if="star <= review.review_star" icon="star" class="star-icon" style="color: yellow;"/>
                            <!-- Empty Star -->
                            <font-awesome-icon v-if="(star > review.review_star)" icon="star" class="star-icon" style="color: transparent;"/>
                          </li>
                        </ul>
                        <span class="ps-2 d-inline-block">{{ review.review_star }} of 5</span>
                      </div>
                    </div>
                  </div>

                  <div class="form-group col-12 text-start">
                    <div class="row m-2">
                      <label for="review_text" class="font-weight-bold m-0 p-0">Review Message (Optional)</label>
                      <textarea v-model="review.review_text" name="review_text" id="review_text" rows="4" cols="50" maxlength="300" class="form-control"/>
                      {{ counter }} / 300
                    </div>
                  </div>
                  <div class="col-12 mb-2 mt-2">
                    <button type="submit" :disabled="processing" @click="addReview" class="btn-branding border-branding btn-block min-width-100">
                      {{ processing ? "Please wait" : "Submit" }}
                    </button>
                    <p v-if="showAddReviewConfirmation && validationMessage" class="success-info text-center">
                      {{ validationMessage }}
                    </p>
                    <div v-if="validationErrors" class="global-error mt-2">
                      <ValidationErrors :errors="validationErrors"/>
                    </div>
                  </div>
                </form>
                <div v-else>
                  Something went wrong, seems like not enough or wrong data was provided to process add review page
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ValidationErrors from "../ValidationErrors";
import Loader from "../Loader";

export default {
  name: 'AddReview',
  props: ['adId', 'adDetails', 'reviewType', 'toUserId', 'fromUserId', 'refresh'],
  components: {
    'ValidationErrors': ValidationErrors,
    'Loader': Loader
  },
  data(){
    return {
      ad_id: this.adId ? this.adId : null,
      ad_details: this.adDetails ? this.adDetails : null,
      review_type: this.reviewType ? this.reviewType : null,
      to_user_id: this.toUserId ? this.toUserId : null,
      from_user_id: this.fromUserId ? this.fromUserId : null,
      review: {
        review_communication: 0,
        review_cleanliness: 0,
        review_location: 0,
        review_value: 0,
        review_amenities: 0,
        review_crime: 0,
        review_community: 0,
        review_professionalism: 0,
        review_star: 0,
        review_text: ''
      },
      processing: false,
      loading: false,
      validationMessage: '',
      validationErrors: '',
      showAddReviewConfirmation: false,
      counter: 0
    }
  },
  methods:{
    setUserDefaultImg(e) {
      e.target.src = require(`@/assets/no-user-image.png`);
    },
    rate(star, review_property) {
      if (
          typeof star === 'number' &&
          star <= 5 &&
          star >= 0
      )
        switch(review_property) {
          case 'communication':
            this.review.review_communication = this.review.review_communication === star ? star - 1 : star
            break;
          case 'cleanliness':
            this.review.review_cleanliness = this.review.review_cleanliness === star ? star - 1 : star
            break;
          case 'location':
            this.review.review_location = this.review.review_location === star ? star - 1 : star
            break;
          case 'value':
            this.review.review_value = this.review.review_value === star ? star - 1 : star
            break;
          case 'amenities':
            this.review.review_amenities = this.review.review_amenities === star ? star - 1 : star
            break;
          case 'crime':
            this.review.review_crime = this.review.review_crime === star ? star - 1 : star
            break;
          case 'community':
            this.review.review_community = this.review.review_community === star ? star - 1 : star
            break;
          case 'professionalism':
            this.review.review_professionalism = this.review.review_professionalism === star ? star - 1 : star
            break;
          case 'star':
            this.review.review_star = this.review.review_star === star ? star - 1 : star
            break;
        }
    },
    async addReview(){
      this.processing = true;

      let data = new FormData();

      // Host
      if (this.review_type === 'host') {
        data.append("ad_id", this.ad_id);  // this.adId
        data.append("booking_id", this.ad_details.id); // this.adDetails.id
        data.append("guest_id", this.from_user_id); // this.fromUserId
        data.append("host_id", this.to_user_id); // this.toUserId
        // Review
        data.append("review_communication", this.review.review_communication);
        data.append("review_cleanliness", this.review.review_cleanliness);
        data.append("review_location", this.review.review_location);
        data.append("review_value", this.review.review_value);
      }
      // Guest
      if (this.review_type === 'guest') {
        data.append("ad_id", this.ad_id);  // this.adId
        data.append("booking_id", this.ad_details.id); // this.adDetails.id
        data.append("guest_id", this.from_user_id); // this.fromUserId
        data.append("host_id", this.to_user_id); // this.toUserId
        // Review
        data.append("review_star", this.review.review_star);
      }
      // Service
      if (this.review_type === 'service') {
        data.append("ad_id", this.ad_id);  // this.adId
        data.append("booking_id", this.ad_details.id); // this.adDetails.id
        data.append("guest_id", this.from_user_id); // this.fromUserId
        data.append("host_id", this.to_user_id); // this.toUserId
        data.append("service_type_occupation_id", this.ad_details.service.service_type_occupation_id); // this.adDetails.service.service_type_occupation_id
        // Review
        data.append("review_communication", this.review.review_communication);
        data.append("review_professionalism", this.review.review_professionalism);
        data.append("review_value", this.review.review_value);
      }
      // Neighbourhood
      if (this.review_type === 'neighbourhood') {
        data.append("guest_id", this.from_user_id); // this.fromUserId
        data.append("address_id", this.ad_details.address.id); // this.adDetails.address.id
        data.append("address_region_name", this.ad_details.address.county); // this.adDetails.address.county
        data.append("latitude", this.ad_details.address.latitude); // this.adDetails.address.latitude
        data.append("longitude", this.ad_details.address.longitude); // this.adDetails.address.longitude
        // Review
        data.append("review_amenities", this.review.review_amenities);
        data.append("review_crime", this.review.review_crime);
        data.append("review_community", this.review.review_community);
        data.append("review_location", this.review.review_location);
      }

      data.append("review_text", this.review.review_text);
      data.append("user_id", this.$store.state.auth.user.id);
      data.append("review_type", this.review_type); // this.reviewType

      await axios.post('api/add-review', data).then(({data})=> {
        this.validationMessage = data.success;
        this.showAddReviewConfirmation = true;
        const timeout = setTimeout(() => {
          this.showAddReviewConfirmation = false;
          this.validationSuccess = null;
          clearTimeout(timeout);
        }, 7000);
        this.validationErrors = null;

        // We use below to refresh modal in App.vue by changing one of its bool keys
        this.$root.$emit('refreshModal', 'reviewsModal');

      }).catch(({response})=>{
        if (response && response.data && response.data.errors) {
          this.validationErrors = response.data.errors;
          this.validationMessage = response.data.message;
        }
      }).finally(()=>{
        this.processing = false
      })
    },
    async getBooking(id, type) {
      this.processing = true;

      let data = new FormData();

      data.append("type", type); // guest, host or service
      data.append("id", id); // booking_id
      data.append("user_id", this.$store.state.auth.user.id); // user_id

      await axios.post('api/booking-by-id-and-type', data).then(({data})=> {
        this.ad_details = data;
        this.validationSuccess = null;
        this.validationErrors = null;
      }).catch(({response})=>{
        if (response && response.data && response.data.errors) {
          this.validationErrors = response.data.errors;
          this.validationMessage = response.data.message;
        }
      }).finally(()=>{
        this.processing = false;
      })
    },
  },
  watch: {
    'review.review_text': function (val) {
      this.counter = val.length;
    },
    "$route.query": {
      handler: async function(query) {
        this.loading = true;
        if (query.ad_id && query.booking_id && query.from_user_id && query.to_user_id && query.type) {
          this.ad_id = query.ad_id;
          this.from_user_id = query.from_user_id;
          this.to_user_id = query.to_user_id;
          this.review_type = query.type;
          await this.getBooking(query.booking_id, query.type);
        }
        this.loading = false;
      }, deep: true, immediate: true,
    }
  }
}
</script>

<style lang="scss">

.user-image {
  width: 50px;
  height: 50px;
  margin: auto;
  border-radius: 50%;
  border: 1px solid #9e9e9e;
}

.list {
  margin: 0 0 5px 0;
  padding: 0;
  list-style-type: none;
  &:hover {
    .star {
      color: #f3d23e;
    }
  }
}
.star {
  display: inline-block;
  cursor: pointer;
  &:hover {
    &~.star {
      &:not(.active) {
        color: inherit;
      }
    }
  }
}
.active {
  color: #f3d23e;
}

</style>