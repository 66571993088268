<template>
  <div class="container-preview-ad-details">

    <div class="row">
      <div class="col-12 col-md-12 col-sm-12 col-lg-12 ad-details">
        <!-- Loop properties -->
        <div class="pt-2 pb-2">

          <div class="row ad-details-thumbnails">
            <div class="col-12 col-md-12 col-sm-12 col-lg-12 p-0 m-0">
              <div id="carouselCaptionsPreviewPropDetails" class="carousel slide" data-bs-ride="carousel" data-bs-interval="false">
                <ol class="carousel-indicators no-scroll d-none d-md-block" id="carouselThumbnailsPreviewPropDetails">
                  <li v-if="formData.previewPropImages.length === 0">
                    <img :src="require(`@/assets/no-image-found.png`)"
                         class="d-block img-fluid carousel-indicators-thumbnails"
                         alt="..."
                    >
                  </li>
                  <li v-for="(doc, num) in formData.previewPropImages"
                      :key="num"
                      :data-bs-target="'#carouselCaptionsPreviewPropDetails'"
                      :data-bs-slide-to="num"
                      aria-current="true"
                      :aria-label="'Slide '+num"
                      :class="num === 0 ? 'active' : ''"
                      :id="'carousel-thumb-'+num"
                  >
                    <img v-if="doc" :src="doc" class="d-block img-fluid carousel-indicators-thumbnails"  alt="..."/>
                  </li>
                </ol>
                <!--                :class="index === activeCarouselIndex ? 'active carousel-item' : 'carousel-item'"-->
                <div class="carousel-inner" id="adDetailItems">
                  <div v-if="formData.previewPropImages.length === 0">
                    <img :src="require(`@/assets/no-image-found.png`)"
                         class="d-block w-100 img-fluid thumbnails-height"
                         alt="..."
                    >
                  </div>
                  <div v-for="(doc, index) in formData.previewPropImages"
                       :key="index"
                       :class="index === 0 ? 'active carousel-item' : 'carousel-item'"
                       :id="'carousel-inner-'+index"
                  >
                    <img v-if="doc" :src="doc" class="d-block w-100 img-fluid thumbnails-height"  alt="..."/>
                  </div>
                </div>

                <button class="carousel-control-prev" type="button" :data-bs-target="'#carouselCaptionsPreviewPropDetails'" data-bs-slide="prev"> <!-- v-on:click="setActive(activeCarouselIndex-1)" -->
                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" :data-bs-target="'#carouselCaptionsPreviewPropDetails'" data-bs-slide="next"> <!-- v-on:click="setActive(activeCarouselIndex+1)" -->
                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Next</span>
                </button>

            </div>
          </div>
          <div class="col-12">
            <span aria-hidden="true" class="scroll-through-icon-span" v-on:click="scrollDetailsIntoView"><font-awesome-icon icon="chevron-circle-down" class="scroll-through-icon"/></span>
          </div>
          <div class="col-12 mt-2 mb-2" id="scrollIntoViewPreview">
            <div class="row text-center">
              <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-6">
                <div v-if="formData && formData.propertyCategory && formData.latitude && formData.longitude" class="row property-info m-0 p-0">
                  <div class="text-start list-relative property-info-box p-0">
                    <!-- Show Mini Map with Marker -->
                    <div class="ps-1 pe-1">
                      <MiniMap :lat="formData.latitude" :lng="formData.longitude" :propCategory="formData.propertyCategory" :customClass="'rounded-3'"></MiniMap>
                    </div>
                  </div>
                </div>
                <div class="row property-info m-0 p-0 mt-2">
                  <div class="text-start list-relative property-info-box p-0">
                    <div class="property-info-type text-truncate-item no-text-wrap overflow-hidden text-truncate ps-1 pe-1">
                      <span class="fw-bold">
                        {{ formData.propertyCatType }}
                      </span>
                      {{ (formData.full_address ? ' - ' + formData.full_address : '') }}
                    </div>
                  </div>
                </div>
                <div class="row property-info m-0 p-0 mt-2">
                  <div class="col-7 col-xs-7 col-sm-7 col-md-7 col-lg-7 m-0 p-0">
                    <div class="text-start position-relative property-info-box p-0">
                      <div class="text-start property-info-type text-truncate-item no-text-wrap overflow-hidden text-truncate ps-1 pe-1">
                        <span class="fw-bold">Posted:</span>
                        {{ calculateCreatedDate(formData.created_at) }}
                      </div>
                    </div>
                  </div>
                  <div class="col-5 col-xs-5 col-sm-5 col-md-5 col-lg-5 m-0 p-0">
                    <div class="text-end position-relative p-0">
                      <p class="m-0">
                        <span v-if="formData.is_sale" class="p-1 category-label" style="color: green; background-color: lightgreen;">FOR SALE</span>
                        <span v-if="formData.is_let" class="p-1 category-label" style="color: white; background-color: deepskyblue;">TO LET</span>
                        <span v-if="formData.is_buy" class="p-1 category-label" style="color: green; background-color: lightgreen;">TO BUY</span>
                        <span v-if="formData.is_rent" class="p-1 category-label" style="color: white; background-color: deepskyblue;">TO RENT</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div v-if="formData.is_let || formData.is_rent" class="row property-info m-0 p-0 mt-2">
                  <div class="text-start position-relative property-info-box p-0">
                    <div class="text-start property-info-type text-truncate-item no-text-wrap overflow-hidden text-truncate ps-1 pe-1">
                      <font-awesome-icon icon="calendar-day" style="color: green;"/>
                      <span class="fw-bold">{{formData.is_rent ? ' Required Dates:' : ' Availability:'}}</span>
                      {{ formData.date_range && formData.date_range.start ? (formData.date_range.end ? (moment.utc(formData.date_range.start).format('DD/MM/YYYY') + ' - ' + moment.utc(formData.date_range.end).format('DD/MM/YYYY')) : (moment.utc(formData.date_range.start).format('DD/MM/YYYY') + ' - ' + 'TBD')) : 'None' }}
                    </div>
                  </div>
                </div>

                <!-- Start Favourites, Share and Report -->
                <div class="row property-info m-0 p-0 mt-2">
                  <div class="col-4 col-xs-4 col-sm-4 col-md-4 col-lg-4 m-0 p-0">
                    <div class="text-center position-relative property-info-box p-0">
                      <div class="text-center property-info-type ps-1 pe-1">
                        <!-- Just preview AddBookmark button -->
                        <AddBookmark style="font-size: 30px;" :preview="true" :type="'Property'" :tooltipClass="'tooltiptext-right'"></AddBookmark>
                        <span class="text-black-50 small">Favourites</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-4 col-xs-4 col-sm-4 col-md-4 col-lg-4 m-0 p-0">
                    <div class="text-center position-relative property-info-box p-0">
                      <div class="text-center property-info-type ps-1 pe-1">
                        <!-- Just preview Share button -->
                        <Share :preview="true"></Share>
                        <span class="text-black-50 small">Share</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-4 col-xs-4 col-sm-4 col-md-4 col-lg-4 m-0 p-0">
                    <div class="text-center position-relative property-info-box p-0">
                      <!-- Just preview Report button -->
                      <div class="text-center property-info-type ps-1 pe-1">
                        <!-- Start Action Buttons -->
                        <div class="action-dropdown">
                          <button @click.stop="preventParentClick()"
                                  class="action-button-report"
                                  type="button"
                                  :id="'actionPropertyDropdownReportType'"
                                  data-bs-toggle="dropdown"
                                  data-bs-auto-close="outside"
                                  aria-expanded="false"
                          >
                                <span data-tooltip="tooltip" class="m-0 p-0">
                                  <div class="review-icons-container" style="font-size: 30px;">
                                    <div class="position-relative icon-wrapper">
                                      <font-awesome-icon icon="exclamation-triangle" class="icon-report" />
                                    </div>
                                  </div>
                                  <span class="tooltiptext">Report</span>
                                </span>
                          </button>
                          <!-- Report Type Dropdown  :id="'actionPropertyDropdownReportTypeMenu'+propertyDetails.id" -->
                          <ul class="dropdown-menu dropdown-menu-start list-unstyled action-buttons" :aria-labelledby="'actionPropertyDropdownReportType'">
                            <li class="action-item">
                              <button :class="processing ? 'disabled action-button-spam' : 'action-button-spam'">
                                <font-awesome-icon icon="clone" class="min-width-30 icon-spam" />
                                SPAM
                              </button>
                            </li>
                            <li class="action-item">
                              <button :class="processing ? 'disabled action-button-scam' : 'action-button-scam'">
                                <font-awesome-icon icon="user-ninja" class="min-width-30 icon-scam" />
                                SCAM
                              </button>
                            </li>
                            <li class="action-item">
                              <button :class="processing ? 'disabled action-button-abuse' : 'action-button-abuse'">
                                <font-awesome-icon icon="heart-broken" class="min-width-30 icon-abuse" />
                                ABUSE
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <span class="text-black-50 small">Report</span>
                    </div>

                  </div>
                </div>

                <!-- Just preview Reviews -->
                <div class="form-group col-12 text-center">
                  <!-- Start Horizontal Line with Icon -->
                  <div class="horizontal-separator-box">
                    <span class="hr-line-short"></span>
                    <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                    <span class="hr-line-short"></span>
                  </div>
                  <!-- End Horizontal Line with Icon -->

                  <!-- Information -->
                  <small class="p-3 fw-bold">Reviews</small>

                  <!-- Start Horizontal Line with Icon -->
                  <div class="horizontal-separator-box">
                    <span class="hr-line-short"></span>
                    <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                    <span class="hr-line-short"></span>
                  </div>
                  <!-- End Horizontal Line with Icon -->
                </div>

                <div class="row property-info m-0 p-0">
                  <span data-tooltip="tooltip" class="m-0 p-0">
                    <div
                        class="row m-0 p-0"
                    >
                      <div class="col-4 col-xs-4 col-sm-4 col-md-4 col-lg-4 m-0 p-0">
                        <div class="text-start position-relative property-info-box p-0">
                          <div class="text-start property-info-type ps-1 pe-1">
                            <div class="review-icons-container" style="font-size: 30px;">
                              <div class="position-relative icon-wrapper">
                                <font-awesome-icon icon="city" class="neighbourhood-review" style="color: mediumpurple;"/>
                                <font-awesome-icon icon="star" class="small-star" style="color: yellow;"/>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-8 col-xs-8 col-sm-8 col-md-8 col-lg-8 m-0 p-0">
                        <div class="text-start position-relative property-info-box p-0">
                          <div class="text-start property-info-type ps-1 pe-1 pt-3">
                            <!-- Use component here to display star rating -->
                            Rating: <ShowReviewRating :rating="5" :showNumericRating="true"></ShowReviewRating> (5 miles radius)
                          </div>
                        </div>
                      </div>
                    </div>
                    <span class="tooltiptext">Neighbourhood Reviews</span>
                  </span>

                  <span v-if="formData.is_let && formData.is_short_term" data-tooltip="tooltip" class="m-0 p-0">
                    <div
                        class="row m-0 p-0"
                    >
                      <div class="col-4 col-xs-4 col-sm-4 col-md-4 col-lg-4 m-0 p-0">
                        <div class="text-start position-relative property-info-box p-0">
                          <div class="text-start property-info-type ps-1 pe-1">
                            <div class="review-icons-container" style="font-size: 30px;">
                                <div class="position-relative icon-wrapper">
                                  <font-awesome-icon icon="house-user" class="neighbourhood-review" style="color: lightblue;"/>
                                  <font-awesome-icon icon="star" class="small-star" style="color: yellow;"/>
                                </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-8 col-xs-8 col-sm-8 col-md-8 col-lg-8 m-0 p-0">
                        <div class="text-start position-relative property-info-box p-0">
                          <div class="text-start property-info-type ps-1 pe-1 pt-3">
                            <!-- Use component here to display star rating -->
                            Rating: <ShowReviewRating :rating="5" :showNumericRating="true"></ShowReviewRating>
                          </div>
                        </div>
                      </div>
                    </div>
                    <span class="tooltiptext">Host Reviews</span>
                  </span>
                </div>
                <!-- End Favourites, Share and Report -->

              </div>

              <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-6">

                <!-- CONTACT DETAILS - USER OR AGENCY IMAGE -->
                <div class="row m-0 p-0 text-start marketed-by">
                  <!-- USER OR AGENCY IMAGE -->
                  <p class="marketed-by-heading">Marketed By</p>
                  <div class="col-8 m-0 p-0 ps-2 property-info">
                    <div v-if="formData.company_name && formData.show_company_name" class="list-relative-contact">
                      <div class="text-truncate-item no-text-wrap overflow-hidden text-truncate">
                        <span class="fw-bold">Company:</span>
                        {{ formData.company_name }}
                      </div>
                    </div>
                    <div v-if="formData.prop_contact_full_address && formData.show_address" class="list-relative-contact">
                      <div class="text-truncate-item no-text-wrap overflow-hidden text-truncate">
                        <span class="fw-bold">Address:</span>
                        {{ formData.prop_contact_full_address }}
                      </div>
                    </div>
                    <div class="list-relative-contact">
                      <div class="text-truncate-item no-text-wrap overflow-hidden text-truncate">
                        <span class="fw-bold">Contact:</span>
                        {{ formData.name ? formData.name : 'Anonymous' }}
                      </div>
                    </div>
                    <ul class="list-unstyled">
                      <!-- Landing -->
                      <li v-if="formData.landing && formData.show_landing">
                            <span data-tooltip="tooltip">
                              <div class="row m-0 p-0">
                                <div class="col-1 m-0 p-0 pt-1">
                                  <font-awesome-icon icon="phone" class="contact-icons"/>
                                </div>
                                <div class="col-11 m-0 p-0 pt-1">
                                  <a :href="'tel:'+calculateLanding()" alt="call" class="contact-url">
                                    {{ calculateLanding() }}
                                  </a>
                                  <span class="tooltiptext">Landing</span>
                                </div>
                              </div>
                            </span>
                      </li>
                      <!-- Mobile -->
                      <li v-if="formData.mobile && formData.show_mobile">
                              <span data-tooltip="tooltip">
                                <div class="row m-0 p-0">
                                  <div class="col-1 m-0 p-0 pt-1">
                                    <font-awesome-icon icon="mobile-alt" class="contact-icons"/>
                                  </div>
                                  <div class="col-11 m-0 p-0 pt-1">
                                    <a :href="'tel:'+calculateMobile()" alt="call" class="contact-url">
                                      {{ calculateMobile() }}
                                    </a>
                                    <span class="tooltiptext">Mobile</span>
                                  </div>
                                </div>
                              </span>
                      </li>
                      <!-- Email -->
                      <li v-if="formData.email && formData.show_email">
                              <span data-tooltip="tooltip">
                                <div class="row m-0 p-0">
                                  <div class="col-1 m-0 p-0 pt-1">
                                    <font-awesome-icon icon="envelope" class="contact-icons"/>
                                  </div>
                                  <div class="col-11 m-0 p-0 pt-1">
                                    <a :href="'mailto:'+formData.email" alt="email" class="contact-url">
                                      {{ formData.email }}
                                    </a>
                                    <span class="tooltiptext">Email</span>
                                  </div>
                                </div>
                              </span>
                      </li>
                    </ul>
                  </div>
                  <div class="col-4">
                    <div v-if="!this.$store.state.auth.user.documents_user_images">
                      <img :src="require(`@/assets/no-user-image.png`)"
                           class="d-block img-fluid user-image"
                           alt="..."
                      >
                    </div>
                    <div v-else>
<!--                      <img :src="require(`@/assets/${this.$store.state.auth.user.documents_user_images.file_name}`)"-->
<!--                           class="d-block img-fluid user-image"-->
<!--                           alt="..."-->
<!--                      >-->
                      <img :src="`${$s3bucket}${this.$store.state.auth.user.documents_user_images.file_path}`" @error="setUserDefaultImg"
                           class="d-block img-fluid user-image"
                           alt="..."
                      >
<!--                      <img v-else :src="require(`@/assets/no-image-found.png`)"-->
<!--                           class="d-block img-fluid user-image"-->
<!--                           alt="..."-->
<!--                      >-->
                    </div>
                  </div>

                  <!-- Chat Message -->
                  <hr v-if="this.$store.state.auth.authenticated === true && this.$store.state.auth.user" class="p-0 m-0"/>
                  <div v-if="this.$store.state.auth.authenticated === true && this.$store.state.auth.user" class="col-12 col-sm-12 col-md-12 col-lg-12 text-center p-2">
                    <button class="chat-msg-button">
                      <font-awesome-icon icon="comment-dots" class="contact-icons chat-icon"/> Send Message
                    </button>
<!--                    &lt;!&ndash; Chat Modal - Messages &ndash;&gt;-->
<!--                    <ChatModal class="chat-modal" :toUserId='disabled'></ChatModal>-->
                  </div>
                </div>

              </div>
            </div>
          </div>
<!--            <div class="col-12 mt-2 mb-2">-->
<!--              <div class="row text-start">-->
<!--                &lt;!&ndash; Start Horizontal Line with Icon &ndash;&gt;-->
<!--                <div class="horizontal-separator-box">-->
<!--                  <div class="property-info-container">-->
<!--                    <div class="text-start list-relative"> &lt;!&ndash; ps-3 pb-2 &ndash;&gt;-->
<!--                      <div class="property-info text-truncate-item no-text-wrap overflow-hidden text-truncate ps-1 pe-1">-->
<!--                        {{ formData.propertyCatType + ' - ' + formData.full_address }}-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <span aria-hidden="true" class="scroll-through-icon-span" v-on:click="scrollDetailsIntoView"><font-awesome-icon icon="chevron-circle-down" class="scroll-through-icon"/></span>-->
<!--                  <span class="hr-line-invisible"></span>-->
<!--                </div>-->
<!--                <div class="row property-info m-0 p-0 ps-1">-->
<!--                  Posted: {{ calculateCreatedDate(formData.created_at) }}-->
<!--                </div>-->
<!--                &lt;!&ndash; End Horizontal Line with Icon &ndash;&gt;-->
<!--              </div>-->
<!--            </div>-->

            <!-- Start Horizontal Line with Icon -->
            <div class="horizontal-separator-box">
              <span class="hr-line"></span>
              <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
              <span class="hr-line"></span>
            </div>
            <!-- End Horizontal Line with Icon -->

            <!-- Information -->
            <small class="p-3 fw-bold">Premises Details</small>

            <!-- Start Horizontal Line with Icon -->
            <div class="horizontal-separator-box">
              <span class="hr-line"></span>
              <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
              <span class="hr-line"></span>
            </div>
            <!-- End Horizontal Line with Icon -->

            <div class="col-12 mt-2 mb-2">
              <div class="row text-start">
                <div class="row m-0 p-0 ps-2 pe-2">
                  <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-6">
                    <div class="row m-0 p-0 property-icons">
                    <!-- ICONS -->

                    <!-- Start Top row icons: Price, Tenure -->
                    <div class="col-12">
                      <div class="row m-0 p-0 top-row-icons">
                        <div class="col-6">
                          <div class="row m-0 p-0">
                            <div class="col-1 m-0 p-0">
                              <ul class="list-unstyled">
                                <!-- Price -->
                                <li>
                                <span data-tooltip="tooltip">
                                  <font-awesome-icon icon="pound-sign" class="icon-fa min-width-30" />
                                  <span class="tooltiptext">Price</span>
                                </span>
                                </li>
                              </ul>
                            </div>
                            <div class="col-2">
                              <ul class="list-unstyled">
                                <!-- Price -->
                                <li class="list-relative">
                                  <div v-if="showPriceRange(formData)" class="text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                    <span v-if="showHourPriceRange(formData)" class="price-color"> {{ calculateHourPriceRange(formData) }} </span>
                                    <span v-if="showHourPriceRange(formData)" class="fw-bold"> per hour </span> <br v-if="showHourPriceRange(formData)"/>
                                    <span v-if="showNightPriceRange(formData)" class="price-color"> {{ calculateNightPriceRange(formData) }} </span>
                                    <span v-if="showNightPriceRange(formData)" class="fw-bold"> per night </span>
                                    <!-- This only for Let Long-Term Rooms -->
                                    <span v-if="showWeekPriceRange(formData)" class="price-color"> {{ calculateWeekPriceRange(formData) }} </span>
                                    <span v-if="showWeekPriceRange(formData)" class="fw-bold"> per week </span>
                                    <span v-if="showMonthPriceRange(formData)" class="price-color"> {{ calculateMonthPriceRange(formData) }} </span>
                                    <span v-if="showMonthPriceRange(formData)" class="fw-bold"> per month </span>
                                  </div>
                                  <div v-else class="text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                    <span class="price-color"> {{ convertNumberToCommas(formData.price) }} </span>
                                    <span v-if="formData.is_rent || checkAdIsLetLongTermOrStudio(formData)" class="fw-bold"> per {{ formData.property_rent_type ? formData.property_rent_type.type : '' }} </span>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div v-if="!formData.is_let && !formData.is_rent" class="col-6">
                          <div class="row m-0 p-0">
                            <div class="col-1 m-0 p-0">
                              <ul class="list-unstyled">
                                <!-- Tenure -->
                                <li>
                                  <span data-tooltip="tooltip">
                                    <font-awesome-icon icon="file-signature" class="icon-fa min-width-30" />
                                    <span class="tooltiptext">Tenure</span>
                                  </span>
                                </li>
                              </ul>
                            </div>
                            <div class="col-2">
                              <ul class="list-unstyled">
                                <!-- Tenure -->
                                <li class="list-relative">
                                  <div class="text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                    {{ formData.is_freehold ? 'Freehold' : 'Leasehold' }}
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div v-if="!formData.is_rent && !formData.is_buy" class="col-6">
                          <div class="row m-0 p-0">
                            <div class="col-1 m-0 p-0">
                              <ul class="list-unstyled">
                                <!-- EPC Rating -->
                                <li v-if="formData.epc_rating">
                                    <span data-tooltip="tooltip">
                                      <font-awesome-icon icon="poll-h" class="icon-fa min-width-30" />
                                      <span class="tooltiptext">EPC Rating</span>
                                    </span>
                                </li>
                              </ul>
                            </div>
                            <div class="col-2">
                              <ul class="list-unstyled">
                                <!-- EPC Rating -->
                                <li v-if="formData.epc_rating">
                                  {{ formData.epc_rating }}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="row m-0 p-0">
                            <div class="col-1 m-0 p-0">
                              <ul class="list-unstyled">
                                <!-- Square Meter -->
                                <li v-if="formData.property_square_meter">
                                    <span data-tooltip="tooltip">
                                      <font-awesome-icon icon="vector-square" class="icon-fa min-width-30" />
                                      <span class="tooltiptext">Area Square Meter</span>
                                    </span>
                                </li>
                              </ul>
                            </div>
                            <div class="col-2">
                              <ul class="list-unstyled">
                                <!-- Square Meter -->
                                <li v-if="formData.property_square_meter" class="list-relative min-height-25">
                                  <div class="text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                    {{ formData.property_square_meter }} ㎡
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>

                        <div v-if="conditionsStatus" class="form-group col-12 text-center">
                          <!-- Start Horizontal Line with Icon -->
                          <div class="horizontal-separator-box">
                            <span class="hr-line-short"></span>
                            <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                            <span class="hr-line-short"></span>
                          </div>
                          <!-- End Horizontal Line with Icon -->

                          <!-- Information -->
                          <small class="p-3 fw-bold">Status</small>

                          <!-- Start Horizontal Line with Icon -->
                          <div class="horizontal-separator-box">
                            <span class="hr-line-short"></span>
                            <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                            <span class="hr-line-short"></span>
                          </div>
                          <!-- End Horizontal Line with Icon -->
                        </div>

                        <!-- Investment Section -->
                        <div v-if="conditionsStatus" class="col-6 m-0 p-0">
                          <ul class="list-unstyled icons-listing">
                            <!-- Cash Only -->
                            <li v-if="formData.is_cash">
                                <span data-tooltip="tooltip">
                                  <font-awesome-icon icon="coins" class="icon-fa min-width-30" />
                                  <span class="tooltiptext">Cash Only</span>
                                </span>
                              Cash Only
                            </li>
                            <!-- Auction -->
                            <li v-if="formData.is_auction">
                                <span data-tooltip="tooltip">
                                  <font-awesome-icon icon="gavel" class="icon-fa min-width-30" />
                                  <span class="tooltiptext">Auction</span>
                                </span>
                              Auction
                            </li>
                            <!-- Investment -->
                            <li v-if="formData.is_investment">
                                <span data-tooltip="tooltip">
                                  <font-awesome-icon icon="chart-line" class="icon-fa min-width-30" />
                                  <span class="tooltiptext">Investment</span>
                                </span>
                              Investment
                            </li>
                            <!-- Mixed Use -->
                            <li v-if="formData.is_mixed_use">
                                <span data-tooltip="tooltip">
                                  <font-awesome-icon icon="balance-scale" class="icon-fa min-width-30" />
                                  <span class="tooltiptext">Mixed-use Premises</span>
                                </span>
                              Mixed-use
                            </li>
                          </ul>
                        </div>
                        <div v-if="conditionsStatus" class="col-6 m-0 ps-1 p-0 border-start-separator">
                          <ul class="list-unstyled icons-listing">
                            <!-- Cash Only -->
                            <li v-if="formData.is_cash">
                                <span data-tooltip="tooltip">
                                  <font-awesome-icon icon="coins" class="icon-fa min-width-30" />
                                  <span class="tooltiptext">Cash Only</span>
                                </span>
                              Yes
                            </li>
                            <!-- Auction -->
                            <li v-if="formData.is_auction">
                                <span data-tooltip="tooltip">
                                  <font-awesome-icon icon="gavel" class="icon-fa min-width-30" />
                                  <span class="tooltiptext">Auction</span>
                                </span>
                              Yes
                            </li>
                            <!-- Investment -->
                            <li v-if="formData.is_investment">
                                <span data-tooltip="tooltip">
                                  <font-awesome-icon icon="chart-line" class="icon-fa min-width-30" />
                                  <span class="tooltiptext">Investment</span>
                                </span>
                              Yes
                            </li>
                            <!-- Mixed Use -->
                            <li v-if="formData.is_mixed_use">
                                <span data-tooltip="tooltip">
                                  <font-awesome-icon icon="balance-scale" class="icon-fa min-width-30" />
                                  <span class="tooltiptext">Mixed-use Premises</span>
                                </span>
                              Yes
                            </li>
                          </ul>
                        </div>

                        <div v-if="formData.is_investment" class="form-group col-12 text-center">
                          <!-- Start Horizontal Line with Icon -->
                          <div class="horizontal-separator-box">
                            <span class="hr-line-short"></span>
                            <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                            <span class="hr-line-short"></span>
                          </div>
                          <!-- End Horizontal Line with Icon -->

                          <!-- Information -->
                          <small class="p-3 fw-bold">Investment</small>

                          <!-- Start Horizontal Line with Icon -->
                          <div class="horizontal-separator-box">
                            <span class="hr-line-short"></span>
                            <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                            <span class="hr-line-short"></span>
                          </div>
                          <!-- End Horizontal Line with Icon -->
                        </div>

                        <!-- Investment Section -->
                        <div v-if="formData.is_investment" class="col-6 m-0 p-0">
                          <ul class="list-unstyled icons-listing">
                            <!-- ROI -->
                            <li v-if="formData.roi">
                              <span data-tooltip="tooltip">
                                <font-awesome-icon icon="chart-bar" class="icon-fa min-width-30" />
                                <span class="tooltiptext">ROI</span>
                              </span>
                              ROI
                            </li>
                            <!-- ROA -->
                            <li v-if="formData.roa">
                              <span data-tooltip="tooltip">
                                <font-awesome-icon icon="chart-pie" class="icon-fa min-width-30" />
                                <span class="tooltiptext">ROA</span>
                              </span>
                              ROA
                            </li>
                            <!-- Yield -->
                            <li v-if="formData.yield">
                              <span data-tooltip="tooltip">
                                <font-awesome-icon icon="calculator" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Yield</span>
                              </span>
                              Yield
                            </li>
                            <!-- Share Percentage -->
                            <li v-if="formData.share_percentage">
                              <span data-tooltip="tooltip">
                                <font-awesome-icon icon="handshake" class="icon-fa min-width-20" />
                                  <font-awesome-icon icon="percentage" class="icon-fa min-width-10" />
                                <span class="tooltiptext">Share Percentage</span>
                              </span>
                              Share Percentage
                            </li>
                            <!-- Cashflow -->
                            <li v-if="formData.cash_flow">
                              <span data-tooltip="tooltip">
                                <font-awesome-icon icon="money-bill-wave" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Cashflow</span>
                              </span>
                              Cashflow
                            </li>
                            <!-- Maintenance Cost -->
                            <li v-if="formData.maintenance_cost">
                              <span data-tooltip="tooltip">
                                <font-awesome-icon icon="wallet" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Maintenance Cost</span>
                              </span>
                              Maintenance Cost
                            </li>
                            <!-- Number of Flats -->
                            <li v-if="formData.number_of_flats">
                              <span data-tooltip="tooltip">
                                <font-awesome-icon icon="building" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Number of Flats</span>
                              </span>
                              Number of Flats
                            </li>
                            <!-- Number of Blocks -->
                            <li v-if="formData.number_of_blocks">
                              <span data-tooltip="tooltip">
                                <font-awesome-icon icon="th" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Number of Blocks</span>
                              </span>
                              Number of Blocks
                            </li>
                            <!-- Number of Units -->
                            <li v-if="formData.number_of_units">
                              <span data-tooltip="tooltip">
                                <font-awesome-icon icon="stream" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Number of Units</span>
                              </span>
                              Number of Units
                            </li>
                          </ul>
                        </div>
                        <div v-if="formData.is_investment" class="col-6 m-0 ps-1 p-0 border-start-separator">
                          <ul class="list-unstyled icons-listing">
                            <!-- ROI -->
                            <li v-if="formData.roi">
                              <span data-tooltip="tooltip">
                                <font-awesome-icon icon="chart-bar" class="icon-fa min-width-30" />
                                <span class="tooltiptext">ROI</span>
                              </span>
                              {{ formData.roi ? formData.roi + '%' : '' }}
                            </li>
                            <!-- ROA -->
                            <li v-if="formData.roa">
                              <span data-tooltip="tooltip">
                                <font-awesome-icon icon="chart-pie" class="icon-fa min-width-30" />
                                <span class="tooltiptext">ROA</span>
                              </span>
                              {{ formData.roa ? formData.roa + '%' : '' }}
                            </li>
                            <!-- Yield -->
                            <li v-if="formData.yield">
                              <span data-tooltip="tooltip">
                                <font-awesome-icon icon="calculator" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Yield</span>
                              </span>
                              {{ formData.yield ? formData.yield + '%' : '' }}
                            </li>
                            <!-- Share Percentage -->
                            <li v-if="formData.share_percentage">
                              <span data-tooltip="tooltip">
                                <font-awesome-icon icon="handshake" class="icon-fa min-width-20" />
                                  <font-awesome-icon icon="percentage" class="icon-fa min-width-10" />
                                <span class="tooltiptext">Share Percentage</span>
                              </span>
                              {{ formData.share_percentage ? formData.share_percentage + '%' : '' }}
                            </li>
                            <!-- Cashflow -->
                            <li v-if="formData.cash_flow">
                              <span data-tooltip="tooltip">
                                <font-awesome-icon icon="money-bill-wave" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Cashflow</span>
                              </span>
                              {{ formData.cash_flow ? '£' + convertNumberToCommas(formData.cash_flow) : '' }}
                            </li>
                            <!-- Maintenance Cost -->
                            <li v-if="formData.maintenance_cost">
                              <span data-tooltip="tooltip">
                                <font-awesome-icon icon="wallet" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Maintenance Cost</span>
                              </span>
                              {{ formData.maintenance_cost ? '£' + convertNumberToCommas(formData.maintenance_cost) : '' }}
                            </li>
                            <!-- Number of Flats -->
                            <li v-if="formData.number_of_flats">
                              <span data-tooltip="tooltip">
                                <font-awesome-icon icon="building" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Number of Flats</span>
                              </span>
                              {{ formData.number_of_flats }}
                            </li>
                            <!-- Number of Blocks -->
                            <li v-if="formData.number_of_blocks">
                              <span data-tooltip="tooltip">
                                <font-awesome-icon icon="th" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Number of Blocks</span>
                              </span>
                              {{ formData.number_of_blocks }}
                            </li>
                            <!-- Number of Units -->
                            <li v-if="formData.number_of_units">
                              <span data-tooltip="tooltip">
                                <font-awesome-icon icon="stream" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Number of Units</span>
                              </span>
                              {{ formData.number_of_units }}
                            </li>
                          </ul>
                        </div>

                      </div>
                    </div>
                    <!-- End Top row icons: Price, Tenure -->

                      <div v-if="conditionsSuiteApartment" class="form-group col-12 text-center">
                        <!-- Start Horizontal Line with Icon -->
                        <div class="horizontal-separator-box">
                          <span class="hr-line-short"></span>
                          <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                          <span class="hr-line-short"></span>
                        </div>
                        <!-- End Horizontal Line with Icon -->

                        <!-- Information -->
                        <small class="p-3 fw-bold">Suite/Apartment</small>

                        <!-- Start Horizontal Line with Icon -->
                        <div class="horizontal-separator-box">
                          <span class="hr-line-short"></span>
                          <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                          <span class="hr-line-short"></span>
                        </div>
                        <!-- End Horizontal Line with Icon -->
                      </div>

                      <!-- New Icons Two Columns -->
                      <div v-if="conditionsSuiteApartment" class="col-6 m-0 p-0">
                        <ul class="list-unstyled icons-listing">
                          <!-- Suite/Apartment (Whole Place LET short-term not ROOM) -->
                          <li v-if="calculateSuiteBeds(formData)">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="building" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Suite/Apartment Bedrooms</span>
                            </span>
                            <span> Suite/Apartment Beds</span>
                          </li>
                          <!-- Single Bed Suite -->
                          <li v-if="formData.single_room_suite" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="dice-one" class="icon-fa min-width-10" />
                              <font-awesome-icon icon="bed" class="icon-fa min-width-20" />
                              <span class="tooltiptext">Single Bedroom</span>
                            </span>
                            Single Bedroom
                          </li>
                          <!-- Double Bed Suite -->
                          <li v-if="formData.double_room_suite" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="dice-two" class="icon-fa min-width-10" />
                              <font-awesome-icon icon="bed" class="icon-fa min-width-20" />
                              <span class="tooltiptext">Double Bedroom</span>
                            </span>
                            Double Bedroom
                          </li>
                        </ul>
                      </div>
                      <div v-if="conditionsSuiteApartment" class="col-6 m-0 ps-1 p-0 border-start-separator">
                        <ul class="list-unstyled icons-listing">
                          <!-- Suite/Apartment (Whole Place LET short-term not ROOM) -->
                          <li v-if="calculateSuiteBeds(formData)">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="building" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Suite/Apartment Bedrooms</span>
                            </span>
                            {{ calculateSuiteBeds(formData) }}
                          </li>
                          <!-- Single Bed Suite -->
                          <li v-if="formData.single_room_suite" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="dice-one" class="icon-fa min-width-10" />
                              <font-awesome-icon icon="bed" class="icon-fa min-width-20" />
                              <span class="tooltiptext">Single Bedroom</span>
                            </span>
                            {{ formData.single_room_suite }}
                          </li>
                          <!-- Double Bed Suite -->
                          <li v-if="formData.double_room_suite" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="dice-two" class="icon-fa min-width-10" />
                              <font-awesome-icon icon="bed" class="icon-fa min-width-20" />
                              <span class="tooltiptext">Double Bedroom</span>
                            </span>
                            {{ formData.double_room_suite }}
                          </li>
                        </ul>
                      </div>

                      <div v-if="conditionsMainFeatures" class="form-group col-12 text-center">
                        <!-- Start Horizontal Line with Icon -->
                        <div class="horizontal-separator-box">
                          <span class="hr-line-short"></span>
                          <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                          <span class="hr-line-short"></span>
                        </div>
                        <!-- End Horizontal Line with Icon -->

                        <!-- Information -->
                        <small class="p-3 fw-bold">Rooms</small>

                        <!-- Start Horizontal Line with Icon -->
                        <div class="horizontal-separator-box">
                          <span class="hr-line-short"></span>
                          <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                          <span class="hr-line-short"></span>
                        </div>
                        <!-- End Horizontal Line with Icon -->
                      </div>

                      <!-- New Icons Two Columns -->
                      <div v-if="conditionsMainFeatures" class="col-6 m-0 p-0">
                        <ul class="list-unstyled icons-listing">
                          <!-- Bedrooms -->
                          <li v-if="calculateBeds(formData)">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="bed" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Bedrooms</span>
                            </span>
                            <span> Bedrooms</span>
                          </li>
                          <!-- Single Bed -->
                          <li v-if="formData.single_room" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="dice-one" class="icon-fa min-width-10" />
                              <font-awesome-icon icon="bed" class="icon-fa min-width-20" />
                              <span class="tooltiptext">Single Bedroom</span>
                            </span>
                            Single Bedroom
                          </li>
                          <!-- Double Bed -->
                          <li v-if="formData.double_room" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="dice-two" class="icon-fa min-width-10" />
                              <font-awesome-icon icon="bed" class="icon-fa min-width-20" />
                              <span class="tooltiptext">Double Bedroom</span>
                            </span>
                            Double Bedroom
                          </li>
                          <!-- Twin Bed -->
                          <li v-if="formData.twin_room && checkAdIsLetShortTermOrRoom(formData)" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="dice-one" class="icon-fa min-width-10" />
                              <font-awesome-icon icon="dice-one" class="icon-fa min-width-10" />
                              <font-awesome-icon icon="bed" class="icon-fa min-width-20" />
                              <span class="tooltiptext">Twin Bedroom</span>
                            </span>
                            Twin Bedroom
                          </li>
                          <!-- Twin Double Bed -->
                          <li v-if="formData.twin_double_room && checkAdIsLetShortTermOrRoom(formData)" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="dice-two" class="icon-fa min-width-10" />
                              <font-awesome-icon icon="dice-two" class="icon-fa min-width-10" />
                              <font-awesome-icon icon="bed" class="icon-fa min-width-20" />
                              <span class="tooltiptext">Twin Double Bedroom</span>
                            </span>
                            Twin Double Bedroom
                          </li>
                          <!-- Triple Bed -->
                          <li v-if="formData.triple_room && checkAdIsLetShortTermOrRoom(formData)" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="dice-three" class="icon-fa min-width-10" />
                              <font-awesome-icon icon="bed" class="icon-fa min-width-20" />
                              <span class="tooltiptext">Triple Bedroom</span>
                            </span>
                            Triple Bedroom
                          </li>
                          <!-- Quadruple Bed -->
                          <li v-if="formData.quadruple_room && checkAdIsLetShortTermOrRoom(formData)" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="dice-four" class="icon-fa min-width-10" />
                              <font-awesome-icon icon="bed" class="icon-fa min-width-20" />
                              <span class="tooltiptext">Quadruple Bedroom</span>
                            </span>
                            Quadruple Bedroom
                          </li>
                          <!-- Family Bed -->
                          <li v-if="formData.family_room && checkAdIsLetShortTermOrRoom(formData)" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="child" class="icon-fa min-width-10" />
                              <font-awesome-icon icon="bed" class="icon-fa min-width-20" />
                              <span class="tooltiptext">Family Bedroom</span>
                            </span>
                            Family Bedroom
                          </li>
                          <!-- Studio Room -->
                          <li v-if="formData.studio_room" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="dice-d6" class="icon-fa min-width-10" />
                              <font-awesome-icon icon="bed" class="icon-fa min-width-20" />
                              <span class="tooltiptext">Studio Flat</span>
                            </span>
                            Studio Flat
                          </li>
                          <!-- Living Room -->
                          <li v-if="formData.living_room">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="couch" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Living Room</span>
                            </span>
                            <span> Living Room</span>
                          </li>
                          <!-- Dining Room -->
                          <li v-if="formData.dining_room">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="utensils" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Dining Room</span>
                            </span>
                            <span> Dining Room</span>
                          </li>
                          <!-- Box Room -->
                          <li v-if="formData.box_room">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="boxes" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Box Room</span>
                            </span>
                            <span> Box Room</span>
                          </li>
                        </ul>
                      </div>
                      <div v-if="conditionsMainFeatures" class="col-6 m-0 ps-1 p-0 border-start-separator">
                        <ul class="list-unstyled icons-listing">
                          <!-- Bedrooms -->
                          <li v-if="calculateBeds(formData)">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="bed" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Bedrooms</span>
                            </span>
                            {{ calculateBeds(formData) }}
                          </li>
                          <!-- Single Bed -->
                          <li v-if="formData.single_room" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="dice-one" class="icon-fa min-width-10" />
                              <font-awesome-icon icon="bed" class="icon-fa min-width-20" />
                              <span class="tooltiptext">Single Bedroom</span>
                            </span>
                            {{ formData.single_room }}
                          </li>
                          <!-- Double Bed -->
                          <li v-if="formData.double_room" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="dice-two" class="icon-fa min-width-10" />
                              <font-awesome-icon icon="bed" class="icon-fa min-width-20" />
                              <span class="tooltiptext">Double Bedroom</span>
                            </span>
                            {{ formData.double_room }}
                          </li>
                          <!-- Twin Bed -->
                          <li v-if="formData.twin_room && checkAdIsLetShortTermOrRoom(formData)" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="dice-one" class="icon-fa min-width-10" />
                              <font-awesome-icon icon="dice-one" class="icon-fa min-width-10" />
                              <font-awesome-icon icon="bed" class="icon-fa min-width-20" />
                              <span class="tooltiptext">Twin Bedroom</span>
                            </span>
                            {{ formData.twin_room }}
                          </li>
                          <!-- Twin Double Bed -->
                          <li v-if="formData.twin_double_room && checkAdIsLetShortTermOrRoom(formData)" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="dice-two" class="icon-fa min-width-10" />
                              <font-awesome-icon icon="dice-two" class="icon-fa min-width-10" />
                              <font-awesome-icon icon="bed" class="icon-fa min-width-20" />
                              <span class="tooltiptext">Twin Double Bedroom</span>
                            </span>
                            {{ formData.twin_double_room }}
                          </li>
                          <!-- Triple Bed -->
                          <li v-if="formData.triple_room && checkAdIsLetShortTermOrRoom(formData)" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="dice-three" class="icon-fa min-width-10" />
                              <font-awesome-icon icon="bed" class="icon-fa min-width-20" />
                              <span class="tooltiptext">Triple Bedroom</span>
                            </span>
                            {{ formData.triple_room }}
                          </li>
                          <!-- Quadruple Bed -->
                          <li v-if="formData.quadruple_room && checkAdIsLetShortTermOrRoom(formData)" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="dice-four" class="icon-fa min-width-10" />
                              <font-awesome-icon icon="bed" class="icon-fa min-width-20" />
                              <span class="tooltiptext">Quadruple Bedroom</span>
                            </span>
                            {{ formData.quadruple_room }}
                          </li>
                          <!-- Family Bed -->
                          <li v-if="formData.family_room && checkAdIsLetShortTermOrRoom(formData)" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="child" class="icon-fa min-width-10" />
                              <font-awesome-icon icon="bed" class="icon-fa min-width-20" />
                              <span class="tooltiptext">Family Bedroom</span>
                            </span>
                            {{ formData.family_room }}
                          </li>
                          <!-- Studio Room -->
                          <li v-if="formData.studio_room" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="dice-d6" class="icon-fa min-width-10" />
                              <font-awesome-icon icon="bed" class="icon-fa min-width-20" />
                              <span class="tooltiptext">Studio Flat</span>
                            </span>
                            Yes
                          </li>
                          <!-- Living Room -->
                          <li v-if="formData.living_room">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="couch" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Living Room</span>
                            </span>
                            {{ formData.living_room }}
                          </li>
                          <!-- Dining Room -->
                          <li v-if="formData.dining_room">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="utensils" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Dining Room</span>
                            </span>
                            {{ formData.dining_room }}
                          </li>
                          <!-- Box Room -->
                          <li v-if="formData.box_room">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="boxes" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Box Room</span>
                            </span>
                            {{ formData.box_room }}
                          </li>
                        </ul>
                      </div>

                      <div v-if="conditionsKitchen" class="form-group col-12 text-center">
                        <!-- Start Horizontal Line with Icon -->
                        <div class="horizontal-separator-box">
                          <span class="hr-line-short"></span>
                          <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                          <span class="hr-line-short"></span>
                        </div>
                        <!-- End Horizontal Line with Icon -->

                        <!-- Information -->
                        <small class="p-3 fw-bold">Kitchen</small>

                        <!-- Start Horizontal Line with Icon -->
                        <div class="horizontal-separator-box">
                          <span class="hr-line-short"></span>
                          <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                          <span class="hr-line-short"></span>
                        </div>
                        <!-- End Horizontal Line with Icon -->
                      </div>

                      <!-- New Icons Two Columns -->
                      <div v-if="conditionsKitchen" class="col-6 m-0 p-0">
                        <ul class="list-unstyled icons-listing">
                          <!-- Kitchen -->
                          <li v-if="formData.kitchen">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="fire-alt" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Kitchen</span>
                            </span>
                            <span> Kitchen</span>
                          </li>
                          <!-- Oven -->
                          <li v-if="formData.oven" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="burn" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Oven</span>
                            </span>
                            Oven
                          </li>
                          <!-- Fridge -->
                          <li v-if="formData.fridge" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="hamburger" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Fridge</span>
                            </span>
                            Fridge
                          </li>
                          <!-- Freezer -->
                          <li v-if="formData.freezer" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="snowflake" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Freezer</span>
                            </span>
                            Freezer
                          </li>
                          <!-- Microwave -->
                          <li v-if="formData.microwave" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="pager" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Microwave</span>
                            </span>
                            Microwave
                          </li>
                          <!-- Kettle -->
                          <li v-if="formData.kettle" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="mug-hot" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Kettle</span>
                            </span>
                            Kettle
                          </li>
                          <!-- Toaster -->
                          <li v-if="formData.toaster" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="bread-slice" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Toaster</span>
                            </span>
                            Toaster
                          </li>
                          <!-- Coffee -->
                          <li v-if="formData.coffee" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="coffee" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Coffee</span>
                            </span>
                            Coffee
                          </li>
                        </ul>
                      </div>
                      <div v-if="conditionsKitchen" class="col-6 m-0 ps-1 p-0 border-start-separator">
                        <ul class="list-unstyled icons-listing">
                          <!-- Kitchen -->
                          <li v-if="formData.kitchen">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="fire-alt" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Kitchen</span>
                            </span>
                            {{ formData.kitchen }}
                          </li>
                          <!-- Oven -->
                          <li v-if="formData.oven" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="burn" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Oven</span>
                            </span>
                            Yes
                          </li>
                          <!-- Fridge -->
                          <li v-if="formData.fridge" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="hamburger" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Fridge</span>
                            </span>
                            Yes
                          </li>
                          <!-- Freezer -->
                          <li v-if="formData.freezer" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="snowflake" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Freezer</span>
                            </span>
                            Yes
                          </li>
                          <!-- Microwave -->
                          <li v-if="formData.microwave" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="pager" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Microwave</span>
                            </span>
                            Yes
                          </li>
                          <!-- Kettle -->
                          <li v-if="formData.kettle" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="mug-hot" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Kettle</span>
                            </span>
                            Yes
                          </li>
                          <!-- Toaster -->
                          <li v-if="formData.toaster" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="bread-slice" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Toaster</span>
                            </span>
                            Yes
                          </li>
                          <!-- Coffee -->
                          <li v-if="formData.coffee" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="coffee" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Coffee</span>
                            </span>
                            Yes
                          </li>
                        </ul>
                      </div>

                      <div v-if="conditionsBathroom" class="form-group col-12 text-center">
                        <!-- Start Horizontal Line with Icon -->
                        <div class="horizontal-separator-box">
                          <span class="hr-line-short"></span>
                          <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                          <span class="hr-line-short"></span>
                        </div>
                        <!-- End Horizontal Line with Icon -->

                        <!-- Information -->
                        <small class="p-3 fw-bold">Bathroom</small>

                        <!-- Start Horizontal Line with Icon -->
                        <div class="horizontal-separator-box">
                          <span class="hr-line-short"></span>
                          <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                          <span class="hr-line-short"></span>
                        </div>
                        <!-- End Horizontal Line with Icon -->
                      </div>

                      <!-- New Icons Two Columns -->
                      <div v-if="conditionsBathroom" class="col-6 m-0 p-0">
                        <ul class="list-unstyled icons-listing">
                          <!-- Bathrooms -->
                          <li v-if="conditionsBathroom">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="bath" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Bathrooms</span>
                            </span>
                            <span> Bathrooms</span>
                          </li>
                          <!-- WC -->
                          <li v-if="formData.wc" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="toilet" class="icon-fa min-width-30" />
                              <span class="tooltiptext">WC</span>
                            </span>
                            WC
                          </li>
                          <!-- Downstairs WC -->
                          <li v-if="formData.downstairs_wc" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="toilet" class="icon-fa min-width-20" />
                              <font-awesome-icon icon="hand-point-down" class="icon-fa min-width-10" />
                              <span class="tooltiptext">Downstairs WC</span>
                            </span>
                            Downstairs WC
                          </li>
                          <!-- Shower -->
                          <li v-if="formData.shower" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="shower" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Shower</span>
                            </span>
                            Shower
                          </li>
                          <!-- Bath -->
                          <li v-if="formData.bath" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="bath" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Bath</span>
                            </span>
                            Bath
                          </li>
                          <!-- Sink -->
                          <li v-if="formData.sink" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="sink" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Sink</span>
                            </span>
                            Sink
                          </li>
                        </ul>
                      </div>
                      <div v-if="conditionsBathroom" class="col-6 m-0 ps-1 p-0 border-start-separator">
                        <ul class="list-unstyled icons-listing">
                          <!-- Bathrooms -->
                          <li v-if="conditionsBathroom">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="bath" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Bathrooms</span>
                            </span>
                            {{ formData.bath_room ? formData.bath_room : 0 }}
                          </li>
                          <!-- WC -->
                          <li v-if="formData.wc" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="toilet" class="icon-fa min-width-30" />
                              <span class="tooltiptext">WC</span>
                            </span>
                            {{ formData.wc }}
                          </li>
                          <!-- Downstairs WC -->
                          <li v-if="formData.downstairs_wc" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="toilet" class="icon-fa min-width-20" />
                              <font-awesome-icon icon="hand-point-down" class="icon-fa min-width-10" />
                              <span class="tooltiptext">Downstairs WC</span>
                            </span>
                            {{ formData.downstairs_wc }}
                          </li>
                          <!-- Shower -->
                          <li v-if="formData.shower" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="shower" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Shower</span>
                            </span>
                            {{ formData.shower }}
                          </li>
                          <!-- Bath -->
                          <li v-if="formData.bath" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="bath" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Bath</span>
                            </span>
                            {{ formData.bath }}
                          </li>
                          <!-- Sink -->
                          <li v-if="formData.sink" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="sink" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Sink</span>
                            </span>
                            {{ formData.sink }}
                          </li>
                        </ul>
                      </div>

                      <div v-if="conditionsStructural" class="form-group col-12 text-center">
                        <!-- Start Horizontal Line with Icon -->
                        <div class="horizontal-separator-box">
                          <span class="hr-line-short"></span>
                          <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                          <span class="hr-line-short"></span>
                        </div>
                        <!-- End Horizontal Line with Icon -->

                        <!-- Information -->
                        <small class="p-3 fw-bold">Structural</small>

                        <!-- Start Horizontal Line with Icon -->
                        <div class="horizontal-separator-box">
                          <span class="hr-line-short"></span>
                          <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                          <span class="hr-line-short"></span>
                        </div>
                        <!-- End Horizontal Line with Icon -->
                      </div>

                      <div v-if="conditionsStructural" class="col-6 m-0 p-0">
                        <ul class="list-unstyled icons-listing">
                          <!-- Garden -->
                          <li v-if="conditionsGarden">
                              <span data-tooltip="tooltip">
                                <font-awesome-icon icon="seedling" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Garden</span>
                              </span>
                            Garden
                          </li>
                          <!-- Front Garden -->
                          <li v-if="formData.front_garden" class="ps-3 sub-icons-font">
                              <span data-tooltip="tooltip">
                                <font-awesome-icon icon="leaf" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Front Garden</span>
                              </span>
                            Front Garden
                          </li>
                          <!-- Rear Garden -->
                          <li v-if="formData.rear_garden" class="ps-3 sub-icons-font">
                              <span data-tooltip="tooltip">
                                <font-awesome-icon icon="tree" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Rear Garden</span>
                              </span>
                            Rear Garden
                          </li>
                          <!-- Garden Terrace -->
                          <li v-if="formData.garden_terrace" class="ps-3 sub-icons-font">
                              <span data-tooltip="tooltip">
                                <font-awesome-icon icon="ruler-horizontal" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Garden Terrace</span>
                              </span>
                            Garden Terrace
                          </li>
                          <!-- Conservatory -->
                          <li v-if="formData.conservatory" class="ps-3 sub-icons-font">
                              <span data-tooltip="tooltip">
                                <font-awesome-icon icon="store-alt" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Conservatory</span>
                              </span>
                            Conservatory
                          </li>
                          <!-- Green House -->
                          <li v-if="formData.green_house" class="ps-3 sub-icons-font">
                              <span data-tooltip="tooltip">
                                <font-awesome-icon icon="border-none" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Green House</span>
                              </span>
                            Green House
                          </li>
                          <!-- Shed -->
                          <li v-if="formData.shed" class="ps-3 sub-icons-font">
                              <span data-tooltip="tooltip">
                                <font-awesome-icon icon="box-open" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Shed</span>
                              </span>
                            Shed
                          </li>
                          <!-- Parking -->
                          <li v-if="calculateParking(formData.driveway, formData.garage, formData.parking, formData.parking_spaces)">
                              <span data-tooltip="tooltip">
                                <font-awesome-icon icon="parking" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Parking</span>
                              </span>
                            Parking
                          </li>
                          <!-- Driveway -->
                          <li v-if="formData.driveway" class="ps-3 sub-icons-font">
                              <span data-tooltip="tooltip">
                                <font-awesome-icon icon="car" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Driveway</span>
                              </span>
                            Driveway
                          </li>
                          <!-- Garage -->
                          <li v-if="formData.garage" class="ps-3 sub-icons-font">
                              <span data-tooltip="tooltip">
                                <font-awesome-icon icon="warehouse" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Garage</span>
                              </span>
                            Garage
                          </li>
                          <!-- Front Porch -->
                          <li v-if="formData.front_porch">
                              <span data-tooltip="tooltip">
                                <font-awesome-icon icon="door-closed" class="icon-fa min-width-20" />
                                <font-awesome-icon icon="level-down-alt" class="icon-fa min-width-10" />
                                <span class="tooltiptext">Front Porch</span>
                              </span>
                            Front Porch
                          </li>
                          <!-- Rear Porch -->
                          <li v-if="formData.rear_porch">
                              <span data-tooltip="tooltip">
                                <font-awesome-icon icon="door-closed" class="icon-fa min-width-20" />
                                <font-awesome-icon icon="level-up-alt" class="icon-fa min-width-10" />
                                <span class="tooltiptext">Rear Porch</span>
                              </span>
                            Rear Porch
                          </li>
                          <!-- Floors -->
                          <li v-if="formData.floors">
                              <span data-tooltip="tooltip">
                                <font-awesome-icon icon="layer-group" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Floors</span>
                              </span>
                            Floors
                          </li>
                          <!-- Attic/Loft -->
                          <li v-if="formData.attic">
                              <span data-tooltip="tooltip">
                                <font-awesome-icon icon="caret-square-up" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Attic/Loft</span>
                              </span>
                            Attic/Loft
                          </li>
                          <!-- Basement -->
                          <li v-if="formData.basement">
                              <span data-tooltip="tooltip">
                                <font-awesome-icon icon="caret-square-down" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Basement</span>
                              </span>
                            Basement
                          </li>
                          <!-- Balcony -->
                          <li v-if="formData.balcony">
                              <span data-tooltip="tooltip">
                                <font-awesome-icon icon="chess-rook" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Balcony</span>
                              </span>
                            Balcony
                          </li>
                          <!-- Charging Station -->
                          <li v-if="formData.charging_station">
                              <span data-tooltip="tooltip">
                                <font-awesome-icon icon="charging-station" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Charging Station</span>
                              </span>
                            Charging Station
                          </li>
                          <!-- Solar Panels -->
                          <li v-if="formData.solar_panels">
                              <span data-tooltip="tooltip">
                                <font-awesome-icon icon="solar-panel" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Solar Panels</span>
                              </span>
                            Solar Panels
                          </li>
                        </ul>
                      </div>
                      <div v-if="conditionsStructural" class="col-6 m-0 ps-1 p-0 border-start-separator">
                        <ul class="list-unstyled icons-listing">
                          <!-- Garden -->
                          <li v-if="conditionsGarden">
                            <span data-tooltip="tooltip">
                                <font-awesome-icon icon="seedling" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Garden</span>
                            </span>
                            {{ calculateGarden(formData.front_garden, formData.rear_garden) > 0 ? calculateGarden(formData.front_garden, formData.rear_garden) : '0' }}
                          </li>
                          <!-- Front Garden -->
                          <li v-if="formData.front_garden" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                                <font-awesome-icon icon="leaf" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Front Garden</span>
                            </span>
                            Yes
                          </li>
                          <!-- Rear Garden -->
                          <li v-if="formData.rear_garden" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                                <font-awesome-icon icon="tree" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Rear Garden</span>
                            </span>
                            Yes
                          </li>
                          <!-- Garden Terrace -->
                          <li v-if="formData.garden_terrace" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                                <font-awesome-icon icon="ruler-horizontal" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Garden Terrace</span>
                            </span>
                            Yes
                          </li>
                          <!-- Conservatory -->
                          <li v-if="formData.conservatory" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                                <font-awesome-icon icon="store-alt" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Conservatory</span>
                            </span>
                            Yes
                          </li>
                          <!-- Green House -->
                          <li v-if="formData.green_house" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                                <font-awesome-icon icon="border-none" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Green House</span>
                            </span>
                            {{ formData.green_house }}
                          </li>
                          <!-- Shed -->
                          <li v-if="formData.shed" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                                <font-awesome-icon icon="box-open" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Shed</span>
                            </span>
                            {{ formData.shed }}
                          </li>
                          <!-- Parking -->
                          <li v-if="calculateParking(formData.driveway, formData.garage, formData.parking, formData.parking_spaces)">
                            <span data-tooltip="tooltip">
                                <font-awesome-icon icon="parking" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Parking</span>
                            </span>
                            <span v-if="checkAdIsLetShortTermOrRoom(formData)">
                              {{ calculateParking(formData.driveway, formData.garage, formData.parking, formData.parking_spaces) }} / {{ formData.parking_paid ? 'Paid: £ ' + convertNumberToCommas(formData.parking_fee) : 'Free'}}
                            </span>
                            <span v-else>
                              {{ calculateParking(formData.driveway, formData.garage, formData.parking, formData.parking_spaces) }}
                            </span>
                          </li>
                          <!-- Driveway -->
                          <li v-if="formData.driveway" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                                <font-awesome-icon icon="car" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Driveway</span>
                            </span>
                            {{ formData.driveway }}
                          </li>
                          <!-- Garage -->
                          <li v-if="formData.garage" class="ps-3 sub-icons-font">
                            <span data-tooltip="tooltip">
                                <font-awesome-icon icon="warehouse" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Garage</span>
                            </span>
                            {{ formData.garage }}
                          </li>
                          <!-- Front Porch -->
                          <li v-if="formData.front_porch">
                            <span data-tooltip="tooltip">
                                <font-awesome-icon icon="door-closed" class="icon-fa min-width-20" />
                                <font-awesome-icon icon="level-down-alt" class="icon-fa min-width-10" />
                                <span class="tooltiptext">Front Porch</span>
                            </span>
                            Yes
                          </li>
                          <!-- Rear Porch -->
                          <li v-if="formData.rear_porch">
                            <span data-tooltip="tooltip">
                                <font-awesome-icon icon="door-closed" class="icon-fa min-width-20" />
                                <font-awesome-icon icon="level-up-alt" class="icon-fa min-width-10" />
                                <span class="tooltiptext">Rear Porch</span>
                            </span>
                            Yes
                          </li>
                          <!-- Floors -->
                          <li v-if="formData.floors">
                            <span data-tooltip="tooltip">
                                <font-awesome-icon icon="layer-group" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Floors</span>
                            </span>
                            {{ formData.floors }}
                          </li>
                          <!-- Attic/Loft -->
                          <li v-if="formData.attic">
                            <span data-tooltip="tooltip">
                                <font-awesome-icon icon="caret-square-up" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Attic/Loft</span>
                            </span>
                            Yes
                          </li>
                          <!-- Basement -->
                          <li v-if="formData.basement">
                            <span data-tooltip="tooltip">
                                <font-awesome-icon icon="caret-square-down" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Basement</span>
                            </span>
                            Yes
                          </li>
                          <!-- Balcony -->
                          <li v-if="formData.balcony">
                            <span data-tooltip="tooltip">
                                <font-awesome-icon icon="chess-rook" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Balcony</span>
                            </span>
                            Yes
                          </li>
                          <!-- Charging Station -->
                          <li v-if="formData.charging_station">
                            <span data-tooltip="tooltip">
                                <font-awesome-icon icon="charging-station" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Charging Station</span>
                            </span>
                            Yes
                          </li>
                          <!-- Solar Panels -->
                          <li v-if="formData.solar_panels">
                            <span data-tooltip="tooltip">
                                <font-awesome-icon icon="solar-panel" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Solar Panels</span>
                            </span>
                            Yes
                          </li>
                        </ul>
                      </div>

                      <div v-if="conditionsAdditionals" class="form-group col-12 text-center">
                        <!-- Start Horizontal Line with Icon -->
                        <div class="horizontal-separator-box">
                          <span class="hr-line-short"></span>
                          <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                          <span class="hr-line-short"></span>
                        </div>
                        <!-- End Horizontal Line with Icon -->

                        <!-- Information -->
                        <small class="p-3 fw-bold">Additional Info</small>

                        <!-- Start Horizontal Line with Icon -->
                        <div class="horizontal-separator-box">
                          <span class="hr-line-short"></span>
                          <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                          <span class="hr-line-short"></span>
                        </div>
                        <!-- End Horizontal Line with Icon -->
                      </div>

                      <div v-if="conditionsAdditionals" class="col-6 m-0 p-0">
                        <ul class="list-unstyled icons-listing">
                          <!-- Accessibility -->
                          <li v-if="formData.is_accessible">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="wheelchair" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Accessibility</span>
                            </span>
                            Accessibility
                          </li>
                          <!-- Shared Ownership -->
                          <li v-if="formData.is_shared_ownership">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="handshake" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Shared Ownership</span>
                            </span>
                            Shared Ownership
                          </li>
                          <!-- Over 60's only -->
                          <li v-if="formData.is_over_60s">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="hand-holding-heart" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Over 60's only</span>
                            </span>
                            Over 60's only
                          </li>
                          <!-- HMO -->
                          <li v-if="formData.is_hmo">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="users" class="icon-fa min-width-30" />
                              <span class="tooltiptext">HMO</span>
                            </span>
                            HMO
                          </li>
                          <!-- Furnished -->
                          <li v-if="formData.is_furnished">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="chair" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Furnished</span>
                            </span>
                            Furnished
                          </li>
                          <!-- Tenanted -->
                          <li v-if="formData.is_tenanted">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="house-user" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Tenanted</span>
                            </span>
                            Tenanted
                          </li>
                          <!-- Pet Friendly -->
                          <li v-if="formData.is_pet_friendly">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="paw" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Pet Friendly</span>
                            </span>
                            Pet Friendly
                          </li>
                          <!-- Events Allowed -->
                          <li v-if="formData.events_allowed">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="music" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Events Allowed</span>
                            </span>
                            Events Allowed
                          </li>
                          <!-- DSS Accepted -->
                          <li v-if="formData.is_dss_accepted">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="user-shield" class="icon-fa min-width-30" />
                              <span class="tooltiptext">DSS Accepted</span>
                            </span>
                            DSS Accepted
                          </li>
                          <!-- Easements and Covenants -->
                          <li v-if="formData.easements">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="exclamation-circle" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Easements and Covenants</span>
                            </span>
                            Easements and Covenants
                          </li>
                          <!-- Holiday -->
                          <li v-if="formData.is_holiday">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="plane" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Holiday</span>
                            </span>
                            Holiday
                          </li>
                          <!-- Seashore -->
                          <li v-if="formData.is_seashore">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="water" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Seashore</span>
                            </span>
                            Seashore
                          </li>
                          <!-- Waterfront -->
                          <li v-if="formData.is_waterfront">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="home" class="icon-fa min-width-20 max-width-20" />
                              <font-awesome-icon icon="water" class="icon-fa min-width-10 max-width-10" />
                              <span class="tooltiptext">Waterfront</span>
                            </span>
                            Waterfront
                          </li>
                        </ul>
                      </div>
                      <div v-if="conditionsAdditionals" class="col-6 m-0 ps-1 p-0 border-start-separator">
                        <ul class="list-unstyled icons-listing">
                          <!-- Accessibility -->
                          <li v-if="formData.is_accessible">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="wheelchair" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Accessibility</span>
                            </span>
                            Yes
                          </li>
                          <!-- Shared Ownership -->
                          <li v-if="formData.is_shared_ownership">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="handshake" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Shared Ownership</span>
                            </span>
                            Yes
                          </li>
                          <!-- Over 60's only -->
                          <li v-if="formData.is_over_60s">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="hand-holding-heart" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Over 60's only</span>
                            </span>
                            Yes
                          </li>
                          <!-- HMO -->
                          <li v-if="formData.is_hmo">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="users" class="icon-fa min-width-30" />
                              <span class="tooltiptext">HMO</span>
                            </span>
                            Yes
                          </li>
                          <!-- Furnished -->
                          <li v-if="formData.is_furnished">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="chair" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Furnished</span>
                            </span>
                            Yes
                          </li>
                          <!-- Tenanted -->
                          <li v-if="formData.is_tenanted">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="house-user" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Tenanted</span>
                            </span>
                            Yes
                          </li>
                          <!-- Pet Friendly -->
                          <li v-if="formData.is_pet_friendly">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="paw" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Pet Friendly</span>
                            </span>
                            <span v-if="formData.is_let && formData.is_short_term">
                              {{ formData.pet_friendly_paid ? 'Paid: £ ' + convertNumberToCommas(formData.pet_friendly_fee) : 'Free' }}
                            </span>
                            <span v-else>
                              Yes
                            </span>
                          </li>
                          <!-- Events Allowed -->
                          <li v-if="formData.events_allowed">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="music" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Events Allowed</span>
                            </span>
                            Yes
                          </li>
                          <!-- DSS Accepted -->
                          <li v-if="formData.is_dss_accepted">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="user-shield" class="icon-fa min-width-30" />
                              <span class="tooltiptext">DSS Accepted</span>
                            </span>
                            Yes
                          </li>
                          <!-- Easements and Covenants -->
                          <li v-if="formData.easements">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="exclamation-circle" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Easements and Covenants</span>
                            </span>
                            Yes
                          </li>
                          <!-- Holiday -->
                          <li v-if="formData.is_holiday">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="plane" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Holiday</span>
                            </span>
                            Yes
                          </li>
                          <!-- Seashore -->
                          <li v-if="formData.is_seashore">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="water" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Seashore</span>
                            </span>
                            Yes
                          </li>
                          <!-- Waterfront -->
                          <li v-if="formData.is_waterfront">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="home" class="icon-fa min-width-20 max-width-20" />
                              <font-awesome-icon icon="water" class="icon-fa min-width-10 max-width-10" />
                              <span class="tooltiptext">Waterfront</span>
                            </span>
                            Yes
                          </li>
                        </ul>
                      </div>

                      <div v-if="conditionsServicesAndAmenities" class="form-group col-12 text-center">
                        <!-- Start Horizontal Line with Icon -->
                        <div class="horizontal-separator-box">
                          <span class="hr-line-short"></span>
                          <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                          <span class="hr-line-short"></span>
                        </div>
                        <!-- End Horizontal Line with Icon -->

                        <!-- Information -->
                        <small class="p-3 fw-bold">Services & Amenities</small>

                        <!-- Start Horizontal Line with Icon -->
                        <div class="horizontal-separator-box">
                          <span class="hr-line-short"></span>
                          <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                          <span class="hr-line-short"></span>
                        </div>
                        <!-- End Horizontal Line with Icon -->
                      </div>

                      <div v-if="conditionsServicesAndAmenities" class="col-6 m-0 p-0">
                        <ul class="list-unstyled icons-listing">
                          <!-- Room Service -->
                          <li v-if="formData.room_service">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="door-open" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Room Service</span>
                            </span>
                            Room Service
                          </li>
                          <!-- Cleaning Service -->
                          <li v-if="formData.cleaning_service">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="hands-wash" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Cleaning Service</span>
                            </span>
                            Cleaning Service
                          </li>
                          <!-- Wi-Fi -->
                          <li v-if="formData.wifi">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="wifi" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Wi-Fi</span>
                            </span>
                            Wi-Fi
                          </li>
                          <!-- Breakfast -->
                          <li v-if="formData.breakfast">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="bread-slice" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Breakfast</span>
                            </span>
                            Breakfast
                          </li>
                          <!-- Gym -->
                          <li v-if="formData.gym">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="dumbbell" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Gym</span>
                            </span>
                            Gym
                          </li>
                          <!-- Cinema Room -->
                          <li v-if="formData.cinema_room">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="film" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Cinema Room</span>
                            </span>
                            Cinema Room
                          </li>
                          <!-- Sauna -->
                          <li v-if="formData.sauna">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="thermometer-full" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Sauna</span>
                            </span>
                            Sauna
                          </li>
                          <!-- Hot Tub -->
                          <li v-if="formData.hot_tub">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="hot-tub" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Hot Tub</span>
                            </span>
                            Hot Tub
                          </li>
                          <!-- Spa -->
                          <li v-if="formData.spa">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="spa" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Spa</span>
                            </span>
                            Spa
                          </li>
                          <!-- Inside Swimming Pool -->
                          <li v-if="formData.inside_pool">
                              <span data-tooltip="tooltip">
                                <font-awesome-icon icon="swimmer" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Inside Pool</span>
                              </span>
                            Inside Swimming Pool
                          </li>
                          <!-- Outside Swimming Pool -->
                          <li v-if="formData.outside_pool">
                              <span data-tooltip="tooltip">
                                <font-awesome-icon icon="swimming-pool" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Outside Pool</span>
                              </span>
                            Outside Swimming Pool
                          </li>
                          <!-- Bicycle -->
                          <li v-if="formData.bicycle">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="bicycle" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Bicycle</span>
                            </span>
                            Bicycle
                          </li>
                          <!-- Fire Rings -->
                          <li v-if="formData.fire_rings">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="fire" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Fire Rings</span>
                            </span>
                            Fire Rings
                          </li>
                          <!-- Bar -->
                          <li v-if="formData.bar">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="cocktail" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Bar</span>
                            </span>
                            Bar
                          </li>
                          <!-- Restaurant -->
                          <li v-if="formData.restaurant">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="utensils" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Restaurant</span>
                            </span>
                            Restaurant
                          </li>
                          <!-- 24h Front Desk -->
                          <li v-if="formData.h24_front_desk">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="concierge-bell" class="icon-fa min-width-30" />
                              <span class="tooltiptext">24h Front Desk</span>
                            </span>
                            24h Front Desk
                          </li>
                          <!-- Airport Shuttle -->
                          <li v-if="formData.airport_shuttle">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="plane-arrival" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Airport Shuttle</span>
                            </span>
                            Airport Shuttle
                          </li>
                          <!-- Smoking Rooms -->
                          <li v-if="formData.smoking_rooms">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="smoking" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Smoking Rooms</span>
                            </span>
                            Smoking Rooms
                          </li>
                          <!-- Non Smoking Rooms -->
                          <li v-if="formData.non_smoking_rooms">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="smoking-ban" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Non Smoking Rooms</span>
                            </span>
                            Non Smoking Rooms
                          </li>
                          <!-- Air Conditioning -->
                          <li v-if="formData.air_conditioning">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="temperature-low" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Air Conditioning</span>
                            </span>
                            Air Conditioning
                          </li>
                          <!-- Security Cameras -->
                          <li v-if="formData.security_cameras">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="eye" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Security Cameras</span>
                            </span>
                            Security Cameras
                          </li>
                          <!-- Hammocks -->
                          <li v-if="formData.hammocks">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="feather" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Hammocks</span>
                            </span>
                            Hammocks
                          </li>
                          <!-- Stove -->
                          <li v-if="formData.stove">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="fire" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Stove</span>
                            </span>
                            Stove
                          </li>
                        </ul>
                      </div>
                      <div v-if="conditionsServicesAndAmenities" class="col-6 m-0 ps-1 p-0 border-start-separator">
                        <ul class="list-unstyled icons-listing">
                          <!-- Room Service -->
                          <li v-if="formData.room_service">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="door-open" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Room Service</span>
                            </span>
                            <span v-if="formData.is_let && formData.is_short_term">
                              {{ formData.room_service_paid ? 'Paid: £ ' + convertNumberToCommas(formData.service_fee) : 'Free' }}
                            </span>
                            <span v-else>
                              Yes
                            </span>
                          </li>
                          <!-- Cleaning Service -->
                          <li v-if="formData.cleaning_service">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="hands-wash" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Cleaning Service</span>
                            </span>
                            <span v-if="formData.is_let && formData.is_short_term">
                              {{ formData.cleaning_service_paid ? 'Paid: £ ' + convertNumberToCommas(formData.cleaning_fee) : 'Free' }}
                            </span>
                            <span v-else>
                              Yes
                            </span>
                          </li>
                          <!-- Wi-Fi -->
                          <li v-if="formData.wifi">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="wifi" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Wi-Fi</span>
                            </span>
                            <span v-if="formData.is_let && formData.is_short_term">
                              {{ formData.wifi_paid ? 'Paid' : 'Free' }}
                            </span>
                            <span v-else>
                              Yes
                            </span>
                          </li>
                          <!-- Breakfast -->
                          <li v-if="formData.breakfast">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="bread-slice" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Breakfast</span>
                            </span>
                            <span v-if="formData.is_let && formData.is_short_term">
                              {{ formData.breakfast_paid ? 'Paid' : 'Free' }}
                            </span>
                            <span v-else>
                              Yes
                            </span>
                          </li>
                          <!-- Gym -->
                          <li v-if="formData.gym">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="dumbbell" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Gym</span>
                            </span>
                            <span v-if="formData.is_let && formData.is_short_term">
                              {{ formData.gym_paid ? 'Paid' : 'Free' }}
                            </span>
                            <span v-else>
                              Yes
                            </span>
                          </li>
                          <!-- Cinema Room -->
                          <li v-if="formData.cinema_room">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="film" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Cinema Room</span>
                            </span>
                            <span v-if="formData.is_let && formData.is_short_term">
                              {{ formData.cinema_room_paid ? 'Paid' : 'Free' }}
                            </span>
                            <span v-else>
                              Yes
                            </span>
                          </li>
                          <!-- Sauna -->
                          <li v-if="formData.sauna">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="thermometer-full" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Sauna</span>
                            </span>
                            <span v-if="formData.is_let && formData.is_short_term">
                              {{ formData.sauna_paid ? 'Paid' : 'Free' }}
                            </span>
                            <span v-else>
                              Yes
                            </span>
                          </li>
                          <!-- Hot Tub -->
                          <li v-if="formData.hot_tub">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="hot-tub" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Hot Tub</span>
                            </span>
                            <span v-if="formData.is_let && formData.is_short_term">
                              {{ formData.hot_tub_paid ? 'Paid' : 'Free' }}
                            </span>
                            <span v-else>
                              Yes
                            </span>
                          </li>
                          <!-- Spa -->
                          <li v-if="formData.spa">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="spa" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Spa</span>
                            </span>
                            <span v-if="formData.is_let && formData.is_short_term">
                              {{ formData.spa_paid ? 'Paid' : 'Free' }}
                            </span>
                            <span v-else>
                              Yes
                            </span>
                          </li>
                          <!-- Inside Swimming Pool -->
                          <li v-if="formData.inside_pool">
                            <span data-tooltip="tooltip">
                                <font-awesome-icon icon="swimmer" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Inside Pool</span>
                            </span>
                            <span v-if="formData.is_let && formData.is_short_term">
                              {{ formData.inside_pool_paid ? 'Paid' : 'Free' }}
                            </span>
                            <span v-else>
                              Yes
                            </span>
                          </li>
                          <!-- Outside Swimming Pool -->
                          <li v-if="formData.outside_pool">
                            <span data-tooltip="tooltip">
                                <font-awesome-icon icon="swimming-pool" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Outside Pool</span>
                            </span>
                            <span v-if="formData.is_let && formData.is_short_term">
                              {{ formData.outside_pool_paid ? 'Paid' : 'Free' }}
                            </span>
                            <span v-else>
                              Yes
                            </span>
                          </li>
                          <!-- Bicycle -->
                          <li v-if="formData.bicycle">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="bicycle" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Bicycle</span>
                            </span>
                            <span v-if="formData.is_let && formData.is_short_term">
                              {{ formData.bicycle_paid ? 'Paid' : 'Free' }}
                            </span>
                            <span v-else>
                              Yes
                            </span>
                          </li>
                          <!-- Fire Rings -->
                          <li v-if="formData.fire_rings">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="fire" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Fire Rings</span>
                            </span>
                            <span v-if="formData.is_let && formData.is_short_term">
                              {{ formData.fire_rings_paid ? 'Paid' : 'Free' }}
                            </span>
                            <span v-else>
                              Yes
                            </span>
                          </li>
                          <!-- Bar -->
                          <li v-if="formData.bar">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="cocktail" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Bar</span>
                            </span>
                            Yes
                          </li>
                          <!-- Restaurant -->
                          <li v-if="formData.restaurant">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="utensils" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Restaurant</span>
                            </span>
                            Yes
                          </li>
                          <!-- 24h Front Desk -->
                          <li v-if="formData.h24_front_desk">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="concierge-bell" class="icon-fa min-width-30" />
                              <span class="tooltiptext">24h Front Desk</span>
                            </span>
                            Yes
                          </li>
                          <!-- Airport Shuttle -->
                          <li v-if="formData.airport_shuttle">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="plane-arrival" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Airport Shuttle</span>
                            </span>
                            Yes
                          </li>
                          <!-- Smoking Rooms -->
                          <li v-if="formData.smoking_rooms">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="smoking" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Smoking Rooms</span>
                            </span>
                            Yes
                          </li>
                          <!-- Non Smoking Rooms -->
                          <li v-if="formData.non_smoking_rooms">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="smoking-ban" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Non Smoking Rooms</span>
                            </span>
                            Yes
                          </li>
                          <!-- Air Conditioning -->
                          <li v-if="formData.air_conditioning">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="temperature-low" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Air Conditioning</span>
                            </span>
                            Yes
                          </li>
                          <!-- Security Cameras -->
                          <li v-if="formData.security_cameras">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="eye" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Security Cameras</span>
                            </span>
                            Yes
                          </li>
                          <!-- Hammocks -->
                          <li v-if="formData.hammocks">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="feather" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Hammocks</span>
                            </span>
                            Yes
                          </li>
                          <!-- Stove -->
                          <li v-if="formData.stove">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="fire" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Stove</span>
                            </span>
                            Yes
                          </li>
                        </ul>
                      </div>

                      <div v-if="conditionsPolicies" class="form-group col-12 text-center">
                        <!-- Start Horizontal Line with Icon -->
                        <div class="horizontal-separator-box">
                          <span class="hr-line-short"></span>
                          <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                          <span class="hr-line-short"></span>
                        </div>
                        <!-- End Horizontal Line with Icon -->

                        <!-- Information -->
                        <small class="p-3 fw-bold">Policies</small>

                        <!-- Start Horizontal Line with Icon -->
                        <div class="horizontal-separator-box">
                          <span class="hr-line-short"></span>
                          <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                          <span class="hr-line-short"></span>
                        </div>
                        <!-- End Horizontal Line with Icon -->
                      </div>

                      <div v-if="conditionsPolicies" class="col-6 m-0 p-0">
                        <ul class="list-unstyled icons-listing">
                          <!-- Check-In -->
                          <li v-if="formData.check_in_flexible || formData.check_in_from">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="calendar-check" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Check-in Policy</span>
                            </span>
                            Check-in
                          </li>
                          <!-- Checkout -->
                          <li v-if="formData.check_out_flexible || formData.check_out_from">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="calendar-minus" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Checkout Policy</span>
                            </span>
                            Checkout
                          </li>
                          <!-- Check-in Instructions -->
                          <li v-if="formData.check_in_instructions && formData.check_in_instructions.type">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="hourglass-start" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Check-in Instructions</span>
                            </span>
                            Check-in Instructions
                          </li>
                          <!-- Checkout Instructions -->
                          <li v-if="formData.check_out_instructions && formData.check_out_instructions.type">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="hourglass-end" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Checkout Instructions</span>
                            </span>
                            Checkout Instructions
                          </li>
                          <!-- Free Cancellation Period -->
                          <li v-if="formData.cancellation_period">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="calendar-times" class="icon-fa min-width-30" />
                              <span class="tooltiptext">What is the accepted free cancellation period</span>
                            </span>
                            Free Cancellation Period
                          </li>
                          <!-- Late Cancellation Rule -->
                          <li v-if="formData.cancellation_rule && formData.cancellation_rule.rule">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="ban" class="icon-fa min-width-30" />
                              <span class="tooltiptext">What will happen if you cancel after the 'free cancellation period'</span>
                            </span>
                            Late Cancellation Rule
                          </li>
                        </ul>
                      </div>
                      <div v-if="conditionsPolicies" class="col-6 m-0 ps-1 p-0 border-start-separator">
                        <ul class="list-unstyled icons-listing">
                          <!-- Check-In -->
                          <li v-if="formData.check_in_flexible || formData.check_in_from">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="calendar-check" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Check-in Policy</span>
                            </span>
                            <span v-if="formData.check_in_flexible">
                              Flexible
                            </span>
                            <span v-else-if="formData.check_in_from">
                              {{ this.moment.utc(formData.check_in_from).format('HH:mm') }} {{ formData.check_in_to ? ' - ' + this.moment.utc(formData.check_in_to).format('HH:mm') : '' }}
                            </span>
                          </li>
                          <!-- Checkout -->
                          <li v-if="formData.check_out_flexible || formData.check_out_from">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="calendar-minus" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Checkout Policy</span>
                            </span>
                            <span v-if="formData.check_out_flexible">
                              Flexible
                            </span>
                            <span v-else-if="formData.check_out_from">
                              {{ this.moment.utc(formData.check_out_from).format('HH:mm') }} {{ formData.check_out_to ? ' - ' + this.moment.utc(formData.check_out_to).format('HH:mm') : '' }}
                            </span>
                          </li>
                          <!-- Check-in Instructions -->
                          <li v-if="formData.check_in_instructions && formData.check_in_instructions.type">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="hourglass-start" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Check-in Instructions</span>
                            </span>
                            {{ formData.check_in_instructions && formData.check_in_instructions.type ? formData.check_in_instructions.type : '' }}
                          </li>
                          <!-- Checkout Instructions -->
                          <li v-if="formData.check_out_instructions && formData.check_out_instructions.type">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="hourglass-end" class="icon-fa min-width-30" />
                              <span class="tooltiptext">Checkout Instructions</span>
                            </span>
                            {{ formData.check_out_instructions && formData.check_out_instructions.type ? formData.check_out_instructions.type : '' }}
                          </li>
                          <!-- Free Cancellation Period -->
                          <li v-if="formData.cancellation_period">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="calendar-times" class="icon-fa min-width-30" />
                              <span class="tooltiptext">What is the accepted free cancellation period</span>
                            </span>
                            {{ formData.cancellation_period ? formData.cancellation_period : '' }}
                          </li>
                          <!-- Late Cancellation Rule -->
                          <li v-if="formData.cancellation_rule && formData.cancellation_rule.rule">
                            <span data-tooltip="tooltip">
                              <font-awesome-icon icon="ban" class="icon-fa min-width-30" />
                              <span class="tooltiptext">What will happen if you cancel after the 'free cancellation period'</span>
                            </span>
                            {{ formData.cancellation_rule && formData.cancellation_rule.rule ? formData.cancellation_rule.rule : '' }}{{ (formData.cancellation_rule && formData.cancellation_rule.rule && formData.cancellation_rule.rule == 'Fixed Fee Charged') ? ': £ ' + formData.cancellation_fee : '' }}
                          </li>
                        </ul>
                      </div>

                      <div v-if="conditionsDiscounts" class="form-group col-12 text-center">
                        <!-- Start Horizontal Line with Icon -->
                        <div class="horizontal-separator-box">
                          <span class="hr-line-short"></span>
                          <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                          <span class="hr-line-short"></span>
                        </div>
                        <!-- End Horizontal Line with Icon -->

                        <!-- Information -->
                        <small class="p-3 fw-bold">Discounts</small>

                        <!-- Start Horizontal Line with Icon -->
                        <div class="horizontal-separator-box">
                          <span class="hr-line-short"></span>
                          <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                          <span class="hr-line-short"></span>
                        </div>
                        <!-- End Horizontal Line with Icon -->
                      </div>

                      <div v-if="conditionsDiscounts" class="col-6 m-0 p-0">
                        <ul class="list-unstyled icons-listing">
                          <!-- Discounts -->
                          <li v-if="formData.is_discount_term_term">
                            <!-- Term Discount -->
                            <div>
                              <span data-tooltip="tooltip">
                                <font-awesome-icon icon="tag" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Term Discount</span>
                              </span>
                              <span>
                                Term Discount
                              </span>
                            </div>
                          </li>
                          <li v-if="formData.is_discount_term_last_minute">
                            <!-- Last-minute Discount -->
                            <div>
                              <span data-tooltip="tooltip">
                                <font-awesome-icon icon="clock" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Last-minute Discount</span>
                              </span>
                              <span>
                                Last-minute Discount
                              </span>
                            </div>
                          </li>
                          <li v-if="formData.is_discount_term_no_of_guests">
                            <!-- Guest Number Discount -->
                            <div>
                              <span data-tooltip="tooltip">
                                <font-awesome-icon icon="user-friends" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Guest Number Discount</span>
                              </span>
                              <span>
                                Guest Number Discount
                              </span>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div v-if="conditionsDiscounts" class="col-6 m-0 ps-1 p-0 border-start-separator">
                        <ul class="list-unstyled icons-listing">
                          <!-- Discounts -->
                          <li v-if="formData.is_discount_term_term">
                            <!-- Term Discount -->
                            <div>
                              <span data-tooltip="tooltip">
                                <font-awesome-icon icon="tag" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Term Discount</span>
                              </span>
                              <span>
                                {{ formData.discount_term_term && formData.discount_term_term == '1' ? formData.discount_term_term + ' day' : formData.discount_term_term + ' days' }} {{ formData.discount_fee_type_term && formData.discount_fee_type_term == 1 ? formData.discount_fee_term + '% off' : '£' + formData.discount_fee_term + ' off'}}
                              </span>
                            </div>
                          </li>
                          <li v-if="formData.is_discount_term_last_minute">
                            <!-- Last-minute Discount -->
                            <div>
                              <span data-tooltip="tooltip">
                                <font-awesome-icon icon="clock" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Last-minute Discount</span>
                              </span>
                              <span>
                                {{ formData.discount_term_last_minute && formData.discount_term_last_minute == '1' ? formData.discount_term_last_minute + ' day' : formData.discount_term_last_minute + ' days' }} {{ formData.discount_fee_type_last_minute && formData.discount_fee_type_last_minute == 1 ? formData.discount_fee_last_minute + '% off' : '£' + formData.discount_fee_last_minute + ' off'}}
                              </span>
                            </div>
                          </li>
                          <li v-if="formData.is_discount_term_no_of_guests">
                            <!-- Guest Number Discount -->
                            <div>
                              <span data-tooltip="tooltip">
                                <font-awesome-icon icon="user-friends" class="icon-fa min-width-30" />
                                <span class="tooltiptext">Guest Number Discount</span>
                              </span>
                              <span>
                                {{ formData.discount_term_no_of_guests ? formData.discount_term_no_of_guests + '+ guests' : '' }} {{ formData.discount_fee_type_no_of_guests && formData.discount_fee_type_no_of_guests == 1 ? formData.discount_fee_no_of_guests + '% off' : '£' + formData.discount_fee_no_of_guests + ' off'}}
                              </span>
                            </div>
                          </li>
                        </ul>
                      </div>

                      <div v-if="conditionsHouseRules" class="form-group col-12 text-center">
                        <!-- Start Horizontal Line with Icon -->
                        <div class="horizontal-separator-box">
                          <span class="hr-line-short"></span>
                          <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                          <span class="hr-line-short"></span>
                        </div>
                        <!-- End Horizontal Line with Icon -->

                        <!-- Information -->
                        <small class="p-3 fw-bold">House Rules</small>

                        <!-- Start Horizontal Line with Icon -->
                        <div class="horizontal-separator-box">
                          <span class="hr-line-short"></span>
                          <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                          <span class="hr-line-short"></span>
                        </div>
                        <!-- End Horizontal Line with Icon -->
                      </div>

                      <div v-if="conditionsHouseRules" class="col-12 m-0 p-0">
                        <ul class="list-unstyled">
                          <li class="rule-list mt-1" v-for="rule in formData.rules">
                            {{ rule.rule }}
                          </li>
                        </ul>
                      </div>

                    <!-- Start Horizontal Line with Icon -->
                    <div class="horizontal-separator-box">
                      <span class="hr-line-short"></span>
                      <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                      <span class="hr-line-short"></span>
                    </div>
                    <!-- End Horizontal Line with Icon -->

                    <!-- Property Description Header -->
                    <div class="row text-center">
                      <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <p class="fw-bold m-0 p-0">Description</p>
                      </div>
                    </div>

                    <!-- Start Horizontal Line with Icon -->
                    <div class="horizontal-separator-box">
                      <span class="hr-line-short"></span>
                      <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                      <span class="hr-line-short"></span>
                    </div>
                    <!-- End Horizontal Line with Icon -->

                    <!-- Property Description Text -->
                    <div class="row text-start">
                      <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div id="propDescription" class="m-0 p-0"></div>
                      </div>
                    </div>

                  </div>
                </div>

                  <div v-if="!formData.is_rent && !formData.is_buy" class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-6">
                    <div class="row m-0 p-0">
                      <div class="col-12">
                        <!-- FLOORPLANS -->
                        <div id="carouselPreviewFloorplans" class="carousel slide" data-bs-ride="carousel" data-bs-interval="false">

                          <div class="carousel-inner" id="floorplansItems">
                            <div v-if="formData.previewFloorplanImages.length === 0">
                              <img :src="require(`@/assets/no-image-found.png`)"
                                   class="d-block w-100 img-fluid thumbnails-floorplan-height"
                                   alt="..."
                              >
                            </div>
                            <div v-for="(doc, index) in formData.previewFloorplanImages"
                                 :key="index"
                                 :class="index === 0 ? 'active carousel-item' : 'carousel-item'"
                                 :id="'carousel-inner-floorplan-'+index"
                            >
                              <img v-if="doc" :src="doc" class="d-block w-100 img-fluid thumbnails-floorplan-height" alt="..." />
                              <!--                    <div class="carousel-caption d-none d-md-block">-->
                              <!--                      <h5>{{ index }} slide</h5>-->
                              <!--                      <p>Some representative placeholder content for the {{ index }} slide.</p>-->
                              <!--                    </div>-->
                            </div>
                          </div>

                          <button class="carousel-control-prev" type="button" :data-bs-target="'#carouselPreviewFloorplans'" data-bs-slide="prev"> <!-- v-on:click="setActiveFloorplan(activeCarouselFloorplansIndex-1)" -->
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Previous</span>
                          </button>
                          <button class="carousel-control-next" type="button" :data-bs-target="'#carouselPreviewFloorplans'" data-bs-slide="next"> <!-- v-on:click="setActiveFloorplan(activeCarouselFloorplansIndex+1)" -->
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Next</span>
                          </button>

                        </div>
                      </div>

<!--                      <div class="col-12 mt-2">-->
<!--                        &lt;!&ndash; Start AdSense &ndash;&gt;-->
<!--                        <AdSense></AdSense>-->
<!--                        <AdSense></AdSense>-->
<!--                        <AdSense></AdSense>-->
<!--                        <AdSense></AdSense>-->
<!--                        <AdSense></AdSense>-->
<!--                        <AdSense></AdSense>-->
<!--                        &lt;!&ndash; End AdSense &ndash;&gt;-->
<!--                      </div>-->

                    </div>
                  </div>

                </div>

              </div>

<!--              &lt;!&ndash; Start Horizontal Line with Icon &ndash;&gt;-->
<!--              <div class="horizontal-separator-box mt-4 mb-4">-->
<!--                <span class="hr-line"></span>-->
<!--                <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>-->
<!--                <span class="hr-line"></span>-->
<!--              </div>-->
<!--              &lt;!&ndash; End Horizontal Line with Icon &ndash;&gt;-->

<!--              &lt;!&ndash; Property Info Details &ndash;&gt;-->
<!--              <div class="row text-start">-->
<!--                <div class="col-12 col-xs-6 col-sm-6 col-md-6 col-lg-6">-->
<!--                  <ul class="list-unstyled">-->
<!--                    <li>-->
<!--                      <p class="fw-bold m-0 p-0">Description</p>-->
<!--                    </li>-->
<!--                    <li>-->
<!--                      <p class="m-0 p-0">{{ formData.property_description }}</p>-->
<!--                    </li>-->
<!--                  </ul>-->
<!--                </div>-->
<!--                <div class="col-12 col-xs-6 col-sm-6 col-md-6 col-lg-6">-->
<!--                  <div class="row m-0 p-0">-->

<!--                    <div class="col-12 mt-2">-->
<!--                      &lt;!&ndash; Start AdSense &ndash;&gt;-->
<!--                      <AdSense></AdSense>-->
<!--                      <AdSense></AdSense>-->
<!--                      <AdSense></AdSense>-->
<!--                      &lt;!&ndash; End AdSense &ndash;&gt;-->
<!--                    </div>-->

<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->

            </div>
          </div>
        </div>
        <!-- End Loop properties -->
        <a href="javascript:void(0)" id="propDetailsModalCloseButton" class="closebtn" v-on:click="">
          <font-awesome-icon icon="angle-double-left" id="adDetailsChevronCloseBtn" class="m-0 p-0" />
<!--          <span data-tooltip="tooltip">-->
<!--            <font-awesome-icon icon="chevron-circle-left" id="adDetailsChevronCloseBtn"/>-->
<!--            <span class="tooltiptext">Close</span>-->
<!--          </span>-->
        </a>
      </div>

    </div>

  </div>
</template>

<script>
import axios from "axios";
import ChatModal from "./ChatModal";
import AdSense from "./AdSense";
import ShowReviewRating from "./Reviews/ShowReviewRating";
import Share from "./ShareSocials/Share";
import AddBookmark from "./AddBookmark";
import MiniMap from "./MiniMap";

export default {
  name: 'PreviewAdDetails',
  props: {
    id: Number,
    formData: Object,
    postType: Number
  },
  components: {
    'ChatModal': ChatModal,
    'AdSense': AdSense,
    'ShowReviewRating': ShowReviewRating,
    'Share': Share,
    'AddBookmark': AddBookmark,
    'MiniMap': MiniMap
  },
  data(){
    return {
      user: this.$store.state.auth.user,
      // showMobile: false,
      // showLanding: false,
      // showEmail: false,
      // showCompanyName: false,
      // showAddress: false,
      // formData: null,
      activeCarouselIndex: 0,
      activeCarouselFloorplansIndex: 0,
      // formData: {},
      // postType: ''
      processing: false
    }
  },
  mounted: function() {
    // this.getUserSettings();
  },
  computed: {
    conditionsHouseRules() {
      return (this.formData.rules && this.formData.rules.length > 0);
    },
    conditionsDiscounts() {
      return this.formData.is_discount_term_term ||
          this.formData.is_discount_term_last_minute ||
          this.formData.is_discount_term_no_of_guests
    },
    conditionsPolicies() {
      return this.formData.check_in_flexible ||
          this.formData.check_out_flexible ||
          this.formData.check_in_from ||
          this.formData.check_in_to ||
          this.formData.check_out_from ||
          this.formData.check_out_to ||
          this.formData.cancellation_period ||
          this.formData.cancellation_late_rule_id

    },
    conditionsSuiteApartment() {
      return this.formData.single_room_suite || this.formData.double_room_suite;
    },
    conditionsMainFeatures() {
      return this.formData.single_room ||
          this.formData.double_room ||
          this.formData.studio_room ||
          this.formData.living_room ||
          this.formData.dining_room ||
          this.formData.box_room
    },
    conditionsStructural() {
      return this.formData.front_garden ||
          this.formData.rear_garden ||
          this.formData.garden_terrace ||
          this.formData.conservatory ||
          this.formData.green_house ||
          this.formData.shed ||
          this.formData.driveway ||
          this.formData.garage ||
          this.formData.parking ||
          this.formData.front_porch ||
          this.formData.rear_porch ||
          this.formData.floors ||
          this.formData.attic ||
          this.formData.basement ||
          this.formData.balcony ||
          this.formData.charging_station ||
          this.formData.solar_panels
    },
    conditionsStatus() {
      return this.formData.is_mixed_use ||
          this.formData.is_auction ||
          this.formData.is_cash ||
          this.formData.is_investment
    },
    conditionsAdditionals() {
      return this.formData.is_accessible ||
          this.formData.events_allowed ||
          this.formData.is_shared_ownership ||
          this.formData.is_over_60s ||
          this.formData.is_hmo ||
          this.formData.is_furnished ||
          this.formData.is_tenanted ||
          this.formData.is_pet_friendly ||
          this.formData.is_dss_accepted ||
          this.formData.easements ||
          this.formData.is_holiday ||
          this.formData.is_seashore ||
          this.formData.is_waterfront
    },
    conditionsServicesAndAmenities() {
      return this.formData.gym ||
          this.formData.cinema_room ||
          this.formData.sauna ||
          this.formData.hot_tub ||
          this.formData.inside_pool ||
          this.formData.outside_pool ||
          this.formData.wifi ||
          this.formData.spa ||
          this.formData.bicycle ||
          this.formData.fire_rings ||
          this.formData.bar ||
          this.formData.smoking_rooms ||
          this.formData.non_smoking_rooms ||
          this.formData.air_conditioning ||
          this.formData.hammocks ||
          this.formData.security_cameras ||
          this.formData.room_service ||
          this.formData.cleaning_service ||
          this.formData.breakfast ||
          this.formData.restaurant ||
          this.formData.h24_front_desk ||
          this.formData.airport_shuttle
    },
    conditionsBathroom() {
      return this.formData.bath_room ||
          this.formData.wc ||
          this.formData.downstairs_wc ||
          this.formData.shower ||
          this.formData.bath ||
          this.formData.sink
    },
    conditionsKitchen() {
      return this.formData.kitchen ||
          this.formData.oven ||
          this.formData.fridge ||
          this.formData.freezer ||
          this.formData.microwave ||
          this.formData.kettle ||
          this.formData.toaster ||
          this.formData.coffee
    },
    conditionsGarden() {
      return this.calculateGarden(this.formData.front_garden, this.formData.rear_garden) ||
          this.formData.garden_terrace ||
          this.formData.conservatory ||
          this.formData.green_house ||
          this.formData.shed
    }
  },
  methods: {
    // // I consider this temporary solution - later would be more beneficial to move user settings into STATE (Vuex)
    // // So it can be accessed globally, rather than in the way below
    // getUserSettings() {
    //   for (let i = 0; i < this.user.settings.length; i++) {
    //     // 1 = show company name
    //     if (this.user.settings[i].setting_type_id === 1 && this.user.settings[i].setting_value === '1') {
    //       this.showCompanyName = true;
    //     }
    //     // 2 = show address
    //     if (this.user.settings[i].setting_type_id === 2 && this.user.settings[i].setting_value === '1') {
    //       this.showAddress = true;
    //     }
    //     // 3 = show mobile
    //     if (this.user.settings[i].setting_type_id === 3 && this.user.settings[i].setting_value === '1') {
    //       this.showMobile = true;
    //     }
    //     // 4 = show landing
    //     if (this.user.settings[i].setting_type_id === 4 && this.user.settings[i].setting_value === '1') {
    //       this.showLanding = true;
    //     }
    //     // 5 = show email
    //     if (this.user.settings[i].setting_type_id === 5 && this.user.settings[i].setting_value === '1') {
    //       this.showEmail = true;
    //     }
    //   }
    // },
    // Helpers for LET ads to reflect short-term and long-term lets data
    preventParentClick() {
      // Do nothing, its just used to prevent parent function trigger (setActivePropertyDetailsThumbnail)
    },
    checkAdIsLetShortTermOrRoom(prop) {
      if (prop && prop.is_let && ((prop.is_short_term && !prop.studio_room) || prop.is_room)) {
        return true;
      } else {
        return false;
      }
    },
    checkAdIsLetLongTermOrStudio(prop) {
      if (prop && prop.is_let && ((!prop.is_short_term && !prop.is_room) || prop.studio_room)) {
        return true;
      } else {
        return false;
      }
    },
    showPriceRange(prop) {
      if (this.checkAdIsLetShortTermOrRoom(prop)) {
        return true;
      } else {
        return false;
      }
    },
    showHourPriceRange(prop) {
      if (prop.rooms && prop.rooms.length > 0) {
        // get hourly prices
        let hourlyPrices = prop.rooms.filter(obj => {
          if (obj.property_rent_type_id !== 2) {
            return false;
          } else {
            return true;
          }
        }).map(object => {
          return object.price;
        });

        if (hourlyPrices.length > 0) {
          return true;
        } else {
          return false;
        }
      }
    },
    calculateHourPriceRange(prop) {
      if (prop.rooms && prop.rooms.length > 0) {
        let maxHourlyPrice = null;
        let minHourlyPrice = null;

        // get hourly prices
        let hourlyPrices = prop.rooms.filter(obj => {
          if (obj.property_rent_type_id !== 2) {
            return false;
          } else {
            return true;
          }
        }).map(object => {
          return object.price;
        });

        if (hourlyPrices.length > 0) {
          maxHourlyPrice = Math.max.apply(null, hourlyPrices);
          minHourlyPrice = Math.min.apply(null, hourlyPrices);
        }

        if (minHourlyPrice && maxHourlyPrice) {
          if (minHourlyPrice == maxHourlyPrice) {
            return this.convertNumberToCommas(minHourlyPrice);
          } else {
            return this.convertNumberToCommas(minHourlyPrice) + ' - ' + this.convertNumberToCommas(maxHourlyPrice);
          }
        }
      }
    },
    showNightPriceRange(prop) {
      if (prop.rooms && prop.rooms.length > 0) {
        // get nightly prices
        let nightlyPrices = prop.rooms.filter(obj => {
          if (obj.property_rent_type_id !== 3) {
            return false;
          } else {
            return true;
          }
        }).map(object => {
          return object.price;
        });

        if (nightlyPrices.length > 0) {
          return true;
        } else {
          return false;
        }
      }
    },
    calculateNightPriceRange(prop) {
      if (prop.rooms && prop.rooms.length > 0) {
        let maxNightlyPrice = null;
        let minNightlyPrice = null;

        // get nightly prices
        let nightlyPrices = prop.rooms.filter(obj => {
          if (obj.property_rent_type_id !== 3) {
            return false;
          } else {
            return true;
          }
        }).map(object => {
          return object.price;
        });

        if (nightlyPrices.length > 0) {
          maxNightlyPrice = Math.max.apply(null, nightlyPrices);
          minNightlyPrice = Math.min.apply(null, nightlyPrices);
        }

        if (minNightlyPrice && maxNightlyPrice) {
          if (minNightlyPrice == maxNightlyPrice) {
            return this.convertNumberToCommas(minNightlyPrice);
          } else {
            return this.convertNumberToCommas(minNightlyPrice) + ' - ' + this.convertNumberToCommas(maxNightlyPrice);
          }
        }
      }
    },
    showWeekPriceRange(prop) {
      if (prop.rooms && prop.rooms.length > 0) {
        // get weekly prices
        let weeklyPrices = prop.rooms.filter(obj => {
          if (obj.property_rent_type_id !== 4) {
            return false;
          } else {
            return true;
          }
        }).map(object => {
          return object.price;
        });

        if (weeklyPrices.length > 0) {
          return true;
        } else {
          return false;
        }
      }
    },
    calculateWeekPriceRange(prop) {
      if (prop.rooms && prop.rooms.length > 0) {
        let maxWeeklyPrice = null;
        let minWeeklyPrice = null;

        // get weekly prices
        let weeklyPrices = prop.rooms.filter(obj => {
          if (obj.property_rent_type_id !== 4) {
            return false;
          } else {
            return true;
          }
        }).map(object => {
          return object.price;
        });

        if (weeklyPrices.length > 0) {
          maxWeeklyPrice = Math.max.apply(null, weeklyPrices);
          minWeeklyPrice = Math.min.apply(null, weeklyPrices);
        }

        if (minWeeklyPrice && maxWeeklyPrice) {
          if (minWeeklyPrice == maxWeeklyPrice) {
            return this.convertNumberToCommas(minWeeklyPrice);
          } else {
            return this.convertNumberToCommas(minWeeklyPrice) + ' - ' + this.convertNumberToCommas(maxWeeklyPrice);
          }
        }
      }
    },
    showMonthPriceRange(prop) {
      if (prop.rooms && prop.rooms.length > 0) {
        // get monthly prices
        let monthlyPrices = prop.rooms.filter(obj => {
          if (obj.property_rent_type_id !== 5) {
            return false;
          } else {
            return true;
          }
        }).map(object => {
          return object.price;
        });

        if (monthlyPrices.length > 0) {
          return true;
        } else {
          return false;
        }
      }
    },
    calculateMonthPriceRange(prop) {
      if (prop.rooms && prop.rooms.length > 0) {
        let maxMonthlyPrice = null;
        let minMonthlyPrice = null;

        // get monthly prices
        let monthlyPrices = prop.rooms.filter(obj => {
          if (obj.property_rent_type_id !== 5) {
            return false;
          } else {
            return true;
          }
        }).map(object => {
          return object.price;
        });

        if (monthlyPrices.length > 0) {
          maxMonthlyPrice = Math.max.apply(null, monthlyPrices);
          minMonthlyPrice = Math.min.apply(null, monthlyPrices);
        }

        if (minMonthlyPrice && maxMonthlyPrice) {
          if (minMonthlyPrice == maxMonthlyPrice) {
            return this.convertNumberToCommas(minMonthlyPrice);
          } else {
            return this.convertNumberToCommas(minMonthlyPrice) + ' - ' + this.convertNumberToCommas(maxMonthlyPrice);
          }
        }
      }
    },
    // End Helpers for LET ads to reflect short-term and long-term lets data
    getPropertyDetails(id) {
      axios.get('api/properties/' + id).then(({data})=>{
        this.formData = data.data;
      }).catch(({response})=>{
        alert(response.data.message)
      }).finally(()=> {
        this.processing = false
      })
    },
    // closeParentPropDetailsModal() {
    //   this.$emit('closePropDetailsModal');
    // },
    parseHTML(html, elementId) {
      let element = document.getElementById(elementId);
      element.innerHTML = '';
      element.insertAdjacentHTML('beforeend', html);
    },
    calculateMobile() {
      if(this.formData.mobile_prefix != null && this.formData.mobile_prefix !== '') {
        return this.formData.mobile_prefix + ' ' + this.formData.mobile;
      } else {
        return this.formData.mobile;
      }
    },
    calculateLanding() {
      if(this.formData.landing_prefix != null && this.formData.landing_prefix !== '') {
        return this.formData.landing_prefix + ' ' + this.formData.landing;
      } else {
        return this.formData.landing;
      }
    },
    calculateBeds(propDetails) {
      return Number(propDetails.single_room) + Number(propDetails.double_room) + Number(propDetails.twin_room) + Number(propDetails.twin_double_room) +
          Number(propDetails.triple_room) + Number(propDetails.quadruple_room) + Number(propDetails.family_room) + Number(propDetails.studio_room);
    },
    calculateSuiteBeds(propDetails) {
      return Number(propDetails.single_room_suite) + Number(propDetails.double_room_suite);
    },
    calculateParking(driveway = 0, garage = 0, parking = 0, parkingSpaces = 0) {
      if (parking && parkingSpaces > 0) {
        return Number(driveway) + Number(garage) + Number(parkingSpaces);
      } else {
        return Number(driveway) + Number(garage) + Number(parking);
      }
    },
    calculateGarden(frontGarden, rearGarden) {
      return Number(frontGarden) + Number(rearGarden);
    },
    calculateCreatedDate(date) {
      let resultDate = null;

      let currentDate = new Date();
      let propDate = new Date(date);
      let numberOfDays = (currentDate.getTime() - propDate.getTime()) / (1000*60*60*24);

      switch (true) {
        case numberOfDays <= 1:
          resultDate = "Today";
          break;
        case (numberOfDays <= 30 && numberOfDays > 1):
          if(numberOfDays < 2) {
            resultDate = Math.round(numberOfDays)+" day ago";
          } else {
            resultDate = Math.round(numberOfDays)+" days ago";
          }
          break;
        case numberOfDays > 30:
          let numberOfMonths = Math.round(numberOfDays / 30);
          if(numberOfMonths === 1) {
            resultDate = numberOfMonths+" month ago";
          } else {
            resultDate = numberOfMonths+" months ago"
          }
          break;
      }

      return resultDate;

    },
    setUserDefaultImg(e) {
      e.target.src = require(`@/assets/no-user-image.png`);
    },
    // getImage(file_name) {
    //   try {
    //     return require(`@/assets/${file_name}`);
    //   } catch (e) {
    //     return false
    //   }
    // },
    getPropRentType(prop_rent_type_id) {
      switch(prop_rent_type_id) {
        case 2:
          return 'per hour';
          break;
        case 3:
          return 'per night';
          break;
        case 4:
          return 'per week';
          break;
        case 5:
          return 'per month';
          break;
        case 6:
          return 'per TBD';
          break;
      }
    },
    convertNumberToCommas(num) {
      if (num && num !== '' && num !== null) {
        return num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return 0;
      }
    },
    scrollDetailsIntoView() {
      /* Use of double !! does not return dom element, just bool true/false */
      let doesElExist = !!document.getElementById("scrollIntoViewPreview");
      if(doesElExist) {
        document.getElementById("scrollIntoViewPreview").scrollIntoView({behavior: 'smooth', block: 'start'});
      }
    },
    /* I might not need that anymore, up there removed its trigger on prev and next slides */
    setActive(index) {
      /*
        As slides are counted from 0 to x slides, negative values would mean we want to
        end up on LAST possible slide, hence if index < 0 we count number of child elements (slides)
        and assign last number -1 (elements count might be 1-2-3, but slides counts from 0-1-2)

        This solution works, nevertheless FAST clicking on slide change interrupts it, I might need
        to set it as async and promise
      */
      let countChildren = document.getElementById('adDetailItems').childElementCount;

      if(countChildren) {
        switch (index) {
          case index < 0:
            // console.log('index < 0');
            // console.log('Index: ' + index);
            // console.log('Count children: ' + countChildren);
            this.activeCarouselIndex = countChildren - 1;
            // console.log(this.activeCarouselIndex);
            break;
          case (index >= 0 && index <= (countChildren - 1)):
            // console.log('index >= 0');
            // console.log('Index: ' + index);
            this.activeCarouselIndex = index;
            break;
          case index > (countChildren - 1):
            // console.log('index = '+ (countChildren));
            // console.log('Index: ' + index);
            this.activeCarouselIndex = 0;
            break;
        }
      }
    },
    /* I might not need that anymore, up there removed its trigger on prev and next slides */
    setActiveFloorplan(index) {
      /*
        As slides are counted from 0 to x slides, negative values would mean we want to
        end up on LAST possible slide, hence if index < 0 we count number of child elements (slides)
        and assign last number -1 (elements count might be 1-2-3, but slides counts from 0-1-2)

        This solution works, nevertheless FAST clicking on slide change interrupts it, I might need
        to set it as async and promise
      */
      let countFloorplansChildren = document.getElementById('floorplansItems').childElementCount;

      if(countFloorplansChildren) {
        switch (index) {
          case index < 0:
            // console.log('index < 0');
            // console.log('Index: ' + index);
            // console.log('Count children: ' + countChildren);
            this.activeCarouselFloorplansIndex = countFloorplansChildren - 1;
            // console.log(this.activeCarouselIndex);
            break;
          case (index >= 0 && index <= (countFloorplansChildren - 1)):
            // console.log('index >= 0');
            // console.log('Index: ' + index);
            this.activeCarouselFloorplansIndex = index;
            break;
          case index > (countFloorplansChildren - 1):
            // console.log('index = '+ (countChildren));
            // console.log('Index: ' + index);
            this.activeCarouselFloorplansIndex = 0;
            break;
        }
      }
    },
  },
  updated() {
    this.$nextTick(function () {
      // Code that will run only after the entire view has been re-rendered

      // /*
      //     AdDetail Slides carousel
      //     Cheating a bit, as v-for bootstrap 5 carousel seems to not be as reactive as I would like it to be.
      //     It does not reset v-for INDEX on pure carousel data change, so I had to use below functionality,
      //     so that I can remove all current 'active' class and set activeCarouselIndex back to 0 (start point)
      //    */
      // let carouselItems = document.getElementById('adDetailItems');
      // let countChildren = null;
      //
      // if(carouselItems != null) {
      //   countChildren = document.getElementById('adDetailItems').childElementCount;
      // }
      // if(carouselItems != null && countChildren > 0) {
      //   let i = 0;
      //   for(i; i<countChildren; i++) {
      //     let carouselElement = document.getElementById('carousel-inner-'+i);
      //     if(carouselElement) {
      //       if (document.getElementById('carousel-inner-' + i).classList.contains('active')) {
      //         document.getElementById('carousel-inner-' + i).classList.remove('active');
      //       }
      //     }
      //   }
      //
      //   let carouselFirstElement = document.getElementById('carousel-inner-0');
      //   if(carouselFirstElement) {
      //     if (!document.getElementById('carousel-inner-0').classList.contains('active')) {
      //       document.getElementById('carousel-inner-0').classList.add('active');
      //     }
      //   }
      // }
      //
      // this.activeCarouselIndex = 0;
      //
      // /* Floorplan carousel */
      // let carouselFloorplanItems = document.getElementById('floorplansItems');
      // let countFloorplanChildren = null;
      //
      // if(carouselFloorplanItems != null) {
      //   countFloorplanChildren = document.getElementById('floorplansItems').childElementCount;
      // }
      // if(carouselFloorplanItems != null && countFloorplanChildren > 0) {
      //   let i = 0;
      //   for(i; i<countFloorplanChildren; i++) {
      //     let carouselFloorplanElement = document.getElementById('carousel-inner-floorplan-'+i);
      //     if(carouselFloorplanElement) {
      //       if (document.getElementById('carousel-inner-floorplan-' + i).classList.contains('active')) {
      //         document.getElementById('carousel-inner-floorplan-' + i).classList.remove('active');
      //       }
      //     }
      //   }
      //
      //   let carouselFloorplanElement = document.getElementById('carousel-inner-floorplan-0');
      //   if(carouselFloorplanElement) {
      //     if (!document.getElementById('carousel-inner-floorplan-0').classList.contains('active')) {
      //       document.getElementById('carousel-inner-floorplan-0').classList.add('active');
      //     }
      //   }
      // }
      //
      // this.activeCarouselFloorplansIndex = 0;
    })
  },
  watch: {
    'formData.property_description': function(val) {
      // Set Description Html Field
      this.parseHTML(this.formData.property_description, "propDescription");
    }
  }
  // watch: {
  //   "id": function (id) {
  //     if(id !== null) {
  //       this.getPropertyDetails(this.$props.id);
  //
  //       /*
  //         AdDetail Slides carousel
  //         Cheating a bit, as v-for bootstrap 5 carousel seems to not be as reactive as I would like it to be.
  //         It does not reset v-for INDEX on pure carousel data change, so I had to use below functionality,
  //         so that I can remove all current 'active' class and set activeCarouselIndex back to 0 (start point)
  //        */
  //       let carouselItems = document.getElementById('adDetailItems');
  //       let countChildren = null;
  //
  //       if(carouselItems != null) {
  //         countChildren = document.getElementById('adDetailItems').childElementCount;
  //       }
  //
  //       let carouselThumbnails = document.getElementById('carouselThumbnailsPreviewPropDetails');
  //       let countCarouselThumbnailsChildren = null;
  //
  //       if(carouselThumbnails != null) {
  //         countCarouselThumbnailsChildren = document.getElementById('carouselThumbnailsPreviewPropDetails').childElementCount;
  //       }
  //
  //       if(carouselItems != null && countChildren > 0) {
  //         let i = 0;
  //         for(i; i<countChildren; i++) {
  //           let carouselElement = document.getElementById('carousel-inner-'+i);
  //           let carouselThumbElement = document.getElementById('carousel-thumb-'+i);
  //           if(carouselElement && carouselThumbElement) {
  //             if (document.getElementById('carousel-inner-' + i).classList.contains('active')) {
  //               document.getElementById('carousel-inner-' + i).classList.remove('active');
  //             }
  //             if (document.getElementById('carousel-thumb-' + i).classList.contains('active')) {
  //               document.getElementById('carousel-thumb-' + i).classList.remove('active');
  //             }
  //           }
  //         }
  //
  //         let carouselFirstElement = document.getElementById('carousel-inner-0');
  //         let carouselThumbFirstElement = document.getElementById('carousel-thumb-0');
  //         if(carouselFirstElement && carouselThumbFirstElement) {
  //           if (!document.getElementById('carousel-inner-0').classList.contains('active')) {
  //             document.getElementById('carousel-inner-0').classList.add('active');
  //           }
  //           if (!document.getElementById('carousel-thumb-0').classList.contains('active')) {
  //             document.getElementById('carousel-thumb-0').classList.add('active');
  //           }
  //         }
  //       }
  //
  //       // this.activeCarouselIndex = 0;
  //
  //       /* Floorplan carousel */
  //       let carouselFloorplanItems = document.getElementById('floorplansItems');
  //       let countFloorplanChildren = null;
  //
  //       if(carouselFloorplanItems != null) {
  //         countFloorplanChildren = document.getElementById('floorplansItems').childElementCount;
  //       }
  //       if(carouselFloorplanItems != null && countFloorplanChildren > 0) {
  //         let i = 0;
  //         for(i; i<countFloorplanChildren; i++) {
  //           let carouselFloorplanElement = document.getElementById('carousel-inner-floorplan-'+i);
  //           if(carouselFloorplanElement) {
  //             if (document.getElementById('carousel-inner-floorplan-' + i).classList.contains('active')) {
  //               document.getElementById('carousel-inner-floorplan-' + i).classList.remove('active');
  //             }
  //           }
  //         }
  //
  //         let carouselFloorplanElement = document.getElementById('carousel-inner-floorplan-0');
  //         if(carouselFloorplanElement) {
  //           if (!document.getElementById('carousel-inner-floorplan-0').classList.contains('active')) {
  //             document.getElementById('carousel-inner-floorplan-0').classList.add('active');
  //           }
  //         }
  //       }
  //
  //       // this.activeCarouselFloorplansIndex = 0;
  //     }
  //   }
  // }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

/* Start Review Icons */
.review-icons-container {
  width: 100%;
  height: auto;
  .icon-wrapper {
    width: fit-content;
    width: -moz-fit-content;
  }
}

.small-star {
  transform: scale(1);
  transition: 0.5s;
  -moz-transition: 0.5s;
  color: yellow;
  font-size: 12px;
  position: absolute;
  top: 4px;
  right: -6px;
}

.small-star path {
  stroke: black;
  stroke-width: 20;
}

.neighbourhood-review {
  transform: scale(1);
  transition: 0.5s;
  -moz-transition: 0.5s;
  color: limegreen;
}

.neighbourhood-review path {
  stroke: black;
  stroke-width: 20;
}

.neighbourhood-review:hover {
  color: red;
  transform: scale(1.1);
  transition: 0.5s;
  -moz-transition: 0.5s;
}
/* End Review Icons */

/* Start Action Buttons */
.ad-details .action-dropdown {
  z-index: 9999;
  min-height: 30px;
  text-align: center;
  background-color: rgba(255, 255, 255 , 0);
  color: #868e96;
  cursor: pointer;

  .dropdown-menu {
    //top: 15px !important;
  }

  .icon-report {
    transform: scale(1);
    transition: 0.5s;
    -moz-transition: 0.5s;
    color: orange;
  }

  .icon-report path {
    stroke: black;
    stroke-width: 20;
  }

  .icon-report:hover {
    color: red;
    transform: scale(1.1);
    transition: 0.5s;
    -moz-transition: 0.5s;
  }

  .action-button-report {
    background-color: unset;
    border: none;
    transition: 0.5s;
    -moz-transition: 0.5s;
  }

  .action-button-report:hover {
    background-color: unset;
    border: none;
    transition: 0.5s;
    -moz-transition: 0.5s;
  }

  .action-button-report:hover .icon-report {
    color: red;
    transition: 0.5s;
    -moz-transition: 0.5s;
  }

  .action-button-report .tooltiptext {
    position: absolute;
    top: -25px;
    width: max-content !important;
  }
}

.action-dropdown:hover {
  cursor: pointer;
}

.action-buttons {
  z-index: 1001;
  .action-item {
    padding: 2px 5px 2px 5px;
    margin: 2px 5px 2px 5px;
    min-width: 80px;
    font-size: 13px;
  }

  .action-button-spam {
    text-align: start;
    min-height: 30px;
    width: 100%;
    background-color: unset;
    border: none;
    //border-bottom: 3px solid deepskyblue;
    min-width: 80px;
    transition: 0.5s;
    -moz-transition: 0.5s;
    transform: scale(1);
  }

  .action-button-spam:hover {
    background-color: unset;
    border: none;
    transition: 0.5s;
    -moz-transition: 0.5s;
    transform: scale(1.1);
  }

  .action-button-spam:hover .icon-spam {
    color: mediumpurple;
    transition: 0.5s;
    -moz-transition: 0.5s;
  }

  .action-button-scam {
    text-align: start;
    min-height: 30px;
    width: 100%;
    background-color: unset;
    border: none;
    //border-bottom: 3px solid deepskyblue;
    min-width: 80px;
    transition: 0.5s;
    -moz-transition: 0.5s;
    transform: scale(1);
  }

  .action-button-scam:hover {
    background-color: unset;
    border: none;
    transition: 0.5s;
    -moz-transition: 0.5s;
    transform: scale(1.1);
  }

  .action-button-scam:hover .icon-scam {
    color: mediumblue;
    transition: 0.5s;
    -moz-transition: 0.5s;
  }

  .action-button-abuse {
    text-align: start;
    min-height: 30px;
    width: 100%;
    background-color: unset;
    border: none;
    //border-bottom: 3px solid deepskyblue;
    min-width: 80px;
    transition: 0.5s;
    -moz-transition: 0.5s;
    transform: scale(1);
  }

  .action-button-abuse:hover {
    background-color: unset;
    border: none;
    transition: 0.5s;
    -moz-transition: 0.5s;
    transform: scale(1.1);
  }

  .action-button-abuse:hover .icon-abuse {
    color: red;
    transition: 0.5s;
    -moz-transition: 0.5s;
  }
}

.success-msg {
  position: fixed;
  top: 20%;
  left: 10%;
  z-index: 9999;
}

/* End Action Buttons */

.category-label {
  border: 1px solid #868e96;
  border-radius: 10px;
  width: 100px;
  min-width: 100px;
}

/* Start Text truncate - white space - for long texts and icons */
.list-relative-contact {
  width: 100%;
  position: relative;
  min-height: 25px;
}

.list-relative {
  width: 100%;
  position: relative;
}

.text-truncate-item-square-meter {
  box-sizing:border-box;
  transition: 0.7s;
  border-radius: 5px;
}

.text-truncate-item-square-meter:hover {
  position: absolute;
  transition: 0.7s;
  box-sizing:border-box;
  overflow: visible !important;
  white-space: normal !important;
  background-color: white;
  border: 1px solid black;
  padding: 0 0 0 1px;
  border-radius: 5px;
}

.text-truncate-item {
  width: 100%;
  box-sizing:border-box;
  transition: 0.7s;
  border-radius: 5px;
}

.text-truncate-item:hover {
  position: absolute;
  transition: 0.7s;
  box-sizing:border-box;
  overflow: visible !important;
  white-space: normal !important;
  background-color: white;
  border: 1px solid black;
  padding: 0 0 0 1px;
  border-radius: 5px;
}
/* End Text truncate - white space - for long texts and icons */

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scroll {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.user-image {
  width: 100%;
  height: 100%;
  max-width: 120px;
  margin: auto;
  border-radius: 50%;
  border: 1px solid #9e9e9e;
}

.property-info-container {
  width: 48%;
  height: 100%;
  display: inline-block;
  vertical-align: middle;
}

.property-info-box {
  min-height: 20px;
}

.property-info-type {
  cursor: pointer;
  font-size: 13px;
  //font-weight: bold;
}

.property-info {
  cursor: pointer;
  font-size: 13px;
  //font-weight: bold;
  margin-left: -4%;
}

.ad-details {
  position: absolute;
  z-index: 99;
  background-color: rgba(255, 255, 255, 70%);
  a.closebtn {
    position: fixed;
    width: 28px;
    height: 28px;
    top: 46%;
    left: 31%;
    font-size: 24px;
    z-index: 9999;
    visibility: hidden;
    transition: 0.7s;
    color: #198754;
    border-radius: 50%;
    border: 2px solid rgba(0, 0, 0, .125);
    line-height: 0;
    background-color: rgba(255, 255, 255, 0.8);
    :hover {
      color: red;
      transition: 0.7s;
    }
  }
  a.closebtn .tooltiptext:hover {
    color: white !important;
  }
  a.closebtn #adDetailsChevronCloseBtn {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.container-preview-ad-details {
  width: 100%;
  height: 81vh;
  margin: auto;
  .row {
    margin: auto;
  }
  .col-6 {
    padding: 0;
  }
  .col-md-6 {
    padding: 0;
  }
}

/*
  START
  change some css so that images are always sized same
  use media queries to adjust that for mobile and other devices
*/

.ad-details-thumbnails {
  #carouselCaptionsPreviewPropDetails {
    .carousel-indicators {
      position: absolute;
      display: block;
      list-style: none;
      text-align: center;
      margin: 0;
      padding: 0;
      left: 80% !important;
      top: 0;
      overflow-x: scroll;
      .active {
        transition: 0.7s !important;
        opacity: 1 !important;
        background-color: rgba(255,255,255,0) !important;
      }
    }
    .carousel-control-prev-icon {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' stroke='%23000' viewBox='0 0 10 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
    }
    .carousel-control-next-icon {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' stroke='%23000' viewBox='0 0 10 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
    }
    .carousel-indicators li {
      width: auto; // 200px
      height: auto; // 150px
      max-height: 180px;
      transition: 0.7s;
      opacity: 0.5;
      display: block;
      .carousel-indicators-thumbnails {
        width: 100%;
        height: auto;
        max-height: 200px;
        min-height: auto;
        //max-height: 150px;
        //min-height: 150px;
        border-radius: 5px;
      }
    }
    .thumbnails-height {
      width: 100%;
      height: auto;
      max-height: 500px;
      min-height: auto;
      //max-height: 550px;
      //min-height: 550px;
    }

    .carousel-inner {
      width: 79%;
      border-radius: 5px;
      height: auto;
      max-height: 500px;
      min-height: auto;
      //max-height: 550px;
      //min-height: 550px;
      background-clip: border-box;
      border: 1px solid rgba(0, 0, 0, .125);
      box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
    }
    .carousel-control-prev {
      width: 10%;
    }
    .carousel-control-next {
      right: 21%;
      width: 10%;
    }
  }
  #carouselPreviewFloorplans {
    .carousel-control-prev-icon {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' stroke='%23000' viewBox='0 0 14 10'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
    }
    .carousel-control-next-icon {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' stroke='%23000' viewBox='0 0 14 10'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
    }
    .thumbnails-floorplan-height {
      width: 100%;
      height: auto;
      max-height: 400px;
      min-height: auto;
      //max-height: 250px;
      //min-height: 250px;
    }
    .carousel-inner {
      width: 100%;
      border-radius: 5px;
      height: auto;
      max-height: 400px;
      min-height: auto;
      //max-height: 250px;
      //min-height: 250px;
      background-clip: border-box;
      border: 1px solid rgba(0, 0, 0, .125);
      box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
    }
    .carousel-control-prev {
      width: 10%;
    }
    .carousel-control-next {
      width: 10%;
    }
  }
  /* change transition duration to control the speed of fade effect */
  .carousel-item {
    transition: transform 1s ease-in-out;
  }

  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: opacity 1s 1s;
  }
}
/*
  END
  change some css so that images are always sized same
  use media queries to adjust that for mobile and other devices
*/

/* start tooltip - popups info on icons */
.tooltiptext {
  visibility: hidden;
  width: auto;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  text-align: center;
  font-size: 10px;
  border-radius: 6px;
  padding: 5px 5px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1000;
}

[data-tooltip]:hover .tooltiptext {
  visibility: visible;
  color: #fff;
}
/* end tooltip - popups info on icons */

.property-icons {
  font-size: 14px;
  --bs-gutter-x: 0;
  .col-1 {
    width: 14.33333333% !important;
  }
  .col-2 {
    width: 14.66666667% !important;
  }
  .top-row-icons {
    .col-2 {
      width: 80.66666667% !important;
    }
  }
}

.sub-icons-font {
  font-size: small;
}

ul.icons-listing li {
  margin: 5px 0 5px 0;
}

/* Start Contact Area */
.chat-modal {
  left: 0;
  top: 20%;
}

.marketed-by {
  border-radius: 10px;
  background: white;
  $border: 2px;
  position: relative;
  padding: 30% 2em;
  box-sizing: border-box;
  &:before {
    content: '';
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    z-index: -1;
    margin: -$border; /* !importanté */
    border-radius: inherit; /* !importanté */
    //background: linear-gradient(to bottom, #b209b2, #19b5fe);
    background: linear-gradient(to bottom, rgba(128, 0, 128, 0.7), rgba(25, 181, 254, 0.7));
  }
  p.marketed-by-heading {
    font-size: 13px;
    color: white;
    border-radius: 10px 10px 0 0;
    background: rgba(128, 0, 128, 0.7);
    padding: .5rem;
    text-transform: uppercase
  }
  .chat-msg-button {
    padding: 5px;
    border-radius: 10px;
    border: 1px solid #19b5fe;
    background-color: white; // #19b5fe
    color: #19b5fe;
    transition: 0.5s;
    -moz-transition: 0.5s;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
  }
  .chat-msg-button:hover {
    background: rgba(128, 0, 128, 0.7);
    border: 1px solid rgba(128, 0, 128, 0.7);
    color: white;
    transition: 0.5s;
    -moz-transition: 0.5s;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
  }
  .chat-icon {
    color: #19b5fe;
    transition: 0.5s;
    -moz-transition: 0.5s;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
  }
  .chat-msg-button:hover .chat-icon {
    color: white;
    transition: 0.5s;
    -moz-transition: 0.5s;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
  }
}

.contact-icons {
  font-size: 16px;
  color: darkblue;
}

a.contact-url {
  color: black;
  transition: 0.6s;
  text-decoration: none;
  :hover {
    transition: 0.6s;
    color: black;
  }
}

.list-horizontal {
  list-style-type: none;
  text-align: center;
  padding-inline-start: 0;
  li.list-item-horizontal {
    display: inline-block;
  }
}
/* End Contact Area */

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-width: 499px) {
  #carouselPreviewFloorplans {
    .thumbnails-floorplan-height {
      min-height: 150px !important;
    }
    .carousel-inner {
      min-height: 150px !important;
    }
  }
  #carouselCaptionsPreviewPropDetails {
    .carousel-indicators-thumbnails {
      width: 100%;
      min-height: 90px !important;
    }
    .thumbnails-height {
      min-height: 200px !important;
    }
    .carousel-inner {
      min-height: 200px !important;
      width: 100% !important;
    }
    .carousel-control-prev {
      width: 10%;
    }
    .carousel-control-next {
      right: 0 !important;
      width: 10%;
    }
  }
}
@media screen and (min-width: 500px) {
  #carouselPreviewFloorplans {
    .thumbnails-floorplan-height {
      min-height: 250px !important;
    }
    .carousel-inner {
      min-height: 250px !important;
    }
  }
  #carouselCaptionsPreviewPropDetails {
    .carousel-indicators-thumbnails {
      width: 100%;
      min-height: 90px !important;
    }
    .thumbnails-height {
      min-height: 250px !important;
    }
    .carousel-inner {
      min-height: 250px !important;
      width: 100% !important;
    }
    .carousel-control-prev {
      width: 10%;
    }
    .carousel-control-next {
      right: 0 !important;
      width: 10%;
    }
  }
}
@media screen and (min-width: 580px) {
  #carouselPreviewFloorplans {
    .thumbnails-floorplan-height {
      min-height: 250px !important;
    }
    .carousel-inner {
      min-height: 250px !important;
    }
  }
  #carouselCaptionsPreviewPropDetails {
    .carousel-indicators-thumbnails {
      width: 100%;
      min-height: 120px !important;
    }

    .thumbnails-height {
      min-height: 250px !important;
    }

    .carousel-inner {
      min-height: 250px !important;
      width: 100% !important;
    }
    .carousel-control-prev {
      width: 10%;
    }
    .carousel-control-next {
      right: 0 !important;
      width: 10%;
    }
  }
}
@media screen and (min-width: 768px) {
  #carouselPreviewFloorplans {
    .thumbnails-floorplan-height {
      min-height: 350px !important;
    }
    .carousel-inner {
      min-height: 350px !important;
    }
  }
  #carouselCaptionsPreviewPropDetails {
    .carousel-indicators-thumbnails {
      width: 100%;
      min-height: 120px !important;
    }
    .thumbnails-height {
      min-height: 350px !important;
    }
    .carousel-inner {
      min-height: 350px !important;
      width: 79% !important;
    }
    .carousel-control-prev {
      width: 10%;
    }
    .carousel-control-next {
      right: 21% !important;
      width: 10%;
    }
  }
}
@media screen and (min-width: 992px) {
  #carouselPreviewFloorplans {
    .thumbnails-floorplan-height {
      min-height: 200px !important;
    }
    .carousel-inner {
      min-height: 200px !important;
    }
  }
  #carouselCaptionsPreviewPropDetails {
    .carousel-indicators-thumbnails {
      width: 100%;
      min-height: 120px !important;
    }
    .thumbnails-height {
      min-height: 350px !important;
    }
    .carousel-inner {
      min-height: 350px !important;
    }
  }
}
@media screen and (min-width: 1200px) {
  #carouselPreviewFloorplans {
    .thumbnails-floorplan-height {
      min-height: 250px !important;
    }
    .carousel-inner {
      min-height: 250px !important;
    }
  }
  #carouselCaptionsPreviewPropDetails {
    .carousel-indicators-thumbnails {
      width: 100%;
      min-height: 150px !important;
    }
    .thumbnails-height {
      min-height: 450px !important;
    }
    .carousel-inner {
      min-height: 450px !important;
    }
  }
}
@media screen and (min-width: 1920px) {
  #carouselPreviewFloorplans {
    .thumbnails-floorplan-height {
      min-height: 350px !important;
    }
    .carousel-inner {
      min-height: 350px !important;
    }
  }
  #carouselCaptionsPreviewPropDetails {
    .carousel-indicators-thumbnails {
      width: 100%;
      min-height: 150px !important;
    }
    .thumbnails-height {
      min-height: 550px !important;
    }
    .carousel-inner {
      min-height: 550px !important;
    }
  }
}

</style>
