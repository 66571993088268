import axios from 'axios'

export default {
    namespaced: true,
    state:{
        loading:false,
        properties: {},
        errors: {},
        properties_map: {},
        errors_map: {},
        /* Main Level Dropdown - Holds property categories */
        property_categories_options: null,
        property_types_options: null,
            /* First Level Dropdown - Holds property types according to property categories - skipped room as no subitems */
            prop_residential_types_options: null,
            prop_commercial_types_options: null,
            prop_industrial_types_options: null,
            prop_land_types_options: null,
            prop_luxury_types_options: null,
            prop_special_use_types_options: null,
                /* Second Level Dropdown - Holds property type details according to property types */
                // Residential
                prop_house_type_details: null,
                prop_flat_type_details: null,
                // Commercial
                prop_office_type_details: null,
                prop_retail_type_details: null,
                prop_leisure_type_details: null,
                prop_healthcare_type_details: null,
                prop_parking_type_details: null,
                prop_multifamily_type_details: null,
                // Industrial (we skipped Warehouse and Factory as no subcategories)
                prop_power_plant_type_details: null,
    },
    getters:{
        loading(state){
            return state.loading
        },
        properties(state){
            return state.properties
        },
        errors(state){
            return state.errors
        },
        properties_map(state){
            return state.properties_map
        },
        errors_map(state){
            return state.errors_map
        },

        /* Properties Categories, Types, Details */
        property_categories_options(state){
            return state.property_categories_options
        },
        property_types_options(state){
            return state.property_types_options
        },
        prop_residential_types_options(state){
            return state.prop_residential_types_options
        },
        prop_commercial_types_options(state){
            return state.prop_commercial_types_options
        },
        prop_industrial_types_options(state){
            return state.prop_industrial_types_options
        },
        prop_land_types_options(state){
            return state.prop_land_types_options
        },
        prop_luxury_types_options(state){
            return state.prop_luxury_types_options
        },
        prop_special_use_types_options(state){
            return state.prop_special_use_types_options
        },
        prop_house_type_details(state){
            return state.prop_house_type_details
        },
        prop_flat_type_details(state){
            return state.prop_flat_type_details
        },
        prop_office_type_details(state){
            return state.prop_office_type_details
        },
        prop_retail_type_details(state){
            return state.prop_retail_type_details
        },
        prop_leisure_type_details(state){
            return state.prop_leisure_type_details
        },
        prop_healthcare_type_details(state){
            return state.prop_healthcare_type_details
        },
        prop_parking_type_details(state){
            return state.prop_parking_type_details
        },
        prop_multifamily_type_details(state){
            return state.prop_multifamily_type_details
        },
        prop_power_plant_type_details(state){
            return state.prop_power_plant_type_details
        },
    },
    mutations:{
        SET_LOADING (state, value) {
            state.loading = value
        },
        SET_PROPERTIES (state, value) {
            state.properties = value
        },
        SET_ERRORS (state, value) {
            state.errors = value
        },
        SET_PROPERTIES_MAP (state, value) {
            state.properties_map = value
        },
        SET_ERRORS_MAP (state, value) {
            state.errors_map = value
        },

        /* Property Categories, Types, Details */
        SET_PROPERTY_CATEGORIES_OPTIONS (state, value) {
            sessionStorage.removeItem('propertyCategoriesOptions');
            sessionStorage.setItem('propertyCategoriesOptions', JSON.stringify(value));
            state.property_categories_options = value
        },
        SET_PROPERTY_TYPES_OPTIONS (state, value) {
            sessionStorage.removeItem('propertyTypesOptions');
            sessionStorage.setItem('propertyTypesOptions', JSON.stringify(value));
            state.property_types_options = value
        },
        SET_PROPERTY_RESIDENTIAL_TYPES_OPTIONS (state, value) {
            sessionStorage.removeItem('propResidentialTypesOptions');
            sessionStorage.setItem('propResidentialTypesOptions', JSON.stringify(value));
            state.prop_residential_types_options = value
        },
        SET_PROPERTY_COMMERCIAL_TYPES_OPTIONS (state, value) {
            sessionStorage.removeItem('propCommercialTypesOptions');
            sessionStorage.setItem('propCommercialTypesOptions', JSON.stringify(value));
            state.prop_commercial_types_options = value
        },
        SET_PROPERTY_INDUSTRIAL_TYPES_OPTIONS (state, value) {
            sessionStorage.removeItem('propIndustrialTypesOptions');
            sessionStorage.setItem('propIndustrialTypesOptions', JSON.stringify(value));
            state.prop_industrial_types_options = value
        },
        SET_PROPERTY_LAND_TYPES_OPTIONS (state, value) {
            sessionStorage.removeItem('propLandTypesOptions');
            sessionStorage.setItem('propLandTypesOptions', JSON.stringify(value));
            state.prop_land_types_options = value
        },
        SET_PROPERTY_LUXURY_TYPES_OPTIONS (state, value) {
            sessionStorage.removeItem('propLuxuryTypesOptions');
            sessionStorage.setItem('propLuxuryTypesOptions', JSON.stringify(value));
            state.prop_luxury_types_options = value
        },
        SET_PROPERTY_SPECIAL_USE_TYPES_OPTIONS (state, value) {
            sessionStorage.removeItem('propSpecialUseTypesOptions');
            sessionStorage.setItem('propSpecialUseTypesOptions', JSON.stringify(value));
            state.prop_special_use_types_options = value
        },
        SET_PROPERTY_HOUSE_TYPE_DETAILS (state, value) {
            sessionStorage.removeItem('propHouseTypeDetails');
            sessionStorage.setItem('propHouseTypeDetails', JSON.stringify(value));
            state.prop_house_type_details = value
        },
        SET_PROPERTY_FLAT_TYPE_DETAILS (state, value) {
            sessionStorage.removeItem('propFlatTypeDetails');
            sessionStorage.setItem('propFlatTypeDetails', JSON.stringify(value));
            state.prop_flat_type_details = value
        },
        SET_PROPERTY_OFFICE_TYPE_DETAILS (state, value) {
            sessionStorage.removeItem('propOfficeTypeDetails');
            sessionStorage.setItem('propOfficeTypeDetails', JSON.stringify(value));
            state.prop_office_type_details = value
        },
        SET_PROPERTY_RETAIL_TYPE_DETAILS (state, value) {
            sessionStorage.removeItem('proRetailTypeDetails');
            sessionStorage.setItem('proRetailTypeDetails', JSON.stringify(value));
            state.prop_retail_type_details = value
        },
        SET_PROPERTY_LEISURE_TYPE_DETAILS (state, value) {
            sessionStorage.removeItem('propLeisureTypeDetails');
            sessionStorage.setItem('propLeisureTypeDetails', JSON.stringify(value));
            state.prop_leisure_type_details = value
        },
        SET_PROPERTY_HEALTHCARE_TYPE_DETAILS (state, value) {
            sessionStorage.removeItem('propHealthcareTypeDetails');
            sessionStorage.setItem('propHealthcareTypeDetails', JSON.stringify(value));
            state.prop_healthcare_type_details = value
        },
        SET_PROPERTY_PARKING_TYPE_DETAILS (state, value) {
            sessionStorage.removeItem('propParkingTypeDetails');
            sessionStorage.setItem('propParkingTypeDetails', JSON.stringify(value));
            state.prop_parking_type_details = value
        },
        SET_PROPERTY_MULTIFAMILY_TYPE_DETAILS (state, value) {
            sessionStorage.removeItem('propMultifamilyTypeDetails');
            sessionStorage.setItem('propMultifamilyTypeDetails', JSON.stringify(value));
            state.prop_multifamily_type_details = value
        },
        SET_PROPERTY_POWER_PLANT_TYPE_DETAILS (state, value) {
            sessionStorage.removeItem('propPowerPlantTypeDetails');
            sessionStorage.setItem('propPowerPlantTypeDetails', JSON.stringify(value));
            state.prop_power_plant_type_details = value
        },
    },
    actions:{
        async getProperties({commit}, payload){
            commit('SET_LOADING',true)
            // { params: payload }
            return axios.post('api/properties-list', payload).then(({data})=>{
                commit('SET_PROPERTIES',data)
                commit('SET_LOADING',false)
            }).catch(({response:{data}})=>{
                commit('SET_PROPERTIES',{})
                commit('SET_ERRORS', data.errors)
                commit('SET_LOADING',false)
            })
        },
        async getPropertiesMap({commit}, payload){
            commit('SET_LOADING',true)
            return axios.post('api/properties-map', payload).then(({data})=>{
                commit('SET_PROPERTIES_MAP',data)
                commit('SET_LOADING',false)
            }).catch(({response:{data}})=>{
                commit('SET_PROPERTIES_MAP',{})
                commit('SET_ERRORS_MAP', data.errors)
                commit('SET_LOADING',false)
            })
        },
        setPropertyCategories({commit}, payload){
            commit('SET_LOADING',true)
            // console.log(payload);
            if(payload) {
                commit('SET_PROPERTY_CATEGORIES_OPTIONS',payload.propertyCategoriesOptions)
                commit('SET_PROPERTY_TYPES_OPTIONS',payload.propertyTypesOptions)
                commit('SET_PROPERTY_RESIDENTIAL_TYPES_OPTIONS',payload.propResidentialTypesOptions)
                commit('SET_PROPERTY_COMMERCIAL_TYPES_OPTIONS',payload.propCommercialTypesOptions)
                commit('SET_PROPERTY_INDUSTRIAL_TYPES_OPTIONS',payload.propIndustrialTypesOptions)
                commit('SET_PROPERTY_LAND_TYPES_OPTIONS',payload.propLandTypesOptions)
                commit('SET_PROPERTY_LUXURY_TYPES_OPTIONS',payload.propLuxuryTypesOptions)
                commit('SET_PROPERTY_SPECIAL_USE_TYPES_OPTIONS',payload.propSpecialUseTypesOptions)
                commit('SET_PROPERTY_HOUSE_TYPE_DETAILS',payload.propHouseTypeDetails)
                commit('SET_PROPERTY_FLAT_TYPE_DETAILS',payload.propFlatTypeDetails)
                commit('SET_PROPERTY_OFFICE_TYPE_DETAILS',payload.propOfficeTypeDetails)
                commit('SET_PROPERTY_RETAIL_TYPE_DETAILS',payload.propRetailTypeDetails)
                commit('SET_PROPERTY_LEISURE_TYPE_DETAILS',payload.propLeisureTypeDetails)
                commit('SET_PROPERTY_HEALTHCARE_TYPE_DETAILS',payload.propHealthcareTypeDetails)
                commit('SET_PROPERTY_PARKING_TYPE_DETAILS',payload.propParkingTypeDetails)
                commit('SET_PROPERTY_MULTIFAMILY_TYPE_DETAILS',payload.propMultifamilyTypeDetails)
                commit('SET_PROPERTY_POWER_PLANT_TYPE_DETAILS',payload.propPowerPlantTypeDetails)
            }
            commit('SET_LOADING',false)
        },
        resetErrors({commit}) {
            commit('SET_ERRORS', {})
            commit('SET_ERRORS_MAP', {})
        }
    }
}