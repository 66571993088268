import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

/* Guest Component */
const Map = () => import('../components/Map.vue' /* webpackChunkName: "resource/js/components/map" */)
const List = () => import('../components/List.vue' /* webpackChunkName: "resource/js/components/list" */)
const About = () => import('../components/About.vue' /* webpackChunkName: "resource/js/components/about" */)
const Contact = () => import('../components/Contact.vue' /* webpackChunkName: "resource/js/components/contact" */)
const Careers = () => import('../components/Careers.vue' /* webpackChunkName: "resource/js/components/careers" */)
const QnA = () => import('../components/QnA.vue' /* webpackChunkName: "resource/js/components/qna" */)
const PrivacyPolicy = () => import('../components/PrivacyPolicy.vue' /* webpackChunkName: "resource/js/components/privacypolicy" */)
const CookiePolicy = () => import('../components/CookiePolicy.vue' /* webpackChunkName: "resource/js/components/cookiepolicy" */)
const TermsAndConditions = () => import('../components/TermsAndConditions.vue' /* webpackChunkName: "resource/js/components/termsandconditions" */)
const Services = () => import('../components/Services.vue' /* webpackChunkName: "resource/js/components/services" */)
const Login = () => import('../components/Login.vue' /* webpackChunkName: "resource/js/components/login" */)
const Register = () => import('../components/Register.vue' /* webpackChunkName: "resource/js/components/register" */)
const AdDetails = () => import('../components/AdDetails.vue' /* webpackChunkName: "resource/js/components/addetails" */)
const AdService = () => import('../components/AdService.vue' /* webpackChunkName: "resource/js/components/adservice" */)
const AdDetailsPage = () => import('../components/AdDetailsPage.vue' /* webpackChunkName: "resource/js/components/addetailspage" */)
const AdServicePage = () => import('../components/AdServicePage.vue' /* webpackChunkName: "resource/js/components/adservicepage" */)
/* Guest Component */

/* User Password Component */
const ForgotPassword = () => import('../components/Auth/ForgotPassword.vue' /* webpackChunkName: "resource/js/components/forgotpassword" */)
const ResetPassword = () => import('../components/Auth/ResetPassword.vue' /* webpackChunkName: "resource/js/components/resetpassword" */)
/* User Password Component */

/* Layouts */
const DashboardLayout = () => import('../components/layouts/Dashboard.vue' /* webpackChunkName: "resource/js/components/layouts/dashboard" */)
/* Layouts */

/* Authenticated Component */
/* Dashboard */
const DashboardStatistics = () => import('../components/Dashboard/DashboardStatistics.vue' /* webpackChunkName: "resource/js/components/dashboardstatistics" */)
const DashboardPortfolio = () => import('../components/Dashboard/DashboardPortfolio.vue' /* webpackChunkName: "resource/js/components/dashboardportfolio" */)
const DashboardServices = () => import('../components/Dashboard/DashboardServices.vue' /* webpackChunkName: "resource/js/components/dashboardservices" */)
const DashboardFavourites = () => import('../components/Dashboard/DashboardFavourites.vue' /* webpackChunkName: "resource/js/components/dashboardfavourites" */)
const DashboardUserSettings = () => import('../components/Dashboard/DashboardUserSettings.vue' /* webpackChunkName: "resource/js/components/dashboardusersettings" */)
const DashboardUserCalendar = () => import('../components/Dashboard/DashboardUserCalendar.vue' /* webpackChunkName: "resource/js/components/dashboardusercalendar" */)
const DashboardBookings = () => import('../components/Dashboard/DashboardBookings.vue' /* webpackChunkName: "resource/js/components/dashboardbookings" */)
const DashboardReviews = () => import('../components/Dashboard/DashboardReviews.vue' /* webpackChunkName: "resource/js/components/dashboardreviews" */)
const DashboardUserMessages = () => import('../components/Dashboard/DashboardUserMessages.vue' /* webpackChunkName: "resource/js/components/dashboardusermessages" */)
const DashboardNotifications = () => import('../components/Dashboard/DashboardNotifications.vue' /* webpackChunkName: "resource/js/components/dashboardnotifications" */)
/* End Dashboard */
const AddEditAd = () => import('../components/AddEditAd.vue' /* webpackChunkName: "resource/js/components/addeditad" */)
// const AddEditAdMultiStep = () => import('../components/AddEditAdMultiStep.vue' /* webpackChunkName: "resource/js/components/addeditadmultistep" */)
const AddEditService = () => import('../components/AddEditService.vue' /* webpackChunkName: "resource/js/components/addeditservice" */)
const AdSubmitConfirmation = () => import('../components/AdSubmitConfirmation.vue' /* webpackChunkName: "resource/js/components/addsubmitconfirmation" */)
const Donate = () => import('../components/Donate.vue' /* webpackChunkName: "resource/js/components/donate" */)
const BookingPayment = () => import('../components/Bookings/BookingPayment.vue' /* webpackChunkName: "resource/js/components/bookingpayment" */)
const AddReview = () => import('../components/Reviews/AddReview.vue' /* webpackChunkName: "resource/js/components/addreview" */)
/* Authenticated Component */

const Routes = [
  {
    name:"forgotpassword",
    path:"/forgot-password",
    component:ForgotPassword,
    meta:{
      middleware:"guest",
      title:`ForgotPassword`
    }
  },
  {
    name:"resetpassword",
    path:"/reset-password",
    component:ResetPassword,
    props: (route) => ({ token: route.query.token, email: route.query.email }),
    meta:{
      middleware:"guest",
      title:`ResetPassword`
    }
  },
  {
    name:"map",
    path:"/",
    component:Map,
    meta:{
      middleware:"guest",
      title:`Map`
    }
  },
  // {
  //   name:"list",
  //   path:"/list",
  //   component:List,
  //   meta:{
  //     middleware:"guest",
  //     title:`List`
  //   }
  // },
  {
    name:"about",
    path:"/about",
    component:About,
    meta:{
      middleware:"guest",
      title:`About`
    }
  },
  {
    name:"contact",
    path:"/contact",
    component:Contact,
    meta:{
      middleware:"guest",
      title:`Contact`
    }
  },
  {
    name:"careers",
    path:"/careers",
    component:Careers,
    meta:{
      middleware:"guest",
      title:`Careers`
    }
  },
  {
    name:"qna",
    path:"/qna",
    component:QnA,
    meta:{
      middleware:"guest",
      title:`QnA`
    }
  },
  {
    name:"privacypolicy",
    path:"/privacy-policy",
    component:PrivacyPolicy,
    meta:{
      middleware:"guest",
      title:`PrivacyPolicy`
    }
  },
  {
    name:"cookiepolicy",
    path:"/cookie-policy",
    component:CookiePolicy,
    meta:{
      middleware:"guest",
      title:`CookiePolicy`
    }
  },
  {
    name:"termsandconditions",
    path:"/terms-and-conditions",
    component:TermsAndConditions,
    meta:{
      middleware:"guest",
      title:`TermsAndConditions`
    }
  },
  {
    name:"donate",
    path:"/donate",
    component:Donate,
    beforeEnter(to, from, next) {
      let user = null;
      if (sessionStorage.getItem('auth')) {
        user = JSON.parse(sessionStorage.getItem('auth'));
      }
      if (user && user.auth[0].authenticated === true) {
        next();
      } else {
        next({name:"login", query: {redirect: to.fullPath}})
      }
    },
    meta: {
      middleware: "auth",
      title:`Donate`
    }
  },
  {
    name:"services",
    path:"/services",
    component:Services,
    meta:{
      middleware:"guest",
      title:`Services`
    }
  },
  {
    name:"login",
    path:"/login",
    component:Login,
    meta:{
      middleware:"guest",
      title:`Login`
    }
  },
  {
    name:"register",
    path:"/register",
    component:Register,
    meta:{
      middleware:"guest",
      title:`Register`
    }
  },
  {
    name:"addetails",
    path:"/ad-details/:id",
    props: (route) => {
      const adId = Number.parseInt(route.params.id, 10)
      if (Number.isNaN(adId)) {
        return { id: null };
      }
      return { id: adId };
    },
    component:AdDetailsPage,
    meta:{
      middleware:"guest",
      title:`AdDetails`
    }
  },
  {
    name:"adservice",
    path:"/ad-service/:id",
    props: (route) => {
      const adId = Number.parseInt(route.params.id, 10)
      if (Number.isNaN(adId)) {
        return { id: null };
      }
      return { id: adId };
    },
    component:AdServicePage,
    meta:{
      middleware:"guest",
      title:`AdService`
    }
  },
  /* Authenticated paths */
  {
    name:"booking-payment",
    path: '/booking-payment/:token',
    component: BookingPayment,
    props: true,
    beforeEnter(to, from, next) {
      let user = null;
      if (sessionStorage.getItem('auth')) {
        user = JSON.parse(sessionStorage.getItem('auth'));
      }
      if (user && user.auth[0].authenticated === true) {
        next();
      } else {
        next({name:"login", query: {redirect: to.fullPath}})
      }
    },
    meta:{
      middleware: 'auth',
      title:`BookingPayment`
    }
  },
  {
    name:"add-review",
    // adId (property or service id)
    // adDetails - should be booking data :adId?/:adDetails?/:reviewType?/:toUserId?/:fromUserId?/:refresh?
    path: '/add-review',
    // props: (route) => {
    //   if (route.params.openReviewData) {
    //     const reviewData = route.params.openReviewData;
    //     if (reviewData) {
    //       return {openReviewData: reviewData};
    //     } else {
    //       return {openReviewData: null};
    //     }
    //   } else {
    //     return {openReviewData: null};
    //   }
    // },
    component: AddReview,
    beforeEnter(to, from, next) {
      let user = null;
      if (sessionStorage.getItem('auth')) {
        user = JSON.parse(sessionStorage.getItem('auth'));
      }
      if (user && user.auth[0].authenticated === true) {
        next();
      } else {
        next({name:"login", query: {redirect: to.fullPath}})
      }
    },
    meta:{
      title:`AddReview`
    }
  },
  {
    path:"/",
    component:DashboardLayout,
    beforeEnter(to, from, next) {
      let user = null;
      if (sessionStorage.getItem('auth')) {
        user = JSON.parse(sessionStorage.getItem('auth'));
      }
      if (user && user.auth[0].authenticated === true) {
        next();
      } else {
        next({name:"login", query: {redirect: to.fullPath}})
      }
    },
    meta:{
      middleware:"auth"
    },
    children:[
      {
        name:"dash-statistics",
        props: true,
        path: '/dashboard-statistics/:is_boarded?', // optional parameter used for stripe boarding, to trigger update on user
        component: DashboardStatistics,
        beforeEnter(to, from, next) {
          let user = null;
          if (sessionStorage.getItem('auth')) {
            user = JSON.parse(sessionStorage.getItem('auth'));
          }
          if (user && user.auth[0].authenticated === true) {
            next();
          } else {
            next({name:"login", query: {redirect: to.fullPath}})
          }
        },
        meta:{
          title:`DashboardStatistics`
        }
      },
      {
        name:"dash-portfolio",
        path: '/dashboard-portfolio',
        component: DashboardPortfolio,
        beforeEnter(to, from, next) {
          let user = null;
          if (sessionStorage.getItem('auth')) {
            user = JSON.parse(sessionStorage.getItem('auth'));
          }
          if (user && user.auth[0].authenticated === true) {
            next();
          } else {
            next({name:"login", query: {redirect: to.fullPath}})
          }
        },
        meta:{
          title:`DashboardPortfolio`
        }
      },
      {
        name:"dash-services",
        path: '/dashboard-services',
        component: DashboardServices,
        beforeEnter(to, from, next) {
          let user = null;
          if (sessionStorage.getItem('auth')) {
            user = JSON.parse(sessionStorage.getItem('auth'));
          }
          if (user && user.auth[0].authenticated === true) {
            next();
          } else {
            next({name:"login", query: {redirect: to.fullPath}})
          }
        },
        meta:{
          title:`DashboardServices`
        }
      },
      // {
      //   name:"dash-favourites",
      //   path: '/dashboard-favourites',
      //   component: DashboardFavourites,
      //   meta:{
      //     title:`DashboardFavourites`
      //   }
      // },
      {
        name:"dash-user-settings",
        path: '/dashboard-user-settings',
        component: DashboardUserSettings,
        beforeEnter(to, from, next) {
          let user = null;
          if (sessionStorage.getItem('auth')) {
            user = JSON.parse(sessionStorage.getItem('auth'));
          }
          if (user && user.auth[0].authenticated === true) {
            next();
          } else {
            next({name:"login", query: {redirect: to.fullPath}})
          }
        },
        meta:{
          title:`DashboardUserSettings`
        }
      },
      {
        name:"dash-user-calendar",
        path: '/dashboard-user-calendar',
        component: DashboardUserCalendar,
        beforeEnter(to, from, next) {
          let user = null;
          if (sessionStorage.getItem('auth')) {
            user = JSON.parse(sessionStorage.getItem('auth'));
          }
          if (user && user.auth[0].authenticated === true) {
            next();
          } else {
            next({name:"login", query: {redirect: to.fullPath}})
          }
        },
        meta:{
          title:`DashboardUserCalendar`
        }
      },
      {
        name:"dash-bookings",
        path: '/dashboard-bookings',
        component: DashboardBookings,
        beforeEnter(to, from, next) {
          let user = null;
          if (sessionStorage.getItem('auth')) {
            user = JSON.parse(sessionStorage.getItem('auth'));
          }
          if (user && user.auth[0].authenticated === true) {
            next();
          } else {
            next({name:"login", query: {redirect: to.fullPath}})
          }
        },
        meta:{
          title:`DashboardBookings`
        }
      },
      {
        name:"dash-reviews",
        path: '/dashboard-reviews',
        component: DashboardReviews,
        beforeEnter(to, from, next) {
          let user = null;
          if (sessionStorage.getItem('auth')) {
            user = JSON.parse(sessionStorage.getItem('auth'));
          }
          if (user && user.auth[0].authenticated === true) {
            next();
          } else {
            next({name:"login", query: {redirect: to.fullPath}})
          }
        },
        meta:{
          title:`DashboardReviews`
        }
      },
      {
        name:"dash-user-messages",
        props: true,
        path: '/dashboard-user-messages/:chat_room_id?',
        component: DashboardUserMessages,
        beforeEnter(to, from, next) {
          let user = null;
          if (sessionStorage.getItem('auth')) {
            user = JSON.parse(sessionStorage.getItem('auth'));
          }
          if (user && user.auth[0].authenticated === true) {
            next();
          } else {
            next({name:"login", query: {redirect: to.fullPath}})
          }
        },
        meta:{
          title:`DashboardUserMessages`
        }
      },
      {
        name:"dash-notifications",
        props: true,
        path: '/dashboard-notifications',
        component: DashboardNotifications,
        beforeEnter(to, from, next) {
          let user = null;
          if (sessionStorage.getItem('auth')) {
            user = JSON.parse(sessionStorage.getItem('auth'));
          }
          if (user && user.auth[0].authenticated === true) {
            next();
          } else {
            next({name:"login", query: {redirect: to.fullPath}})
          }
        },
        meta:{
          title:`DashboardNotifications`
        }
      },
    ]
  },
  {
    name: 'addeditservice',
    path: "/add-edit-service/:id?",
    component: AddEditService,
    beforeEnter(to, from, next) {
      let user = null;
      if (sessionStorage.getItem('auth')) {
        user = JSON.parse(sessionStorage.getItem('auth'));
      }
      if (user && user.auth[0].authenticated === true) {
        next();
      } else {
        next({name:"login", query: {redirect: to.fullPath}})
      }
    },
    meta: {
      middleware: "auth",
      title:`AddEditService`
    },
  },
  {
    name: 'addeditad',
    path: "/add-edit-ad/:id?",
    props: route => ({ id: route.params.id, query: route.query.q }),
    component: AddEditAd,
    beforeEnter(to, from, next) {
      let user = null;
      if (sessionStorage.getItem('auth')) {
        user = JSON.parse(sessionStorage.getItem('auth'));
      }
      if (user && user.auth[0].authenticated === true) {
        next();
      } else {
        next({name:"login", query: {redirect: to.fullPath}})
      }
    },
    meta: {
      middleware: "auth",
      title:`AddEditAd`
    },
  },
    // Experimental addeditadmultistep
  // {
  //   name: 'addeditadmultistep',
  //   path: "/add-edit-ad-multi-step/:id?",
  //   props: route => ({ id: route.params.id, query: route.query.q }),
  //   component: AddEditAdMultiStep,
  //   beforeEnter(to, from, next) {
  //     let user = null;
  //     if (sessionStorage.getItem('auth')) {
  //       user = JSON.parse(sessionStorage.getItem('auth'));
  //     }
  //     if (user && user.auth[0].authenticated === true) {
  //       next();
  //     } else {
  //       next({name:"login", query: {redirect: to.fullPath}})
  //     }
  //   },
  //   meta: {
  //     middleware: "auth",
  //     title:`AddEditAdMultiStep`
  //   },
  // },
    // End experimental addeditadmultistep
  {
    name: 'adsubmitconfirmation',
    props: true,
    path: "/add-submit-confirmation/:id?/:type?/:paymentId?",
    component: AdSubmitConfirmation,
    beforeEnter(to, from, next) {
      let user = null;
      if (sessionStorage.getItem('auth')) {
        user = JSON.parse(sessionStorage.getItem('auth'));
      }
      if (user && user.auth[0].authenticated === true) {
        next();
      } else {
        next({name:"login", query: {redirect: to.fullPath}})
      }
    },
    meta: {
      middleware: "auth",
      title:`AdSubmitConfirmation`
    },
  }
]

let router  = new VueRouter({
  base: './', // test temporary
  mode: 'history',
  routes: Routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

export default router