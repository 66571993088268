import Vue from 'vue'
import FakeScss from './helpers/fake.scss'
import App from './App.vue'
import './registerServiceWorker'
/* Map Box Geocoder */
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'
/* End Map Box Geocoder */
/* Firebase Auth SMS Verify */
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
/* End Firebase Auth SMS Verify */
/* Vue-select */
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import "vue-select/src/scss/vue-select.scss"
/* End Vue-select */
/* Vue Vee-Validate */
import { ValidationObserver, ValidationProvider, extend} from 'vee-validate';
import Validator from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
/* Date Picker - Calendar */
// import VCalendar from 'v-calendar';
import Calendar from 'v-calendar/lib/components/calendar.umd'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
/* End Date Picker - Calendar */
/* Start Echo and Pusher (Broadcasting) */
import Echo from 'laravel-echo'
window.Pusher = require('pusher-js')
/* End Echo and Pusher (Broadcasting) */
import router from './router'
import store from './store'
import axios from 'axios'
/* Moment.js for dates */
import moment from 'moment'
import 'bootstrap/dist/css/bootstrap.min.css'
import * as bootstrap from 'bootstrap';
window.bootstrap = bootstrap;

// Font awesome icons
import { library } from '@fortawesome/fontawesome-svg-core'
import { faFacebook, faFacebookMessenger, faLinkedin, faWhatsapp, faTwitter, faGoogle } from '@fortawesome/free-brands-svg-icons'
import {
  faDove, faListAlt, faMapMarked, faSearch, faChevronCircleDown, faComments, faToilet, faHandPointDown, faShower,
  faBath, faSink, faSeedling, faCar, faParking, faWarehouse, faPoundSign, faBed, faFileSignature, faHandshake,
  faHandHoldingHeart, faWheelchair, faTree, faLeaf, faCampground, faDiceOne, faDiceTwo, faChevronCircleLeft,
  faBreadSlice, faChair, faCouch, faEdit, faFireAlt, faBuilding, faSun, faHouseUser, faUsers, faCommentDots,
  faMobileAlt, faEnvelope, faUserShield, faPaw, faVectorSquare, faExclamationCircle, faCaretSquareUp, faDoorClosed,
  faDumbbell, faFilm, faDiceD6, faHotTub, faPollH, faUtensils, faWater, faSwimmer, faSwimmingPool, faRulerHorizontal,
  faStoreAlt, faTint, faLevelUpAlt, faLevelDownAlt, faBorderNone, faBoxOpen, faCaretSquareDown, faChessRook, faHeart,
  faBold, faItalic, faStrikethrough, faHighlighter, faHeading, faParagraph, faListOl, faListUl, faQuoteRight,
  faHandSparkles, faUndo, faRedo, faAlignLeft, faAlignCenter, faAlignRight, faAlignJustify, faLayerGroup, faFire,
  faThermometerFull, faHome, faCoins, faPlane, faGavel, faChartLine, faKey, faBoxes, faPercentage, faSolarPanel,
  faPeopleArrows, faChargingStation, faHandHoldingUsd, faCalculator, faMoneyBillWave, faWallet, faHands, faTh,
  faStream, faChartBar, faChartPie, faBalanceScale, faPhone, faSearchLocation, faBookmark, faStar, faChevronDown,
  faAngleDoubleDown, faAngleDoubleLeft, faUsersCog, faCog, faEllipsisH, faFlagCheckered, faPencilAlt, faTrash,
  faCheck, faTimes, faPaperPlane, faVolumeUp, faExclamation, faUserNinja, faClone, faHeartBroken, faPlus, faPlusCircle,
  faWrench, faCogs, faThumbsUp, faMinusCircle, faGrinHearts, faKissWinkHeart, faCalendarDay, faShare, faCheckCircle,
  faComment, faEye, faBell, faArrowCircleUp, faGift, faCalendarWeek, faSpa, faBeer, faFan, faWifi, faBacon, faCoffee,
  faUtensilSpoon, faConciergeBell, faDoorOpen, faSmoking, faSmokingBan, faPlaneArrival, faBurn, faCocktail,
  faIceCream, faHamburger, faShoppingBag, faShoppingBasket, faSnowflake, faTable, faTv, faBicycle, faBlender, faMugHot,
  faCaravan, faCloudSun, faPager, faHandsWash, faSprayCan, faFeather, faBan, faBellSlash, faCalendarMinus, faCalendarCheck,
  faHourglassStart, faHourglassEnd, faMusic, faTag, faUserFriends, faClock, faAsterisk, faChessQueen, faChessKing, faCrown,
  faDiceThree, faDiceFour, faDiceFive, faDiceSix, faLink, faBaby, faBabyCarriage, faTrashAlt, faPumpSoap, faArrowsAltV,
  faWindowMinimize, faGamepad, faDice, faPuzzlePiece, faRss, faSignal, faFootballBall, faTableTennis, faTemperatureHigh,
  faBroadcastTower, faUmbrellaBeach, faAnchor, faShip, faCity, faFish, faFrog, faImage, faLandmark, faMountain, faShoePrints,
  faWind, faTemperatureLow, faCaretUp, faLaptopHouse, faAngleUp, faRibbon, faRuler, faShieldAlt, faChild, faCalendarTimes,
  faHandshakeSlash, faHandshakeAltSlash, faStarHalf, faStarHalfAlt, faShareSquare, faExclamationTriangle, faMapMarkerAlt,
  faTimesCircle, faCreditCard, faInfoCircle, faSave
  } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(
    faDove, faListAlt, faMapMarked, faSearch, faChevronCircleDown, faComments, faToilet, faHandPointDown, faShower,
    faBath, faSink, faSeedling, faCar, faParking, faWarehouse, faPoundSign, faBed, faFileSignature, faHandshake,
    faHandHoldingHeart, faWheelchair, faTree, faLeaf, faCampground, faDiceOne, faDiceTwo, faChevronCircleLeft,
    faBreadSlice, faChair, faCouch, faEdit, faFireAlt, faBuilding, faSun, faHouseUser, faUsers, faCommentDots,
    faMobileAlt, faEnvelope, faUserShield, faPaw, faVectorSquare, faExclamationCircle, faCaretSquareUp, faDoorClosed,
    faDumbbell, faFilm, faDiceD6, faHotTub, faPollH, faUtensils, faWater, faSwimmer, faSwimmingPool, faRulerHorizontal,
    faStoreAlt, faTint, faLevelUpAlt, faLevelDownAlt, faBorderNone, faBoxOpen, faCaretSquareDown, faChessRook, faHeart,
    faBold, faItalic, faStrikethrough, faHighlighter, faHeading, faParagraph, faListOl, faListUl, faQuoteRight,
    faHandSparkles, faUndo, faRedo, faAlignLeft, faAlignCenter, faAlignRight, faAlignJustify, faLayerGroup, faFire,
    faThermometerFull, faHome, faCoins, faPlane, faGavel, faChartLine, faKey, faBoxes, faPercentage, faSolarPanel,
    faPeopleArrows, faChargingStation, faHandHoldingUsd, faCalculator, faMoneyBillWave, faWallet, faHands, faTh,
    faStream, faChartBar, faChartPie, faBalanceScale, faPhone, faSearchLocation, faBookmark, faStar, faChevronDown,
    faAngleDoubleDown, faAngleDoubleLeft, faUsersCog, faCog, faEllipsisH, faFlagCheckered, faPencilAlt, faTrash,
    faCheck, faTimes, faPaperPlane, faVolumeUp, faExclamation, faUserNinja, faClone, faHeartBroken, faPlus, faPlusCircle,
    faWrench, faCogs, faThumbsUp, faMinusCircle, faGrinHearts, faKissWinkHeart, faFacebook, faTwitter, faGoogle,
    faCalendarDay, faShare, faCheckCircle, faComment, faEye, faBell, faArrowCircleUp, faGift, faCalendarWeek, faSpa,
    faBeer, faFan, faWifi, faBacon, faCoffee, faUtensilSpoon, faConciergeBell, faDoorOpen, faSmoking, faSmokingBan,
    faPlaneArrival, faBurn, faCocktail, faIceCream, faHamburger, faShoppingBag, faShoppingBasket, faSnowflake, faTable,
    faTv, faBicycle, faBlender, faMugHot, faCaravan, faCloudSun, faPager, faHandsWash, faSprayCan, faFeather, faBan,
    faBellSlash, faCalendarMinus, faCalendarCheck, faHourglassStart, faHourglassEnd, faMusic, faTag, faUserFriends,
    faClock, faAsterisk, faChessQueen, faChessKing, faCrown, faDiceThree, faDiceFour, faDiceFive, faDiceSix, faLink,
    faBaby, faBabyCarriage, faTrashAlt, faPumpSoap, faArrowsAltV, faWindowMinimize, faGamepad, faDice, faPuzzlePiece,
    faRss, faSignal, faFootballBall, faTableTennis, faTemperatureHigh, faBroadcastTower, faUmbrellaBeach, faAnchor,
    faShip, faCity, faFish, faFrog, faImage, faLandmark, faMountain, faShoePrints, faWind, faTemperatureLow, faCaretUp,
    faLaptopHouse, faAngleUp, faRibbon, faRuler, faShieldAlt, faChild, faCalendarTimes, faHandshakeSlash, faHandshakeAltSlash,
    faStarHalf, faStarHalfAlt, faShareSquare, faFacebookMessenger, faLinkedin, faWhatsapp, faExclamationTriangle,
    faMapMarkerAlt, faTimesCircle, faCreditCard, faInfoCircle, faSave
  )

// extend('required', {
//   ...required,
//   message: 'This field is required'
// });

// Vee-Validate Custom Rules
extend('required_checkbox', {
  computesRequired: true,
  validate: value => (value === true || value === 1),
  message: 'This field is required'
});

// Vee-validate error messages
let messages = {
  'required': 'This field is required',
  'min_value': 'The minimum required value is {min}',
  'max_value': 'The maximum allowed value is {max}',
  'min': 'The minimum required length is {length} characters',
  'max': 'The maximum allowed length is {length} characters'
};

// Vee-validate assign rules and messages
Object.keys(rules).forEach(rule => {
  extend(rule, {
    ...rules[rule],
    message: messages[rule]
  });
});

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('v-select', vSelect)
Vue.component('calendar', Calendar)
Vue.component('date-picker', DatePicker)
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

/* Firebase Config */
firebase.initializeApp({
  apiKey: process.env.VUE_APP_FIREBASE_API,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID
})
// databaseURL: 'YOUR_DATABASE_URL',
/* End Firebase */

window.axios = axios
axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.withCredentials = true

Vue.config.productionTip = false

Vue.prototype.$baseUrl = process.env.VUE_APP_BASE_URL;
Vue.prototype.$apiUrl = process.env.VUE_APP_API_URL;
Vue.prototype.$s3bucket =  process.env.VUE_APP_S3_URL;
/* Initialize moment.js for dates */
Vue.prototype.moment = moment;

/* Start Config Echo and Pusher (Broadcasts) */
window.Echo = new Echo({
  broadcaster: 'pusher',
  key: process.env.VUE_APP_MIX_PUSHER_APP_KEY,
  cluster: 'eu',
  wsHost: process.env.VUE_APP_ECHO_WS_HOST,
  wssHost: process.env.VUE_APP_ECHO_WS_HOST, // for localhost comment out
  authEndpoint: process.env.VUE_APP_ECHO_AUTH_ENDPOINT,
  encrypted: true,
  forceTLS: true, // false on localhost, true for live I suppose ?
  wsPort: "6001", // was 6001
  wssPort: "443", // for localhost comment out
  disableStats: true,
  enabledTransports: ['ws', 'wss'],
  authorizer: (channel, options) => {
    return {
      authorize: (socketId, callback) => {
        axios.post('api/broadcasting/auth', {
          socket_id: socketId,
          channel_name: channel.name
        })
            .then(response => {
              callback(false, response.data);
            })
            .catch(error => {
              callback(true, error);
            });
      }
    };
  }
})
/* End Config Echo and Pusher (Broadcasts) */

new Vue({
  store,
  beforeCreate() {
    // check if user is stored in sessionStorage
    this.$store.dispatch('getUser');
    // check if searchFilters are stored in sessionStorage
    this.$store.dispatch('getSearchFilters');
    // check if searchServicesFilters are stored in sessionStorage
    this.$store.dispatch('getSearchServicesFilters');
    // check if property Category Type Details are stored in sessionStorage
    this.$store.dispatch('getPropCatTypeDetails');
    },
  router,
  render: h => h(App)
}).$mount('#app')
